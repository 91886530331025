<template>
  <div class="create-notifications">
    <div class="grid-x grid-padding-x">
      <div class="cell font_0875">
        <p>Use the form below to add a notification.</p>
      </div>
    </div>

    <div class="grid-x grid-padding-x">
      <div class="cell auto">
        <label>Notification Title*</label>
        <input
          class
          type="text"
          placeholder="eg. Jane"
          aria-describedby="Title"
          name="Title"
          aria-errormessage="notificationTitle"
          required
          v-model="form.Title"
        />
        <span class="form-error" id="notificationTitle"
          >Please enter a notification title</span
        >

        <label>Call to action</label>
        <v-select
          placeholder="Call to action"
          v-model="form.CTA"
          :reduce="(referrer) => referrer.id"
          label="name"
          :options="ctaoptions"
        ></v-select>
        <input type="hidden" name="CTA" :value="CTA" />

        <label>
          Send to
          <select
            @change="sendtoOptions"
            id="send-to-action"
            class
            novalidate
            name="send_action"
            v-model="form.send_action"
            required
          >
            <option value>Select action</option>
            <option value="company">Everyone at Company</option>
            <option v-if="sitesarr" value="site">Everyone at Site</option>
            <option value="user">Single user</option>
          </select>
          <span class="form-error" id="call-to-action"
            >Please select a who you want the notification to be sent to.</span
          >
        </label>

        <div v-if="selectUser" class="cell auto" id="send-to-users">
          <label>
            Send to (type name):
            <!-- @todo -->
            <select-customer-user-component
              @selectedUser="selectedUserId"
              :site-id="0"
            ></select-customer-user-component
            >Please select someone to send this notification to
          </label>
        </div>

        <div v-if="selectSite" class="cell auto" id="send-to-sites">
          <label>
            Select site:
            <v-select
              placeholder="Select a Site"
              name="site_id"
              v-model="form.site_id"
              :reduce="(referrer) => referrer.id"
              label="name"
              :options="this.siteOptions"
            ></v-select>
          </label>
        </div>
      </div>

      <div class="cell auto">
        <div class="input-box">
          <label>
            Message*
            <div>
              <ckeditor
                v-model="form.Message"
                name="Message"
                id="notification_message"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <span class="form-error" id="message"
              >Please enter a notification message.</span
            >
          </label>
        </div>

        <div class>
          <div class="input-box-2">
            <label>
              When would you to send this notification?
              <div class="cell shrink input-box">
                <input
                  type="text"
                  class="datepicker-here top_bar_dd b_margin_0 white_form"
                  data-language="en"
                  data-date-format="dd-mm-yy"
                  data-position="bottom left"
                  aria-describedby="example1Hint1"
                  aria-errormessage="date"
                  id="filter-date-picker"
                  name="launch_date"
                  data-timepicker="true"
                />
                <span class="form-error" id="date">Please enter the date</span>
              </div>
              <div class="cell auto input-box">
                <input
                  name="send_immediately"
                  class="send_immediately"
                  id="send-immediately"
                  type="checkbox"
                  v-model="form.send_immediately"
                />
                <label for="send-immediately">Send immediately</label>
              </div>
            </label>
          </div>

          <div class="input-box">
            <label>Repeat Notification</label>
            <v-select
              v-model="form.Repeat"
              id="send-to-action"
              required
              :reduce="(referrer) => referrer.id"
              label="name"
              :options="senttooptions"
            ></v-select>
          </div>

          <div class="input-box">
            <button
              class="button pink_btn b_margin_0 no_wrap_btn"
              data-toggle="confirm"
              :disabled="formFilledbtn"
            >
              Confirm
            </button>
            <div
              class="dropdown-pane"
              id="confirm"
              data-dropdown
              data-auto-focus="true"
              data-position="bottom"
              data-alignment="center"
            >
              <div class="arrow_box">
                <fieldset class="cell" align="center">
                  <legend>Are you sure you'd like to confirm?</legend>
                  <input
                    type="radio"
                    v-model="confirmed"
                    value="true"
                    id="confirmYes"
                    required
                  />
                  <label for="confirmYes">YES</label>
                  <input
                    type="radio"
                    v-model="confirmed"
                    value="false"
                    id="confirmNo"
                  />
                  <label for="confirmNo">NO</label>
                </fieldset>
                <div align="right" v-if="confirmed == 'true'">
                  <button @click="save" type="submit" class="grey_link" disabled="isSaving">
                    {{isSaving ? 'Saving' : 'Submit'}}
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue/dist/legacy.js";

export default {
  components: {
    CKEditor,
  },
  props: ["sitesarr"],
  data: function () {
    return {
      confirmed: false,
      selectUser: false,
      selectSite: false,
      scheduleDate: false,
      siteOptions: [],
      senttooptions: [
        { id: "no repeat", name: "Repeat OFF" },
        { id: "daily", name: "Daily" },
        { id: "weekly", name: "Weekly" },
        { id: "monthly", name: "Monthly" },
      ],
      ctaoptions: [
        { id: "", name: "No action" },
        { id: "POINTS", name: "View the leaderboard" },
        { id: "JOBS", name: "Go to jobs" },
      ],
      CTA: "",
      sendto: "",
      timezone: "",
      form: {
        Title: "",
        CTA: "",
        send_action: "",
        site_id: "",
        Scheduled: "",
        send_immediately: "",
        Message: "",
        Repeat: "no repeat",
        timezone: "",
        user_id: "",
      },
      editorConfig: {
        /*plugins: "emoji,basicstyles,list,undo,link,wysiwygarea,toolbar",
              toolbar: [["Bold", "NumberedList", "BulletedList", "EmojiPanel", "Undo", "Redo"]],
              removeButtons: 'Italic',
              enterMode: 3*/
        toolbar: [
          [
            "Bold",
            "NumberedList",
            "BulletedList",
            "EmojiPanel",
            "Undo",
            "Redo",
            "Paste",
          ],
        ],
        removeButtons: "Italic",
        extraPlugins: "emoji",
      },
      isSaving: false,
    };
  },
  computed: {
    sendImmediately() {
      if (this.form.send_immediately == true) {
        return true;
      } else {
        return false;
      }
    },

    formFilledbtn() {
      if (this.form.Title && this.form.Message && this.form.send_action) {
        if (this.form.send_immediately || $("#filter-date-picker").val()) {
          if (
            (this.send_action == "site" && this.form.site_id) ||
            (this.send_action == "user" && this.form.user_id) ||
            this.send_action == "company"
          ) {
            return false;
          }
        } else return true;
      } else return true;
    },
  },
  methods: {
    selectedUserId(value) {
      this.form.user_id = value;
    },
    onInput(event) {
      this.form.Message = event.data;
    },
    clearTextarea() {
      this.form.Message = "";
    },

    sendtoOptions() {
      if (this.form.send_action == "site") {
        this.selectSite = true;
        this.selectUser = false;
      } else if (this.form.send_action == "user") {
        this.selectUser = true;
        this.selectSite = false;
      } else {
        this.selectUser == false;
        this.selectSite = false;
      }
    },

    save() {
      var self = this;
      this.isSaving = true;
      this.form.Scheduled = $("#filter-date-picker").val();
      axios
        .post("/dashboard/notification/create", this.form)
        .then(function (response) {
          self.resetForm();
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> New Notification is Created',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          this.isSaving = false;
          location.reload();
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log("Error Adding New Notification - error: ", error);
          this.isSaving = false;
        });
      //.finally(function() {});
    },
    resetForm() {
      this.form = {
        Title: null,
        CTA: null,
        send_action: null,
        site_id: "40 Hours",
        Scheduled: null,
        send_immediately: null,
        Message: null,
        Repeat: null,
        timezone: null,
        user_id: "",
      };
    },
  },
  mounted() {
    this.form.timezone = new Date().getTimezoneOffset();
    $("#filter-date-picker").val(moment(new Date()).format("DD-MM-YY hh:mm a"));
    this.$root.$on("selectedUser", this.form.user_id);
    if (this.sitesarr.length > 0) {
      for (var i = 0; i < this.sitesarr.length; i++) {
        let x = new Object();
        x.id = this.sitesarr[i].id;
        x.name = this.sitesarr[i].name;
        this.siteOptions.push(x);
      }
    } else if (this.sitesarr) {
      let x = new Object();
      x.id = this.sitesarr.id;
      x.name = this.sitesarr.name;
      this.siteOptions.push(x);
    }
  },
};
</script>

<style scoped>
</style>

