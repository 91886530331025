<script>
export default {
  name: 'JobsIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9331 12.9087C16.9331 13.2402 16.6641 13.5087 16.3331 13.5087H13.6902C13.3592 13.5087 13.0902 13.2402 13.0902 12.9087C13.0902 12.5772 13.3592 12.3087 13.6902 12.3087H16.3331C16.6641 12.3087 16.9331 12.5772 16.9331 12.9087ZM16.9331 10.0243C16.9331 10.3558 16.6641 10.6243 16.3331 10.6243H13.6902C13.3592 10.6243 13.0902 10.3558 13.0902 10.0243C13.0902 9.69282 13.3592 9.42433 13.6902 9.42433H16.3331C16.6641 9.42433 16.9331 9.69282 16.9331 10.0243ZM8.26737 12.7502H10.5908V10.1828H8.26737V12.7502ZM11.1908 8.98234H7.66739C7.3359 8.98234 7.0674 9.25134 7.0674 9.58283V13.3502C7.0674 13.6817 7.3359 13.9502 7.66739 13.9502H11.1908C11.5223 13.9502 11.7908 13.6817 11.7908 13.3502V9.58283C11.7908 9.25134 11.5223 8.98234 11.1908 8.98234ZM16.9316 18.568L14.8782 16.5986C14.6462 16.3756 14.2792 16.3761 14.0477 16.5986L11.9963 18.5675L9.94731 16.5986C9.71482 16.3761 9.34833 16.3756 9.11584 16.5986L7.0659 18.5675L5.20046 16.7761V6.48342C5.20046 5.77594 5.72145 5.19996 6.36193 5.19996H17.6381C18.2786 5.19996 18.7995 5.77594 18.7995 6.48342V16.7756L16.9316 18.568ZM20 6.48342C20 5.11397 18.9405 4 17.6381 4H6.36193C5.05947 4 4 5.11397 4 6.48342V16.8951C4 16.9196 4.0115 16.9406 4.014 16.9641C3.994 17.1441 4.0445 17.3291 4.18449 17.4641L6.65042 19.832C6.88191 20.055 7.2494 20.055 7.48139 19.8325L9.53133 17.8636L11.5803 19.832C11.8128 20.055 12.1797 20.055 12.4117 19.8325L14.4632 17.8631L16.5161 19.8325C16.6321 19.944 16.7816 19.9995 16.9316 19.9995C17.0811 19.9995 17.2306 19.944 17.3466 19.8325L19.815 17.4641C19.9555 17.3296 20.006 17.1441 19.986 16.9646C19.9885 16.9406 20 16.9196 20 16.8951V6.48342Z" fill="currentColor"/>
  </svg>
</template>