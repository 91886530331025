<script>
export default {
  name: 'AlertIcon'
}
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.25 4.25H8.75V5.75H7.25V4.25ZM8 11.75C8.4125 11.75 8.75 11.4125 8.75 11V8C8.75 7.5875 8.4125 7.25 8 7.25C7.5875 7.25 7.25 7.5875 7.25 8V11C7.25 11.4125 7.5875 11.75 8 11.75ZM8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14Z" fill="#E61414"/>
 </svg>

</template>