<template>
    <div v-if="addCandidate" id="add-candidate">
        <!-- <div class="grey_border_box_inner box_shadow"> -->
        <b-modal ref="add-candidate" id="modal-lg" size="lg"  class="tab-modal" v-model="show" @hide="isShowfalse" :no-close-on-backdrop="true">
            <h3> Add a Candidate </h3>
            <div class="grid-y">
                <div class="grid-x">

                    <div class="cell auto input-box">
                        <label>Role*</label>
                        <v-select
                            v-model="data.job_id"
                            :reduce="referrer => referrer.id"
                            label="name"
                            :options="availableJobs"
                            :close-on-select="true"
                        ></v-select>
                        <span class="form-error" id="appliedfor-error">Job Role is required</span>
                    </div>
                </div>

                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                        <label>
                            First name*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. Jane"
                                aria-describedby="example1Hint1"
                                aria-errormessage="firstname-error"
                                required
                                v-model="data.First_Name"
                            />
                            <span class="form-error" id="firstname-error">The candidates first name is required</span>
                        </label>
                    </div>

                    <div class="cell auto input-box">
                        <label>
                            Last Name*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. Smith"
                                aria-describedby="example1Hint1"
                                aria-errormessage="surname-error"
                                required
                                v-model="data.Surname"
                            />
                            <span class="form-error" id="surname-error">The candidates surname is required</span>
                        </label>
                    </div>
                </div>

                <div class="grid-x input-box-2">


                    <div class="cell auto input-box">
                        <label>
                            Phone number*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. 07777 123456"
                                aria-describedby="example1Hint1"
                                aria-errormessage="phone-error"
                                required
                                v-model="data.Phone_Number"
                            />
                            <span
                                class="form-error"
                                id="phone-error"
                            >A contact number is required for the candidate</span>
                        </label>
                    </div>

                    <div class="cell auto input-box">
                        <label>
                            Email*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. jane@email.com"
                                aria-describedby="example1Hint1"
                                aria-errormessage="email-error"
                                required
                                pattern="email"
								@blur="validateEmail"
								ref="Email"
                                v-model="data.Email"
                            />
                            <span class="form-error" id="email-error">The candidates email address is required</span>
                        </label>
                    </div>

                </div>

                <div class="grid-x">
                    <div class="cell auto input-box half-col">
                        <label>
                            Postcode*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. WR9 8TN"
                                v-model="data.Postcode"
                            />
                        </label>
                    </div>
                    <div class="cell auto input-box"></div>
                </div>

                <div class="grid-x">
                    <div class="cell auto input-box">
                        <label>Referred by*</label>
                        <v-select
                            v-model="data.full_data"
                            :reduce="referrer => referrer.full_data"
                            label="name"
                            :options="filteredAvailableUsers"
                            :close-on-select="true"
                            @search="onSearch"
                            @input="userTypeinfo(data.full_data)"
                        >
                          <template #selected-option>
                            <div>{{ userName }}</div>
                          </template>

                          <template #no-options="{ search }">
                            <span v-if="search.length < 3">Type at least 3 characters...</span>
                            <span v-else>Sorry, No Matching Options</span>
                          </template>
                        </v-select>
                    </div>
                </div>

                <div class="grid-x">
                    <div class="cell auto input-box">
                        <label>Expressed interest on*</label>
                        <datepicker
                            name="applied-on"
                            v-model="data.created_at"
                            format="dd/MM/yy"
                            input-class=""
                        ></datepicker>
                    </div>
                </div>

                <template v-if="isCareSector">
                  <div class="grid-x">
                      <div class="cell auto input-box half-col">
                          <label>New to Care?*</label>
                          <v-select
                              v-model="data.newtocare"
                              label="name"
                              :options="newtocare"
                              :reduce="referrer => referrer.id"
                              :close-on-select="true"
                          ></v-select>
                      </div>
                      <div class="cell auto input-box"></div>
                  </div>
                </template>
            </div>

            <div class="cell auto popup-button">
                <button
                    id="add-candidate-btn-confirm"
                    type="button"
                    @click="confirm()"
                    class="button btn_lg btn pink_btn"
                    :disabled="btnAddCanidate"
                >Add</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
  import Datepicker from "vuejs-datepicker";
  export default {
    components: {
      Datepicker
    },
    props: {
      users: {
        type: Array
      },
      currentuser: {
        type: Object
      },
      addCandidate: {
        type: Boolean
      },
      initalStatus: {
        type: String,
        default: ""
      },
      isCareSector: {
        type: Boolean
      }
    },
    data() {
      return {
        referrerName: "",
        show: true,
        options: this.users,
        newtocare: [{ id: 1, name: 'Yes' }, { id: 2, name: 'No' }, { id: -1, name: 'Unsure' }],
        availableUsers: [],
        checkArr: [],
        availableJobs: [],
        data: {
          First_Name: "",
          Surname: "",
          Email: "",
          Status: "",
          Postcode: "",
          Phone_Number: "",
          Deleted: "",
          cv_upload: "",
          cv_link: "",
          user_id: "",
          user_type: "",
          job_id: "",
          newtocare: this.isCareSector ? "" : "2",
          usertype_email: "",
          usertype_phone_number: "",
          full_data: ''
        },
        jobs: {},
        search: '',
        userName: ''
      };
    },
    methods: {
      confirm() {
        // alert(JSON.stringify(this.data));
        this.save();
        this.$root.$emit("addCandidate", false);
      },
      cancel() {
        this.$parent.savedStatus = "";
        this.$root.$emit("addCandidate", false);
      },
      validateEmail() {
        var email = this.data.Email;
        axios.post('/dashboard/candidate/vaidate-email', { 'Email': email, 'customer_id': this.currentuser.customer_id })
          .then(function (response) {
            if (response.data == 'duplicate') {
              Vue.swal({
                title: "Candidate email identified as duplicate",
                confirmButtonColor: '#f55d73',
                confirmButtonText: 'OK',
                html: 'Whoops! That email address is registered to an existing candidate.',

                allowOutsideClick: false,

              });
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });

      },
      save() {
        //console.log('save Candidate: ', this.data);
        var self = this;
        Vue.swal({
          title: "Saving...",
          html: "",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Vue.swal.showLoading();
          }
        });
        var self = this;
        axios
          .post("/dashboard/candidate/add", { ...this.data, tab: "live" }, {})
          .then(function (response) {
            if (response.data == 'duplicate') {
              Vue.swal.close();

              Vue.swal({
                title: "Candidate email identified as duplicate",
                confirmButtonColor: '#f55d73',
                confirmButtonText: 'OK',
                html: 'Whoops! That email address is registered to an existing candidate.',

                allowOutsideClick: false,
              });
              this.$root.$on("addCandidate", true);
              // show = false;
              return false;
            }
            //self.message = self.title+" Added!"
            /*console.log(
                "Candidate successfully added, updates jobs 2: ",
                response.data
            );*/
            self.$parent.savedStatus = "saved";
            self.$parent.isShow = false;
            self.$root.$emit("updatedCandidates", response.data);
            Vue.swal({
              html:
                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Candidate added',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast"
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s"
              },
              hideClass: {
                popup: "animated fadeOutLeft slow"
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs"
            });
            //Bugfix-9145: Refreshing the page to load the newly added candidates on the candidate-page
            setTimeout(function () {
              location.reload();
            }, 500);
          })
          .catch(function (error) {
            // handle error
            self.message = "Error!";
            //console.log("save Candidate - error: ", error);
          });
      },
      isShowfalse() {
        var self = this;
        self.$parent.isShow = false;
      },
      userTypeinfo(value) {
        var self = this;

        for (var i = this.users.length - 1; i >= 0; i--) {
          //if (this.users[i].active) {
          if (value == this.stringifyUserDetails(this.users[i])) {
            if(this.users[i].email !== '' && this.users[i].email !== null) {
              this.userName = `${this.users[i].first_name} ${this.users[i].surname} (${this.users[i].email})`;
            } else {
              this.userName = `${this.users[i].first_name} ${this.users[i].surname}`;
            }
            this.data.usertype_email = this.users[i].email;
            this.data.user_id = this.users[i].id;
            this.data.usertype_phone_number = this.users[i].phone_number;
            this.data.user_type = this.users[i].user_type;
            this.data.full_data = this.stringifyUserDetails(this.users[i]);
          }
          //}
        }
      },
      onSearch(query) {
        this.search = query
      }
    },
    mounted() {
      var candidate = '';
      var self = this;
      axios
        .get("/dashboard/candidates/get-candidates-jobs")
        .then(function (response) {
          if (response.data) {
            //console.log("JOB DATA "+JSON.stringify(response.data));
            self.jobs = JSON.stringify(response.data);
            //console.log("JOB DATA2 "+self.jobs);
            var sitename = '';
            //Create array of available jobs for reffer dropdown
            for (var i = response.data.length - 1; i >= 0; i--) {
              if (response.data[i].site !== null) {
                sitename = response.data[i].site.name;
              } else {
                sitename = 'All Sites';
              }
              var job = {
                name: response.data[i].Job_Title + ' [' + sitename + ']',
                id: response.data[i].id
              };
              self.availableJobs.push(job);
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      //Create array of available users for reffer dropdown
      for (var i = this.users.length - 1; i >= 0; i--) {
        if (this.users[i].active) {
          if (this.checkArr.includes(this.stringifyUserDetails(this.users[i])) == false) {
            var stringifiedUserDetails = this.stringifyUserDetails(this.users[i]);
            var email = "";

            if (this.users[i].email !== '' && this.users[i].email !== null) {
              var email = " (" + this.users[i].email + ")";
            }
            var user = {
              name: this.users[i].first_name + " " + this.users[i].surname + email,
              id: this.users[i].id + "-" + this.users[i].email,
              full_data: stringifiedUserDetails
            };
            this.checkArr.push(stringifiedUserDetails);
            this.availableUsers.push(user);
          }
        }
      }
      //console.log("Avaiable Sites "+JSON.stringify(this.availableUsers));
      $(this.$refs.add - candidate).on("hidden.bs.modal", this.isShowfalse)
    },
    computed: {
      btnAddCanidate() {
        if (
          this.data.First_Name &&
          this.data.Surname &&
          this.data.Email &&
          this.data.user_id >= 0 &&
          this.data.job_id &&
          this.data.Phone_Number &&
          this.data.newtocare &&
          this.data.Postcode
        ) {
          return false;
        } else return true;
      },
      filteredAvailableUsers() {
        if (this.search.length < 3) {
          return [];
        }

        return this.availableUsers.filter((user) => user.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()));
      },
    }
  };
</script>
