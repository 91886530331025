<script>
    export default {
    name: "SingleOneOffBudgetIcon",
    };
</script>

<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_80_5130)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 8C16.5 10.21 14.71 12 12.5 12C10.29 12 8.5 10.21 8.5 8C8.5 5.79 10.29 4 12.5 4C14.71 4 16.5 5.79 16.5 8ZM4.5 18C4.5 15.34 9.83 14 12.5 14C12.9235 14 13.4139 14.0337 13.9393 14.1011C13.042 15.1497 12.5 16.5116 12.5 18C12.5 18.7013 12.6203 19.3744 12.8414 20H5.5C4.95 20 4.5 19.55 4.5 19V18Z" fill="#3C3C3C"/>
        <circle cx="18.5" cy="18" r="4.5" stroke="#3C3C3C"/>
        <path d="M17.3731 16.503V15.39H19.3121V20.5H18.0661V16.503H17.3731Z" fill="#3C3C3C"/>
        </g>
        <defs>
        <clipPath id="clip0_80_5130">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
        </defs>
    </svg>
</template>
