<template>
    <div>
        <a href="javascript:void(0)"
           class="action-btn"
           @click="duplicateJob(job.id)">
            <icon name="duplicate" />
            <span>Duplicate job</span>
        </a>
    </div>
</template>

<script>
import { Icon } from '../../../../app/components/Icon';

export default {
    name: 'DuplicateJob',
    props: {
        currentuser: { type: Object, required: true },
        job: { type: Object, required: true },
        index: { type: Number, default: 0 }
    },
    components: {
        Icon,
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        duplicateJob(id) {
            Vue.swal({
                title: "Duplicating...",
                html: "",
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Vue.swal.showLoading();
                }
            });
            var self = this;
            axios
                .get("/dashboard/jobs/" + id + "/duplicate")
                .then(function (response) {
                    self.$emit('on-job-duplicated', response.data.job);
                    self.showSuccessToast('Job Duplicated');
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showSuccessToast(message) {
            Vue.swal({
                html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> ' + message,
                confirmButtonColor: "#5dc2a6",
                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: {
                    container: "candidate-toast"
                },
                showClass: {
                    popup: "animated fadeInLeft fast",
                    icon: "animated heartBeat delay-1s"
                },
                hideClass: {
                    popup: "animated fadeOutLeft slow"
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
            });
        }
    }
};
</script>

<style scoped lang="scss">
@import "./DuplicateJob.scss";
</style>