<template>
  <div class="grid-container full background_care reset-password">
    <div class="grid-x grid-padding-x align-center-middle full_height">
      <div class="cell large-4 medium-8 small-10">
        <div class="login-inner grid-padding-x padding-x-lg"
             :class="{ pass_login_inner: isActive }">
          <div class="login-small">
            <div class="logo-login-form">
              <img :src="logo" />
            </div>
            <h3 class="login-padding">Welcome!</h3>
            <p class="login-bold">To get started, please choose a password:</p>
            <div v-if="passError"
                 class="pass-error">
              <div class="cell small-6">
                <div class="grid-x">
                  <div class="cell small-1">
                    <img src="/images/skip-error.png" />
                  </div>
                  <div class="cell medium-5">
                    <p>{{ errors[0] }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form_container t_margin_2">
              <div class="input-group">
                <input type="email"
                       class="input-group-field"
                       name="email"
                       v-model="email"
                       disabled />
              </div>

              <div class="b_border_w1"></div>

              <div class="cell small-12 input-group-field pass-12">
                <div class="grid-x">
                  <div class="cell small-11">
                    <input :type="type"
                           class="no-outline"
                           name="password"
                           v-model="password"
                           placeholder="Password"
                           @input="validatePassword" />
                  </div>
                  <div class="cell small-1 pass-2">
                    <img @click="changeImage"
                         :src="image" />
                  </div>
                </div>
              </div>
              <div class="cell small-4 pass-4">
                <div class="grid-x">
                  <div class="cell small-1 correct-pass">
                    <img :src="pass_image" />
                    <img class="white-tick"
                         src="/images/white-tick.png" />
                  </div>
                  <div class="cell small-3 min-pass">
                    <p>Min 10 characters</p>
                  </div>
                </div>
              </div>
              <div class="cell small-12 input-group-field pass-12 pass-12-margin">
                <div class="grid-x">
                  <div class="cell small-11">
                    <input :type="type2"
                           class="no-outline"
                           name="password_confirmation"
                           v-model="password_confirmation"
                           placeholder="Confirm password" />
                  </div>
                  <div class="cell small-1 pass-2">
                    <img @click="changeConfirmImage"
                         :src="image2" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <b-button @click="submit()"
                        v-on:keyup.13="submit"
                        type="submit"
                        id="login-button"
                        class="button btn-lg expanded redis_btn bold"
                        :disabled="btnDisabled == true">
                <img src="/images/svg/white.svg"
                     :class="progress" />
                NEXT
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["token", "email", "set", "brandLogo"],
  data() {
    return {
      password: "",
      password_confirmation: "",
      progress: "progress-white hide",
      image: "/images/show-password.png",
      image2: "/images/show-password.png",
      pass_image: "/images/pass-initial.png",
      type: "password",
      type2: "password",
      passError: false,
      errors: [],
      isActive: false,
      passValidation: false,
      logo: this.brandLogo || "/images/cf-login-logo.png",
    };
  },
  methods: {
    validatePassword() {
      this.passValidation = false;
      this.passError = false;
      this.isActive = false;
      this.pass_image = "/images/pass-initial.png";
      if (this.password.length >= 10) {
        var self = this;
        this.pass_image = "/images/pass-final.png";
        axios
          .post("/validate-password", { email: this.email, password: this.password })
          .then(function (response) {
            if (response.data.error) {
              if (response.data.error.password) {
                self.errors = response.data.error.password;
              } else {
                self.errors[0] = response.data.error;
              }
              self.passError = true;
              self.isActive = true;
            } else {
              self.passValidation = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    changeImage() {
      if (this.image == "/images/show-password.png") {
        this.image = "/images/hide-password.png";
        this.type = "text";
      } else {
        this.image = "/images/show-password.png";
        this.type = "password";
      }
    },
    changeConfirmImage() {
      if (this.image2 == "/images/show-password.png") {
        this.image2 = "/images/hide-password.png";
        this.type2 = "text";
      } else {
        this.image2 = "/images/show-password.png";
        this.type2 = "password";
      }
    },
    submit() {
      const axios = require("axios");
      if (this.set) {
        axios
          .post("/setup-password", {
            password: this.password,
          })
          .then(response => {
            location.replace(location.protocol + "//" + location.hostname + response.data);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        axios
          .post("/password/reset", {
            token: this.token,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
          })
          .then(response => {
            if (response.data?.message?.includes('Your password has been reset')) {
              location.href = '/login';
            } else {
              location.reload();
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  },
  computed: {
    btnDisabled: function () {
      if (
        this.email &&
        this.password &&
        this.password_confirmation &&
        this.password == this.password_confirmation &&
        this.passValidation
      ) {
        return false;
      } else return true;
    },
  },
};
</script>
