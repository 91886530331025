<template>
  <div>
    <div id="menu" class="cell auto magic_flag" v-if="magic_flag">
      <div class="megic-flag-left">
        <h3 class="Unsaved-updates">Unsaved changes</h3>
      </div>
      <div class="megic-flag-right1">
        <button
          id="top_discard_btn"
          class="button btn_lg btn orange_btn"
          v-if="magic_flag"
          @click="undoCheck = true"
          v-b-modal.modal_undo
        >
          Discard
        </button>
        <input
          type="button"
          name="next"
          class="button btn_lg btn pink_btn"
          value="Save"
          @click="postData"
        />
      </div>
    </div>
    <div>
      <p class="Push-and-in-app">
        Push and in-app notifications are automatically sent to your app users
        at various times/when triggered. You can turn each of these on/off and
        even customise the wording if you want to.
      </p>
    </div>
    <div class="bg-card">
      <div
        class="border-card"
        v-if="
          'good_news' in this.automations &&
          this.automations['good_news'].length > 0
        "
      >
        <div class="container-fluid Goodnews">
          <h4 class="automation-h-new">Good news</h4>
        </div>
        <table class="table1">
          <thead class="table-body-styling1">
            <tr>
              <td class="table-head-styling-automation" scope="col">TRIGGER</td>
              <td class="table-head-styling-automation" scope="col">CONTENT</td>
              <td
                class="table-head-styling-push"
                id="tooltip-target-push"
                scope="col"
              >
                PUSH
                <b-tooltip target="tooltip-target-push" triggers="hover">
                  Notifications appear in mobile <br />
                  status bar
                </b-tooltip>
              </td>

              <td
                class="table-head-styling-push"
                id="tooltip-target-inapp"
                scope="col"
              >
                IN-APP
                <b-tooltip target="tooltip-target-inapp" triggers="hover">
                  Notifications appear in mobile <br />
                  notification tab
                </b-tooltip>
              </td>
            </tr>
          </thead>
          <tbody class="table-automation-body">
            <tr
              class="automation-hover-edit"
              v-for="(automation, index) in this.automations['good_news']"
            >
              <th class="table-CREATED-styling">
                {{ automation.trigger }}
              </th>
              <td scope="row" class="text-p-styling-automation">
                <div class="edit-automations-div">
                  <div>
                    <span
                      class="table-title-styling1"
                        v-html="
                          automation.title
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                    ></span>
                    <!-- <span
                      class="p-styling1"
                      v-html="
                        automation.description
                          .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 100) + '..'
                      "
                    >
                    </span> -->

                    <p
                      v-b-tooltip.hover.html="{
                        customClass: 'Notification-tooltip',
                        title: automation.description,
                      }"
                      class="p-styling1"
                        v-html="
                          automation.description
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                    ></p>
                  </div>

                  <div
                    class="img-automation"
                    @click="
                      editNotification(
                        automation.id,
                        index,
                        automation.category
                      )
                    "
                  >
                    <img src="/images/icon-24-edit-02.png" alt="" />
                  </div>
                </div>
              </td>
              <td class="table-CREATED-styling">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="automation.push"
                    @click="
                      togglePush(index, automation.category, automation.push)
                    "
                  />
                  <span class="slider round"></span>
                </label>
              </td>
              <td class="table-data-styling">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="automation.in_app"
                    @click="
                      toggleInApp(index, automation.category, automation.in_app)
                    "
                  />
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="border-card"
        v-if="
          'candidates' in this.automations &&
          this.automations['candidates'].length > 0
        "
      >
        <div class="container-fluid Goodnews">
          <h4>Candidates</h4>
          <p class="condidatesParagraph">
            We’ll send these notifications to your staff automatically when the
            status of their candidate is updated, so they always know how their
            candidate are progressing.
          </p>
        </div>

        <table class="table1">
          <thead class="table-body-styling1">
            <tr class="">
              <td class="table-head-styling-automation" scope="col">TRIGGER</td>
              <td class="table-head-styling-automation" scope="col">CONTENT</td>
              <td
                class="table-head-styling-push"
                id="tooltip-target-push-Candidates"
                scope="col"
              >
                PUSH
                <b-tooltip
                  target="tooltip-target-push-Candidates"
                  triggers="hover"
                >
                  Notifications appear in mobile <br />
                  status bar
                </b-tooltip>
              </td>

              <td
                class="table-head-styling-push"
                id="tooltip-target-inapp-Candidates"
                scope="col"
              >
                IN-APP
                <b-tooltip
                  target="tooltip-target-inapp-Candidates"
                  triggers="hover"
                >
                  Notifications appear in mobile <br />
                  notification tab
                </b-tooltip>
              </td>
            </tr>
          </thead>
          <tbody class="table-automation-body">
            <tr
              class="automation-hover-edit"
              v-for="(automation, index) in this.automations['candidates']"
            >
              <th class="table-CREATED-styling">
                {{ automation.trigger }}
              </th>
              <td scope="row" class="text-p-styling-automation">
                <div class="edit-automations-div">
                  <div>
                    <span
                      class="table-title-styling1"
                        v-html="
                          automation.title
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                    ></span>
                    <!-- <span
                      class="p-styling1"
                      v-html="
                        automation.description
                          .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 100) + '..'
                      "
                    ></span> -->

                    <p
                      v-b-tooltip.hover.html="{
                        customClass: 'Notification-tooltip',
                        title: automation.description,
                      }"
                      class="p-styling1"
                      v-html="
                          automation.description
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                    ></p>
                  </div>

                  <div
                    class="img-automation"
                    @click="
                      editNotification(
                        automation.id,
                        index,
                        automation.category
                      )
                    "
                  >
                    <img src="/images/icon-24-edit-02.png" alt="" />
                  </div>
                </div>
              </td>
              <td class="table-CREATED-styling">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="automation.push"
                    @click="
                      togglePush(index, automation.category, automation.push)
                    "
                  />
                  <span class="slider round"></span>
                </label>
              </td>
              <td class="table-data-styling">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="automation.in_app"
                    @click="
                      toggleInApp(index, automation.category, automation.in_app)
                    "
                  />
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="border-card"
        v-if="
          'payments' in this.automations &&
          this.automations['payments'].length > 0
        "
      >
        <div class="container-fluid Goodnews">
          <h4>Payments</h4>
          <p class="condidatesParagraph">
            We’ll send these notifications to your staff automatically when the
            status of their candidate is updated, so they always know how their
            candidate are progressing.
          </p>
        </div>
        <table class="table1">
          <thead class="table-body-styling1">
            <tr>
              <td class="table-head-styling-automation" scope="col">TRIGGER</td>
              <td class="table-head-styling-automation" scope="col">CONTENT</td>
              <td
                class="table-head-styling-push"
                id="tooltip-target-push-other1"
                scope="col"
              >
                PUSH
                <b-tooltip target="tooltip-target-push-other1" triggers="hover">
                  Notifications appear in mobile <br />
                  status bar
                </b-tooltip>
              </td>

              <td
                class="table-head-styling-push"
                id="tooltip-target-inapp-other1"
                scope="col"
              >
                IN-APP
                <b-tooltip
                  target="tooltip-target-inapp-other1"
                  triggers="hover"
                >
                  Notifications appear in mobile <br />
                  notification tab
                </b-tooltip>
              </td>
            </tr>
          </thead>
          <tbody class="table-automation-body">
            <tr
              class="automation-hover-edit"
              v-for="(automation, index) in this.automations['payments']"
            >
              <th class="table-CREATED-styling">
                {{ automation.trigger }}
              </th>
              <td scope="row" class="text-p-styling-automation">
                <div class="edit-automations-div">
                  <div>
                    <span
                      class="table-title-styling1"
                        v-html="
                          automation.title
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                    ></span>
                    <!-- <span
                      class="p-styling1"
                      v-html="
                        automation.description
                          .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 100) + '..'
                      "
                    ></span> -->
                    <p
                      v-b-tooltip.hover.html="{
                        customClass: 'Notification-tooltip',
                        title: automation.description,
                      }"
                      class="p-styling1"
                      v-html="
                          automation.description
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                    ></p>
                  </div>

                  <div
                    class="img-automation"
                    @click="
                      editNotification(
                        automation.id,
                        index,
                        automation.category
                      )
                    "
                  >
                    <img src="/images/icon-24-edit-02.png" alt="" />
                  </div>
                </div>
              </td>
              <td class="table-CREATED-styling">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="automation.push"
                    @click="
                      togglePush(index, automation.category, automation.push)
                    "
                  />
                  <span class="slider round"></span>
                </label>
              </td>
              <td class="table-data-styling">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="automation.in_app"
                    @click="
                      toggleInApp(index, automation.category, automation.in_app)
                    "
                  />
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="border-card"
        v-if="
          'others' in this.automations && this.automations['others'].length > 0
        "
      >
        <div class="container-fluid Goodnews">
          <h4 class="other-padding">Other</h4>
        </div>
        <table class="table1">
          <thead class="table-body-styling1">
            <tr>
              <td class="table-head-styling-automation" scope="col">TRIGGER</td>
              <td class="table-head-styling-automation" scope="col">CONTENT</td>
              <td
                class="table-head-styling-push"
                id="tooltip-target-push-other"
                scope="col"
              >
                PUSH
                <b-tooltip target="tooltip-target-push-other" triggers="hover">
                  Notifications appear in mobile <br />
                  status bar
                </b-tooltip>
              </td>

              <td
                class="table-head-styling-push"
                id="tooltip-target-inapp-other"
                scope="col"
              >
                IN-APP
                <b-tooltip target="tooltip-target-inapp-other" triggers="hover">
                  Notifications appear in mobile <br />
                  notification tab
                </b-tooltip>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              class="automation-hover-edit"
              v-for="(automation, index) in this.automations['others']"
            >
              <th class="table-CREATED-styling">
                {{ automation.trigger }}
              </th>
              <td scope="row" class="text-p-styling-automation">
                <div class="edit-automations-div">
                  <div>
                    <span
                      class="table-title-styling1"
                        v-html="
                          automation.title
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                    ></span>
                    <!-- <span
                      class="p-styling1"
                      v-html="
                        automation.description
                          .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 100) + '..'
                      "
                    ></span> -->
                    <p
                      v-b-tooltip.hover.html="{
                        customClass: 'Notification-tooltip',
                        title: automation.description,
                      }"
                      class="p-styling1"
                      v-html="
                          automation.description
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                    ></p>
                  </div>

                  <div
                    class="img-automation"
                    @click="
                      editNotification(
                        automation.id,
                        index,
                        automation.category
                      )
                    "
                  >
                    <img src="/images/icon-24-edit-02.png" alt="" />
                  </div>
                </div>
              </td>
              <td class="table-CREATED-styling">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="automation.push"
                    @click="
                      togglePush(index, automation.category, automation.push)
                    "
                  />
                  <span class="slider round"></span>
                </label>
              </td>
              <td class="table-data-styling">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="automation.in_app"
                    @click="
                      toggleInApp(index, automation.category, automation.in_app)
                    "
                  />
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <hr class="hr-line-automation" />
    </div>
    <b-modal
      ref="add-job"
      id="edit-notification"
      size="xl"
      :no-close-on-backdrop="true"
    >
      <section class="multi-step-row">
        <div class="pop-up-box">
          <div class="text-box" id="text-box">
            <fieldset>
              <div class="job-leftside">
                <form>
                  <div class="form-group">
                    <p class="Edit-notification">Edit notification</p>
                    <label
                      for="exampleInputEmail1"
                      class="exampleInputEmailtitle"
                      >Title *</label
                    >
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            v-model="currentObject.title"
                                            aria-errormessage="notificationTitle"
                                        /> -->

                    <ckeditor
                      class="form-control text-area-border styling-bottom"
                      placeholder="Add Wording"
                      id="exampleFormControlTextarea1"
                      name="Message"
                      rows="5"
                      v-model="currentObject.title"
                      :config="titleEditorConfig"
                    >
                    </ckeditor>

                    <span class="form-error" id="notificationTitle"
                      >Please enter a notification title</span
                    >
                  </div>

                  <div class="form-group">
                    <label class="wording" for="exampleFormControlTextarea1"
                      >WORDING *</label
                    >
                    <ckeditor
                      class="cheditor-styling"
                      :config="editorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObject.description"
                    ></ckeditor>
                  </div>
                  <!-- <div class="imgae-Modal-styling">
                                      <p class="img-Content-styling">Image</p>
                                  </div> -->
                  <!-- <label class="Image-label-content"></label>
                                  <input type="file" id="actual-btn" hidden />
                                  <label for="actual-btn" class="upload-file-styling">
                                      <img class="img-upload-btn" src="/imagesss/Capture.PNG" />
                                      <span
                                      >Drag and drop an image or browse to choose a file.Min
                                   size 180 x 180 px</span
                                      >
                                  </label> -->
                </form>
              </div>
              <div class="job-rightside">
                <div class="frame">
                  <img
                    class="img-mobile-notification edit-mobile-image"
                    src="/images/notification-preview-image.png"
                    alt=""
                  />

                  <div class="mobile-notification-title-automation">
                    <div class="mobile-notification-branding-table line-clamp-title">
                        <img
                            v-if="brand.is_care_sector"
                            src="/images/cf-favicon.png"
                        />
                        <img
                            v-else
                            src="/images/workfriends-logomark-2000px-full-colour.png"
                        />
                        <p>
                            {{ brand.name }}
                        </p>
                    </div>
                    <p
                      class="
                        mobile-notification-title-automation
                        hidden-title
                        line-clamp-title
                      "
                      v-html="currentObject.title"
                    ></p>
                    <p
                      class="
                        mobile-notification-paragraph-automation
                        line-clamp
                      "
                      v-html="currentObject.description"
                    ></p>
                  </div>
                </div>
              </div>

              <div class="cell auto popup-button pad-top edit-btn-styling">
                <button @click.prevent="closeModal" class="Cancel button btn">
                  Cancel
                </button>
                <input
                  @click="save"
                  type="button"
                  name="Edit"
                  class="next action-button button btn_lg btn pink_btn"
                  value="Edit"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>

    <div class="btn-automation-save-discard-div">
      <button
        class="discard-btn-automation"
        @click="undoCheck = true"
        v-b-modal.modal_undo
      >
        DISCARD
      </button>
      <button class="Save-btn-automation" @click="postData">SAVE</button>
    </div>
    <b-modal v-if="undoCheck" class="tab-modal" id="modal_undo" size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <div class="discard-header4">
              <h3 class="discard-header-title">Discard all unsaved changes?</h3>
            </div>
            <p class="if-you-discard-chang">
              If you discard changes, you’ll delete any edits you made since you
              last saved.
            </p>
          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input
          class="button btn_lg btn pink_btn"
          type="submit"
          value="Discard changes"
          style="
            float: right;
            margin-right: -13px;
            margin-bottom: 13px;
            margin-left: 7px;
          "
          @click="undoEdit"
        />
        <input
          type="button"
          class="button btn_lg btn orange_btn anon-can"
          value="Continue editing"
          @click="undoCheck = false"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue/dist/legacy.js";
export default {
  props: ["milestone", "automations", "brand"],
  CKEditor,
  data() {
    return {
      titleEditorConfig: {
        autoParagraph: false,
        autoGrow_maxHeight: 50,
        height: 60,
        editorplaceholder: "Add title",
        // removePlugins : 'autogrow',
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["editorplaceholder", "emoji"],
        enterMode: 2,
      },
      step: 1,
      currentObject: {
        id: null,
        customer_id: null,
        trigger: "",
        title: "",
        description: "",
        category: "",
        push: 1,
        in_app: 1,
        created_at: "",
        updated_at: "",
      },
      currentIndex: null,
      currentCategory: "",
      editorConfig: {
        editorplaceholder: "Add words",
        autoParagraph: false,
        height: 148,
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      initialStateAutomations: JSON.parse(JSON.stringify(this.automations)),
      magic_flag: false,
      undoCheck: false,
    };
  },
  methods: {
    editNotification(id, index, category = "") {
      console.log("called notification # ", id);
      console.log("prev value ::: ", this.currentObject);

      this.currentIndex = index;
      this.currentCategory = category;

      let value = this.automations[category][index];
      this.currentObject = Object.assign({}, value);

      this.$bvModal.show("edit-notification");
    },
    save() {
      let tempArr = JSON.parse(JSON.stringify(this.automations));
      tempArr[this.currentCategory][this.currentIndex] = JSON.parse(
        JSON.stringify(this.currentObject)
      );
      this.automations = JSON.parse(JSON.stringify(tempArr));

      this.magic_flag = true;
      this.$bvModal.hide("edit-notification");
    },
    closeModal() {
      this.$bvModal.hide("edit-notification");
    },
    togglePush(index, category, value) {
      let tempArr = JSON.parse(JSON.stringify(this.automations));
      tempArr[category][index].push = value;
      this.automations = JSON.parse(JSON.stringify(tempArr));
      this.magic_flag = true;
    },
    toggleInApp(index, category, value) {
      let tempArr = JSON.parse(JSON.stringify(this.automations));
      tempArr[category][index].in_app = value;
      this.automations = JSON.parse(JSON.stringify(tempArr));
      this.magic_flag = true;
    },
    postData() {
      let that = this;
      axios
        .post(
          "/dashboard/notifications-page/update/automations",
          this.automations
        )
        .then(function (response) {
          that.magic_flag = false;
          that.undoCheck = false;
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Automations-Notifications are Updated',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          // location.reload();
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log(
            "Error Updating Automations Notifications - error: ",
            error
          );
        });
    },
    undoEdit() {
      this.$bvModal.hide("modal_undo");

      let tempArr = JSON.parse(JSON.stringify(this.initialStateAutomations));
      this.automations = JSON.parse(JSON.stringify(tempArr));
      this.magic_flag = false;
      this.undoCheck = false;
    },
    preventNav(event) {
      console.log("event is called");
      if (this.magic_flag) {
        this.$bvModal.show("modal_undo");
        // console.log('event.cancelBubble after : ', event.cancelBubble);
        // event.stopPropagation();
        // event.stopImmediatePropagation();
        event.preventDefault();
        event.returnValue = "";
        console.log(" this.magic_flag ", this.magic_flag);
      } else {
        console.log(" this.magic_flag ", this.magic_flag);
        return;
      }
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  beforeRouteLeave() {
    console.log("leaving component");
    const answer = window.confirm(
      "Do you really want to leave? you have unsaved changes!"
    );
    if (answer) {
    } else {
    }
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
  border-radius: 0px 0px 8px 8px !important;
  overflow: hidden;
  /* background-color: aquamarine; */
  border-bottom: 1px solid #e4e5ea !important;
}
table tr:last-child {
  border-radius: 0px 0px 8px 8px;
}
.border-card {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%), 0 0 3px 0 rgb(0 0 0 / 5%);
  border-radius: 8px;
}
div#menu {
  z-index: 20;
}

tr {
  /* border-bottom: none; */
  border-bottom: 1px solid #e4e5ea !important;
}

tr:last-child {
  border-bottom: none;
}
</style>
