<template>
  <div>
    <div class="opt-1">
      <h3>Security rules</h3>
      <p>Enforce security rules on all users of the {{ brandName }} company.
        <a target="_blank" href="https://support.carefriends.co.uk/articles/5645350-security-settings">Learn more about security rules
        <img src="/images/vector.png"></a></p>
    </div>
    <div class="opt-2"></div>
    <div class="opt-1 opt-3">
      <h3>Multi-factor authentication</h3>
      <div class="switch-label grid-x">
        <label class="switch security-switch" for="mfa_check">
          <input id="mfa_check" class="custom-control-input" type="checkbox" v-model="mfa_check" @change="mfa"/>
          <span class="slider round"></span>
        </label>
        <p class="p-second">Enforce multi-factor authentication via SMS for portal users</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['mfaValue'],
    data() {
      return {
        mfa_check: this.mfaValue
      };
    },
    methods: {
      mfa() {
        axios.post('/dashboard/mfa-checkbox', {value: this.mfa_check})
          .then(function (response) {
            Vue.swal({
              html:
                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> MFA updated',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast"
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s"
              },
              hideClass: {
                popup: "animated fadeOutLeft slow"
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs"
            });
          })
          .catch(function (error) {
            console.log(error);
          })
      }
    }
  };
</script>
