<template>
	<b-modal v-model="modalShow" :title="title" :modal-class="loading ? 'modal--loading' : ''" centered hide-header-close no-close-on-backdrop>
        <slot></slot>

        <template slot="modal-footer" slot-scope="{ cancel }">
            <button class="cancel-button" @click="cancel">Cancel</button>
            <button class="process-button" @click="process">Save</button>
        </template>
    </b-modal>
</template>

<script>
export default {
	name: 'TariffModal',
    props: {
        title: { type: String, default: '' },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            modalShow: false
        }
    },
    methods: {
        show() {
            this.modalShow = true;
        },
        hide() {
            this.modalShow = false;
        },
        process() {
            this.$emit('on-process');
        }
    }
}
</script>

<style scoped lang="scss">
@import "./TariffModal.scss";
</style>