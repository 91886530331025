<template>
    <div v-if="editCandidate" id="add-candidate">
        <!-- <div class="grey_border_box_inner box_shadow"> -->
        <b-modal ref="add-candidate" id="modal-editcan" size="lg"  class="tab-modal" v-model="show" @hide="isShowfalse" :no-close-on-backdrop="true">


            <h3> Edit Candidate - {{data.First_Name}} {{data.Surname}}</h3>
            <div class="grid-y">
                <div class="grid-x right-cut" >
                    <div class="cell auto input-box">
                        <label>Role*</label>
                        <v-select v-if="availableJobs.length"
                                  v-model="data.job_id"
                                  :reduce="referrer => referrer.id"
                                  label="name"
                                  :options="availableJobs"
                                  :clearable="false"
                                  :close-on-select="true"
                        ></v-select>
                        <span class="form-error" id="appliedfor-error">Job Role is required</span>
                    </div>

                </div>

                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                        <label>
                            First name*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. Jane"
                                aria-describedby="example1Hint1"
                                aria-errormessage="firstname-error"
                                required
                                v-model="data.First_Name"
                            />
                            <span class="form-error" id="firstname-error">The candidates first name is required</span>
                        </label>
                    </div>

                    <div class="cell auto input-box">
                        <label>
                            Last Name*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. Smith"
                                aria-describedby="example1Hint1"
                                aria-errormessage="surname-error"
                                required
                                v-model="data.Surname"
                            />
                            <span class="form-error" id="surname-error">The candidates surname is required</span>
                        </label>
                    </div>
                </div>

                <div class="grid-x input-box-2">


                    <div class="cell auto input-box">
                        <label>
                            Phone number*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. 07777 123456"
                                aria-describedby="example1Hint1"
                                aria-errormessage="phone-error"
                                required
                                v-model="data.Phone_Number"
                            />
                            <span
                                class="form-error"
                                id="phone-error"
                            >A contact number is required for the candidate</span>
                        </label>
                    </div>

                    <div class="cell auto input-box">
                        <label>
                            Email*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. jane@email.com"
                                aria-describedby="example1Hint1"
                                aria-errormessage="email-error"
                                required
                                pattern="email"
                                v-model="data.Email"
                            />
                            <span class="form-error" id="email-error">The candidates email address is required</span>
                        </label>
                    </div>

                </div>

                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                        <label>
                            Postcode*
                            <input
                                class=""
                                type="text"
                                placeholder="eg. WR9 8TN"
                                v-model="data.Postcode"
                            />
                        </label>
                    </div>
                    <div class="cell auto input-box"></div>
                </div>
                <div class="grid-x right-cut">
                    <div class="cell auto input-box">
                        <label>Referred by*</label>
                        <v-select
                            v-model="data.userDetails"
                            :reduce="referrer => referrer.userDetails"
                            label="name"
                            :options="filteredAvailableUsers"
                            :close-on-select="true"
                            @search="onSearch"
                            @input="setSelected(data.userDetails)"
                        >
                            <template #selected-option>
                                <div>{{ userName() }}</div>
                            </template>

                            <template #no-options="{ search }">
                                <span v-if="search.length < 3">Type at least 3 characters...</span>
                                <span v-else>Sorry, No Matching Options</span>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="grid-x right-cut">
                    <div class="cell auto input-box">
                        <label>Expressed interest on*</label>
                        <datepicker
                            name="applied-on"
                            v-model="data.created_at"
                            format="dd/MM/yy"
                            input-class=""
                        ></datepicker>
                    </div>
                </div>

                <template v-if="isCareSector">
                    <div class="grid-x input-box-2">
                        <div class="cell auto input-box">
                            <label>New to Care?*</label>
                            <v-select
                                v-model="data.new_to_care"
                                label="name"
                                :options="new_to_care"
                                :reduce="referrer => referrer.id"
                                :close-on-select="true"
                            ></v-select>
                        </div>
                        <div class="cell auto input-box"></div>
                    </div>
                </template>

            </div>
            <div class="cell auto popup-button">
                <input v-if="currentUserId == 0 && data.user_id > 0" type="submit" value="Update" v-b-modal="'confirm-' + data.id"
                       @click="confirmCheck = true" :disabled="btnAddCanidate">
                <input v-else type="submit" value="Update" @click="update()" :disabled="btnAddCanidate">
            </div>
        </b-modal>
        <b-modal v-if="confirmCheck" class="tab-modal" :id="'confirm-' + data.id" size="lg">
            <div class="grid-y">
                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                        <p>Are you sure? Any further updates of this candidate will result in notifications and points
                            being awarded to {{user_name}}.</p>
                    </div>
                </div>
            </div>
            <div class="cell auto popup-button">
                <input class="delete-candidate" type="submit" value="Yes" @click="update()">
                <input type="button" class="button btn_lg btn orange_btn delete-candidate anon-can" value="No"
                       @click="confirmCheck = false">
            </div>
        </b-modal>
    </div>
</template>

<script>
    import Datepicker from "vuejs-datepicker";
    export default {
        components: {
            Datepicker
        },
        props: {
            users: {
                type: Array
            },
            data: {
                type: Object,
            },
            currentuser: {
                type: Object
            },
            editCandidate: {
                type: Boolean
            },
            initalStatus: {
                type: String,
                default: ""
            },
            isCareSector: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                referrerName: "",
                show: true,
                options: this.users,
                new_to_care: [{id: 1, name: 'Yes'}, {id: 2, name: 'No'}, {id: -1, name: 'Unsure'}],
                availableUsers: [],
                checkArr: [],
                availableJobs: [],
                jobs: {},
                currentUserId: '',
                confirmCheck: false,
                user_name: '',
				usertype_email:'',
				usertype_phone_number:'',
                userDetails:'',
                search: '',
            };
        },
        methods: {
            setSelected(value) {
                if (value && this.availableUsers.length > 0) {
                    for (var i = 0; i < this.availableUsers.length; i++) {
                        if (value == this.stringifyUserDetails(this.availableUsers[i])) {
                            this.user_name = this.availableUsers[i].name;
							this.data.usertype_email= this.availableUsers[i].email;
							this.data.user_id= this.availableUsers[i].userid;
							this.data.usertype_phone_number = this.availableUsers[i].phone_number;
							this.data.userDetails = this.stringifyUserDetails(this.availableUsers[i]);
                            break;
                        }
                    }
                }
            },
            confirm() {
                // alert(JSON.stringify(this.data));
                this.save();
                this.$root.$emit("editCandidate", false);
            },
            cancel() {
                this.$parent.savedStatus = "";
                this.$root.$emit("editCandidate", false);
            },

            update() {
                Vue.swal({
                    title: 'Updating...',
                    html: '',
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });
                var self = this;
                this.data.currentUserId = this.currentUserId;
                axios.put('/dashboard/candidates/'+this.data.id, this.data, {
                }).then(function (response) {
				  if(response.data=='duplicate'){
				   Vue.swal({
								title: "Candidate email identified as duplicate",
								confirmButtonColor: '#f55d73',
								confirmButtonText: 'OK',
								html: 'Whoops! That email address is registered to an existing candidate.',

								allowOutsideClick: false,

							});
				  }else{
                    self.confirmCheck = false;
                    self.$parent.editCandidate = false;
                    self.$parent.showDrop = false;
                    self.$root.$emit('updatedCandidates', response.data);
                    Vue.swal({
                        html:
                            '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Candidate updated.',
                        confirmButtonColor: "#5dc2a6",
                        confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                        background: "#5dc2a6",
                        customClass: {
                            container: "candidate-toast"
                        },
                        showClass: {
                            popup: "animated fadeInLeft fast",
                            icon: "animated heartBeat delay-1s"
                        },
                        hideClass: {
                            popup: "animated fadeOutLeft slow"
                        },
                        toast: true,
                        timer: 5000,
                        position: "top-right",
                        target: "#example-tabs"
                    });
                    self.$root.$emit("bv::hide::modal", "modal-editcan");
					}
                    //this.$root.$emit("editCandidate", false);
                    //self.$root.$emit("editCandidate", false);
                    //self.editCandidate = false;

                })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
                /*.finally(function () {
                    Vue.swal({
        html:
          '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Candidate updated.',
        confirmButtonColor: "#5dc2a6",
        confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
        background: "#5dc2a6",
        customClass: {
          container: "candidate-toast"
        },
        showClass: {
          popup: "animated fadeInLeft fast",
          icon: "animated heartBeat delay-1s"
        },
        hideClass: {
          popup: "animated fadeOutLeft slow"
        },
        toast: true,
        timer: 5000,
        position: "top-right",
        target: "#example-tabs"
      });
        self.$root.$emit("bv::hide::modal", "modal-editcan");
        //this.$root.$emit("editCandidate", false);
        //self.$root.$emit("editCandidate", false);
                    //self.editCandidate = false;
                });*/
            },

            isShowfalse(){
                self.$parent.isShow = false;
            },
            onSearch(query) {
                this.search = query
            },
            userName() {
                const user = this.availableUsers.find(availableUser => availableUser.userDetails === this.data.userDetails);

                if (!user) {
                    return '';
                }

                return user.name;
            },
        },
        mounted() {
            var self = this;
            axios
                .get("/dashboard/candidates/get-candidates-jobs")
                .then(function(response) {
                    if (response.data) {
                        self.jobs = JSON.stringify(response.data);
                        //console.log("List of Job "+self.jobs);
                        //console.log("lenght"+response.data.length);
                        //Create array of available jobs for reffer dropdown
                        for (var i = response.data.length - 1; i >= 0; i--) {
                            var job = {
                                name: response.data[i].Job_Title,
                                id: response.data[i].id
                            };
                            self.availableJobs.push(job);
                        }
                    }
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });

            //Create array of available users for reffer dropdown
            for (var i = this.users.length - 1; i >= 0; i--) {
                var stringifiedUserDetails = this.stringifyUserDetails(this.users[i]);
                if (this.users[i].active) {
                    if (this.checkArr.includes(stringifiedUserDetails) == false){
                    var email = "";
                    if(this.users[i].email){
                        var email = " (" + this.users[i].email + ")";
                    }
                    var user = {
                        name: this.users[i].first_name + " " + this.users[i].surname + email,
                        id: this.users[i].id,
                        email: this.users[i].email,
                        userid: this.users[i].id,
                        phone_number: this.users[i].phone_number,
                        userDetails: stringifiedUserDetails
                    };
                    this.checkArr.push(stringifiedUserDetails);
                    this.availableUsers.push(user);
					}
                }
            }
			this.currentUserId = this.data.user_id;
			 for (var i = this.users.length - 1; i >= 0; i--) {
                    if (this.users[i].id == this.data.user_id) {
					   if(this.data.user_type=='authorized' && this.users[i].active =='No invite sent'){
                        this.setSelected(this.stringifyUserDetails(this.users[i]));
						}
						else if(this.data.user_type=='authorized' && this.users[i].active =='Reminder sent'){
                        this.setSelected(this.stringifyUserDetails(this.users[i]));
						}
						else if(this.data.user_type=='authorized' && this.users[i].active =='Invite sent'){
                        this.setSelected(this.stringifyUserDetails(this.users[i]));
						}else if(this.data.user_type=='registered' && this.users[i].active =='1'){
                        this.setSelected(this.stringifyUserDetails(this.users[i]));
						}
                    }
                }
            

        },
        computed: {
            btnAddCanidate() {
                if (
                    this.data.First_Name &&
                    this.data.Surname &&
                    this.data.Email &&
                    this.data.user_id >= 0 &&
                    this.data.job_id &&
                    this.data.Phone_Number &&
                    this.data.newToCare &&
                    this.data.Postcode
                ) {
                    return false;
                } else return true;
            },
            filteredAvailableUsers() {
                if (this.search.length < 3) {
                    return [];
                }

                return this.availableUsers.filter((user) => user.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()));
            },
        }
    };
</script>
