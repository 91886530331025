<template>
  <aside class="sidebar"
         :class="{'sidebar--collapsed': collapsed}"
         :style="cssVariables">
    <div class="sidebar-header">
      <div class="sidebar-inner-wrapper">
        <button type="button"
                @click="toggleSidebar"
                class="toggle-button">
          <icon name="sidebar-toggle" />
        </button>

        <a href="/"
           class="sidebar-logo">
          <img :src="logo"
               :alt="brandName">
        </a>
      </div>
    </div>

    <div class="sidebar-body">
      <nav class="sidebar-menu">
        <div v-for="menuItem in menu"
             :key="menuItem.url"
             class="sidebar-menu__item">
          <a :href="menuItem.url"
             :title="menuItem.title"
             class="sidebar-menu__link"
             :class="{'sidebar-menu__link--active': currentUrl === menuItem.url}">
            <icon :name="menuItem.icon" />
            <span>{{ menuItem.title }}</span>
          </a>
        </div>
      </nav>

      <nav class="sidebar-settings">
        <div class="submenu-wrapper">
          <div v-if="settingsMenu.sub_menu && settingsMenu.sub_menu.length > 0">
            <div class="sidebar-help__item">
              <icon :name="settingsMenu.icon" />
              <span>{{ settingsMenu.title }}</span>
            </div>
            <div class="submenu">
              <div class="submenu__title">{{ settingsMenu.title }}</div>
              <a v-for="subSettingMenu in settingsMenu.sub_menu"
                 :href="subSettingMenu.url"
                 class="submenu__item">
                <icon :name="subSettingMenu.icon" />
                <span>{{ subSettingMenu.title }}</span>
              </a>
            </div>
          </div>
          <a v-else
             :href="settingsMenu.url"
             class="sidebar-help__item">
            <icon :name="settingsMenu.icon" />
            <span>{{ settingsMenu.title }}</span>
          </a>
        </div>
      </nav>

      <nav class="sidebar-help">
        <div class="submenu-wrapper">
          <div class="sidebar-help__item">
            <icon name="help" />
            <span>Help Centre</span>
          </div>

          <div class="submenu">
            <div class="submenu__title">Help Centre</div>

            <a href="https://support.carefriends.co.uk"
               target="_blank"
               class="submenu__item">
              <icon name="knowledge-base" />
              <span>Knowledge base</span>
            </a>

            <div class="submenu__title">In-portal help</div>

            <a id="my_custom_link"
               href="#"
               class="submenu__item">
              <icon name="contact-us" />
              <span>Contact Us</span>
            </a>
          </div>
        </div>
      </nav>
    </div>

    <div class="sidebar-footer">
      <div class="submenu-wrapper">
        <div class="sidebar-user">
          <div class="sidebar-user__avatar">
            <img v-if="user.ImageBase64"
                 :src="`/storage/${user.ImageBase64}`"
                 :alt="userFullName"
                 class="sidebar-user__image">
            <div v-else
                 class="sidebar-user__initials">{{ userInitials }}</div>
          </div>

          <div class="sidebar-user__details">
            <div class="sidebar-user__name">{{ userFullName }}</div>
            <div class="sidebar-user__company">{{ user.customer.name }}</div>
          </div>
        </div>

        <div class="submenu">
          <a href="/dashboard/account/account-settings"
             class="submenu__item">
            <icon name="user-settings" />
            <span>My Account Settings</span>
          </a>

          <a href="/dashboard/account/company-details"
             class="submenu__item">
            <icon name="company-details" />
            <span>Company Details</span>
          </a>

          <form ref="logout-form"
                action="/logout"
                method="POST">
            <input type="hidden"
                   name="_token"
                   :value="csrf">

            <button type="submit"
                    class="submenu__item">
              <icon name="log-out" />
              <span>Log Out</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { Icon } from "../../../components/Icon"
import { cssVariables } from "../../../lib/css-variables";

export default {
  props: {
    user: { type: Object, required: true },
    menu: { type: Array, default: () => [] },
    settingsMenu: { type: Object, default: () => ({}) },
    activeTextColour: { type: String },
    inactiveTextColour: { type: String },
    backgroundColour: { type: String },
    brandLogo: { type: String }
  },
  components: {
    Icon
  },
  data() {
    return {
      csrf: null,
      collapsed: false,
      logo: this.brandLogo || "/img/cf-logo.svg"
    };
  },
  computed: {
    currentUrl() {
      return window.location.pathname;
    },
    userFullName() {
      return `${this.user.first_name} ${this.user.surname}`
    },
    userInitials() {
      return this.user.first_name[0] + this.user.surname[0]
    }
  },
  methods: {
    toggleSidebar() {
      this.collapsed = !this.collapsed;
      localStorage.setItem('collapsedMenu', this.collapsed ? 1 : 0);
    }
  },
  created() {
    this.collapsed = !!Number.parseInt(localStorage.getItem('collapsedMenu'));
    this.csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    this.cssVariables = cssVariables()

    if (this.activeTextColour) {
      this.cssVariables["--activeTextColour"] = this.activeTextColour;
    }

    if (this.inactiveTextColour) {
      this.cssVariables["--inactiveTextColour"] = this.inactiveTextColour;
    }

    if (this.backgroundColour) {
      this.cssVariables["--backgroundColour"] = this.backgroundColour;
    }
  }
};
</script>

<style scoped lang="scss">
@import "./Sidebar.scss";
</style>
