/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require("./vendor/foundation.js");
require("./vendor/what-input.js");

require("./bootstrap");
require("./scripts/customerFileInput");
require("./scripts/pagination");
require("./scripts/page/candidate");

import vSelect from "vue-select";
import VueSweetalert2 from "vue-sweetalert2";
import SortedTablePlugin from "vue-sorted-table";
import Datepicker from "vuejs-datepicker";
import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
import "trix";
import Helpers from "./components/common/Helpers";
//import sitedropdownform from './components/forms/SitedropdownFormComponent';

import "../app/components/PopupBox";

import { initApp } from "../app/init-app";

window.initApp = initApp;
window.moment = require("moment/moment");
window.Vue = require("vue");
Vue.config.ignoredElements = ["trix-editor"];
// window.openInEditor = require('launch-editor-middleware');

/**
 * Import required libraries for country listing
 * for Job - EOI -Form
 */
// import 'intl-tel-input/build/css/intlTelInput.min.css';
// import intlTelInput from "intl-tel-input";
// import telUtils from "intl-tel-input/build/js/utils";

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context("./", true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split("/").pop().split(".")[0], files(key).default));

Vue.component("CfSidebar", require("../app/modules/sidebar").default);
Vue.component("CfIntegrations", require("../app/modules/integrations").default, {
  name: "CfIntegrations",
});
Vue.component("IntegrationData", require("../app/modules/integrations").default, {
  name: "IntegrationData",
});
Vue.component("Tariffs", require("../app/modules/tariffs").default, {
  name: "Tariffs",
});

Vue.component("Templates", require("../app/modules/templates").default, {
  name: "Templates",
});

Vue.component("ScreeningQuestions", require("../app/modules/screening-questions").default, {
  name: "ScreeningQuestions",
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
//Vue.component(sitedropdownform);
Vue.use(require("vue-moment"));
Vue.component("v-select", vSelect);
Vue.component(Datepicker);
Vue.use(SortedTablePlugin);

Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
Vue.mixin(Helpers);
//Vue.mixin(sitedropdownform);

//Initialise country lists in the phone field
/*if($('body').hasClass('job-eoi-form')) {
    const phoneNumberField = document.querySelector("#Phone_Number");
    if(phoneNumberField.length > 0) {
        intlTelInput(phoneNumberField, {
            separateDialCode: true,
            initialCountry: "GB",
            hiddenInput: "full_phone",
            utlisScript: telUtils
        });
    }
}*/

//Fix for datepickers that overflow page
$(document).ready(function () {
  $(".datepicker-here.scroll-fix").click(function () {
    $("html, body").animate({ scrollTop: $(document).height() }, "slow");
  });
  CKEDITOR.config.versionCheck = false;
});
