<template>
  <div>
    <div v-show="showLoad" class="show-load-app">
      <img :src="myImage" />
    </div>
    <div
      class="grid-x grid-padding-x"
      style="margin-top: -78px; position: relative; float: right"
    >
      <div class="cell shrink add-invitee candidate-margin-left">
        <add-payment-request-component modalKeySuffix="history" />
      </div>
    </div>

    <div class="payment-history">
      <div class="cf-table">
        <div class="table-top-area">
          <div class="grid-x">
            <div class="cell auto font_0875 no-padding">
              <div class="grid-x" style="margin-bottom: -10px">
                <div class="cell small-5 pull-left">
                  <div class="total_inline">
                    <div class="tot-num-bonus">
                      {{ historyPaginationData.total }}
                    </div>
                    <div
                      class="total-points-bonus"
                      v-if="historyPaginationData.total == 1"
                    >
                      paid request
                    </div>
                    <div class="total-points-bonus" v-else>paid requests</div>
                  </div>
                  <div class="total_inline">
                    <div class="tot-num-bonus">{{ totalPointsCount }}</div>
                    <div
                      class="total-points-bonus"
                      v-if="totalPointsCount == 1"
                    >
                      Total point
                    </div>
                    <div class="total-points-bonus" v-else>Total points</div>
                  </div>
                </div>
                <div class="cell small-7 pull-right">
                  <div class="cell shrink">
                    <a
                      href="javascript:void(0)"
                      @click="filtertoggle()"
                      class="btn button orange_btn float-right bonus-filter-cls"
                    >
                      FILTERS <img src="/images/filter-icon.png" alt="img"
                    /></a>
                  </div>
                  <div class="cell shrink">
                    <a
                      :href="
                        '/dashboard/points/get-payment-history-filter-export?approvedBy[]=' +
                        approvedById +
                        '&start_date=' +
                        filterStartDate +
                        '&end_date=' +
                        filterEndDate +
                        '&userId[]=' +
                        selectedUsers +
                        '&site[]=' +
                        selectedSite +
                        '&search=' +
                        searchVar +
                        ''
                      "
                      class="btn button orange_btn float-right"
                      >Export</a
                    >
                  </div>
                  <div class="cell shrink">
                    <input
                      class="jobs-search"
                      placeholder="Search "
                      v-model="searchVar"
                      name="searchVar"
                      @keyup.delete="loadPaymentHistoryRequests()"
                      @keyup="searchhistoryPayment()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="showFilter">
            <div class="grid-x filters-wrappers">
              <div class="cell small-2 padright">
                <div class="input-box">
                  <label></label>
                  <date-range-picker
                    v-model="dateRange"
                    id="date-range-filter"
                    ref="dateRangeFilter"
                    opens="right"
                    class="phone-recruiter"
                    @keydown.enter="addTag"
                    @keydown.188="addTag"
                    @select="addTag"
                    @keydown.delete="removeLastTag"
                  >
                    <template #input="picker">
                      <label class="datelabelclass"
                        >Date Requested</label
                      ></template
                    >
                  </date-range-picker>
                </div>
              </div>

              <div v-if="show_sites" class="cell small-2 padright">
                <div class="input-box">
                  <v-select
                    class="bonus-placeholder"
                    id="site-filter"
                    ref="siteFilter"
                    @input="filterSelectedSite"
                    v-model="select_site"
                    :reduce="(referrer) => referrer.id"
                    label="name"
                    :options="availableSites"
                    :close-on-select="true"
                    multiple
                    taggable
                    placeholder="Site"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name }}
                      <img style="float: right" :src="option.image" />
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="cell small-2 padright">
                <div class="input-box">
                  <v-select
                    class="bonus-placeholder"
                    id="users-filter"
                    ref="userFilter"
                    @input="filterSelectedUser"
                    v-model="select_users"
                    :reduce="(referrer) => referrer.id"
                    label="name"
                    :options="allUsers"
                    :close-on-select="true"
                    multiple
                    taggable
                    placeholder="Users"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name }}
                      <img style="float: right" :src="option.image" />
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="cell small-2 padright his-approvedBy">
                <div class="input-box">
                  <v-select
                    class="phone-recruiter"
                    id="approvedby-filter"
                    ref="approvedByFilter"
                    @input="selectedApprovedBy(awarded_by)"
                    v-model="awarded_by"
                    :reduce="(referrer) => referrer.id"
                    label="name"
                    :options="allApprovedByUsers"
                    :close-on-select="true"
                    multiple
                    taggable
                    placeholder="Approved by"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name }}
                      <img style="float: right" :src="option.image" />
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <!--for displaying tags -->
            <div class="grid-x" v-if="selected_date_tags != ''">
              <div class="tag-input__tag">
                <span class="lablesize">{{ selected_date_tags }}</span>
                <span class="deselect-pad"
                  ><button class="deselect" @click="removeDateTags()"></button
                ></span>
              </div>
            </div>
            <div class="grid-x" v-if="selected_sites_tags != ''">
              <div class="tag-input__tag">
                <span class="lablesize">{{ selected_sites_tags }}</span>
                <span class="deselect-pad"
                  ><button class="deselect" @click="removeSiteTags()"></button
                ></span>
              </div>
            </div>
            <div class="grid-x" v-if="select_user_tag != ''">
              <div class="tag-input__tag">
                <span class="lablesize">{{ select_user_tag }}</span>
                <span class="deselect-pad"
                  ><button class="deselect" @click="removeUserTags()"></button
                ></span>
              </div>
            </div>
            <div class="grid-x" v-if="selected_approvedby_tags != ''">
              <div class="tag-input__tag">
                <span class="lablesize">{{ selected_approvedby_tags }}</span>
                <span class="deselect-pad"
                  ><button
                    class="deselect"
                    @click="removeApprovedByTags()"
                  ></button
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-x grid-padding-x cf-table-head align-middle">
          <div class="cell auto" align="center">
            <p class="tb_margin_1"><strong>Requested on:</strong></p>
          </div>
          <div class="cell auto" align="center">
            <p class="tb_margin_1"><strong>Paid on:</strong></p>
          </div>
          <div class="cell auto" align="center">
            <p class="tb_margin_1"><strong>User:</strong></p>
          </div>
          <div class="cell medium-2">
            <p class="tb_margin_1"><strong>Payroll Info:</strong></p>
          </div>
          <div class="cell medium-1">
            <p class="tb_margin_1"><strong>Amount:</strong></p>
          </div>

          <div class="cell medium-2">
            <p class="tb_margin_1"><strong>Approved by:</strong></p>
          </div>
          <div v-if="hasPaidPermission === 1 && revertFeatureFlagEnabled" class="cell medium-1">
          </div>
        </div>
        <div
          v-if="requests && requests.length == 0 && message != ''"
          class="empty-state empty-state-back"
        >
          <img src="/images/icon-empty-state-payment-history.png" />
          <p>{{ message }}</p>
          <br />
        </div>
        <div>
          <div
            v-for="request in requests"
            v-bind:key="request.id"
            class="grid-x grid-padding-x align-middle b_border_b1"
          >
            <div class="cell auto" align="center">
              <p class="tb_margin_1">
                {{ request.created_at | moment("DD/MM/YYYY") }}
              </p>
            </div>
            <div class="cell auto" align="center">
              <p class="tb_margin_1">
                {{ request.updated_at | moment("DD/MM/YYYY") }}
              </p>
            </div>
            <div class="cell auto" align="center">
              <p class="tb_margin_1">
                {{ request.user.first_name }} {{ request.user.surname }}
              </p>
            </div>
            <div class="cell medium-2">
              <p class="tb_margin_1">{{ request.user.PaymentInfo }}</p>
            </div>
            <div class="cell medium-1">
              <p class="tb_margin_1">
                <strong>{{ request.amount }}</strong>
              </p>
            </div>
            <div class="cell medium-2">
              <p class="tb_margin_1">
                {{ request.approved_by_user.first_name }}
                {{ request.approved_by_user.surname }}
              </p>
            </div>
            <div v-if="hasPaidPermission === 1 && revertFeatureFlagEnabled" class="cell medium-1">
              <div v-if="request.isLatest" class="tb_margin_1" @click="undoPaidRequest(request.id)">
                <icon name="undo" />
              </div>
            </div>
          </div>

          <div class="pagination-container margin-top-2">
            <template v-if="historyPaginationData.last_page > 1">
              <paginate
                :page-count="historyPaginationData.last_page"
                :page-range="3"
                :margin-pages="2"
                :click-handler="loadPaymentHistoryRequests"
                :prev-text="'<'"
                :next-text="'>'"
                :prev-class="'paginate-controls'"
                :next-class="'paginate-controls'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :active-class="'active'"
              >
              </paginate>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Paginate from "vuejs-paginate";
import DateRangePicker from "vue2-daterange-picker";
import { Icon } from "../../../app/components/Icon"
import eventBus from '../../utils/event-bus.js';


export default {
  components: {
    paginate: Paginate,
    DateRangePicker: DateRangePicker,
    Icon,
    eventBus,
  },
  props: ["all_usersdata", "approved_by", "all_sites", "hasPaidPermission", "revertFeatureFlagEnabled"],
  data() {
    return {
      requests: this.requests,

      // Handling tabs pagination in Vuejs way
      historyPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      dateRange: {
        startDate: "",
        endDate: "",
      },
      tags: [],
      availableSites: [],
      allUsers: [],
      allApprovedByUsers: [],
      approvedById: [],
      showFilter: false,
      select_site: "",
      selected_sites_tags: "",
      select_users: "",
      select_user_tag: "",
      selected_date_tags: "",
      selectedSite: [],
      siteCount: 0,
      UsersCount: 0,
      selectedUsers: [],
      totalPointsCount: 0,
      loggedUserSiteId: null,
      userCount: 0,
      awarded_by: "",
      awarded_by_users: "",
      selected_approvedby_tags: "",
      filterStartDate: "",
      filterEndDate: "",
      sites: this.all_sites,
      all_users: this.all_usersdata,
      approved_by_users: this.approved_by,
      searchVar: "",
      showLoad: false,
      myImage: "/images/loading-circle.gif",
      message: "",
      show_sites: false,
    };
  },
  methods: {
    /**
     * Send AJAX request to get the payment history
     * @param page
     */
    removeDateTags() {
      this.removeTag("reset");
      //this.selected_date_tags = '';
    },
    filtertoggle() {
      if (this.showFilter == true) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
    },
    addTag(event) {
      //alert(event.endDate);

      $(".cancelBtn").trigger("click");
      // alert(event.startDate.toLocaleDateString());
      var newstartDate = event.startDate.getDate();
      var newstartMonth = event.startDate.getMonth() + 1;
      var newstartYear = event.startDate.getFullYear();
      //var FinalStartDate = newstartYear+"-"+newstartMonth+"-"+newstartDate;
      var FinalStartDate =
        newstartDate + "/" + newstartMonth + "/" + newstartYear;
      var newendDate = event.endDate.getDate();
      var newendMonth = event.endDate.getMonth() + 1;
      var newendYear = event.endDate.getFullYear();
      //var FinalEndDate = newendYear+"-"+newendMonth+"-"+newendDate;
      var FinalEndDate = newendDate + "/" + newendMonth + "/" + newendYear;

      var Difference_In_Days =
        (event.endDate - event.startDate) / (1000 * 3600 * 24);

      this.filterStartDate = FinalStartDate;
      this.filterEndDate = FinalEndDate;
      var d = new Date();
      var currentDate = d.getDate();
      var currentMonth = d.getMonth() + 1;
      var currentYear = d.getFullYear();
      // this method evaulate 'This Month' or 'Last Month' conditions.
      let monthSpan = this.evaluateMonthSpan(
        newstartMonth,
        Difference_In_Days,
        newstartDate,
        newstartYear
      );

      if (FinalStartDate == FinalEndDate) {
        var todayDate = currentDate + "/" + currentMonth + "/" + currentYear;
        var yesDate = currentDate - 1 + "/" + currentMonth + "/" + currentYear;

        if (FinalStartDate == todayDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Today  ");
        } else if (FinalStartDate == yesDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Yesterday ");
        } else {
          this.removeTag(this.tags.length - 1);
          this.tags.push(FinalStartDate + " to " + FinalEndDate);
        }
      } else if (Difference_In_Days > 1 && Difference_In_Days < 28) {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      } else if (
        currentMonth == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This Month ");
      } else if (
        currentMonth - 1 == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("Last Month ");
      } else if (currentYear && Difference_In_Days == 365) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This year ");
      } else {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      }
      this.selected_date_tags = "Date: " + this.tags[0];

      this.loadPaymentHistoryRequests(
        this.getParamsFromUrl("payment_history_page", window.location.href)
      );
    },
    removeTag(index) {
      if (index === "reset") {
        this.filterStartDate = "";
        this.filterEndDate = "";
      }
      this.selected_date_tags = "";
      this.tags.splice(index, 1);
      this.loadPaymentHistoryRequests(
        this.getParamsFromUrl("payment_history_page", window.location.href)
      );
    },
    removeLastTag(event) {
      console.log("866 - removeLastTag: ", event.target);
      if (event.target.value.length === 0) {
        this.filterStartDate = "";
        this.filterEndDate = "";
        this.removeTag(this.tags.length - 1);
        this.loadPaymentHistoryRequests(
          this.getParamsFromUrl("payment_history_page", window.location.href)
        );
      }
    },
    filterSelectedSite(value) {
      this.selectedSite = value;

      if (
        this.siteCount > 0 &&
        this.selectedSite.length > 0 &&
        this.selectedSite.length < this.siteCount
      ) {
        if (this.selectedSite.length % 2 == 0) {
          this.val -= 4;
        }
        this.siteCount = this.selectedSite.length;
      } else if (this.selectedSite.length == 0) {
        this.val = 0;
        this.siteCount = 0;
      } else if (this.selectedSite) {
        if (this.selectedSite.length % 2 == 0) {
          this.val += 4;
        }
        this.siteCount = this.selectedSite.length;
      }

      let selected_sites = [];
      let i;
      for (i = 0; i < this.availableSites.length; ++i) {
        if ($.inArray(this.availableSites[i].id, this.selectedSite) !== -1) {
          this.availableSites[i].image = "/images/selected.png";
          selected_sites.push(this.availableSites[i].name);
        }
      }
      if (selected_sites.length > 0) {
        this.selected_sites_tags = "Sites: " + selected_sites.join(", ");
      }

      this.loadPaymentHistoryRequests(
        this.getParamsFromUrl("payment_history_page", window.location.href)
      );
    },
    removeSiteTags() {
      this.$refs.siteFilter.clearSelection();
      this.selectedSite = "";
      this.selected_sites_tags = "";
      if (this.availableSites) {
        for (i = 0; i < this.availableSites.length; i++) {
          if (this.availableSites[i].image == "/images/selected.png") {
            this.availableSites[i].image = "/images/idle.png";
          }
        }
      }
    },
    filterSelectedUser(value) {
      this.selectedUsers = value;

      if (
        this.UsersCount > 0 &&
        this.selectedUsers.length > 0 &&
        this.selectedUsers.length < this.UsersCount
      ) {
        if (this.selectedUsers.length % 2 == 0) {
          this.val -= 4;
        }
        this.UsersCount = this.selectedUsers.length;
      } else if (this.selectedUsers.length == 0) {
        this.val = 0;
        this.UsersCount = 0;
      } else if (this.selectedUsers) {
        if (this.selectedUsers.length % 2 == 0) {
          this.val += 4;
        }
        this.UsersCount = this.selectedUsers.length;
      }

      let selected_users = [];
      let i;
      for (i = 0; i < this.allUsers.length; ++i) {
        if ($.inArray(this.allUsers[i].id, this.selectedUsers) !== -1) {
          this.allUsers[i].image = "/images/selected.png";
          selected_users.push(this.allUsers[i].name);
        }
      }

      if (selected_users.length > 0) {
        this.select_user_tag = "Users: " + selected_users.join(", ");
      }

      this.loadPaymentHistoryRequests(
        this.getParamsFromUrl("payment_history_page", window.location.href)
      );
    },
    removeUserTags() {
      this.$refs.userFilter.clearSelection();
      this.selectedUsers = "";
      this.select_user_tag = "";
      if (this.allUsers) {
        for (i = 0; i < this.allUsers.length; i++) {
          if (this.allUsers[i].image == "/images/selected.png") {
            this.allUsers[i].image = "/images/idle.png";
          }
        }
      }
    },
    evaluateMonthSpan: function (
      newstartMonth,
      Difference_In_Days,
      newstartDate,
      newstartYear
    ) {
      // Transform below code to find total days in month.
      let monthTotalDays = [
        31, // Jan
        28, // Feb
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // Aug
        30, // Sep
        31, // Oct
        30, // Nov
        31, // Dec
      ];

      let monthIndex = newstartMonth - 1;
      let monthDays = monthTotalDays[monthIndex];
      let totalSelectedSpan = Difference_In_Days + 1; // catering previous dev coded result.

      // February Case
      if (newstartMonth == 2) {
        // When it is leap year like 2020, Feb would be off 29
        if (
          newstartYear % 4 == 0 &&
          totalSelectedSpan == 29 &&
          newstartDate == 1
        ) {
          return true;
        } else if (
          newstartYear % 4 != 0 &&
          totalSelectedSpan == 28 &&
          newstartDate == 1
        ) {
          return true;
        }
        return false;
      } else {
        // Non February Cases
        if (totalSelectedSpan == monthDays && newstartDate == 1) {
          return true;
        }
        return false;
      }
    },
    loadPaymentHistoryRequests(page) {
      this.requests = [];
      let self = this;
      //self.showLoad = true;
      axios
        .post("/dashboard/points/get-payment-history", {
          payment_history_page: page,
          siteId: $("#selected-site").val(),
          filter_site_id: this.selectedSite,
          approved_by_ids: this.approvedById,
          filter_user_id: this.selectedUsers,
          start_date: this.filterStartDate,
          end_date: this.filterEndDate,
          search: this.searchVar,
        })
        .then((response) => {
          this.show_sites = response.data.show_sites;
          if (Object.keys(response.data.paymentHistoryRequests).length > 0) {
            self.showLoad = false;

            this.requests = response.data.paymentHistoryRequests;
            this.pagination = response.data.pagination;
            this.totalPointsCount = response.data.total_points;
            this.loggedUserSiteId = response.data.loggedUserSiteId;

            if (response.data.hasOwnProperty("pagination")) {
              let paginationArr = response.data.pagination;
              this.historyPaginationData.current_page =
                paginationArr.current_page;
              this.historyPaginationData.from = paginationArr.from;
              this.historyPaginationData.last_page = paginationArr.last_page;
              this.historyPaginationData.per_page = paginationArr.per_page;
              this.historyPaginationData.to = paginationArr.to;
              this.historyPaginationData.total = paginationArr.total;
            }
          } else {
            if (
              this.selectedSite.length > 0 ||
              this.approvedById.length > 0 ||
              this.selectedUsers.length > 0 ||
              this.filterStartDate.length > 0 ||
              this.filterEndDate.length > 0 ||
              this.searchVar.length > 0
            ) {
              this.message =
                "No payments match your current criteria. Please amend your filters/search and try again.";
            } else {
              this.message =
                "You don't have any payment requests that have been paid yet.";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAvailableSites() {
      //Create array of available users for dropdown
      var toProcess = Object.keys(this.sites);
      var site = {
        id: -1,
        name: "All Sites",
        image: "/images/idle.png",
      };
      this.availableSites.push(site);
      for (var i = toProcess.length - 1; i >= 0; i--) {
        var site = {
          name: this.sites[toProcess[i]].name,
          id: this.sites[toProcess[i]].id,
          image: "/images/idle.png",
        };
        this.availableSites.push(site);
      }
    },
    getAllUsers() {
      var toProcess = Object.keys(this.all_users);
      for (var i = toProcess.length - 1; i >= 0; i--) {
        var user = {
          name:
            this.all_users[toProcess[i]].first_name +
            " " +
            this.all_users[toProcess[i]].surname,
          id: this.all_users[toProcess[i]].id,
          image: "/images/idle.png",
        };

        this.allUsers.push(user);
      }
    },
    getAllApprovedByUsers() {
      var toProcess = Object.keys(this.approved_by_users);

      for (var i = toProcess.length - 1; i >= 0; i--) {
        var user = {
          name:
            this.approved_by_users[toProcess[i]].first_name +
            " " +
            this.approved_by_users[toProcess[i]].surname,
          id: this.approved_by_users[toProcess[i]].id,
          image: "/images/idle.png",
        };
        this.allApprovedByUsers.push(user);
      }
    },
    selectedApprovedBy(value) {
      this.approvedById = value;
      if (
        this.userCount > 0 &&
        this.approvedById.length > 0 &&
        this.approvedById.length < this.userCount
      ) {
        if (this.approvedById.length % 2 == 0) {
          this.val -= 4;
        }
        this.userCount = this.approvedById.length;
      } else if (this.approvedById.length == 0) {
        this.val = 0;
        this.userCount = 0;
      } else if (this.approvedById) {
        if (this.approvedById.length % 2 == 0) {
          this.val += 4;
        }
        this.userCount = this.approvedById.length;
      }

      /**
       * Code to managed selected tags for Awarded To
       */
      let selected_approvedby = [];
      let i;
      for (i = 0; i < this.allApprovedByUsers.length; ++i) {
        if (
          $.inArray(this.allApprovedByUsers[i].id, this.approvedById) !== -1
        ) {
          this.allApprovedByUsers[i].image = "/images/selected.png";
          selected_approvedby.push(this.allApprovedByUsers[i].name);
        }
      }
      if (selected_approvedby.length > 0) {
        this.selected_approvedby_tags =
          "Approved By: " + selected_approvedby.join(", ");
      }

      /**
       * Ends Code to managed selected tags for Awarded To
       */

      this.loadPaymentHistoryRequests(
        this.getParamsFromUrl("payment_history_page", window.location.href)
      );
    },
    removeApprovedByTags() {
      this.$refs.approvedByFilter.clearSelection();
      this.selected_approvedby_tags = "";
      if (this.allApprovedByUsers) {
        for (i = 0; i < this.allApprovedByUsers.length; i++) {
          if (this.allApprovedByUsers[i].image == "/images/selected.png") {
            this.allApprovedByUsers[i].image = "/images/idle.png";
          }
        }
      }
    },
    /**
     * Get specific parameter from the url
     * @param name
     * @param url
     * @returns {*}
     */
    getParamsFromUrl(name, url) {
      if (!url) url = location.href;
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      const regexS = "[\\?&]" + name + "=([^&#]*)";
      let regex = new RegExp(regexS);
      const results = regex.exec(url);
      return results == null ? null : results[1];
    },
    getCSV() {
      const url = "/dashboard/points/get-payment-history-export";
      const method = "GET";
      axios({
        url,
        method,
        responseType: "blob",
      }).then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payment-history.xlsx");
        document.body.appendChild(link);
        link.click();

        Vue.swal({
          html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Export complete.',
          confirmButtonColor: "#5dc2a6",
          confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
          background: "#5dc2a6",
          customClass: {
            container: "points-toast",
          },
          showClass: {
            popup: "animated fadeInLeft fast",
            icon: "animated heartBeat delay-1s",
          },
          hideClass: {
            popup: "animated fadeOutLeft slow",
          },
          toast: true,
          timer: 5000,
          position: "top-right",
          target: "#edit-company",
        });
      });
    },
    getAllSites() {
      var self = this;
      axios.get("/dashboard/points/get-all-sites").then(function (response) {
        self.sites = response.data.sites;
        self.availableSites = response.data.sites;
      });
    },
    allUsersData() {
      var self = this;
      axios.get("/dashboard/points/all-users").then(function (response) {
        self.all_users = response.data.all_users;

        self.approved_by_users = response.data.approved_by_users;
      });
    },
    searchhistoryPayment() {
      if (this.searchVar.length > 2) {
        this.loadPaymentHistoryRequests(
          this.getParamsFromUrl("payment_history_page", window.location.href)
        );
      }
    },
    undoPaidRequest(paymentRequestId) {
      const self = this;
      this.$swal({
        title: "Are you sure?",
        text: "This will move the request back into the request tab where it can be rejected or marked as paid at a later date. The points will be re-added to the user's account.",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#F55D73",
        cancelButtonColor: "#EEEFF7",
        confirmButtonText: "Continue",
        customClass: {
          container: "undo-payment-request",
          cancelButton: "button orange_btn",
          confirmButton: "button pink_btn",
          closeButton: "close-button",
          actions: "reverse-actions",
          popup: "swal-wide",
        },
        onOpen: function () {
          var closeButton = document.querySelector(".close-button");
          closeButton.addEventListener("click", function () {
            Swal.close();
          });
        },
      }).then(result => {
        if (result.value) {
          if (paymentRequestId) {
            axios
              .post("paymentRequest/undoPaidRequest/" + paymentRequestId, paymentRequestId)
              .then(function (response) {
                self.loadPaymentHistoryRequests();
                eventBus.$emit('undo-clicked');
                Vue.swal({
                  html: `<div><span class="success-circle"><i class="fas fa-check-circle"></i></span> ${response.data.message} </div>`,
                  confirmButtonColor: "#5dc2a6",
                  confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                  background: "#5dc2a6",
                  showClass: {
                    popup: "animated fadeInLeft fast",
                    icon: "animated heartBeat delay-1s",
                  },
                  hideClass: {
                    popup: "animated fadeOutLeft slow",
                  },
                  toast: true,
                  timer: 5000,
                  position: "top-right",
                  target: "#example-tabs",
                });
              })
              .catch(function (error) {
                Vue.swal({
                  title: "Error!",
                  text: error.response.data.error,
                  confirmButtonColor: "#F55D73",
                });
              });
          }
        }
      });
    },
  },
  mounted() {
    //Fetch the requested payments
    this.loadPaymentHistoryRequests(
      this.getParamsFromUrl("payment_history_page", window.location.href)
    );
    this.getAvailableSites();
    this.getAllUsers();
    this.getAllApprovedByUsers();
    //this.getAllSites();
    //this.allUsersData();
    eventBus.$on('paymentRequestChanges', (updatedData) => {
      if(updatedData === 'yes') {
        this.loadPaymentHistoryRequests(
          this.getParamsFromUrl("payment_history_page", window.location.href)
        );
      }
    });
  },
};
</script>

<style>
#payment-history .table-top-area {
  padding: 10px 20px;
}
#payment-history
  .grid-x.filters-wrappers
  .cell:first-child
  .vue-daterange-picker.phone-recruiter {
  min-width: 100% !important;
  width: 100%;
}
#payment-history
  .grid-x.filters-wrappers
  .cell:first-child
  .vue-daterange-picker.phone-recruiter
  .form-control.reportrange-text {
  max-width: 100%;
  border: 1px solid #dcdee6 !important;
}
#payment-history .filters-wrappers .cell:first-child {
  max-width: 150px;
  margin-right: 0px;
}
#payment-history
  .filters-wrappers
  .cell:first-child
  .form-control.reportrange-text {
  max-width: 140px;
}
#payment-history .vs__search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #85899e;
}
#payment-history .vs__search::-moz-placeholder {
  /* Firefox 19+ */
  color: #85899e;
}
#payment-history .vs__search:-ms-input-placeholder {
  /* IE 10+ */
  color: #85899e;
}
#payment-history .vs__search:-moz-placeholder {
  /* Firefox 18- */
  color: #85899e;
}
#payment-history .cf-table tbody tr td {
  padding: 8px 0px !important;
}
#payment-history .daterangepicker .calendar-table td,
#payment-history .daterangepicker .calendar-table th {
  width: auto;
  height: auto;
  min-width: auto;
}
#payment-history .cf-table thead th {
  padding: 3px !important;
  background: #fff !important;
}
#payment-history .cf-table th.month {
  font-size: 14px !important;
  text-transform: initial !important;
}
#payment-history .filters-wrappers .cell:nth-child(2) {
  max-width: unset;
}
#payment-history .tag-input__tag {
  z-index: 1;
}
#payment-history .vs__selected {
  display: none;
}
#payment-history .datelabelclass {
  color: #85899e !important;
}
.payment-history .cf-table {
  margin-top: 0px;
}
.margin-set {
  margin-bottom: 1%;
  height: 44px;
}
.his-approvedBy {
  max-width: 152px !important;
}
.payment-history .jobs-search {
  height: 40px;
  width: 280px;
  padding: 6px 6px 6px 38px;
  border: 1px solid #dcdee6;
  border-radius: 3px;
  color: #9297ae;
  font: 400 14px InterUI, sans-serif;
  background: #fff url(/images/search.png) no-repeat 7px;
  margin-right: 12px;
  float: right;
}
.payment-history .total_inline {
  float: left;
  width: 40%;
}
.payment-history .total-points-bonus {
  font-family: Helvetica !important;
}
.undo-payment-request .swal-wide {
  width: 50%;
}
.undo-payment-request .swal2-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.5rem;
  flex-direction: row;
}
.undo-payment-request .swal2-cancel {
 order: 1;
 float: right;
 margin-left: 0.5rem;
}
.undo-payment-request .swal2-confirm {
  order: 2;
  float: right;
}
.undo-payment-request .swal2-close {
  margin: 10px;
}
.undo-payment-request .swal2-popup {
  border-radius: 12px;
}
</style>
