<template>
	<button type="button" class="add-tarif-button">
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_2192_987)">
				<path d="M18.4426 13H13.4426V18C13.4426 18.55 12.9926 19 12.4426 19C11.8926 19 11.4426 18.55 11.4426 18V13H6.44263C5.89263 13 5.44263 12.55 5.44263 12C5.44263 11.45 5.89263 11 6.44263 11H11.4426V6C11.4426 5.45 11.8926 5 12.4426 5C12.9926 5 13.4426 5.45 13.4426 6V11H18.4426C18.9926 11 19.4426 11.45 19.4426 12C19.4426 12.55 18.9926 13 18.4426 13Z" fill="white"/>
			</g>
			<defs>
				<clipPath id="clip0_2192_987">
					<rect width="24" height="24" fill="white" transform="translate(0.442627)"/>
				</clipPath>
			</defs>
		</svg>

		<span>Add Tariff</span>
	</button>
</template>

<script>
export default {
	name: 'AddTariffButton',
}
</script>

<style lang="scss" scoped>
.add-tarif-button {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	background: #5DC2A6;
	transition: all .3s ease-in-out;
	border-radius: 24px;
	cursor: pointer;
	padding: 12px 24px;
	margin-left: 15px;

	&:hover {
		background: darken(#5DC2A6, 10%);
	}

	span {
		font-family: var(--fontFamily);
		font-size: 16px;
		line-height: 1.5;
		font-weight: 700;
		color: #fff;
		padding-left: 8px;
	}
}
</style>