<template>
    <div>
        <v-select v-model="Repeat" id="send-to-action" required :reduce="referrer => referrer.id" label="name"
                  :options="senttooptions"></v-select>
        <input type="hidden" id="send-to-action" name="Repeat" :value="Repeat">
    </div>
</template>

<script>
    export default {
        data() {
            return {
			senttooptions: [{id: 'no repeat', name: 'Repeat OFF'}, {id: 'daily', name: 'Daily'}, {id: 'weekly', name: 'Weekly'}, {id: 'monthly', name: 'Monthly'}],
                Repeat: 'no repeat'
            }
        },
        watch: {
            Repeat: function (val) {			
                this.$emit('selectedRepeat', this.Repeat);				
            }
        }
    }
</script>
