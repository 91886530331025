<template>
  <div>

    <!-- <div class="layout-container scheduled-main-div"> -->
    <div class="Scheduled-layout layout-item">
      <!-- <div class="layout-item layout-border-styling"> -->
      <div class="scheduled-notification-datepicker">
        <div id="calendar-wrap"
             class="test123">
          <div class="month-div">
            <div class="month-div-styling">
              {{ this.stringMonth }} {{ this.currentYear }}
            </div>
            <div class="arrow-div">
              <span v-if="this.isCalendarMonthSwitchDisabled">
                <i class="fa fa-spinner fa-pulse fa-lg fa-fw red"></i>
              </span>
              <span class="date-picker-arrow" v-bind:class="calendarMonthSwitchDisabled" @click="prevMonth">
                <img src="/images/icon-24-arrow-left.png" alt="" />
              </span>
              <span class="date-picker-arrow" v-bind:class="calendarMonthSwitchDisabled" @click="nextMonth">
                <img src="/images/icon-24-arrow-right.png" alt="" />
              </span>
            </div>
          </div>
          <table class="date-picker-scheduled">
            <tr class="month-Row">
              <td>Mon</td>
              <td>Tue</td>
              <td>Wed</td>
              <td>Thu</td>
              <td>Fri</td>
              <td>Sat</td>
              <td>Sun</td>
            </tr>

            <tr v-for="(row, rowIndex) in this.calendarMonth">
              <td v-for="(col, columnIndex) in row"
                  @click="onSelectDate(col.date, rowIndex, columnIndex)">
                <div class="date-div">
                  <span class="date-span"
                        :class="[
                      {
                        Today: col.isToday && col.value > 9 ? true : false,
                        today_sec: col.isToday && col.value < 10 ? true : false,
                        // select: col.isSelected ? true : false,
                      },
                      col.class,
                    ]">{{ col.value }}</span>
                  <span class="cont-scheduled"
                        v-if="col.count">{{
                    col.count
                    }}</span>
                </div>
              </td>
            </tr>
          </table>

          <div class="Scheduled-draft-sent-div">
            <span class="dot"></span><span class="sent-scheduled">Sent</span>
            <span class="dot1"></span><span class="draft-scheduled">Draft</span>
            <span class="dot2"></span><span class="scheduled-scheduled">Scheduled</span>
          </div>
        </div>
      </div>
      <div class="row fixed-div-height"
           v-if="notificationsLength == 0">
        <div class="No-notication">
          <div>
            <div>
              <img class="icon-notification"
                   src="/images/icon-24-time.png"
                   alt="" />
              <p class="No-Notification-text">
                {{ currentDate }}
              </p>
            </div>

            <p class="Create-notification"
               v-b-modal.modal-notification>
              Create notification
            </p>
          </div>
        </div>
      </div>
      <div v-else
           class="layout-item mobile-history">
        <!-- no notification -->

        <div class="fixed-div-height">
          <div v-for="(notification, index) in this.notifications">
            <div class="row row-border-styling">
              <div :class="{
                  column12: notification.notification_status == 'Scheduled',
                  'column12-full':
                    notification.notification_status != 'Scheduled',
                }">
                <h1 style="display: inline"
                    class="title-notification-history"
                    v-html="notification.Title"></h1>
                <span class="Drafted-notification"
                      v-if="notification.notification_status == 'Drafted'">
                  Drafted
                </span>
                <span class="Scheduled-notification"
                      v-else-if="notification.notification_status == 'Scheduled'">
                  Scheduled
                </span>
                <span class="Send-notification"
                      v-else> Sent </span>
              </div>
              <div class="column13"
                   v-if="notification.notification_status == 'Scheduled' && canManageNotification(notification)">
                <span class="notification-icon-div">
                  <img class="img-icon"
                       src="/images/icon-24-edit-02@3x.png"
                       alt=""
                       @click="editNotification(notification.id, index)" />
                  <button type="button"
                          :id="'d' + index"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          @click="showMenu()">
                    <img class="img-icon"
                         src="/images/icon-24-delete.png"
                         alt=""
                         @click="
                        showDeleteNotificationModal(notification.id, index, notification.repeat_notification_id)
                      " />
                  </button>

                </span>
              </div>
            </div>
            <div class="row row-border-styling-details">
              <div class="column1">
                <div class="Scheduled-text-icon">
                  <div style="min-width: 30px">
                    <img class="img-icon-24-time"
                         src="/images/icon-24-time.png"
                         alt="" />
                  </div>
                  <div class="text-span-styling">
                    {{ getHumanDate(notification.start_date) }}
                    Repeats {{ notification.Repeat }}
                  </div>
                </div>

                <div class="Scheduled-text-icon">
                  <div style="min-width: 30px">
                    <img class="img-icon-24-time"
                         src="/images/icon-24-profile-round.png"
                         alt="" />
                  </div>
                  <div class="text-span-styling">
                    {{ notification.first_name }}
                    {{ notification.surname }}
                  </div>
                </div>
              </div>
              <div class="column2">
                <img class="img-mobile-notification"
                     src="/images/notification-preview-image.png"
                     alt="" />
                <div class="scheduled-mobile-title">
                  <div class="mobile-notification-title-scheduled">
                    <div class="mobile-notification-branding-table line-clamp-title">
                      <img v-if="brand.is_care_sector"
                           src="/images/cf-favicon.png" />
                      <img v-else
                           src="/images/workfriends-logomark-2000px-full-colour.png" />
                      <p>
                        {{ brand.name }}
                      </p>
                    </div>
                    <p class="
                        mobile-notification-title-table
                        hidden-title
                        line-clamp-title
                      "
                       v-html="String(notification.Title).trim()"></p>
                    <p class="mobile-notification-paragraph-scheduled line-clamp"
                       v-html="notification.Message"></p>
                  </div>
                </div>
              </div>
              <div>
              </div>
              <hr class="hr-row-styling"
                  v-if="notificationsLength - 1 != index" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sec part -->
    <!-- </div> -->

    <div class="pagination-container margin-top-2 test-2">
      <template v-if="historyPaginationData.last_page > 1">
        <paginate :page-count="historyPaginationData.last_page"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="paginatorClickCallback"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :prev-class="'paginate-controls'"
                  :next-class="'paginate-controls'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  :active-class="'active'">
        </paginate>
      </template>
    </div>

    <edit-notifications-component-modal :templates="this.templates"
                                        :sitesarr="this.sitesarr"
                                        :current-object="this.currentObject"
                                        :show_Sites="show_sites"
                                        :can_notify_all_users="can_notify_all_users"
                                        :brand="brand" />
    <b-modal ref="add-job"
             id="edit-scheduled-notification"
             size="xl"
             :no-close-on-backdrop="true">
      <section class="multi-step-row">
        <div class="pop-up-box">
          <div class="text-box"
               id="text-box">
            <fieldset>
              <div class="job-leftside">
                <form>
                  <div class="form-group">
                    <p class="Edit-notification">Edit notification</p>
                    <label for="exampleInputEmail"
                           class="exampleInputEmailtitle">Title *</label>
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            v-model="currentObject.Title"
                                            aria-errormessage="notificationTitle"
                                        /> -->
                    <ckeditor class="form-control text-area-border styling-bottom"
                              placeholder="Add Wording"
                              id="exampleFormControlTextarea1"
                              name="Message"
                              rows="5"
                              v-model="currentObject.Title"
                              :config="titleEditorConfig"></ckeditor>
                    <span class="form-error"
                          id="notificationTitle">Please enter a notification title</span>
                  </div>

                  <div class="form-group">
                    <label class="wording"
                           for="exampleFormControlTextarea1">WORDING *</label>
                    <!-- <div class="text-area-header">
                                          <img
                                              src="/images/icon-text-list-bullet-copy.png"
                                              alt=""
                                          />
                                          <img src="/images/icon-24-undo2.png" alt="" />
                                          <img src="/images/icon-24-redo.png" alt="" />

                                      </div> -->
                    <!-- <textarea
                                          class="form-control text-area-border"
                                          placeholder="Add Wording"
                                          id="exampleFormControlTextarea1"
                                          name="Message"
                                          rows="5"
                                          v-model="currentObject.Message"
                                      >
                              </textarea> -->

                    <ckeditor class="form-control text-area-border"
                              placeholder="Add Wording"
                              id="exampleFormControlTextarea1"
                              name="Message"
                              rows="5"
                              v-model="currentObject.Message"
                              :config="editorConfig"></ckeditor>
                  </div>
                  <!-- <div class="imgae-Modal-styling">
                                      <p class="img-Content-styling">Image</p>
                                  </div> -->
                  <!-- <label class="Image-label-content"></label>
                                  <input type="file" id="actual-btn" hidden />
                                  <label for="actual-btn" class="upload-file-styling">
                                      <img class="img-upload-btn" src="/images/Capture.PNG" />
                                      <span
                                      >Drag and drop an image or browse to choose a file.Min
                      size 180 x 180 px</span
                                      >
                                  </label> -->
                </form>
              </div>
              <div class="job-rightside">
                <div class="frame">
                  <img class="img-mobile-notification edit-mobile-image"
                       src="/images/component-notifications-preview.png"
                       alt="" />
                  <div class="mobile-notification-div">
                    <div class="mobile-notification-title">
                      <p class="
                          mobile-notification-title2
                          hidden-title
                          title
                        "
                         v-html="currentObject.Title"></p>
                      <p class="mobile-notification-paragraph2 line-clamp"
                         v-html="currentObject.Message"></p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="cell auto popup-button pad-top edit-btn-styling">
                <button @click.prevent="closeModal"
                        class="Cancel button btn">
                  Cancel
                </button>
                <input @click="save"
                       type="button"
                       name="Edit"
                       class="next action-button button btn_lg btn pink_btn"
                       value="Edit" />
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>
    <b-modal class="tab-modal"
             id="modal_delete"
             size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <div class="discard-header4">
              <h3 class="discard-header-title">Delete recurring notification</h3>
            </div>

            <div class="form-group input-sendto">
              <div class="radio-btn-input">
                <input type="radio"
                       id="currentevent"
                       value="currentevent"
                       v-model="delevents"
                       v-bind:style="[
                            delevents == 'currentevent'
                            ? { border: '1px solid #5dc2a6 !important' }
                             : { border: '1px solid #D1D3E0 !important' },
                        ]" />
               
                <label for="currentevent"
                       class="following">This event</label><br />
               
                <input v-if="currentObject.Repeat != 'no repeat'" type="radio"
                       id="following"
                       value="following"
                       v-model="delevents"
                       v-bind:style="[
                              delevents == 'following'
                                ? { border: '1px solid #5dc2a6 !important' }
                                : { border: '1px solid #D1D3E0 !important' },
                            ]" />
                <label v-if="currentObject.Repeat != 'no repeat'" for="following"
                       class="following">This and following events</label><br />
                <!-- <input type="radio"
                       id="allevents"
                       value="allevents"
                       v-model="delevents"
                       v-bind:style="[
                              delevents == 'allevents'
                                ? { border: '1px solid #5dc2a6 !important' }
                                : { border: '1px solid #D1D3E0 !important' },
                            ]" />
                <label for="allevents"
                       class="following">All events</label><br /> -->
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input class="button btn_lg btn pink_btn"
               type="submit"
               value="OK"
               :disabled="seletedeloption"
               style="
            float: right;
            margin-right: -13px;
            margin-bottom: 13px;
            margin-left: 7px;
          "
               @click="deleteNotificationPermanent" />
        <input type="button"
               class="button btn_lg btn orange_btn anon-can"
               value="Cancel"
               @click="closeModal" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import Datepicker from "vuejs-datepicker";
import CKEditor from "ckeditor4-vue/dist/legacy.js";
import EditNotificationsComponentModal from "./EditNotificationsComponentModal";
import moment from "moment";

export default {
  components: {
    EditNotificationsComponentModal,
    CKEditor,
    paginate: Paginate,
    Datepicker,
  },
  props: ["status", "templates", "sitesarr", "show_sites", "currentUser", "can_notify_all_users", "brand", "shouldRefreshCalendar"],
  data: function () {
    return {
      delevents: '',
      repeat_id: '',
      current_id: '',
      calendarMonth: [],
      calendarMonthState: [],
      monthValue: 0,
      stringMonth: moment().startOf("month").format("MMMM"),
      currentMonth: moment().month() + 1,
      currentYear: moment().year(),
      startDayOfMonth: moment().startOf("month").format("dddd"),
      endDateOfMonth: moment().endOf("month").format("DD"),
      highlighted: {
        // days: [6, 0], // Highlight Saturday's and Sunday's
        daysOfMonth: [15, 20, 31], // Highlight 15th, 20th and 31st of each month
        // dates: [
        //     // Highlight an array of dates
        //     new Date(2016, 9, 16),
        //     new Date(2016, 9, 17),
        //     new Date(2016, 9, 18)
        // ],
        // a custom function that returns true of the date is highlighted
        // this can be used for wiring you own logic to highlight a date if none
        // of the above conditions serve your purpose
        // this function should accept a date and return true if is highlighted
        customPredictor: function (date) {
          // highlights the date if it is a multiple of 4
          if (date.getDate() % 4 == 0) {
            return false;
          }
        },
        includeDisabled: true, // Highlight disabled dates
      },

      titleEditorConfig: {
        autoParagraph: false,
        autoGrow_maxHeight: 50,
        height: 60,
        editorplaceholder: "Add title",
        // removePlugins : 'autogrow',
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      selectedDate: "",
      currentDate: moment(new Date()).format("dddd, MMMM DD, YYYY"),
      search: "",
      notifications: this.notifications,
      notificationsLength: 0,
      // Handling tabs pagination in Vuejs way
      historyPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      currentIndex: null,
      currentObject: {},
      editorConfig: {
        autoParagraph: false,
        height: 148,
        editorplaceholder: "Add Words",
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      isCalendarMonthSwitchDisabled: false,
    };
  },

  methods: {
    nextMonth() {
      if (this.isCalendarMonthSwitchDisabled == true) {
        return;
      };
      if (this.monthValue < 5) {
        this.monthValue++;
        this.startDayOfMonth = moment()
          .add(this.monthValue, "M")
          .startOf("month")
          .format("dddd");
        this.endDateOfMonth = moment()
          .add(this.monthValue, "M")
          .endOf("month")
          .format("DD");
        this.currentMonth = moment()
          .add(this.monthValue, "M")
          .startOf("month")
          .format("MM");
        this.stringMonth = moment()
          .add(this.monthValue, "M")
          .startOf("month")
          .format("MMMM");
        this.currentYear = moment()
          .add(this.monthValue, "M")
          .startOf("month")
          .format("YYYY");
        this.getCalendarMonth();
        this.loadCalendarData();

      } else { }

    },
    prevMonth() {
      if (this.isCalendarMonthSwitchDisabled == true) {
        return;
      };
      this.monthValue--;
      this.startDayOfMonth = moment()
        .add(this.monthValue, "M")
        .startOf("month")
        .format("dddd");
      this.endDateOfMonth = moment()
        .add(this.monthValue, "M")
        .endOf("month")
        .format("DD");
      this.currentMonth = moment()
        .add(this.monthValue, "M")
        .startOf("month")
        .format("MM");
      this.stringMonth = moment()
        .add(this.monthValue, "M")
        .startOf("month")
        .format("MMMM");
      this.currentYear = moment()
        .add(this.monthValue, "M")
        .startOf("month")
        .format("YYYY");
      this.getCalendarMonth();
      this.loadCalendarData();
    },
    getCalendarMonth() {
      const startDayOfMonth = this.startDayOfMonth;
      const startDate = moment().startOf("month").format("DD");
      const endDayOfMonth = moment().endOf("month").format("dddd");
      const endDate = this.endDateOfMonth;
      let weekDay = this.getWeekday(startDayOfMonth);
      let MonthArray = [];
      let tempArr = [];
      for (let i = 1; i < weekDay; i++) {
        tempArr.push({
          value: null,
          date: null,
          class: "",
          count: null,
        });
      }

      for (let i = 1; i <= endDate; i++) {
        let dateValue = i + "-" + this.currentMonth + "-" + this.currentYear;
        if (
          moment(moment(new Date()).format("YYYY-MM-DD")).isSame(
            this.currentYear + "-" + this.currentMonth + "-" + i,
            "day"
          )
        ) {
          console.log("iam  true");
        }

        if (weekDay % 7 == 0) {
          tempArr.push({
            value: i,
            date: dateValue,
            class: "",
            isToday: moment(moment(new Date()).format("YYYY-MM-DD")).isSame(
              this.currentYear + "-" + this.currentMonth + "-" + i,
              "day"
            )
              ? true
              : false,
            isSelected: false,
            count: null,
          });
          MonthArray.push(tempArr);
          tempArr = [];
          weekDay = 1;
        } else {
          tempArr.push({
            value: i,
            date: dateValue,
            class: "",
            isToday: moment(moment(new Date()).format("YYYY-MM-DD")).isSame(
              this.currentYear + "-" + this.currentMonth + "-" + i,
              "day"
            )
              ? true
              : false,
            isSelected: false,
            count: null,
          });
          weekDay++;
        }
      }

      if (weekDay > 1) {
        for (let i = weekDay; i <= 7; i++) {
          tempArr.push({
            value: null,
            date: null,
            class: "",
            isToday: null,
            isSelected: null,
            count: null,
          });
        }
        MonthArray.push(tempArr);
      }
      console.log("monthArr :", MonthArray);
      this.calendarMonth = [...MonthArray];
    },
    getWeekday(day) {
      if (day == "Monday") {
        return 1;
      } else if (day == "Tuesday") {
        return 2;
      } else if (day == "Wednesday") {
        return 3;
      } else if (day == "Thursday") {
        return 4;
      } else if (day == "Friday") {
        return 5;
      } else if (day == "Saturday") {
        return 6;
      } else if (day == "Sunday") {
        return 7;
      }
    },
    onSelectDate(date, rowIndex, colIndex) {
      let temp = [...this.calendarMonth];

      if (temp[rowIndex][colIndex].value != null) {
        this.selectedDate = date;
        this.getCurrentDate();
        this.loadScheduledNotificationsByDate(null);

        temp = JSON.parse(JSON.stringify(this.calendarMonthLastState));

        //make isSelected equals to false for all values
        temp[rowIndex][colIndex].isSelected = true;
        if (temp[rowIndex][colIndex].class == "Scheduled") {
          temp[rowIndex][colIndex].class = "Scheduled-select";
        } else if (temp[rowIndex][colIndex].class == "Sent") {
          temp[rowIndex][colIndex].class = "Sent-select";
        } else if (temp[rowIndex][colIndex].class == "Draft") {
          temp[rowIndex][colIndex].class = "Drafted-select";
        } else {
          temp[rowIndex][colIndex].class = "select";
        }

        this.calendarMonth = JSON.parse(JSON.stringify(temp));
      }
    },

    /**
     * Send AJAX request to get the scheduled notifications
     * @param page
     */
    loadCalendarData(page = "") {
      this.isCalendarMonthSwitchDisabled = true;
      axios
        .post("/dashboard/notifications-page/get-calendar-month", {
          date: 1 + "-" + this.currentMonth + "-" + this.currentYear,
          siteId: $("#selected-site").val(),
        })
        .then((response) => {
          let data = response.data.calendarMonth;

          let tempData = [...this.calendarMonth];

          for (var i = 0; i < data.length; i++) {
            let val = data[i];
            for (var j = 0; j < tempData.length; j++) {
              let row = tempData[j];

              for (var k = 0; k < row.length; k++) {
                if (row[k].value == val.day) {
                  const unsendNotifications = (val.start_dates ?? [])
                    .filter(start_date => moment.utc(start_date).isAfter(moment.utc()));
                  row[k].class = unsendNotifications.length > 0 ? 'Scheduled' : 'Sent';
                  row[k].count = val.count;
                }
              }
            }
          }
          this.calendarMonth = [...tempData];
          this.calendarMonthLastState = [...tempData];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.isCalendarMonthSwitchDisabled = false;
          }, 500);
        });
    },
    /**
     * Send AJAX request to get the scheduled notifications
     * @param page
     */
    loadScheduledNotificationsByDate(page = "") {
      this.isCalendarMonthSwitchDisabled = true;
      axios
        .post(
          `/dashboard/notifications-page/get-scheduled-notifications-by-date?page=${page}`,
          {
            scheduled_page: page,
            date: this.selectedDate,
            timezone: new Date().getTimezoneOffset(),
            siteId: $("#selected-site").val(),
          }
        )
        .then((response) => {
          this.notifications = this.compareDatesToAppendSendStatus(
            response.data.scheduledNotifications
          );
          this.notificationsLength = this.notifications.length;
          this.pagination = response.data.pagination;

          if (response.data.hasOwnProperty("pagination")) {
            let paginationArr = response.data.pagination;
            this.historyPaginationData.current_page =
              paginationArr.current_page;
            this.historyPaginationData.from = paginationArr.from;
            this.historyPaginationData.last_page = paginationArr.last_page;
            this.historyPaginationData.per_page = paginationArr.per_page;
            this.historyPaginationData.to = paginationArr.to;
            this.historyPaginationData.total = paginationArr.total;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.isCalendarMonthSwitchDisabled = false;
          }, 500);
        });
    },
    paginatorClickCallback: function (pageNum) {
      if (this.selectedDate) {
        this.loadScheduledNotificationsByDate(pageNum);
      } else {
        this.loadScheduledNotifications(pageNum);
      }
    },

    /**
     * Send AJAX request to get the scheduled notifications
     * @param page
     */
    loadScheduledNotifications(page) {
      axios
        .post(
          `/dashboard/notifications-page/get-scheduled-notifications?page=${page}`,
          {
            scheduled_page: page,
            siteId: $("#selected-site").val(),
          }
        )
        .then((response) => {
          this.notifications = this.compareDatesToAppendSendStatus(
            response.data.scheduledNotifications
          );
          this.notificationsLength = this.notifications.length;
          this.pagination = response.data.pagination;

          if (response.data.hasOwnProperty("pagination")) {
            let paginationArr = response.data.pagination;
            this.historyPaginationData.current_page =
              paginationArr.current_page;
            this.historyPaginationData.from = paginationArr.from;
            this.historyPaginationData.last_page = paginationArr.last_page;
            this.historyPaginationData.per_page = paginationArr.per_page;
            this.historyPaginationData.to = paginationArr.to;
            this.historyPaginationData.total = paginationArr.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHumanDate: function (date) {
      return moment
        .utc(date, "YYYY-MM-DD hh:mm")
        .local()
        .format("dddd, MMMM DD, YYYY · hh:mm A");
    },
    getCurrentDate: function () {
      this.currentDate = moment(this.selectedDate, "DD/MM/YYYY").format(
        "dddd, MMMM DD, YYYY"
      );
    },
    editNotification(id, index) {
      this.currentIndex = index;

      let value = this.notifications[index];
      this.currentObject = Object.assign({}, value);

      this.$bvModal.show("edit-notification-modal");
    },
    save() {
      let tempArr = JSON.parse(JSON.stringify(this.notifications));
      tempArr[this.currentIndex] = JSON.parse(
        JSON.stringify(this.currentObject)
      );
      this.notifications = JSON.parse(JSON.stringify(tempArr));
      this.editNotificationPostData();

      this.$bvModal.hide("edit-scheduled-notification");
    },
    closeModal() {
      this.$bvModal.hide("modal_delete");
      this.$bvModal.hide("edit-scheduled-notification");
    },
    editNotificationPostData() {
      axios
        .post("/dashboard/notifications/update/notification", {
          data: this.currentObject,
        })
        .then((response) => {
          if (response.status == 200) {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Notification is Edited',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          } else {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> Could not edit Notification!',
              confirmButtonColor: "#ea2020",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#ea2020",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showDeleteNotificationModal(id, index, repeat_id) {
      this.currentIndex = index;
      this.repeat_id = repeat_id;
      this.current_id = id;
      let value = this.notifications[index];
      this.currentObject = Object.assign({}, value);
      this.$bvModal.show("modal_delete");
    },
    deleteNotificationPermanent() {
      this.$bvModal.hide("modal_delete");
      Vue.swal({
        title: 'Deleting...',
        html: '',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading()
        },
      });

      var that = this;
      axios
        .post("/dashboard/notifications/delete/notification", {
          data: this.currentObject, deletetype: this.delevents, repeat_id: this.repeat_id,
        })
        .then((response) => {
          that.repeat_id = '';
          that.current_id = '';
          that.delevents = '';
          let httpRequestOk = response.status == 200 ? true : false;
          if (httpRequestOk == true) {
            let temp = JSON.parse(JSON.stringify(that.notifications));
            temp.splice(that.currentIndex, 1);
            that.notifications = JSON.parse(JSON.stringify(temp));

            that.notificationsLength = temp.length;

            that.loadCalendarData();
            this.getCalendarMonth();
            this.loadScheduledNotificationsByDate();
          };

          Vue.swal({
            html: httpRequestOk == true 
              ? '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Notification deleted'
              : '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> Could not delete Notification!',
            confirmButtonColor: httpRequestOk == true ? "#5dc2a6" : "#ea2020",
            confirmButtonText:
              '<i class="fa fa-times" aria-hidden="true"></i>',
            background: httpRequestOk == true ? "#5dc2a6" : "#ea2020",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch((error) => {
          console.log(error);
        });

    },
    compareDatesToAppendSendStatus(notifications) {
      notifications.map((item, index) => {
        if (moment.utc(item.start_date).isAfter(moment.utc())) {
          notifications[index].notification_status = "Scheduled";
        } else {
          notifications[index].notification_status = "Sent";
        }
      });
      return notifications;
    },
    showMenu() {
      $(this.$el).foundation();
    },
    canManageNotification(notification) {
      if (!this.currentUser) {
        return false;
      }
      if (notification.send_to_all == 2) {
        //check if currentUser has access to all sites
        return this.currentUser.notification_check && this.currentUser.site_id == null;
      }

      return this.currentUser.notification_check;
    },
  },
  mounted() {
    this.getCalendarMonth();
    this.loadScheduledNotifications(
      this.getParamsFromUrl("scheduled_page", window.location.href)
    );
    this.loadCalendarData();
    this.$root.$on("notificationCreated", () => {
      this.getCalendarMonth();
      this.loadCalendarData();
      this.loadScheduledNotificationsByDate();
    });
  },
  computed: {
    seletedeloption: function () {
      if (this.delevents) {
        return false;
      } else {
        return true;
      }
    },
    calendarMonthSwitchDisabled: function () {
      return this.isCalendarMonthSwitchDisabled == true ? 'disabled' : '';
    },
  },
  watch: {
    shouldRefreshCalendar(value) {
      if (value == true) {
        this.$emit('refresh-calendar');

        for (let i = 1; i <= 3; i++) {
          setTimeout(() => {
            this.getCalendarMonth();
            this.loadCalendarData();
            this.loadScheduledNotificationsByDate();
          }, (i**3) * 1000);
        };
      };
    },
  },
};
</script>

//
<style lang="scss" scoped>
div#scheduled {
  border-radius: 8px !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 510%;
  outline: none;
  // box-shadow: 0 0 5px 0px green inset;
  vertical-align: middle;
}

input[type="radio"]::before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}


input[type="radio"]:checked:before {
  background: #5dc2a6 !important;
}

label.following {
  margin-bottom: 2px;
  font-weight: 600;
  cursor: pointer;
  vertical-align: top;
}

[type="radio"] {
  margin: 0 0 0 !important;
}
</style>