<template>
  <!-- MAIN CONTAINER -->
  <div class="cell small-11 t_margin_2">
    <div v-show="showLoad" class="show-load-candidate">
      <img :src="myImage" />
    </div>
    <div class="tabs-sticky">
      <ul
        class="tabs sticky-element-tab candidate-width-head"
        data-tabs
        id="example-tabs"
        data-deep-link="true"
        data-update-history="true"
        data-deep-link-smudge="true"
        :class="{ change_color: scrollPosition > 50 }"
      >
        <li class="tabs-title">
          <a
            href="#live-candidates"
            v-on:click="
              isActive(1);
              manageServerDataRequest();
            "
            >Live Candidates</a
          >
        </li>
        <li class="tabs-title">
          <a
            data-tabs-target="archived-candidates"
            href="#archived-candidates"
            v-on:click="
              isActive(2);
              manageServerDataRequest();
            "
            >Archived Candidates</a
          >
        </li>
        <li class="tabs-title">
          <a
            data-tabs-target="starters"
            href="#starters"
            v-on:click="
              isActive(3);
              manageServerDataRequest();
            "
            >Starters</a
          >
        </li>
      </ul>

      <template v-if="liveTab == 1">
        <template v-if="currentuser.new_admin == 1">
          <div
            v-if="currentuser.candidate_check == 1"
            class="candidate-margin-left candidate-padding-right"
            style="float: right"
          >
            <button
              id="add-candidate-btn"
              @click="
                createNew(1);
                isShow = true;
              "
              class="button pink_btn b_margin_0 no_wrap_btn"
            >
              Add a candidate
            </button>
            <add-candidate-component
              v-if="isShow"
              :childComponant="childComponant"
              :addCandidate="addCandidate"
              initalStatus="new"
              :users="users"
              :currentuser="currentuser"
              :isCareSector="isCareSector"
            ></add-candidate-component>
          </div>
        </template>
        <template v-else>
          <div
            class="candidate-margin-left candidate-padding-right"
            style="float: right"
          >
            <button
              id="add-candidate-btn"
              @click="
                createNew(1);
                isShow = true;
              "
              class="button pink_btn b_margin_0 no_wrap_btn"
            >
              Add a candidate
            </button>
            <add-candidate-component
              v-if="isShow"
              :childComponant="childComponant"
              :addCandidate="addCandidate"
              initalStatus="new"
              :users="users"
              :currentuser="currentuser"
              :isCareSector="isCareSector"
            ></add-candidate-component>
          </div>
        </template>
      </template>
    </div>
    <div
      class="tabs-content box_shadow candidate-width-head"
      data-tabs-content="example-tabs"
    >
      <div
        v-if="liveTab == 1"
        class="tabs-panel no-padding is-active"
        id="live-candidates"
      >
        <div
          class="grey_box_candidates candidate-prevent candidates-padding-top"
        >
          <div class="grid-x grid-padding-x normal_font">
            <div
              class="grid-x sticky-element candidate-width-full cf-tool-bar"
              :class="{ sticky_element_pad: scrollPosition > 50 }"
            >
              <div class="candidate-total-block-live">
                <div class="tot-num-bonus">{{ livePaginationData.total }}</div>
                <div class="total-points-bonus">Total live candidates</div>
              </div>
              <div class="candidate-total-block-live">
                <div
                  class="tot-num-red"
                  v-if="livePaginationData.tot_overdue > 0"
                >
                  <div class="showall-left">
                    <i
                      class="fa fa-clock-o fa-lg fa-fw redglow-tiles"
                      aria-hidden="true"
                    ></i
                    >{{ livePaginationData.tot_overdue }}
                  </div>
                  <div class="showall-right" @click="showOverdue()">
                    Show me
                  </div>
                </div>
                <div
                  class="total-points-bonus"
                  v-if="livePaginationData.tot_overdue > 0"
                >
                  Overdue for initial contact
                </div>
              </div>
              <div class="middle-serarch no-padding">
                <input
                  class="candidates-search-top"
                  placeholder="Search"
                  v-model="searchVarLive"
                  name="searchVar"
                  @input="searchCandidates(1)"
                />
              </div>

              <div class="pull-right live-filter-block">
                <div class="cell shrink">
                  <a
                    href="javascript:void(0)"
                    @click="filtertoggle(1)"
                    class="btn button orange_btn float-right bonus-filter-cls"
                  >
                    FILTERS <img src="/images/filter-icon.png" alt="img"
                  /></a>
                </div>
                <div class="cell shrink">
                  <a
                    :href="
                      '/dashboard/candidatesexport?tab=live&search=' +
                      this.searchVarLive +
                      '&sort=' +
                      this.sortByLive +
                      '&sites=' +
                      export_site_ids +
                      '&jobId=' +
                      this.export_job_ids +
                      '&start_date=' +
                      this.filterStartDate +
                      '&end_date=' +
                      this.filterEndDate +
                      '&stage=' +
                      this.seleted_stage +
                      '&status=' +
                      this.export_status_ids +
                      '&overdue=' +
                      this.overdueVal
                    "
                    class="btn button orange_btn float-right"
                  >
                    EXPORT
                  </a>
                </div>
              </div>
            </div>
            <!--Filter div start here-->
            <div
              v-show="showFilterLive"
              class="grid-x sticky-element candidate-width-full cf-tool-bar"
            >
              <div class="candidate-filters-wrappers filters-wrappers">
                <div class="cell padright">
                  <div class="input-box">
                    <div class="candidate-select">
                      <v-select
                        placeholder="Sort by"
                        id="sort-filter"
                        ref="sortFilter"
                        v-model="data.sortby_live"
                        :reduce="(referrer) => referrer.id"
                        label="sort"
                        class="phone-recruiter"
                        :options="sortByOptionsLive"
                        :close-on-select="true"
                        multiple
                        taggable
                        @input="sortedJobs(1)"
                      ></v-select>
                    </div>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <date-range-picker
                      v-model="dateRange"
                      id="date-range-filter"
                      ref="dateRangeFilter"
                      opens="right"
                      class="phone-recruiter"
                      @keydown.enter="addTag"
                      @keydown.188="addTag"
                      @select="addTag"
                      @keydown.delete="removeLastTag"
                    >
                      <!--    input slot (new slot syntax)-->
                      <template #input="picker">
                        <label class="datelabelclass-candidate"
                          >Date of EOI</label
                        ></template
                      >
                    </date-range-picker>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="stage-filter"
                      ref="stageFilter"
                      @input="seletedstage(data.stage, 1)"
                      v-model="data.stage"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="stageOptions"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Stage"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image" />
                      </template>
                    </v-select>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="status-filter"
                      ref="statusFilter"
                      @input="seletedStatus(data.status, 1)"
                      v-model="data.status"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="statusOptions"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Status"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image" />
                      </template>
                    </v-select>
                  </div>
                </div>
                <div v-if="showFilterSites" class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="sites-filter"
                      ref="sitesFilter"
                      @input="seletedsites(data.sites, 1)"
                      v-model="data.sites"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="availableSites"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Site"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image" />
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="jobs-filter"
                      ref="jobsFilter"
                      @input="selectedJobs(data.jobs, 1)"
                      v-model="data.jobs"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="availableJobs"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Jobs"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image" />
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!--for displaying tags -->
              <div
                class="grid-x full-width-can"
                v-if="selected_overdue_tags != 0"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{ selected_overdue_tags }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeOverdueTag()"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_sortby_tags != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{ selected_sortby_tags }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeSortbyTags(1)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_date_tags != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{ selected_date_tags }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeDateTags(1)"
                    ></button
                  ></span>
                </div>
              </div>

              <div
                class="grid-x full-width-can"
                v-if="selected_stage_tags != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{ selected_stage_tags }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeStageTags(1)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_status_tags != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{ selected_status_tags }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeStatusTags(1)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_sites_tags != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{ selected_sites_tags }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeSitesTags(1)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_jobs_tags != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{ selected_jobs_tags }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeJobsTags(1)"
                    ></button
                  ></span>
                </div>
              </div>
            </div>
            <template v-if="liveArray.length == 0">
              <div class="empty-state empty-state-back" v-if="message">
                <img src="/images/icon-empty-state-candidates.png" />
                <p>{{ message }}</p>
                <br />
              </div>
            </template>
            <!-- Filter div end here -->
            <div
              v-else
              v-for="candidate in liveArray"
              v-bind:key="candidate.id"
              style="width: 100%;"
            >
              <div class="can-bottom-border"></div>
              <candidate-single-component
                :availableJobs="availableCandidateJobs"
                :job="candidate.job"
                :tab-id="tabId"
                :childComponant="childComponant"
                :data="candidate"
                :users="users"
                :currentuser="currentuser"
                :showFilterSites="showFilterSites"
                :isCareSector="isCareSector"
                :point-tariffs-feature-flag="pointTariffsFeatureFlag"
              />
            </div>
          </div>
        </div>
        <template v-if="livePaginationData.last_page > 1">
          <paginate
            :page-count="livePaginationData.last_page"
            :page-range="3"
            :margin-pages="2"
            :click-handler="livePaginatorClickCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :prev-class="'paginate-controls'"
            :next-class="'paginate-controls'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :active-class="'active'"
          >
          </paginate>
        </template>
      </div>
      <div
        v-if="archivedTab == 1"
        class="tabs-panel no-padding is-active"
        id="archived-candidates"
      >
        <div
          class="grey_box_candidates candidate-prevent candidates-padding-top"
        >
          <div class="grid-x grid-padding-x normal_font">
            <div
              class="grid-x sticky-element candidate-width-full"
              :class="{ sticky_element_pad: scrollPosition > 50 }"
            >
              <div class="candidate-total-block">
                <div class="tot-num-bonus">
                  {{ archivedPaginationData.total }}
                </div>
                <div class="total-points-bonus">Total archived candidates</div>
              </div>
              <div class="no-padding middle-serarch">
                <input
                  class="candidates-search-top"
                  placeholder="Search"
                  v-model="searchVarArchived"
                  name="searchVar"
                  @input="searchCandidates(2)"
                />
              </div>

              <div class="pull-right live-filter-block">
                <div class="cell shrink">
                  <a
                    href="javascript:void(0)"
                    @click="filtertoggle(2)"
                    class="btn button orange_btn float-right bonus-filter-cls"
                  >
                    FILTERS <img src="/images/filter-icon.png" alt="img"
                  /></a>
                </div>
                <div class="cell shrink">
                  <a
                    :href="
                      '/dashboard/candidatesexport?tab=archived&search=' +
                      this.searchVarArchived +
                      '&sort=' +
                      this.sortByArchived +
                      '&sites=' +
                      export_site_ids_archived +
                      '&jobId=' +
                      this.export_job_ids_archived +
                      '&start_date=' +
                      this.filterStartDate_archived +
                      '&end_date=' +
                      this.filterEndDate_archived +
                      '&stage=' +
                      this.seleted_stage_archived +
                      '&status=' +
                      this.export_status_ids_archived
                    "
                    class="btn button orange_btn float-right"
                  >
                    EXPORT
                  </a>
                </div>
              </div>
            </div>

            <!--Archived Filter div start here-->
            <div
              v-show="showFilterArchived"
              class="grid-x sticky-element candidate-width-full"
            >
              <div class="candidate-filters-wrappers filters-wrappers">
                <div class="cell padright">
                  <div class="input-box">
                    <div class="candidate-select">
                      <v-select
                        placeholder="Sort by"
                        id="sort-Archived-filter"
                        ref="sortArchivedFilter"
                        v-model="data.sortby_Archived"
                        :reduce="(referrer) => referrer.id"
                        label="sort"
                        class="phone-recruiter"
                        :options="sortByOptions"
                        :close-on-select="true"
                        @input="sortedJobs(2)"
                        multiple
                        taggable
                      ></v-select>
                    </div>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <date-range-picker
                      v-model="dateRange"
                      id="date-range-filter-archived"
                      ref="dateRangeFilterArchived"
                      opens="right"
                      class="phone-recruiter"
                      @keydown.enter="addTagArchived"
                      @keydown.188="addTagArchived"
                      @select="addTagArchived"
                      @keydown.delete="removeLastTag"
                    >
                      <!--    input slot (new slot syntax)-->
                      <template #input="picker">
                        <label class="datelabelclass-candidate"
                          >Date of EOI</label
                        ></template
                      >
                    </date-range-picker>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="stage-filter-archived"
                      ref="stageFilterArchived"
                      @input="seletedstage(data.stage_archived, 2)"
                      v-model="data.stage_archived"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="stageOptions"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Stage"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img
                          style="float: right"
                          :src="option.image_archived"
                        />
                      </template>
                    </v-select>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="status-filter-archived"
                      ref="statusFilterArchived"
                      @input="seletedStatus(data.status_archived, 2)"
                      v-model="data.status_archived"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="statusOptions"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Status"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img
                          style="float: right"
                          :src="option.image_archived"
                        />
                      </template>
                    </v-select>
                  </div>
                </div>
                <div v-if="showFilterSites" class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="sites-filter-archived"
                      ref="sitesFilterArchived"
                      @input="seletedsites(data.sites_archived, 2)"
                      v-model="data.sites_archived"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="availableSites"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Site"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img
                          style="float: right"
                          :src="option.image_archived"
                        />
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="jobs-filter-archived"
                      ref="jobsFilterArchived"
                      @input="selectedJobs(data.jobs_archived, 2)"
                      v-model="data.jobs_archived"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="availableJobs"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Jobs"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img
                          style="float: right"
                          :src="option.image_archived"
                        />
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!--for displaying tags -->
              <div
                class="grid-x full-width-can"
                v-if="selected_sortby_archived_tags != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_sortby_archived_tags
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeSortbyTags(2)"
                    ></button
                  ></span>
                </div>
              </div>

              <div
                class="grid-x full-width-can"
                v-if="selected_date_tags_archived != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_date_tags_archived
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeDateTags(2)"
                    ></button
                  ></span>
                </div>
              </div>

              <div
                class="grid-x full-width-can"
                v-if="selected_stage_tags_archived != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_stage_tags_archived
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeStageTags(2)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_status_tags_archived != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_status_tags_archived
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeStatusTags(2)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_sites_tags_archived != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_sites_tags_archived
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeSitesTags(2)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_jobs_tags_archived != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_jobs_tags_archived
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeJobsTags(2)"
                    ></button
                  ></span>
                </div>
              </div>
            </div>
            <!-- Filter div end here -->

            <template v-if="archivedArray.length == 0">
              <div class="empty-state empty-state-back" v-if="message">
                <img src="/images/icon-empty-state-candidates.png" />
                <p>{{ message }}</p>
                <br />
              </div>
            </template>
            <div
              v-else
              v-for="candidate in archivedArray"
              v-bind:key="candidate.id"
              style="width: 100%;"
            >
              <div class="can-bottom-border"></div>
              <candidate-single-component
                :availableJobs="availableCandidateJobs"
                :job="candidate.job"
                :tab-id="tabId"
                :childComponant="childComponant"
                :data="candidate"
                :users="users"
                :currentuser="currentuser"
                :showFilterSites="showFilterSites"
                :isCareSector="isCareSector"
                :point-tariffs-feature-flag="pointTariffsFeatureFlag"
              />
            </div>
          </div>
        </div>
        <template v-if="archivedPaginationData.last_page > 1">
          <paginate
            :page-count="archivedPaginationData.last_page"
            :page-range="3"
            :margin-pages="2"
            :click-handler="archivedPaginatorClickCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :prev-class="'paginate-controls'"
            :next-class="'paginate-controls'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :active-class="'active'"
          >
          </paginate>
        </template>
      </div>
      <div
        v-if="startersTab == 1"
        class="tabs-panel no-padding is-active"
        id="starters"
      >
        <div
          class="grey_box_candidates candidate-prevent candidates-padding-top"
        >
          <div class="grid-x grid-padding-x normal_font">
            <div
              class="grid-x sticky-element candidate-width-full"
              :class="{ sticky_element_pad: scrollPosition > 50 }"
            >
              <div class="candidate-total-block">
                <div class="tot-num-bonus">
                  {{ startersPaginationData.total }}
                </div>
                <div class="total-points-bonus">Total starter candidates</div>
              </div>
              <div class="no-padding middle-serarch">
                <input
                  class="candidates-search-top"
                  placeholder="Search"
                  v-model="searchVarStarters"
                  name="searchVar"
                  @input="searchCandidates(3)"
                />
              </div>

              <div class="pull-right live-filter-block">
                <div class="cell shrink">
                  <a
                    href="javascript:void(0)"
                    @click="filtertoggle(3)"
                    class="btn button orange_btn float-right bonus-filter-cls"
                  >
                    FILTERS <img src="/images/filter-icon.png" alt="img"
                  /></a>
                </div>
                <div class="cell shrink">
                  <a
                    :href="
                      '/dashboard/candidatesexport?tab=starters&search=' +
                      this.searchVarStarters +
                      '&sort=' +
                      this.sortByStarters +
                      '&sites=' +
                      export_site_ids_starter +
                      '&jobId=' +
                      this.export_job_ids_starter +
                      '&start_date=' +
                      this.filterStartDate_starter +
                      '&end_date=' +
                      this.filterEndDate_starter +
                      '&stage=' +
                      this.seleted_stage_starter +
                      '&status=' +
                      this.export_status_ids_starter
                    "
                    class="btn button orange_btn float-right"
                  >
                    EXPORT
                  </a>
                </div>
              </div>
            </div>

            <!--Starters Filter div start here-->
            <div
              v-show="showFilterStarter"
              class="grid-x sticky-element candidate-width-full"
            >
              <div class="candidate-filters-wrappers filters-wrappers">
                <div class="cell padright">
                  <div class="input-box">
                    <div class="candidate-select">
                      <v-select
                        placeholder="Sort by"
                        id="sort-Starters-filter"
                        ref="sortStartersFilter"
                        v-model="data.sortby_Starters"
                        :reduce="(referrer) => referrer.id"
                        label="sort"
                        class="phone-recruiter"
                        :options="sortByOptions"
                        :close-on-select="true"
                        @input="sortedJobs(3)"
                        multiple
                        taggable
                      ></v-select>
                    </div>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <date-range-picker
                      v-model="dateRange"
                      id="date-range-filter-starter"
                      ref="dateRangeFilterStarter"
                      opens="right"
                      class="phone-recruiter"
                      @keydown.enter="addTagStarter"
                      @keydown.188="addTagStarter"
                      @select="addTagStarter"
                      @keydown.delete="removeLastTag"
                    >
                      <!--    input slot (new slot syntax)-->
                      <template #input="picker">
                        <label class="datelabelclass-candidate"
                          >Date of EOI</label
                        ></template
                      >
                    </date-range-picker>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="stage-filter-starter"
                      ref="stageFilterStarter"
                      @input="seletedstage(data.stage_starter, 3)"
                      v-model="data.stage_starter"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="stageOptions"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Stage"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image_starter" />
                      </template>
                    </v-select>
                  </div>
                </div>

                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="status-filter-starter"
                      ref="statusFilterStarter"
                      @input="seletedStatus(data.status_starter, 3)"
                      v-model="data.status_starter"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="statusOptions"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Status"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image_starter" />
                      </template>
                    </v-select>
                  </div>
                </div>
                <div v-if="showFilterSites" class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="sites-filter-starter"
                      ref="sitesFilterStarter"
                      @input="seletedsites(data.sites_starter, 3)"
                      v-model="data.sites_starter"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="availableSites"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Site"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image_starter" />
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="cell padright">
                  <div class="input-box">
                    <v-select
                      class="phone-recruiter"
                      id="jobs-filter-starter"
                      ref="jobsFilterStarter"
                      @input="selectedJobs(data.jobs_starter, 3)"
                      v-model="data.jobs_starter"
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="availableJobs"
                      :close-on-select="true"
                      multiple
                      taggable
                      placeholder="Jobs"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image_starter" />
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!--for displaying tags -->
              <div
                class="grid-x full-width-can"
                v-if="selected_sortby_starters_tags != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_sortby_starters_tags
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeSortbyTags(3)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_date_tags_starter != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_date_tags_starter
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeDateTags(3)"
                    ></button
                  ></span>
                </div>
              </div>

              <div
                class="grid-x full-width-can"
                v-if="selected_stage_tags_starter != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_stage_tags_starter
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeStageTags(3)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_status_tags_starter != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_status_tags_starter
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeStatusTags(3)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_sites_tags_starter != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_sites_tags_starter
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeSitesTags(3)"
                    ></button
                  ></span>
                </div>
              </div>
              <div
                class="grid-x full-width-can"
                v-if="selected_jobs_tags_starter != ''"
              >
                <div class="tag-input__tag">
                  <span class="lablesize">{{
                    selected_jobs_tags_starter
                  }}</span>
                  <span class="deselect-pad"
                    ><button
                      class="deselect"
                      @click="removeJobsTags(3)"
                    ></button
                  ></span>
                </div>
              </div>
            </div>
            <!-- Filter div end here -->
            <template v-if="startersArray.length == 0">
              <div class="empty-state empty-state-back" v-if="message">
                <img src="/images/icon-empty-state-candidates.png" />
                <p>{{ message }}</p>
                <br />
              </div>
            </template>
            <div
              v-else
              v-for="candidate in startersArray"
              v-bind:key="candidate.id"
              style="width: 100%;"
            >
              <div class="can-bottom-border"></div>
              <candidate-single-component
                :availableJobs="availableCandidateJobs"
                :job="candidate.job"
                :tab-id="tabId"
                :childComponant="childComponant"
                :data="candidate"
                :users="users"
                :currentuser="currentuser"
                :showFilterSites="showFilterSites"
                :isCareSector="isCareSector"
                :point-tariffs-feature-flag="pointTariffsFeatureFlag"
              />
            </div>
          </div>
        </div>
        <template v-if="startersPaginationData.last_page > 1">
          <paginate
            :page-count="startersPaginationData.last_page"
            :page-range="3"
            :margin-pages="2"
            :click-handler="startersPaginatorClickCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :prev-class="'paginate-controls'"
            :next-class="'paginate-controls'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :active-class="'active'"
          >
          </paginate>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Vue2Filters from "vue2-filters";
import { filter } from "minimatch";
import ClickOutside from "vue-click-outside";
Vue.use(Vue2Filters);
import Paginate from "vuejs-paginate";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  mixins: [Vue2Filters.mixin],
  props: ["users", "currentuser", "isCareSector", "pointTariffsFeatureFlag"],
  components: {
    paginate: Paginate,
    DateRangePicker: DateRangePicker,
  },
  data() {
    return {
      availableCandidateJobs: [],
      searchDebounce: 0,
      childComponant: new Vue(),
      savedStatus: null,
      sortByOptions: [
        { id: 1, sort: "by update date" },
        { id: 2, sort: "alphabetically" },
        { id: 3, sort: "by EOI date" },
      ],
      sortByOptionsLive: [
        { id: 1, sort: "by update date" },
        { id: 2, sort: "alphabetically" },
        { id: 3, sort: "by EOI date" },
        { id: 4, sort: "Overdue first contact" },
      ],
      searchOptions: [
        { id: 1, search: "Candidates" },
        { id: 2, search: "Jobs" },
        { id: 3, search: "Sites" },
      ],
      sortByLive: "",
      sortByArchived: "",
      sortByStarters: "",
      searchBy: 1,
      searchVarLive: "",
      searchVarArchived: "",
      searchVarStarters: "",
      liveArray: [],
      archivedArray: [],
      startersArray: [],
      liveTab: 0,
      archivedTab: 0,
      startersTab: 0,
      currentHref: "",
      tabId: 1,
      arr: [],
      addCandidate: true,
      isShow: false,
      showLoad: false, // setting it true by default.
      myImage: "/images/loading-circle.gif",
      newCandidates: [],
      scrollPosition: null,

      // Handling tabs pagination in Vuejs way
      livePaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
        tot_overdue: 0,
      },
      archivedPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      startersPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      siteId: this.$root.getParamsFromUrl("siteId"),
      showFilterLive: false,
      showFilterArchived: false,
      showFilterStarter: false,
      showFilterSites: false,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      stageOptions: [
        {
          id: "applied",
          name: "Expressed interest",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview offered",
          name: "Contact made",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview successful",
          name: "Interview complete",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview booked",
          name: "Interview booked",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Started work",
          name: "Started work",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Job offered",
          name: "Job offered",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Employed for 12 months",
          name:
            "Employed for " + this.currentuser.customer.milestone + " months",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
      ],

      statusOptions: [
        {
          id: "attempting_to_contact",
          name: "Attempted to contact - try again",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "unable_to_contact",
          name: "Attempted to contact - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "doesnt_meet_criteria",
          name: "Ineligible",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "suitable_to_progress",
          name: "Contacted - suitable to progress",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "not_suitable_to_progress",
          name: "Contacted - unsuitable to progress",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "schedule_interview",
          name: "Schedule interview",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "more_info_requested",
          name: "More info requested",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "in_review",
          name: "In review",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "candidate_not_responding",
          name: "Candidate not responding - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "No show - reschedule",
          name: "No show - reschedule",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview successful",
          name: "Interview successful",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview unsuccessful",
          name: "Interview unsuccessful",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "no_show_archive",
          name: "No show - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Job offered - accepted",
          name: "Job offered - accepted",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Job offered - not accepted",
          name: "Job offered - not accepted",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "awaiting_decision",
          name: "Job offered - awaiting decision",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Job not offered - archive",
          name: "Job not offered - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Started work",
          name: "Started work",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Failed to start - archive",
          name: "Failed to start - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "police_checks_received",
          name: "Police checks received",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "references_complete",
          name: "References complete",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "police_checks_references_received",
          name: "Police checks & references received",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "yes",
          name: "Yes",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "no",
          name: "No",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
      ],
      data: {
        stage: "",
        jobs: "",
        sites: "",
        status: "",
        sortby_live: "",
        sortby_Archived: "",
        sortby_Starters: "",
        stage_archived: "",
        jobs_archived: "",
        sites_archived: "",
        status_archived: "",
        stage_starter: "",
        jobs_starter: "",
        sites_starter: "",
        status_starter: "",
      },
      seleted_stage: [],
      selected_stage_tags: "",
      selected_date_tags: "",
      availableJobs: [],
      selected_jobs_tags: "",
      export_job_ids: [],
      export_site_ids: [],
      export_status_ids: [],
      availableSites: [],
      tags: [],
      selected_sites_tags: "",
      selected_status_tags: "",
      filterStartDate: "",
      filterEndDate: "",
      selected_sortby_tags: "",
      selected_overdue_tags: "",
      selected_sortby_archived_tags: "",
      selected_sortby_starters_tags: "",
      seleted_stage_archived: [],
      selected_stage_tags_archived: "",
      selected_date_tags_archived: "",
      selected_jobs_tags_archived: "",
      export_job_ids_archived: [],
      export_site_ids_archived: [],
      export_status_ids_archived: [],
      tags_archived: [],
      selected_sites_tags_archived: "",
      selected_status_tags_archived: "",
      filterStartDate_archived: "",
      filterEndDate_archived: "",

      seleted_stage_starter: [],
      selected_stage_tags_starter: "",
      selected_date_tags_starter: "",
      selected_jobs_tags_starter: "",
      export_job_ids_starter: [],
      export_site_ids_starter: [],
      export_status_ids_starter: [],
      tags_starter: [],
      selected_sites_tags_starter: "",
      selected_status_tags_starter: "",
      filterStartDate_starter: "",
      filterEndDate_starter: "",
      overdueVal: 0,

      userCount: 0,
      ranges: {
        default() {
          let today = new Date();
          today.setHours(0, 0, 0, 0);

          let yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);

          let thisMonthStart = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          let thisMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );

          return {
            Today: [today, today],
            Yesterday: [yesterday, yesterday],
            "This week": [yesterday, yesterday],
            "Last week": [yesterday, yesterday],
            "This month": [thisMonthStart, thisMonthEnd],
            "This year": [
              new Date(today.getFullYear(), 0, 1),
              new Date(today.getFullYear(), 11, 31),
            ],
            "Last month": [
              new Date(today.getFullYear(), today.getMonth() - 1, 1),
              new Date(today.getFullYear(), today.getMonth(), 0),
            ],
          };
        },
      },
      message: "",
      query: false,
    };
  },
  methods: {
    async getCandidateJobs() {
      const { data } = await axios.get(
        "/dashboard/candidates/get-candidates-jobs"
      );
      if (data) {
        return data.map((job) => ({ name: job.Job_Title, id: job.id }));
      }
      return [];
    },
    isActive(tab) {
      this.showLoad = true;
      $(".tabs-title").find("> a").attr("aria-selected", "false");

      if (tab === 1) {
        this.liveTab = 1;
        this.archivedTab = 0;
        this.startersTab = 0;
        this.tabId = 1;
        $("#live-candidates").addClass("is-active");
        $("[href='#live-candidates']").attr("aria-selected", "true");
      } else if (tab === 2) {
        this.liveTab = 0;
        this.archivedTab = 1;
        this.startersTab = 0;
        this.tabId = 2;
        $("#archived-candidates").addClass("is-active");
        $("[href='#archived-candidates']").attr("aria-selected", "true");
      } else if (tab === 3) {
        this.liveTab = 0;
        this.archivedTab = 0;
        this.startersTab = 1;
        this.tabId = 3;
        $("#starters").addClass("is-active");
        $("[href='#starters']").attr("aria-selected", "true");
      }
      this.showLoad = false;
    },
    createNew(id) {
      // TODO: Kamran you need to fix it.
      this.addCandidate = true;
      if (!this.newCandidates) {
        this.newCandidates = [];
      }
      var candidate = {
        First_Name: "",
        Surname: "",
        Email: "",
        Status: "",
        Phone_Number: "",
        Deleted: "",
        cv_upload: "",
        cv_link: "",
        user_id: "",
        job_id: "",
      };
      this.newCandidates.push(candidate);
    },
    updateAll: function () {
      this.childComponant.$emit("save");
    },
    sortedJobs: function (tab) {
      if (tab == 1) {
        this.sortByLive = this.data.sortby_live;
        if (this.sortByLive == 1) {
          this.selected_sortby_tags = "Sort by: by update date";
        }
        if (this.sortByLive == 2) {
          this.selected_sortby_tags = "Sort by: alphabetically";
        }
        if (this.sortByLive == 3) {
          this.selected_sortby_tags = "Sort by: by EOI date";
        }
        if (this.sortByLive == 4) {
          this.selected_sortby_tags = "Sort by: Overdue first contact";
        }
        this.data.sortby_live = "";
        this.searchOrSortLiveCandidates();
      }
      if (tab == 2) {
        this.sortByArchived = this.data.sortby_Archived;
        if (this.sortByArchived == 1) {
          this.selected_sortby_archived_tags = "Sort by: by update date";
        }
        if (this.sortByArchived == 2) {
          this.selected_sortby_archived_tags = "Sort by: alphabetically";
        }
        if (this.sortByArchived == 3) {
          this.selected_sortby_archived_tags = "Sort by: by EOI date";
        }
        this.data.sortby_Archived = "";
        this.searchOrSortArchivedCandidates();
      }
      if (tab == 3) {
        this.sortByStarters = this.data.sortby_Starters;
        if (this.sortByStarters == 1) {
          this.selected_sortby_starters_tags = "Sort by: by update date";
        }
        if (this.sortByStarters == 2) {
          this.selected_sortby_starters_tags = "Sort by: alphabetically";
        }
        if (this.sortByStarters == 3) {
          this.selected_sortby_starters_tags = "Sort by: by EOI date";
        }
        this.data.sortby_Starters = "";
        this.searchOrSortStartersCandidates();
      }
    },
    searchCandidates: function (tab) {
      // search should at minimum 3 letters/characters.
      if (this.searchDebounce > 0) {
        clearTimeout(this.searchDebounce);
      }
      this.searchDebounce = setTimeout(() => {
        if (tab == 1) {
          this.searchOrSortLiveCandidates();
        }
        if (tab == 2) {
          this.searchOrSortArchivedCandidates();
        }
        if (tab == 3) {
          this.searchOrSortStartersCandidates();
        }
      }, 750);
    },
    fetchUpdatedData: function (elementDistanceToTop) {
      //this.showLoad = true;
      let searchQuery = "";
      let extraParams = "";

      if (this.tabId == 1) {
        if (this.searchVarLive.length > 0) {
          this.query = true;
          searchQuery = "&search=" + this.searchVarLive;
        }
        if (this.export_site_ids.length > 0) {
          this.query = true;
          searchQuery += "&sites=" + this.export_site_ids;
        }
        if (this.export_job_ids.length > 0) {
          this.query = true;
          searchQuery += "&jobId=" + this.export_job_ids;
        }
        if (this.filterStartDate.length > 0) {
          this.query = true;
          searchQuery += "&start_date=" + this.filterStartDate;
        }

        if (this.filterEndDate.length > 0) {
          this.query = true;
          searchQuery += "&end_date=" + this.filterEndDate;
        }
        if (this.seleted_stage.length > 0) {
          this.query = true;
          searchQuery += "&stage=" + this.seleted_stage;
        }
        if (this.export_status_ids.length > 0) {
          this.query = true;
          searchQuery += "&status=" + this.export_status_ids;
        }
        if (this.overdueVal == 1) {
          searchQuery += "&overdue=1";
        }
        extraParams =
          "?tab=live&page=" +
          this.livePaginationData.current_page +
          searchQuery +
          "&sort=" +
          this.sortByLive;
      }
      if (this.tabId == 2) {
        if (this.searchVarArchived.length > 0) {
          this.query = true;
          searchQuery = "&search=" + this.searchVarArchived;
        }
        if (this.export_site_ids_archived.length > 0) {
          this.query = true;
          searchQuery += "&sites=" + this.export_site_ids_archived;
        }
        if (this.export_job_ids_archived.length > 0) {
          this.query = true;
          searchQuery += "&jobId=" + this.export_job_ids_archived;
        }
        if (this.filterStartDate_archived.length > 0) {
          this.query = true;
          searchQuery += "&start_date=" + this.filterStartDate_archived;
        }

        if (this.filterEndDate_archived.length > 0) {
          this.query = true;
          searchQuery += "&end_date=" + this.filterEndDate_archived;
        }
        if (this.seleted_stage_archived.length > 0) {
          this.query = true;
          searchQuery += "&stage=" + this.seleted_stage_archived;
        }
        if (this.export_status_ids_archived.length > 0) {
          this.query = true;
          searchQuery += "&status=" + this.export_status_ids_archived;
        }

        extraParams =
          "?tab=archived&page=" +
          this.archivedPaginationData.current_page +
          searchQuery +
          "&sort=" +
          this.sortByArchived;
      }

      if (this.tabId == 3) {
        if (this.searchVarStarters.length > 0) {
          this.query = true;
          searchQuery = "&search=" + this.searchVarStarters;
        }
        if (this.export_site_ids_starter.length > 0) {
          this.query = true;
          searchQuery += "&sites=" + this.export_site_ids_starter;
        }
        if (this.export_job_ids_starter.length > 0) {
          this.query = true;
          searchQuery += "&jobId=" + this.export_job_ids_starter;
        }
        if (this.filterStartDate_starter.length > 0) {
          this.query = true;
          searchQuery += "&start_date=" + this.filterStartDate_starter;
        }

        if (this.filterEndDate_starter.length > 0) {
          this.query = true;
          searchQuery += "&end_date=" + this.filterEndDate_starter;
        }
        if (this.seleted_stage_starter.length > 0) {
          this.query = true;
          searchQuery += "&stage=" + this.seleted_stage_starter;
        }
        if (this.export_status_ids_starter.length > 0) {
          this.query = true;
          searchQuery += "&status=" + this.export_status_ids_starter;
        }
        extraParams =
          "?tab=starters&page=" +
          this.startersPaginationData.current_page +
          searchQuery +
          "&sort=" +
          this.sortByStarters;
      }
      if (this.siteId) {
        this.query = true;
        extraParams += "&siteId=" + this.siteId;
      }

      var self = this;
      self.showLoad = true;
      self.liveArray = "";
      self.archivedArray = "";
      self.startersArray = "";
      self.message = "";
      axios
        .get("/dashboard/candidates/updated-list" + extraParams)
        .then(function (response) {
          self.message = "";
          self.showLoad = false;
          if (
            (response.data.liveCandidates &&
              response.data.liveCandidates.data.length > 0) ||
            (response.data.archivedCandidates &&
              response.data.archivedCandidates.data.length > 0) ||
            (response.data.startersCandidates &&
              response.data.startersCandidates.data.length > 0)
          ) {
            self.renderCandidates(response.data);
          } else {
            if (self.query == true) {
              self.message = "No candidates match your search.";
            } else {
              if (
                response.data.archivedCandidates &&
                response.data.archivedCandidates.data.length == 0
              ) {
                self.message = "You don't have any archived candidates yet.";
              } else if (
                response.data.startersCandidates &&
                response.data.startersCandidates.data.length == 0
              ) {
                self.message = "You don't have any starters yet.";
              } else if (
                response.data.liveCandidates &&
                response.data.liveCandidates.data.length == 0
              ) {
                self.message = "You don't have any live candidates yet.";
              }
            }
          }
          //self.query = false;
        })
        .then(function () {
          if (elementDistanceToTop){
            const toolbars = document.getElementsByClassName("cf-tool-bar");
            let toolbarHeight = 0;
            toolbars.forEach((toolbar) => {
              toolbarHeight += toolbar.offsetHeight;
            });
            elementDistanceToTop -= toolbarHeight;
            document.getElementsByClassName("tabs-content candidate-width-head")[0].scroll(0, elementDistanceToTop);
          };
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    postDataUpdateProcess: function () {
      this.isActive(this.tabId);
      this.childComponant.$emit("clear");
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },

    isHide() {
      this.isShow = false;
    },

    manageServerDataRequest: function (extraParams = "") {
      if (extraParams == "") {
        if (this.tabId == 1) {
          extraParams = "?tab=live";
          if (this.searchVarLive.length > 0) {
            this.query = true;
            extraParams = "&search=" + this.searchVarLive;
          }

          if (this.overdueVal == 1) {
            extraParams += "&overdue=1";
          }

          if (this.export_site_ids.length > 0) {
            this.query = true;
            extraParams += "&sites=" + this.export_site_ids;
          }
          if (this.export_job_ids.length > 0) {
            this.query = true;
            extraParams += "&jobId=" + this.export_job_ids;
          }
          if (this.filterStartDate.length > 0) {
            this.query = true;
            extraParams += "&start_date=" + this.filterStartDate;
          }

          if (this.filterEndDate.length > 0) {
            this.query = true;
            extraParams += "&end_date=" + this.filterEndDate;
          }
          if (this.seleted_stage.length > 0) {
            this.query = true;
            extraParams += "&stage=" + this.seleted_stage;
          }
          if (this.export_status_ids.length > 0) {
            this.query = true;
            extraParams += "&status=" + this.export_status_ids;
          }
        }
        //for tab 2
        if (this.tabId == 2) {
          extraParams = "?tab=archived";
          if (this.searchVarArchived.length > 0) {
            this.query = true;
            extraParams = "&search=" + this.searchVarArchived;
          }
          if (this.export_site_ids_archived.length > 0) {
            this.query = true;
            extraParams += "&sites=" + this.export_site_ids_archived;
          }
          if (this.export_job_ids_archived.length > 0) {
            this.query = true;
            extraParams += "&jobId=" + this.export_job_ids_archived;
          }
          if (this.filterStartDate_archived.length > 0) {
            this.query = true;
            extraParams += "&start_date=" + this.filterStartDate_archived;
          }

          if (this.filterEndDate_archived.length > 0) {
            this.query = true;
            extraParams += "&end_date=" + this.filterEndDate_archived;
          }
          if (this.seleted_stage_archived.length > 0) {
            this.query = true;
            extraParams += "&stage=" + this.seleted_stage_archived;
          }
          if (this.export_status_ids_archived.length > 0) {
            this.query = true;
            extraParams += "&status=" + this.export_status_ids_archived;
          }
        }
        //for tab 3
        if (this.tabId == 3) {
          extraParams = "?tab=starters";
          if (this.searchVarStarters.length > 0) {
            this.query = true;
            extraParams = "&search=" + this.searchVarStarters;
          }
          if (this.export_site_ids_starter.length > 0) {
            this.query = true;
            extraParams += "&sites=" + this.export_site_ids_starter;
          }
          if (this.export_job_ids_starter.length > 0) {
            this.query = true;
            extraParams += "&jobId=" + this.export_job_ids_starter;
          }
          if (this.filterStartDate_starter.length > 0) {
            this.query = true;
            extraParams += "&start_date=" + this.filterStartDate_starter;
          }

          if (this.filterEndDate_starter.length > 0) {
            this.query = true;
            extraParams += "&end_date=" + this.filterEndDate_starter;
          }
          if (this.seleted_stage_starter.length > 0) {
            this.query = true;
            extraParams += "&stage=" + this.seleted_stage_starter;
          }
          if (this.export_status_ids_starter.length > 0) {
            this.query = true;
            extraParams += "&status=" + this.export_status_ids_starter;
          }
        }
      }

      var self = this;
      self.liveArray = "";
      self.archivedArray = "";
      self.startersArray = "";
      self.showLoad = true;
      this.liveArray = "";
      self.message = "";
      axios
        .get("/dashboard/candidates/updated-list" + extraParams)
        .then(function (response) {
          self.message = "";
          self.showLoad = false;
          if (
            (response.data.liveCandidates &&
              response.data.liveCandidates.data.length > 0) ||
            (response.data.archivedCandidates &&
              response.data.archivedCandidates.data.length > 0) ||
            (response.data.startersCandidates &&
              response.data.startersCandidates.data.length > 0)
          ) {
            self.renderCandidates(response.data);
            self.showLoad = false;
          } else {
            if (self.query == true) {
              self.message = "No candidates match your search.";
            } else {
              if (
                response.data.archivedCandidates &&
                response.data.archivedCandidates.data.length == 0
              ) {
                self.message = "You don't have any archived candidates yet.";
              } else if (
                response.data.startersCandidates &&
                response.data.startersCandidates.data.length == 0
              ) {
                self.message = "You don't have any starters candidates yet.";
              } else if (
                response.data.liveCandidates &&
                response.data.liveCandidates.data.length == 0
              ) {
                self.message = "You don't have any live candidates yet.";
              }
            }
          }
          //self.query = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    loadInitialData: function () {
      if (this.live.hasOwnProperty("data")) {
        this.liveArray = this.live.data;
        this.livePaginationData.current_page = this.live.current_page;
        this.livePaginationData.from = this.live.from;
        this.livePaginationData.last_page = this.live.last_page;
        this.livePaginationData.per_page = this.live.per_page;
        this.livePaginationData.to = this.live.to;
        this.livePaginationData.total = this.live.total;
        this.livePaginationData.tot_overdue = this.live.data.tot_overdue;
      }
      if (this.archived.hasOwnProperty("data")) {
        this.archivedArray = this.archived.data;
        this.archivedPaginationData.current_page = this.archived.current_page;
        this.archivedPaginationData.from = this.archived.from;
        this.archivedPaginationData.last_page = this.archived.last_page;
        this.archivedPaginationData.per_page = this.archived.per_page;
        this.archivedPaginationData.to = this.archived.to;
        this.archivedPaginationData.total = this.archived.total;
      }
      if (this.starters.hasOwnProperty("data")) {
        this.startersArray = this.starters.data;
        this.startersPaginationData.current_page = this.starters.current_page;
        this.startersPaginationData.from = this.starters.from;
        this.startersPaginationData.last_page = this.starters.last_page;
        this.startersPaginationData.per_page = this.starters.per_page;
        this.startersPaginationData.to = this.starters.to;
        this.startersPaginationData.total = this.starters.total;
      }
      this.postDataUpdateProcess();
    },

    livePaginatorClickCallback: function (pageNum) {
      let searchQuery = "";
      if (this.searchVarLive.length > 0) {
        this.query = true;
        searchQuery = "&search=" + this.searchVarLive;
      }
      if (this.export_site_ids.length > 0) {
        this.query = true;
        searchQuery += "&sites=" + this.export_site_ids;
      }
      if (this.export_job_ids.length > 0) {
        this.query = true;
        searchQuery += "&jobId=" + this.export_job_ids;
      }
      if (this.filterStartDate.length > 0) {
        this.query = true;
        searchQuery += "&start_date=" + this.filterStartDate;
      }

      if (this.filterEndDate.length > 0) {
        this.query = true;
        searchQuery += "&end_date=" + this.filterEndDate;
      }
      if (this.seleted_stage.length > 0) {
        this.query = true;
        searchQuery += "&stage=" + this.seleted_stage;
      }
      if (this.export_status_ids.length > 0) {
        this.query = true;
        searchQuery += "&status=" + this.export_status_ids;
      }

      let extraParams =
        "?tab=live&page=" + pageNum + searchQuery + "&sort=" + this.sortByLive;
      if (this.siteId) {
        this.query = true;
        extraParams += "&siteId=" + this.siteId;
      }
      if (this.overdueVal == 1) {
        extraParams += "&overdue=1";
      }
      this.manageServerDataRequest(extraParams);
    },

    archivedPaginatorClickCallback: function (pageNum) {
      this.query = false;
      let searchQuery = "";
      if (this.searchVarArchived.length > 0) {
        this.query = true;
        searchQuery = "&search=" + this.searchVarArchived;
      }

      if (this.export_site_ids_archived.length > 0) {
        this.query = true;
        searchQuery += "&sites=" + this.export_site_ids_archived;
      }
      if (this.export_job_ids_archived.length > 0) {
        this.query = true;
        searchQuery += "&jobId=" + this.export_job_ids_archived;
      }
      if (this.filterStartDate_archived.length > 0) {
        this.query = true;
        searchQuery += "&start_date=" + this.filterStartDate_archived;
      }

      if (this.filterEndDate_archived.length > 0) {
        this.query = true;
        searchQuery += "&end_date=" + this.filterEndDate_archived;
      }
      if (this.seleted_stage_archived.length > 0) {
        this.query = true;
        searchQuery += "&stage=" + this.seleted_stage_archived;
      }
      if (this.export_status_ids_archived.length > 0) {
        this.query = true;
        searchQuery += "&status=" + this.export_status_ids_archived;
      }
      let extraParams =
        "?tab=archived&page=" +
        pageNum +
        searchQuery +
        "&sort=" +
        this.sortByArchived;
      if (this.siteId) {
        this.query = true;
        extraParams += "&siteId=" + this.siteId;
      }
      this.manageServerDataRequest(extraParams);
    },

    startersPaginatorClickCallback: function (pageNum) {
      this.query = false;
      let searchQuery = "";
      if (this.searchVarStarters.length > 0) {
        this.query = true;
        searchQuery = "&search=" + this.searchVarStarters;
      }

      if (this.export_site_ids_starter.length > 0) {
        this.query = true;
        searchQuery += "&sites=" + this.export_site_ids_starter;
      }
      if (this.export_job_ids_starter.length > 0) {
        this.query = true;
        searchQuery += "&jobId=" + this.export_job_ids_starter;
      }
      if (this.filterStartDate_starter.length > 0) {
        this.query = true;
        searchQuery += "&start_date=" + this.filterStartDate_starter;
      }

      if (this.filterEndDate_starter.length > 0) {
        this.query = true;
        searchQuery += "&end_date=" + this.filterEndDate_starter;
      }
      if (this.seleted_stage_starter.length > 0) {
        this.query = true;
        searchQuery += "&stage=" + this.seleted_stage_starter;
      }
      if (this.export_status_ids_starter.length > 0) {
        this.query = true;
        searchQuery += "&status=" + this.export_status_ids_starter;
      }

      let extraParams =
        "?tab=starters&page=" +
        pageNum +
        searchQuery +
        "&sort=" +
        this.sortByStarters;
      if (this.siteId) {
        this.query = true;
        extraParams += "&siteId=" + this.siteId;
      }
      this.manageServerDataRequest(extraParams);
    },

    searchOrSortLiveCandidates: function () {
      let extraParams = "?tab=live&sort=" + this.sortByLive;
      if (this.searchVarLive.length > 0) {
        this.query = true;
        extraParams += "&search=" + this.searchVarLive;
      }
      if (this.siteId) {
        this.query = true;
        extraParams += "&siteId=" + this.siteId;
      }
      if (this.export_site_ids.length > 0) {
        this.query = true;
        extraParams += "&sites=" + this.export_site_ids;
      }

      if (this.overdueVal == 1) {
        extraParams += "&overdue=1";
      }
      if (this.export_job_ids.length > 0) {
        this.query = true;
        extraParams += "&jobId=" + this.export_job_ids;
      }
      if (this.filterStartDate.length > 0) {
        this.query = true;
        extraParams += "&start_date=" + this.filterStartDate;
      }

      if (this.filterEndDate.length > 0) {
        this.query = true;
        extraParams += "&end_date=" + this.filterEndDate;
      }
      if (this.seleted_stage.length > 0) {
        this.query = true;
        extraParams += "&stage=" + this.seleted_stage;
      }
      if (this.export_status_ids.length > 0) {
        this.query = true;
        extraParams += "&status=" + this.export_status_ids;
      }
      this.manageServerDataRequest(extraParams);
    },

    searchOrSortArchivedCandidates: function () {
      this.query = false;
      let extraParams = "?tab=archived&sort=" + this.sortByArchived;
      if (this.searchVarArchived.length > 0) {
        this.query = true;
        extraParams += "&search=" + this.searchVarArchived;
      }
      if (this.siteId) {
        this.query = true;
        extraParams += "&siteId=" + this.siteId;
      }
      if (this.export_site_ids_archived.length > 0) {
        this.query = true;
        extraParams += "&sites=" + this.export_site_ids_archived;
      }
      if (this.export_job_ids_archived.length > 0) {
        this.query = true;
        extraParams += "&jobId=" + this.export_job_ids_archived;
      }
      if (this.filterStartDate_archived.length > 0) {
        this.query = true;
        extraParams += "&start_date=" + this.filterStartDate_archived;
      }

      if (this.filterEndDate_archived.length > 0) {
        this.query = true;
        extraParams += "&end_date=" + this.filterEndDate_archived;
      }
      if (this.seleted_stage_archived.length > 0) {
        this.query = true;
        extraParams += "&stage=" + this.seleted_stage_archived;
      }
      if (this.export_status_ids_archived.length > 0) {
        this.query = true;
        extraParams += "&status=" + this.export_status_ids_archived;
      }
      this.manageServerDataRequest(extraParams);
    },

    searchOrSortStartersCandidates: function () {
      this.query = false;
      let extraParams = "?tab=starters&sort=" + this.sortByStarters;
      if (this.searchVarStarters.length > 0) {
        this.query = true;
        extraParams += "&search=" + this.searchVarStarters;
      }
      if (this.siteId) {
        this.query = true;
        extraParams += "&siteId=" + this.siteId;
      }
      if (this.export_site_ids_starter.length > 0) {
        this.query = true;
        extraParams += "&sites=" + this.export_site_ids_starter;
      }
      if (this.export_job_ids_starter.length > 0) {
        this.query = true;
        extraParams += "&jobId=" + this.export_job_ids_starter;
      }
      if (this.filterStartDate_starter.length > 0) {
        this.query = true;
        extraParams += "&start_date=" + this.filterStartDate_starter;
      }

      if (this.filterEndDate_starter.length > 0) {
        this.query = true;
        extraParams += "&end_date=" + this.filterEndDate_starter;
      }
      if (this.seleted_stage_starter.length > 0) {
        this.query = true;
        extraParams += "&stage=" + this.seleted_stage_starter;
      }
      if (this.export_status_ids_starter.length > 0) {
        this.query = true;
        extraParams += "&status=" + this.export_status_ids_starter;
      }
      this.manageServerDataRequest(extraParams);
    },

    filtertoggle(tab) {
      if (tab == 1) {
        if (this.showFilterLive == true) {
          this.showFilterLive = false;
        } else {
          this.showFilterLive = true;
        }
      }
      if (tab == 2) {
        if (this.showFilterArchived == true) {
          this.showFilterArchived = false;
        } else {
          this.showFilterArchived = true;
        }
      }
      if (tab == 3) {
        if (this.showFilterStarter == true) {
          this.showFilterStarter = false;
        } else {
          this.showFilterStarter = true;
        }
      }
    },
    addTag(event) {
      $(".cancelBtn").trigger("click");
      // alert(event.startDate.toLocaleDateString());
      var newstartDate = event.startDate.getDate();
      var newstartMonth = event.startDate.getMonth() + 1;
      var newstartYear = event.startDate.getFullYear();
      var FinalStartDate =
        newstartYear + "-" + newstartMonth + "-" + newstartDate;
      var FinalStartDatePrint =
        newstartDate + "-" + newstartMonth + "-" + newstartYear;
      var newendDate = event.endDate.getDate();
      var newendMonth = event.endDate.getMonth() + 1;
      var newendYear = event.endDate.getFullYear();
      var FinalEndDate = newendYear + "-" + newendMonth + "-" + newendDate;
      var FinalEndDatePrint = newendDate + "-" + newendMonth + "-" + newendYear;

      var Difference_In_Days =
        (event.endDate - event.startDate) / (1000 * 3600 * 24);

      this.filterStartDate = FinalStartDate;
      this.filterEndDate = FinalEndDate;
      var d = new Date();
      var currentDate = d.getDate();
      var currentMonth = d.getMonth() + 1;
      var currentYear = d.getFullYear();
      // this method evaulate 'This Month' or 'Last Month' conditions.
      let monthSpan = this.evaluateMonthSpan(
        newstartMonth,
        Difference_In_Days,
        newstartDate,
        newstartYear
      );

      if (FinalStartDate == FinalEndDate) {
        var todayDate = currentYear + "-" + currentMonth + "-" + currentDate;
        var yesDate =
          currentYear + "-" + currentMonth + "-" + (currentDate - 1);

        if (FinalStartDate == todayDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Today  ");
        } else if (FinalStartDate == yesDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Yesterday ");
        } else {
          this.removeTag(this.tags.length - 1);
          this.tags.push(FinalStartDatePrint + " to " + FinalEndDatePrint);
        }
      } else if (Difference_In_Days > 1 && Difference_In_Days < 28) {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDatePrint + " to " + FinalEndDatePrint);
      } else if (
        currentMonth == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This Month ");
      } else if (
        currentMonth - 1 == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("Last Month ");
      } else if (currentYear && Difference_In_Days == 365) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This year ");
      } else {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDatePrint + " to " + FinalEndDatePrint);
      }
      this.selected_date_tags = "Date: " + this.tags[0];
      this.searchOrSortLiveCandidates();
    },
    removeTag(index) {
      /*if (index === 0){
                    this.filterStartDate = '';
                    this.filterEndDate = '';
                }*/
      this.selected_date_tags = "";
      this.tags.splice(index, 1);
      this.searchOrSortLiveCandidates();
    },

    addTagArchived(event) {
      $(".cancelBtn").trigger("click");
      var newstartDate = event.startDate.getDate();
      var newstartMonth = event.startDate.getMonth() + 1;
      var newstartYear = event.startDate.getFullYear();
      var FinalStartDate =
        newstartYear + "-" + newstartMonth + "-" + newstartDate;
      var FinalStartDatePrint =
        newstartDate + "-" + newstartMonth + "-" + newstartYear;
      var newendDate = event.endDate.getDate();
      var newendMonth = event.endDate.getMonth() + 1;
      var newendYear = event.endDate.getFullYear();
      var FinalEndDate = newendYear + "-" + newendMonth + "-" + newendDate;
      var FinalEndDatePrint = newendDate + "-" + newendMonth + "-" + newendYear;

      var Difference_In_Days =
        (event.endDate - event.startDate) / (1000 * 3600 * 24);

      this.filterStartDate_archived = FinalStartDate;
      this.filterEndDate_archived = FinalEndDate;
      var d = new Date();
      var currentDate = d.getDate();
      var currentMonth = d.getMonth() + 1;
      var currentYear = d.getFullYear();
      // this method evaulate 'This Month' or 'Last Month' conditions.
      let monthSpan = this.evaluateMonthSpan(
        newstartMonth,
        Difference_In_Days,
        newstartDate,
        newstartYear
      );

      if (FinalStartDate == FinalEndDate) {
        var todayDate = currentYear + "-" + currentMonth + "-" + currentDate;
        var yesDate =
          currentYear + "-" + currentMonth + "-" + (currentDate - 1);

        if (FinalStartDate == todayDate) {
          this.removeTagArchived(this.tags_archived.length - 1);
          this.tags_archived.push("Today  ");
        } else if (FinalStartDate == yesDate) {
          this.removeTagArchived(this.tags_archived.length - 1);
          this.tags_archived.push("Yesterday ");
        } else {
          this.removeTagArchived(this.tags_archived.length - 1);
          this.tags_archived.push(
            FinalStartDatePrint + " to " + FinalEndDatePrint
          );
        }
      } else if (Difference_In_Days > 1 && Difference_In_Days < 28) {
        this.removeTagArchived(this.tags_archived.length - 1);
        this.tags_archived.push(
          FinalStartDatePrint + " to " + FinalEndDatePrint
        );
      } else if (
        currentMonth == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTagArchived(this.tags_archived.length - 1);
        this.tags_archived.push("This Month ");
      } else if (
        currentMonth - 1 == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTagArchived(this.tags_archived.length - 1);
        this.tags_archived.push("Last Month ");
      } else if (currentYear && Difference_In_Days == 365) {
        this.removeTagArchived(this.tags_archived.length - 1);
        this.tags_archived.push("This year ");
      } else {
        this.removeTagArchived(this.tags_archived.length - 1);
        this.tags_archived.push(
          FinalStartDatePrint + " to " + FinalEndDatePrint
        );
      }
      this.selected_date_tags_archived = "Date: " + this.tags_archived[0];
      this.searchOrSortArchivedCandidates();
    },
    removeTagArchived(index) {
      /*if (index === 0){
                    this.filterStartDate = '';
                    this.filterEndDate = '';
                }*/
      this.selected_date_tags_archived = "";
      this.tags_archived.splice(index, 1);
      this.searchOrSortArchivedCandidates();
    },
    addTagStarter(event) {
      $(".cancelBtn").trigger("click");
      var newstartDate = event.startDate.getDate();
      var newstartMonth = event.startDate.getMonth() + 1;
      var newstartYear = event.startDate.getFullYear();
      var FinalStartDate =
        newstartYear + "-" + newstartMonth + "-" + newstartDate;
      var FinalStartDatePrint =
        newstartDate + "-" + newstartMonth + "-" + newstartYear;
      var newendDate = event.endDate.getDate();
      var newendMonth = event.endDate.getMonth() + 1;
      var newendYear = event.endDate.getFullYear();
      var FinalEndDate = newendYear + "-" + newendMonth + "-" + newendDate;
      var FinalEndDatePrint = newendDate + "-" + newendMonth + "-" + newendYear;

      var Difference_In_Days =
        (event.endDate - event.startDate) / (1000 * 3600 * 24);

      this.filterStartDate_starter = FinalStartDate;
      this.filterEndDate_starter = FinalEndDate;
      var d = new Date();
      var currentDate = d.getDate();
      var currentMonth = d.getMonth() + 1;
      var currentYear = d.getFullYear();
      // this method evaulate 'This Month' or 'Last Month' conditions.
      let monthSpan = this.evaluateMonthSpan(
        newstartMonth,
        Difference_In_Days,
        newstartDate,
        newstartYear
      );

      if (FinalStartDate == FinalEndDate) {
        var todayDate = currentYear + "-" + currentMonth + "-" + currentDate;
        var yesDate =
          currentYear + "-" + currentMonth + "-" + (currentDate - 1);

        if (FinalStartDate == todayDate) {
          this.removeTagStarter(this.tags_starter.length - 1);
          this.tags_starter.push("Today  ");
        } else if (FinalStartDate == yesDate) {
          this.removeTagStarter(this.tags_starter.length - 1);
          this.tags_starter.push("Yesterday ");
        } else {
          this.removeTagStarter(this.tags_starter.length - 1);
          this.tags_starter.push(
            FinalStartDatePrint + " to " + FinalEndDatePrint
          );
        }
      } else if (Difference_In_Days > 1 && Difference_In_Days < 28) {
        this.removeTagStarter(this.tags_starter.length - 1);
        this.tags_starter.push(
          FinalStartDatePrint + " to " + FinalEndDatePrint
        );
      } else if (
        currentMonth == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTagStarter(this.tags_starter.length - 1);
        this.tags_starter.push("This Month ");
      } else if (
        currentMonth - 1 == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTagStarter(this.tags_starter.length - 1);
        this.tags_starter.push("Last Month ");
      } else if (currentYear && Difference_In_Days == 365) {
        this.removeTagStarter(this.tags_starter.length - 1);
        this.tags_starter.push("This year ");
      } else {
        this.removeTagStarter(this.tags_starter.length - 1);
        this.tags_starter.push(
          FinalStartDatePrint + " to " + FinalEndDatePrint
        );
      }
      this.selected_date_tags_starter = "Date: " + this.tags_starter[0];
      this.searchOrSortArchivedCandidates();
    },
    removeTagStarter(index) {
      /*if (index === 0){
                    this.filterStartDate = '';
                    this.filterEndDate = '';
                }*/
      this.selected_date_tags_starter = "";
      this.tags_starter.splice(index, 1);
      this.searchOrSortStartersCandidates();
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.filterStartDate = "";
        this.filterEndDate = "";
        this.removeTag(this.tags.length - 1);
      }
    },

    evaluateMonthSpan: function (
      newstartMonth,
      Difference_In_Days,
      newstartDate,
      newstartYear
    ) {
      // Transform below code to find total days in month.
      let monthTotalDays = [
        31, // Jan
        28, // Feb
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // Aug
        30, // Sep
        31, // Oct
        30, // Nov
        31, // Dec
      ];

      let monthIndex = newstartMonth - 1;
      let monthDays = monthTotalDays[monthIndex];
      let totalSelectedSpan = Difference_In_Days + 1; // catering previous dev coded result.

      // February Case
      if (newstartMonth == 2) {
        // When it is leap year like 2020, Feb would be off 29
        if (
          newstartYear % 4 == 0 &&
          totalSelectedSpan == 29 &&
          newstartDate == 1
        ) {
          return true;
        } else if (
          newstartYear % 4 != 0 &&
          totalSelectedSpan == 28 &&
          newstartDate == 1
        ) {
          return true;
        }
        return false;
      } else {
        // Non February Cases
        if (totalSelectedSpan == monthDays && newstartDate == 1) {
          return true;
        }
        return false;
      }
    },
    showOverdue() {
      this.showFilterLive = true;
      this.overdueVal = 1;
      if (this.overdueVal > 0) {
        this.selected_overdue_tags = "Overdue: yes";
      }
      this.searchOrSortLiveCandidates();
    },

    removeOverdueTag() {
      this.selected_overdue_tags = 0;
      this.overdueVal = 0;
      this.searchOrSortLiveCandidates();
    },
    seletedstage: function (value, tab) {
      if (tab == 1) {
        this.seleted_stage = value;

        if (
          this.userCount > 0 &&
          this.seleted_stage.length > 0 &&
          this.seleted_stage.length < this.userCount
        ) {
          if (this.seleted_stage.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.seleted_stage.length;
        } else if (this.seleted_stage.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.seleted_stage) {
          if (this.seleted_stage.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.seleted_stage.length;
        }

        /**
         * Code to managed selected tags for stage
         */
        let selected_staged = [];
        let i;
        for (i = 0; i < this.stageOptions.length; ++i) {
          if ($.inArray(this.stageOptions[i].id, this.seleted_stage) !== -1) {
            this.stageOptions[i].image = "/images/selected.png";
            selected_staged.push(this.stageOptions[i].name);
          }
        }
        if (selected_staged.length > 0) {
          this.selected_stage_tags = "Stage: " + selected_staged.join(", ");
        }

        this.searchOrSortLiveCandidates();
      }

      if (tab == 2) {
        this.seleted_stage_archived = value;

        if (
          this.userCount > 0 &&
          this.seleted_stage_archived.length > 0 &&
          this.seleted_stage_archived.length < this.userCount
        ) {
          if (this.seleted_stage_archived.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.seleted_stage_archived.length;
        } else if (this.seleted_stage_archived.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.seleted_stage_archived) {
          if (this.seleted_stage_archived.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.seleted_stage_archived.length;
        }

        /**
         * Code to managed selected tags for stage
         */
        let selected_staged = [];
        let i;
        for (i = 0; i < this.stageOptions.length; ++i) {
          if (
            $.inArray(this.stageOptions[i].id, this.seleted_stage_archived) !==
            -1
          ) {
            this.stageOptions[i].image_archived = "/images/selected.png";
            selected_staged.push(this.stageOptions[i].name);
          }
        }
        if (selected_staged.length > 0) {
          this.selected_stage_tags_archived =
            "Stage: " + selected_staged.join(", ");
        }

        this.searchOrSortArchivedCandidates();
      }

      if (tab == 3) {
        this.seleted_stage_starter = value;
        if (
          this.userCount > 0 &&
          this.seleted_stage_starter.length > 0 &&
          this.seleted_stage_starter.length < this.userCount
        ) {
          if (this.seleted_stage_starter.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.seleted_stage_starter.length;
        } else if (this.seleted_stage_starter.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.seleted_stage_starter) {
          if (this.seleted_stage_starter.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.seleted_stage_starter.length;
        }

        /**
         * Code to managed selected tags for stage
         */
        let selected_staged = [];
        let i;
        for (i = 0; i < this.stageOptions.length; ++i) {
          if (
            $.inArray(this.stageOptions[i].id, this.seleted_stage_starter) !==
            -1
          ) {
            this.stageOptions[i].image_starter = "/images/selected.png";
            selected_staged.push(this.stageOptions[i].name);
          }
        }
        if (selected_staged.length > 0) {
          this.selected_stage_tags_starter =
            "Stage: " + selected_staged.join(", ");
        }

        this.searchOrSortStartersCandidates();
      }
    },
    selectedJobs: function (value, tab) {
      if (tab == 1) {
        this.export_job_ids = value;
        if (
          this.userCount > 0 &&
          this.export_job_ids.length > 0 &&
          this.export_job_ids.length < this.userCount
        ) {
          if (this.export_job_ids.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.export_job_ids.length;
        } else if (this.export_job_ids.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.export_job_ids) {
          if (this.export_job_ids.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.export_job_ids.length;
        }

        /**
         * Code to managed selected tags for jobs
         */
        let selected_jobs = [];
        let i;
        for (i = 0; i < this.availableJobs.length; ++i) {
          if ($.inArray(this.availableJobs[i].id, this.export_job_ids) !== -1) {
            this.availableJobs[i].image = "/images/selected.png";
            selected_jobs.push(this.availableJobs[i].name);
          }
        }
        if (selected_jobs.length > 0) {
          this.selected_jobs_tags = "Jobs: " + selected_jobs.join(", ");
        }
        this.searchOrSortLiveCandidates();
      }
      if (tab == 2) {
        this.export_job_ids_archived = value;
        if (
          this.userCount > 0 &&
          this.export_job_ids_archived.length > 0 &&
          this.export_job_ids_archived.length < this.userCount
        ) {
          if (this.export_job_ids_archived.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.export_job_ids_archived.length;
        } else if (this.export_job_ids_archived.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.export_job_ids_archived) {
          if (this.export_job_ids_archived.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.export_job_ids_archived.length;
        }

        /**
         * Code to managed selected tags for jobs
         */
        let selected_jobs_archived = [];
        let i;
        for (i = 0; i < this.availableJobs.length; ++i) {
          if (
            $.inArray(
              this.availableJobs[i].id,
              this.export_job_ids_archived
            ) !== -1
          ) {
            this.availableJobs[i].image_archived = "/images/selected.png";
            selected_jobs_archived.push(this.availableJobs[i].name);
          }
        }
        if (selected_jobs_archived.length > 0) {
          this.selected_jobs_tags_archived =
            "Jobs: " + selected_jobs_archived.join(", ");
        }
        this.searchOrSortArchivedCandidates();
      }
      if (tab == 3) {
        this.export_job_ids_starter = value;
        if (
          this.userCount > 0 &&
          this.export_job_ids_starter.length > 0 &&
          this.export_job_ids_starter.length < this.userCount
        ) {
          if (this.export_job_ids_starter.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.export_job_ids_starter.length;
        } else if (this.export_job_ids_starter.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.export_job_ids_starter) {
          if (this.export_job_ids_starter.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.export_job_ids_starter.length;
        }

        /**
         * Code to managed selected tags for jobs
         */
        let selected_jobs_starter = [];
        let i;
        for (i = 0; i < this.availableJobs.length; ++i) {
          if (
            $.inArray(this.availableJobs[i].id, this.export_job_ids_starter) !==
            -1
          ) {
            this.availableJobs[i].image_starter = "/images/selected.png";
            selected_jobs_starter.push(this.availableJobs[i].name);
          }
        }
        if (selected_jobs_starter.length > 0) {
          this.selected_jobs_tags_starter =
            "Jobs: " + selected_jobs_starter.join(", ");
        }
        this.searchOrSortStartersCandidates();
      }
    },
    seletedsites: function (value, tab) {
      if (tab == 1) {
        this.export_site_ids = value;
        this.data.sites = "";
        /**
         * Code to managed selected tags for site
         */
        let selected_sites = [];
        let i;
        for (i = 0; i < this.availableSites.length; ++i) {
          if (
            $.inArray(this.availableSites[i].id, this.export_site_ids) !== -1
          ) {
            this.availableSites[i].image = "/images/selected.png";
            selected_sites.push(this.availableSites[i].name);
          } else {
            if (this.availableSites[i].image == "/images/selected.png") {
              this.availableSites[i].image = "/images/idle.png";
            }
          }
        }

        if (selected_sites.length > 0) {
          this.selected_sites_tags = "Sites: " + selected_sites;
        }
        this.searchOrSortLiveCandidates();
      }

      if (tab == 2) {
        this.export_site_ids_archived = value;
        this.data.sites_archived = "";

        /**
         * Code to managed selected tags for site
         */
        let selected_sites = [];
        let i;
        for (i = 0; i < this.availableSites.length; ++i) {
          if (
            $.inArray(
              this.availableSites[i].id,
              this.export_site_ids_archived
            ) !== -1
          ) {
            this.availableSites[i].image_archived = "/images/selected.png";
            selected_sites.push(this.availableSites[i].name);
          } else {
            if (
              this.availableSites[i].image_archived == "/images/selected.png"
            ) {
              this.availableSites[i].image_archived = "/images/idle.png";
            }
          }
        }
        if (selected_sites.length > 0) {
          this.selected_sites_tags_archived =
            "Sites: " + selected_sites.join(", ");
        }
        this.searchOrSortArchivedCandidates();
      }

      if (tab == 3) {
        this.export_site_ids_starter = value;
        this.data.sites_starter = "";

        /**
         * Code to managed selected tags for site
         */
        let selected_sites = [];
        let i;
        for (i = 0; i < this.availableSites.length; ++i) {
          if (
            $.inArray(
              this.availableSites[i].id,
              this.export_site_ids_starter
            ) !== -1
          ) {
            this.availableSites[i].image_starter = "/images/selected.png";
            selected_sites.push(this.availableSites[i].name);
          } else {
            if (
              this.availableSites[i].image_starter == "/images/selected.png"
            ) {
              this.availableSites[i].image_starter = "/images/idle.png";
            }
          }
        }
        if (selected_sites.length > 0) {
          this.selected_sites_tags_starter =
            "Sites: " + selected_sites.join(", ");
        }
        this.searchOrSortStartersCandidates();
      }
    },
    seletedStatus: function (value, tab) {
      if (tab == 1) {
        this.export_status_ids = value;
        if (
          this.userCount > 0 &&
          this.export_status_ids.length > 0 &&
          this.export_status_ids.length < this.userCount
        ) {
          if (this.export_status_ids.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.export_status_ids.length;
        } else if (this.export_status_ids.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.export_status_ids) {
          if (this.export_status_ids.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.export_status_ids.length;
        }

        /**
         * Code to managed selected tags for site
         */
        let selected_status = [];
        let i;
        for (i = 0; i < this.statusOptions.length; ++i) {
          if (
            $.inArray(this.statusOptions[i].id, this.export_status_ids) !== -1
          ) {
            this.statusOptions[i].image = "/images/selected.png";
            selected_status.push(this.statusOptions[i].name);
          }
        }
        if (selected_status.length > 0) {
          this.selected_status_tags = "Status: " + selected_status.join(", ");
        }
        var employedmonthsyes = value.indexOf("yes");
        if (employedmonthsyes !== -1) {
          this.seleted_stage = "Employed for 12 months";
        }
        var employedmonthsno = value.indexOf("no");
        if (employedmonthsno !== -1) {
          this.seleted_stage = "Employed for 12 months";
        }
        var Interview_successful = value.indexOf("Interview successful");
        if (Interview_successful !== -1) {
          value[Interview_successful] = "yes";
          this.seleted_stage = "Interview successful";
        }
        var Interview_unsuccessful = value.indexOf("Interview unsuccessful");
        if (Interview_unsuccessful !== -1) {
          value[Interview_unsuccessful] = "no";
          this.seleted_stage = "Interview successful";
        }
        var Job_offered_accepted = value.indexOf("Job offered - accepted");
        if (Job_offered_accepted !== -1) {
          value[Job_offered_accepted] = "yes";
          this.seleted_stage = "Job offered";
        }
        var Job_not_offered_archive = value.indexOf(
          "Job not offered - archive"
        );
        if (Job_not_offered_archive !== -1) {
          value[Job_not_offered_archive] = "no";
          this.seleted_stage = "Job offered";
        }
        var Started_work = value.indexOf("Started work");
        if (Started_work !== -1) {
          value[Started_work] = "yes";
          this.seleted_stage = "Started work";
        }
        var Failed_to_start_archive = value.indexOf(
          "Failed to start - archive"
        );
        if (Failed_to_start_archive !== -1) {
          value[Failed_to_start_archive] = "no";
          this.seleted_stage = "Started work";
        }
        var No_show_reschedule = value.indexOf("No show - reschedule");
        if (No_show_reschedule !== -1) {
          value[No_show_reschedule] = "no_show";
          this.seleted_stage = "Interview successful";
        }
        var Job_offered_not_accepted = value.indexOf(
          "Job offered - not accepted"
        );
        if (Job_offered_not_accepted !== -1) {
          value[Job_offered_not_accepted] = "no_show";
          this.seleted_stage = "Job offered";
        }

        this.searchOrSortLiveCandidates();
      }

      if (tab == 2) {
        this.export_status_ids_archived = value;
        if (
          this.userCount > 0 &&
          this.export_status_ids_archived.length > 0 &&
          this.export_status_ids_archived.length < this.userCount
        ) {
          if (this.export_status_ids_archived.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.export_status_ids_archived.length;
        } else if (this.export_status_ids_archived.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.export_status_ids_archived) {
          if (this.export_status_ids_archived.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.export_status_ids_archived.length;
        }

        /**
         * Code to managed selected tags for site
         */
        let selected_status = [];
        let i;
        for (i = 0; i < this.statusOptions.length; ++i) {
          if (
            $.inArray(
              this.statusOptions[i].id,
              this.export_status_ids_archived
            ) !== -1
          ) {
            this.statusOptions[i].image_archived = "/images/selected.png";
            selected_status.push(this.statusOptions[i].name);
          }
        }
        if (selected_status.length > 0) {
          this.selected_status_tags_archived =
            "Status: " + selected_status.join(", ");
        }

        var employedmonthsyes = value.indexOf("yes");
        if (employedmonthsyes !== -1) {
          this.seleted_stage_archived = "Employed for 12 months";
        }
        var employedmonthsno = value.indexOf("no");
        if (employedmonthsno !== -1) {
          this.seleted_stage_archived = "Employed for 12 months";
        }
        var Interview_successful = value.indexOf("Interview successful");
        if (Interview_successful !== -1) {
          value[Interview_successful] = "yes";
          this.seleted_stage_archived = "Interview successful";
        }
        var Interview_unsuccessful = value.indexOf("Interview unsuccessful");
        if (Interview_unsuccessful !== -1) {
          value[Interview_unsuccessful] = "no";
          this.seleted_stage_archived = "Interview successful";
        }
        var Job_offered_accepted = value.indexOf("Job offered - accepted");
        if (Job_offered_accepted !== -1) {
          value[Job_offered_accepted] = "yes";
          this.seleted_stage_archived = "Job offered";
        }
        var Job_not_offered_archive = value.indexOf(
          "Job not offered - archive"
        );
        if (Job_not_offered_archive !== -1) {
          value[Job_not_offered_archive] = "no";
          this.seleted_stage_archived = "Job offered";
        }
        var Started_work = value.indexOf("Started work");
        if (Started_work !== -1) {
          value[Started_work] = "yes";
          this.seleted_stage_archived = "Started work";
        }
        var Failed_to_start_archive = value.indexOf(
          "Failed to start - archive"
        );
        if (Failed_to_start_archive !== -1) {
          value[Failed_to_start_archive] = "no";
          this.seleted_stage_archived = "Started work";
        }
        var No_show_reschedule = value.indexOf("No show - reschedule");
        if (No_show_reschedule !== -1) {
          value[No_show_reschedule] = "no_show";
          this.seleted_stage_archived = "Interview successful";
        }
        var Job_offered_not_accepted = value.indexOf(
          "Job offered - not accepted"
        );
        if (Job_offered_not_accepted !== -1) {
          value[Job_offered_not_accepted] = "no_show";
          this.seleted_stage_archived = "Job offered";
        }

        this.searchOrSortArchivedCandidates();
      }

      if (tab == 3) {
        this.export_status_ids_starter = value;
        if (
          this.userCount > 0 &&
          this.export_status_ids_starter.length > 0 &&
          this.export_status_ids_starter.length < this.userCount
        ) {
          if (this.export_status_ids_starter.length % 2 == 0) {
            this.val -= 4;
          }
          this.userCount = this.export_status_ids_starter.length;
        } else if (this.export_status_ids_starter.length == 0) {
          this.val = 0;
          this.userCount = 0;
        } else if (this.export_status_ids_starter) {
          if (this.export_status_ids_starter.length % 2 == 0) {
            this.val += 4;
          }
          this.userCount = this.export_status_ids_starter.length;
        }

        /**
         * Code to managed selected tags for site
         */
        let selected_status = [];
        let i;
        for (i = 0; i < this.statusOptions.length; ++i) {
          if (
            $.inArray(
              this.statusOptions[i].id,
              this.export_status_ids_starter
            ) !== -1
          ) {
            this.statusOptions[i].image_starter = "/images/selected.png";
            selected_status.push(this.statusOptions[i].name);
          }
        }
        if (selected_status.length > 0) {
          this.selected_status_tags_starter =
            "Status: " + selected_status.join(", ");
        }

        if (value == "yes") {
          this.seleted_stage_starter = "Employed for 12 months";
        }

        if (value == "no") {
          this.seleted_stage_starter = "Employed for 12 months";
        }
        var Interview_successful = value.indexOf("Interview successful");
        if (Interview_successful !== -1) {
          value[Interview_successful] = "yes";
          this.seleted_stage_starter = "Interview successful";
        }
        var Interview_unsuccessful = value.indexOf("Interview unsuccessful");
        if (Interview_unsuccessful !== -1) {
          value[Interview_unsuccessful] = "no";
          this.seleted_stage_starter = "Interview successful";
        }
        var Job_offered_accepted = value.indexOf("Job offered - accepted");
        if (Job_offered_accepted !== -1) {
          value[Job_offered_accepted] = "yes";
          this.seleted_stage_starter = "Job offered";
        }
        var Job_not_offered_archive = value.indexOf(
          "Job not offered - archive"
        );
        if (Job_not_offered_archive !== -1) {
          value[Job_not_offered_archive] = "no";
          this.seleted_stage_starter = "Job offered";
        }
        var Started_work = value.indexOf("Started work");
        if (Started_work !== -1) {
          value[Started_work] = "yes";
          this.seleted_stage_starter = "Started work";
        }
        var Failed_to_start_archive = value.indexOf(
          "Failed to start - archive"
        );
        if (Failed_to_start_archive !== -1) {
          value[Failed_to_start_archive] = "no";
          this.seleted_stage_starter = "Started work";
        }
        var No_show_reschedule = value.indexOf("No show - reschedule");
        if (No_show_reschedule !== -1) {
          value[No_show_reschedule] = "no_show";
          this.seleted_stage_starter = "Interview successful";
        }
        var Job_offered_not_accepted = value.indexOf(
          "Job offered - not accepted"
        );
        if (Job_offered_not_accepted !== -1) {
          value[Job_offered_not_accepted] = "no_show";
          this.seleted_stage_starter = "Job offered";
        }

        this.searchOrSortStartersCandidates();
      }
    },
    removeSortbyTags: function (tab) {
      //this.removeTag(0);
      if (tab == 1) {
        this.$refs.sortFilter.clearSelection();
        this.selected_sortby_tags = "";
        this.searchOrSortLiveCandidates();
      }
      if (tab == 2) {
        this.$refs.sortArchivedFilter.clearSelection();
        this.selected_sortby_archived_tags = "";
        this.searchOrSortArchivedCandidates();
      }
      if (tab == 3) {
        this.$refs.sortStartersFilter.clearSelection();
        this.selected_sortby_starters_tags = "";
        this.searchOrSortStartersCandidates();
      }
    },
    removeDateTags: function (tab) {
      //this.removeTag(0);
      if (tab == 1) {
        this.filterStartDate = "";
        this.filterEndDate = "";
        this.selected_date_tags = "";
        this.searchOrSortLiveCandidates();
      }
      if (tab == 2) {
        this.filterStartDate_archived = "";
        this.filterEndDate_archived = "";
        this.selected_date_tags_archived = "";
        this.searchOrSortArchivedCandidates();
      }
      if (tab == 3) {
        this.filterStartDate_starter = "";
        this.filterEndDate_starter = "";
        this.selected_date_tags_starter = "";
        this.searchOrSortStartersCandidates();
      }
    },
    removeStageTags: function (tab) {
      if (tab == 1) {
        this.$refs.stageFilter.clearSelection();
        this.selected_stage_tags = "";
        if (this.stageOptions) {
          for (i = 0; i < this.stageOptions.length; i++) {
            if (this.stageOptions[i].image == "/images/selected.png") {
              this.stageOptions[i].image = "/images/idle.png";
            }
          }
        }
      }
      if (tab == 2) {
        this.$refs.stageFilterArchived.clearSelection();
        this.selected_stage_tags_archived = "";
        if (this.stageOptions) {
          for (i = 0; i < this.stageOptions.length; i++) {
            if (this.stageOptions[i].image_archived == "/images/selected.png") {
              this.stageOptions[i].image_archived = "/images/idle.png";
            }
          }
        }
      }
      if (tab == 3) {
        this.$refs.stageFilterStarter.clearSelection();
        this.selected_stage_tags_starter = "";
        if (this.stageOptions) {
          for (i = 0; i < this.stageOptions.length; i++) {
            if (this.stageOptions[i].image_starter == "/images/selected.png") {
              this.stageOptions[i].image_starter = "/images/idle.png";
            }
          }
        }
      }
    },
    removeStatusTags: function (tab) {
      if (tab == 1) {
        if (this.seleted_stage == "Job offered") {
          this.seleted_stage = "";
        }
        if (this.seleted_stage == "Started work") {
          this.seleted_stage = "";
        }
        if (this.seleted_stage == "Interview successful") {
          this.seleted_stage = "";
        }
        if (this.seleted_stage == "Employed for 12 months") {
          this.seleted_stage = "";
        }

        this.$refs.statusFilter.clearSelection();
        this.selected_status_tags = "";
        if (this.statusOptions) {
          for (i = 0; i < this.statusOptions.length; i++) {
            if (this.statusOptions[i].image == "/images/selected.png") {
              this.statusOptions[i].image = "/images/idle.png";
            }
          }
        }
      }
      if (tab == 2) {
        if (this.seleted_stage_archived == "Job offered") {
          this.seleted_stage_archived = "";
        }
        if (this.seleted_stage_archived == "Started work") {
          this.seleted_stage_archived = "";
        }
        if (this.seleted_stage_archived == "Interview successful") {
          this.seleted_stage_archived = "";
        }
        if (this.seleted_stage_archived == "Employed for 12 months") {
          this.seleted_stage_archived = "";
        }

        this.$refs.statusFilterArchived.clearSelection();
        this.selected_status_tags_archived = "";
        if (this.statusOptions) {
          for (i = 0; i < this.statusOptions.length; i++) {
            if (
              this.statusOptions[i].image_archived == "/images/selected.png"
            ) {
              this.statusOptions[i].image_archived = "/images/idle.png";
            }
          }
        }
      }
      if (tab == 3) {
        if (this.seleted_stage_starter == "Job offered") {
          this.seleted_stage_starter = "";
        }
        if (this.seleted_stage_starter == "Started work") {
          this.seleted_stage_starter = "";
        }
        if (this.seleted_stage_starter == "Interview successful") {
          this.seleted_stage_starter = "";
        }

        if (this.seleted_stage_starter == "Employed for 12 months") {
          this.seleted_stage_starter = "";
        }
        this.$refs.statusFilterStarter.clearSelection();
        this.selected_status_tags_starter = "";
        if (this.statusOptions) {
          for (i = 0; i < this.statusOptions.length; i++) {
            if (this.statusOptions[i].image_starter == "/images/selected.png") {
              this.statusOptions[i].image_starter = "/images/idle.png";
            }
          }
        }
      }
    },
    removeSitesTags: function (tab) {
      if (tab == 1) {
        this.$refs.sitesFilter.clearSelection();
        this.selected_sites_tags = "";
        if (this.availableSites) {
          for (i = 0; i < this.availableSites.length; i++) {
            if (this.availableSites[i].image == "/images/selected.png") {
              this.availableSites[i].image = "/images/idle.png";
            }
          }
        }
      }
      if (tab == 2) {
        this.$refs.sitesFilterArchived.clearSelection();
        this.selected_sites_tags_archived = "";
        if (this.availableSites) {
          for (i = 0; i < this.availableSites.length; i++) {
            if (
              this.availableSites[i].image_archived == "/images/selected.png"
            ) {
              this.availableSites[i].image_archived = "/images/idle.png";
            }
          }
        }
      }
      if (tab == 3) {
        this.$refs.sitesFilterStarter.clearSelection();
        this.selected_sites_tags_starter = "";
        if (this.availableSites) {
          for (i = 0; i < this.availableSites.length; i++) {
            if (
              this.availableSites[i].image_starter == "/images/selected.png"
            ) {
              this.availableSites[i].image_starter = "/images/idle.png";
            }
          }
        }
      }
    },
    removeJobsTags: function (tab) {
      if (tab == 1) {
        this.$refs.jobsFilter.clearSelection();
        this.selected_jobs_tags = "";
        if (this.availableJobs) {
          for (i = 0; i < this.availableJobs.length; i++) {
            if (this.availableJobs[i].image == "/images/selected.png") {
              this.availableJobs[i].image = "/images/idle.png";
            }
          }
        }
      }
      if (tab == 2) {
        this.$refs.jobsFilterArchived.clearSelection();
        this.selected_jobs_tags_archived = "";
        if (this.availableJobs) {
          for (i = 0; i < this.availableJobs.length; i++) {
            if (
              this.availableJobs[i].image_archived == "/images/selected.png"
            ) {
              this.availableJobs[i].image_archived = "/images/idle.png";
            }
          }
        }
      }
      if (tab == 3) {
        this.$refs.jobsFilterStarter.clearSelection();
        this.selected_jobs_tags_starter = "";
        if (this.availableJobs) {
          for (i = 0; i < this.availableJobs.length; i++) {
            if (this.availableJobs[i].image_starter == "/images/selected.png") {
              this.availableJobs[i].image_starter = "/images/idle.png";
            }
          }
        }
      }
    },
    renderCandidates: function (updatedCandidates) {
      this.showFilterSites = updatedCandidates?.show_sites_filter;
      if (updatedCandidates.hasOwnProperty("liveCandidates")) {
        this.liveArray = updatedCandidates.liveCandidates.data;
        // pagination data.
        this.livePaginationData.current_page =
          updatedCandidates.liveCandidates.current_page;
        this.livePaginationData.from = updatedCandidates.liveCandidates.from;
        this.livePaginationData.last_page =
          updatedCandidates.liveCandidates.last_page;
        this.livePaginationData.per_page =
          updatedCandidates.liveCandidates.per_page;
        this.livePaginationData.to = updatedCandidates.liveCandidates.to;
        this.livePaginationData.total = updatedCandidates.liveCandidates.total;

        if (updatedCandidates.tot_overdue) {
          this.livePaginationData.tot_overdue = updatedCandidates.tot_overdue;
        }
      }

      if (updatedCandidates.hasOwnProperty("archivedCandidates")) {
        this.archivedArray = updatedCandidates.archivedCandidates.data;
        // pagination data.
        this.archivedPaginationData.current_page =
          updatedCandidates.archivedCandidates.current_page;
        this.archivedPaginationData.from =
          updatedCandidates.archivedCandidates.from;
        this.archivedPaginationData.last_page =
          updatedCandidates.archivedCandidates.last_page;
        this.archivedPaginationData.per_page =
          updatedCandidates.archivedCandidates.per_page;
        this.archivedPaginationData.to =
          updatedCandidates.archivedCandidates.to;
        this.archivedPaginationData.total =
          updatedCandidates.archivedCandidates.total;
      }

      if (updatedCandidates.hasOwnProperty("startersCandidates")) {
        this.startersArray = updatedCandidates.startersCandidates.data;
        // pagination data.
        this.startersPaginationData.current_page =
          updatedCandidates.startersCandidates.current_page;
        this.startersPaginationData.from =
          updatedCandidates.startersCandidates.from;
        this.startersPaginationData.last_page =
          updatedCandidates.startersCandidates.last_page;
        this.startersPaginationData.per_page =
          updatedCandidates.startersCandidates.per_page;
        this.startersPaginationData.to =
          updatedCandidates.startersCandidates.to;
        this.startersPaginationData.total =
          updatedCandidates.startersCandidates.total;
      }
      this.postDataUpdateProcess();
    },
  },

  created() {
    this.currentHref = window.location.href;
    if (this.currentHref.indexOf("live") != -1) {
      this.tabId = 1;
    } else if (this.currentHref.indexOf("archived") != -1) {
      this.tabId = 2;
    } else if (this.currentHref.indexOf("starters") != -1) {
      this.tabId = 3;
    } else {
      this.tabId = 1;
    }

    this.isActive(this.tabId);
  },

  async mounted() {
    const candidateJobs = await this.getCandidateJobs();
    this.availableCandidateJobs = candidateJobs;
    window.addEventListener("scroll", this.updateScroll);

    this.$root.$on("addCandidate", (status) => {
      this.addCandidate = status;
    });

    this.$root.$on("Event::DataUpdated", (elementDistanceToTop) => {
      this.fetchUpdatedData(elementDistanceToTop);
    });

    /**
     * Organized data passed in via view page to component.
     */
    //this.loadInitialData();

    let urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('overdue')) {
      this.showOverdue()
    } else {
      this.fetchUpdatedData()
    }

    /**
     *  for job filter dropdown
     */
    var self = this;
    axios
      .get("/dashboard/account/get-candidates-jobfilter", {
        params: {
          site: true,
        },
      })
      .then(function (response) {
        if (response.data) {
          self.jobs = JSON.stringify(response.data);
          var sitename = "";
          for (var i = response.data.length - 1; i >= 0; i--) {
            if (response.data[i].site !== null) {
              sitename = response.data[i].site.name;
            } else {
              sitename = "All Sites";
            }
            var job = {
              name: response.data[i].Job_Title + " [" + sitename + "]",
              id: response.data[i].id,
              image: "/images/idle.png",
              image_archived: "/images/idle.png",
              image_starter: "/images/idle.png",
            };
            self.availableJobs.push(job);
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    /**
     *  for Sites filter dropdown
     */

    axios
      .get("/dashboard/account/get-portal-sites")
      .then(function (response) {
        if (response.data) {
          self.sites = JSON.stringify(response.data);
          var site = "";
          site = {
            name: "All Sites",
            id: -1,
          };
          self.availableSites.push(site);
          for (var i = 0; i < response.data.sites.length; i++) {
            site = {
              name: response.data.sites[i].name,
              id: response.data.sites[i].id,
              image: "/images/idle.png",
              image_archived: "/images/idle.png",
            };
            self.availableSites.push(site);
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style>
#status-filter-starter #vs3__listbox {
  width: 300px;
}
#status-filter-archived #vs3__listbox {
  width: 300px;
}
#status-filter #vs3__listbox {
  width: 300px;
}
.middle-serarch {
  width: 20%;
}
.candidate-total-block {
  width: 40%;
  padding-left: 15px;
}
.candidate-total-block-live {
  width: 20%;
  padding-left: 15px;
}
.live-filter-block {
  width: 40%;
}
.candidates-search-top {
  height: 40px;
  width: 95%;
  padding: 0 11px 0 43px;
  border: 1px solid #dcdee6;
  border-radius: 3px;
  color: #9297ae;
  font: 400 14px "InterUI", sans-serif;
  background: #fff url(/images/search.png) no-repeat 7px center;
}
.filters-wrappers .cell input[type="search"] {
  color: #85899e !important;
}
.datelabelclass-candidate {
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #85899e;
}
.tot-num-red {
  height: 24px;
  font-family: InterUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363b54;
}
.showall-right {
  padding-right: 22px;
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #f15574;
  cursor: pointer;
}
.showall-left {
  float: left;
  padding-left: 0px;
  width: 100px;
}
.sticky-element {
  z-index: 1;
}
@media only screen and (min-width: 1400px) {
  .middle-serarch {
    width: 20%;
  }
  .candidate-total-block {
    /*width:56%;*/
  }
  .candidate-total-block-live {
    width: 20%;
  }
  .live-filter-block {
    width: 40%;
  }
}
.candidate-width-full .filters-wrappers .cell:last-child {
  max-width: 136px;
  padding-left: 0px !important;
}
.candidate-width-full .filters-wrappers .cell:nth-child(2) {
  max-width: 129px;
  padding-left: 0px !important;
}
.candidate-filters-wrappers .cell:first-child {
  max-width: 164px !important;
  padding-right: 12px;
}
</style>
