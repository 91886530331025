<template>
    <fieldset>
        <div class="main-form-div job-table">
            <h2 class="step-heading">{{ titleWithSubtitle }}</h2>
            <ImageUploader v-model="form.Image" />
            <div class="job-row">
                <label class="job-label"
                       for="Video_Link">
                    FEATURE VIDEO LINK
                    <span class="tooltip-span"
                          id="feature_video_link"
                          style="padding: 0 2px">
                        <img src="/images/InfoIcon.png"
                             aria-label="Site Info Tooltip" />
                    </span>
                    <b-tooltip target="feature_video_link"
                               triggers="hover">
                        Youtube or Vimeo links supported
                    </b-tooltip>
                </label>
                <input type="text"
                       id="Video_Link"
                       v-model="form.Video_Link"
                       :class="{ 'is-invalid': !isFieldValid(form.Video_Link) }"
                       placeholder="e.g. https://youtube.com" />
                <span v-if="!isFieldValid(form.Video_Link)"
                      class="error-message">
                    "Feature video link" is required.
                </span>
            </div>
            <div class="job-row">
                <label class="job-label"
                       for="Description">
                    DESCRIPTION <span>*</span>
                </label>
                <p class="description-text">
                    For guidance on writing your job description to maximise conversions, check out our help centre
                    article
                    <a :href="descriptionURL"
                       target="_blank"
                       class="no-description">
                        here
                    </a>.
                </p>
                <ckeditor v-model="form.Description"
                          name="Message"
                          ref="Message"
                          id="addJobMessage"
                          :config="editorConfig"></ckeditor>
                <span v-if="!isFieldValid(form.Description)"
                      class="error-message">
                    "Description" is required.
                </span>
            </div>
        </div>
    </fieldset>
</template>

<script>
import CKEditor from "ckeditor4-vue/dist/legacy.js";
import ImageUploader from "../../ImageUploader/ImageUploader.vue";

export default {
    name: "Step3",
    components: {
        CKEditor,
        ImageUploader,
    },
    props: {
        form: {
            type: Object,
            default: () => ({
                Image: "",
                Video_Link: "",
                Description: "",
            }),
        },
        title: {
            type: String,
            default: "Job Details",
        },
    },
    data() {
        return {
            editorConfig: {
                height: 150,
                toolbar: [["Bold", "NumberedList", "BulletedList", "EmojiPanel", "Paste"]],
                removeButtons: "Italic",
                extraPlugins: "emoji",
            },
        };
    },
    computed: {
        titleWithSubtitle() {
            return `${this.title} - Description and Media`;
        },
        checkValidation() {
            const requiredFields = [this.form.Description];
            return requiredFields.every(this.isFieldValid);
        },
        descriptionURL() {
            return "https://support.carefriends.co.uk/articles/4814402-suggested-job-ad-wording";
        }
    },
    methods: {
        isFieldValid(field) {
            return field !== "" && field !== null;
        },
    },
    watch: {
        form: {
            deep: true,
            handler() {
                this.$emit("update-step-validity", this.checkValidation);
            },
        },
    },
    mounted() {
        this.$emit("update-step-validity", this.checkValidation);
    },
};
</script>
<style scoped lang="scss">
@import "./Steps.scss";
</style>