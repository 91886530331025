<script>
export default {
    name: "PersonxIcon",
};
</script>

<template>
    <svg width="26"
         height="20"
         viewBox="0 0 26 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.625 19.75C1.625 19.75 0 19.75 0 18.125C0 16.5 1.625 11.625 9.75 11.625C17.875 11.625 19.5 16.5 19.5 18.125C19.5 19.75 17.875 19.75 17.875 19.75H1.625ZM9.75 10C11.0429 10 12.2829 9.48639 13.1971 8.57215C14.1114 7.65791 14.625 6.41793 14.625 5.125C14.625 3.83207 14.1114 2.59209 13.1971 1.67785C12.2829 0.763615 11.0429 0.25 9.75 0.25C8.45707 0.25 7.21709 0.763615 6.30285 1.67785C5.38861 2.59209 4.875 3.83207 4.875 5.125C4.875 6.41793 5.38861 7.65791 6.30285 8.57215C7.21709 9.48639 8.45707 10 9.75 10ZM19.7372 5.36225C19.8127 5.28658 19.9024 5.22655 20.0011 5.18559C20.0998 5.14463 20.2056 5.12355 20.3125 5.12355C20.4194 5.12355 20.5252 5.14463 20.6239 5.18559C20.7226 5.22655 20.8123 5.28658 20.8878 5.36225L22.75 7.22613L24.6122 5.36225C24.7648 5.20968 24.9717 5.12397 25.1875 5.12397C25.4033 5.12397 25.6102 5.20968 25.7628 5.36225C25.9153 5.51482 26.001 5.72174 26.001 5.9375C26.001 6.15326 25.9153 6.36018 25.7628 6.51275L23.8989 8.375L25.7628 10.2372C25.9153 10.3898 26.001 10.5967 26.001 10.8125C26.001 11.0283 25.9153 11.2352 25.7628 11.3878C25.6102 11.5403 25.4033 11.626 25.1875 11.626C24.9717 11.626 24.7648 11.5403 24.6122 11.3878L22.75 9.52387L20.8878 11.3878C20.7352 11.5403 20.5283 11.626 20.3125 11.626C20.0967 11.626 19.8898 11.5403 19.7372 11.3878C19.5847 11.2352 19.499 11.0283 19.499 10.8125C19.499 10.5967 19.5847 10.3898 19.7372 10.2372L21.6011 8.375L19.7372 6.51275C19.6616 6.43728 19.6016 6.34762 19.5606 6.2489C19.5196 6.15019 19.4985 6.04437 19.4985 5.9375C19.4985 5.83063 19.5196 5.72481 19.5606 5.6261C19.6016 5.52738 19.6616 5.43772 19.7372 5.36225Z"
              fill="#3C3C3C" />
    </svg>
</template>