<template>
  <div class="view-points-user">
    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto name-id-points">
        <h2 align="left">
          <strong>{{ usero.first_name }} {{ usero.surname }}</strong>
        </h2>
        <span class="id-profileCard"> ID: {{ usero.id }} </span>
      </div>
    </div>
    <br />
    <br>

    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto">
        <label>Phone </label>
		<p class="user-clickable" v-if="usero.first_name === 'Anonymised' || usero.status=='Leaver' || usero.mark_as_leaver ==1"></p>
        <p v-else>{{ usero.phone_number }}</p>
      </div>

      <div class="cell auto">
        <label>Email</label>
        <p class="user-clickable" v-if="usero.first_name == 'Anonymised' || usero.status=='Leaver' || usero.mark_as_leaver ==1"></p>
        <p class="user-clickable" v-else>{{ usero.email }}</p>
      </div>
    </div>

    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto">
        <label>Site </label>
        <p>{{ site_name }}</p>
      </div>
      <div class="cell auto">
        <label>{{ taxName }}</label>
        <p>{{ usero.PaymentInfo }}</p>
      </div>
    </div>

    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto">
        <label> Status </label>
        <p>{{ user_status }}</p>
      </div>

      <div class="cell auto">
        <label> Date registered </label>
        <p>
          <template>{{
              usero.created_at | moment("DD-MM-YYYY")
          }}</template>
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto">
        <h3>Referrals breakdown</h3>
      </div>
      <div class="cell auto"></div>
    </div>
    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto viewpoint">
        <h3 align="center">{{ leaderboardValues.totalShares }}</h3>
        <p align="center">Shares</p>
      </div>
      <div class="cell auto viewpoint">
        <h3 align="center">{{ leaderboardValues.totalApplied }}</h3>
        <p align="center">Candidates</p>
      </div>
      <div class="cell auto viewpoint">
        <h3 align="center">{{ pointValues.Verified }}</h3>
        <p align="center">Verified</p>
      </div>
      <div class="cell auto viewpoint">
        <h3 align="center">{{ pointValues.CountHires }}</h3>
        <p align="center">Started</p>
      </div>
      <div class="cell auto viewpoint">
        <h3 align="center">{{ pointValues.CountEmployed12Months }}</h3>
        <p align="center">{{ leaderboardValues.milestone }} months</p>
      </div>
      <template v-if="isCareSector">
        <div class="cell auto viewpoint">
          <h3 align="center">{{ pointValues.CountNewToCare }}</h3>
          <p align="center">N2C</p>
        </div>
      </template>
    </div>
    <div class="grid-x grid-padding-x align-middle activity-log">
      <div class="cell auto medium-9">
        <h3>Activity log</h3>
      </div>
      <div class="cell auto activity-dropdown">
        <v-select
          placeholder="All Activity"
          @input="viewPoints(user)"
          :options="activityTypes"
          :reduce="(referrer) => referrer.id"
          label="name"
          v-model="activityType"
        ></v-select>
      </div>
    </div>
    <div class="grid-x grid-padding-x align-middle">
      <table id="activity-table" class="cf-table cell auto">
        <thead>
          <tr>
            <th width="130px">Date</th>
            <th>Type</th>
            <th width="230px">Reason</th>
            <th class="align-right">Points</th>
            <th class="align-right">Balance</th>
          </tr>
        </thead>
        <tbody>
        <tr v-if="viewPointsData" v-for="value in viewPointsData">
            <td>{{ value.date }}</td>
            <td>{{ value.type }}</td>
            <td>{{ value.reason }}</td>
            <td>{{ value.points }}</td>
            <td>{{ value.balance }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
  components: { paginate: Paginate },
  props: ["user", "isCareSector", "taxName"],
  data() {
    return {
      viewPointsData: [],
      type_id: "",
      activityType: 0,
      activityTypes: [
        { id: 0, name: "All Activity" },
        { id: 1, name: "Referral Activity" },
        { id: 2, name: "Bonus Activity" },
        { id: 3, name: "Payment Activity" },
      ],
      filteredPoints: [],
      site_name: "",
      dateReg: [],
      balance: [],
      leaderboardValues: [],
      pointValues: [],
      usero:this.user,
      user_status: ''
    };
  },
  methods: {
    filterViewPoints() {
        this.filteredPoints = [];
        for (var i = 0; i < this.viewPointsData.length; i++) {
            if (this.activityType == 1) {
                if (this.viewPointsData[i].type == "Referral") {
                    this.filteredPoints.push(this.viewPointsData[i]);
                }
            } else if (this.activityType == 2) {
                if (this.viewPointsData[i].type == "Bonus") {
                    this.filteredPoints.push(this.viewPointsData[i]);
                }
            } else if (this.activityType == 3) {
                if (this.viewPointsData[i].type == "Request" || this.viewPointsData[i].type == "Paid"
                    || this.viewPointsData[i].type == "Approved" || this.viewPointsData[i].type == "Rejected") {
                    this.filteredPoints.push(this.viewPointsData[i]);
                }
            }
        }
        this.viewPointsData = this.filteredPoints;
    },
    viewPoints(user) {
      let user_type = 'registered';
      if(this.user.status != null){
        user_type = 'authorized';
      }
        var self = this;
        axios.get("/view-points/" + user.id+"/"+user_type)
            .then(function (response) {
                if (response.data.activityLog) {
                    self.viewPointsData = response.data.activityLog;
                    if (self.activityType > 0) {
                        self.filterViewPoints();
                    }
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    },

    getUserInfo(id) {
      let usertype = 'registered';
      if(this.user.status != null){
        usertype = 'authorized';
      }
      var self = this;
      axios
        .post("/dashboard/get-user-info", { id: id, usertype:usertype })
        .then(function (response) {
          if (response.data.info) {
            self.leaderboardValues = response.data.info.leaderboardValues;
            self.pointValues = response.data.info.pointValues;
            self.site_name = response.data.info.site_name;
              self.user_status = response.data.info.status;
            if(self.site_name == null){
                self.site_name = '-';
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
	getPostBody (post) {
		let body = this.stripTags(post);
		return body.length > 15 ? body.substring(0, 15) + '...' : body;
	},
	stripTags (markup) {
		const temp = document.createElement("div");
		temp.innerHTML = markup;
		return temp.textContent || temp.innerText || "";
	},
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  mounted() {
    this.getUserInfo(this.user.id);
    this.viewPoints(this.user);
    this.usero = this.user;
    if(this.usero.email == null){
        this.usero.email = '-';
    }
    if(this.usero.phone_number == null){
        this.usero.phone_number= '-';
    }
    if(this.usero.PaymentInfo == null){
        this.usero.PaymentInfo = '-';
    }
  },
  computed: {},
};
</script>
