<template>
    <question-modal ref="questionModal"
                    :loading="loading"
                    :currentuser="currentuser"
                    :current-step="currentStep"
                    @on-process="handleProcess"
                    @next-step="nextStep"
                    @pre-step="preStep"
                    :is-form-valid="isFormValid"
                    :is-step2-valid="isStep2Valid">
        <question-form ref="form"
                       :question="question"
                       :currentuser="currentuser"
                       :errors="errors"
                       :current-step="currentStep"
                       :affected-jobs="affectedJobs"
                       @update-form-validity="isFormValid = $event"
                       @update-step2-validity="isStep2Valid = $event"
                       @on-submit="updateQuestion" />
    </question-modal>
</template>

<script>
import QuestionForm from '../QuestionForm';
import QuestionModal from '../QuestionModal';

export default {
    name: 'EditQuestion',
    props: {
        question: { type: Object, required: true },
        currentuser: { type: Object, required: true }
    },
    components: { QuestionForm, QuestionModal },
    data() {
        return {
            loading: false,
            errors: {},
            currentStep: 1,
            isFormValid: false,
            isStep2Valid: false,
            affectedJobs: 0
        };
    },
    methods: {
        showModal() {
            this.errors = {};
            this.currentStep = 1;
            this.$refs.questionModal.show();
            this.getAffectedJobsCount();
        },
        hideModal() {
            this.$refs.questionModal.hide();
        },
        async getAffectedJobsCount() {
            this.loading = true;
            await axios.get('/dashboard/get-affected-jobs-count/', {
                params: {
                    questionId: this.question.id
                }
            })
                .then(response => {
                    this.loading = false;
                    this.affectedJobs = response.data;
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
        },
        async updateQuestion(data) {
            this.loading = true;
            let oldQuestionId = data.id;
            await axios.put('/dashboard/screening-questions/' + oldQuestionId, data)
                .then(response => {
                    data.customer_id = response.data.customer_id;
                    data.modified_by = response.data.modified_by;
                    data.id = response.data.id;
                    data.old_question_id = oldQuestionId;
                    this.loading = false;
                    this.hideModal();
                    this.$emit('on-update', data);
                    Vue.swal({
                        html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Screening Question updated successfully!',
                        confirmButtonColor: "#5dc2a6",
                        confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                        background: "#5dc2a6",
                        customClass: {
                            container: "candidate-toast",
                        },
                        showClass: {
                            popup: "animated fadeInLeft fast",
                            icon: "animated heartBeat delay-1s",
                        },
                        hideClass: {
                            popup: "animated fadeOutLeft slow",
                        },
                        toast: true,
                        timer: 5000,
                        position: "top-right",
                        target: "#example-tabs",
                    });
                })
                .catch(error => {
                    this.loading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else {
                        Vue.swal({
                            title: "Oops!",
                            text: "Something went wrong. Please try again.",
                            confirmButtonColor: "#ec5168",
                            allowOutsideClick: false,
                        });
                    }
                });
        },
        handleProcess() {
            if (this.$refs.form) {
                this.$refs.form.submitForm();
            }
        },
        nextStep() {
            this.currentStep++;
            this.$refs.questionModal.show();
        },
        preStep() {
            this.currentStep--;
            this.$refs.questionModal.show();
        }
    }
};
</script>