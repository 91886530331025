<script>
export default {
  name: 'LogOutIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 11.5C8.72386 11.5 8.5 11.7239 8.5 12C8.5 12.2761 8.72386 12.5 9 12.5V11.5ZM20.3536 12.3536C20.5488 12.1583 20.5488 11.8417 20.3536 11.6464L17.1716 8.46447C16.9763 8.2692 16.6597 8.2692 16.4645 8.46447C16.2692 8.65973 16.2692 8.97631 16.4645 9.17157L19.2929 12L16.4645 14.8284C16.2692 15.0237 16.2692 15.3403 16.4645 15.5355C16.6597 15.7308 16.9763 15.7308 17.1716 15.5355L20.3536 12.3536ZM9 12.5L20 12.5V11.5L9 11.5V12.5Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 5H11C12.1046 5 13 5.89543 13 7V10.5H14V7C14 5.34315 12.6569 4 11 4H7C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H11C12.6569 20 14 18.6569 14 17V13.5H13V17C13 18.1046 12.1046 19 11 19H7C5.89543 19 5 18.1046 5 17V7C5 5.89543 5.89543 5 7 5Z" fill="currentColor"/>
  </svg>
</template>
