<template>
    <div>
        <div class="templates-table">
            <div class="template-row template-row--head">
                <div class="template-column template-column--left-align">Template Name</div>
                <div class="template-column template-column--left-align">Subject</div>
                <div class="template-column template-column--tag-align template-column--tag-text">Tags</div>
                <div class="template-column template-column--left-align template-column--actions"></div>
            </div>

            <div v-if="templates.length > 0">
                <div class="template-row"
                     v-for="template in templates"
                     :key="template.id">
                    <div class="template-column template-column--left-align">{{ template.name }}</div>
                    <div class="template-column template-column--left-align">{{ template.subject }}
                    </div>
                    <div class="template-column template-column--tag-align">
                        <span class="template-status template-status--active">
                            DEFAULT
                        </span>
                    </div>
                    <div class="template-column template-column--left-align template-column--actions">
                        <template-actions ref="template-actions"
                                          :template="template"
                                          :currentuser="currentuser"
                                          @on-update="onUpdateTemplate" />
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="template-row"><div class="template-column template-column--tag-align" style="max-width: 100%; text-align: center;">0 records</div></div>
            </div>
        </div>

        <transition name="fade">
            <div v-show="loading"
                 class="loading-overlay">
                <span>Loading...</span>
            </div>
        </transition>
    </div>
</template>

<script>
import TemplateActions from "../TemplateActions";
import { Icon } from "../../../../components/Icon";

export default {
    name: "TemplatesTableView",
    props: {
        templates: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        currentuser: { type: Object, required: true }
    },
    components: {
        Icon, TemplateActions
    },
    methods: {
        onUpdateTemplate(data) {
            this.$emit('on-update', data)
        }
    }
};
</script>

<style scoped lang="scss">
@import "./TemplatesTable.scss";
</style>