<template>
    <view-email-template-modal ref="view-email-template-modal"
                               :title="modalTitle"
                               :currentuser="currentuser">
        <view-email-template-form ref="view-email-template-form"
                                  :currentuser="currentuser"
                                  :template="template"></view-email-template-form>
    </view-email-template-modal>
</template>

<script>
import ViewEmailTemplateForm from '../ViewEmailTemplateForm';
import ViewEmailTemplateModal from '../ViewEmailTemplateModal';

export default {
    name: 'ViewEmailTemplate',
    props: {
        currentuser: { type: Object, required: true },
        template: { type: Object, default: null }
    },
    components: { ViewEmailTemplateForm, ViewEmailTemplateModal },
    computed: {
        modalTitle() {
            return 'Email preview';
        }
    },
    methods: {
        showModal() {
            this.$refs['view-email-template-modal'].show();
        },
        hideModal() {
            this.$refs['view-email-template-modal'].hide();
        }
    }
}
</script>