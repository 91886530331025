<template>
    <b-modal v-model="modalShow"
             no-enforce-focus
             :title="title"
             :modal-class="loading ? 'modal--loading' : ''"
             centered
             hide-header-close
             no-close-on-backdrop>
        <slot></slot>
        <template #modal-footer="{ cancel }">
            <button v-if="currentStep === 1"
                    class="cancel-button"
                    @click="cancel">Cancel</button>
            <button v-if="currentStep > 1"
                    class="cancel-button"
                    @click="preStep">Back</button>
            <button v-if="currentStep === 2"
                    class="process-button"
                    :disabled="!isStep2Valid"
                    @click="process">Save</button>
            <button v-if="currentStep === 1"
                    class="process-button"
                    :disabled="!isFormValid"
                    @click="nextStep">Next</button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: 'QuestionModal',
    props: {
        title: { type: String, default: '' },
        loading: { type: Boolean, default: false },
        currentuser: { type: Object, required: true },
        currentStep: { type: Number, default: 1 },
        isFormValid: { type: Boolean, default: false },
        isStep2Valid: { type: Boolean, default: false }
    },
    data() {
        return {
            modalShow: false
        };
    },
    methods: {
        show() {
            this.modalShow = true;
        },
        hide() {
            this.modalShow = false;
        },
        process() {
            this.$emit('on-process');
        },
        nextStep() {
            this.$emit('next-step');
        },
        preStep() {
            this.$emit('pre-step');
        }
    }
};
</script>

<style scoped lang="scss">
@import "./QuestionModal.scss";
</style>