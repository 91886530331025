<script>
export default {
  name: 'FilterIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.25067 5.61C6.57067 8.59 10.0007 13 10.0007 13V18C10.0007 19.1 10.9007 20 12.0007 20C13.1007 20 14.0007 19.1 14.0007 18V13C14.0007 13 17.4307 8.59 19.7507 5.61C20.2607 4.95 19.7907 4 18.9507 4H5.04067C4.21067 4 3.74067 4.95 4.25067 5.61Z" fill="currentColor"/>
  </svg>
</template>