export const colors = {
  orange: "#FAAB5F",
  red: "#EF5D72",
  green: "#5DC2A6",
  black: "#494949",
  grey1: "#363B54",
  grey2: "#9F9F9F",
};

export const graphColors = ["#1ec5a5", "#fea84c", "#ff4f6f", "#67b9fb"];

export const fontFamily = "Poppins, sans-serif";
export const secondFontFamily = "Inter, sans-serif";
export const thirdFontFamily = "Helvetica, sans-serif";
