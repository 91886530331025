<template>
    <b-modal v-model="modalShow"
             no-enforce-focus
             :title="title"
             :modal-class="loading ? 'modal--loading' : ''"
             centered
             hide-header-close
             no-close-on-backdrop>
        <slot></slot>
        <template slot="modal-footer"
                  slot-scope="{ cancel }">
            <button v-if="!isViewMode"
                    class="cancel-button"
                    @click="cancel">Cancel</button>
            <button v-if="!isViewMode"
                    class="process-button"
                    @click="process">Save</button>
            <button v-if="isViewMode"
                    class="cancel-button"
                    @click="cancel">Close</button>

        </template>
    </b-modal>
</template>

<script>
import TemplateForm from '../TemplateForm';

export default {
    name: 'TemplateModal',
    props: {
        title: { type: String, default: '' },
        loading: { type: Boolean, default: false },
        currentuser: { type: Object, required: true }
    },
    components: { TemplateForm },
    data() {
        return {
            modalShow: false
        }
    },
    computed: {
        isViewMode() {
            return ((this.currentuser.account_check === 0 || this.currentuser.account_check === false) || (this.currentuser.customer.edit_templates_feature === 0 || this.currentuser.customer.edit_templates_feature === false) ); 
        }
    },
    methods: {
        show() {
            this.modalShow = true;
        },
        hide() {
            this.modalShow = false;
        },
        process() {
            this.$emit('on-process');
        }
    }
}
</script>

<style scoped lang="scss">
@import "./TemplateModal.scss";
</style>