<template>
    <b-modal v-model="modalShow"
             no-enforce-focus
             :title="title"
             centered
             hide-header-close
             no-close-on-backdrop>
        <template #modal-header>
            <div class="modal-header">
                <span>{{ title }}</span>
                <button type="button"
                        class="close"
                        @click="hide"
                        aria-label="Close">
                    <img src="/images/cancel.png"
                         alt="Close" />
                </button>
            </div>
        </template>

        <slot></slot>

        <template #modal-footer>
        </template>
    </b-modal>
</template>

<script>
import ViewEmailTemplateForm from '../ViewEmailTemplateForm';

export default {
    name: 'ViewEmailTemplateModal',
    props: {
        title: { type: String, default: '' },
        currentuser: { type: Object, required: true }
    },
    components: { ViewEmailTemplateForm },
    data() {
        return {
            modalShow: false
        };
    },
    methods: {
        show() {
            this.modalShow = true;
        },
        hide() {
            this.modalShow = false;
        }
    }
};
</script>

<style scoped lang="scss">
@import "./ViewEmailTemplateModal.scss";

.modal-header {
    padding: 1rem;
    border-bottom: none;

    .close {
        font-size: 1.5rem;
        color: #000;
        opacity: 0.6;

        &:hover {
            opacity: 0.8;
        }
    }
}
</style>