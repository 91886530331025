<template>
  <div>
    <label v-if="!notShowLabel" class="bonus-label">SELECT USERS</label>
    <v-select
      class="phone-recruiter"
      placeholder="Add user"
      label="name"
      :options="filteredUsers"
      :getOptionKey="opt => getOptionKey(opt)"
      :close-on-select="true"
      @input="(value) => handleSelection(value)"
      @search="(search, loading) => handleSearch(search, loading)"
    ></v-select>
    <div class="input-tagged">
      <span v-for="(element) in selected_notify_tags" v-bind:key="element.id">
        <div class="tag-input__tag tag-right">
          <span class="lablesize">{{ element.name }}</span>
          <span class="deselect-pad"
            ><span class="deselect" @click="removeNotiTags(element.id)"></span
          ></span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["users", "notShowLabel", "selectedUserIds"],
  data() {
    return {
      availableUsers: [],
      filteredUsers: [],
      userIds: this.selectedUserIds,
      selected_notify_tags: [],
      userId: "",
      userCount: "",
    };
  },
  watch: {
    userIds: function (val) {
      this.$emit("selectedUser", val);
      this.setSelectedUserIds(val)
    },
    users: function (users) {
      const availableUsers = this.getAvailableUsers(users);
      this.availableUsers = availableUsers;
      this.filteredUsers = availableUsers;
    },
  },
  methods: {
    handleSelection(value) {
      this.userIds = [...this.userIds, value.id];
    },
    handleSearch(searchString, loading) {
      loading(true);
      const matchedUsers = this.users.filter(user => {
        const name = `${user.first_name.toLowerCase()} ${user.surname.toLowerCase()}`;
        const lowercaseSearchString = searchString.toLowerCase();
        return name.includes(lowercaseSearchString);
      });
      this.filteredUsers = this.processUsers(matchedUsers);
      loading(false);
    },
    getOptionKey(option) {
      return `${option.id}|${option.name}`;
    },
    getAvailableUsers(usersArray) {
      return this.processUsers(usersArray);
    },
    processUsers(usersArray) {
      if (!usersArray) {
        return [];
      }

      return usersArray.map(userObj => {
        const userType = userObj.active === "1" ? "User" : "Invitee";
        return {
          name: `${userObj.first_name} ${userObj.surname}`,
          id: `${userObj.id}-${userType}`
        };
      }).sort((a, b) => a.name.localeCompare(b.name));
    },
    setSelectedUserIds: function (userIds) {
      if(typeof userIds.map !== "function") {
        return;
      }
      this.selected_notify_tags = userIds.map((userId) => {
        const splitUserId = userId.split("-");
        const selectedUser = splitUserId[1] === "User"
          ? this.users.find((user) => user.id === +splitUserId[0] && user.active === "1")
          : this.users.find((user) => user.id === +splitUserId[0] && user.active !== "1");
        if(selectedUser) {
          return {
            name: `${selectedUser.first_name} ${selectedUser.surname}`,
            id: userId
          }
        }
      });
    },
    removeNotiTags: function (id) {
      this.userIds = this.userIds.filter((uid) => uid !== id);
    },
  },
  mounted() {
    const availableUsers = this.getAvailableUsers(this.users);
    this.availableUsers = availableUsers;
    this.filteredUsers = availableUsers;
    this.setSelectedUserIds(this.selectedUserIds);
  },
  computed: {
    isShow: function () {
      return !!this.userIds;
    },
  },
};
</script>
<style lang="scss" scoped>
.input-tagged {
  float: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
  flex-basis: 100%;
  flex-grow: 1;
}
.tag-right {
  margin-right: 5px;
}
</style>
