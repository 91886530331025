<template>
    <div class="delete-button-wrap">
        <a href="javascript:void(0)" @click.prevent="onButtonClick">{{ this.label }}</a>
        <form :action="this.deleteUrl" method="post" :id="'form-'+this.id" class="hide">
            <input type='hidden' name='_method' value="delete">
            <input type='hidden' name='_token' :value="this.token">
        </form>
    </div>
</template>

<script>
    export default {
        props: {
            id: Number,
            label: String,
            deleteUrl: String,
            warningTitle: String,
            warningText: String,
            confirmText: String,
            cancelText: String
        },
        data() {
            return {
                confirmed: false,
                token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        methods: {
            onButtonClick() {
                console.log(this.id);
                this.submitForm();
            },
            submitForm() {
                this.$swal({
                    title: (this.warningTitle) ? this.warningTitle : 'Are you sure you want to delete this?',
                    text: (this.warningText) ? this.warningText : "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f55d73',
                    cancelButtonText: (this.cancelText) ? this.cancelText : 'Cancel',
                    confirmButtonText: (this.confirmText) ? this.confirmText : 'Yes, delete this.',
                    customClass: {
        					container:'',
        					confirmButton: 'button pink_btn b_margin_0 no_wrap_btn',
        					cancelButton: 'button orange_btn b_margin_0 no_wrap_btn'
        				},

                }).then((result) => {
                        if (result.value) {
                            document.getElementById('form-' + this.id).submit();
                        }
                    }
                );
            }
        }
    }
</script>
