<script>
export default {
  name: 'ActivateIcon'
}
</script>

<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.477 5.20065C6.418 5.20065 6.37 5.22165 6.339 5.23865C6.287 5.26765 6.2 5.33965 6.2 5.47865V18.2087C6.2 18.3477 6.288 18.4197 6.339 18.4487C6.392 18.4777 6.496 18.5187 6.618 18.4467L17.416 12.0827C17.535 12.0127 17.553 11.9027 17.553 11.8447C17.553 11.7847 17.535 11.6757 17.417 11.6047H17.416L6.617 5.24065C6.567 5.21165 6.519 5.20065 6.477 5.20065ZM6.477 19.6877C6.226 19.6877 5.974 19.6227 5.744 19.4907C5.278 19.2237 5 18.7447 5 18.2087V5.47865C5 4.94165 5.278 4.46365 5.744 4.19665C6.21 3.93165 6.764 3.93365 7.227 4.20765L18.026 10.5717C18.481 10.8397 18.753 11.3147 18.753 11.8447C18.753 12.3727 18.481 12.8477 18.025 13.1157L7.227 19.4807C6.992 19.6187 6.735 19.6877 6.477 19.6877Z" fill="#6A6F8E"/>
    </svg>
</template>