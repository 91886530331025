import Vue from "vue";
import { TextReplacementService } from "../js/services/TextReplacementService";

export const initApp = config => {
  const textReplacementService = new TextReplacementService(config.textReplacementDictionary);

  Vue.prototype.textReplacementService = textReplacementService;
  Vue.prototype.brandName = textReplacementService.lookup("brand");

  new Vue({
    el: "#app",
  });
};
