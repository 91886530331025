import { colors, fontFamily, secondFontFamily, thirdFontFamily } from "../../config/branding";

export const cssVariables = () => {
  const variables = {
    "--fontFamily": fontFamily,
    "--secondFontFamily": secondFontFamily,
    "--thirdFontFamily": thirdFontFamily,
  };

  Object.keys(colors).forEach(color => {
    variables["--" + color] = colors[color];
  });

  return variables;
};
