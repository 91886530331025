<script>
export default {
    name: 'SchemeRulesIcon'
}
</script>

<template>
    <svg width="11"
         height="15"
         viewBox="0 0 11 15"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g id="Scheme rules icon">
            <g id="Group 603">
                <rect id="Rectangle 241"
                      x="0.5"
                      y="1.5"
                      width="10"
                      height="13"
                      rx="0.5"
                      stroke="#6A6F8E" />
                <circle id="Ellipse 8"
                        cx="5.5"
                        cy="1.5"
                        r="1"
                        fill="#6A6F8E"
                        stroke="#6A6F8E" />
                <path id="Ellipse 9"
                      d="M7.5 2.5C7.5 2.58103 7.47787 2.62277 7.44179 2.66058C7.39358 2.7111 7.29603 2.77597 7.11378 2.83496C6.74221 2.95524 6.18603 3 5.5 3C4.81397 3 4.25779 2.95524 3.88622 2.83496C3.70397 2.77597 3.60642 2.7111 3.55821 2.66058C3.52213 2.62277 3.5 2.58103 3.5 2.5C3.5 2.41897 3.52213 2.37723 3.55821 2.33942C3.60642 2.2889 3.70397 2.22403 3.88622 2.16504C4.25779 2.04476 4.81397 2 5.5 2C6.18603 2 6.74221 2.04476 7.11378 2.16504C7.29603 2.22403 7.39358 2.2889 7.44179 2.33942C7.47787 2.37723 7.5 2.41897 7.5 2.5Z"
                      fill="#6A6F8E"
                      stroke="#6A6F8E" />
                <circle id="Ellipse 10"
                        cx="5.5"
                        cy="1.5"
                        r="0.5"
                        fill="#D9D9D9" />
            </g>
        </g>
    </svg>
</template>