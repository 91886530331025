<script>
export default {
  name: 'DashboardIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.801 13H14.6995C14.0375 13 13.5 13.5365 13.5 14.199V18.801C13.5 19.4635 14.0375 20 14.6995 20H18.801C19.463 20 20 19.4635 20 18.801V14.199C20 13.5365 19.463 13 18.801 13ZM18.8 14.2L18.801 18.8L14.7005 18.801L14.6995 14.2H18.8ZM18.8005 4H14.7C14.0375 4 13.5 4.537 13.5 5.1995V9.8005C13.5 10.463 14.0375 11 14.7 11H18.8005C19.463 11 20 10.463 20 9.8005V5.1995C20 4.537 19.463 4 18.8005 4ZM18.8 5.2L18.8005 9.8L14.7005 9.8005L14.7 5.2H18.8ZM9.7995 4H5.201C4.538 4 4 4.5375 4 5.2005V18.7995C4 19.4625 4.538 20 5.201 20H9.7995C10.4625 20 11 19.4625 11 18.7995V5.2005C11 4.5375 10.4625 4 9.7995 4ZM9.7995 5.2L9.8 5.2005L9.7995 18.8L5.2005 18.7995L5.201 5.2H9.7995Z" fill="currentColor"/>
  </svg>
</template>