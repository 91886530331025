<script>
export default {
    name: "DuplicateIcon",
};
</script>

<template>
    <svg width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M16 11.125L16 3.99991C16 2.34304 14.6568 0.999893 13 0.999912L5.875 0.999994M10 19L3.25 19C2.00736 19 1 17.9926 1 16.75L1 6.99999C1 5.75735 2.00736 4.74999 3.25 4.74999L10 4.74999C11.2426 4.74999 12.25 5.75735 12.25 6.99999L12.25 16.75C12.25 17.9926 11.2426 19 10 19Z"
              stroke="#6A6F8E"
              stroke-width="2"
              stroke-linecap="round" />
    </svg>
</template>