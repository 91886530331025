<template>
  <div class="job-listing">
    <div v-if="showImg"
         class="show-load">
      <img :src="loadImage" />
    </div>
    <div v-show="showLoad"
         class="job-load-app">
      <img :src="myImage" />
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell auto font_0875">
        <div class="grid-x">
          <div class="cell small-6">
          </div>
          <div class="cell small-3">
          </div>
        </div>
      </div>
      <template v-if="currentuser.new_admin == 1">
        <div v-if="currentuser.job_check == 1"
             class="cell small-2 top-addjob">
          <add-job :sites="sitelist"
                   :pusers="pusers"
                   :currency="currency"
                   :brand="brand"
                   :currentuser="currentuser"
                   @on-added="getJobs(paginationData.current_page)"></add-job>
        </div>
      </template>
    </div>
    <div style="clear:both;"></div>

    <!-- FILTERS -->
    <job-filter :totalJobsCount="totalJobsCount"
                :searchVar="searchVar"
                :statusFilter="statusFilter"
                :sitesDropdownOptions="sitesDropdownOptions"
                :siteFilter="siteFilter"
                :currentSort="currentSort"
                :currentSortDir="currentSortDir"
                @update:searchVar="searchVar = $event"
                @update:statusFilter="statusFilter = $event"
                @update:siteFilter="siteFilter = $event"
                @getJobs="getJobs" />
    <!--Filter End here -->

    <jobs-table-view :jobs="filteredValues"
                     :sites="sitelist"
                     :pusers="pusers"
                     :show_sites="show_sites"
                     :pointTariffsFeatureFlag="pointTariffsFeatureFlag"
                     :brand="brand"
                     :currentuser="currentuser"
                     @on-job-pinned="getJobs(paginationData.current_page)"
                     @on-job-deleted="onJobDeleted"
                     @on-tariff-assign="updateJobs"
                     @on-job-toggle="getJobs(paginationData.current_page)"
                     @on-job-duplicated="onJobDuplicated"
                     @on-bulk-update="getJobs(paginationData.current_page)"
                     @on-job-edited="getJobs(paginationData.current_page)"
                     @on-job-sort="onJobSort" />

    <!-- JOBS Listing -->
    <div id="job-pagination">
      <div class="grid-x record">
        <label>ROWS PER PAGE:</label>
        <v-select @input="applyRecord"
                  v-model="selectedRecord"
                  :options="recordOptions"
                  :close-on-select="true"
                  :clearable="false">
        </v-select>
        <template>
          <paginate v-model="paginationData.current_page"
                    :page-count="paginationData.last_page"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="getJobs"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :prev-class="'paginate-controls'"
                    :next-class="'paginate-controls'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :active-class="'active'"></paginate>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import JobsTableView from "./Table/JobsTableView.vue";
import AddJob from "./AddJob/AddJob.vue";
import JobFilter from "./Filter/JobFilter.vue";
import Paginate from "vuejs-paginate";
import CKEditor from "ckeditor4-vue/dist/legacy.js";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "ListJobsComponent",
  props: ["pusers", "brand", "currentuser", "sitelist", "currency", "pointTariffsFeatureFlag"],
  components: { JobsTableView, JobFilter, CKEditor, paginate: Paginate, DateRangePicker: DateRangePicker, },
  data: function () {
    return {
      statusFilter: 0,
      searchVar: "",
      filteredValues: [],
      paginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0
      },
      current_edit_job_index: "",
      showLoad: false,
      loadImage: "/images/loading-circle.gif",
      myImage: "/images/loading-circle.gif",
      showImg: false,
      siteId: "",
      currentSort: "Job_Title",
      currentSortDir: "asc",
      sitesDropdownOptions: [],
      siteFilter: [],
      totalJobsCount: 0,
      recordOptions: [10, 15, 20, 25],
      selectedRecord: 10,
      show_sites: false
    };
  },
  methods: {
    getJobs: function (pageNum) {
      this.showLoad = true;
      let params = "?page=" + pageNum;
      if (this.siteFilter) {
        params += "&siteId=" + this.siteFilter;
      }
      if (this.searchVar.length > 0) {
        params += "&searchVar=" + this.searchVar;
      }
      if (this.statusFilter) {
        params += "&filter=" + this.statusFilter;
      }
      if (this.currentSort) {
        params += "&column_name=" + this.currentSort;
      }
      if (this.currentSortDir) {
        params += "&sort=" + this.currentSortDir;
      }
      if (this.selectedRecord) {
        params += "&per_page=" + this.selectedRecord;
      }

      var self = this;
      axios
        .get("/dashboard/account/get-jobs-data" + params)
        .then(function (response) {
          if (response.data) {
            self.filteredValues = response.data.jobs.data;
            $(self.$el).foundation();
            self.paginationData.current_page =
              response.data.jobs.current_page;
            self.paginationData.from = response.data.jobs.from;
            self.paginationData.last_page =
              response.data.jobs.last_page;
            self.paginationData.per_page =
              response.data.jobs.per_page;
            self.paginationData.to = response.data.jobs.to;
            self.paginationData.total = response.data.jobs.total;
            self.totalJobsCount = response.data.jobs.total;
            self.siteId = response.data.siteId;
            self.show_sites = response.data.show_sites;
          }
          self.showLoad = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onJobDeleted: function (deletedJobIndex) {
      this.filteredValues.splice(deletedJobIndex, 1);
      this.getJobs(this.paginationData.current_page);
    },
    onJobDuplicated: function (duplicatedJob) {
      this.filteredValues.splice(this.current_edit_job_index, 1, duplicatedJob);
      this.getJobs(this.paginationData.current_page);
    },
    applyRecord: function () {
      this.getJobs(1);
    },
    updateJobs: function (updatedJobs) {
      updatedJobs.forEach((updatedJob) => {
        let index = this.filteredValues.findIndex((item) => item.id === updatedJob.id);

        if (index !== -1) {
          let job = this.filteredValues[index];

          job.point_tariff = updatedJob.point_tariff;
          job.point_tariff_id = updatedJob.point_tariff_id;

          this.$set(this.filteredValues, index, job);
        }
      });

      this.getJobs(this.paginationData.current_page);
    },
    onJobSort: function (column) {
      if (this.currentSort === column) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSort = column;
        this.currentSortDir = 'asc';
      }
      this.getJobs(1);
    }
  },
  mounted() {
    this.getJobs(1);
    this.$root.$on("updatedJobs", this.getJobs);
    if (this.sitelist.length > 1) {
      for (var i = 0; i < this.sitelist.length; i++) {
        var site = {
          id: this.sitelist[i].id,
          name: this.sitelist[i].name,
          image: "/images/idle.png"
        };
        this.sitesDropdownOptions.push(site);
      }
    }
    $(this.$el).foundation();
  }
};
</script>
<style scoped lang="scss">
@import "./ListJobsComponent.scss";
</style>