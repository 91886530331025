<script>
    export default {
    name: "MultipleUserMonthlyBudgetIcon",
    };
</script>

<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_80_5140)">
        <path d="M9.5 12C11.7091 12 13.5 10.2091 13.5 8C13.5 5.79086 11.7091 4 9.5 4C7.29086 4 5.5 5.79086 5.5 8C5.5 10.2091 7.29086 12 9.5 12Z" fill="#3C3C3C"/>
        <g clip-path="url(#clip1_80_5140)">
        <path d="M21.9946 14.0909H21.4946V13.5909C21.4946 13.3159 21.2696 13.0909 20.9946 13.0909C20.7196 13.0909 20.4946 13.3159 20.4946 13.5909V14.0909H16.4946V13.5909C16.4946 13.3159 16.2696 13.0909 15.9946 13.0909C15.7196 13.0909 15.4946 13.3159 15.4946 13.5909V14.0909H14.9946C14.4396 14.0909 13.9996 14.5409 13.9996 15.0909L13.9946 22.0909C13.9946 22.6409 14.4396 23.0909 14.9946 23.0909H21.9946C22.5446 23.0909 22.9946 22.6409 22.9946 22.0909V15.0909C22.9946 14.5409 22.5446 14.0909 21.9946 14.0909ZM21.9946 21.5909C21.9946 21.8659 21.7696 22.0909 21.4946 22.0909H15.4946C15.2196 22.0909 14.9946 21.8659 14.9946 21.5909V16.5909H21.9946V21.5909Z" fill="#3C3C3C"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4999 12C17.7099 12 19.4999 10.21 19.4999 8C19.4999 5.79 17.7099 4 15.4999 4C15.0299 4 14.5899 4.1 14.1699 4.24C14.9999 5.27 15.4999 6.58 15.4999 8C15.4999 9.42 14.9999 10.73 14.1699 11.76C14.5899 11.9 15.0299 12 15.4999 12Z" fill="#3C3C3C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.94 13.492C11.6959 13.1643 10.4349 13 9.5 13C6.83 13 1.5 14.34 1.5 17V19C1.5 19.55 1.95 20 2.5 20H12.4015L12.405 15.0864V15.0852C12.4053 14.4906 12.6034 13.9369 12.94 13.492Z" fill="#3C3C3C"/>
        </g>
        <defs>
        <clipPath id="clip0_80_5140">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
        <clipPath id="clip1_80_5140">
        <rect width="12" height="12" fill="white" transform="translate(12.5 12)"/>
        </clipPath>
        </defs>
    </svg>
</template>
