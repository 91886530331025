<template>
    <div>
        <v-select placeholder="Send to" v-model="send_action" id="send-to-action" :reduce="referrer => referrer.id" label="name"
                  :options="senttooptions"></v-select>
        <input type="hidden" id="send-to-action" name="send_action" :value="send_action">
    </div>
</template>

<script>
    export default {
        data() {
            return {
			senttooptions: [{id: 'company', name: 'Everyone at company'}, {id: 'site', name: 'Everyone at site'}, {id: 'user', name: 'Single user'}],
                send_action: 'company'
            }
        },
        watch: {
            send_action: function (val) {

                this.$emit('selectedsendAction', this.send_action);
				if(val == 'company'){
                    $('#send-to-sites').hide();
                    $('#send-to-users').hide();
                }

                if(val == 'site'){
                    $('#send-to-sites').show();
                    $('#send-to-users').hide();
                }

                if(val == 'user'){
                    $('#send-to-users').show();
                    $('#send-to-sites').hide();
                }
            }
        }
    }
</script>
