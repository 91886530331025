<script>
export default {
  name: 'ReportBugIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.041 14.308C15.041 15.631 13.964 16.708 12.641 16.708H11.358C10.035 16.708 8.958 15.631 8.958 14.308V10.793C8.958 10.021 9.587 9.393 10.358 9.393H13.641C14.412 9.393 15.041 10.021 15.041 10.793V14.308ZM10.484 6.6C10.484 6.379 10.664 6.2 10.885 6.2H13.113C13.334 6.2 13.514 6.379 13.514 6.6V8.193H10.484V6.6ZM19.399 11.386H16.24V10.793C16.24 10.615 16.222 10.44 16.187 10.272C16.197 10.266 16.208 10.268 16.218 10.262L17.627 9.287C17.71 9.229 17.777 9.151 17.822 9.06L18.88 6.932C19.027 6.635 18.906 6.274 18.609 6.127C18.316 5.979 17.953 6.1 17.806 6.397L16.816 8.388L15.667 9.184C15.412 8.864 15.089 8.603 14.713 8.431V6.6C14.713 5.718 13.995 5 13.113 5H10.885C10.003 5 9.285 5.718 9.285 6.6V8.432C8.909 8.603 8.587 8.865 8.333 9.184L7.183 8.388L6.193 6.397C6.046 6.1 5.681 5.978 5.39 6.127C5.093 6.274 4.972 6.635 5.119 6.932L6.177 9.06C6.222 9.151 6.289 9.229 6.372 9.287L7.781 10.262C7.79 10.268 7.802 10.266 7.812 10.272C7.777 10.44 7.759 10.615 7.759 10.793V11.386H4.6C4.269 11.386 4 11.655 4 11.986C4 12.318 4.269 12.586 4.6 12.586H7.759V13.726L6.372 14.686C6.289 14.744 6.222 14.822 6.177 14.912L5.119 17.041C4.972 17.338 5.093 17.698 5.39 17.845C5.475 17.888 5.566 17.908 5.656 17.908C5.876 17.908 6.089 17.786 6.193 17.575L7.183 15.585L7.858 15.117C8.228 16.712 9.652 17.908 11.358 17.908H12.641C14.347 17.908 15.771 16.712 16.141 15.117L16.816 15.585L17.806 17.575C17.91 17.786 18.123 17.908 18.343 17.908C18.433 17.908 18.524 17.888 18.609 17.845C18.906 17.698 19.027 17.338 18.88 17.041L17.822 14.912C17.777 14.822 17.71 14.744 17.627 14.686L16.24 13.726V12.586H19.399C19.73 12.586 19.999 12.318 19.999 11.986C19.999 11.655 19.73 11.386 19.399 11.386Z" fill="currentColor"/>
  </svg>
</template>