<template>
  <div class="upload-profile">
    <div v-if="design=='box-upload'" class="box-upload">
      <div class="upload-logo grid-x grid-padding-x">
        <div id="example-tabs" style="display:none"></div>
        <div class="leftside-profile-image">
          <h4>Upload profile image</h4>
          <div class="drag-drop-box3" style="float:left; width:100%;">
            <div
              class="uploader"
              @dragenter="OnDragEnter"
              @dragleave="OnDragLeave"
              @dragover.prevent
              @drop="onDrop"
              :class="{ dragging: isDragging }"
            >
              <div style="padding-top:26px; padding-left:33px; padding-bottom:30px;">
                <img src="/images/group-10.png" class="icon" />
                <p>
                  Drag and drop an image or
                  <input
                    data-button-text="browse"
                    type="file"
                    style="display:none"
                    id="file"
                    ref="fileInput"
                    @change="onInputChange"
                    value="Browse"
                  />
                  <a
                    href="javascript:void(0)"
                    @click="$refs.fileInput.click()"
                    id="uploadTrigger"
                  >browse</a> to choose a file. Minimum size 200 x 200 px.
                </p>
              </div>
            </div>

            <div class="single-mobile-screen" style="float: right;margin-top: 0px;">
              <div class="images-preview image-placeholder" v-if="!images.length">
                <div class="img-wrapper" v-if="showimg === true">
                  <div v-on:click="removeimg();">
                    <i aria-hidden="true" class="fa fa-close fa-lg"></i>
                  </div>
                  <img :src="`/storage/${users.ImageBase64}`" />
                </div>
              </div>

              <div class="images-preview image-placeholder" v-else>
                <div class="img-wrapper" v-for="(image, index) in images" :key="index">
                  <div v-if="index == (images.length-1)">
                    <div v-on:click="removeimg();">
                      <i aria-hidden="true" class="fa fa-close fa-lg"></i>
                    </div>
                    <img :src="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="rightside-profile-image"></div>
      </div>
    </div>

    <div v-else-if="design == 'btn-upload'" class="btn-upload">
      <div class="input-box">
        <label class="label-dark">PROFILE PICTURE</label>
        <div v-if="avatorshow == 1">
          <span class="name" style="background: #FFFFFF;background-position: center;
background-size: cover;" :style="{backgroundImage: `url(${images[0]})`}">            
          </span>
        </div>
        
          <span class="name" style="background: #FFFFFF;background-position: center;
background-size: cover;" v-if="avatorshow==2" :style="{backgroundImage: `url(/storage/${users.ImageBase64})`}">
            
          </span>
          <span v-if="avatorshow==3" class="name avator-bg">{{initiates}}</span>
        

        <p class="btn-upload-pic">
          <input
            data-button-text="browse"
            type="file"
            style="display:none"
            id="file"
            ref="fileInput"
            @change="onInputChange"
            value="Browse"
          />
          <button
            href="javascript:void(0)"
            @click="$refs.fileInput.click()"
            id="uploadTrigger"
            class="btn btn-orange btn-lg"
          >UPLOAD PHOTO</button>
        </p>

        <div v-if="deletebtnshow">
          <div class="delete_icon" v-on:click="removeimg();">
            <i aria-hidden="true" class="far fa-trash-alt"></i>
          </div>
          <!-- <img :src="images[0]" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["users", "design", "initiates"],
  data: () => ({
    deleted: false,
    showimg: true,
    isDragging: false,
    personal: {
      ImageBase64: "",
    },
    dragCount: 0,
    files: [],
    images: [],
  }),
  methods: {
    OnDragEnter(e) {
      e.preventDefault();

      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onInputChange(e) {
      const files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        this.$toastr.e(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const img = new Image(),
        reader = new FileReader();
      reader.onload = (e) => this.images.push(e.target.result);
      reader.readAsDataURL(file);

      const formData = new FormData();

      this.files.forEach((file) => {
        formData.append("images[]", file, file.name);
      });
      Vue.swal({
        title: "Uploading...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      //   @todo url
	  this.images = [];
      axios
        .post("/dashboard/upload-profilepicture", formData)
        .then((response) => {
          console.log(response.data);
          var pimg = "/storage/" + response.data.ImageBase64;
          //$(".appprofile b").empty().append("<img class='avatar' src='" + pimg + "' />");
		  //$(".avatar").attr("src","/storage/"+response.data.ImageBase64);
		  $(".appprofile b").empty();
          $(".appprofile").css({ background: "#ffffff" });
		  $(".appprofile").css({ backgroundPosition: "center" });
		  $(".appprofile").css({ backgroundSize: "cover" });
		  $(".appprofile").css({ backgroundImage: `url(${pimg})` });
          this.$root.$emit("EditAccount::Success", response.data);
          this.deleted = true;

          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Profile picture uploaded',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        });
    },
    getFileSize(size) {
      const fSExt = ["Bytes", "KB", "MB", "GB"];
      let i = 0;

      while (size > 900) {
        size /= 1024;
        i++;
      }
      return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
    },
    upload() {
      const formData = new FormData();

      this.files.forEach((file) => {
        formData.append("images[]", file, file.name);
      });
      Vue.swal({
        title: "Uploading...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
	  this.images = [];
      //   @todo url
      axios
        .post("/dashboard/upload-profilepicture", formData)
        .then((response) => {
          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Profile picture uplaoded.',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        });
    },
    removeimg() {
      this.images.push("");
      this.showimg = false;
      Vue.swal({
        title: "Deleting...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      //   @todo url
      axios.post("/dashboard/delete-profilepicture", "").then((response) => {
        //console.log(response.data);
        var initVal = $("#init_vale").val();
        this.images = [];
        this.files = [];
		this.users.ImageBase64 ='';
        $(".avatar").attr("src", "");
        $(".appprofile b").empty().append(initVal);

        this.$root.$emit("EditAccount::Success", response.data);

        $(".dropbtn").css({ background: "#d8f1ea" });
        Vue.swal({
          html:
            '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Profile picture deleted.',
          confirmButtonColor: "#5dc2a6",
          confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
          background: "#5dc2a6",
          customClass: {
            container: "candidate-toast",
          },
          showClass: {
            popup: "animated fadeInLeft fast",
            icon: "animated heartBeat delay-1s",
          },
          hideClass: {
            popup: "animated fadeOutLeft slow",
          },
          toast: true,
          timer: 5000,
          position: "top-right",
          target: "#example-tabs",
        });

        this.showimg = false;
        this.images = [];
        this.files = [];
      });
    },
  },
  computed: {
    btnDisabled: function () {
      if (this.images.length > 0) {
        return false;
      } else return true;
    },

    deletebtnshow() {
      if (this.images.length > 0 || this.users.ImageBase64) {
        return true;
      } else return false;
    },

    avatorshow(){
      if(this.images.length>0){
        return 1;
      }else if(this.users.ImageBase64 !=''){
        return 2;
      }else return 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.uploader {
  float: left;
  width: 416px;
  height: 112px;
  border-radius: 8px;
  border: dashed 1px #dadfe8;
  background-color: #f9fafb;
  position: relative;
  img.icon {
    vertical-align: top;
  }
  p {
    width: 249px;
    height: 38px;
    font-family: InterUI;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    color: #656a85;
    padding-left: 28px;
    /*float: right;
    position: absolute;
    top: 26px;
    right: 0px;
    text-align: left;
    width: 240px;
    font-size: 12px;*/
    display: inline-block;
  }
  &.dragging {
    background: #fff;
    color: #2196f3;
    border: 3px dashed #2196f3;
    .file-input label {
      background: #2196f3;
      color: #fff;
    }
  }
  i {
    font-size: 85px;
  }
  .file-input {
    width: 200px;
    margin: auto;
    height: 68px;
    position: relative;
    label,
    input {
      background: #fff;
      color: #2196f3;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 10px;
      border-radius: 4px;
      margin-top: 7px;
      cursor: pointer;
    }
    input {
      opacity: 0;
      z-index: -2;
    }
  }
  .images-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .img-wrapper {
      margin: 10px;
      img {
        margin: 0;
        width: 100%;
        height: auto;
      }
    }
    .details {
      font-size: 12px;
      background: #fff;
      color: #000;
      display: flex;
      flex-direction: column;
      align-items: self-start;
      padding: 3px 6px;
      .name {
        overflow: hidden;
        height: 18px;
      }
    }
  }
  .upload-control {
    position: absolute;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px;
    padding-bottom: 4px;
    text-align: right;
    margin-top: 30px;
  }
}
</style>