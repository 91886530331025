<template></template>

<script>
export default {
    name: 'UpdateTariffStatus',
    props: {
        tariff: { type: Object, required: true },
    },
    methods: {
        async updateStatus() {
			await axios.patch(`/point-tariffs/${this.tariff.id}/toggle-status`)
				.then(response => {
					this.$emit('on-update', response.data.data.pointTariff);

					Vue.swal({
						html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>' + response.data.data.message,
            			confirmButtonColor: "#5dc2a6",
              			confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
             			background: "#5dc2a6",
						customClass: {
							container: "candidate-toast",
						},
						showClass: {
							popup: "animated fadeInLeft fast",
							icon: "animated heartBeat delay-1s",
						},
						hideClass: {
							popup: "animated fadeOutLeft slow",
						},
						toast: true, 
						timer: 5000,
						position: "top-right",
						target: "#example-tabs",
					});
				})
				.catch(error => {
					const errorMessage = error.response.status === 422 
						? error.response.data.message
						: "Something went wrong. Please try again.";

					Vue.swal({
						title: "Oops!",
						text: errorMessage,
						confirmButtonColor: "#ec5168",
						allowOutsideClick: false,
					});
				});
        }
    }
}
</script>