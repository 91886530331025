<template>
  <div>
    <div v-show="showLoadRequest" class="show-load-app">
      <img :src="myImage" />
    </div>
    <div
      class="grid-x grid-padding-x"
      style="margin-top: -78px; position: relative; float: right"
    >
      <div class="cell shrink add-invitee candidate-margin-left">
        <add-payment-request-component
          modalKeySuffix="request"
          :current-user="currentUser"
          :show_sites="show_sites"
          :user_site_count="user_site_count"
        />
      </div>
    </div>

    <div class="payment-request">
      <div class="grid-x grid-padding-x">
        <div class="cell shrink" v-if="currentUser.advanced_admin">
          <div
            class="dropdown-pane"
            style="width: 220px"
            id="approve"
            data-dropdown
            data-auto-focus="true"
            data-position="bottom"
            data-alignment="center"
          >
            <div class="arrow_box">
              <fieldset class="cell" align="center">
                <legend>
                  Are you sure you'd like to approve all selected payment? This
                  can not be undone.
                </legend>
                <input
                  type="radio"
                  name="interview"
                  v-model="showSubmit"
                  value="true"
                  id="interviewYes"
                  required
                />
                <label for="interviewYes">YES</label>
                <input
                  type="radio"
                  name="interview"
                  v-model="showSubmit"
                  id="interviewNo"
                />
                <label for="interviewNo">NO</label>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <div class="cf-table">
        <div class="table-top-area">
          <div class="grid-x">
            <div class="cell auto font_0875 no-padding">
              <div class="grid-x" style="margin-bottom: -10px">
                <div class="cell small-5 pull-left">
                  <div class="total_inline">
                    <div class="tot-num-bonus">
                      {{ requestedPaginationData.total }}
                    </div>
                    <div
                      class="total-points-bonus"
                      v-if="requestedPaginationData.total == 1"
                    >
                      Outstanding request
                    </div>
                    <div class="total-points-bonus" v-else>
                      Outstanding requests
                    </div>
                  </div>
                  <div class="total_inline">
                    <div class="tot-num-bonus">{{ totalPointsCount }}</div>
                    <div
                      class="total-points-bonus"
                      v-if="totalPointsCount == 1"
                    >
                      Point requested
                    </div>
                    <div class="total-points-bonus" v-else>
                      Points requested
                    </div>
                  </div>
                </div>
                <div class="cell small-7 pull-right">
                  <div class="cell shrink">
                    <a
                      href="javascript:void(0)"
                      @click="filtertoggle()"
                      class="btn button orange_btn float-right bonus-filter-cls"
                    >
                      FILTERS <img src="/images/filter-icon.png" alt="img"
                    /></a>
                  </div>
                  <div class="cell shrink">
                    <a
                      :href="
                        '/dashboard/account/payment-request-report-filter-selected?site[]=' +
                        selectedSite +
                        '&status[]=' +
                        selectedStatus +
                        '&start_date=' +
                        filterStartDate +
                        '&end_date=' +
                        filterEndDate +
                        '&search=' +
                        searchRequestpym +
                        '&userId[]=' +
                        selectedUsers +
                        ''
                      "
                      class="btn button orange_btn float-right"
                      >EXPORT</a
                    >
                  </div>
                  <div class="cell shrink">
                    <input
                      class="jobs-search"
                      placeholder="Search"
                      v-model="searchRequestpym"
                      name="searchRequestpym"
                      @keyup.delete="loadPaymentRequests()"
                      @keyup="searchPayment()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="showFilter">
            <div class="grid-x filters-wrappers">
              <div class="cell small-2 padright">
                <div class="input-box">
                  <date-range-picker
                    v-model="dateRange"
                    id="date-range-filter"
                    ref="dateRangeFilter"
                    opens="right"
                    class="phone-recruiter"
                    @keydown.enter="addTag"
                    @keydown.188="addTag"
                    @select="addTag"
                    @keydown.delete="removeLastTag"
                  >
                    <template>
                      <label class="datelabelclass">
                        Date Requested
                      </label>
                    </template>
                  </date-range-picker>
                </div>
              </div>

              <div v-if="show_sites" class="cell small-2 padright">
                <div class="input-box">
                  <multiselect 
                    track-by="id" 
                    label="name" 
                    placeholder="Sites" 
                    v-model="select_site"
                    :options="availableSites" 
                    :searchable="false" 
                    :multiple="true"
                    :allow-empty="true"
                    selectLabel=""
                    deselectLabel=""
                  >
                    <template 
                      slot="singleLabel" 
                      slot-scope="{ option }"
                    >
                      {{ option.name }}
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="cell small-2 padright">
                <div class="input-box">
                  <multiselect 
                    track-by="id" 
                    label="name" 
                    placeholder="Users" 
                    v-model="select_users"
                    :options="allUsers" 
                    :searchable="false" 
                    :multiple="true"
                    :allow-empty="true"
                    selectLabel=""
                    deselectLabel=""
                  >
                    <template 
                      slot="singleLabel" 
                      slot-scope="{ option }"
                    >
                      {{ option.name }}
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="cell small-2 padright">
                <div class="input-box">
                  <multiselect 
                    track-by="id" 
                    label="name" 
                    placeholder="Status" 
                    v-model="selectedStatuses"
                    :options="availableStatus" 
                    :searchable="false" 
                    :multiple="true"
                    :allow-empty="true"
                    selectLabel=""
                    deselectLabel=""
                  >
                    <template 
                      slot="singleLabel" 
                      slot-scope="{ option }"
                    >
                      {{ option.name }}
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="
            grid-x grid-padding-x
            cf-table-head
            red_bg
            t_margin_1
            align-middle
          "
        >
          <div class="cell checbox-width">
            <b-form-checkbox
              @click="taskBar()"
              v-model="selectAll"
              :indeterminate.sync="indeterminate"
            />
            <div class="select-icon-only">
              <v-select
                class="dropdown-select"
                v-model="filterOption"
                :options="fiterOptions"
              ></v-select>
            </div>
          </div>
          <div class="cell auto" align="left">
            <p class="tb_margin_1">
              <strong>Date</strong>
            </p>
          </div>
          <div class="cell auto" align="left">
            <p class="tb_margin_1">
              <strong>User</strong>
            </p>
          </div>
          <div class="cell auto">
            <p class="tb_margin_1" style="text-align: right">
              <strong>Payroll Info</strong>
            </p>
          </div>
          <div class="cell auto">
            <p class="tb_margin_1" style="text-align: right">
              <strong>Current Balance</strong>
            </p>
          </div>
          <div class="cell auto">
            <p class="tb_margin_1" style="text-align: right">
              <strong>Points Request</strong>
            </p>
          </div>

          <div class="cell auto" align="left">
            <p class="tb_margin_1" style="text-align: center">
              <strong>Status</strong>
            </p>
          </div>

          <div class="cell auto action-icons" align="center"></div>
        </div>

        <div
          v-if="requests.length == 0 && message != ''"
          class="empty-state empty-state-back"
        >
          <img src="/images/icon-empty-state-payment-request.png" />
          <p>{{ message }}</p>
          <br />
        </div>
        <div class="scroll-table" v-else>
          <div v-for="request in requests" v-bind:key="request.id">
            <single-payment-request-component
              @select="countSelected"
              :advanced="currentUser.advanced_admin"
              :current_user_id="currentUser.id"
              :childComponant="childComponant"
              :request="request"
              :currentuser="currentUser"
              :makeAllSelected="evaluateSelectAllOption"
              :selectionAction="actionType"
              :isCareSector="isCareSector"
              :taxName="taxName"
            >
            </single-payment-request-component>
          </div>
        </div>
      </div>
      <div
        v-if="this.countselect > 0"
        id="selection-buttons"
        class="payments-bar-row"
      >
        <section class="bar-row">
          <div class="inner-box">
            <strong class="number"></strong>
            <a @click="closeTaskBar()" class="cross">
              <img src="/images/exit-2.png" alt="img" />
            </a>
            <strong class="title">{{ countselect }} Items(s) selected</strong>

            <ul>
              <li>
                <a @click="exportCSV" target="_blank">
                  <img src="/images/svg/csv.svg" alt="img" />Export
                </a>
              </li>
              <li v-if="currentUser.approve_check">
                <a
                  @click="rejectSelected"
                  data-toggle="modal"
                  data-target="#reminderCheck"
                >
                  <img src="/images/svg/close.svg" alt="img" />Reject
                </a>
              </li>
              <li v-if="currentUser.approve_check">
                <a
                  @click="approveSelected"
                  data-toggle="modal"
                  data-target="#deleteCheck"
                >
                  <img src="/images/svg/checkmark.svg" alt="img" />Approve
                </a>
              </li>
              <li v-if="currentUser.paid_check">
                <a
                  @click="paySelected"
                  data-toggle="modal"
                  data-target="#deleteCheck"
                >
                  <img src="/images/svg/payment_.svg" alt="img" />Mark as Paid
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div>

      <div class="pagination-container margin-top-2">
        <template v-if="requestedPaginationData.last_page > 1">
          <paginate
            :page-count="requestedPaginationData.last_page"
            :page-range="3"
            :margin-pages="2"
            :click-handler="loadPaymentRequests"
            :prev-text="'<'"
            :next-text="'>'"
            :prev-class="'paginate-controls'"
            :next-class="'paginate-controls'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :active-class="'active'"
          ></paginate>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import Datepicker from "vuejs-datepicker";
import Paginate from "vuejs-paginate";
import DateRangePicker from "vue2-daterange-picker";
import eventBus from '../../utils/event-bus.js';
import Multiselect from "vue-multiselect";

export default {
  mixins: [
    // eslint-disable-next-line
    require("../../mixins/InitFoundation"),
  ],
  components: {
    Datepicker,
    Paginate,
    DateRangePicker: DateRangePicker,
  },
  props: ["raw_requests", "currentUser", "all_usersdata", "all_sites", "isCareSector", "taxName"],
  data() {
    return {
      markAsPaidButtonClicked: false,
      filterOption: "",
      indeterminate: false,
      tags: [],
      totalPointsCount: 0,
      loggedUserSiteId: null,
      availableSites: [],
      availableStatus: [],
      dateRange: {
        startDate: null,
        endDate: null,
      },
      select_site: "",
      select_status: "",
      selectedStatuses: [],
      requests: this.raw_requests,
      childComponant: new Vue(),
      tickAll: false,
      tickPayAll: false,
      showSubmit: false,
      dateFrom: null,
      inviteChecked: [],
      dateTo: null,
      csvUrl: this.getCsvUrl(),
      selectAll: false,
      selectedid: [],
      exportedid: [],
      fiterOptions: ["All", "All Pending", "All Approved", "None"],
      selectedAllBroken: false,
      actionType: "",
      showFilter: false,
      // Handling tabs pagination in Vuejs way
      requestedPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
        total_approved: 0,
        total_pending: 0,
      },
      selected_date_tags: "",
      selected_sites_tags: "",
      selected_status_tags: "",
      select_users: [],
      select_user_tag: "",
      siteCount: 0,
      selectedSite: [],
      selectedStatus: [],
      filterStartDate: "",
      filterEndDate: "",
      allUsers: [],
      all_users: this.all_usersdata,
      selectedUsers: [],
      searchRequestpym: "",
      showLoadRequest: false,
      myImage: "/images/loading-circle.gif",
      sites: this.all_sites,
      message: "",
      show_sites: false,
      user_site_count: 0,
    };
  },
  methods: {
    removeDateTags() {
      this.removeTag("reset");
      //this.selected_date_tags = '';
    },
    getAvailableSites() {
      //Create array of available users for dropdown
      var toProcess = Object.keys(this.sites);
      var site = {
        id: -1,
        name: "All Sites",
        image: "/images/idle.png",
      };
      this.availableSites.push(site);
      for (var i = toProcess.length - 1; i >= 0; i--) {
        var site = {
          name: this.sites[toProcess[i]].name,
          id: this.sites[toProcess[i]].id,
          image: "/images/idle.png",
        };
        this.availableSites.push(site);
      }
    },
    getAvailableStatus() {
      this.availableStatus = [
        { id: -1, name: "All", image: "/images/idle.png" },
        { id: 0, name: "Pending", image: "/images/idle.png" },
        { id: 1, name: "Approved", image: "/images/idle.png" },
      ];
    },
    getAllUsers() {
      var toProcess = Object.keys(this.all_users);
      for (var i = toProcess.length - 1; i >= 0; i--) {
        var user = {
          name:
            this.all_users[toProcess[i]].first_name +
            " " +
            this.all_users[toProcess[i]].surname,
          id: this.all_users[toProcess[i]].id,
          image: "/images/idle.png",
        };

        this.allUsers.push(user);
      }
    },
    rejectSelected() {
      let self = this;

      if (self.actionType != "" && !self.selectedAllBroken) {
        axios
          .post("/dashboard/points/request/destroy", {
            id: "reject-all",
            siteId: $("#selected-site").val(),
            filter_type: self.actionType,
          })
          .then(function (response) {
            self.loadPaymentRequests(self.requestedPaginationData.current_page);
            self.closeTaskBar();
            self.selectedAllBroken = true;
            //self.updatedPayments();
          });
      } else {
        for (var i = 0; i < this.countselect; i++) {
          axios
            .post("/dashboard/points/request/destroy", {
              id: this.selectedid[i].id,
            })
            .then(function (response) {
              self.loadPaymentRequests(
                self.requestedPaginationData.current_page
              );
              console.log(response);
            });
        }
        //self.updatedPayments();
      }

      //location.reload();// Why there is a need for this?
      this.selectedid = [];
      this.closeTaskBar();
    },
    filtertoggle() {
      if (this.showFilter == true) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
    },
    addTag(event) {
      //alert(event.endDate);

      $(".cancelBtn").trigger("click");
      // alert(event.startDate.toLocaleDateString());
      if (event.startDate && event.endDate) {
        this.dateRange = { startDate: event.startDate, endDate: event.endDate };
      };
      var newstartDate = event.startDate.getDate();
      var newstartMonth = event.startDate.getMonth() + 1;
      var newstartYear = event.startDate.getFullYear();
      //var FinalStartDate = newstartYear+"-"+newstartMonth+"-"+newstartDate;
      var FinalStartDate =
        newstartDate + "/" + newstartMonth + "/" + newstartYear;
      var newendDate = event.endDate.getDate();
      var newendMonth = event.endDate.getMonth() + 1;
      var newendYear = event.endDate.getFullYear();
      //var FinalEndDate = newendYear+"-"+newendMonth+"-"+newendDate;
      var FinalEndDate = newendDate + "/" + newendMonth + "/" + newendYear;

      var Difference_In_Days =
        (event.endDate - event.startDate) / (1000 * 3600 * 24);

      this.filterStartDate = FinalStartDate;
      this.filterEndDate = FinalEndDate;
      var d = new Date();
      var currentDate = d.getDate();
      var currentMonth = d.getMonth() + 1;
      var currentYear = d.getFullYear();
      // this method evaulate 'This Month' or 'Last Month' conditions.
      let monthSpan = this.evaluateMonthSpan(
        newstartMonth,
        Difference_In_Days,
        newstartDate,
        newstartYear
      );

      if (FinalStartDate == FinalEndDate) {
        var todayDate = currentDate + "/" + currentMonth + "/" + currentYear;
        var yesDate = currentDate - 1 + "/" + currentMonth + "/" + currentYear;

        if (FinalStartDate == todayDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Today  ");
        } else if (FinalStartDate == yesDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Yesterday ");
        } else {
          this.removeTag(this.tags.length - 1);
          this.tags.push(FinalStartDate + " to " + FinalEndDate);
        }
      } else if (Difference_In_Days > 1 && Difference_In_Days < 28) {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      } else if (
        currentMonth == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This Month ");
      } else if (
        currentMonth - 1 == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("Last Month ");
      } else if (currentYear && Difference_In_Days == 365) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This year ");
      } else {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      }
      this.selected_date_tags = "Date: " + this.tags[0];

      this.loadPaymentRequests(
        this.getParamsFromUrl("payments_page", window.location.href)
      );
    },
    removeTag(index) {
      if (index === "reset") {
        this.filterStartDate = "";
        this.filterEndDate = "";
      }
      this.selected_date_tags = "";
      this.tags.splice(index, 1);
      this.loadPaymentRequests(
        this.getParamsFromUrl("payments_page", window.location.href)
      );
    },
    removeLastTag(event) {
      console.log("866 - removeLastTag: ", event.target);
      if (event.target.value.length === 0) {
        this.filterStartDate = "";
        this.filterEndDate = "";
        this.removeTag(this.tags.length - 1);
        this.loadPaymentRequests(
          this.getParamsFromUrl("payments_page", window.location.href)
        );
      }
    },
    filterSelectedSite(value) {
      this.selectedSite = value;

      if (
        this.siteCount > 0 &&
        this.selectedSite.length > 0 &&
        this.selectedSite.length < this.siteCount
      ) {
        if (this.selectedSite.length % 2 == 0) {
          this.val -= 4;
        }
        this.siteCount = this.selectedSite.length;
      } else if (this.selectedSite.length == 0) {
        this.val = 0;
        this.siteCount = 0;
      } else if (this.selectedSite) {
        if (this.selectedSite.length % 2 == 0) {
          this.val += 4;
        }
        this.siteCount = this.selectedSite.length;
      }

      let selected_sites = [];
      let i;
      for (i = 0; i < this.availableSites.length; ++i) {
        if ($.inArray(this.availableSites[i].id, this.selectedSite) !== -1) {
          this.availableSites[i].image = "/images/selected.png";
          selected_sites.push(this.availableSites[i].name);
        }
      }
      if (selected_sites.length > 0) {
        this.selected_sites_tags = "Sites: " + selected_sites.join(", ");
      }

      this.loadPaymentRequests(
        this.getParamsFromUrl("payments_page", window.location.href)
      );
    },
    removeSiteTags() {
      this.$refs.siteFilter.clearSelection();
      this.selectedSite = "";
      this.selected_sites_tags = "";
      if (this.availableSites) {
        for (i = 0; i < this.availableSites.length; i++) {
          if (this.availableSites[i].image == "/images/selected.png") {
            this.availableSites[i].image = "/images/idle.png";
          }
        }
      }
    },
    filterSelectedUser(value) {
      this.selectedUsers = value;

      if (
        this.UsersCount > 0 &&
        this.selectedUsers.length > 0 &&
        this.selectedUsers.length < this.UsersCount
      ) {
        if (this.selectedUsers.length % 2 == 0) {
          this.val -= 4;
        }
        this.UsersCount = this.selectedUsers.length;
      } else if (this.selectedUsers.length == 0) {
        this.val = 0;
        this.UsersCount = 0;
      } else if (this.selectedUsers) {
        if (this.selectedUsers.length % 2 == 0) {
          this.val += 4;
        }
        this.UsersCount = this.selectedUsers.length;
      }

      let selected_users = [];
      let i;
      for (i = 0; i < this.allUsers.length; ++i) {
        if ($.inArray(this.allUsers[i].id, this.selectedUsers) !== -1) {
          this.allUsers[i].image = "/images/selected.png";
          selected_users.push(this.allUsers[i].name);
        }
      }

      if (selected_users.length > 0) {
        this.select_user_tag = "Users: " + selected_users.join(", ");
      }

      this.loadPaymentRequests(
        this.getParamsFromUrl("payments_page", window.location.href)
      );
    },
    removeUserTags() {
      this.$refs.userFilter.clearSelection();
      this.selectedUsers = "";
      this.select_user_tag = "";
      if (this.allUsers) {
        for (i = 0; i < this.allUsers.length; i++) {
          if (this.allUsers[i].image == "/images/selected.png") {
            this.allUsers[i].image = "/images/idle.png";
          }
        }
      }
    },
    filterselectedStatus(value) {
      this.selectedStatus = value;

      let selected_status = [];
      let i;
      for (i = 0; i < this.availableStatus.length; ++i) {
        if ($.inArray(this.availableStatus[i].id, this.selectedStatus) !== -1) {
          this.availableStatus[i].image = "/images/selected.png";
          selected_status.push(this.availableStatus[i].name);
        }
      }

      if (selected_status.length > 0) {
        this.selected_status_tags = "Status: " + selected_status.join(", ");
      }

      this.loadPaymentRequests(
        this.getParamsFromUrl("payments_page", window.location.href)
      );
    },
    removeStatusTags() {
      this.$refs.statusFilter.clearSelection();
      this.selectedStatus = [];
      this.selected_status_tags = "";

      if (this.availableStatus) {
        for (i = 0; i < this.availableStatus.length; i++) {
          if (this.availableStatus[i].image == "/images/selected.png") {
            this.availableStatus[i].image = "/images/idle.png";
          }
        }
      }
    },
    approveSelected() {
      let self = this;
      let id = null;

      if (self.actionType != "" && !self.selectedAllBroken) {
        axios
          .post("/dashboard/points/approve-all/approve", {
            siteId: $("#selected-site").val(),
            approveStatus: true,
            filter_type: self.actionType,
          })
          .then(function (response) {
            if (response.data.success == false) {
              Vue.swal({
                title: "We could not Approve this Payment Request",
                confirmButtonColor: "#F84366",
              });
            } else {
              self.loadPaymentRequests(
                self.requestedPaginationData.current_page
              );
              self.closeTaskBar();
              self.selectedAllBroken = true;
            }
            //self.updatedPayments();
          });
      } else {
        for (var i = 0; i < this.countselect; i++) {
          this.selectedid[i].approveStatus = true;
          id = this.selectedid[i].id;
          const user = this.selectedid[i].user;
          const amount = this.selectedid[i].amount;
          axios
            .post(
              "/dashboard/points/" + this.selectedid[i].id + "/approve",
              this.selectedid[i]
            )
            .then(function (response) {
              if (response.data.success == false) {
                Vue.swal({
                  title: "We could not Approve this Payment Request",
                  text: `${user.first_name} ${user.surname} - ${amount} points`,
                  confirmButtonColor: "#F84366",
                });
              } else {
                self.childComponant.$emit("approveBulk", id);
                self.loadPaymentRequests(
                  self.requestedPaginationData.current_page
                );
              }
            })
            .catch(function () {
              Vue.swal({
                title: "We could not Approve this Payment Request",
                text: `${user.first_name} ${user.surname} - ${amount} points`,
                confirmButtonColor: "#F84366",
              });
            });
        }
        //self.updatedPayments();
      }

      //this.selectedid=[];
      this.childComponant.$emit("deselectAll");
      this.indeterminate = false;
      this.selectedid = [];
    },

    paySelected() {
      if (this.markAsPaidButtonClicked) {
        return;
      }
      this.markAsPaidButtonClicked = true;
      let self = this;
      let id = null;

      /*if(self.actionType != ''  && !self.selectedAllBroken){

				    axios.post('/dashboard/points/pay-all/approve', {siteId: $("#selected-site").val(), paidStatus: true, filter_type: self.actionType})
                        .then(function (response) {
                            if (response.data == false) {
                                Vue.swal({
                                    title: 'Redeemable balance is insufficient for the payment request',
                                    confirmButtonColor: '#F84366'
                                });
                            } else {
                                self.closeTaskBar();
                                self.selectedAllBroken = true;
                            }
                            self.updatedPayments();
                        });

                } else {*/
      for (var i = 0; i < this.countselect; i++) {
        console.log(self.selectedid[i].id);
        self.selectedid[i].paidStatus = true;
        id = self.selectedid[i].id;

        axios
          .post(
            "/dashboard/points/" + this.selectedid[i].id + "/approve",
            this.selectedid[i]
          )
          .then(function (response) {
            if (response.data.success == false) {
              Vue.swal({
                title:
                  "Redeemable balance is insufficient for the payment request",
                confirmButtonColor: "#F84366",
              });
            } else {
              self.childComponant.$emit("payBulk", id);
              self.loadPaymentRequests(
                self.requestedPaginationData.current_page
              );
            }
          });
      }
      //self.updatedPayments();
      //}
      this.selectedid = [];
    },

    getCsvUrl() {
      var urlParams = new URLSearchParams(window.location.search);
      return (
        "/dashboard/account/payment-request-report?" + urlParams.toString()
      );
    },

    exportCSV() {
      let self = this;

      let url = "";
      let method = "POST";
      let axiosData = {};

      if (self.actionType != "" && !self.selectedAllBroken) {
        url = "/dashboard/account/payment-request-report-selected";
        axiosData = {
          data: {
            ids: [],
            exportAll: true,
            siteId: $("#selected-site").val(),
            filter_type: self.actionType,
          },
        };
      } else if (this.countselect > 0) {
        url = "/dashboard/account/payment-request-report-selected";
        const ids = this.selectedid.map((obj) => obj.id);
        axiosData = { params: ids };
      } else {
        url = this.csvUrl;
        method = "GET";
      }

      axios({
        url,
        method,
        responseType: "blob",
        Headers: { Accept: "application/vnd.ms-excel" },
        ...axiosData,
      })
        .then((response) => {
          if (self.actionType != "" && !self.selectedAllBroken) {
            self.closeTaskBar();
            self.selectedAllBroken = true;
          }

          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "payment-request.xlsx");
          link.setAttribute("target", "_blank");
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {});

      this.childComponant.$emit("deselectAll");
      this.indeterminate = false;
      this.selectedid = [];
    },

    save() {
      console.log("Saving..");
      this.childComponant.$emit("save");

      $("#approve").foundation("close");
    },
    /**
     * Send AJAX request to get the requested payments
     * @param page
     */
    loadPaymentRequests(page) {
      let self = this;
      this.requests = [];
      let filter_site_id = "";
      let status = "";
      //this.showLoadRequest = true;

      axios
        .post("/dashboard/points/get-requested-payments", {
          payments_page: page,
          siteId: $("#selected-site").val(),
          filter_site_id: this.selectedSite,
          status: this.selectedStatus,
          start_date: this.filterStartDate,
          end_date: this.filterEndDate,
          filter_user_id: this.selectedUsers,
          search: this.searchRequestpym,
        })
        .then((response) => {
          this.show_sites = response.data.show_sites;
          this.user_site_count = response.data.user_site_count;
          //console.log(response.data.redeemRequests);
          if (Object.keys(response.data.redeemRequests).length > 0) {
            this.showLoadRequest = false;
            this.requests = response.data.redeemRequests;
            this.totalPointsCount = response.data.total_points;
            this.loggedUserSiteId = response.data.loggedUserSiteId;

            if (response.data.hasOwnProperty("pagination")) {
              let paginationArr = response.data.pagination;
              this.requestedPaginationData.current_page =
                paginationArr.current_page;
              this.requestedPaginationData.from = paginationArr.from;
              this.requestedPaginationData.last_page = paginationArr.last_page;
              this.requestedPaginationData.per_page = paginationArr.per_page;
              this.requestedPaginationData.to = paginationArr.to;
              this.requestedPaginationData.total = paginationArr.total;
              this.requestedPaginationData.total_pending =
                paginationArr.total_pending;
              this.requestedPaginationData.total_approved =
                paginationArr.total_approved;
            }

            // Reset selected items for "All" case when someone unselect any option on current page then it will have records of current page.
            self.selectedid = [];
            if (self.actionType == "all" && !self.selectedAllBroken) {
              $.each(self.requests, function (index, request) {
                self.selectedid.push(request);
              });
            }
            if (self.actionType == "all-pending" && !self.selectedAllBroken) {
              $.each(self.requests, function (index, request) {
                if (request.status == 0) {
                  self.selectedid.push(request);
                }
              });
            }
            if (self.actionType == "all-approved" && !self.selectedAllBroken) {
              $.each(self.requests, function (index, request) {
                if (request.status == 1) {
                  self.selectedid.push(request);
                }
              });
            }

            // show action if there are already selected records.
            if (self.selectedid.length > 0) {
              $("#selection-buttons").show();
            }
          } else {
            if (
              this.selectedSite.length > 0 ||
              this.selectedStatus.length > 0 ||
              this.filterStartDate.length > 0 ||
              this.filterEndDate.length > 0 ||
              this.selectedUsers.length > 0 ||
              this.searchRequestpym.length > 0
            ) {
              this.message =
                "No outstanding payment requests match your current criteria. Please amend your filters/search and try again.";
              this.requestedPaginationData.total = 0;
              this.totalPointsCount = 0;
            } else {
              this.message =
                "You don't have any outstanding payment requests yet.";
              this.requestedPaginationData.total = 0;
              this.totalPointsCount = 0;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updatedPayments() {
      this.loadPaymentRequests(
        this.getParamsFromUrl("payments_page", window.location.href)
      );
    },
    /**
     * Get specific parameter from the url
     * @param name
     * @param url
     * @returns {*}
     */
    getParamsFromUrl(name, url) {
      if (!url) url = location.href;
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      const regexS = "[\\?&]" + name + "=([^&#]*)";
      let regex = new RegExp(regexS);
      const results = regex.exec(url);
      return results == null ? null : results[1];
    },

    closeTaskBar() {
      $("#selection-buttons").hide();
    },
    countSelected(val) {
      //val is id of object selected
      if (this.selectedid.includes(val)) {
        var removeIndex = this.selectedid
          .map(function (item) {
            return item.id;
          })
          .indexOf(val.id);

        // remove object
        this.selectedid.splice(removeIndex, 1);
        //this.selectedid.pop(val);
      } else {
        this.selectedid.push(val);
      }
      // this.countselect = this.selectedid.length;
      if (this.countselect > 0) {
        this.indeterminate = true;
      }
    },
    selectAllids() {
      for (var i = 0; i < this.requests.length; i++) {
        this.countSelected(this.requests[i]);
      }
    },
    evaluateMonthSpan: function (
      newstartMonth,
      Difference_In_Days,
      newstartDate,
      newstartYear
    ) {
      // Transform below code to find total days in month.
      let monthTotalDays = [
        31, // Jan
        28, // Feb
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // Aug
        30, // Sep
        31, // Oct
        30, // Nov
        31, // Dec
      ];

      let monthIndex = newstartMonth - 1;
      let monthDays = monthTotalDays[monthIndex];
      let totalSelectedSpan = Difference_In_Days + 1; // catering previous dev coded result.

      // February Case
      if (newstartMonth == 2) {
        // When it is leap year like 2020, Feb would be off 29
        if (
          newstartYear % 4 == 0 &&
          totalSelectedSpan == 29 &&
          newstartDate == 1
        ) {
          return true;
        } else if (
          newstartYear % 4 != 0 &&
          totalSelectedSpan == 28 &&
          newstartDate == 1
        ) {
          return true;
        }
        return false;
      } else {
        // Non February Cases
        if (totalSelectedSpan == monthDays && newstartDate == 1) {
          return true;
        }
        return false;
      }
    },
    getAllSites() {
      var self = this;
      axios.get("/dashboard/points/get-all-sites").then(function (response) {
        self.sites = response.data.sites;
        self.availableSites = response.data.sites;
      });
    },
    searchPayment() {
      if (this.searchRequestpym.length > 2) {
        this.loadPaymentRequests(
          this.getParamsFromUrl("payments_page", window.location.href)
        );
      }
    },
  },
  watch: {
    dateRange: function (newVal, oldVal) {
      console.log({'newVal': newVal, 'oldVal': oldVal});
    },
    tickAll: function (val) {
      if (val) {
        this.childComponant.$emit("approve");
      } else {
        this.childComponant.$emit("unApprove");
      }
    },
    tickPayAll: function (val) {
      if (val) {
        this.childComponant.$emit("pay");
      } else {
        this.childComponant.$emit("unPay");
      }
    },
    selectAll: function (val) {
      if (val) {
        this.selectAllids();
        this.childComponant.$emit("selectAll");
      } else {
        this.childComponant.$emit("deselectAll");
      }
    },
    filterOption: function (newVal, oldVal) {
      let self = this;
      this.selectedid = [];
      this.selectedAllBroken = false;

      if (newVal == "All") {
        $.each(this.requests, function (index, request) {
          self.selectedid.push(request);
        });
        this.selectAll = true;
        this.childComponant.$emit("selectAll");
        this.selectAllids();
        this.indeterminate = false;
        this.actionType = "all";

        if (this.selectedid.length > 0) {
          $("#selection-buttons").show();
        }
      } else if (newVal == "All Pending") {
        this.childComponant.$emit("pendingAll");
        $.each(this.requests, function (index, request) {
          if (request.status == 0) {
            self.selectedid.push(request);
          }
        });
        this.indeterminate = true;
        this.actionType = "all-pending";
        if (this.selectedid.length > 0) {
          $("#selection-buttons").show();
        }
      } else if (newVal == "All Approved") {
        $.each(this.requests, function (index, request) {
          if (request.status == 1) {
            self.selectedid.push(request);
          }
        });
        this.indeterminate = true;
        this.childComponant.$emit("approvedAll");
        this.actionType = "all-approved";
        if (this.selectedid.length > 0) {
          $("#selection-buttons").show();
        }
      } else {
        this.actionType = "";
        this.childComponant.$emit("deselectAll");
        this.indeterminate = false;
        this.selectAll = false;
        this.selectedAllBroken = true;
      }
    },
    selectedStatuses: function (newVal, oldVal) {
      const selectedStatusIds = newVal.reduce((carry, val) => [...carry, val.id], []);
      this.filterselectedStatus(selectedStatusIds);
    },
    select_users: function (newVal, oldVal) {
      const selectedUserIds = newVal.reduce((carry, val) => [...carry, val.id], []);
      this.filterSelectedUser(selectedUserIds);
    },
    select_site: function (newVal, oldVal) {
      const selectedSiteIds = newVal.reduce((carry, val) => [...carry, val.id], []);
      this.filterSelectedSite(selectedSiteIds);
    },
  },
  computed: {
    countselect() {
      if (this.actionType == "all" && !this.selectedAllBroken) {
        return this.requestedPaginationData.total;
      }
      if (this.actionType == "all-pending" && !this.selectedAllBroken) {
        return this.requestedPaginationData.total_pending;
      }
      if (this.actionType == "all-approved" && !this.selectedAllBroken) {
        return this.requestedPaginationData.total_approved;
      }
      return this.selectedid.length;
    },
    evaluateSelectAllOption: function () {
      if (
        this.selectAll &&
        this.filterOption == "All" &&
        !this.selectedAllBroken
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    // Listen for the 'request-paid' event
    eventBus.$on('undo-clicked', this.loadPaymentRequests);
  },
  beforeDestroy() {
    // Unsubscribe from the 'request-paid' event when the component is destroyed
    eventBus.$off('undo-clicked');
  },
  mounted() {
    //Fetch the requested payments
    const params = this.getParamsFromUrl("payments_page", window.location.href);

    this.loadPaymentRequests(params);

    this.$root.$on("paymentRequests", this.updatedPayments);

    //Add/Remove checkbox id when it is checked/unchecked.
    this.$root.$on("select", (paymentRequest, checkedState) => {
      if (checkedState) {
        this.selectedid.push(paymentRequest);
      } else {
        for (i = 0; i < this.selectedid.length; i++) {
          if (this.selectedid[i].id === paymentRequest.id) {
            this.selectedid.splice(i, 1);
          }
        }
        // Handle Select All case.
        this.selectedAllBroken = true;
        console.log("selected requests: ", this.selectedid);
      }
    });
    this.getAvailableStatus();
    //this.getAllSites();
    this.getAvailableSites();
    this.getAllUsers();
  },
};
</script>


<style>
#requested-payments .table-top-area {
  padding: 10px 20px;
}
.datelabelclass {
  margin-top: 6px;
}
/*custom stylesheet by KM*/
.payment-request .cell.checbox-width {
  display: flex;
  align-items: center;
  width: 45px;
}
.payment-request .cell.checbox-width .custom-control.custom-checkbox {
  display: none;
}
.payment-request .cell .checbox-width .select-icon-only {
  width: 18px;
}
.payment-request .cell.checbox-width .select-icon-only div#vs4__combobox {
  height: 18px;
  border-radius: 0px;
  border-color: #ccc;
}
.payment-request .cell.checbox-width .select-icon-only ul#vs4__listbox {
  margin-top: 2px;
}
#requested-payments .grid-x.filters-wrappers .cell:first-child {
  max-width: 150px;
  width: 100%;
}
#requested-payments
  .grid-x.filters-wrappers
  .cell:first-child
  .vue-daterange-picker.phone-recruiter {
  min-width: 100% !important;
  width: 100%;
}
#requested-payments
  .grid-x.filters-wrappers
  .cell:first-child
  .vue-daterange-picker.phone-recruiter
  .form-control.reportrange-text {
  max-width: 100%;
  border: 1px solid #dcdee6 !important;
}
#requested-payments .vs__search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #85899e;
}
#requested-payments .vs__search::-moz-placeholder {
  /* Firefox 19+ */
  color: #85899e;
}
#requested-payments .vs__search:-ms-input-placeholder {
  /* IE 10+ */
  color: #85899e;
}
#requested-payments .vs__search:-moz-placeholder {
  /* Firefox 18- */
  color: #85899e;
}
.vs__dropdown-menu {
  width: auto !important;
}
#requested-payments .cf-table tbody tr td {
  padding: 8px 0px !important;
}
#requested-payments .daterangepicker .calendar-table td,
#requested-payments .daterangepicker .calendar-table th {
  width: auto;
  height: auto;
  min-width: auto;
}
#requested-payments .cf-table thead th {
  padding: 3px !important;
  background: #fff !important;
}
#requested-payments .cf-table th.month {
  font-size: 14px !important;
  text-transform: initial !important;
}
#requested-payments .filters-wrappers .cell:nth-child(2) {
  max-width: unset;
}
#requested-payments .tag-input__tag {
  z-index: 1;
}
#requested-payments .vs__actions .vs__clear {
  display: none;
}
#requested-payments .datelabelclass {
  color: #85899e !important;
}
.payment-page h2#swal2-title {
  justify-content: left;
}
.payment-page .single-app-user-swal h2#swal2-title,
.payment-page .all-users-site-swal h2#swal2-title,
.payment-page .single-app-user-swal .swal2-actions,
.payment-page .all-users-site-swal .swal2-actions {
  justify-content: center;
}

.payment-page .all-users-at-org-swal .swal2-actions {
  justify-content: flex-end;
  padding: 0 1.6em;
}
.payment-page .single-app-user-swal button.swal2-cancel.swal2-styled,
.payment-page .all-users-site-swal button.swal2-cancel.swal2-styled,
.payment-page .all-users-at-org-swal button.swal2-cancel.swal2-styled {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: center;
  color: #312d8b;
  background-color: #eeeff7;
  padding: 14px 23px;
  border-radius: 4px;
}

.payment-page .single-app-user-swal button.swal2-confirm.swal2-styled,
.payment-page .all-users-site-swal button.swal2-confirm.swal2-styled,
.payment-page .all-users-at-org-swal button.swal2-confirm.swal2-styled {
  font-size: 12px;
  font-weight: 600;
  padding: 14px 15px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  background-color: #f55d73;
  width: 96px;
}
.payment-page .single-app-user-swal div#swal2-content,
.payment-page .all-users-site-swal div#swal2-content,
.payment-page .all-users-at-org-swal div#swal2-content {
  text-align: center;
  line-height: 1.57;
}
.payment-page .single-app-user-swal .swal2-icon.swal2-warning,
.payment-page .all-users-site-swal .swal2-icon.swal2-warning,
.payment-page .all-users-at-org-swal .swal2-icon.swal2-warning {
  border-color: #f55d73;
  color: #f55d73;
}
.payment-page .swal2-popup.swal2-toast {
  box-shadow: none;
}
.payment-page .cell.pay-user-col {
  width: 20%;
}

.payment-request .cf-table {
  margin-top: 0px;
}
.margin-set {
  margin-bottom: 1%;
  height: 44px;
}
.payment-request .cell.checbox-width .select-icon-only .vs__dropdown-toggle {
  height: 18px !important;
  border-radius: 0px !important;
  border-color: #ccc !important;
  width: 18px !important;
}
.payment-request .jobs-search {
  height: 40px;
  width: 280px;
  padding: 6px 6px 6px 38px;
  border: 1px solid #dcdee6;
  border-radius: 3px;
  color: #9297ae;
  font: 400 14px InterUI, sans-serif;
  background: #fff url(/images/search.png) no-repeat 7px;
  margin-right: 12px;
  float: right;
}
.payment-request .total_inline {
  float: left;
  width: 40%;
}
.payment-request .total-points-bonus {
  font-family: Helvetica !important;
}
.payment-request .right_text-align {
  text-align: right !important;
}
.payment-request .fillrotton {
  color: #ee6969;
}
</style>
