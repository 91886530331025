<template>
  <div>
    <div v-show="showLoad" class="show-load-app">
      <img :src="myImage" />
    </div>
    <div class="grid-x app-margin-bottom">
      <div class="cell auto font_0875 no-padding">
        <div class="grid-x">
          <div class="cell small-4">
            <input
              class="jobs-search"
              placeholder="Search: Enter at least 3 characters"
              v-model="searchVar"
              name="searchVar"
              @keyup.delete="searchAndFilterUsers()"
              @keyup="searchUsers()"
            />
          </div>
          <div
            v-if="currentuser.users_check"
            class="cell shrink add-invitee candidate-margin-left"
          >
            <button
              data-toggle="modal"
              data-target="#chooseMethod"
              class="button pink_btn b_margin_0 no_wrap_btn job-add float-right"
            >
              Add Invitees
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bonus-points-history-table">
      <div class="table-top-area">
        <div class="grid-x">
          <div class="cell small-12">
            <div class="cell small-6 pull-left bonus-history-top">
              <div class="tot-num-bonus">
                {{
                  inviteChecked.length > 0
                    ? inviteChecked.length
                    : total_invitees
                }}
              </div>
              <div class="total-points-bonus">Current users</div>
            </div>
            <div class="cell small-6 pull-right bonus-history-top">
              <div class="cell shrink">
                <a
                  href="javascript:void(0)"
                  class="btn button orange_btn float-right bonus-filter-cls"
                  @click="filtertoggle()"
                >
                  FILTERS <img src="/images/filter-icon.png" alt="img"
                /></a>
              </div>
              <div class="cell shrink">
                <a
                  class="btn button orange_btn float-right"
                  :href="
                    '/dashboard/account/search-app-export?search=' +
                    searchVar +
                    '&status=' +
                    statusFilter +
                    '&site=' +
                    siteFilter
                  "
                >
                  EXPORT
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- filter start-->
        <div v-show="showFilter">
          <div class="grid-x filters-wrappers-app">
            <div class="cell small-2 padright">
              <div class="input-box">
                <v-select
                  class="filter_app"
                  ref="selectedFilter"
                  v-model="statusFilter"
                  :reduce="(referrer) => referrer.id"
                  label="name"
                  :options="filters"
                  :clearable="false"
                  @input="selectedCategory()"
                  :close-on-select="true"
                  multiple
                  taggable
                  placeholder="Status:"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.name }}
                    <img style="float: right" :src="option.image" />
                  </template>
                </v-select>
              </div>
            </div>

            <div
              v-if="sitesInviteOptions.length > 1"
              class="cell small-2 padright"
            >
              <div class="input-box">
                <v-select
                  class="filter_app"
                  ref="selectedSiteFilter"
                  v-model="siteFilter"
                  :reduce="(referrer) => referrer.id"
                  label="name"
                  :options="sitesInviteOptions"
                  :clearable="false"
                  @input="selectedSiteCategory()"
                  placeholder="Sites:"
                  :close-on-select="true"
                  multiple
                  taggable
                >
                  <template slot="option" slot-scope="option">
                    {{ option.name }}
                    <img style="float: right" :src="option.image" />
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <!-- for displaying tags-->
          <div class="grid-x">
            <div v-if="selected_filter_tags" class="tag-input__tag">
              <span class="lablesize">{{ selected_filter_tags }}</span>
              <span class="deselect-pad"
                ><button class="deselect" @click="removeFilterTags()"></button
              ></span>
            </div>
            <div
              v-if="selected_filter_site_tags"
              class="tag-input__tag sites_tag"
            >
              <span class="lablesize">{{ selected_filter_site_tags }}</span>
              <span class="deselect-pad"
                ><button
                  class="deselect"
                  @click="removeSiteFilterTags()"
                ></button
              ></span>
            </div>
          </div>
        </div>
      </div>

      <!-- USER HEADER-->
      <div class="cf-table cell auto">
        <div
          class="
            grid-x grid-padding-x
            cf-table-head
            red_bg
            t_margin_1
            align-middle
          "
        >
          <div v-if="currentuser.users_check" class="cell medium-1">
            <label class="contain-check">
              <input @click="taskBar()" type="checkbox" v-model="selectAll" />
              <span class="checkmark-check"></span>
            </label>
          </div>
          <div @click="sortby('first_name')" class="cell auto white">
            <p class="tb_margin_1">
              <strong
                >Name
                <i
                  style="float: right"
                  v-bind:class="{
                    'fa fa-sort': sort.name,
                    'fa fa-sort-asc': sort.nameasc,
                    'fa fa-sort-desc': sort.namedesc,
                  }"
                ></i>
              </strong>
            </p>
          </div>
          <div @click="sortby('email')" class="cell auto white">
            <p class="tb_margin_1">
              <strong
                >Email<i
                  style="float: right"
                  v-bind:class="{
                    'fa fa-sort': sort.email,
                    'fa fa-sort-asc': sort.emailasc,
                    'fa fa-sort-desc': sort.emaildesc,
                  }"
                ></i>
              </strong>
            </p>
          </div>
          <div class="cell auto white">
            <p class="tb_margin_1"><strong>Mobile number</strong></p>
          </div>
          <div
            v-if="sitesInviteOptions.length > 1 && currentuser.customer.site_type == 'multi'"
            @click="sortby('site')"
            class="cell auto white"
          >
            <p class="tb_margin_1">
              <strong
                >Site
                <i
                  style="float: right"
                  v-bind:class="{
                    'fa fa-sort': sort.site,
                    'fa fa-sort-asc': sort.siteasc,
                    'fa fa-sort-desc': sort.sitedesc,
                  }"
                ></i>
              </strong>
            </p>
          </div>
          <div class="cell auto white">
            <p class="tb_margin_1"><strong>Status</strong></p>
          </div>
          <div class="cell medium-1 white"></div>
        </div>
        <template v-for="(user, index) in results">
          <!-- LEADERBOARD TABLE - SINGLE ROW (NO MOVE) -->
          <div
            :key="user.id"
            class="grid-x grid-padding-x align-middle b_border_b1"
          >
            <div v-if="currentuser.users_check"  class="cell medium-1">
              <label
                v-if="
                  user.mark_as_leaver == 0 ||
                  (user.unsubscribed == 0 && user.status != 'Leaver')
                "
                class="contain-check"
              >
                <input
                  :value="user.id"
                  v-model="inviteChecked"
                  @click="taskBar()"
                  type="checkbox"
                />
                <span class="checkmark-check"></span>
              </label>
            </div>
            <div class="cell auto">
              <p class="tb_margin_1">
                <strong>{{ user.first_name }} {{ user.surname }}</strong>
              </p>
            </div>
            <div class="cell auto">
              <p
                class="tb_margin_1 overflow-ellipsis"
                v-if="
                  user.first_name == 'Anonymised' ||
                  user.status == 'Leaver' ||
                  user.mark_as_leaver == 1
                "
              ></p>

              <p class="tb_margin_1 overflow-ellipsis" v-else>
                <a :href="'mailto:' + user.email"
                  ><strong>{{ user.email }}</strong></a
                >
              </p>
            </div>
            <div class="cell auto">
              <p class="tb_margin_1">
                <strong>{{
                  user.mobile_number ? user.mobile_number : user.phone_number
                }}</strong>
              </p>
            </div>
            <div v-if="sitesInviteOptions.length > 1 && currentuser.customer.site_type == 'multi'" class="cell auto">
              <p class="tb_margin_1">
                {{ user.site_name ? user.site_name : "" }}
              </p>
            </div>

            <div
              class="cell auto invitee-user-line"
              v-tooltip.top-center="user.title"
            >
              <p v-if="user.reg_via == 7" class="deactivated tb_margin_1">
                <span>Deactivated</span>
              </p>
              <p
                v-else-if="
                  user.reg_via == 9 ||
                  user.status == 'Leaver' ||
                  user.mark_as_leaver == 1
                "
                class="deactivated tb_margin_1"
              >
                <span>Leaver</span>
              </p>
              <p v-else-if="user.reg_via == 6" class="deactivated tb_margin_1">
                <span>Unsubscribed</span>
              </p>
              <p v-else-if="user.reg_via == 1" class="approved tb_margin_1">
                <span>Invited<span v-if="user.portal_user ==1">- Portal User</span></span>
              </p>
              <p v-else-if="user.reg_via == 5" class="green-status tb_margin_1">
                <span v-if="user.portal_user == 0">Registered</span>
                <span v-else>Portal User</span>
              </p>
              <p
                v-else-if="
                  user.reg_via == 2 ||
                  user.reg_via == 3 ||
                  user.reg_via == 4 ||
                  user.reg_via == 8
                "
                class="pending tb_margin_1"
              >
                <span>Authorised<span v-if="user.portal_user ==1">- Portal User</span></span>
              </p>
              <p v-else class="pending tb_margin_1">
                <span>Authorised<span v-if="user.portal_user ==1">- Portal User</span></span>
              </p>
            </div>
            <div
              v-if="
                user.reg_via !== 9 &&
                user.status !== 'Leaver' &&
                user.mark_as_leaver !== 1
              "
              class="cell medium-1 mobile squeeze dropdown-action"
              align="center"
            >
              <button
                class="dropdown button"
                :id="'d-' + index"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="showMenu()"
              ></button>
              <div
                data-alignment="right"
                class="dropdown-menu dropdown-pane pane-width radius-3"
                :aria-labelledby="'d-' + index"
              >
                <ul>
                  <li @click="showPoints = true" v-b-modal="'modal-' + user.id">
                    <a href="javascript:void(0)">View points</a>
                  </li>
                  <template v-if="currentuser.new_admin == 1">
                    <li
                      v-if="
                        user.portal_user == 0 && currentuser.admin_check == 1
                      "
                      @click="showPortalPermision(user);"
                      v-b-modal="'portalpermission-' + user.id"
                    >
                      <a href="javascript:void(0)"> Convert to portal user</a>
                    </li>
                    <li v-if="currentuser.users_check == 1">
                      <a v-on:click="removePoints(user)">Remove points</a>
                    </li>
                    <li
                      v-if="
                        current != user.id &&
                        currentuser.users_check == 1 &&
                        user.mark_as_leaver == 0 &&
                        user.unsubscribed == 1 &&
                        user.status != 'Leaver'
                      "
                    >
                      <a
                        v-on:click="leftOptions(user.id)"
                        data-toggle="modal"
                        data-target="#processLeaver"
                        >Mark as left</a
                      >
                    </li>
                    <li
                      v-if="currentuser.users_check == 1 && currentuser.customer.site_type == 'multi'"
                      @click="
                        showChangeSite = true;
                        changeSite(user);
                      "
                      v-b-modal="'change-site-' + user.id"
                    >
                      <a>Change site</a>
                    </li>
                    <li
                      v-if="current != user.id && currentuser.users_check == 1"
                    >
                      <a
                        href="javascript:void(0)"
                        key=""
                        @click="toggleActive(user)"
                      >
                        {{
                          user.active == 1
                            ? "Deactivate user"
                            : "Reactivate user"
                        }}
                      </a>
                    </li>

                    <li v-if="currentuser.users_check && user.reg_via !== 5 && user.reg_via !== 7 && user.reg_via !== 9">
                      <a
                        @click="fillInvitee(user, index)"
                        data-toggle="modal"
                        data-target="#editInvitee"
                        >Edit details</a
                      >
                    </li>

                    <li
                      v-else-if="currentuser.users_check"
                      @click="tempUser(index)"
                      v-b-modal="'edit-user-modal-' + user.id"
                    >
                      <a>Edit user</a>
                    </li>
                    
                    <li
                      v-if="current != user.id && currentuser.users_check == 1"
                    >
                      <a v-on:click="deluserOptions(user)">Delete user</a>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div
              v-else
              class="cell medium-1 mobile squeeze dropdown-action"
              align="center"
            >
              <button
                v-if="currentuser.users_check == 1"
                class="dropdown button"
                :id="'d-' + index"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="showMenu()"
              ></button>
              <div
                data-alignment="right"
                class="dropdown-menu dropdown-pane pane-width radius-3"
                :aria-labelledby="'d-' + index"
              >
                <ul>
                  <li @click="showPoints = true" v-b-modal="'modal-' + user.id">
                    <a href="javascript:void(0)">View points</a>
                  </li>
                  <li v-if="user.reminder2_sent">
                    <a
                      @click="setInvitee(user.id)"
                      data-toggle="modal"
                      data-target="#unsubscribeCheck"
                      >Unsubscribe</a
                    >
                  </li>
                  <li
                    v-if="
                      user.mark_as_leaver == 0 &&
                      user.unsubscribed == 1 &&
                      user.status != 'Leaver'
                    "
                  >
                    <a
                      v-on:click="leftOptions(user.id)"
                      data-toggle="modal"
                      data-target="#processLeaver"
                      >Mark as left</a
                    >
                    <a
                      @click="fillInvitee(user, index)"
                      data-toggle="modal"
                      data-target="#editInvitee"
                      >Edit details</a
                    >
                  </li>
                  <li>
                    <a
                      @click="setInvitee(user.id)"
                      data-toggle="modal"
                      data-target="#deleteCheck"
                      >Delete</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <b-modal
            :key="'edit-user-modal-' + user.id"
            :id="'edit-user-modal-' + user.id"
            size="xl"
            :no-close-on-backdrop="true"
            centered
            @hide="undoEdit()"
            modal-class="edit-app-user"
          >
            <h3>Edit app user</h3>
            <div class="grid-x input-box-2 invitee-modal-margin">
              <div class="cell auto input-box">
                <label>First name</label>
                <input
                  type="text"
                  placeholder="First name"
                  v-model="temp_user.first_name"
                  @input="flagon"
                />
              </div>
              <div class="cell auto input-box">
                <label>Surname</label>
                <input
                  type="text"
                  placeholder="Surname"
                  v-model="temp_user.surname"
                  @input="flagon"
                />
              </div>
            </div>
            <div class="grid-x input-box-2">
              <div class="cell auto input-box">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  v-model="temp_user.email"
                  @input="flagon"
                />
              </div>
              <div class="cell auto input-box">
                <label>Mobile number</label>
                <input
                  type="text"
                  placeholder="Mobile number"
                  v-model="temp_user.phone_number"
                  @input="flagon"
                />
              </div>
            </div>
            <div class="grid-x input-box-2">
              <div
                v-if="sitesInviteOptions.length > 0 && currentuser.customer.site_type == 'multi'"
                class="cell auto input-box"
              >
                <label>Site</label>
                <v-select
                  placeholder="Site"
                  v-model="temp_user.site_id"
                  :options="
                    temp_user.portal_user == 1
                      ? sitesInviteOptionsPortal
                      : sitesInviteOptions
                  "
                  :reduce="(referrer) => referrer.id"
                  label="name"
                  :clearable="false"
                  @input="flagon"
                ></v-select>
              </div>
              <div class="cell auto input-box">
                <label>{{ taxName }}</label>
                <input
                  type="text"
                  :placeholder="taxName"
                  v-model="temp_user.PaymentInfo"
                  @input="flagon"
                />
              </div>
            </div>
            <div class="cell auto popup-button">
              <input
                style="margin-right: 5px"
                type="button"
                class="button btn_lg btn orange_btn"
                value="Cancel"
                @click="undoEdit()"
              />
              <input
                type="button"
                name="confirm"
                class="next action-button button btn_lg btn pink_btn"
                value="Update"
                @click="updateUser()"
              />
            </div>
          </b-modal>

          <!-- LEADERBOARD TABLE - SINGLE ROW (NO MOVE) -->
          <b-modal
            v-if="showPoints"
            :key="'modal-' + user.id"
            :id="'modal-' + user.id"
            size="xl"
            hide-footer
          >
            <profile-card-component 
              :user="user"
              :isCareSector="isCareSector"
              :taxName="taxName"
            >
            </profile-card-component>
          </b-modal>

          <!--</div>-->
          
          <b-modal
            v-if="portalpermission"
            :key="'portalpermission-' + user.id"
            :id="'portalpermission-' + user.id"
            size="xl"
            ref="portalpermissionModel"
            :no-close-on-backdrop="true"
            centered
          >
            <convert-portalpermission-component
              :user="convert"
              @parentReference="convertToPortal"
              ref="convertPortalPermission"
            ></convert-portalpermission-component>
          </b-modal>

          <b-modal
            v-if="showChangeSite"
            :key="'change-site-' + user.id"
            :id="'change-site-' + user.id"
            :no-close-on-backdrop="true"
          >
            <h3>Change Site</h3>
            <div class="input-box">
              <v-select
                :placeholder="currentSite"
                :options="sitesOptions"
                v-model="currentSite"
              ></v-select>
            </div>
            <div class="cell auto popup-button">
              <input
                @click="updateSitebtn(user.id, index)"
                type="button"
                name="next"
                class="next action-button button btn_lg btn pink_btn"
                value="Update"
              />
            </div>
          </b-modal>
        </template>
        <div
          v-if="currentuser.users_check && inviteChecked.length > 0 && showbottomBar"
          id="invitee-selected"
          class="invitee-users-bar"
        >
          <section class="bar-row">
            <div class="inner-box">
              <strong class="number"></strong>
              <a @click="closeTaskBar()" class="cross"
                ><img src="/images/exit-2.png" alt="img"
              /></a>
              <strong class="title"
                >{{ inviteChecked.length }} Invitee(s) selected</strong
              >
              <ul>
                <li v-if="currentuser.customer.site_type == 'multi'">
                  <a @click="getAllSites"
                    ><img
                      class="bulk-options bulk-site"
                      src="/images/all_sites.png"
                      alt="img"
                    />Change site</a
                  >
                </li>
                <li>
                  <a
                    data-toggle="modal"
                    data-target="#processLeaver"
                    v-on:click="getMarked()"
                    ><img
                      class="bulk-options"
                      src="/images/mark-as-left.png"
                      alt="img"
                    />Mark as left</a
                  >
                </li>
                <li>
                  <a data-toggle="modal" data-target="#inviteCheck"
                    ><img
                      class="bulk-options"
                      src="/images/invite.png"
                      alt="img"
                    />Invite</a
                  >
                </li>
                <li>
                  <a data-toggle="modal" data-target="#unsubscribeCheck"
                    ><img
                      class="bulk-options"
                      src="/images/unsubscribe.png"
                      alt="img"
                    />Unsubscribe</a
                  >
                </li>
                <li>
                  <a data-toggle="modal" data-target="#deleteCheck"
                    ><img
                      class="bulk-options"
                      src="/images/delete.png"
                      alt="img"
                    />Delete</a
                  >
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <template v-if="paginationData.last_page > 1">
        <paginate
          v-model="paginationData.current_page"
          :page-count="paginationData.last_page"
          :page-range="3"
          :margin-pages="2"
          :click-handler="paginatorClickCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :prev-class="'paginate-controls'"
          :next-class="'paginate-controls'"
          :container-class="'pagination-custom'"
          :page-class="'page-item'"
          :active-class="'active'"
        >
        </paginate>
      </template>

      <div
        class="modal fade"
        id="addInvitees"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog invitees_task" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Authorise app user</h3>
              <div class="grid-x input-box-2 invitee-modal-margin">
                <div class="cell auto input-box">
                  <input
                    type="text"
                    placeholder="First Name"
                    v-model="inviteUser.first_name"
                  />
                </div>
                <div class="cell auto input-box">
                  <input
                    type="text"
                    placeholder="Surname"
                    v-model="inviteUser.surname"
                  />
                </div>
              </div>
              <div class="grid-x input-box-2">
                <div class="cell auto input-box">
                  <input
                    type="text"
                    placeholder="Email"
                    v-model="inviteUser.email"
                  />
                </div>
                <div class="cell auto input-box">
                  <input
                    type="text"
                    placeholder="Mobile number"
                    v-model="inviteUser.mobile"
                  />
                </div>
              </div>
              <div
              v-if="currentuser.users_check && currentuser.customer.site_type == 'multi'"
                class="grid-x input-box-2"
              >
                <div class="cell auto input-box">
                  <v-select
                    placeholder="Site"
                    v-model="inviteUser.site"
                    :options="sitesInviteOptions"
                    :reduce="(referrer) => referrer.id"
                    label="name"
                    :clearable="false"
                  ></v-select>
                </div>
                <div class="cell auto input-box">
                  <input type="text"
                         :placeholder="taxName"
                         v-model="inviteUser.PaymentInfo" />
                </div>
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Save"
                  :disabled="btnAddCanidate"
                  @click="saveWithoutSending()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--process Leaver model -->
      <div
        class="modal fade"
        id="processLeaver"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="xl"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <section class="multi-step-row">
                <div class="pop-up-box">
                  <div class="top-check-row">
                    <ul>
                      <li
                        v-bind:class="{
                          completed: done1,
                          selected: activestep1,
                        }"
                      >
                        <a href="javascript:void(0)">1</a>
                      </li>
                      <li
                        v-bind:class="{
                          completed: done2,
                          selected: activestep2,
                        }"
                      >
                        <a href="javascript:void(0)">2</a>
                      </li>
                    </ul>
                  </div>
                  <div class="text-box" id="text-box">
                    <p class="add-a-job">PROCESS LEAVERS</p>
                    <p class="key-center">
                      <span class="key-details" v-if="step == 1"
                        >Outstanding points</span
                      >
                      <span class="key-details" v-if="step == 2"
                        >Are you sure?</span
                      >
                    </p>
                    <fieldset v-if="step == 1">
                      <p>
                        Of the users you have selected to mark as leavers,
                        {{ this.nopoint.length }} have outstanding points
                        balances that have not been requested. By default, we
                        will automatically generate a payment request for these
                        points on behalf of the app user.
                      </p>

                      <p>
                        If there are any users whom you DO NOT wish to receive
                        these points, please indicate that below by unchecking
                        the relevant box.
                      </p>

                      <div class="cf-table cell auto">
                        <div
                          class="
                            grid-x grid-padding-x
                            cf-table-head
                            red_bg
                            t_margin_1
                            align-middle
                          "
                        >
                          <div class="cell auto white">
                            <p class="tb_margin_1"><strong>Name</strong></p>
                          </div>
                          <div class="cell auto white">
                            <p class="tb_margin_1">
                              <strong>Points remaining</strong>
                            </p>
                          </div>
                          <div class="cell auto white">
                            <p class="tb_margin_1">
                              <strong>Create payment request</strong>
                            </p>
                          </div>
                        </div>
                        <template v-for="user in paymentChecked">
                          <div
                            :key="user.id"
                            class="
                              grid-x grid-padding-x
                              align-middle
                              b_border_b1
                            "
                            v-if="
                              typeof user.points !== 'undefined' &&
                              user.points.redeemable_balance > 0 &&
                              user.active == 1
                            "
                          >
                            <div class="cell auto">
                              <p class="tb_margin_1">
                                <strong
                                  >{{ user.first_name }}
                                  {{ user.surname }}</strong
                                >
                              </p>
                            </div>
                            <div class="cell auto">
                              <p
                                class="tb_margin_1 moveright"
                                v-if="user.points.redeemable_balance > 0"
                              >
                                {{ user.points.redeemable_balance }}
                              </p>
                              <p class="tb_margin_1 moveright" v-else>0</p>
                            </div>
                            <div class="cell auto">
                              <label
                                class="contain-check movecenter"
                                v-if="user.leaderboardValues"
                              >
                                <input
                                  :value="user.id"
                                  v-model="nopoint"
                                  type="checkbox"
                                />
                                <span class="checkmark-check"></span>
                              </label>
                              <label class="contain-check movecenter" v-else>
                                <input
                                  :value="user.id"
                                  v-model="nopoint"
                                  type="checkbox"
                                />
                                <span class="checkmark-check"></span>
                              </label>
                            </div>
                          </div>
                        </template>
                      </div>
                    </fieldset>
                    <fieldset v-if="step == 2">
                      <p class="Marking-these-5-app">
                        Marking these {{ this.inviteChecked.length }} app users
                        as ‘leaver’ cannot be undone. This action will:
                      </p>
                      <p class="deactivate-their-a">
                        <ul>
                          <li>
                            Deactivate their account so they can no longer use the
                            app
                          </li>
                          <li>
                            Immediately delete their email and phone number from
                            the system. Their names will remain for record
                            keeping purposes.
                          </li>
                        </ul>
                      </p>
                    </fieldset>
                  </div>
                </div>
              </section>
              <div
                class="cell auto popup-button pad-bottom pad-topp"
                v-if="step == 1"
              >
                <input
                  @click="nextStep()"
                  type="button"
                  name="next"
                  class="next action-button button btn_lg btn pink_btn"
                  value="Next"
                />
              </div>
              <div
                class="cell auto popup-button pad-bottom pad-topp"
                v-if="step == 2"
              >
                <button @click.prevent="goback()" class="back button btn">
                  Back
                </button>
                <input
                  @click="markLeaver()"
                  type="button"
                  class="action-button button btn_lg btn pink_btn"
                  value="Confirm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="inviteCheck"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog invitees_deleted" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Are you sure?</h3>
              <div class="many-invitee-modal-margin">
                <p>
                  This will send an automated sequence of three SMS and/or email
                  invitations to the selected app users. The first SMS and/or
                  email will be sent immediately.
                </p>
                <p>
                  To find out more about our automated invitation sequence,
                  <a
                    href="https://support.carefriends.co.uk/articles/4521850-sending-invitations-to-download-the-app"
                    target="_blank"
                    class="many-invitee"
                    >click here</a
                  >.
                </p>
                <p>
                  To send yourself a test SMS and/or email,
                  <a class="many-invitee" @click="testInvitations()"
                    >click here</a
                  >.<br /><br />
                </p>
              </div>
              <div v-show="showReminderLoad" style="text-align: center">
                <img :src="myImage" />
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn orange_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  value="Cancel"
                />
                <input
                  :disabled="isOpen"
                  @click="sendInvitations()"
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Send Invitations"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="manyInvitee"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog invitees_task" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Invite multiple app users - upload</h3>
              <div class="many-invitee-modal-margin">
                <p>
                  Step 1:
                  <a
                    class="many-invitee"
                    :href="currentURL + '/dashboard/points/download-csv'"
                  >
                    Download</a
                  >
                  template file
                </p>
                <p>
                  Step 2: Copy and paste the details of your invitees into the
                  template, one invite per row. Site names must match the sites
                  your organisation has listed
                </p>
                <p>Step 3: Re-save the file and upload it below</p>
              </div>
              <div class="drag-drop-box leftside-invite">
                <div class="uploader">
                  <img src="/images/group-10.png" />
                  <p>
                    <a @click.prevent="launch">Browse</a> to choose a file.
                    Required file type csv.
                  </p>
                </div>
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn orange_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  value="Cancel"
                />
                <input
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Next"
                  :disabled="btnAddCanidate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="editInvitee"
        tabindex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog invitees_task" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Edit invitee details</h3>
              <div class="grid-x input-box-2 invitee-modal-margin">
                <div class="cell auto input-box">
                  <input
                    type="text"
                    placeholder="First Name"
                    v-model="inviteUser.first_name"
                  />
                </div>
                <div class="cell auto input-box">
                  <input
                    type="text"
                    placeholder="Surname"
                    v-model="inviteUser.surname"
                  />
                </div>
              </div>
              <div class="grid-x input-box-2">
                <div class="cell auto input-box">
                  <input
                    type="text"
                    placeholder="Email"
                    v-model="inviteUser.email"
                  />
                </div>
                <div class="cell auto input-box">
                  <input
                    type="text"
                    placeholder="Mobile number"
                    v-model="inviteUser.mobile"
                  />
                </div>
              </div>
              <div class="grid-x input-box-2"
                v-if="currentuser.customer.site_type == 'multi'">
                <div class="cell auto input-box">
                  <v-select
                    placeholder="Site"
                    v-model="inviteUser.site"
                    :options="sitesInviteOptions"
                    :reduce="(referrer) => referrer.id"
                    label="name"
                    :clearable="false"
                  ></v-select>
                </div>
                <div class="cell auto input-box">
                  <input type="text"
                         :placeholder="taxName"
                         v-model="inviteUser.PaymentInfo" />
                </div>
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Update"
                  :disabled="editbtnDisabled"
                  @click="editInvitee(inviteUser.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="duplicate-user"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog only_csv" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4><br />{{ user_type }} identified as duplicate</h4>
              <div class="cell auto popup-button">
                <input
                  @click="closeDup()"
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Ok"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="inviteCheck"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog invitees_deleted" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Are you sure?</h3>
              <div class="many-invitee-modal-margin">
                <p>
                  This will send an automated sequence of three SMS and/or email
                  invitations to the selected app users. The first SMS and/or
                  email will be sent immediately.
                </p>
                <p>
                  To find out more about our automated invitation sequence,
                  <a
                    href="https://support.carefriends.co.uk/articles/4521850-sending-invitations-to-download-the-app"
                    target="_blank"
                    class="many-invitee"
                    >click here</a
                  >.
                </p>
                <p>
                  To send yourself a test SMS and/or email,
                  <a class="many-invitee" @click="testInvitations()"
                    >click here</a
                  >.<br /><br />
                </p>
              </div>
              <div v-show="showReminderLoad" style="text-align: center">
                <img :src="myImage" />
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn orange_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  value="Cancel"
                />
                <input
                  :disabled="isOpen"
                  @click="sendInvitations()"
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Send Invitations"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="reminderCheck"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog invitees_deleted" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Are you sure?</h3>
              <div class="many-invitee-modal-margin">
                <p>Do you want to send a reminder SMS and email to invitees?</p>
                <p>
                  You can send a test SMS and email to yourself by clicking
                  <a
                    class="many-invitee"
                    :href="currentURL + '/dashboard/points/test-reminders'"
                    >here</a
                  >.<br /><br />
                </p>
              </div>
              <div v-show="showReminderLoad" style="text-align: center">
                <img :src="myImage" />
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn orange_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  value="Cancel"
                />
                <input
                  @click="sendReminders()"
                  :disabled="isOpen"
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Send Reminders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="unsubscribeCheck"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog invitees_deleted" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Are you sure?</h3>
              <div class="many-invitee-modal-margin">
                <p>
                  If you unsubscribe the invitees you will not be able to send
                  them further invitations and reminders.<br /><br />
                </p>
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn orange_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  value="Cancel"
                />
                <input
                  @click="unsubscribeInvitees()"
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Unsubscribe"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="deleteCheck"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog invitees_deleted" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Are you sure?</h3>
              <div class="many-invitee-modal-margin">
                <p>
                  If you delete this invitee they will no longer be able to
                  register for the app and you will not be able to send them
                  further invitations.<br /><br />
                </p>
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn orange_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  value="Cancel"
                />
                <input
                  @click="deleteInvitees()"
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Delete"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        style="overflow-y: auto"
        id="invitees-added"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog invitees_added" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                @click="closeInvitees()"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Invitees added</h3>
              <div class="invitee-modal-margin">
                <p>The details of your uploaded invitees are being processed in the background.</p>
                <p>
                  Once they have been saved, you can send them SMS and email invitations and reminder
                  to download {{ brandName }}.
                </p>
                <p>
                  To send yourself a test email and SMS click
                  <a
                    class="many-invitee"
                    :href="currentURL + '/dashboard/points/test-all'"
                    >here</a
                  >.
                </p>

                <template v-if="duplicates.length > 0">
                  <p>
                    One or more of your new invitees email/phone number was
                    identified as identical to an existing invitee in some other
                    organization and was therefore not added.
                  </p>
                  <div
                    @click="convertToCSV(duplicates)"
                    class="duplicate-invitees dublicate-anchor"
                  >
                    Download duplicates email/phone CSV
                  </div>
                  <template v-for="(duplicate, index) in duplicates">
                    <p :key="index" class="duplicate-invitees" align="center">
                      <strong>Duplicate invitee [{{ duplicate["Name"] }} - {{ duplicate["Email"] }} - {{ duplicate["Phone Number"] }} - {{ duplicate["Site Name"] }}]</strong>
                    </p>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="only-csv"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog only_csv" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4><br />Only CSV file type is allowed</h4>
              <div class="cell auto popup-button">
                <input
                  @click="closeCSV()"
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Ok"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="changeSite"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          class="modal-dialog invitees_deleted"
          role="document"
          style="max-width: 668px !important"
        >
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Change site</h3>
              <div class="many-invitee-modal-margin">
                <p>
                  Please select the new site you wish to allocate the
                  {{ inviteChecked.length }} selected app users to:
                </p>
                <v-select
                  placeholder="Select site"
                  :options="sites_data"
                  :reduce="(referrer) => referrer.id"
                  label="name"
                  v-model="selected_site"
                ></v-select>
                <br />
              </div>
              <div class="cell auto popup-button">
                <input
                  type="button"
                  class="button btn_lg btn orange_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  value="Cancel"
                />
                <input
                  @click="assignSite"
                  type="button"
                  class="button btn_lg btn pink_btn"
                  value="Change site"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Remove Points Modal -->
      <b-modal id="remove-points-modal" size="xl" :no-close-on-backdrop="true">
        <h3>Remove Points</h3>
        <section class="multi-step-row">
          <div class="pop-up-box">
            <div class="top-check-row">
              <ul>
                <li
                  v-bind:class="{
                    selected: isActive(1),
                    completed: done1,
                    selected: step == 1,
                  }"
                >
                  <a @click="step = 1" href="javascript:void(0)">1</a>
                </li>
                <template v-if="selected_point != ''">
                  <li
                    v-bind:class="{
                      selected: isActive(2),
                      completed: done2,
                      selected: step == 2,
                    }"
                  >
                    <a @click="step = 2" href="javascript:void(0)">2</a>
                  </li>
                </template>
                <template v-else>
                  <li v-bind:class="{ selected: false, completed: false }">
                    <a href="javascript:void(0)">2</a>
                  </li>
                </template>
              </ul>
            </div>

            <div class="text-box" id="text-box">
              <fieldset v-if="step == 1">
                <strong class="title"
                  >Step 1: Select the points you wish to remove, from the list
                  of point awarded</strong
                >
                <div class="grid-x grid-padding-x">
                  <div class="cell auto font_0875">
                    <div class="grid-x grid-padding-x align-middle">
                      <table id="activity-table" class="cf-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Reason</th>
                            <th>Points</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="value in viewPointsData"
                            v-bind:key="value.id"
                          >
                            <td align="center">
                              <label class="points-check points-popup-remove">
                                <input
                                  type="radio"
                                  name="selected_point"
                                  :value="value.id"
                                  v-model="selected_point"
                                />
                                <span class="radiomark"></span>
                              </label>
                            </td>
                            <td
                              v-if="
                                value.new_to_care != 1 &&
                                value.candidatesProgress == 1
                              "
                            >
                              {{
                                value.updated_at
                                  ? value.updated_at.split(" ")[0]
                                  : "Not set"
                              }}
                            </td>
                            <td
                              v-if="
                                value.new_to_care != 1 &&
                                value.candidatesProgress == 1
                              "
                            >
                              Referral
                            </td>
                            <td
                              v-if="
                                value.new_to_care != 1 &&
                                value.candidatesProgress == 1
                              "
                            >
                              <div v-if="value.stage == 'applied'">
                                Expression of Interest :
                                {{
                                  value.candidate_name
                                    ? value.candidate_name
                                    : ""
                                }}
                              </div>
                              <div v-else>
                                {{ value.stage | capitalize }} :
                                {{
                                  value.candidate_name
                                    ? value.candidate_name
                                    : ""
                                }}
                              </div>
                            </td>
                            <td
                              class="align-right"
                              v-if="
                                value.new_to_care != 1 &&
                                value.candidatesProgress == 1
                              "
                            >
                              +{{ value.points }}
                            </td>
                            <td
                              v-if="
                                value.new_to_care == 1 &&
                                value.stage == 'applied'
                              "
                            >
                              {{
                                value.updated_at
                                  ? value.updated_at.split(" ")[0]
                                  : "Not set"
                              }}
                            </td>
                            <td
                              v-if="
                                value.new_to_care == 1 &&
                                value.stage == 'applied'
                              "
                            >
                              Referral
                            </td>
                            <td
                              v-if="
                                value.new_to_care == 1 &&
                                value.stage == 'applied'
                              "
                            >
                              <div v-if="value.stage == 'applied'">
                                Expression of Interest :
                                {{
                                  value.candidate_name
                                    ? value.candidate_name
                                    : ""
                                }}
                              </div>
                              <div v-else>
                                {{ value.stage | capitalize }} :
                                {{
                                  value.candidate_name
                                    ? value.candidate_name
                                    : ""
                                }}
                              </div>
                            </td>
                            <td
                              class="align-right"
                              v-if="
                                value.new_to_care == 1 &&
                                value.stage == 'applied'
                              "
                            >
                              +{{ value.points }}
                            </td>
                            <td
                              v-if="
                                value.new_to_care == 1 &&
                                value.stage == 'Started work'
                              "
                            >
                              {{
                                value.updated_at
                                  ? value.updated_at.split(" ")[0]
                                  : "Not set"
                              }}
                            </td>
                            <td
                              v-if="
                                value.new_to_care == 1 &&
                                value.stage == 'Started work'
                              "
                            >
                              Referral
                            </td>
                            <td
                              v-if="
                                value.new_to_care == 1 &&
                                value.stage == 'Started work'
                              "
                            >
                              Started work & New to care :
                              {{
                                value.candidate_name ? value.candidate_name : ""
                              }}
                            </td>
                            <td
                              class="align-right"
                              v-if="
                                value.new_to_care == 1 &&
                                value.stage == 'Started work'
                              "
                            >
                              +{{ value.points }}
                            </td>
                            <td v-if="value.share == 1">
                              {{
                                value.updated_at
                                  ? value.updated_at.split(" ")[0]
                                  : "Not set"
                              }}
                            </td>
                            <td v-if="value.share == 1">Referral</td>
                            <td v-if="value.share == 1">Share</td>
                            <td class="align-right" v-if="value.share == 1">
                              +{{ value.points }}
                            </td>
                            <td v-if="value.bonus == 1">
                              {{
                                value.updated_at
                                  ? value.updated_at.split(" ")[0]
                                  : "Not set"
                              }}
                            </td>
                            <td v-if="value.bonus == 1">Bonus</td>
                            <td v-if="value.bonus == 1">
                              {{ value.reward_catagory_name }} :
                              {{
                                getPostBody(
                                  value.event_data.reason_for_allocation
                                )
                              }}
                              : {{ value.allocated_by }}
                            </td>
                            <td class="align-right" v-if="value.bonus == 1">
                              +{{ value.points }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="cell auto popup-button">
                  <template v-if="selected_point != ''">
                    <input
                      @click="step = 2"
                      type="button"
                      name="next"
                      class="next action-button button btn_lg btn pink_btn"
                      value="Next"
                    />
                  </template>
                  <template v-else>
                    <input
                      @click="step = 2"
                      type="button"
                      name="next"
                      class="next action-button button btn_lg btn pink_btn"
                      value="Next"
                      disabled="true"
                    />
                  </template>
                </div>
              </fieldset>
              <fieldset v-if="step == 2 && selected_point != ''">
                <strong class="title"
                  >Step 2: Select the reason for the points removal from the
                  dropdown</strong
                >
                <div class="grid-x grid-padding-x">
                  <div class="medium-7 font_0875">
                    <v-select
                      placeholder="Select reason"
                      v-model="reason_id"
                      :reduce="(referrer) => referrer.id"
                      label="reason"
                      :options="reasons"
                      class="centered"
                    ></v-select>
                    <input type="hidden" name="reason_id" :value="reason_id" />
                    <br />
                  </div>
                  <div v-if="reason_id == 5" class="medium-7 font_0875">
                    <label
                      ><strong>Reason</strong>
                      <textarea
                        name="otherReason"
                        v-model="otherReason"
                      ></textarea>
                    </label>
                    <br />
                  </div>
                </div>

                <div class="cell auto popup-button">
                  <template
                    v-if="selected_point != '' && reason_id == 5 && otherReason"
                  >
                    <input
                      type="button"
                      name="confirm"
                      class="next action-button button btn_lg btn pink_btn"
                      value="Confirm"
                      @click="confirmRemove(reason_id)"
                    />
                  </template>
                  <template
                    v-else-if="
                      reason_id >= 1 && reason_id <= 4 && selected_point != ''
                    "
                  >
                    <input
                      type="button"
                      name="confirm"
                      class="next action-button button btn_lg btn pink_btn"
                      value="Confirm"
                      @click="confirmRemove(reason_id)"
                    />
                  </template>
                  <template v-else>
                    <input
                      type="button"
                      name="confirm"
                      class="next action-button button btn_lg btn pink_btn"
                      value="Confirm"
                      disabled="true"
                    />
                  </template>
                </div>
              </fieldset>
            </div>
          </div>
        </section>
      </b-modal>
      <!-- Ends remove points Modal-->

      <!-- Delete User Modal -->
      <b-modal
        id="delete-users-modal"
        size="xl"
        :no-close-on-backdrop="true"
        modal-class="delete-appuser"
      >
        <h3>
          Delete App user - {{ deluserdata.first_name }}
          {{ deluserdata.surname }}
        </h3>
        <section class="multi-step-row delete-appuser">
          <div class="pop-up-box">
            <div class="text-box" id="text-box">
              <fieldset>
                <div class="grid-y delete">
                  <div class="medium-7 font_0875">
                    <label
                      >Please select the main reason for deleting this app user
                      <v-select
                        placeholder="Select reason"
                        v-model="delete_reason_id"
                        :reduce="(referrer) => referrer.id"
                        label="reason"
                        :options="delete_reasons"
                        class="centered"
                      ></v-select>
                      <input
                        type="hidden"
                        name="delete_reason_id"
                        :value="reason_id"
                      />
                    </label>
                    <br />
                  </div>
                </div>

                <template v-if="delete_reason_id">
                  <div class="grid-y">
                    <div class="grid-x input-box-2">
                      <div class="cell auto input-box">
                        <template v-if="delete_reason_id == 1">
                          <p>Are you sure?</p>

                          <p>
                            Deleting this app user will delete their contact
                            details, and all of the points they have been
                            awarded and/or paid. This action cannot be undone.
                          </p>
                        </template>
                        <template v-else-if="delete_reason_id == 3">
                          <p>We recommend anonymising users who request this</p>
                          <p>
                            Anonymising a user will delete all of their personal
                            data, but any points that they have earned and/or
                            been paid will still contribute to your account
                            analytics. They will no longer be able to use their
                            {{ brandName }} app. This action can not be undone.<br />Are
                            you sure you wish to anonymise the app user
                            {{ deluserdata.first_name }}
                            {{ deluserdata.surname }} ?
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>

                  <div class="cell auto popup-button">
                    <input
                      type="button"
                      class="button btn_lg btn orange_btn"
                      value="Cancel"
                      @click="deleteunCheck()"
                    />
                    <template v-if="delete_reason_id == 1">
                      <input
                        type="button"
                        name="confirm"
                        class="next action-button button btn_lg btn pink_btn"
                        value="Delete"
                        @click="deleteUser(deluserdata.id)"
                      />
                    </template>
                    <template v-else-if="delete_reason_id == 2">
                      <input
                        type="button"
                        name="confirm"
                        class="next action-button button btn_lg btn pink_btn"
                        value="Mark as left"
                        @click="leftOptions(deluserdata.id)"
                        data-toggle="modal"
                        data-target="#processLeaver"
                      />
                    </template>
                    <template v-else-if="delete_reason_id == 3">
                      <input
                        type="button"
                        name="confirm"
                        class="next action-button button btn_lg btn pink_btn"
                        value="Anonymise"
                        @click="anonymiseUser(deluserdata.id)"
                      />
                    </template>
                    <template v-else>
                      <input
                        type="button"
                        name="confirm"
                        class="next action-button button btn_lg btn pink_btn"
                        value="Confirm"
                        disabled="true"
                      />
                    </template>
                  </div>
                </template>
              </fieldset>
            </div>
          </div>
        </section>
      </b-modal>
      <!-- Ends Delete user Modal-->
      <div
        class="modal fade"
        id="linksModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog invitees_task" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeLinksModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>{{ siteLinks.name }}'s app user invitation link</h3>
              <div class="cell medium-12 links-modal-width">
                <div class="grid-x">
                  <div class="cell medium-10">
                    <p class="p1">
                      <span>{{ siteLinks.url }}</span>
                    </p>
                    <input
                      type="hidden"
                      id="link-text"
                      :value="siteLinks.url"
                    />
                  </div>
                  <div class="cell medium-2 link-copy">
                    <img :src="copyImage" @click="copyLink" />
                  </div>
                </div>
              </div>
              <p class="p2" v-if="currentuser.account_check" @click="showConfirmURLModal">
                Delete and generate new link
              </p>
              <p class="p3">
                You can share this link with new or existing employees on any
                channel, including email, WhatsApp, SMS and social media.
              </p>
              <p class="p3">
                Anyone who clicks on the link will be able to join {{ brandName }}
                as a user in your account, so you shouldn’t share it with anyone
                outside your organisation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="confirmURL"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog invitees_task" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Generate new link?</h3>
              <p class="p3 para-3">
                Generating a new link will cause the previous one to be
                automatically deleted and to stop working. This cannot be
                undone.
              </p>
              <p class="p3">Are you sure you want to generate a new link?</p>
              <br />
              <div class="cell auto cancel-link" style="text-align: right">
                <input
                  type="button"
                  class="button btn_lg btn orange_btn"
                  value="CANCEL"
                  data-dismiss="modal"
                />
                <input
                  type="button"
                  class="btn button pink_btn float-right"
                  value="OK"
                  @click="deleteLink"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="chooseMethod"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog invitees_task" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Add new app users by:</h3>
              <div
                class="cell auto box-links"
                data-dismiss="modal"
                @click.prevent="launch"
              >
                <div class="grid-x">
                  <div class="cell medium-8">
                    <h4>Bulk uploading via a CSV file</h4>
                    <p>
                      Perfect for adding many new users at once. You will need
                      employees mobile no. & email address.
                    </p>
                  </div>
                  <div class="cell medium-4">
                    <button class="link-button-1" type="button">
                      <span class="link-button-text"> BULK UPLOAD</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="currentuser.users_check"
                class="cell auto box-links"
                @click="sitesUrls"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#linksModal"
              >
                <div class="grid-x">
                  <div class="cell medium-8">
                    <h4>Creating and sharing an invite link</h4>
                    <p>
                      Ideal for regularly adding new users effortlessly. No need
                      for you to have the employees' mobile no. & email.
                      Employee must register using the link.
                    </p>
                  </div>
                  <div class="cell medium-4">
                    <button class="link-button-2" type="button">
                      <span class="link-button-text"> INVITE LINK</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="cell auto box-links"
                @click="fillInvitee(null)"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#addInvitees"
              >
                <div class="grid-x">
                  <div class="cell medium-8">
                    <h4>Manually entering one at a time</h4>
                    <p>
                      Convenient for adding one new user at a time. Requires you
                      to have the employees' mobile no. & email. Easiest sign up
                      experience for the employee.
                    </p>
                  </div>
                  <div class="cell medium-4">
                    <button class="link-button-3" type="button">
                      <span class="link-button-text-3"> ONE-BY-ONE</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate";
import { VTooltip } from "v-tooltip";
import FlatfileImporter from "@flatfile/adapter";
import config from "../../config.js";
const importer = new FlatfileImporter(config.license, config.config);
importer.registerRecordHook(async (record) => {
  let out = {};
  out.site_name = await fetch("/dashboard/points/check-site-name", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(record.site_name),
  }).then((response) => response.json());

  if (record.email) {
    out.email = await fetch("/dashboard/points/check-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(record.email),
    }).then((response) => response.json());
  }

  if (record.mobile_number) {
    out.mobile_number = await fetch("/dashboard/points/check-mobile-number", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(record.mobile_number),
    }).then((response) => response.json());
  }
  
  return out;
});

Vue.directive("tooltip", VTooltip);
export default {
  components: { paginate: Paginate },
  props: [
    "result",
    "appUsers",
    "userInvitations",
    "current",
    "allSites",
    "currentuser",
    "search",
    "filter",
    "sortdirc",
    "orderby",
    "totalInvitees",
    "showFilters",
    "sitesFilter",
    "none",
    "isCareSector",
    "taxName"
  ],
  data() {
    return {
      results: this.result ? this.result : [],
      resultIndex: null,
      invites: this.result ? this.result : [],
      editMode: false,
      savedStatus: null,
      sentDetails: false,
      dateReg: [],
      viewPointsData: [],
      balance: [],
      currentSort: "first_name",
      currentSortDir: "asc",
      searchVar: this.search != "0" ? this.search : "",
      showReminderLoad: false,
      isOpen: false,
      showLoad: false,
      myImage: "/images/loading-circle.gif",
      paginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      sitesOptions: [],
      sitesInviteOptions: [],
      currentSite: "",
      type_id: "",
      selected_point: "",
      reason_id: "",
      reasons: [
        { id: 1, reason: "Duplicate candidate" },
        { id: 2, reason: "Bonus points awarded in error" },
        { id: 3, reason: "Ineligible candidate" },
        { id: 4, reason: "Ineligible share" },
        { id: 5, reason: "Other" },
      ],
      delete_reason_id: "",
      deluserdata: [],
      delete_reasons: [
        { id: 1, reason: "This is a test app user" },
        { id: 2, reason: "The user has left our organisation" },
        {
          id: 3,
          reason: "The user asked for their personal data to be deleted",
        },
      ],
      isShow: 0,
      otherReason: "",
      step: 1,
      activestep1: true,
      activestep2: false,
      done1: false,
      done2: false,
      current1: true,
      current2: false,
      inviteUser: {
        id: null,
        first_name: null,
        surname: null,
        site: null,
        email: null,
        mobile: null,
        PaymentInfo: null
      },
      convert: {
        id: null,
        first_name: null,
        surname: null,
        site_id: null,
        email: null,
        phone_number: null,
        points_eligible: 1,
        sites_users: [],
      },
      checkUser: {
        first_name: null,
        surname: null,
        site: null,
        email: null,
        mobile: null,
        PaymentInfo: null
      },
      currentURL: null,
      isDragging: false,
      dragCount: 0,
      fileinput: null,
      statusFilter: this.filter,
      filters: [
        { id: 1, name: "All", image: "/images/idle.png" },
        { id: 2, name: "Leaver", image: "/images/idle.png" },
        { id: 3, name: "Deactivated", image: "/images/idle.png" },
        { id: 4, name: "Registered", image: "/images/idle.png" },
        { id: 5, name: "Invited", image: "/images/idle.png" },
        { id: 6, name: "Authorised", image: "/images/idle.png" },
        { id: 7, name: "Portal User", image: "/images/idle.png" },
      ],
      inviteChecked: [],
      paymentChecked: [],
      nopoint: [],
      oldpoint: [],
      duplicates: [],
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      phoneReg: /^\+?[0-9]\d{1,14}$/,
      leaderboardValues: [],
      pointValues: [],
      site_name: null,
      sort: {
        name: false,
        nameasc: true,
        namedesc: false,
        email: true,
        emailasc: false,
        emaildesc: false,
        site: true,
        siteasc: false,
        sitedesc: false,
      },
      activityType: "",
      activityTypes: [
        { id: 0, name: "All Activity" },
        { id: 1, name: "Referral Activity" },
        { id: 2, name: "Bonus Activity" },
        { id: 3, name: "Paid Activity" },
        { id: 4, name: "Approved Activity" },
        { id: 5, name: "Removal Activity" },
        { id: 6, name: "Request Activity" },
      ],
      filteredPoints: [],
      showChangeSite: false,
      convertUser: false,
      portalpermission: false,
      showbottomBar: true,
      showPoints: false,
      showFilter: this.showFilters,
      selected_filter_tags: "",
      total_invitees: this.totalInvitees ? this.totalInvitees : [],
      showClass: false,
      siteFilter: this.sitesFilter,
      selected_filter_site_tags: "",
      csvResult: [],
      siteLinks: [],
      copyImage: "/images/icon-24-duplicate.png",
      timeoutHandle: null,
      sites_data: [],
      selected_site: "",
      temp_user: {
        id: "",
        first_name: "",
        surname: "",
        email: "",
        phone_number: "",
        site_id: "",
        PaymentInfo: "",
        portal_user: "",
      },
      magic_flag: false,
      handler: null,
      user_type: "User invitation",
      sitesInviteOptionsPortal: [],
    };
  },
  methods: {
    updateUser() {
      var self = this,
        show = true;
      this.user_type = "User invitation";
      axios
        .post("/dashboard/points/edit-user", { user: this.temp_user })
        .then(function (response) {
          if (response.data == "duplicate") {
            self.user_type = "App user";
            $("#duplicate-user").show();
            show = false;
            return;
          }
          if (show) {
            self.undoEdit();
            self.searchAndFilterUsers();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> User details updated',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    undoEdit() {
      this.magic_flag = false;
      this.$bvModal.hide("edit-user-modal-" + this.temp_user.id);
      this.temp_user = {
        id: "",
        first_name: "",
        surname: "",
        email: "",
        phone_number: "",
        site_id: "",
        PaymentInfo: "",
        portal_user: "",
      };
      this.removeHandler();
    },
    tempUser(index) {
      var site_id = this.results[index].site_id;
      if (site_id == null) {
        site_id = -1;
      }
      this.temp_user = {
        id: this.results[index].id,
        first_name: this.results[index].first_name,
        surname: this.results[index].surname,
        email: this.results[index].email,
        phone_number: this.results[index].phone_number,
        site_id: site_id,
        PaymentInfo: this.results[index].PaymentInfo,
        portal_user: this.results[index].portal_user,
      };
    },
    flagon() {
      this.magic_flag = true;
      document.body.classList.add("bg-light");
    },
    removeHandler() {
      if (this.handler)
        window.removeEventListener("beforeunload", this.handler);
      return true;
    },
    assignSite() {
      var self = this;
      self.paymentChecked = [];
      for (var i = 0; i < self.results.length; i++) {
        if ($.inArray(self.results[i].id, self.inviteChecked) !== -1) {
          self.paymentChecked.push(self.results[i]);
        }
      }
      axios
        .post("/dashboard/account/assign-site", {
          site_id: this.selected_site,
          data: self.paymentChecked,
        })
        .then(function (response) {
          $("#changeSite").modal("hide");
          self.searchAndFilterUsers();
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Site changed',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showConfirmURLModal() {
      $("#confirmURL").modal("show");
    },
    closeLinksModal() {
      $("#linksModal").modal("hide");
      $(".modal-backdrop").hide();
    },
    onClickChild() {
      var self = this;
      self.portalpermission = false;
    },
    showPortalPermision(user) {
      var self = this;

      self.convert.id = user.id;
      self.convert.first_name = user.first_name;
      self.convert.surname = user.surname;
      if (user.site_id == null) {
        self.convert.site_id = null;
      } else {
        self.convert.site_id = user.site_id;
      }
      self.convert.email = user.email;
      self.convert.phone_number = user.phone_number;
      self.convert.points_eligible = 1;
      self.convert.sites_users = user.sites_users;

      self.portalpermission = true;
    },
    convertToPortal(user) {
      
      var self = this;
      
      Vue.swal({
        title: "Converting...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      axios
        .post("/dashboard/account/convert-app-to-portal-user", user)
        .then(function (response) {
          self.convertUser = false;
          Vue.swal.close();
          var dd = "";
          dd = "portalpermission-" + user.id;
          self.dd = true;
          
          location.reload();
        });
    },
    launch() {
      const $v = this;
      importer.setCustomer({ userId: 1 });
      importer
        .requestDataFromUser()
        .then(function (results) {
          if (results) {
            $v.csvResult = results.validData;
            if ($v.csvResult) {
              $v.uploadCSV();
            }
            importer.displaySuccess(
              "Your file has been uploaded. Any newly authorized\n" +
                "app users will now be able to login to the app, and\n" +
                "you can send them direct SMS and email invitation\n" +
                "if you want to."
            );
          }
        })
        .catch(function (error) {
          console.info(error || "window close");
        });
    },
    sitesUrls() {
      var self = this;
      axios
        .get("/dashboard/points/sites-urls")
        .then(function (response) {
          if (response.data) {
            self.siteLinks = response.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    copyLink() {
      let codeToCopy = document.querySelector("#link-text");
      codeToCopy.setAttribute("type", "text");
      codeToCopy.select();
      document.execCommand("copy");
      codeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      clearTimeout(this.timeoutHandle);
      this.copyImage = "/images/icon-24-duplicate-pink.png";
      Vue.swal({
        html: "Link copied to clipboard",
        showConfirmButton: false,
        background: "#51bf73",
        customClass: {
          container: "candidate-toast",
        },
        showClass: {
          popup: "animated fadeInLeft fast toast-link",
          icon: "animated heartBeat delay-1s",
        },
        hideClass: {
          popup: "animated fadeOutLeft slow",
        },
        toast: true,
        timer: 3000,
        position: "top-right",
        target: "#example-tabs",
      });
    },
    deleteLink() {
      var self = this;
      axios
        .post("/dashboard/points/delete-link")
        .then(function (response) {
          self.sitesUrls();
          $("#confirmURL").modal("hide");
          clearTimeout(self.timeoutHandle);
          self.copyImage = "/images/icon-24-duplicate-pink.png";
          Vue.swal({
            html: "New link generated",
            showConfirmButton: false,
            background: "#51bf73",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast toast-link",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 3000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    filtertoggle() {
      if (this.showFilter == true) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
    },
    removeFilterTags: function () {
      if (this.statusFilter.length > 1) {
        let length = this.statusFilter.length;
        this.statusFilter.splice(length - 1, 1);
      } else {
        this.$refs.selectedFilter.clearSelection();
      }

      this.selected_filter_tags = "";
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].image == "/images/selected.png") {
          this.filters[i].image = "/images/idle.png";
        }
      }
      this.selectedCategory();
      this.resetUrl();
    },
    removeSiteFilterTags: function () {
      if (this.siteFilter.length > 1) {
        let length = this.siteFilter.length;
        this.siteFilter.splice(length - 1, 1);
      } else {
        this.$refs.selectedSiteFilter.clearSelection();
      }
      this.selected_filter_site_tags = "";
      for (var i = 0; i < this.sitesInviteOptions.length; i++) {
        if (this.sitesInviteOptions[i].image == "/images/selected.png") {
          this.sitesInviteOptions[i].image = "/images/idle.png";
        }
      }
      this.selectedSiteCategory();
      this.resetUrl();
    },
    selectedCategory: function (flag = null) {
      /**
       * Code to managed selected tags for Filters
       */
      if (flag) {
        if (this.statusFilter[0] == 1) {
          this.statusFilter.splice(0, 1);
        }
      }
      if (this.statusFilter.length > 1) {
        if (this.statusFilter[0] == 1) {
          this.statusFilter.splice(0, 1);
        } else {
          for (var i = 0; i < this.statusFilter.length; i++) {
            if (i == 0) {
              continue;
            }
            if (this.statusFilter[i] == 1) {
              this.statusFilter = [];
              this.statusFilter[0] = 1;
            }
          }
        }
      }
      let selected_filter = [];
      for (var i = 0; i < this.filters.length; i++) {
        if ($.inArray(this.filters[i].id, this.statusFilter) !== -1) {
          this.filters[i].image = "/images/selected.png";
          selected_filter.push(this.filters[i].name);
        } else {
          this.filters[i].image = "/images/idle.png";
        }
      }
      if (selected_filter.length > 0) {
        this.selected_filter_tags = "Status: " + selected_filter.join(", ");
      }

      if (!flag) {
        this.searchAndFilterUsers();
      }
    },
    selectedSiteCategory: function (flag = null) {
      if (flag) {
        if (this.siteFilter[0] == -1) {
          this.siteFilter.splice(0, 1);
        } else if (this.siteFilter[0] == 0) {
          this.siteFilter.splice(0, 1);
        }
      }
      if (this.siteFilter.length > 1) {
        if (this.siteFilter[0] == -1) {
          this.siteFilter.splice(0, 1);
        } else if (this.siteFilter[0] == 0) {
          this.siteFilter.splice(0, 1);
        } else {
          for (var i = 0; i < this.siteFilter.length; i++) {
            if (i == 0) {
              continue;
            }
            if (this.siteFilter[i] == -1) {
              this.siteFilter = [];
              this.siteFilter[0] = -1;
            }

            if (this.siteFilter[i] == 0) {
              this.siteFilter = [];
              this.siteFilter[0] = 0;
            }
          }
        }
      }
      let selected_filter = [];
      for (var i = 0; i < this.sitesInviteOptions.length; i++) {
        if ($.inArray(this.sitesInviteOptions[i].id, this.siteFilter) !== -1) {
          this.sitesInviteOptions[i].image = "/images/selected.png";
          selected_filter.push(this.sitesInviteOptions[i].name);
        } else {
          this.sitesInviteOptions[i].image = "/images/idle.png";
        }
      }
      if (selected_filter.length > 0) {
        this.selected_filter_site_tags = "Site: " + selected_filter.join(", ");
      }

      /**
       * Ends Code to managed selected tags for Category
       */
      if (!flag) {
        this.searchAndFilterUsers();
      }
    },
    editInvitee(id) {
      // let temp = [...this.result];
      //
      //
      // temp[this.resultIndex].first_name = this.inviteUser.first_name;
      // temp[this.resultIndex].surname = this.inviteUser.surname;
      // temp[this.resultIndex].email = this.inviteUser.email;
      // temp[this.resultIndex].mobile_number = this.inviteUser.mobile;
      // temp[this.resultIndex].site_id = this.inviteUser.site;
      // temp[this.resultIndex].site_name = siteName;
      // this.result = temp;

      let tempResults = [...this.results];

      let siteName = "";
      for (var i = 0; i < this.sitesInviteOptions.length; i++) {
        if (this.sitesInviteOptions[i].id === this.inviteUser.site) {
          siteName = this.sitesInviteOptions[i].name;
        }
      }

      tempResults[this.resultIndex].first_name = this.inviteUser.first_name;
      tempResults[this.resultIndex].surname = this.inviteUser.surname;
      tempResults[this.resultIndex].email = this.inviteUser.email;
      tempResults[this.resultIndex].mobile_number = this.inviteUser.mobile;
      tempResults[this.resultIndex].site_id = this.inviteUser.site;
      tempResults[this.resultIndex].site_name = siteName;
      this.results = tempResults;

      var self = this;
      var show = true;
      axios
        .post("/dashboard/points/edit-invitee", {
          id: id,
          inviteUser: this.inviteUser,
        })
        .then(function (response) {
          if (response.data == "duplicate") {
            Vue.swal.close();
            $("#duplicate-user").show();
            show = false;
            return;
          }
          $("#editInvitee").hide();
          $(".modal-backdrop.show").hide();
          self.searchAndFilterUsers();
          if (show) {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invitee updated',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
          $("body").removeClass("modal-open");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    fillInvitee(user, index) {
      if (user) {
        this.resultIndex = index;
        this.inviteUser.id = this.checkUser.id = user.id;
        this.inviteUser.first_name = this.checkUser.first_name =
          user.first_name;
        this.inviteUser.surname = this.checkUser.surname = user.surname;
        this.inviteUser.email = this.checkUser.email = user.email;
        this.inviteUser.mobile = this.checkUser.mobile = user.mobile_number;
        this.inviteUser.site = this.checkUser.site = user.site_id;
        this.inviteUser.PaymentInfo = this.checkUser.PaymentInfo = user.payRoll;
      } else {
        this.inviteUser.id = "";
        this.inviteUser.first_name = "";
        this.inviteUser.surname = "";
        this.inviteUser.email = "";
        this.inviteUser.mobile = "";
        this.inviteUser.site = "";
        this.inviteUser.PaymentInfo = "";
      }
    },
    setInvitee(id) {
      if (id) {
        this.inviteChecked[0] = id;
      }
    },
    sortby(orderby) {
      this.togglesort(orderby);
      this.currentSort = orderby;
      this.searchAndFilterUsers();
      this.updatesorticons();
    },
    togglesort(orderby) {
      if (this.currentSort == orderby) {
        if (this.currentSortDir == "asc") {
          this.currentSortDir = "desc";
        } else {
          this.currentSortDir = "asc";
        }
      } else {
        this.currentSortDir = "asc";
      }
    },
    updatesorticons() {
      this.sort.name = true;
      this.sort.nameasc = false;
      this.sort.namedesc = false;
      this.sort.email = true;
      this.sort.emailasc = false;
      this.sort.emaildesc = false;
      this.sort.site = true;
      this.sort.siteasc = false;
      this.sort.sitedesc = false;

      if (this.currentSort == "first_name") {
        if (this.currentSortDir == "asc") {
          this.sort.nameasc = true;
          this.sort.name = false;
        } else {
          this.sort.namedesc = true;
          this.sort.name = false;
        }
      } else if (this.currentSort == "email") {
        if (this.currentSortDir == "asc") {
          this.sort.emailasc = true;
          this.sort.email = false;
        } else {
          this.sort.emaildesc = true;
          this.sort.email = false;
        }
      } else if (this.currentSort == "site_name") {
        if (this.currentSortDir == "asc") {
          this.sort.siteasc = true;
          this.sort.site = false;
        } else {
          this.sort.sitedesc = true;
          this.sort.site = false;
        }
      } else {
        this.sort.nameasc = true;
        this.sort.name = false;
      }
    },
    searchUsers() {
      if (this.searchVar.length > 2) {
        this.searchAndFilterUsers();
      } else {
        this.resetUrl();
      }
    },
    saveWithoutSending() {
      var self = this;
      var show = true;
      axios
        .post("/dashboard/points/save-without-sending", {
          inviteUser: this.inviteUser,
        })
        .then(function (response) {
          if (response.data == "duplicate") {
            Vue.swal.close();
            $("#duplicate-user").show();
            show = false;
            return;
          }
          self.searchAndFilterUsers();

          if (show) {
            //self.showAddInvitees = false;
            $("#addInvitees").hide();
            $(".modal-backdrop.show").hide();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invitee added',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
          $("body").removeClass("modal-open");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveWithSending() {
      var self = this;
      var show = true;
      axios
        .post("/dashboard/points/save-with-sending", {
          inviteUser: this.inviteUser,
        })
        .then(function (response) {
          if (response.data == "duplicate") {
            Vue.swal.close();
            $("#duplicate-user").show();
            show = false;
            $("body").removeClass("modal-open");
            return;
          }
          //self.showAddInvitees = false;
          $("#addInvitees").hide();
          $(".modal-backdrop.show").hide();
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invite sent',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });

          $("body").removeClass("modal-open");

          self.searchAndFilterUsers();
        })
        .catch(function (error) {
          console.log(error);
          $("body").removeClass("modal-open");
        });
    },
    getInvitees() {
      this.showLoad = true;
      var self = this;
      axios
        .post("/dashboard/account/search-app")
        .then(function (response) {
          if (response.data.result) {
            self.results = response.data.result;
            $(self.$el).foundation();
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get("page");
            self.inviteChecked = [];
            if (response.data.userInvitations.length > 0) {
              self.invites = response.data.userInvitations;
            }
          }
          self.showLoad = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    taskBar() {
      this.total_invitees = this.totalInvitees;
      $("#invitee-selected").show();
      this.showbottomBar = true;
    },
    closeTaskBar() {
      this.total_invitees = this.totalInvitees;
      $("#invitee-selected").hide();
      this.inviteChecked = [];
    },
    sendInvitations() {
      var self = this;
      self.isOpen = true;
      self.showReminderLoad = true;

      const invitees = [];

      this.inviteChecked.forEach(id => {
        const user = this.results.filter(user => user.id === id)[0];
        const isAuthorised = [2, 3, 4, 5, 8].includes(parseInt(user.reg_via));

        invitees.push({ id, isAuthorised });
      })

      axios
        .post("/dashboard/points/send-invitations", { invitees })
        .then(function (response) {
          self.showLoad = false;
          $("#inviteCheck").hide();
          $(".modal-backdrop.show").hide();
          if (response.data == 0) {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-times-circle"></i></span> Invitations not sent',
              confirmButtonColor: "#f55d73",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#f55d73",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          } else {
            self.searchAndFilterUsers();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invitations sent',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
            axios
              .post("/dashboard/points/send-invitations-emails", {
                invitees: this.inviteChecked.length,
              })
              .then(function (response) {});
          }
          self.isOpen = false;
          self.showReminderLoad = false;
        })
        .catch(function (error) {
          self.isOpen = false;
          self.showReminderLoad = false;
          console.log(error);
        });
      $("body").removeClass("modal-open");
    },
    sendReminders() {
      var self = this;
      self.isOpen = true;
      self.showReminderLoad = true;
      axios
        .post("/dashboard/points/send-reminders", {
          invitees: this.inviteChecked,
        })
        .then(function (response) {
          if (response.data == 1) {
            self.searchAndFilterUsers();
            $("#reminderCheck").hide();
            $(".modal-backdrop.show").hide();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Reminders sent',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          } else {
            $("#reminderCheck").hide();
            $(".modal-backdrop.show").hide();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-times-circle"></i></span> Reminders not sent',
              confirmButtonColor: "#f55d73",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#f55d73",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
          self.isOpen = false;
          self.showReminderLoad = false;
        })
        .catch(function (error) {
          console.log(error);
        });
      $("body").removeClass("modal-open");
    },
    unsubscribeInvitees() {
      var self = this;
      axios
        .post("/dashboard/points/unsubscribe-invitees", {
          invitees: this.inviteChecked,
        })
        .then(function (response) {
          if (response.data == 1) {
            self.searchAndFilterUsers();
            $("#unsubscribeCheck").hide();
            $(".modal-backdrop.show").hide();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invitee(s) unsubscribed',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      $("body").removeClass("modal-open");
    },
    deleteInvitees() {
      var self = this;
      axios
        .post("/dashboard/points/delete-invitees", {
          invitees: this.inviteChecked,
        })
        .then(function (response) {
          if (response.data == 1) {
            self.searchAndFilterUsers();
            $("#deleteCheck").hide();
            $(".modal-backdrop.show").hide();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invitee(s) deleted',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      $("body").removeClass("modal-open");
    },
    closeCSV() {
      $("#only-csv").hide();
    },
    closeDup() {
      $("#duplicate-user").hide();
    },
    OnDragEnter(e) {
      e.preventDefault();
      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    onInputChange(e) {
      const files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    uploadCSV() {
      Vue.swal({
        title: "Loading...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      var vm = this;
      axios
        .post("/dashboard/points/parse-csv", { fileinput: this.csvResult })
        .then((response) => {
          vm.duplicates = [];
          if (response.data.duplicate.length > 0) {
            vm.duplicates = response.data.duplicate;
          }
          vm.getInvitees();
          Vue.swal.close();
          $("#manyInvitee").hide();
          $("#invitees-added").show();
        });
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "First name,Surname,Email,Mobile number\n";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }
      //console.log(str);
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(str);
      anchor.target = "_blank";
      anchor.download = "dublicates.csv";
      anchor.click();
    },
    closeInvitees() {
      $("#invitees-added").hide();
      $(".modal-backdrop.show").hide();
      location.reload();
    },
    showMenu() {
      $(this.$el).foundation();
    },
    filterViewPoints() {
      this.filteredPoints = [];
      for (var i = 0; i < this.viewPointsData.length; i++) {
        if (this.activityType == 2) {
          if (this.viewPointsData[i].hasOwnProperty("event_data")) {
            this.filteredPoints.push(this.viewPointsData[i]);
          }
        } else if (this.activityType == 1) {
          // if(!this.viewPointsData[i].hasOwnProperty('event_data')){
          if (
            this.viewPointsData[i].type == "Referral" ||
            this.viewPointsData[i].hasOwnProperty("reffered_by") ||
            this.viewPointsData[i].hasOwnProperty("share")
          ) {
            this.filteredPoints.push(this.viewPointsData[i]);
          }

          // }
        } else if (this.activityType == 3) {
          if (this.viewPointsData[i].hasOwnProperty("paidPaymentRequest")) {
            if (this.viewPointsData[i].paidPaymentRequest == 1) {
              this.filteredPoints.push(this.viewPointsData[i]);
            }
          }
        } else if (this.activityType == 4) {
          if (
            this.viewPointsData[i].hasOwnProperty("approvedByPaymentRequest")
          ) {
            if (this.viewPointsData[i].approvedByPaymentRequest == 1) {
              this.filteredPoints.push(this.viewPointsData[i]);
            }
          }
        } else if (this.activityType == 5) {
          if (this.viewPointsData[i].hasOwnProperty("removal")) {
            if (this.viewPointsData[i].removal == 1) {
              if (this.viewPointsData[i].type == "Removal") {
                this.filteredPoints.push(this.viewPointsData[i]);
              }
            }
          }
        } else if (this.activityType == 6) {
          if (this.viewPointsData[i].hasOwnProperty("paymentRequest")) {
            this.filteredPoints.push(this.viewPointsData[i]);
          }
        } else {
          this.filteredPoints = [];
        }
      }
      this.viewPointsData = this.filteredPoints;
    },
    viewPoints(user) {
      var self = this;
      axios
        .get("/view-points/" + user.id + "/registered")
        .then(function (response) {
          // handle success
          if (response.data.activityLog) {
            //console.log(response.data.activityLog);
            self.viewPointsData = response.data.activityLog;
            var i;
            for (i = self.viewPointsData.length - 1; i >= 0; i--) {
              if (i == self.viewPointsData.length - 1) {
                self.balance[i] = self.viewPointsData[i].points;
              } else {
                if (self.balance[i + 1] == null) {
                  self.balance[i + 1] = 0;
                }
                if (self.viewPointsData[i].points == null) {
                  self.viewPointsData[i].points = 0;
                }
                if (self.viewPointsData[i].paidPaymentRequest == 1) {
                  self.balance[i] =
                    self.balance[i + 1] - self.viewPointsData[i].points;
                } else if (self.viewPointsData[i].type == "Removal") {
                  self.balance[i] =
                    self.balance[i + 1] - self.viewPointsData[i].points;
                } else {
                  self.balance[i] =
                    self.balance[i + 1] + self.viewPointsData[i].points;
                }
              }
            }
            if (self.activityType > 0) {
              self.filterViewPoints();
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      self.editMode = true;
      if (user.created_at) {
        self.dateReg = user.created_at.split(" ");
      }
    },
    testInvitations() {
      var self = this;
      Vue.swal({
        title: "Sending...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      axios
        .get("/dashboard/points/test-invitations/")
        .then(function (response) {
          // handle success
          Vue.swal.close();
          if (response.status == 200) {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Test invites sent',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              border: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
        });
    },
    toggleActive(user) {
      if (!user.active) {
        user.active = true;
      } else {
        user.active = false;
      }
      axios
        .post("/dashboard/account/toggle-active", user)
        .then(function (response) {
          // handle success
          location.reload();
          //console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    deleteunCheck() {
      var self = this;
      self.$bvModal.hide("delete-users-modal");
    },
    deleteUser(userId) {
      var self = this;
      if (userId) {
        Vue.swal({
          title: "Updating...",
          html: "",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Vue.swal.showLoading();
          },
        });
        axios
          .post("/dashboard/account/user/delete/" + userId, {
            userId: userId,
          })
          .then(function (response) {
            // handle success
            if (response.data.success) {
              self.$bvModal.hide("delete-users-modal");
            }
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Deleted!',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });

            window.location = "/dashboard/app-users#app-users";
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      }
    },
    deactivateUser(userId) {
      var self = this;
      if (userId) {
        Vue.swal({
          title: "Updating...",
          html: "",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Vue.swal.showLoading();
          },
        });
        axios
          .post("/dashboard/account/user/deactivate/" + userId, {
            userId: userId,
          })
          .then(function (response) {
            // handle success
            if (response.data.success) {
              self.$bvModal.hide("delete-users-modal");
            }
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Deactivated!',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });

            location.reload();
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      }
    },
    anonymiseUser(userId) {
      var self = this;
      if (userId) {
        Vue.swal({
          title: "Updating...",
          html: "",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Vue.swal.showLoading();
          },
        });
        axios
          .post("/dashboard/account/user/anonymise/" + userId, {
            userId: userId,
          })
          .then(function (response) {
            // handle success

            if (response.data.success) {
              self.$bvModal.hide("delete-users-modal");
            }
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Anonymised!',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
            window.location = "/dashboard/app-users#app-users";
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      }
    },
    removePoints(user) {
      this.step = 1;
      this.viewPointsData = [];
      this.current1 = true;
      this.current2 = false;
      this.done1 = false;
      this.done2 = false;
      this.selected_point = "";
      this.reason_id = "";
      this.getLog(user.id);
      this.$bvModal.show("remove-points-modal");
    },
    deluserOptions(user) {
      var self = this;
      self.deluserdata = user;
      self.delete_reason_id = "";
      self.$bvModal.show("delete-users-modal");
    },
    changeSite(user) {
      this.sitesOptions = [];
      var currentSite = "";
      if (!user.site_name) {
        currentSite = "Not Set";
      } else {
        this.currentSite = user.site_name;
      }
      var i;
      var sites = {};
      if (this.allSites) {
        for (i = 0; i < this.allSites.length; i++) {
          this.sitesOptions.push(this.allSites[i].name);
          sites[this.allSites[i].id] = this.allSites[i].name;
        }
      } else {
        sites[0] =
          "There are no sites currently listed for your organisation. You can add sites in your account settings";
      }
      var userId = user.id;
    },
    getAllSites() {
      $("#changeSite").modal("show");
      this.selected_site = "";
      if (this.allSites.length > 0) {
        this.sites_data = [];
        var site = {
          id: null,
          name: "All Sites",
        };
        this.sites_data.push(site);
        for (var i = 0; i < this.allSites.length; i++) {
          site = {
            id: this.allSites[i].id,
            name: this.allSites[i].name,
          };
          this.sites_data.push(site);
        }
      }
    },
    updateSitebtn($id, $index) {
      let temp = [...this.results];
      temp[$index].site_name = this.currentSite;
      this.results = temp;

      if (this.currentSite) {
        var self = this;

        if (this.currentSite == "All Sites") {
          var sId = 0;
        } else {
          for (i = 0; i < this.allSites.length; i++) {
            if (this.allSites[i].name == this.currentSite) {
              var sId = this.allSites[i].id;
            }
          }
        }
        axios
          .post("/dashboard/account/site/update/" + $id, { siteId: sId })
          .then(function (response) {
            self.showPoints = false;
            self.showChangeSite = false;
            // self.searchAndFilterUsers();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> The site has been updated',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          });
      }
    },
    searchAndFilterUsers: function () {
      // if(this.searchVar || this.statusFilter) {
      this.showLoad = true;
      var self = this;
      this.inviteChecked = [];
      this.selectAll = false;

      axios
        .post("/dashboard/account/search-app", {
          search: this.searchVar,
          filter: this.statusFilter,
          orderby: this.currentSort,
          dirc: this.currentSortDir,
          sitesFilter: this.siteFilter,
        })
        .then(function (response) {
          self.showLoad = false;
          self.results = [];

          self.paginationData.current_page = 0;
          self.paginationData.from = 0;
          self.paginationData.last_page = 0;
          self.paginationData.per_page = 0;
          self.paginationData.to = 0;
          self.paginationData.total = 0;
          self.invites = [];
          self.total_invitees = response.data.totalInvitees;
          self.totalInvitees = response.data.totalInvitees;
          if (response.data.result) {
            // self.currentSort = response.data.orderby;
            // self.currentSortDirc = response.data.sort;
            self.results = response.data.result;
            $(self.$el).foundation();
            // console.log(response);

            self.invites = response.data.userInvitations;
            self.invites = response.data.result;
            if (response.data.appUsers) {
              self.paginationData.current_page =
                response.data.appUsers.currentPage;
              self.paginationData.from = response.data.appUsers.from;
              self.paginationData.last_page = response.data.appUsers.last_page;
              self.paginationData.per_page = response.data.appUsers.per_page;
              self.paginationData.to = response.data.appUsers.to;
              self.paginationData.total = response.data.appUsers.total;
            } else if (response.data.userInvitations) {
              self.invites = response.data.result;
              self.paginationData.current_page =
                response.data.userInvitations.currentPage;
              self.paginationData.from = response.data.userInvitations.from;
              self.paginationData.last_page =
                response.data.userInvitations.last_page;
              self.paginationData.per_page =
                response.data.userInvitations.per_page;
              self.paginationData.to = response.data.userInvitations.to;
              self.paginationData.total = response.data.userInvitations.total;
            }
          }
          if (self.results.length == 0) {
            self.total_invitees = self.totalInvitees = 0;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // }
    },
    // searchAndFilterUsersData: function () {
    //     if(this.searchVar.length <= 2) {
    //         this.results = this.result;
    //         $(this.$el).foundation();
    //         const urlParams = new URLSearchParams(window.location.search);
    //         const page = urlParams.get('page');
    //         if (page) {
    //             this.paginationData.current_page = parseInt(page);
    //         } else {
    //             this.paginationData.current_page = 1;
    //         }
    //         this.paginationData.from = this.paginationData.current_page - 1;
    //         if (this.appUsers.total >= this.invites.total) {
    //             var max = this.appUsers.total;
    //             var min = this.invites.total;
    //         } else {
    //             var max = this.invites.total;
    //             var min = this.appUsers.total;
    //         }
    //         var value = max - min;
    //         this.paginationData.last_page = Math.ceil((value + min) / 10);
    //         this.paginationData.per_page = 20;
    //         this.paginationData.to = this.appUsers.to + this.invites.to;
    //         this.paginationData.total = this.appUsers.total + this.invites.total;
    //     } else {
    //         this.searchAndFilterUsers();
    //     }
    // },
    paginatorClickCallback: function (pageNum) {
      if (
        this.statusFilter.length > 0 &&
        this.siteFilter.length > 0 &&
        this.searchVar &&
        this.searchVar.length > 0
      ) {
        window.location =
          window.location.origin +
          "/dashboard/app-users?page=" +
          pageNum +
          "&filter=" +
          this.statusFilter +
          "&sitesFilter=" +
          this.siteFilter +
          "&search=" +
          this.searchVar +
          "&dirc=" +
          this.currentSortDir +
          "&orderby=" +
          this.currentSort +
          "#app-users";
        return false;
      } else if (this.searchVar && this.searchVar.length > 0) {
        window.location =
          window.location.origin +
          "/dashboard/app-users?page=" +
          pageNum +
          "&search=" +
          this.searchVar +
          "&dirc=" +
          this.currentSortDir +
          "&orderby=" +
          this.currentSort +
          "#app-users";
        return false;
      } else if (this.statusFilter.length > 0 || this.siteFilter.length > 0) {
        window.location =
          window.location.origin +
          "/dashboard/app-users?page=" +
          pageNum +
          "&filter=" +
          this.statusFilter +
          "&sitesFilter=" +
          this.siteFilter +
          "&dirc=" +
          this.currentSortDir +
          "&orderby=" +
          this.currentSort +
          "#app-users";
        return false;
      } else {
        window.location =
          window.location.origin +
          "/dashboard/app-users?page=" +
          pageNum +
          "&dirc=" +
          this.currentSortDir +
          "&orderby=" +
          this.currentSort +
          "#app-users";
        return false;
      }
    },
    exportcsv: function () {
      axios
        .get(
          "/dashboard/account/search-app-export?search=" +
            this.searchVar +
            "&export=1"
        )
        .then((response) => {
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Export Completed',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        });
    },
    isActive(id) {
      if (this.selected_point != "") {
        this.done1 = true;
      }
      if (this.reason_id > 0 && this.reason_id < 5) {
        this.done2 = true;
      } else {
        if (this.otherReason.length > 0) {
          this.done2 = true;
        } else this.done2 = false;
      }

      if (this.step == 1) {
        this.current2 = false;
        this.current1 = true;
      }
      if (this.step == 2) {
        this.current1 = false;
        this.current2 = true;
      }
    },

    getLog(id) {
      var self = this;
      axios
        .get("/dashboard/points/get-activity-log/" + id)
        .then(function (response) {
          // handle success
          self.viewPointsData = response.data.activityLog;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    confirmRemove(reason) {
      if (reason == 5) {
        reason = this.otherReason;
      }
      let typeId = this.selected_point;

      this.$swal({
        title: "Are you sure?",
        text:
          "This will cause the app user to receive a notification that the points have been removed and " +
          "the points will be removed from their total. This action can not be undone.",
        showCancelButton: true,
        confirmButtonColor: "#f55d73",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        reverseButtons: true,
        customClass: {
          container: "swal-before-leaving remove-points",
          confirmButton: "button pink_btn b_margin_0 no_wrap_btn",
          cancelButton: "button orange_btn b_margin_0 no_wrap_btn",
        },
      }).then((result) => {
        if (result.value) {
          if (typeId) {
            var data = null;
            var self = this;
            axios
              .post("/dashboard/points/delete-points/" + typeId, {
                reason: reason,
              })
              .then(function (response) {
                // handle success
                self.type_id = null;
                if (response.data.id) {
                  data = response.data.id;
                  self.getLog(data);
                  self.isShow = 0;
                  self.$bvModal.hide("remove-points-modal");
                }
                Vue.swal({
                  html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Points removed',
                  confirmButtonColor: "#5dc2a6",
                  confirmButtonText:
                    '<i class="fa fa-times" aria-hidden="true"></i>',
                  background: "#5dc2a6",
                  customClass: {
                    container: "candidate-toast",
                  },
                  showClass: {
                    popup: "animated fadeInLeft fast",
                    icon: "animated heartBeat delay-1s",
                  },
                  hideClass: {
                    popup: "animated fadeOutLeft slow",
                  },
                  toast: true,
                  timer: 5000,
                  position: "top-right",
                  target: "#example-tabs",
                });
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });
          }
        }
      });
    },
    getUserInfo(id) {
      var self = this;
      axios
        .post("/dashboard/get-user-info", { id: id })
        .then(function (response) {
          if (response.data.info) {
            self.leaderboardValues = response.data.info.leaderboardValues;
            self.pointValues = response.data.info.pointValues;
            self.site_name = response.data.info.site_name;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getPostBody(post) {
      let body = this.stripTags(post);
      return body.length > 15 ? body.substring(0, 15) + "..." : body;
    },
    stripTags(markup) {
      const temp = document.createElement("div");
      temp.innerHTML = markup;
      return temp.textContent || temp.innerText || "";
    },
    goback() {
      if (this.step == 2) {
        if (this.nopoint.length == 0) {
          this.step = 2;
          return false;
        } else {
          this.step = 1;
          return true;
        }
      }
    },
    nextStep() {
      if (this.step == 1) {
        this.step = 2;
        this.activestep2 = true;
        this.done1 = true;
      }
    },
    getMarked() {
      var self = this;
      self.step = 1;
      self.paymentChecked = [];
      self.nopoint = [];
      self.oldpoint = [];
      for (var i = 0; i < self.results.length; i++) {
        if ($.inArray(self.results[i].id, self.inviteChecked) !== -1) {
          self.paymentChecked.push(self.results[i]);

          if (self.results[i].points) {
            if (self.results[i].points.redeemable_balance) {
              if (this.results[i].active == 1) {
                if (typeof this.results[i].mobile_user !== "undefined") {
                  if (this.results[i].mobile_user == 1) {
                    this.oldpoint.push(self.results[i].id);
                    this.nopoint.push(self.results[i].id);
                  }
                }
              }
            }
          }
        }
      }

      if (self.nopoint.length > 0) {
      } else {
        self.step = 2;
        self.activestep2 = true;
        self.done1 = true;
      }
    },
    markLeaver() {
      var self = this;
      if (self.inviteChecked) {
        Vue.swal({
          title: "Updating...",
          html: "",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Vue.swal.showLoading();
          },
        });
        var checked = 0;
        if (self.nopoint.length > 0) {
          checked = 1;
          axios
            .post("points/create-leaver-payment-request-users", {
              user_id_array: self.nopoint,
              amount_type: "All",
            })
            .then((response) => {
              //
            });
        }
        if (self.nopoint.length != self.oldpoint.length) {
          for (var i = 0; i < this.oldpoint.length; i++) {
            if ($.inArray(self.oldpoint[i], self.nopoint) !== -1) {
              //
            } else {
              axios
                .post("/dashboard/account/unpaid-pointdelete", {
                  user_id: self.oldpoint[i],
                })
                .then((response) => {
                  //
                });
            }
          }
        }
        axios
          .post("/dashboard/user/mark-leaver", {
            users: self.paymentChecked,
            checked: checked,
          })
          .then(function (response) {
            // handle success
            Vue.swal.close();
            if (response.data.success) {
              location.reload();
              $("#processLeaver").modal("hide");
              $(".modal-backdrop").hide();
              self.$bvModal.hide("processLeaver");
            }
            Vue.swal({
              html:
                '<span class="success-circle"><i class="fas fa-check-circle"></i></span>  ' +
                self.inviteChecked.length +
                " Users have been marked as leavers!",
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });

            /*setTimeout(function() { location.reload();
           }, 5000);*/
            //location.reload();
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      }
    },
    leftOptions(val) {
      this.$bvModal.hide("delete-users-modal");
      if (val) {
        this.inviteChecked[0] = val;
      }
      this.showbottomBar = false;
      $("#invitee-selected").hide();
      this.step = 1;
      this.paymentChecked = [];
      this.nopoint = [];
      this.oldpoint = [];
      for (var i = 0; i < this.results.length; i++) {
        if ($.inArray(this.results[i].id, this.inviteChecked) !== -1) {
          this.paymentChecked.push(this.results[i]);

          if (this.results[i].points) {
            if (this.results[i].points.redeemable_balance) {
              if (this.results[i].active == 1) {
                if (typeof this.results[i].mobile_user !== "undefined") {
                  if (this.results[i].mobile_user == 1) {
                    this.oldpoint.push(this.results[i].id);
                    this.nopoint.push(this.results[i].id);
                  }
                }
              }
            }
          }
        }
      }

      if (this.nopoint.length > 0) {
      } else {
        this.step = 2;
        this.activestep2 = true;
        this.done1 = true;
      }
      $("#invitee-selected").hide();
    },
    resetUrl() {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('page');
      searchParams.delete('dirc');
      searchParams.delete('orderby');
      const newUrl = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
      history.replaceState({}, document.title, newUrl);
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  watch: {
    copyImage: function () {
      this.timeoutHandle = setTimeout(
        () => (this.copyImage = "/images/icon-24-duplicate.png"),
        3000
      );
    },
    searchVar: function(newSearchVar) {
      const searchParams = new URLSearchParams(window.location.search);
      if (newSearchVar.length >= 1) {
        searchParams.delete('page');
        searchParams.delete('dirc');
        searchParams.delete('orderby');
        searchParams.set('search', newSearchVar);
      } else {
        searchParams.delete('search');
      }
      const newUrl = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
      history.replaceState({}, document.title, newUrl);
    },
    statusFilter: function(newStatusValue) {
      if(newStatusValue !== undefined) {
        const searchParams = new URLSearchParams(window.location.search);
        if (newStatusValue.length > 0) {
          searchParams.delete('page');
          searchParams.delete('dirc');
          searchParams.delete('orderby');
          searchParams.set('filter', newStatusValue);
        } else {
          searchParams.delete('filter');
        }
        const newUrl = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
        history.replaceState({}, document.title, newUrl);
      }
    },
    siteFilter: function(newSiteValue) {
      if(newSiteValue !== undefined) {
        const searchParams = new URLSearchParams(window.location.search);
        if (newSiteValue.length > 0) {
          searchParams.delete('page');
          searchParams.delete('dirc');
          searchParams.delete('orderby');
          searchParams.set('sitesFilter', newSiteValue);
        } else {
          searchParams.delete('sitesFilter');
        }
        const newUrl = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
        history.replaceState({}, document.title, newUrl);
      }
    }
  },
  created() {
    this.$watch(
      function () {
        return this.magic_flag;
      },
      function (magic_flag) {
        if (magic_flag) {
          this.handler = function (e) {
            e.preventDefault();
            e.returnValue = "";
          };
          window.addEventListener("beforeunload", this.handler);
        } else if (!magic_flag) {
          window.removeEventListener("beforeunload", this.handler);
          this.handler = null;
        }
      }
    );

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("search")) {
      console.log(urlParams.get("search"));
      this.searchVar = urlParams.get("search");
    }
  },
  mounted() {
    this.currentURL = window.location.origin;
    this.paginationData.current_page = this.appUsers.current_page;
    this.paginationData.from = this.appUsers.from;
    this.paginationData.last_page = this.appUsers.last_page;
    this.paginationData.per_page = this.appUsers.per_page;
    this.paginationData.to = this.appUsers.to;
    this.paginationData.total = this.appUsers.total;
    this.currentSort = this.orderby;
    this.currentSortDirc = this.sort;

    if (this.allSites) {
      if (this.allSites.length > 0) {
        var site = {
          id: 0,
          name: "None",
          image: "/images/idle.png",
        };
        var site_portal = {
          id: -1,
          name: "All sites",
          image: "/images/idle.png",
        };
        this.sitesInviteOptions.push(site);
        this.sitesInviteOptionsPortal.push(site_portal);
      }
      for (var i = 0; i < this.allSites.length; i++) {
        if (this.allSites[i].name != "None") {
          site = {
            id: this.allSites[i].id,
            name: this.allSites[i].name,
            image: this.allSites[i].image,
          };
          this.sitesInviteOptions.push(site);
          this.sitesInviteOptionsPortal.push(site);
        }
      }
    }
    this.selectedCategory(1);
    this.selectedSiteCategory(1);
  },
  computed: {
    btnDisabledPoints() {
      if (this.selected_point.length > 0 && this.reason_id > 0) {
        if (this.reason_id == 5 && !this.otherReason) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    btnAddCanidate() {
      if (
        this.inviteUser.first_name &&
        this.inviteUser.surname &&
        (this.reg.test(this.inviteUser.email) ||
          this.phoneReg.test(this.inviteUser.mobile))
      ) {
        return false;
      } else return true;
    },
    editbtnDisabled() {
      if (
        (this.inviteUser.first_name != this.checkUser.first_name ||
          this.inviteUser.surname != this.checkUser.surname ||
          this.inviteUser.email != this.checkUser.email ||
          this.inviteUser.mobile != this.checkUser.mobile ||
          this.inviteUser.site != this.checkUser.site || this.inviteUser.PaymentInfo != this.checkUser.PaymentInfo) &&
        this.inviteUser.first_name &&
        this.inviteUser.surname &&
        (this.reg.test(this.inviteUser.email) ||
          this.phoneReg.test(this.inviteUser.mobile))
      ) {
        return false;
      } else return true;
    },
    selectAll: {
      get: function () {
        if (this.invites.length > 0) {
          return this.invites.data
            ? this.inviteChecked.length == this.invites.data.length
            : false;
        }
      },
      set: function (value) {
        if (value) {
          this.inviteChecked = [];
          for (var i = 0; i < this.invites.length; i++) {
            if (
              this.invites[i].mark_as_leaver == 0 ||
              (this.invites[i].status != "Leaver" &&
                this.invites[i].unsubscribed != 1)
            ) {
              this.inviteChecked.push(this.invites[i].id);
            }
          }
          this.total_invitees = this.inviteChecked.length;
        } else {
          this.total_invitees = this.total_invitees;
          this.inviteChecked = [];
        }
      },
    },
  },
};
</script>
<style>
.sites_tag {
  margin-left: 3px;
}
.filters-wrappers-app .input-box .vs__selected-options {
  top: 0px;
}
.filters-wrappers-app .input-box .vs__selected-options:after {
  content: "";
  display: table;
  clear: both;
}
.filters-wrappers-app .vs__selected {
  display: none !important;
}
/*#app .filters-wrappers-app .vs__dropdown-toggle {
        height: auto;
        border: 0;
        padding: 50px 0 0 !important;
    }*/
/*#app .filters-wrappers-app .vs__dropdown-toggle:before {
        content: '';
        border: 1px solid #DCDEE6;
        width: 100%;
        height: 42px;
        position: absolute;
        top: 0;
    }*/
/*#app .filters-wrappers-app .vs__dropdown-toggle .vs__selected-options {
        top: 0;
        position: initial;
    }*/
/*#app .filters-wrappers-app .vs__dropdown-toggle .vs__selected-options .vs__search {
        position: absolute;
        top: 0;
        left: 0;
    }*/
/*#app .filters-wrappers-app .vs__dropdown-toggle .vs__actions {
        position: absolute;
        top: 0;
        right: 5px;
        height: 42px;
    }*/
.filters-wrappers-app .deselect {
  width: 24px;
  height: 24px;
  background: url(/images/exit.png) no-repeat right center !important;
  display: inline-flex;
  cursor: pointer;
}
.filters-wrappers-app .deselect-pad {
  padding-top: 5px;
  display: inline-table;
  cursor: pointer;
  opacity: 0.75;
}
.filters-wrappers-app span.lablesize {
  height: 16px;
  font-family: InterUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #656a85;
  padding: 8px 2px 8px 10px;
  display: block;
  float: left;
}
.filters-wrappers-app .tag-input__tag {
  height: 32px;
  border-radius: 16px;
  background-color: #f0f1f7;
  margin-bottom: 10px;
}
.filters-wrappers-app .tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}
.filters-wrappers-app .small-2 {
  width: 21%;
}
.filters-wrappers-app .cell:first-child {
  max-width: 190px;
  padding-right: 12px;
}
.filters-wrappers-app .reportrange-text {
  max-width: 79px;
}
.filters-wrappers-app .cell:last-child {
  max-width: 260px;
  padding-right: 12px;
}
.filters-wrappers-app .cell input[type="search"] {
  color: #85899e;
}
.filters-wrappers-app .daterangepicker td.off,
.filters-wrappers-app .daterangepicker td.off.end-date,
.filters-wrappers-app .daterangepicker td.off.in-range,
.filters-wrappers-app .daterangepicker td.off.start-date {
  background-color: #fff !important;
}
.filters-wrappers-app .daterangepicker .calendar-table td {
  color: #363b54;
}
.filters-wrappers-app .daterangepicker .calendar-table tbody th {
  background: #f7f8fc;
  height: 36px;
}

.filters-wrappers-app .daterangepicker td.active,
.filters-wrappers-app .daterangepicker td.active:hover {
  background-color: #fbd8dd !important;
  border-radius: 0 !important;
}

.filters-wrappers-app .daterangepicker td.active,
.filters-wrappers-app .daterangepicker td.active:hover {
  background-color: inherit !important;
  border-radius: 0;
  position: relative;
  z-index: 2;
}
.filters-wrappers-app .daterangepicker td.active,
.filters-wrappers-app .daterangepicker td:hover {
  color: #fff !important;
}
.filters-wrappers-app .daterangepicker td.active:after,
.filters-wrappers-app .daterangepicker td:hover:after {
  width: 26px !important;
  content: "";
  height: 26px !important;
  background: #ef5d72 !important;
  display: block !important;
  position: absolute;
  left: 50% !important;
  margin-left: -13px;
  top: 50% !important;
  z-index: -1 !important;
  border-radius: 50%;
  margin-top: -13px;
}

.filters-wrappers-app .daterangepicker td.active:before,
.filters-wrappers-app .daterangepicker td:hover:before {
  content: "";
  display: block;
  width: 50%;
  background: #fbd8dd;
  position: absolute;
  z-index: -1;
  left: -1px;
  top: 50%;
  margin-top: -13px;
  height: 26px;
}
.filters-wrappers-app tbody tr:nth-child(2n) {
  background: none;
}
.filters-wrappers-app .daterangepicker .calendar-table td {
  position: relative;
  z-index: 2;
}
.filters-wrappers-app .daterangepicker td.in-range {
  background: none;
}
.filters-wrappers-app .daterangepicker td.in-range:after {
  content: "";
  background: #fbd8dd;
  display: block;
  position: absolute;
  left: -1px;
  width: calc(100% + 1px);
  height: 26px;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  z-index: -3;
}
.filters-wrappers-app tbody {
  border: 0;
}

.filters-wrappers-app .daterangepicker td.active.start-date:before,
.filters-wrappers-app .daterangepicker td.active.start-date:hover:before {
  left: inherit;
  right: 0;
}
.filters-wrappers-app .daterangepicker td.active:before,
.filters-wrappers-app .daterangepicker td:hover:before {
  background: none;
}
.filters-wrappers-app .daterangepicker td.end-date.active:before,
.filters-wrappers-app .daterangepicker td.end-date:hover:before,
.filters-wrappers-app .daterangepicker td.start-date.active:before,
.filters-wrappers-app .daterangepicker td.start-date:hover:before {
  background: #fbd8dd;
}

/*.filters-wrappers-app .padright {
        margin-right: 10px;
    }*/

#invitees-added {
  overflow-y: auto !important;
}
.dublicate-anchor {
  text-decoration: underline;
  cursor: pointer;
  padding-bottom: 10px;
  text-decoration: underline;
}

.tooltip {
  display: block !important;
  z-index: 10000;
  margin-right: 75px;
}
.tooltip .tooltip-inner {
  background: #363b54;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
#text-box .delete .vs__dropdown-toggle,
.modal .delete .vs__dropdown-toggle {
  text-transform: none !important;
}
.delete ul > li {
  text-transform: none !important;
}

.cf-table .cell.auto p.deactivated {
  display: inline-block;
  padding: 0 12px;
  font: 600 11px/24px "InterUI", sans-serif;
}
.deactivated span {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: #ef5d72;
}
.deactivated {
  height: 24px;
  border-radius: 12px;
  background-color: #fbd8dd;
  text-align: center !important;
}
#processLeaver .modal-lg {
  max-width: 800px !important;
  width: 800px !important;
}
.pad-topp {
  padding-top: 20px;
}
#processLeaver fieldset p {
  margin: 32px 0 20px;
  font-family: InterUI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #85899e;
}

.cf-table .cell.auto p.deactivated {
  display: inline-block;
  padding: 0 12px;
  font: 600 11px/24px "InterUI", sans-serif;
}
.deactivated span {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: #ef5d72;
}
.deactivated {
  height: 24px;
  border-radius: 12px;
  background-color: #fbd8dd;
  text-align: center !important;
}
.deactivate-their-a {
  margin: 24px 32px 50px;
  font-family: InterUI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #85899e;
}
p.deactivate-their-a ul li {
  margin: 0px 28px 0px;
  font-family: InterUI !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.57;
  letter-spacing: normal;
  color: #85899e !important;
}
.moveright {
  padding-left: 54px;
}
.movecenter {
  margin-left: 70px;
}
.movecenter input:checked ~ .checkmark-check::after {
  display: block;
}
.deactivate-their-a li:before {
  content: "• ";
  padding-right: 14px;
}
.deactivate-their-a li:before span {
  padding-right: 14px;
}
</style>
