<template>
  <div>
    <div v-if="showImg" class="show-load-client-super">
      <img :src="loadImage" />
    </div>
    <div class="grid-x grid-padding-x">
      <div
        class="cell shrink client-bottom candidate-margin-left"
        @click="postData"
      >
        <a :disabled="enableSaveButton == false" class="btn button orange_btn">
          Update</a
        >
      </div>
      <div class="cell shrink client-bottom" @click="openAddAutomationModal">
        <a class="btn-edit button pink_btn b_margin_0 no_wrap_btn">
          Add Automation</a
        >
      </div>
    </div>

    <div class="cf-table client-table">
      <div class="bg-card">
        <div
          v-if="
            this.automations['good_news'] &&
            this.automations['good_news'].length > 0
          "
        >
          <div class="container-fluid Goodnews">
            <h4 class="automation-h-new">Good news</h4>
          </div>
          <table class="table1">
            <thead class="table-body-styling1">
              <tr>
                <td class="table-head-styling" scope="col">TRIGGER</td>
                <td class="table-head-styling" scope="col">CONTENT</td>
              </tr>
            </thead>
            <tbody>
              <tr
                class="automation-hover-edit"
                v-for="(automation, index) in this.automations['good_news']"
              >
                <th class="table-CREATED-styling">
                  {{ automation.trigger }}
                </th>
                <td scope="row" class="text-p-styling-automation">
                  <div class="edit-automations-div">
                    <div>
                      <span
                        class="table-title-styling1"
                        v-html="
                          automation.title
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                      ></span>

                      <p
                        v-b-tooltip.hover.html="{
                          customClass: 'Notification-tooltip',
                          title: automation.description,
                        }"
                        class="p-styling1"
                        v-html="
                          automation.description
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                      ></p>

                      <!-- <span
                        class="p-styling1"
                        v-html="
                          automation.description
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 100) + '..'
                        "
                      >
                      </span> -->
                    </div>

                    <div class="img-automation">
                      <div>
                        <img
                          class="img-automation-styling"
                          @click="
                            editNotification(
                              automation.id,
                              index,
                              automation.category
                            )
                          "
                          src="/images/icon-24-edit-02.png"
                          alt=""
                        />
                      </div>

                      <div>
                        <img
                          class="img-automation-styling"
                          @click="
                            showDeleteNotifcationModal(
                              automation.id,
                              index,
                              automation.category
                            )
                          "
                          src="/images/icon-24-delete.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="
            this.automations['candidates'] &&
            this.automations['candidates'].length > 0
          "
        >
          <div class="container-fluid Goodnews">
            <h4>Candidates</h4>
            <p class="condidatesParagraph">
              We’ll send these notifications to your staff automatically when
              the status of their candidate is updated, so they always know how
              their candidate are progressing.
            </p>
          </div>

          <table class="table1">
            <thead class="table-body-styling1">
              <tr class="">
                <td class="table-head-styling" scope="col">TRIGGER</td>
                <td class="table-head-styling" scope="col">CONTENT</td>
              </tr>
            </thead>
            <tbody>
              <tr
                class="automation-hover-edit"
                v-for="(automation, index) in this.automations['candidates']"
              >
                <th class="table-CREATED-styling">
                  {{ automation.trigger }}
                </th>
                <td scope="row" class="text-p-styling-automation">
                  <div class="edit-automations-div">
                    <div>
                      <span
                        class="table-title-styling1"
                        v-html="
                          automation.title
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                      ></span>
                      <p
                        v-b-tooltip.hover.html="{
                          customClass: 'Notification-tooltip',
                          title: automation.description,
                        }"
                        class="p-styling1"
                        v-html="
                          automation.description
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                      ></p>
                    </div>

                    <div class="img-automation">
                      <div>
                        <img
                          class="img-automation-styling"
                          @click="
                            editNotification(
                              automation.id,
                              index,
                              automation.category
                            )
                          "
                          src="/images/icon-24-edit-02.png"
                          alt=""
                        />
                      </div>

                      <div>
                        <img
                          class="img-automation-styling"
                          @click="
                            showDeleteNotifcationModal(
                              automation.id,
                              index,
                              automation.category
                            )
                          "
                          src="/images/icon-24-delete.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="
            this.automations['payments'] &&
            this.automations['payments'].length > 0
          "
        >
          <div
            class="container-fluid Goodnews"
            v-if="
              this.automations['payments'] &&
              this.automations['payments'].length > 0
            "
          >
            <h4>Payments</h4>
            <p class="condidatesParagraph">
              We’ll send these notifications to your staff automatically when
              the status of their candidate is updated, so they always know how
              their candidate are progressing.
            </p>
          </div>
          <table class="table1">
            <thead class="table-body-styling1">
              <tr>
                <td class="table-head-styling" scope="col">TRIGGER</td>
                <td class="table-head-styling" scope="col">CONTENT</td>
              </tr>
            </thead>
            <tbody>
              <tr
                class="automation-hover-edit"
                v-for="(automation, index) in this.automations['payments']"
              >
                <th class="table-CREATED-styling">
                  {{ automation.trigger }}
                </th>
                <td scope="row" class="text-p-styling-automation">
                  <div class="edit-automations-div">
                    <div>
                      <span
                        class="table-title-styling1"
                        v-html="
                          automation.title
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.title
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                      ></span>
                      <span
                        class="p-styling1"
                        v-html="
                          automation.description
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : automation.description
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '...'
                        "
                      ></span>
                    </div>

                    <div class="img-automation">
                      <div>
                        <img
                          class="img-automation-styling"
                          @click="
                            editNotification(
                              automation.id,
                              index,
                              automation.category
                            )
                          "
                          src="/images/icon-24-edit-02.png"
                          alt=""
                        />
                      </div>

                      <div>
                        <img
                          class="img-automation-styling"
                          @click="
                            showDeleteNotifcationModal(
                              automation.id,
                              index,
                              automation.category
                            )
                          "
                          src="/images/icon-24-delete.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="
            this.automations['others'] && this.automations['others'].length > 0
          "
        >
          <div
            class="container-fluid Goodnews"
            v-if="
              this.automations['others'] &&
              this.automations['others'].length > 0
            "
          >
            <h4>Other</h4>
          </div>
          <table class="table1">
            <thead class="table-body-styling1">
              <tr>
                <td class="table-head-styling" scope="col">TRIGGER</td>
                <td class="table-head-styling" scope="col">CONTENT</td>
              </tr>
            </thead>
            <tbody>
              <tr
                class="automation-hover-edit"
                v-for="(automation, index) in this.automations['others']"
              >
                <th class="table-CREATED-styling">
                  {{ automation.trigger }}
                </th>
                <td scope="row" class="text-p-styling-automation">
                  <div class="edit-automations-div">
                    <div>
                      <span
                        class="table-title-styling1"
                        v-html="automation.title"
                      ></span>
                      <span
                        class="p-styling1"
                        v-html="automation.description"
                      ></span>
                    </div>

                    <div class="img-automation">
                      <div>
                        <img
                          class="img-automation-styling"
                          @click="
                            editNotification(
                              automation.id,
                              index,
                              automation.category
                            )
                          "
                          src="/images/icon-24-edit-02.png"
                          alt=""
                        />
                      </div>

                      <div>
                        <img
                          class="img-automation-styling"
                          @click="
                            showDeleteNotifcationModal(
                              automation.id,
                              index,
                              automation.category
                            )
                          "
                          src="/images/icon-24-delete.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      ref="add-job"
      id="edit-automation-notification"
      size="xl"
      :no-close-on-backdrop="true"
    >
      <section class="multi-step-row">
        <div class="pop-up-box">
          <div class="text-box" id="text-box">
            <fieldset>
              <div class="job-leftside">
                <form>
                  <div class="form-group">
                    <p class="Edit-notification">Edit Automation</p>
                    <label
                      for="exampleInputEmail1"
                      class="exampleInputEmailtitle"
                      >Title *</label
                    >
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            v-model="currentObject.title"
                                            aria-errormessage="notificationTitle"
                                        /> -->

                    <ckeditor
                      class="form-control text-area-border styling-bottom"
                      placeholder="Add Wording"
                      id="exampleFormControlTextarea1"
                      name="Message"
                      rows="5"
                      v-model="currentObject.title"
                      :config="titleEditorConfig"
                    >
                    </ckeditor>

                    <span class="form-error" id="notificationTitle"
                      >Please enter a notification title</span
                    >
                  </div>

                  <div class="form-group">
                    <label class="wording" for="exampleFormControlTextarea1"
                      >WORDING *</label
                    >
                    <ckeditor
                      class="cheditor-styling"
                      :config="editorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObject.description"
                    ></ckeditor>
                  </div>
                  <!-- <div class="imgae-Modal-styling">
                                      <p class="img-Content-styling">Image</p>
                                  </div> -->
                  <!-- <label class="Image-label-content"></label>
                                  <input type="file" id="actual-btn" hidden />
                                  <label for="actual-btn" class="upload-file-styling">
                                      <img class="img-upload-btn" src="/images/Capture.PNG" />
                                      <span
                                      >Drag and drop an image or browse to choose a file.Min
                                   size 180 x 180 px</span
                                      >
                                  </label> -->
                </form>
              </div>
              <div class="job-rightside">
                <div class="frame">
                  <img
                    class="img-mobile-notification edit-mobile-image"
                    src="/images/notification-preview-image.png"
                    alt=""
                  />

                  <div class="mobile-notification-title-automation">
                    <div class="mobile-notification-branding-table line-clamp-title">
                        <img src="/images/cf-favicon.png"/>
                        <p>CARE FRIENDS</p>
                    </div>
                    <p
                      class="
                        mobile-notification-title-automation
                        hidden-title
                        line-clamp-title
                      "
                      v-html="currentObject.title"
                    ></p>
                    <p
                      class="mobile-notification-paragraph-automation"
                      v-html="currentObject.description"
                    ></p>
                  </div>
                </div>
              </div>

              <div class="cell auto popup-button pad-top edit-btn-styling">
                <button @click.prevent="closeModal" class="Cancel button btn">
                  Cancel
                </button>
                <input
                  @click="save"
                  type="button"
                  name="Edit"
                  class="next action-button button btn_lg btn pink_btn"
                  value="Save"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>
    <b-modal
      ref="add-automation"
      id="add-automation"
      size="xl"
      :no-close-on-backdrop="true"
      @show="resetSavingState"
    >
      <section class="multi-step-row">
        <div class="pop-up-box">
          <div class="text-box" id="text-box">
            <fieldset>
              <div class="job-leftside">
                <form>
                  <div class="form-group">
                    <p class="Edit-notification">Add Automation</p>
                    <label for="exampleInputEmail1" class="Title-content"
                      >Trigger *</label
                    >
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            aria-errormessage="notificationTitle"
                                            required
                                            v-model="form.Title"
                                        /> -->
                    <ckeditor
                      class="cheditor-styling-title"
                      :config="titleEditorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObjectAdd.trigger"
                    ></ckeditor>
                    <span class="form-error" id="notificationTrigger"
                      >Please enter a notification trigger</span
                    >
                  </div>
                  <div class="form-group">
                    <label
                      for="Title_automation"
                      class="Title-content-automation"
                      >Title *</label
                    >
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            aria-errormessage="notificationTitle"
                                            required
                                            v-model="form.Title"
                                        /> -->
                    <ckeditor
                      class="cheditor-styling-title"
                      :config="titleEditorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObjectAdd.title"
                    ></ckeditor>
                    <span class="form-error" id="notificationTitle"
                      >Please enter a notification title</span
                    >
                  </div>
                  <div class="form-group">
                    <label class="wording" for="exampleFormControlTextarea1"
                      >WORDING *</label
                    >
                    <!-- <div class="text-area-header">
                                            <img
                                                src="/images/icon-text-list-bullet-copy.png"
                                                alt=""
                                            />
                                          <img src="/images/icon-24-undo2.png" alt="" />
                                          <img src="/images/icon-24-redo.png" alt="" />
                                        </div> -->
                    <ckeditor
                      class="cheditor-styling"
                      :config="editorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObjectAdd.description"
                    ></ckeditor>
                    <label for="exampleInputEmail1" class="label-catagory-temp"
                      >Catagory *</label
                    >
                    <div class="catagory-automation-dropdown">
                      <select
                        class="select-border"
                        required
                        name="category"
                        id="category"
                        v-model="currentObjectAdd.category"
                      >
                        <option value="good_news">Good News</option>
                        <option value="candidates">Candidates</option>
                        <option value="payments">Payments</option>
                        <option value="others">Other</option>
                      </select>
                    </div>
                    <!-- <textarea
                                            class="form-control text-area-border"
                                            placeholder="Add Wording"
                                            id="exampleFormControlTextarea1"
                                            v-model="form.Message"
                                            name="Message"
                                            rows="5"
                                        >
                                      </textarea> -->
                  </div>
                </form>
              </div>
              <div class="job-rightside">
                <div class="frame mobile-view">
                  <img
                    class="img-mobile-notification"
                    src="/images/component-notifications-preview-mobile.png"
                    alt=""
                  />
                  <div class="mobile-notification-title hidden-title">
                    <img class="img-upload" :src="currentObjectAdd.image_url" />
                    <p
                      class="mobile-notification-title"
                      v-html="
                        currentObjectAdd.title
                          .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 25)
                      "
                    ></p>
                    <p
                      class="mobile-notification-paragraph"
                      v-html="currentObjectAdd.description"
                    ></p>
                  </div>
                </div>
              </div>

              <div class="cell auto popup-button pad-top edit-btn-styling">
                <button
                  @click.prevent="closeAddAutomationModal"
                  class="Cancel button btn"
                >
                  Cancel
                </button>
                <input
                  @click="saveNewAutomation"
                  type="button"
                  name="Edit"
                  class="next action-button button btn_lg btn pink_btn"
                  :value="isSaving ? 'Saving' : 'Save'"
                  :disabled="isSaving"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>
    <b-modal class="tab-modal" id="modal_delete_automation" size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <div class="discard-header4">
              <h3 class="discard-header-title">Are you sure to delete it?</h3>
            </div>
            <p class="if-you-discard-chang">It can not be undone.</p>
          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input
          class="button btn_lg btn pink_btn"
          type="submit"
          value="Delete"
          style="
            float: right;
            margin-right: -13px;
            margin-bottom: 13px;
            margin-left: 7px;
          "
          @click="deleteNotificationPermanent"
        />
        <input
          type="button"
          class="button btn_lg btn orange_btn anon-can"
          value="Cancel"
          @click="closeModal"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import CKEditor from "ckeditor4-vue/dist/legacy.js";

export default {
  props: ["backendautomations"],
  CKEditor,
  components: { paginate: Paginate },
  data() {
    return {
      titleEditorConfig: {
        autoParagraph: false,
        autoGrow_maxHeight: 50,
        height: 60,
        editorplaceholder: "Add Text",
        // removePlugins : 'autogrow',
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      step: 1,
      automations: this.backendautomations,
      currentObject: {
        id: null,
        customer_id: null,
        trigger: "",
        title: "",
        description: "",
        category: "",
        push: 1,
        in_app: 1,
        created_at: "",
        updated_at: "",
      },
      currentObjectAdd: {
        id: null,
        customer_id: null,
        trigger: "",
        title: "",
        image_url: "http://portal.cf-staging.co.uk/images/cf-login-logo.png",
        description: "",
        category: "others",
        push: 1,
        in_app: 1,
        created_at: "",
        updated_at: "",
      },
      currentIndex: null,
      currentCategory: "",
      editorConfig: {
        editorplaceholder: "Add words",
        autoParagraph: false,
        height: 148,
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      enableSaveButton: false,
      isSaving: false,
    };
  },
  methods: {
    resetSavingState() {
      this.isSaving = false;
    },
    saveNewAutomation() {
      let that = this;
      that.isSaving = true;
      axios
        .post("/dashboard/super/add/automation", this.currentObjectAdd)
        .then(function (response) {
          that.automations = response.data.automations;
          that.isSaving = false;
          that.closeAddAutomationModal();
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Automation is added',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          // location.reload();
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log("Error Adding Automation Notification - error: ", error);
          that.isSaving = false;
        });
    },
    closeAddAutomationModal() {
      this.$bvModal.hide("add-automation");
    },
    openAddAutomationModal() {
      this.$bvModal.show("add-automation");
    },
    editNotification(id, index, category = "") {
      console.log("called notification # ", id);
      console.log("prev value ::: ", this.currentObject);

      this.currentIndex = index;
      this.currentCategory = category;

      let value = this.automations[category][index];
      this.currentObject = Object.assign({}, value);

      this.$bvModal.show("edit-automation-notification");
    },
    showDeleteNotifcationModal(id, index, category = "") {
      console.log("called notification # ", id);
      console.log("prev value ::: ", this.currentObject);

      this.currentIndex = index;
      this.currentCategory = category;

      let value = this.automations[category][index];
      this.currentObject = Object.assign({}, value);

      this.$bvModal.show("modal_delete_automation");
    },
    deleteNotificationPermanent() {
      let that = this;
      that.showLoad = true;
      axios
        .post("/dashboard/super/delete/automation", {
          id: this.currentObject.id,
        })
        .then(function (response) {
          let temp = JSON.parse(JSON.stringify(that.automations));
          temp[that.currentCategory].splice(that.currentIndex, 1);
          that.automations = JSON.parse(JSON.stringify(temp));

          that.$bvModal.hide("modal_delete_automation");
          that.showLoad = false;

          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Automation is deleted',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          // location.reload();
        })
        .catch(function (error) {
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> '+ error.message,
            confirmButtonColor: "#F84366",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#F84366",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        });
    },
    save() {
      let tempArr = JSON.parse(JSON.stringify(this.automations));
      tempArr[this.currentCategory][this.currentIndex] = JSON.parse(
        JSON.stringify(this.currentObject)
      );
      this.automations = JSON.parse(JSON.stringify(tempArr));

      this.$bvModal.hide("edit-automation-notification");
    },
    closeModal() {
      this.$bvModal.hide("edit-automation-notification");
      this.$bvModal.hide("modal_delete_automation");
    },
    togglePush(index, category, value) {
      let tempArr = JSON.parse(JSON.stringify(this.automations));
      tempArr[category][index].push = value;
      this.automations = JSON.parse(JSON.stringify(tempArr));
    },
    toggleInApp(index, category, value) {
      let tempArr = JSON.parse(JSON.stringify(this.automations));
      tempArr[category][index].in_app = value;
      this.automations = JSON.parse(JSON.stringify(tempArr));
    },
    postData() {
      axios
        .post("/dashboard/super/update/automations", this.automations)
        .then(function (response) {
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Automations-Notifications are Updated',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          this.enableSaveButton = false;
          // location.reload();
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log(
            "Error Updating Automations Notifications - error: ",
            error
          );
        });
    },
  },
  mounted() {
    console.log("automations in mounted : ", this.automations);
  },
  watch: {
    // whenever question changes, this function will run
    automations: function () {
      this.enableSaveButton = true;
    },
  },
};
</script>

<style scoped>
.img-automation {
  display: flex;
}

.img-automation {
  margin-top: 20px;
  /* font-size: 25px; */
  visibility: hidden;
  width: 47px;
}
.table-head-styling {
  font-weight: 600;
  text-align: left;
  font-size: 11px;
  font-family: "InterUI";
  color: #9297ae;
  height: 56px !important;
  padding-left: 25px;
}

select#category {
  margin-top: 10px;
}
.mobile-notification-paragraph-automation {
  height: 27px;
}

p.mobile-notification-paragraph {
  width: 180px;
  /* height: 40px; */
  font-family: SFProText;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  overflow: hidden;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.05px;
  color: #292929;
  position: relative !important;
  top: -42px;
  left: 10px;
  /* word-wrap: break-word; */
  padding-bottom: 12px;
  overflow-x: hidden;
  height: 19px;
  /* text-overflow: ellipsis; */
  background-color: #f7f8fc;
  word-break: break-all;
  line-height: 10px;
}
</style>