<script>
    export default {
        methods: {
            /**
             *
             * @param url
             * @param parameter
             * @returns {string|any}
             */
            removeURLParameter: function (url, parameter) {
                let urlParts = url.split('?');
                if (urlParts.length >= 2) {

                    let prefix = encodeURIComponent(parameter) + '=';
                    let pars = urlParts[1].split(/[&;]/g);

                    for (let i = pars.length; i-- > 0;) {
                        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                            pars.splice(i, 1);
                        }
                    }
                    url = urlParts[0] + '?' + pars.join('&');
                    return url;
                } else {
                    return url;
                }
            },
            /**
             * Remove parameter from the pagination links
             * @param parameter
             */
            removeParameterFromPaginationLinks: function (parameter) {
                const self = this;
                $("ul.pagination a").each(function () {
                    $(this).attr('href', self.removeURLParameter($(this).attr('href'), parameter));
                });
            },
            /**
             * Add parameters to the pagination links
             * @param queryString
             */
            addParameterToPaginationLinks: function (queryString) {
                console.log( queryString );
                $("ul.pagination a").each(function () {
                    console.log( $(this).attr('href') + queryString );
                    $(this).attr('href', $(this).attr('href') + queryString);
                });
            },
            /**
             * Get specific parameter from the url
             * @param name
             * @param url
             * @returns {*}
             */
            getParamsFromUrl(name, url) {
                if (!url) url = location.href;
                name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
                const regexS = "[\\?&]" + name + "=([^&#]*)";
                let regex = new RegExp(regexS);
                const results = regex.exec(url);
                return results == null ? null : results[1];
            },
            setDirectionClass(column) {
                return this.getParamsFromUrl('sort') === column ? this.getParamsFromUrl('direction') : '';
            },
            setSortingLink(column, baseRoute) {
                const direction = (this.getParamsFromUrl('direction') === 'desc') ? 'asc' : 'desc';
                return baseRoute + '?sort=' + column + '&direction=' + direction;
            },

            /**
             * Stringify users basic details
             * @param user
             */
            stringifyUserDetails: function (user) {
                return user ? user.id + "-" + user.email + "-" + user.phone_number : '';
            },

        }

    };
</script>