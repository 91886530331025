<template>
    <template-modal ref="template-modal"
                    :title="modalTitle"
                    :loading="loading"
                    :currentuser="currentuser"
                    @on-process="$refs.form.submit()">
        <template-form ref="form"
                       :template="template"
                       :currentuser="currentuser"
                       :errors="errors"
                       @on-submit="updateTemplate"></template-form>
    </template-modal>
</template>

<script>
import TemplateForm from '../TemplateForm';
import TemplateModal from '../TemplateModal';

export default {
    name: 'EditTemplate',
    props: {
        template: { type: Object, required: true },
        currentuser: { type: Object, required: true }
    },
    components: { TemplateForm, TemplateModal },
    data() {
        return {
            loading: false,
            errors: {},
        }
    },
    computed: {
        isViewMode() {
            return ((this.currentuser.account_check === 0 || this.currentuser.account_check === false) || (this.currentuser.customer.edit_templates_feature === 0 || this.currentuser.customer.edit_templates_feature === false) ); 
        },
        modalTitle() {
            return this.isViewMode ? this.template.name : 'Edit Template - ' + this.template.name;
        }
    },
    methods: {
        showModal() {
            this.errors = {};
            this.$refs['template-modal'].show();
        },
        hideModal() {
            this.$refs['template-modal'].hide();
        },
        async updateTemplate(data) {
            this.loading = true;

            await axios.put('/templates', data)
                .then(response => {
                    this.loading = false;
                    this.hideModal();
                    this.$emit('on-update', response.data.data);
                    Vue.swal({
                        html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Template updated successfully!',
                        confirmButtonColor: "#5dc2a6",
                        confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                        background: "#5dc2a6",
                        customClass: {
                            container: "candidate-toast",
                        },
                        showClass: {
                            popup: "animated fadeInLeft fast",
                            icon: "animated heartBeat delay-1s",
                        },
                        hideClass: {
                            popup: "animated fadeOutLeft slow",
                        },
                        toast: true,
                        timer: 5000,
                        position: "top-right",
                        target: "#example-tabs",
                    });
                })
                .catch(error => {
                    this.loading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else {
                        Vue.swal({
                            title: "Oops!",
                            text: "Something went wrong. Please try again.",
                            confirmButtonColor: "#ec5168",
                            allowOutsideClick: false,
                        });
                    }
                });
        }
    }
}
</script>