<template>
  <div class="cell small-11 t_margin_2">

    <ul class="tabs" data-tabs id="example-tabs" data-deep-link="true" data-update-history="true" data-deep-link-smudge="true">
      <li class="tabs-title is-active"><a data-tabs-target="scheduled" href="#scheduled" @click="newNotificationButton = true">Upcoming</a></li>
      <li class="tabs-title"><a data-tabs-target="sent" href="#sent" @click="newNotificationButton = true">History</a></li>
      <li class="tabs-title"><a data-tabs-target="automatic" href="#automatic" @click="newNotificationButton = false">Automations</a></li>
    </ul>
    <div class="cell small-3" align="right">
      <!-- CREATE NOTIFICATIONS MODAL-->
      <create-notifications-component-modal v-if="newadmin == 1 && notificationcheck == 1 && newNotificationButton" :templates="this.templates" :sitesarr="this.sitesarr" :show_sites="show_sites" :can_notify_all_users="can_notify_all_users" :brand="brand" @refresh-calendar="toggleRefreshCalendar"></create-notifications-component-modal>

    </div>
    <div class="tabs-content box_shadow" data-tabs-content="example-tabs">


      <!-- CREATE NOTIFICATIONS -->
      <!-- SCHEDULED NOTIFICATIONS -->
      <div class="tabs-panel is-active" id="scheduled">
        <scheduled-notifications-table-component  :templates="this.templates" :sitesarr="this.sitesarr" :show_sites="show_sites" :current-user="currentUser" :can_notify_all_users="can_notify_all_users" :brand="brand" :should-refresh-calendar="shouldRefreshCalendar" @refresh-calendar="toggleRefreshCalendar"></scheduled-notifications-table-component>
      </div>

      <!-- AUTOMATIC NOTIFICATIONS -->
      <div class="tabs-panel" id="automatic">
        <!-- SINGLE NOTIFICATION -->
        <automation-notifcation-component :automations="this.automations" :milestone="this.milestone" :brand="brand"></automation-notifcation-component>
      </div>
      <!-- SENT NOTIFICATIONS -->
      <div class="tabs-panel" id="sent">
        <notifications-table-component type="sent" :sitesarr="this.sitesarr" :show_sites="show_sites"></notifications-table-component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['templates', 'sitesarr', 'automations', 'milestone', 'newadmin', 'notificationcheck', 'show_sites', 'currentUser', 'can_notify_all_users', 'brand'],
  data() {
    return {
      availableSites: [],
      newNotificationButton: true,
      shouldRefreshCalendar: false,
    }
  },
  watch: {
  },
  methods: {
    toggleRefreshCalendar() {
      this.shouldRefreshCalendar = !this.shouldRefreshCalendar;
    }
  },
  mounted() {
    this.currentHref = window.location.href;
    if (this.currentHref.indexOf("automatic") != -1){
      this.newNotificationButton = false;
    }
  }
}
</script>