<template>
  <div>
    <div v-if="showImg" class="show-load-client-super">
      <img :src="loadImage" />
    </div>

    <div class="grid-x grid-padding-x">
      <div class="cell shrink client-bottom candidate-margin-left">
        <a href="/dashboard/super/clients/export-app-users/1" class="btn button orange_btn">
          EXPORT APP USERS
        </a>
      </div>

      <div class="cell shrink client-bottom">
        <a href="/dashboard/super/clients/add" class="btn-edit button pink_btn b_margin_0 no_wrap_btn">
          Add a new client
        </a>
      </div>
    </div>

    <!-- FILTERS -->
    <div class="grid-x">
      <div class="cell auto font_0875 no-padding">
        <div class="grid-x">
          <div class="cell small-5">
            <input
              :disabled="showImg"
              class="jobs-search"
              placeholder="Search"
              v-model="searchVar"
              name="searchVar"
              @keyup.enter="loadClientsData()"
            />
          </div>

          <div class="cell small-2 candidate-margin-left">
            <v-select
              class="filter-client"
              placeholder="Filter by:"
              :options="options"
              :reduce="(referrer) => referrer.id"
              label="name"
              v-model="superFilter"
              @input="loadClientsData()"
              :clearable="false"
            >
            </v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="cf-table client-table">
      <div
        class="
          grid-x grid-padding-x
          red_bg
          t_margin_2
          align-middle
          cf-table-head
          sticky-element-super
          client-margin
        "
      >
        <div scope="col" class="cell medium-4 white">
          <p class="tb_margin_1 client-height">
            <a @click="sort('name')">
              <strong>
                Client Name
                <i
                  style="padding-left: 4px"
                  :class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': namesortasc,
                    'fa fa-sort-desc': namesortdesc,
                  }"
                ></i>
              </strong>
            </a>
          </p>
        </div>

        <div scope="col" class="cell medium-3 white">
          <p class="tb_margin_1 client-height">
            <a @click="sort('poc')">
              <strong>
                POC
                <i
                  style="padding-left: 4px"
                  :class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': pocsortasc,
                    'fa fa-sort-desc': pocsortdesc,
                  }"
                ></i>
              </strong>
            </a>
          </p>
        </div>

        <div scope="col" class="cell medium-3 white" align="center">
          <p class="tb_margin_1 client-height">
            <a @click="sort('total_staff')">
              <strong>
                Total staff
                <i
                  style="padding-left: 4px"
                  :class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': total_staffsortasc,
                    'fa fa-sort-desc': total_staffsortdesc,
                  }"
                ></i>
              </strong>
            </a>
          </p>
        </div>

        <div scope="col" class="cell medium-2 white" align="center"></div>
      </div>

      <div v-for="(value, index) in clientsData" :key="index">
        <div class="grid-x grid-padding-x align-middle b_border_b1 client-margin">
          <div class="cell medium-4">
            <p class="tb_margin_1">{{ value.name }}</p>
          </div>

          <div class="cell medium-3">
            <p class="tb_margin_1">{{ value.poc }}</p>
          </div>

          <div class="cell medium-3" align="center">
            <p class="tb_margin_1">{{ value.total_employees }}</p>
          </div>

          <div class="cell medium-2 dropdown-action" align="center">
            <button
              class="dropdown button"
              :id="'d-' + index"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></button>

            <div
              data-alignment="right"
              class="dropdown-menu dropdown-pane radius-3"
              :aria-labelledby="'d-' + index"
            >
              <ul>
                <li>
                  <a :href="'/dashboard/super/clients/auditlog/' + value.id">
                    View Client Audit
                  </a>
                </li>

                <li>
                  <a :href="'/dashboard/super/clients/' + value.id">
                    View Client Breakdown
                  </a>
                </li>

                <li v-if="value.status == 1">
                  <a v-on:click="deActivateClient(value.id)">
                    Deactivate Client
                  </a>
                </li>

                <li v-else>
                  <a v-on:click="reActivateClient(value.id)">
                    Reactivate Client
                  </a>
                </li>

                <li
                  v-if="
                    useremail == 'emma@carefriends.co.uk' ||
                    useremail == 'nicola@carefriends.co.uk'
                  "
                >
                  <a v-on:click="deleteClient(value.id)">Delete Client</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination-container margin-top-2 test-2">
      <template v-if="paginationData.last_page > 1">
        <paginate
          v-model="paginationData.current_page"
          :page-count="paginationData.last_page"
          :page-range="3"
          :margin-pages="2"
          :click-handler="loadClientsData"
          :prev-text="'<'"
          :next-text="'>'"
          :prev-class="'paginate-controls'"
          :next-class="'paginate-controls'"
          :container-class="'pagination pagination-margin'"
          :page-class="'page-item'"
          :active-class="'active'"
        ></paginate>
      </template>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  components: { paginate: Paginate },
  props: ["useremail"],
  data: function () {
    return {
      searchVar: "",
      currentSort: "name",
      currentSortDir: "SORT_ASC",
      sortboth: true,
      namesortasc: false,
      namesortdesc: true,
      pocsortasc: false,
      pocsortdesc: false,
      total_staffsortasc: false,
      total_staffsortdesc: false,
      clientsData: [],
      paginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      showImg: false,
      loadImage: "/images/loading-circle.gif",
      options: [],
      superFilter: "",
    };
  },
  methods: {
    loadClientsData(pageNum) {
      this.showImg = true;

      let extraParams = "?page=" + pageNum + "&column_name=" + this.currentSort + "&sort=" + this.currentSortDir;

      if (this.searchVar.length > 0) {
        extraParams += "&search=" + this.searchVar;
      }

      if (this.superFilter && this.superFilter > 0) {
        extraParams += "&filter=" + this.superFilter;
      }

      axios
        .get("/dashboard/super/clients-data" + extraParams)
        .then((response) => {
          if (response.data) {
            if (response.data.pagination) {
              this.clientsData = response.data.customers;
              this.paginationData.current_page = response.data.pagination.current_page;
              this.paginationData.from = response.data.pagination.from;
              this.paginationData.last_page = response.data.pagination.last_page;
              this.paginationData.per_page = response.data.pagination.per_page;
              this.paginationData.to = response.data.pagination.to;
              this.paginationData.total = response.data.pagination.total;
            } else {
              this.clientsData = response.data.customers.data;
              this.paginationData.current_page = response.data.customers.current_page;
              this.paginationData.from = response.data.customers.from;
              this.paginationData.last_page = response.data.customers.last_page;
              this.paginationData.per_page = response.data.customers.per_page;
              this.paginationData.to = response.data.customers.to;
              this.paginationData.total = response.data.customers.total;
            }
          } else {
            this.clientsData = [];
            this.paginationData.current_page = 0;
            this.paginationData.from = 0;
            this.paginationData.last_page = 0;
            this.paginationData.per_page = 0;
            this.paginationData.to = 0;
            this.paginationData.total = 0;
          }

          this.showImg = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchSuperUsers: function () {
      var self = this;

      axios
        .get("/dashboard/super/get-super-users")
        .then(function (response) {
          if (response.data) {
            self.options = response.data.superAdmins;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sort: function (s) {
      this.currentSort = s;

      if (s === this.currentSort) {
        this.currentSortDir =
          this.currentSortDir === "SORT_ASC" ? "SORT_DESC" : "SORT_ASC";

        if (this.currentSort == "name") {
          this.pocsortasc = false;
          this.pocsortdesc = false;
          this.total_staffsortasc = false;
          this.total_staffsortdesc = false;

          if (this.currentSortDir == "SORT_ASC") {
            this.namesortasc = true;
            this.namesortdesc = false;
          } else {
            this.namesortasc = false;
            this.namesortdesc = true;
          }
        } else if (this.currentSort == "poc") {
          this.namesortasc = false;
          this.namesortdesc = false;
          this.total_staffsortasc = false;
          this.total_staffsortdesc = false;

          if (this.currentSortDir == "SORT_ASC") {
            this.pocsortasc = true;
          } else {
            this.pocsortasc = false;
            this.pocsortdesc = true;
          }
        } else if (this.currentSort == "total_staff") {
          this.namesortasc = false;
          this.namesortdesc = false;
          this.pocsortasc = false;
          this.pocsortdesc = false;

          if (this.currentSortDir == "SORT_ASC") {
            this.total_staffsortasc = true;
          } else {
            this.total_staffsortasc = false;
            this.total_staffsortdesc = true;
          }
        }
      }

      this.loadClientsData();
    },
    deleteClient(clientId) {
      this.$swal({
        title: "Are you sure you want to delete this client?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F84366",
        cancelButtonColor: "#FA9748",
        confirmButtonText: "Yes, delete client",
      }).then((result) => {
        if (result.value) {
          var message = this.$swal({
            title: "Deleted!",
            text: "The client has been deleted",
            type: "success",
            confirmButtonColor: "#FA9748",
          });

          if (clientId) {
            axios
              .post("/dashboard/super/clients/delete/" + clientId, clientId)
              .then(function (response) {
                // handle success

                //Show delete successful message
                document.location.href = "/dashboard/super/clients";
                return message;
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });
          }
        }
      });
    },
    deActivateClient(clientId) {
      this.$swal({
        title: "Are you sure you want to deactivate this client?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F84366",
        cancelButtonColor: "#FA9748",
        confirmButtonText: "Yes, deactivate client",
      }).then((result) => {
        if (result.value) {
          var message = this.$swal({
            title: "Deleted!",
            text: "The client has been deactivated",
            type: "success",
            confirmButtonColor: "#FA9748",
          });

          if (clientId) {
            axios
              .post("/dashboard/super/clients/deActivate/" + clientId, clientId)
              .then(function (response) {
                // handle success

                //Show delete successful message
                document.location.href = "/dashboard/super/clients";
                return message;
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });
          }
        }
      });
    },
    reActivateClient(clientId) {
      this.$swal({
        title: "Are you sure you want to reactivate this client?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F84366",
        cancelButtonColor: "#FA9748",
        confirmButtonText: "Yes, Reactivate client",
      }).then((result) => {
        if (result.value) {
          var message = this.$swal({
            title: "Deleted!",
            text: "The client has been Reactivated",
            type: "success",
            confirmButtonColor: "#FA9748",
          });

          if (clientId) {
            axios
              .post("/dashboard/super/clients/reActivate/" + clientId, clientId)
              .then(function (response) {
                // handle success

                //Show delete successful message
                document.location.href = "/dashboard/super/clients";
                return message;
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });
          }
        }
      });
    },
  },
  mounted() {
    this.loadClientsData();
    this.fetchSuperUsers();
  },
};
</script>
