<script>
export default {
    name: 'IntegrationsIcon'
}
</script>

<template>
    <svg width="16"
         height="15"
         viewBox="0 0 16 15"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g id="Group 607">
            <g id="Group 606">
                <path id="Ellipse 13"
                      d="M8.86744 11.4345C7.42445 12.6013 5.17369 12.2103 3.84023 10.5612C2.50676 8.91202 2.59556 6.62927 4.03855 5.4625"
                      stroke="#6A6F8E" />
                <path id="Line 19"
                      d="M4.03857 5.46259L8.86746 11.4346"
                      stroke="#6A6F8E"
                      stroke-linecap="round" />
                <g id="Group 605">
                    <path id="Ellipse 14"
                          d="M11.0047 9.70554C12.4477 8.53876 12.5365 6.25601 11.203 4.60687C9.86956 2.95774 7.6188 2.56671 6.1758 3.73349"
                          stroke="#6A6F8E" />
                    <path id="Line 20"
                          d="M6.17578 3.7334L11.0047 9.70545"
                          stroke="#6A6F8E"
                          stroke-linecap="round" />
                </g>
                <path id="Line 21"
                      d="M1.47156 10.5473C0.827375 11.0682 0.727413 12.0127 1.24829 12.6569C1.76917 13.3011 2.71364 13.401 3.35783 12.8802L1.47156 10.5473ZM2.89671 9.395L1.47156 10.5473L3.35783 12.8802L4.78298 11.7278L2.89671 9.395Z"
                      fill="#6A6F8E" />
                <path id="Line 22"
                      d="M14.1687 4.17464C14.8129 3.65377 14.9129 2.70929 14.392 2.06511C13.8711 1.42092 12.9266 1.32096 12.2825 1.84184L14.1687 4.17464ZM10.8573 2.99418L9.6909 3.93732L11.5772 6.27013L12.7436 5.32699L10.8573 2.99418ZM12.2825 1.84184L10.8573 2.99418L12.7436 5.32699L14.1687 4.17464L12.2825 1.84184Z"
                      fill="#6A6F8E" />
            </g>
            <path id="Icon"
                  d="M6.54525 8.03402L6.9577 7.72108L6.57206 6.7759L8.27729 8.08163L7.78159 7.09595L8.19504 6.78225"
                  stroke="#6A6F8E"
                  stroke-width="0.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
        </g>
    </svg>
</template>