<template>
  <div :style="cssVariables">
    <widget-box :title="'Applicant Tracking System (ATS) Integration'" :style="cssVariables">
      <integration-steps-data :customer="customer" :job-setting-options="jobSettingOptions"/>
    </widget-box>
  </div>
</template>

<script>
import IntegrationStepsData from "../widgets/IntegrationSteps/IntegrationStepsData.vue";
import { WidgetBox } from "../components/WidgetBox";
import { cssVariables } from "../../../lib/css-variables";

export default {
  components: { IntegrationStepsData, WidgetBox },
  name: "Integrations",
  props: {
    customer: { type: Object, default: null },
    jobSettingOptions: { type: String, default: null },
  },
  created() {
    this.cssVariables = cssVariables();
  },
};
</script>

<style scoped lang="scss">
@import "./Integrations.scss";
</style>
