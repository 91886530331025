<script>
export default {
  name: 'HelpIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0003 15.4823C11.5989 15.4823 11.2742 15.8076 11.2742 16.209C11.2742 16.6098 11.5989 16.9345 12.0003 16.9345C12.4011 16.9345 12.7264 16.6098 12.7264 16.209C12.7264 15.8076 12.4011 15.4823 12.0003 15.4823ZM12.6783 13.9319V12.9869C14.0092 12.6651 15 11.4638 15 10.036C15 8.36187 13.6387 7 11.9646 7C10.8894 7 9.88436 7.57805 9.34199 8.50698C9.19926 8.752 9.09103 9.01486 9.02204 9.28961C8.9251 9.67141 9.15704 10.0597 9.53884 10.1561C9.92123 10.2542 10.309 10.0217 10.4059 9.63929C10.4422 9.49478 10.4987 9.35622 10.5742 9.22657C10.8621 8.73356 11.3949 8.42788 11.9646 8.42788C12.8513 8.42788 13.5733 9.14926 13.5733 10.036C13.5733 10.9227 12.8513 11.644 11.9646 11.644C11.5709 11.644 11.251 11.9634 11.251 12.3577V13.9319C11.251 14.3262 11.5709 14.6455 11.9646 14.6455C12.3589 14.6455 12.6783 14.3262 12.6783 13.9319Z" fill="currentColor"/>
  </svg>
</template>