<template>
  <integration-steps-view
    :merge-ats-platform="mergeAtsPlatform"
    :merge-is-integrated="mergeIsIntegrated"
    :offices="offices"
    :show-update-job-status="showUpdateJobStatus"
    :job-setting-options="jobSettingOptions"
    :job-status-on-create="jobStatusOnCreate"
    :job-status-on-update="jobStatusOnUpdate"
    :integratedOffices="integratedOffices"
    :last-job-sync-time="lastJobSyncTime"
    :stepCheck="stepCheck"
    :merge_link_token="mergeLinkToken"
    @select="onSelect"
    @job-status-select="onJobStatusSelect"
    @remove="onRemove"
    :loading="loading"
    :error="error"
    :mapping-exists="mappingExists"
    @on-manage-click="manageMergeLink"
    @on-sync-click="bulkImportMergeJobs"
    @mapping-created="mappingCreated"
    @on-store-job-status-click="storeCustomerJobSetting"
  />
</template>

<script>
import axios from "axios";
import IntegrationStepsView from "./IntegrationStepsView.vue";

export default {
  name: "IntegrationStepsData",
  props: {
    customer: { type: Object, default: null },
    jobSettingOptions: { type: Object, default: null },
  },
  components: {
    IntegrationStepsView,
  },
  data() {
    return {
      stepCheck: false,
      mergeAtsPlatform: "",
      mergeIsIntegrated: this.customer.merge_token !== "" && this.customer.merge_token !== null,
      lastJobSyncTime: "",
      mergeLinkToken: false,
      mappingExists: false,
      offices: [],
      integratedOffices: [],
      jobStatusOnCreate: null,
      jobStatusOnUpdate: null,
      loading: false,
      error: false,
      showUpdateJobStatus: false,
    };
  },
  methods: {
    async loadData() {
      this.loading = true;

      try {
        if (this.mergeIsIntegrated) {
          await this.getMergeConnectedAts();
          await this.getOffices();
          await this.getLastJobSyncTime();
          await this.getCustomerJobSetting();
          this.error = false;
        }
      } catch (err) {
        this.error = true;
      }
      this.loading = false;
    },
    async manageMergeLink() {
      if (this.mergeLinkToken === false) {
        // need to get the link token
        await axios.post("/merge.dev/get-link-token").then(response => {
          this.mergeLinkToken = response.data.link_token;

          MergeLink.initialize({
            linkToken: this.mergeLinkToken,
            onSuccess: public_token => this.saveMergeToken(public_token),
          });

          MergeLink.openLink();
        });
      } else {
        // use existing link
        MergeLink.openLink();
      }
    },

    /**
     * save the merge temporary public token and exchange for a permanent one
     */
    saveMergeToken(public_token) {
      axios.post("/merge.dev/get-account-token", { public_token: public_token })
        .then(async () => {
          this.mergeIsIntegrated = true;
          await this.getMergeConnectedAts();
        })
        .then(async () => {
          this.getOffices();
          this.getCustomerJobSetting();
        });
    },

    /**
     * get the connected ATS
     */
    async getMergeConnectedAts() {
      const { data } = await axios.get("/merge.dev/get-ats-platform");
      this.mergeAtsPlatform = data.platform;
    },

    /**
     * get the available offices
     */
    async getOffices() {
      const { data } = await axios.get("/merge.dev/offices");
      const allOfficesOption = {
        id: 0,
        name: "All offices",
        checked: false,
      };
      const results = data.results;
      allOfficesOption.checked = results.every(office => !office.checked);

      this.offices = [allOfficesOption, ...results];
      this.integratedOffices = this.offices.filter(option => option.checked);
      this.saveSelectedOptions();
    },

    async getCustomerJobSetting() {
      const { data } = await axios.get("/merge.dev/customer-job-settings");
      
      if (data.success == true) {
        this.jobStatusOnCreate = data.payload.job_status_on_create;
        this.jobStatusOnUpdate = data.payload.job_status_on_update;

        this.showUpdateJobStatus = 
          (this.jobStatusOnCreate == null || this.jobStatusOnUpdate == null) ? true : false;
      };
    },

    /**
     * get the connected ATS
     */
    async getLastJobSyncTime() {
      const { data } = await axios.get("/merge.dev/last-job-sync-time");
      this.lastJobSyncTime = data.date;
    },

    /**
     * bulk import jobs
     */
    async bulkImportMergeJobs() {
      await this.$swal({
        title: "Are you sure?",
        html:
          "This will sync all job(s) from <strong>" +
          this.mergeAtsPlatform +
          `</strong>. These jobs will be imported as "inactive" in the ${this.brandName} Portal, and you will then need to activate the jobs you want active within ${this.brandName}.<br /><br />This only needs to be ran once when you first connect to Merge.dev, any new jobs created after that will automatically sync to ${this.brandName}.<br /><br />Any jobs that already exist in your ${this.brandName} account will be updated with the details from the ATS system.<br /><br />If any jobs don't get allocated to the correct site within your ${this.brandName} account, you will need to edit those jobs to allocate the correct site.<br /><br />This process may take a short while to complete, please don't close the window until it's finished.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F84366",
        cancelButtonColor: "#FA9748",
        confirmButtonText: "Yes, continue",
      }).then(result => {
        if (result.value) {
          // show loading screen
          Vue.swal({
            title: "Importing all jobs, please wait.",
            html: "",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Vue.swal.showLoading();
            },
          });

          // call the bulk import
          axios.get("/merge.dev/bulk-import-jobs").then(response => {
            Vue.swal.close();

            // show success
            this.$swal({
              title: "Finished",
              html:
                response.data.added +
                " jobs were created. " +
                response.data.edited +
                " existing jobs were edited. " +
                response.data.failed +
                " jobs failed to import.<br /><br />A job may fail to import if it's missing important data such as the job title, or the job status.",
              type: "success",
              showCancelButton: false,
              confirmButtonColor: "#F84366",
              confirmButtonText: "Close",
            }).then(() => {
              this.getLastJobSyncTime();
            });
          });
        }
      });
    },

    /**
     * check is maping exists
     */
    async mappingDoesExist() {
      const { data } = await axios.get("/merge.dev/mapping-exists");
      this.mappingExists = data.mappingExists;
    },

    mappingCreated() {
      this.mappingExists = true;
    },

    async storeCustomerJobSetting() {
      if (this.showUpdateJobStatus == false) {
        this.showUpdateJobStatus = true;
      } else if (this.jobStatusOnCreate != null && this.jobStatusOnUpdate != null) {
        this.loading = true;
        const { status, data } = await axios.post("/merge.dev/customer-job-settings", {
          job_status_on_create: this.jobStatusOnCreate,
          job_status_on_update: this.jobStatusOnUpdate
        });

        if (status == 200 && data?.success == true) {
          this.loading = false;
          this.showUpdateJobStatus = false;
        };
      };
    },

    onSelect(option) {
      let index = this.offices.indexOf(option);

      if (option.id === 0) {
        this.offices.forEach(office => {
          office.checked = true;
          if (!this.integratedOffices.find(o => o.id === office.id)) {
            this.integratedOffices.push(office);
          }
        });

        this.saveSelectedOptions();
        return;
      }

      this.offices[index].checked = true;
      this.integratedOffices.push(option);
      this.saveSelectedOptions();
    },

    onJobStatusSelect(option, type) {
      if (type == 'ON_CREATE') {
        this.jobStatusOnCreate = option?.value;
      } else if (type == 'ON_UPDATE') {
        this.jobStatusOnUpdate = option?.value;
      };
    },

    onRemove(option) {
      if (option.id === 0) {
        this.offices.forEach(office => {
          office.checked = false;
          this.integratedOffices = [];
        });
        this.saveSelectedOptions();
        return;
      }
      const index = this.offices.findIndex(office => office.id === option.id);
      if (index !== -1) {
        this.$set(this.offices[index], "checked", false);
        this.integratedOffices = this.integratedOffices.filter(office => office.id !== option.id);
      }
      this.saveSelectedOptions();
    },

    async saveSelectedOptions() {
      try {
        const response = await axios.post("/merge.dev/integrate-offices", {
          selectedOffices: this.integratedOffices,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    this.loadData();
    this.mappingDoesExist();
  },

  mounted: function () {
    /**
     * load the merge.dev js file
     */
    let mergeScript = document.createElement("script");
    mergeScript.setAttribute("src", "https://cdn.merge.dev/initialize.js");
    document.head.appendChild(mergeScript);
  },
};
</script>
