<script>
    export default {
    name: "MultipleUserOneOffBudgetIcon",
    };
</script>

<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_80_5185)">
        <path d="M9.5 12C11.7091 12 13.5 10.2091 13.5 8C13.5 5.79086 11.7091 4 9.5 4C7.29086 4 5.5 5.79086 5.5 8C5.5 10.2091 7.29086 12 9.5 12Z" fill="#3C3C3C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4999 12C17.7099 12 19.4999 10.21 19.4999 8C19.4999 5.79 17.7099 4 15.4999 4C15.0299 4 14.5899 4.1 14.1699 4.24C14.9999 5.27 15.4999 6.58 15.4999 8C15.4999 9.42 14.9999 10.73 14.1699 11.76C14.5899 11.9 15.0299 12 15.4999 12Z" fill="#3C3C3C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1494 13.8682C12.5602 13.2901 10.7422 13 9.5 13C6.83 13 1.5 14.34 1.5 17V19C1.5 19.55 1.95 20 2.5 20H12.8414C12.6203 19.3744 12.5 18.7013 12.5 18C12.5 16.3988 13.1272 14.9441 14.1494 13.8682Z" fill="#3C3C3C"/>
        <circle cx="18.5" cy="18" r="4.5" stroke="#3C3C3C"/>
        <path d="M17.3731 16.503V15.39H19.3121V20.5H18.0661V16.503H17.3731Z" fill="#3C3C3C"/>
        </g>
        <defs>
        <clipPath id="clip0_80_5185">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
        </defs>
    </svg>
</template>
