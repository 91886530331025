<template>
    <div>
        <div class="table-top-area">
           <div class="grid-x">
                <div class="cell small-12 point-budget-header">
                    <div class="cell small-6 pull-left point-budget-top">
                        <div class="find-out-more">
                            Use this page to control how many bonus points your portal users can award. If a bonus point
                            award meets the criteria for more than one budget, the relative position of the budgets in the list below determines
                            which is used. Find out more <a href="http://support.carefriends.co.uk/articles/8006859-setting-up-bonus-point-budgets" target="_blank">here</a>.
                        </div>
                    </div>
                    <div v-if="currentuser.account_check && currentuser.bonus_check" class="cell small-6 pull-right point-budget-top">
                        <div class="cell shrink"><a class="green_btn float-right" v-b-modal.add-budget-modal>+ Add Budget </a></div>
                        <AddBudgetsModalComponent
                            @budgetsaved="addBudget"
                            :editMode="editMode"
                            :oneOffUsersForSeparateBudget="oneOffUsersForSeparateBudget"
                            :monthlyRolloverUsersForSeparateBudget="monthlyRolloverUsersForSeparateBudget"
                            :monthlyNonRolloverUsersForSeparateBudget="monthlyNonRolloverUsersForSeparateBudget"
                            :oneOffUsersForSharedBudget="oneOffUsersForSharedBudget"
                            :monthlyRolloverUsersForSharedBudget="monthlyRolloverUsersForSharedBudget"
                            :monthlyNonRolloverUsersForSharedBudget="monthlyNonRolloverUsersForSharedBudget"
                            :sitesForSharedBudget="sitesForSharedBudget"
                            :usersForSharedBudget="usersForSharedBudget"
                            :usersForSeparateBudget="usersForSeparateBudget"
                            :budget-types="budgetTypes"/>
                    </div>
                </div>
           </div>
        </div>
        <!-- JOBS Listing -->
        <div class="cf-table" id="point-budgets">
            <EditBudgetsModalComponent
                :activePointBudgetRecord="activePointBudgetRecord"
                @budgetupdated="updateBudget"
                @resetEditMode="resetEditMode"
                :sitesForSharedBudget="sitesForSharedBudget_for_update"
                :oneOffUsersForSeparateBudget="oneOffUsersForSeparateBudget_for_update"
                :monthlyRolloverUsersForSeparateBudget="monthlyRolloverUsersForSeparateBudget_for_update"
                :monthlyNonRolloverUsersForSeparateBudget="monthlyNonRolloverUsersForSeparateBudget_for_update"
                :oneOffUsersForSharedBudget="oneOffUsersForSharedBudget_for_update"
                :monthlyRolloverUsersForSharedBudget="monthlyRolloverUsersForSharedBudget_for_update"
                :monthlyNonRolloverUsersForSharedBudget="monthlyNonRolloverUsersForSharedBudget_for_update"
                :usersForSharedBudget="usersForSharedBudget_for_update"
                :usersForSeparateBudget="usersForSeparateBudget_for_update"
                :budget-types="budgetTypes"
                :editMode="editMode"
            />

            <table class="responsive-card-table unstriped " style="margin-top: 0px;border-top-left-radius:0px !important; border-top-right-radius:0px !important;" v-if="currentuser.bonus_check">
                <thead class="table-header" style="background: #eceef4 !important">
                    <tr>
                        <th></th>
                        <th>
                            Name
                            <span v-if="isNameFilterActive && filteredRowCount !== 0" class="row-count-badge">[{{ filteredRowCount }}]</span>
                            &nbsp;&nbsp;
                            <a @click="filterTableData('name')">
                                <icon name="filter-table" />
                            </a>
                            <FilterAction
                                :checkboxes="pointBudgetsData"
                                @selectedValues="handleSelectedValues"
                                :itemsName="itemsName"
                                :isDropdownOpen="namePopOverVisible"
                                :filterby="'name'"
                                :css="'left: calc(7% + 1px);'"
                            >
                            </FilterAction>
                        </th>
                        <th>
                            Holder
                            <span v-if="isHolderFilterActive && filteredRowCount !== 0" class="row-count-badge">[{{ filteredRowCount }}]</span>
                             &nbsp;&nbsp;
                            <a @click="filterTableData('holder')" v-if="currentuser.account_check">
                                <icon name="filter-table" />
                            </a>
                            <FilterAction
                                @selectedValues="handleSelectedValues"
                                :checkboxes="pointBudgetsData"
                                :isDropdownOpen="holderPopOverVisible"
                                :itemsHolder="itemsHolder"
                                :filterby="'holder'"
                                :css="'left: calc(32% + 1px);'">
                            </FilterAction>

                        </th>
                        <th>
                            Type
                            <span v-if="isTypeFilterActive && filteredRowCount !== 0" class="row-count-badge">[{{ filteredRowCount }}]</span>
                            &nbsp;&nbsp;
                            <a @click="filterTableData('type')">
                                <icon name="filter-table" />
                            </a>
                            <FilterAction
                                @selectedValues="handleSelectedValues"
                                :checkboxes="pointBudgetsData"
                                :isDropdownOpen="typePopOverVisible"
                                :itemsType="itemsType"
                                :filterby="'type'"
                                :css="'left: calc(52% + 1px);'">
                            </FilterAction>

                        </th>
                        <th class="allocation-column">Allocation</th>
                        <th v-if="currentuser.account_check"></th>
                    </tr>
                </thead>

                <!-- TODO: Implement ghosting transition (dragging effect animation) -->
                <draggable v-model="pointBudgetsData" tag="tbody" :options="{ animation: 800 }" @end="updateOrder" :disabled="!Boolean(currentuser.account_check) || !isReorderFeatureEnabled">
                    <tr v-for="(row,index) in this.filteredData" :key="index" :class="{
                      'highlight-animation': isHighlighted(index)
                    }">
                        <td>
                            <div>
                                <icon v-if="isReorderFeatureEnabled && currentuser.account_check" name="drag-indicator" />
                            </div>
                        </td>
                        <td>
                            <div>
                            {{ row.Name }}
                            </div>
                        </td>
                        <td>
                            <div>
                              <b-tooltip
                                style="white-space: pre-line"
                                v-if="row.Type !== 1"
                                :target="'holder-column-budget-' +  row.ID"
                                triggers="hover"
                                placement="right"
                              >
                                {{ row.AssociatedUsers.length > 0 ?
                                  row.AssociatedUsers.map(u => u.name).join(', ') : 'No associated users' }}
                              </b-tooltip>
                              <span :id="'holder-column-budget-' +  row.ID">
                                {{ row.Holder.Name }}
                              </span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <icon :name="row.Icon" v-b-tooltip.hover :title="row.BudgetType"/>
                            </div>
                        </td>
                        <td>
                            <div>
                                <b-progress :max="100" height="2rem">
                                    <b-progress-bar class="allocation-progress-bar" :value="getProgressPercentage(row.Allocation.used, row.Allocation.remaining)">
                                    </b-progress-bar>
                                </b-progress>

                                <small class="allocation-used-text" >{{ row.Allocation.used }} used</small>
                                <small style="float:right">{{ row.Allocation.remaining }} Remaining
                                  {{ row.Allocation.days_remaining ? `| ${row.Allocation.days_remaining} days remaining` : '' }}
                                </small>
                            </div>
                        </td>

                        <td v-if="currentuser.account_check" class="dropdown-action" align="right">
                            <button
                                class=""
                                type="button"
                                :id="'d' + row.ID"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                @click="showMenu()"
                            >
                                <icon class="more-action" name="more-vertical" />
                            </button>

                            <div
                                class="dropdown-menu dropdown-pane radius-3"
                                :aria-labelledby="'d' + row.ID"
                                data-alignment="right"
                            >
                                <ul>
                                    <template>
                                        <li>
                                            <a
                                                href="#"
                                                v-b-modal.edit-budget-modal
                                                @click.prevent="editBudget(row)"
                                            >
                                                <icon name="pencil-filled" class="more-action-icon"/>
                                                &nbsp;Edit
                                            </a>
                                        </li>
                                        <li>
                                            <div class="delete-button-wrap">
                                                <a
                                                    href="javascript:void(0)"
                                                    v-b-modal.modal_delete
                                                    @click.prevent=" showDeleteNotificationModal(row.ID)"
                                                    >
                                                    <icon name="trash" class="more-action-icon"/>
                                                    &nbsp; Delete
                                                </a>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </draggable>
            </table>
        </div>

         <!-- delete BUDGET -->
        <b-modal
            class="tab-modal"
            id="modal_delete"
            size="lg"
        >
            <h3 style="font-family: 'Poppins', sans-serif;">Are you sure?</h3>
            <div class="grid-y">
                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                        <p style="font-family: 'Poppins', sans-serif;">
                            You won't be able to revert this!
                        </p>
                    </div>
                </div>
            </div>
            <div class="cell auto popup-button row">
                <input
                    type="button"
                    style="font-family: 'Poppins', sans-serif;"
                    class="button btn_lg btn orange_btn"
                    value="Cancel"
                    @click="$bvModal.hide('modal_delete')"
                />
                <input
                    class="button btn_lg btn pink_btn"
                    type="submit"
                    onmouseover="this.style.backgroundColor='#41a98c';this.style.color='#ffffff;'"
                    style="font-family: 'Poppins', sans-serif; background-color: #55c2a7; color: white"
                    value="Delete budget"
                    @click="deleteBudget"
                />
            </div>
        </b-modal>


    </div>
</template>


<script>
    import draggable from 'vuedraggable';
    import { Icon } from "../../../../app/components/Icon";
    import MoreAction from "../../popovers/MoreAction";
    import FilterAction from "../../popovers/FilterAction";
    import AddBudgetsModalComponent from "../AddBudgetsModalComponent";
    import EditBudgetsModalComponent from "../EditBudgetsModalComponent";
    import axios from "axios";
    import Vue from "vue";

    export default {
        components: {
            draggable,
            Icon,
            MoreAction,
            AddBudgetsModalComponent,
            EditBudgetsModalComponent,
            FilterAction,
        },
        props: ['currentuser', 'budgetTypes'],
        data: function() {
            return {
                //for filteration
                isNameFilterActive: false,
                isHolderFilterActive: false,
                isTypeFilterActive: false,
                selectfilter: 0,
                namePopOverVisible: false,
                holderPopOverVisible: false,
                typePopOverVisible: false,
                itemsName: [],
                itemsHolder: [],
                itemsType: [],
                checkedItems: [],

                highlightedRow: -1, // Initialize to -1 to indicate no row is highlighted
                budgetIdToDelete: null,
                selectedRecord: null,
                // activePointBudgetRecord: null,
                activePointBudgetRecord: {
                    ID: null,
                    Name: "",
                    IsMonthly: null,
                    IsRolledOver: null,
                    NumberOfPoints: null,
                    BudgetType: null,
                    holders: [],
                    type: "",
                    allocation: {
                        used: 0,
                        remaining: null,
                        days_remaining: 30,
                    },
                    individual_budget_selected_users: [],
                    shared_budget_selected_users: [],
                    shared_budget_selected_site: [],

                    MultipleUserHolderShared: [],
                    MultipleUserHolderSeparate: [],
                    SingleSiteHolder: null,
                },
                editMode: false,
                showInputBudgetModal: false,
                pointBudgetsData: [],


                // FOR MODAL
                showError: true,
                budgetname: '',
                submitted: false,
                singleuseroption: [],
                alluseroption: [],
                siteoption: [],
                value: [],

                usersForSeparateBudget: [],
                usersForSharedBudget: [],
                sitesForSharedBudget: [],

                oneOffUsersForSeparateBudget: [],
                monthlyRolloverUsersForSeparateBudget: [],
                monthlyNonRolloverUsersForSeparateBudget: [],

                oneOffUsersForSharedBudget: [],
                monthlyRolloverUsersForSharedBudget: [],
                monthlyNonRolloverUsersForSharedBudget: [],

                oneOffUsersForSeparateBudget_for_update: [],
                monthlyRolloverUsersForSeparateBudget_for_update: [],
                monthlyNonRolloverUsersForSeparateBudget_for_update: [],

                oneOffUsersForSharedBudget_for_update: [],
                monthlyRolloverUsersForSharedBudget_for_update: [],
                monthlyNonRolloverUsersForSharedBudget_for_update: [],

                // oneOffSitesForSharedBudget: [],
                // monthlyRolloverSitesForSharedBudget: [],
                // monthlyNonRolloverSitesForSharedBudget: [],

                usersForSeparateBudget_for_update: [],
                usersForSharedBudget_for_update: [],
                sitesForSharedBudget_for_update: [],

                isVisible: false,
                isVisibleEdit: false,
                subOption: null,

                isReorderFeatureEnabled: true,
            };
        },
        methods: {
          updateOrder(event) {
            const { oldIndex, newIndex } = event;

            if (oldIndex !== newIndex) {
              // Reassign order for the entire list based on the current index, starting from 1
              this.pointBudgetsData.forEach((item, index) => {
                item.PriorityOrder = index + 1;  // Starts from 1
              });

              try {
                // Send the entire updated list to the backend.
                const updatedBudgetList = this.pointBudgetsData.map(budget => ({ id: budget.ID, order: budget.PriorityOrder }));
                axios.post('/points-budgets/update-order', { budgets : updatedBudgetList }).then((res) => {
                  if (res.status === 200) {
                    Vue.swal({
                      html:
                        '<span class="success-circle"><i class="fas fa-check-circle"></i></span> <span style="font-family: \'Poppins\', sans-serif;"> Order has been saved </span>',
                      confirmButtonColor: "#5dc2a6",
                      confirmButtonText:
                        '<i class="fa fa-times" aria-hidden="true"></i>',
                      background: "#5dc2a6",
                      customClass: {
                        container: "candidate-toast"
                      },
                      showClass: {
                        popup: "animated fadeInLeft fast",
                        icon: "animated heartBeat delay-1s"
                      },
                      hideClass: {
                        popup: "animated fadeOutLeft slow"
                      },
                      toast: true,
                      timer: 5000,
                      position: "top-right",
                      target: "#example-tabs"
                    });
                  }
                });
                console.log('Order updated successfully in backend.');
              } catch (error) {
                console.error('Failed to update order in backend:', error);
                this.getPointsBudgetsData();
              }
            }
          },
          resetEditMode(updatedValue) {
            console.log('IN PARENT COMPONENT - EDIT FORM IN MODAL HAS BEEN RESET.');
            this.editMode = false;
            this.activePointBudgetRecord = {
              ID: null,
              Name: "",
              IsMonthly: null,
              IsRolledOver: null,
              NumberOfPoints: null,
              BudgetType: null,

              allocation: {
                remaining: null,
              },
              type: null,
              individual_budget_selected_users: [],
              shared_budget_selected_users: [],
              shared_budget_selected_site: null,
            }
          },
          openEditModal(row) {
          },
          capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
          },
          //for filteration
          filterTableData(filterby, holderIds, typeId) {
            switch(filterby){
              case "name":
                this.itemsName =  this.pointBudgetsData.map(item => item.Name);
                this.namePopOverVisible = !this.namePopOverVisible;
                this.holderPopOverVisible = false;
                this.typePopOverVisible = false;
                this.isNameFilterActive = this.namePopOverVisible;
                this.isHolderFilterActive = false;
                this.isTypeFilterActive = false;
                break;
              case "holder":
                this.getallHolders = this.pointBudgetsData.map(item => item.Holder.Name);
                this.itemsHolder = [...new Set(this.getallHolders)];
                this.holderPopOverVisible = !this.holderPopOverVisible;
                this.namePopOverVisible = false;
                this.typePopOverVisible = false;
                this.isNameFilterActive = false;
                this.isHolderFilterActive = this.holderPopOverVisible;
                this.isTypeFilterActive = false;
                break;
              case "type":
                this.getallTypes =  this.pointBudgetsData.map(item => item.BudgetType);
                this.itemsType =  [...new Set(this.getallTypes)];
                this.typePopOverVisible = !this.typePopOverVisible;
                this.namePopOverVisible = false;
                this.holderPopOverVisible = false;
                this.isNameFilterActive = false;
                this.isHolderFilterActive = false;
                this.isTypeFilterActive = this.typePopOverVisible;
                break;
              default:
                break;
            }
          },
          handleSelectedValues(checkedItems) {
            console.log(checkedItems);
            this.holderPopOverVisible = false;
            this.typePopOverVisible = false;
            this.namePopOverVisible = false;
            this.checkedItems = checkedItems;
          },
          isHighlighted(index) {
            return this.highlightedRow === index;
          },
          showDeleteNotificationModal(id) {
            console.log(id);
            this.budgetIdToDelete = id; // TODO: Change this to ID from <tr>

            this.$bvModal.show("modal_delete");
          },
          getProgressPercentage(used, remaining)
          {
            let progress =  (used / (used + remaining)) * 100;
            return parseInt(progress);
          },
          showMenu()
          {
            $(this.$el).foundation();
          },
          async handleAddBudget(pointsBudgetRequest) {
            try {
              axios.post("/points-budgets", {
                Name: pointsBudgetRequest.Name,
                IsMonthly: pointsBudgetRequest.IsMonthly,
                IsRolledOver: pointsBudgetRequest.IsRolledOver,
                BudgetType: pointsBudgetRequest.BudgetType,
                MultipleUserHolderSeparate: pointsBudgetRequest.MultipleUserHolderSeparate,
                MultipleUserHolderShared: pointsBudgetRequest.MultipleUserHolderShared,
                SingleSiteHolder: pointsBudgetRequest.SingleSiteHolder,
                NumberOfPoints: pointsBudgetRequest.NumberOfPoints,
                CustomerId: Number(this.currentuser.customer_id),
              }).then((res) => {
                Vue.swal({
                  title: "Saving...",
                  html: "",
                  allowOutsideClick: false,
                  onBeforeOpen: () => {
                    Vue.swal.showLoading();
                  },
                  timer: 1000,
                });

                Vue.swal({
                  html:
                    '<span class="success-circle"><i class="fas fa-check-circle"></i></span> <span style="font-family: \'Poppins\', sans-serif;"> New budget added </span>',
                  confirmButtonColor: "#5dc2a6",
                  confirmButtonText:
                    '<i class="fa fa-times" aria-hidden="true"></i>',
                  background: "#5dc2a6",
                  customClass: {
                    container: "candidate-toast"
                  },
                  showClass: {
                    popup: "animated fadeInLeft fast",
                    icon: "animated heartBeat delay-1s"
                  },
                  hideClass: {
                    popup: "animated fadeOutLeft slow"
                  },
                  toast: true,
                  timer: 5000,
                  position: "top-right",
                  target: "#example-tabs"
                });
                this.getPointsBudgetsData();
              });

                self.$bvModal.hide("add-budget-modal");
            } catch (error) {
              console.error(error);
            }
          },
          addBudget(emittedData)
          {
            this.handleAddBudget(emittedData);
          },
          async editBudget(selectedBudget)
          {
            this.editMode = true;
            this.activePointBudgetRecord = {
              ID: selectedBudget.ID,
              Name: selectedBudget.Name,
              IsMonthly: selectedBudget.OneOffOrMonthly,
              IsRolledOver: selectedBudget.IsRolledOver,
              BudgetType: selectedBudget.Type,
              NumberOfPoints: selectedBudget.Allocation.remaining,
              CustomerId: this.currentuser.customer_id,

              allocation: {
                remaining: selectedBudget.Allocation.remaining
              },
              type: selectedBudget.Type,
              individual_budget_selected_users: [],
              shared_budget_selected_users: [],
              shared_budget_selected_site: null,
              associated_user_ids: [],
              associated_site_id: [],
            }

            switch (selectedBudget.Type) {
              case 1:
                this.activePointBudgetRecord.associated_user_ids.push(selectedBudget.Holder.ID);
                break;
              case 2:
                selectedBudget.AssociatedUsers.forEach(user => this.activePointBudgetRecord.associated_user_ids.push(user.id));
                break;
              case 3:
                this.activePointBudgetRecord.associated_site_id.push(selectedBudget.Holder.ID);
                break;
            }

          },
          async updateBudget(emittedData) {
            axios.put(`/points-budgets/${emittedData.ID}`, {
              ID: emittedData.ID,
              Name: emittedData.Name,
              BudgetType: emittedData.BudgetType,
              IsMonthly: emittedData.IsMonthly,
              IsRolledOver: emittedData.IsRolledOver,
              MultipleUserHolderSeparate: emittedData.BudgetType === 1 ?  (Array.isArray(emittedData.MultipleUserHolderSeparate) ? emittedData.MultipleUserHolderSeparate : [emittedData.MultipleUserHolderSeparate]) : [],
              MultipleUserHolderShared: emittedData.BudgetType === 2 ? emittedData.MultipleUserHolderShared : [],
              SingleSiteHolder: emittedData.BudgetType === 3 ? (Array.isArray(emittedData.SingleSiteHolder) ? emittedData.SingleSiteHolder[0] : emittedData.SingleSiteHolder) : null,
              NumberOfPoints: emittedData.NumberOfPoints,
              CustomerId: emittedData.CustomerId,
            }).then((res) => {
              Vue.swal({
                title: "Updating...",
                html: "",
                allowOutsideClick: false,
                onBeforeOpen: () => {
                  Vue.swal.showLoading();
                },
                timer: 1000,
              });

              Vue.swal({
                html:
                  '<span class="success-circle"><i class="fas fa-check-circle"></i></span> <span style="font-family: \'Poppins\', sans-serif;"> Budget has been updated.</span>',
                confirmButtonColor: "#5dc2a6",
                confirmButtonText:
                  '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: {
                  container: "candidate-toast"
                },
                showClass: {
                  popup: "animated fadeInLeft fast",
                  icon: "animated heartBeat delay-1s"
                },
                hideClass: {
                  popup: "animated fadeOutLeft slow"
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
              });
              this.getPointsBudgetsData();
            });

            self.$bvModal.hide("add-budget-modal");
          },
          deleteBudget()
          {
            axios.delete(`/points-budgets/${this.budgetIdToDelete}`)
              .then(response => {
                Vue.swal({
                  title: "Deleting budget...",
                  html: "",
                  allowOutsideClick: false,
                  onBeforeOpen: () => {
                    Vue.swal.showLoading();
                  },
                  timer: 1000,
                });

                Vue.swal({
                  html:
                    '<span class="success-circle"><i class="fas fa-check-circle"></i></span> <span style="font-family: \'Poppins\', sans-serif;"> Budget has been deleted </span>',
                  confirmButtonColor: "#5dc2a6",
                  confirmButtonText:
                    '<i class="fa fa-times" aria-hidden="true"></i>',
                  background: "#5dc2a6",
                  customClass: {
                    container: "candidate-toast"
                  },
                  showClass: {
                    popup: "animated fadeInLeft fast",
                    icon: "animated heartBeat delay-1s"
                  },
                  hideClass: {
                    popup: "animated fadeOutLeft slow"
                  },
                  toast: true,
                  timer: 5000,
                  position: "top-right",
                  target: "#example-tabs"
                });

                this.getPointsBudgetsData();
                this.$bvModal.hide("modal_delete");
              })
              .catch(error => {
                console.error("Error:", error.response.data);
              });
          },
          hideModal() {
            this.$bvModal.hide("editModal");
            let body = document.querySelector(".modal-open");
            body.classList.remove("modal-open");
            body.removeAttribute("style");
            let div = document.querySelector(".modal-backdrop");
            body.removeChild(div);
            this.editMode = false;
            this.reset_form();
          },

          // METHODS FOR MODAL
          submitForm() {
            this.submitted = true;

            if (this.isUsernameValid) {
              // Handle form submission logic here
            }
          },

          onBudgetTypeChangeWhileInEditMode() {
            this.activePointBudgetRecord.individual_budget_selected_users = [];
            this.activePointBudgetRecord.shared_budget_selected_users = [];
            this.activePointBudgetRecord.shared_budget_selected_site = [];
          },
          saveBudget() {
            this.closeModal();
          },
          limitText (count) {
            return `+${count} others`
          },
          reset_form(){
            this.activePointBudgetRecord = {
              id: null,
              name: "",
              oneOffOrMonthly: null,
              isRolledOver: null,
              holders: [],
              type: "",
              allocation: {
                used: 0,
                remaining: null,
                days_remaining: 30,
              },
              individual_budget_selected_users: [],
              shared_budget_selected_users: [],
              shared_budget_selected_site: [],

              MultipleUserHolderShared: [],
              MultipleUserHolderSeparate: [],
              SingleSiteHolder: null,
            }

            this.singleuseroption =[];
            this.alluseroption = [];
            this.siteoption = [];
            this.selectedOption = "";
            this.content.content_type = null;
            this.content.rollover_type = null;
            this.budgetname = '';
            this.showError = false;
          },

          async getUsersForSeparateBudgetV2(isMonthly, canRollOver) {
            let params;

            if (!isMonthly) {
              params = {
                params: { isMonthly }
              }
            } else {
              params = {
                params: { isMonthly, canRollOver }
              }
            }

            await axios.get(`/points-budgets/get-users-for-separate-budget/${this.currentuser.customer_id}`, params)
              .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {

                  let userArray;

                  if (!isMonthly) {
                    this.oneOffUsersForSeparateBudget = [];
                    userArray = this.oneOffUsersForSeparateBudget;
                  } else if (isMonthly && canRollOver) {
                    this.monthlyRolloverUsersForSeparateBudget = [];
                    userArray = this.monthlyRolloverUsersForSeparateBudget;
                  } else if (isMonthly && !canRollOver) {
                    this.monthlyNonRolloverUsersForSeparateBudget = [];
                    userArray = this.monthlyNonRolloverUsersForSeparateBudget;
                  }

                  response.data.forEach(user => userArray.push({ id: user.id, name: `${user.first_name} ${user.surname}` }));
                }
              })
              .catch(error => {
                let userType;
                if (!isMonthly) {
                  userType = "one-off";
                } else if (isMonthly && canRollOver) {
                  userType = "monthly roll-over";
                } else if (isMonthly && !canRollOver) {
                  userType = "monthly non roll-over";
                }
                console.error(`Error fetching ${userType} users for separate budget type: `, error);
              });
          },

          async getUsersForSharedBudgetV2(isMonthly, canRollOver) {
            let params;

            if (!isMonthly) {
              params = {
                params: { isMonthly }
              }
            } else {
              params = {
                params: { isMonthly, canRollOver }
              }
            }

            await axios.get(`/points-budgets/get-users-for-shared-budget/${this.currentuser.customer_id}`, params)
              .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {

                  let userArray;

                  if (!isMonthly) {
                    this.oneOffUsersForSharedBudget = [];
                    userArray = this.oneOffUsersForSharedBudget;
                  } else if (isMonthly && canRollOver) {
                    this.monthlyRolloverUsersForSharedBudget = [];
                    userArray = this.monthlyRolloverUsersForSharedBudget;
                  } else if (isMonthly && !canRollOver) {
                    this.monthlyNonRolloverUsersForSharedBudget = [];
                    userArray = this.monthlyNonRolloverUsersForSharedBudget;
                  }

                  response.data.forEach(user => userArray.push({ id: user.id, name: `${user.first_name} ${user.surname}` }));
                }
              })
              .catch(error => {
                let userType;
                if (!isMonthly) {
                    userType = "one-off";
                } else if (isMonthly && canRollOver) {
                    userType = "monthly roll-over";
                } else if (isMonthly && !canRollOver) {
                    userType = "monthly non roll-over";
                }
                  console.error(`Error fetching ${userType} users for shared budget type: `, error);
              });
          },

          // async getSitesForSharedBudgetV2(isMonthly, canRollOver) {
          //   let params;
          //
          //   if (!isMonthly) {
          //     params = {
          //       params: { isMonthly }
          //     }
          //   } else {
          //     params = {
          //       params: { isMonthly, canRollOver }
          //     }
          //   }
          //
          //   await axios.get(`/points-budgets/get-sites-for-shared-budget/${this.currentuser.customer_id}`, params)
          //       .then(response => {
          //         if (Array.isArray(response.data) && response.data.length > 0) {
          //           let siteArray;
          //
          //           if (!isMonthly) {
          //             this.oneOffSitesForSharedBudget = [];
          //             siteArray = this.oneOffSitesForSharedBudget;
          //           } else if (isMonthly && canRollOver) {
          //             this.monthlyRolloverSitesForSharedBudget = [];
          //             siteArray = this.monthlyRolloverSitesForSharedBudget;
          //           } else if (isMonthly && !canRollOver) {
          //             this.monthlyNonRolloverSitesForSharedBudget = [];
          //             siteArray = this.monthlyNonRolloverSitesForSharedBudget;
          //           }
          //
          //           response.data.forEach(site => siteArray.push({ id: site.id, name: site.name }));
          //         }
          //       })
          //       .catch(error => {
          //         let userType;
          //         if (!isMonthly) {
          //           userType = "one-off";
          //         } else if (isMonthly && canRollOver) {
          //           userType = "monthly roll-over";
          //         } else if (isMonthly && !canRollOver) {
          //           userType = "monthly non roll-over";
          //         }
          //         console.error(`Error fetching ${userType} sites for shared budget type: `, error);
          //       });
          // },

          async getUsersForSeparateBudget() {
            this.usersForSeparateBudget = [];
            await axios.get(`/points-budgets/get-users-for-separate-budget/${this.currentuser.customer_id}`)
                .then(response => {
                  if (Array.isArray(response.data) && response.data.length > 0) {
                    response.data.forEach(user => this.usersForSeparateBudget.push({ id: user.id, name: `${user.first_name} ${user.surname}` }));
                  }
                })
                .catch(error => {
                  console.error("Error fetching users for separate budget type: ", error);
                });
          },
          async getUsersForSharedBudget() {
            this.usersForSharedBudget = [];
            await axios.get(`/points-budgets/get-users-for-shared-budget/${this.currentuser.customer_id}`)
                .then(response => {
                  if (Array.isArray(response.data) && response.data.length > 0) {
                    response.data.forEach(user => this.usersForSharedBudget.push({ id: user.id, name: `${user.first_name} ${user.surname}` }));
                  }
                })
                .catch(error => {
                  console.error("Error fetching users for shared budget type: ", error);
                });
          },
          async getSitesForSharedBudget() {
            this.sitesForSharedBudget = [];
            await axios.get(`/points-budgets/get-sites-for-shared-budget/${this.currentuser.customer_id}`)
                .then(response => {
                  if (Array.isArray(response.data) && response.data.length > 0) {
                    response.data.forEach(site => this.sitesForSharedBudget.push({ id: site.id, name: site.name }));
                  }
                })
                .catch(error => {
                  console.error("Error fetching sites for shared budget type: ", error);
                });
          },
          async getPointsBudgetsData() {
            await axios.get(`/points-budgets`, {
              params: {
                customer_id: this.currentuser.customer_id
              }
            })
              .then(response => {
                const pointsBudget = JSON.parse(JSON.stringify(response.data));
                this.pointBudgetsData = [];

                pointsBudget.forEach((data) => {
                  this.pointBudgetsData.push({
                    ID: data.id,
                    PriorityOrder: data.priority_order,
                    OneOffOrMonthly: data.is_budget_monthly,
                    IsRolledOver: Boolean(data.can_budget_roll_over),
                    Type: data.points_budgets_type_id,
                    Name: data.name,
                    Holder: {
                      ID: data.holder.id,
                      Name: data.holder.name,
                    },
                    AssociatedUsers: data.associated_users,
                    Icon: data.icon_name,
                    BudgetType: data.budget_type_name,
                    Allocation: {
                      used: data.used_points,
                      remaining: data.remaining_points,
                      days_remaining: data.days_remaining,
                    }
                  });
                });

                this.getUsersForSeparateBudgetV2(0, null);
                this.getUsersForSeparateBudgetV2(1, 1);
                this.getUsersForSeparateBudgetV2(1, 0);

                this.getUsersForSharedBudgetV2(0, null);
                this.getUsersForSharedBudgetV2(1, 1);
                this.getUsersForSharedBudgetV2(1, 0);

                this.getSitesForSharedBudget()

                // this.getSitesForSharedBudgetV2(0, null);
                // this.getSitesForSharedBudgetV2(1, 0);
                // this.getSitesForSharedBudgetV2(1, 0);
              })
              .catch(error => {
                console.error("Error fetching points budgets data: ", error);
              });
          }
        },
        mounted() {
          console.log(Boolean(this.currentuser.account_check));
          this.getPointsBudgetsData();

        },
        computed: {
            //for filteration
            filteredData() {
                if (this.checkedItems.length === 0) {
                    return this.pointBudgetsData;
                }
                return this.pointBudgetsData.filter((row) => {
                    return (
                        row.Type !== 1 && row.AssociatedUsers.some(user => this.checkedItems.includes(user.name)) ||
                        this.checkedItems.includes(row.Holder.Name) ||
                        this.checkedItems.includes(row.Name) ||
                        this.checkedItems.includes(row.BudgetType)
                    )
                });
            },
            filteredRowCount() {
                return this.checkedItems.length;
            },

          budgetRecordToEdit(selectedBudget) {
            return {
              id: selectedBudget.ID,
              name: selectedBudget.Name,
              oneOffOrMonthly: selectedBudget.OneOffOrMonthly,
              isRolledOver: selectedBudget.IsRolledOver,
              type: selectedBudget.Type,
              holders: selectedBudget.MultipleHolder,
              allocation: {
                used: selectedBudget.Allocation.used,
                remaining: selectedBudget.Allocation.remaining,
                days_remaining: selectedBudget.Allocation.days_remaining,
              },
              individual_budget_selected_users: selectedBudget.Type === 1 ? selectedBudget.HolderID : [],
              shared_budget_selected_users: selectedBudget.Type === 2 ? selectedBudget.MultipleHolder : [],
              shared_budget_selected_site: selectedBudget.Type === 3 ? selectedBudget.HolderID : [],
            };
          }
        },
        watch: {
            pointBudgetsData(newVal, oldVal) {
            },
            checkedItems(newVal) {
              this.isReorderFeatureEnabled = newVal.length === 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cf-table thead th[data-v-21078b6e] {
      background: #ECEEF4;
    }
    ::v-deep .multiselect__single, .multiselect__option, .multiselect__content, .multiselect__input {
      font-size: 14px !important;
    }
    .swal-font {
      font-family:'Poppins',sans-serif;
      font-weight: bold;
    }
    .row-count-badge{
        color:#5DC2A6;
        font-weight: 600;
        font-size: 12px;
        font-family: "Poppins", sans-serif;
    }
    .dropdown-action .dropdown-pane ul li a {
        color: #363b54;
        font: 400 14px/40px "Poppins", sans-serif;
        padding: 3px 20px;
        display: list-item;
    }
    .dropdown-action button::after{
        content: "..."
    }
    .table-header{
        position:sticky !important;
        top: calc(0% + 70px) !important;
        z-index:2;
    }
    .dropdown-wrapper {
        display: inline-block;
        border-radius: 12px;
    }
    .icon-container i {
        cursor: pointer;
    }
    .highlight-animation {
        background-color: #5DC2A6;
        transition: background-color 1s;
    }
    .more-action {
        cursor: pointer;
    }
    .more-action-icon{
        display: inline-block;
        margin: 0px 11px -3px 0px;
    }
    td:nth-child(1){
        width:1%;
    }
    td:nth-child(2){
        width:25%;
    }
    td:nth-child(3){
        width:20%;
    }
    td:nth-child(4){
        width:12%;
    }
    td:nth-child(5){
        width:auto;
    }
    td:nth-child(6){
        width:1%;
    }

    .progress {
        display: -ms-flexbox;
        display: flex;
        height: 1rem;
        overflow: hidden;
        line-height: 0;
        font-size: .75rem;
        background-color: #e9ecef;
        border-radius: 0.25rem;
    }
    .progress-bar {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: hidden;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-color: #55c2a7;
        transition: width .6s ease;
    }
    .allocation-used-text {
        color:  #55c2a7;
        float: left;
    }
    .allocation-progress-bar {
        background-color: #55c2a7;
    }
    .point-budget-top:nth-child(1){
        width:70%;
    }
    .point-budget-top:nth-child(2){
        width:30%;
    }
    .cancel_btn {
      font-family: "Poppins", sans-serif;
    }
    .green_btn{
        background-color: #55c2a7;
        border-radius: 20px;
        height: 40px;
        border: 0;
        padding: 0 22px !important;
        color: #ffffff;
        transition: all 0.3s;
        margin-right: 20px;
        transition: all 0.3s;
        letter-spacing: 0.2px;
        font: 700 16px/42px "Poppins", sans-serif;
        box-shadow: 0px 0px rgba(0, 0, 0, 0);
    }
    .green_btn:hover, .green_btn:focus {
        background-color: #41a98c;
        color: #ffffff;
    }
    .green_trans_btn{
        background-color: #ffffff;
        border-radius: 20px;
        height: 40px;
        border: 2px solid #5dc2a6;
        padding: 0 22px !important;
        font: 500 16px/36px "InterUI", sans-serif;
        color: #5dc2a6;
        transition: all 0.3s;

    }
    .green_trans_btn:hover, .green_trans_btn:focus {
        border: 2px solid #41a98c;
        color: #41a98c;

    }
    .point-budget-header{
    margin: 0px 0px 25px 0px;
    }
    .point-budget-top .find-out-more{
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: #3C3C3C;
    }
    .point-budget-top .find-out-more a{
        color:#5dc2a6;
    }
    .table-top-area{
        position:sticky !important;
        top:0 !important;
        background-color: #f7f8fc !important;
        z-index: 3;
    }
    .cf-table thead {
        background-color: #fafafc !important;
    }
    .cf-table thead th {
        background: #fafafc;
        letter-spacing: 0.2px;
        color: #545454 !important;
        font: 600 12px/16px "Poppins", sans-serif !important;
        text-transform: uppercase;
        padding: 20px 25px 19px 25px;
    }
    .cf-table tbody tr td {
        color: #545454;
        font: 400 14px/20px "Poppins", sans-serif !important;
        padding: 10px 24px;
    }
    table.unstriped tbody tr {
        border: 1px solid #f2f2f2 !important;
        background-color: #ffffff;
    }
    table.unstriped tbody tr:hover{
        box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
        border-bottom: 1px solid #5dc2a6 !important;
        background-color: #EFEFEF !important;
    }
    table.unstriped tbody tr:active{
        box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
        cursor:grabbing !important;
        border-bottom: 1px solid #5dc2a6 !important;
        background-color: #EFEFEF !important;
        z-index:1;
    }
    table.unstriped tbody td:nth-child(1):hover  {
        cursor:grabbing;
    }
    #point-budgets .dropdown-action button {
        width: unset;
        height: unset;
        background: unset;
        border-radius: unset;
        text-indent: unset;
        transform: unset;
    }

    #point-budgets .dropdown-action button::after {
        content: " ";
    }
</style>



