<template>
    <div class="dropdown-container"
         ref="dropdown"
         :class="{'empty-input': applyRedBorder}">
        <div class="selected-option"
             @click="toggleDropdown"
             :style="{color: checkedItems.length === 0 ? '#cacaca' : '#3C3C3C'}">
            {{ displaySelectedItems }}
            <img :class="['arrow', { 'arrow-up': isOpen }]"
                 src="/images/svg/dropdown_icon.svg"
                 alt="Dropdown arrow">
        </div>

        <transition name="fade">
            <ul v-if="isOpen"
                class="options-list">
                <li class="single-option"
                    :key="0">
                    <input ref="filterMultiSearchInput"
                           class="filter-search-input"
                           type="text"
                           v-model="searchKeyword"
                           placeholder="Enter name to filter" />
                </li>
                <li class="single-option"
                    v-for="item in filteredOptions"
                    :key="item.id">
                    <div>
                        <label id="optionLabel">
                            <input type="checkbox"
                                   class="custom-checkbox"
                                   v-model="checkedItems"
                                   :value="item.id">
                            <span class="checkmark"></span>
                            {{ item.name }}
                        </label>
                    </div>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        options: { type: Array, required: true },
        placeholder: { type: String, default: "Select items..." },
        value: { type: Array }
    },
    data() {
        return {
            isOpen: false,
            checkedItems: [],
            applyRedBorder: false,
            searchKeyword: '',
            filteredOptions: this.options
        };
    },
    computed: {
        displaySelectedItems() {
            const firstItem = this.options.find(option => option.id === this.checkedItems[0]);
            if (this.checkedItems.length === 0) return this.placeholder;
            if (this.checkedItems.length === 1) return firstItem.name;
            return `${firstItem.name}, +${this.checkedItems.length - 1} others`;
        }
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
            if (this.isOpen) {
                this.$nextTick(() => {
                    this.$refs.filterMultiSearchInput.focus();
                });
            }
        },
        handleOutsideClick(event) {
            if (this.isOpen && !this.$refs.dropdown.contains(event.target)) {
                this.isOpen = false;
            }
        },
        filterOptions() {
            const query = this.searchKeyword.toLowerCase().trim();
            if (!query) {
                this.filteredOptions = this.options;
            } else {
                this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(query));
            }
        },
    },
    mounted() {
        document.addEventListener("mousedown", this.handleOutsideClick);
    },
    beforeDestroy() {
        document.removeEventListener("mousedown", this.handleOutsideClick);
    },
    watch: {
        checkedItems(newVal) {
            this.$emit('input', newVal);
        },
        value(newVal) {
            this.checkedItems = newVal;
        },
        searchKeyword() {
            this.filterOptions();
        },
        options() {
            this.filterOptions();
        },
    }
}
</script>
<style scoped>
@import "./MultiSelect.scss";
</style>