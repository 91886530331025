<template>
    <div>
        <h6>Search App Users and/or Candidates to View/Delete them.</h6>

        <div class="grid-x">
            <div class="cell auto font_0875">
                <div class="grid-x">
                    <div class="cell small-5">
                        <input class="jobs-search" placeholder="Search: Enter atleast three characters"
                               v-model="searchVar" name="searchVar"
                               @keyup.delete="searchSuperData()" @keyup.enter="searchSuper()"/>
                    </div>
                </div>
            </div>
        </div>
		<div v-show="showLoad" class="show-load-search">
            <img :src="myImage"/>
        </div>
        <div class="cf-table client-table">
        <div class="grid-x grid-padding-x cf-table-head t_margin_1 align-middle">
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>Name</strong></p>
            </div>
            <div class="cell medium-3 white">
                <p class="tb_margin_1"><strong>Email</strong></p>
            </div>
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>Mobile Number</strong></p>
            </div>
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>Organisation</strong></p>
            </div>
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>Site</strong></p>
            </div>
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>Type</strong></p>
            </div>
			<div class="cell auto white">
                <p class="tb_margin_1"><strong>Time stamp</strong></p>
            </div>
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>Current balance</strong></p>
            </div>
            <div class="cell medium-1"></div>
        </div>
        </div>
        <!-- LEADERBOARD TABLE - SINGLE ROW (NO MOVE) -->

        <template v-for="(result, index) in results">
            <div :key="index" class="grid-x grid-padding-x align-middle b_border_b1">
                <div class="cell auto">
                    <p class="tb_margin_1"><strong>{{ result.first_name }} {{ result.surname }}</strong></p>
                </div>
                <div class="cell medium-3">
                    <p class="tb_margin_1" v-if="result.status=='Leaver' || result.mark_as_leaver ==1">
                    </p>
					<p class="tb_margin_1" v-else><a :href="'mailto:' + result.email"><strong>{{ result.email }}</strong></a>
                    </p>
                </div>
                <div class="cell auto">
                    <p class="tb_margin_1"><strong>{{ result.phone_number }}</strong></p>
                </div>
                <div class="cell auto">
                    <p class="tb_margin_1"><strong>{{ result.organisation }}</strong></p>
                </div>
                <div class="cell auto">
                    <p class="tb_margin_1"><strong>{{ result.siteName }}</strong></p>
                </div>
                <div class="cell auto">
                    <p class="tb_margin_1"><strong>{{ result.type }}</strong></p>
                </div>
				<div class="cell auto">
                    <p class="tb_margin_1"><strong>{{ result.created_at }}</strong></p>
                </div>
                <div class="cell auto">
                    <p class="tb_margin_1"><strong>{{ result.balance }}</strong></p>
                </div>
                <div class="cell medium-1 dropdown-action" align="center">
                    <button class="dropdown button" :id="'d-' + index" type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false"></button>
                    <div class="dropdown-menu dropdown-pane radius-3" :aria-labelledby="'d-' + index">
                        <ul>
                            <li v-if="result.type == 'Live user' || result.type == 'Deactivated'">
                                <a v-b-modal="'modal-' + index" @click="viewProfile(result.id, result.type)">
                                    View profile
                                </a>
                            </li>

                            <li v-else-if="result.type == 'Candidate'">
                                <a v-b-modal="'modal-view-' + index" @click="viewProfile(result.id, result.type)">
                                    View profile
                                </a>
                            </li>

                            <li v-if="result.type === 'Leaver'">
                                <a v-b-modal="'revert-user-' + index">
                                    Revert from leaver
                                </a>
                            </li>

                            <li v-if="result.type == 'Live user' || result.type == 'Deactivated' || result.type == 'Authorised' || result.type == 'Invitee' || result.type == 'Leaver'">
                                <a v-b-modal="'delete-user-' + index" @click="deleteUser(result.type, !!result.uuid)">
                                    Delete
                                </a>
                            </li>

                            <li v-else-if="result.type == 'Candidate'">
                                <a v-b-modal="'delete-candidate-' + index" @click="deleteUser(result.type)">
                                    Delete
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <b-modal v-if="editMode" :id="'modal-' + index" size="xl" hide-footer>
                <profile-card-component 
                    :user="result"
                    :isCareSector="isCareSector"
                    :taxName="result.tax_name"
                >
                </profile-card-component>
            </b-modal>

            <b-modal v-if="viewCandidate" hide-header hide-footer :id="'modal-view-' + index" size="xl"
                     :title="result.First_Name+' '+result.Surname" modal-class="candidate-view">
                <div class="candidate-view-model-border">
                    <div class="grid-x grid-padding-x candidate-view-model-header-border">
                        <div class="cell auto">
                            <div style="float:left">
                                <h5>{{ result.First_Name}} {{ result.Surname}}</h5>
                            </div>
                            <div data-dismiss="modal" class="close btnclose" style="margin-left: 76%;"><strong>
                                <div id="toasts" style="float:left;padding-right:15px"
                                     @click="closemodel('modal-view-' + index)">
                                    <i class="fa fa-times fa-lg" aria-hidden="true"></i>
                                </div>
                            </strong></div>
                        </div>
                    </div>
					 <div class="grid-x grid-padding-x align-middle candidate-card-padding">
                        <div class="cell auto">
                            <strong>ID: {{result.id}}</strong>
                        </div>
                        <div class="cell auto">

                        </div>
                    </div>
                    <div class="grid-x grid-padding-x align-middle candidate-card-padding">
                        <div class="cell auto">
                            <strong><i class="fa fa-phone"
                                       aria-hidden="true"></i>&nbsp;{{result.Phone_Number}}</strong>
                        </div>
                        <div class="cell auto">
                            <strong><i class="fa fa-at fa-lg" aria-hidden="true"></i>&nbsp;<a
                                :href="'mailto:'+result.Email">{{ result.Email }}</a></strong>
                        </div>
                    </div>
                    <div class="grid-x grid-padding-x align-middle candidate-card-padding">
                        <div class="cell auto">
                            <strong>Role applied for: {{ result.job_title }}</strong>
                        </div>
                        <div class="cell auto">
                            <strong>Site: {{ (result.siteName )? result.siteName : "Company Wide"}}</strong>
                        </div>
                    </div>
                    <div class="grid-x grid-padding-x align-middle candidate-card-padding">
                        <div class="cell auto">
                            <strong>Referred by: {{ result.referrer }}</strong>
                        </div>
                        <div class="cell auto">
                            <strong> Expressed interest on: {{ result.created_at | moment("DD.MM.YYYY") }}</strong>
                        </div>
                    </div>
                    <div v-if="result.Postcode && !result.Living_Overseas"
                         class="grid-x grid-padding-x align-middle candidate-card-padding">
                        <div class="cell auto">
                            <strong>Postal code: {{ result.Postcode }}
                            </strong>
                        </div>
                    </div>
                    <div class="grid-x grid-padding-x align-middle">
                        <div class="cell auto">
                            <strong> New to Care: {{ result.newToCare }}</strong>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal :ref="'revert-user-' + index" class="tab-modal" :id="'revert-user-' + index" size="xl">
                <template #default="{ cancel }">
                    <div class="grid-y">
                        <p>Are you sure you want to revert <strong>{{ result.first_name }} {{ result.surname }}</strong> from leaver?</p>
                    </div>
                    
                    <div class="cell auto popup-button">
                        <input class="delete-candidate" type="submit" value="Cancel" @click="cancel()">
                        <input type="button" class="button btn_lg btn orange_btn delete-candidate anon-can" value="Revert" @click="revertFromLeaver(result.id, index)">
                    </div>
                </template>
            </b-modal>

			<b-modal v-if="deleteInvite" class="tab-modal" :id="'delete-user-' + index" size="xl">
                <div class="grid-y">
                    <p>
                        <br>Are you sure you want to delete this Invitee user?
                    </p>
                </div>
                <div class="cell auto popup-button">
                    <input class="delete-candidate" type="submit" value="Cancel" @click="deleteInvite = false">
                    <input type="button" class="button btn_lg btn orange_btn delete-candidate anon-can" value="Delete"
                           @click="deleteData(result.id, 3)">
                </div>
            </b-modal>

            <b-modal v-if="deleteApp" class="tab-modal" :id="'delete-user-' + index" size="xl">
                <div class="grid-y">
                    <p>
                        <br>Are you sure you want to delete this app user? This will result in:<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;The user no longer being able to log in to the
                        app. If they are
                        logged in currently they will be<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;forced
                        to log out<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;Any points that the app user has in their
                        current balance will be
                        deleted and will no longer<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contribute
                        to client points
                        totals<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;Any points that the app user has been paid will
                        also be removed –
                        this means the ‘total points<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;marked as
                        paid’ for the
                        client will change<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;Any candidates referred by the candidate will
                        remain, but will be
                        assigned to ‘deleted app<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;user’<br>
                    </p>
                </div>
                <div class="cell auto popup-button">
                    <input class="delete-candidate" type="submit" value="Cancel" @click="deleteApp = false">
                    <input type="button" class="button btn_lg btn orange_btn delete-candidate anon-can" value="Delete"
                           @click="deleteData(result.id, 1)">
                </div>
            </b-modal>

            <b-modal v-if="deleteCandidate" class="tab-modal" :id="'delete-candidate-' + index" size="xl">
                <div class="grid-y">
                    <p>
                        <br>Are you sure you want to delete this candidate? This will result in:<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;Any points awarded for that candidate will be
                        deleted from the referring app user<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;The candidate will no longer be able to be
                        progressed, and their contact details will be lost<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;The candidate will no longer count towards any
                        referral analytics/statistics for the client<br>
                    </p>
                </div>
                <div class="cell auto popup-button">
                    <input class="delete-candidate" type="submit" value="Cancel" @click="deleteCandidate = false">
                    <input type="button" class="button btn_lg btn orange_btn delete-candidate anon-can" value="Delete"
                           @click="deleteData(result.id, 2)">
                </div>
            </b-modal>
        </template>
        <template v-if="paginationData.last_page > 1">
            <paginate
                v-model="paginationData.current_page"
                :page-count="paginationData.last_page"
                :page-range="3"
                :margin-pages="2"
                :click-handler="paginatorClickCallback"
                :prev-text="'<'"
                :next-text="'>'"
                :prev-class="'paginate-controls'"
                :next-class="'paginate-controls'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :active-class="'active'"
            ></paginate>
        </template>
    </div>
</template>
<script>
    import Paginate from "vuejs-paginate";

    export default {
        components: {paginate: Paginate},
        props: ['result', 'portal', 'candidate', 'search', 'isCareSector'],
        data() {
            return {
                searchVar: (this.search) ? this.search : '',
                results: [],
                paginationData: {
                    current_page: 0,
                    from: 0,
                    last_page: 0,
                    per_page: 0,
                    to: 0,
                    total: 0
                },
                myImage: "/images/loading-circle.gif",
                showLoad: false,
                viewPointsData: [],
                balance: [],
                editMode: false,
                leaderboardValues: [],
                pointValues: [],
                site_name: null,
                viewCandidate: false,
                deleteApp: false,
                deleteInvite: false,
                deleteCandidate: false
            }
        },
        methods: {
            searchSuper() {
                if (this.searchVar.length > 1) {

				   var self = this;
                    self.showLoad = true;

                    axios.post('/dashboard/super/search-data', {searchVar: this.searchVar})
                        .then(function (response) {
                            if (response.data.result && response.data.portal && response.data.candidate) {
                                $(self.$el).foundation();
                                self.results = response.data.result;
                                self.paginationData.current_page = response.data.portal.current_page;
                                self.paginationData.from = (response.data.portal.from + response.data.candidate.from) - 1;
                                self.paginationData.last_page = Math.ceil((response.data.portal.total + response.data.candidate.total) / 20);
                                self.paginationData.per_page = 20;
                                self.paginationData.to = 20;
                                self.paginationData.total = response.data.portal.total + response.data.candidate.total;
                            } else if (response.data.result.data) {
                                $(self.$el).foundation();
                                self.results = response.data.result.data;
                                self.paginationData.current_page = response.data.result.current_page;
                                self.paginationData.from = response.data.result.from;
                                self.paginationData.last_page = response.data.result.last_page;
                                self.paginationData.per_page = response.data.result.per_page;
                                self.paginationData.to = response.data.result.to;
                                self.paginationData.total = response.data.result.total;
                            }
							self.showLoad = false;
                        })
                        .catch(function (error) {
                            console.log(error);
                        })

                         //self.showLoad = false;

                }

            },
            searchSuperData() {
			        this.results = [];
                    this.paginationData.current_page = 0;
                    this.paginationData.from = 0;
                    this.paginationData.last_page = 0;
                    this.paginationData.per_page = 0;
                    this.paginationData.to = 0;
                    this.paginationData.total = 0;
                /*if (this.searchVar.length <= 0) {
                    this.results = [];
                    this.paginationData.current_page = 0;
                    this.paginationData.from = 0;
                    this.paginationData.last_page = 0;
                    this.paginationData.per_page = 0;
                    this.paginationData.to = 0;
                    this.paginationData.total = 0;
                } else {
                    this.searchSuper();
                }*/
            },
            paginatorClickCallback: function (pageNum) {
                window.location = window.location.origin + "/dashboard/super/search?page=" + pageNum + "&search=" +
                    this.searchVar;
                return false;
            },
            viewProfile(id, type) {
			    if (type == 'Candidate') {
                    this.viewCandidate = true;
                }
                else{
                    this.getUserInfo(id);
                    this.viewPoints();
                }
            },
            getUserInfo(id) {
                var self = this;
                axios.post('/dashboard/get-user-info', {id: id})
                    .then(function (response) {
                        if (response.data.info) {
                            self.leaderboardValues = response.data.info.leaderboardValues;
                            self.pointValues = response.data.info.pointValues;
                            self.site_name = response.data.info.site_name;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            viewPoints() {
                this.editMode = true;
            },
            closemodel(id) {
                this.$bvModal.hide(id);
            },
            deleteUser(type, isAppUser) {
                if (type == 'Candidate') {
                    this.deleteCandidate = true;
                }else if (isAppUser && (type == 'Live user' || type == 'Deactivated' || type == 'Leaver') ) {
                    this.deleteApp = true;
                }else{
				   this.deleteInvite = true;
				}
            },
            deleteData(id, type) {
                Vue.swal({
                    title: 'Deleting...',
                    html: '',
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });
                var self = this;
                var check = false;
                axios.post('/dashboard/super/delete', {id: id, type: type})
                    .then(function (response) {
                        if (response.data) {
                            self.searchSuper();
                            check = true;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .finally(function () {
                        if (check) {
                            self.deleteApp = false;
                            self.deleteInvite = false;
                            self.deleteCandidate = false;
                            Vue.swal({
                                html:
                                    '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Deleted successfully',
                                confirmButtonColor: "#5dc2a6",
                                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                background: "#5dc2a6",
                                customClass: {
                                    container: "candidate-toast"
                                },
                                showClass: {
                                    popup: "animated fadeInLeft fast",
                                    icon: "animated heartBeat delay-1s"
                                },
                                hideClass: {
                                    popup: "animated fadeOutLeft slow"
                                },
                                toast: true,
                                timer: 5000,
                                position: "top-right",
                                target: "#example-tabs"
                            });
                        }
                    });

            },
			getPostBody (post) {
		        let body = this.stripTags(post);
		        return body.length > 15 ? body.substring(0, 15) + '...' : body;
	        },
	        stripTags (markup) {
				const temp = document.createElement("div");
				temp.innerHTML = markup;
				return temp.textContent || temp.innerText || "";
			},
            revertFromLeaver(id, index) {
                Vue.swal({
                    title: 'Reverting...',
                    html: '',
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });

                const user = this.results.find(elem => elem.id === id);
                let type = 'user';

                if (user.isInvitee) {
                    type = 'invitee'
                }

                axios.post('/dashboard/super/revert-user-from-leaver', { id, type })
                    .then((response) => {
                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Reverted successfully',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                        
                        this.searchSuper();
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.$bvModal.hide('revert-user-' + index);
                    })
            }
        },
        mounted() {
            if (this.result && this.portal && this.candidate) {
                $(this.$el).foundation();
                this.results = this.result;
                this.paginationData.current_page = this.portal.current_page;
                this.paginationData.from = (this.portal.from + this.candidate.from) - 1;
                this.paginationData.last_page = Math.ceil((this.portal.total + this.candidate.total) / 20);
                this.paginationData.per_page = 20;
                this.paginationData.to = 20;
                this.paginationData.total = this.portal.total + this.candidate.total;
            } else if (this.result.data) {
                $(this.$el).foundation();
                this.results = this.result.data;
                this.paginationData.current_page = this.result.current_page;
                this.paginationData.from = this.result.from;
                this.paginationData.last_page = this.result.last_page;
                this.paginationData.per_page = this.result.per_page;
                this.paginationData.to = this.result.to;
                this.paginationData.total = this.result.total;
            }
        }
    }
</script>
<style>
.show-load-search {
    position: fixed;
    top: 32%;
    left: 50%;
    width: 100%;
}
</style>
