<template>
  <h2><span v-if="prefix" class="prefix">{{ prefix }}</span> <slot /></h2>
</template>

<style scoped>
  h2 {
    font-size: 22px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .prefix {
    color: #5DC2A6;
    font-size: 32px;
  }
</style>

<script>
  export default {
    props: {
      prefix: String
    }
  }
</script>
