<script>
    export default {
        name: "SiteOneOffBudgetIcon",
    };
</script>

<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_80_5235)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 5V11H19.5C20.5314 11 21.387 11.7913 21.4897 12.7967C20.6095 12.2899 19.5886 12 18.5 12C17.2733 12 16.1325 12.3682 15.1822 13H13.5V14.6822C13.0436 15.3686 12.7248 16.1543 12.583 17H11.5V20C11.5 20.55 11.05 21 10.5 21H5.5C4.4 21 3.5 20.1 3.5 19V9C3.5 7.9 4.4 7 5.5 7H7.5V5C7.5 3.9 8.4 3 9.5 3H15.5C16.6 3 17.5 3.9 17.5 5ZM5.5 19H7.5V17H5.5V19ZM5.5 15H7.5V13H5.5V15ZM5.5 11H7.5V9H5.5V11ZM9.5 15H11.5V13H9.5V15ZM9.5 11H11.5V9H9.5V11ZM9.5 7H11.5V5H9.5V7ZM13.5 11H15.5V9H13.5V11ZM13.5 7H15.5V5H13.5V7Z" fill="#3C3C3C"/>
        <circle cx="18.5" cy="18" r="4.5" stroke="#3C3C3C"/>
        <path d="M17.3731 16.503V15.39H19.3121V20.5H18.0661V16.503H17.3731Z" fill="#3C3C3C"/>
        </g>
        <defs>
        <clipPath id="clip0_80_5235">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
        </defs>
    </svg>
</template>
