<template>
    <div>
        <label v-if="!notShowLabel" class="bonus-label">SELECT USERS</label>
        <v-select class="phone-recruiter"  placeholder="Add user" v-model="user_id" :reduce="referrer => referrer.id" label="name"
                  :options="availableUsers"
				  :close-on-select="true"
				  @input="selectedUser(user_id)"
                  multiple></v-select>
        <input type="hidden" name="user_id" :value="user_id">
		<div class="input-tagged">
			    <span v-for="(element, index) in selected_notify_tags">
			        <div class=" tag-input__tag tag-right">
				       <span class="lablesize">{{ element }}</span>
				       <span class="deselect-pad"><button class="deselect" @click="removeNotiTags(index)"></button></span>
				    </div>
				</span>
			</div>
    </div>

</template>

<script>
    export default {
        props: ['users', 'notShowLabel'],
        data() {
            return {
                availableUsers: [],
                user_id: '',
            }
        },
        watch: {
            user_id: function (val) {
                this.$emit('selectedUser', this.user_id)
            }
        },
        methods: {
            getAvailableUsers() {
                //Create array of available users for dropdown
                var toProcess = Object.keys(this.users);

                for (var i = toProcess.length - 1; i >= 0; i--) {
                    var user = {
                        name: this.users[toProcess[i]].first_name + " " + this.users[toProcess[i]].surname,
                        id: this.users[toProcess[i]].id
                    }
                    this.availableUsers.push(user);
                }
            },
		selectedUser: function(value) {
          this.userId = value;
          if (this.userCount > 0 && this.userId.length > 0 && this.userId.length < this.userCount) {
              if(this.userId.length % 2 == 0) {
                  this.val -= 3;
              }
              this.userCount = this.userId.length;
          } else if (this.userId.length == 0) {
              this.val = 3;
              this.userCount = 0;
          } else if (this.userId) {
              if(this.userId.length % 2 == 0) {
                  this.val += 3;
              }
              this.userCount = this.userId.length;
          }

		   /**
             * Code to managed selected tags
             */
            let selected_notify = [];
            let i;
            for (i = 0; i < this.availableUsers.length; ++i) {
                if($.inArray( this.availableUsers[i].id, this.userId ) !== -1){
              //console.log('selected name: ', this.notifyuser[i].name);
                 selected_notify.push(this.availableUsers[i].name);
                }
            }
            if(selected_notify.length > 0){
                this.selected_notify_tags = selected_notify;
            }
            /**
             * Ends Code to managed selected tags
             */

           },
		   removeNotiTags: function(index){

					 if (index !== -1) {
					   this.selected_notify_tags.splice(index, 1);
					   this.user_id.splice(index, 1);
				     }

         },
        },
        mounted() {
            this.getAvailableUsers();
        },
        computed: {
            isShow: function() {
                if(this.user_id.length == 0) {
                    return false;
                } else if(this.user_id){
                    return true;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
   .input-tagged{
    float: left;
	display: flex;
	flex-wrap: wrap;
	margin-top:6px;
	flex-basis: 100%;
    flex-grow: 1;
   }
   .tag-right{
     margin-right:5px;
   }
</style>
