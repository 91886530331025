<template>
	<div class=""></div>
</template>
<script>
export default {
	props: ["rescode", "phone"],
	data() {
		return {
			messagedata: this.rescode,
			phonenum: this.phone,
		}
	},
	methods: {
		getMessage: function () {
			if (this.messagedata == 200) {

				Vue.swal({
					title: "Whoops! It looks like that link has expired. Please contact " + this.phonenum.name + " who will be able to send a new one to you.",
					imageWidth: 400,
					imageHeight: 200,
					confirmButtonColor: '#f55d73',
					confirmButtonText: 'OK',
					customClass: {
						container: 'alert-message',
						confirmButton: 'button pink_btn b_margin_0 no_wrap_btn',
						cancelButton: 'button orange_btn b_margin_0 no_wrap_btn'
					},

				});
			}
			else {
				Vue.swal({
					title: "No action set, please select who you want to send the notification to",
					text: "Resubmit",
					type: "warning",


				});
			}
		}
	},
	created: function () {
		this.getMessage();
	}



}
</script>
