<script>
export default {
    name: 'TemplatesIcon'
}
</script>

<template>
    <svg width="14"
         height="14"
         viewBox="0 0 14 14"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g id="Group 604">
            <path id="Line 9"
                  d="M3.5 1.00043C3.5 0.724285 3.27614 0.500427 3 0.500427C2.72386 0.500427 2.5 0.724285 2.5 1.00043H3.5ZM3.5 3.00043V1.00043H2.5V3.00043H3.5Z"
                  fill="#6A6F8E" />
            <path id="Line 10"
                  d="M3.00002 0.500016C2.72388 0.500005 2.50002 0.723853 2.5 0.999995C2.49999 1.27614 2.72384 1.5 2.99998 1.50002L3.00002 0.500016ZM13 0.500427L3.00002 0.500016L2.99998 1.50002L13 1.50043L13 0.500427Z"
                  fill="#6A6F8E" />
            <path id="Line 14"
                  d="M1.00002 12.5C0.723882 12.5 0.500015 12.7239 0.500004 13C0.499992 13.2761 0.723841 13.5 0.999983 13.5L1.00002 12.5ZM11 12.5004L1.00002 12.5L0.999983 13.5L11 13.5004L11 12.5004Z"
                  fill="#6A6F8E" />
            <path id="Line 13"
                  d="M1.00002 2.50002C0.723882 2.5 0.500015 2.72385 0.500004 3C0.499992 3.27614 0.723841 3.5 0.999983 3.50002L1.00002 2.50002ZM11 2.50043L1.00002 2.50002L0.999983 3.50002L11 3.50043L11 2.50043Z"
                  fill="#6A6F8E" />
            <path id="Line 11"
                  d="M13 1.00043V11.0004"
                  stroke="#6A6F8E"
                  stroke-linecap="round" />
            <path id="Line 16"
                  d="M1 3.00043L1 13.0004"
                  stroke="#6A6F8E"
                  stroke-linecap="round" />
            <path id="Line 17"
                  d="M9 7.99066L3.00003 8.00961"
                  stroke="#6A6F8E"
                  stroke-linecap="round" />
            <path id="Line 18"
                  d="M5.979 5.00043L6.02103 11.0003"
                  stroke="#6A6F8E"
                  stroke-linecap="round" />
            <path id="Line 15"
                  d="M11 3.00043V13.0004"
                  stroke="#6A6F8E"
                  stroke-linecap="round" />
            <path id="Line 12"
                  d="M11 11.0004H13"
                  stroke="#6A6F8E"
                  stroke-linecap="round" />
        </g>
    </svg>
</template>