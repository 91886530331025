<template>
    <div>
	    <!--<div class="grid-x grid-padding-x" style="margin-top:-74px;position: relative; float:right;">
            <div class="cell auto" >
                <button
                    v-b-modal.reset
                    class="button pink_btn b_margin_0 no_wrap_btn job-add" style="float: right; padding: 0px 53.7px !important;" @click="deleteCheck=true"
                >Reset</button>
            </div>
        </div>-->

        <div v-show="showLoad" class="show-load-app">
              <img :src="myImage">
        </div>

        <!-- FILTERS -->
        <div class="grid-x " style="background:#fff;border-top-left-radius:8px; border-top-right-radius:8px;">
		<div class="cell auto font_0875 no-padding">
                <div class="grid-x" style="margin-bottom: -10px;">
            <div class="cell small-3 pull-left" >
			    <div class="total_inline">
					<div class="tot-num-bonus">{{ ranking | moment("MMMM YYYY") }}
					</div>
					<div class="total-points-bonus">Current leaderboard period
					</div>
				</div>
            </div>
			<div class="cell small-9 pull-right bonus-history-top" style="padding-top: 12px;padding-right: 12px;">
			<div class="cell shrink"  v-if="sitesInviteOptions.length==4">
                <a
                  href="javascript:void(0)" style="height: 36px;"
                  class="btn button orange_btn float-right bonus-filter-cls"
                  @click="filtertoggle()"
                >
                  FILTERS <img src="/images/filter-icon.png" alt="img"
                /></a>
            </div>
            <div class="cell shrink">
                <a style="height: 36px;" class="btn button orange_btn float-right"  :href="'/dashboard/current-leaderboardexport?searchVar='+ searchVar+'&column='+headData+'&filterStartDate=' +filterStartDate+'&filterEndDate=' +filterEndDate+'&statusFilter[]=' +statusFilter+'&siteFilter[]='+siteComparison+'&column_name='+currentSort+'&sort='+currentSortDir+'&siteComparison='+siteComparison"  @click="exportcsv" > EXPORT </a>
            </div>
			<div class="cell shrink">
			<div class="float-right" style="padding-right: 12px;">

			<div class="cell small-2 rrpage" style="width: 250px;" v-if="allSites.length>0">
              <div class="input-box rrlead">
                <v-select
				  class="style-chooser"
                  v-model="siteComparison"
                  :reduce="(referrer) => referrer.id"
                  label="name"
                  :options="siteselection"
                  :close-on-select="true"
				  @input="changeRoute()"
                >
                </v-select>
              </div>
            </div>

			</div>
			</div>
			</div>
			</div>
			</div>
        </div>
		<!-- filter start-->



            <!-- LEADERBOARD HEADER-->
		<div style="margin-top:0px;" :class="sitecls" class="cf-table">
        <div
          class="grid-x grid-padding-x cf-table-head red_bg t_margin_1 align-middle">
                <div class="cell medium-1" @click="sort('pos')">
                    <p class="tb_margin_1" style="text-align:left;width: 66px;" >
                        <strong>POS. <i v-bind:class="{'fa fa-sort-asc': pos_asc, 'fa fa-sort-desc': pos_desc }"></i></strong>
					</p>
				</div>
				<div @click="sort('name')" class="cell auto white">
					<p class="tb_margin_1"  style="text-align:left;margin-left: -5px !important;" >
                        <strong>Name <i v-bind:class="{'fa fa-sort-asc': name_asc, 'fa fa-sort-desc': name_desc }"></i></strong>
                    </p>
				</div>
	            <div @click="sort('total_shares')" class="cell auto white">
					<p class="tb_margin_1" >
                        <strong>Shares <i v-bind:class="{'fa fa-sort-asc': total_shares_asc, 'fa fa-sort-desc': total_shares_desc }"></i></strong>
                    </p>
				</div>
				<div @click="sort('candidates')" class="cell auto white">
					<p class="tb_margin_1">
					<strong>Candidates <i v-bind:class="{'fa fa-sort-asc': candidates_asc, 'fa fa-sort-desc': candidates_desc }"></i></strong>
                    </p>
				</div>
				<div @click="sort('starters')" class="cell auto white">
					<p class="tb_margin_1">
                        <strong>Starters <i v-bind:class="{'fa fa-sort-asc': starters_asc, 'fa fa-sort-desc': starters_desc }"></i></strong>
                    </p>
				</div>
				<div @click="sort('new_to_care')" class="cell auto white">
					<p class="tb_margin_1">
                       <strong>New to care <i v-bind:class="{'fa fa-sort-asc': interview_sucess_asc, 'fa fa-sort-desc': interview_sucess_desc }"></i></strong>
                    </p>
				</div>
				<div @click="sort('bonus_points')" class="cell auto white">
					<p class="tb_margin_1">
                        <strong>Bonus Points <i v-bind:class="{'fa fa-sort-asc': bonus_points_asc, 'fa fa-sort-desc': bonus_points_desc }"></i></strong>
                    </p>
				</div>
				<div @click="sort('referral_points')" class="cell auto white">
					<p class="tb_margin_1">
                        <strong>Referral points <i v-bind:class="{'fa fa-sort-asc': referral_points_asc, 'fa fa-sort-desc': referral_points_desc }"></i></strong>

                    </p>
				</div>
            </div>
            <!--<div class="maindivsite leaderboard-container-ss">-->
			<div v-if="values.length == 0 && query==true"  class="empty-state empty-state-back">
                    <img src="/images/icon-empty-state-points.png">
                    <p>{{ message }}</p>
                    <br>
            </div>
                <div v-for="(value, index) in values" :key="value.id" v-if="values.length>0">
                    <!-- LEADERBOARD TABLE - SINGLE ROW (NO MOVE) -->
                    <div class="grid-x grid-padding-x align-middle b_border_b1">
					    <div class="cell medium-1">
                            <p class="tb_margin_1" style="text-align:left;padding-left:5px;">

                               <div v-for="posi in positions" v-if="posi.uid==value.id" class="positions-lead">
                                {{ getNumberWithOrdinal(posi.pos + 1, posi.points) }}
                            </div>

                            </p>
						</div>
						<div class="cell auto">
                       <p class="tb_margin_1">
                            <p class="tb_margin_1 cursor-custom" v-b-modal="'modal-leaderboard-' +  value.id" style="text-align:left; word-wrap: break-word;" v-if="siteComparison !=1">
                                <strong class="user-clickable">{{ value.first_name }} {{ value.surname }}</strong></p>
							 <p class="tb_margin_1 cursor-custom" style="text-align:left; word-wrap: break-word;" v-else>
                                <strong class="user-clickable">{{ value.sitename }}</strong></p>
                        </p>
					    </div>
						<div class="cell auto">
                        <p class="tb_margin_1">{{ value.total_shares }}</p>
                        </div>
						<div class="cell auto">
                        <p class="tb_margin_1">{{ value.candidates }}</p>
                       </div>
						<div class="cell auto">
                        <p class="tb_margin_1">{{ value.starters }}</p>
                        </div>
						<div class="cell auto">
						<p class="tb_margin_1">{{ value.new_to_care }}</p>
						</div>
						<div class="cell auto">
						<p class="tb_margin_1">{{ value.bonus_points }}</p>
						</div>
						<div class="cell auto">
                        <p class="tb_margin_1">{{ value.referral_points }}</p>
                        </div>

					  <!--header row end -->

                    </div>
                    <!--<div v-if="editMode">-->
                        <b-modal :id="'modal-leaderboard-' + value.id" size="xl" hide-footer>
                            <profile-card-component 
								:user="value"
								:isCareSector="isCareSector"
                                :taxName="taxName"
							></profile-card-component>

                        </b-modal>
                    <!--</div>-->
                </div>
            <!--</div>-->
			</div>

			<b-modal v-if="deleteCheck" class="tab-modal" id="reset" size="lg">
                <h3>Are you sure?</h3>
                    <div class="grid-y">
                        <div class="grid-x input-box-2">
                            <div class="cell auto input-box">
                                 <p>Are you sure you want to reset your organisations’s leaderboards? This will re-set all the values in the leaderboard to 0. </p>
                            </div>
                        </div>
                    </div>
            <div class="cell auto popup-button">
                <input class="delete-candidate" type="submit" value="Reset" @click="resetLeaderboards()">
                    <input type="button" class="button btn_lg btn orange_btn delete-candidate anon-can" value="Cancel"
                                   @click="deleteCheck=false">
                    </div>
        </b-modal>

        <template v-if="paginationData.last_page > 1">
            <paginate
                v-model="paginationData.current_page"
                :page-count="paginationData.last_page"
                :page-range="3"
                :margin-pages="2"
                :click-handler="leaderboardData"
                :prev-text="'<'"
                :next-text="'>'"
                :prev-class="'paginate-controls'"
                :next-class="'paginate-controls'"
                :container-class="'pagination-custom'"
                :page-class="'page-item'"
                :active-class="'active'">
            </paginate>
        </template>
		<div class="pagination-container margin-top-2 test-2">
		</div>



    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import Paginate from 'vuejs-paginate';
	import DateRangePicker from 'vue2-daterange-picker'
    //you need to import the CSS manually (in case you want to override it)
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        components: { paginate: Paginate, draggable: draggable , DateRangePicker :DateRangePicker, },
        props: [ "allSites", "isCareSector", "taxName"],
		name: "table-column-example",
        display: "Table Column",
        order: 9,

        data: function () {
            return {
                search: '',
                //values: this.users.data,
                values: [],
                positions: [],
				query:false,
                searchVar: '',
                paginationData: {
                    current_page: 0,
                    from: 0,
                    last_page: 0,
                    per_page: 0,
                    to: 0,
                    total: 0,
                },
                //page: this.paginationData.current_page,
                showLoad: false,
                myImage: '/images/loading-circle.gif',
                viewPointsData: [],
                balance: [],
                editMode: false,
                leaderboardValues: [],
                pointValues: [],
                site_name: null,
				pos : 1,
                name: 1,
				email: 0,
				id: 0,
				status: 0,
				site_id: 1,
				redeemed: 1,
				points: 0,
				bonus_points: 0,
				referral_points: 0,
				points_paid: 0,
				total_shares: 1,
				candidates: 1,
				starters: 1,
				interview_sucess: 0,
				total_unique_views: 0,
				created_at: 0,
				headData: ["pos", "name", "bonus_points", "referral_points", "total_shares", "candidates", "starters", "new_to_care"],
				dragging: false,
				showFilter: this.showFilters,
				selected_filter_tags: "",
                showClass: false,
                siteFilter: [],
                selected_filter_site_tags: "",
				sitesOptions: [],
                sitesInviteOptions: [],
                dateArr: {},
				statusFilter: [],
			  filters: [
				  { id: 1, name: "All", image: "/images/idle.png" },
				  { id: 2, name: "Leaver", image: "/images/idle.png" },
				  { id: 4, name: "Registered", image: "/images/idle.png" }
			  ],
			  siteselection: [],
			  siteComparison:2,
			  ranking:'',
			  sitecls : '',
			  currentSort:'pos',
              currentSortDir:'SORT_ASC',
			  name_asc:false,
			  name_desc:false,
			  email_asc:false,
			  email_desc:false,
			  id_asc:false,
			  id_desc:false,
			  status_asc:false,
			  status_desc:false,
			  site_id_asc:false,
			  site_id_desc:false,
			  redeemed_asc:false,
			  redeemed_desc:false,
			  points_asc:false,
			  points_desc:false,
			  bonus_points_asc:false,
			  bonus_points_desc:false,
			  referral_points_asc:false,
			  referral_points_desc:false,
			  points_paid_asc:false,
			  points_paid_desc:false,
			  total_shares_asc:false,
			  total_shares_desc:false,
			  candidates_asc:false,
			  candidates_desc:false,
			  starters_asc:false,
			  starters_desc:false,
			  interview_sucess_asc:false,
			  interview_sucess_desc:false,
			  total_unique_views_asc:false,
			  total_unique_views_desc:false,
			  created_at_asc:false,
			  created_at_desc:false,
			  pos_asc:false,
			  pos_desc:false,
			  message: "",
			  tags: [],
				dateRange: {
				  startDate: '',
				  endDate: '',
				},
			   showFilter: true,
			   filterStartDate : '',
		       filterEndDate : '',
		       selected_date_tags: '',
			   deleteCheck: false,
            };
        },
        methods: {
            /**
             * Set points position for customers.
             *
             * @param leaderBoardPos
             * @param points
             * @returns {string|number|*}
             */
            getNumberWithOrdinal(leaderBoardPos, points) {
                /*if (this.currentSortDir == 'SORT_ASC') {
                    if (points === 0) {
                        return 0;
                    }
                    if (!points) {
                        return '';
                    }
                    const s = ["th", "st", "nd", "rd"], v = leaderBoardPos % 100;
                    return leaderBoardPos + (s[(v - 20) % 10] || s[v] || s[0]);
                }else{*/
				    const s = ["th", "st", "nd", "rd"], v = leaderBoardPos % 100;
                    return leaderBoardPos + (s[(v - 20) % 10] || s[v] || s[0]);
				//}
            },
			setLink() {
                const direction = (this.getParamsFromUrl('direction') === 'desc') ? 'asc' : '';
                const sort = this.getParamsFromUrl('sort');
				var status = this.getParamsFromUrl('sort');
				var startDate = this.filterStartDate;
				var endDate = this.filterEndDate;
				var site = this.getParamsFromUrl('sort');
				var pangenum = this.paginationData.currentPage;

                const baseLink = '/dashboard/app-users';
				var extraparm ="?page="+pangenum;
				if(direction !=''){
				  extraparm += "&sort="+sort+"&direction="+direction;
				}
				if(startDate !=''){
				  extraparm += "&startDate="+startDate+"&endDate="+endDate;
				}
				if(this.statusFilter !=''){
				  extraparm += "&status="+statusFilter;
				}
				if(this.siteFilter !=''){
				  extraparm += "&site="+siteFilter;
				}
				//alert(baseLink + extraparm + this.getParamsFromUrl('siteId'));
				window.location.href  = baseLink + extraparm + this.getParamsFromUrl('siteId');
                //return baseLink + extraparm + this.getParamsFromUrl('siteId');
            },
            setSortingLink(column) {
                const direction = (this.getParamsFromUrl('direction') === 'desc') ? 'asc' : 'desc';
                const baseLink = '/dashboard/app-users';

                return baseLink + '?sort=' + column + '&direction=' + direction + '&siteId=' + this.getParamsFromUrl('siteId');
            },
            setDirectionClass(column) {
                return this.getParamsFromUrl('sort') === column ? this.getParamsFromUrl('direction') : '';
            },
			sort:function(s) {
                this.currentSort = s;

				if(s=='name'){
				 $('.makeinline .name').addClass('sort-active');
				 }else{
				 $('.makeinline .name').removeClass('sort-active');
				}
				if(s=='pos'){
				 $('.makeinline .pos').addClass('sort-active');
				 }else{
				 $('.makeinline .pos').removeClass('sort-active');
				}

				if(s=='email'){
				 $('.makeinline .email').addClass('sort-active');
				 }else{
				 $('.makeinline .email').removeClass('sort-active');
				}
				if(s=='id'){
				 $('.makeinline .id').addClass('sort-active');
				 }else{
				 $('.makeinline .id').removeClass('sort-active');
				}
				if(s=='status'){
				 $('.makeinline .status').addClass('sort-active');
				 }else{
				 $('.makeinline .status').removeClass('sort-active');
				}
				if(s=='site_id'){
				 $('.makeinline .site_id').addClass('sort-active');
				 }else{
				 $('.makeinline .site_id').removeClass('sort-active');
				}
				if(s=='redeemed'){
				 $('.makeinline .redeemed').addClass('sort-active');
				 }else{
				 $('.makeinline .redeemed').removeClass('sort-active');
				}
				if(s=='points'){
				 $('.makeinline .points').addClass('sort-active');
				 }else{
				 $('.makeinline .points').removeClass('sort-active');
				}
				if(s=='bonus_points'){
				 $('.makeinline .bonus_points').addClass('sort-active');
				 }else{
				 $('.makeinline .bonus_points').removeClass('sort-active');
				}
				if(s=='referral_points'){
				 $('.makeinline .referral_points').addClass('sort-active');
				 }else{
				 $('.makeinline .referral_points').removeClass('sort-active');
				}
				if(s=='points_paid'){
				 $('.makeinline .points_paid').addClass('sort-active');
				 }else{
				 $('.makeinline .points_paid').removeClass('sort-active');
				}
				if(s=='total_shares'){
				 $('.makeinline .total_shares').addClass('sort-active');
				 }else{
				 $('.makeinline .total_shares').removeClass('sort-active');
				}
				if(s=='candidates'){
				 $('.makeinline .candidates').addClass('sort-active');
				 }else{
				 $('.makeinline .candidates').removeClass('sort-active');
				}
				if(s=='starters'){
				 $('.makeinline .starters').addClass('sort-active');
				 }else{
				 $('.makeinline .starters').removeClass('sort-active');
				}
				if(s=='interview_sucess'){
				 $('.makeinline .interview_sucess').addClass('sort-active');
				 }else{
				 $('.makeinline .interview_sucess').removeClass('sort-active');
				}
				if(s=='total_unique_views'){
				 $('.makeinline .total_unique_views').addClass('sort-active');
				 }else{
				 $('.makeinline .total_unique_views').removeClass('sort-active');
				}
				if(s=='created_at'){
				 $('.makeinline .created_at').addClass('sort-active');
				 }else{
				 $('.makeinline .created_at').removeClass('sort-active');
				}

				 //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='SORT_ASC'?'SORT_DESC':'SORT_ASC';

                    if(this.currentSort=='name'){ 		        this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false,
						  this.site_id_desc=false,
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;

						  this.interview_sucess_desc=false;
						  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.pos_asc=false;
						  this.pos_desc=false;
						//remove red line

$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.name_asc=true;
                            this.name_desc=false;
                        }
                        else
                        {
                            this.name_asc=false;
                            this.name_desc=true;
                        }
                    }


					if(this.currentSort=='pos'){ 		         this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false,
						  this.site_id_desc=false,
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.pos_asc=true;
                            this.pos_desc=false;
                        }
                        else
                        {
                            this.pos_asc=false;
                            this.pos_desc=true;
                        }
                    }

					if(this.currentSort=='email'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false,
						  this.site_id_desc=false,
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.email_asc=true;
                            this.email_desc=false;
                        }
                        else
                        {
                            this.email_asc=false;
                            this.email_desc=true;
                        }
                    }

					if(this.currentSort=='id'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false,
						  this.site_id_desc=false,
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.id_asc=true;
                            this.id_desc=false;
                        }
                        else
                        {
                            this.id_asc=false;
                            this.id_desc=true;
                        }
                    }
					if(this.currentSort=='status'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.site_id_asc=false,
						  this.site_id_desc=false,
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.status_asc=true;
                            this.status_desc=false;
                        }
                        else
                        {
                            this.status_asc=false;
                            this.status_desc=true;
                        }
                    }
					if(this.currentSort=='site_id'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.site_id_asc=true;
                            this.site_id_desc=false;
                        }
                        else
                        {
                            this.site_id_asc=false;
                            this.site_id_desc=true;
                        }
                    }
					if(this.currentSort=='redeemed'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.redeemed_asc=true;
                            this.redeemed_desc=false;
                        }
                        else
                        {
                            this.redeemed_asc=false;
                            this.redeemed_desc=true;
                        }
                    }

					if(this.currentSort=='points'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.points_asc=true;
                            this.points_desc=false;
                        }
                        else
                        {
                            this.points_asc=false;
                            this.points_desc=true;
                        }
                    }
					if(this.currentSort=='bonus_points'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.referral_points_asc=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.bonus_points_asc=true;
                            this.bonus_points_desc=false;
                        }
                        else
                        {
                            this.bonus_points_asc=false;
                            this.bonus_points_desc=true;
                        }
                    }
					if(this.currentSort=='referral_points'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.referral_points_asc=true;
                            this.referral_points_desc=false;
                        }
                        else
                        {
                            this.referral_points_asc=false;
                            this.referral_points_desc=true;
                        }
                    }
					if(this.currentSort=='points_paid'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_as=false;
						  this.referral_points_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.points_paid_asc=true;
                            this.points_paid_desc=false;
                        }
                        else
                        {
                            this.points_paid_asc=false;
                            this.points_paid_desc=true;
                        }
                    }
					if(this.currentSort=='total_shares'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_as=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.total_shares_asc=true;
                            this.total_shares_desc=false;
                        }
                        else
                        {
                            this.total_shares_asc=false;
                            this.total_shares_desc=true;
                        }
                    }
					if(this.currentSort=='candidates'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_as=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.candidates_asc=true;
                            this.candidates_desc=false;
                        }
                        else
                        {
                            this.candidates_asc=false;
                            this.candidates_desc=true;
                        }
                    }
					if(this.currentSort=='starters'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_as=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						  		  this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.starters_asc=true;
                            this.starters_desc=false;
                        }
                        else
                        {
                            this.starters_asc=false;
                            this.starters_desc=true;
                        }
                    }
					if(this.currentSort=='interview_sucess'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_as=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.interview_sucess_asc=true;
                            this.interview_sucess_desc=false;
                        }
                        else
                        {
                            this.interview_sucess_asc=false;
                            this.interview_sucess_desc=true;
                        }
                    }

					if(this.currentSort=='total_unique_views'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_as=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.created_at_asc=false;
						  this.created_at_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .created_at').removeClass('sort-active');
this.total_unique_views_asc=false;
						  this.total_unique_views_desc=false;
                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.total_unique_views_asc=true;
                            this.total_unique_views_desc=false;
                        }
                        else
                        {
                            this.total_unique_views_asc=false;
                            this.total_unique_views_desc=true;
                        }
                    }

					if(this.currentSort=='created_at'){ 		         this.pos_asc=false,
						  this.pos_desc=false,
						  this.email_asc=false,
						  this.email_desc=false,
						  this.id_asc=false,
						  this.id_desc=false,
						  this.status_asc=false,
						  this.status_desc=false,
						  this.site_id_asc=false;
						  this.site_id_desc=false;
						  this.redeemed_asc=false;
						  this.redeemed_desc=false;
						  this.points_asc=false;
						  this.points_desc=false;
						  this.bonus_points_asc=false;
						  this.bonus_points_desc=false;
						  this.referral_points_as=false;
						  this.referral_points_desc=false;
						  this.points_paid_asc=false;
						  this.points_paid_desc=false;
						  this.total_shares_asc=false;
						  this.total_shares_desc=false;
						  this.candidates_asc=false;
						  this.candidates_desc=false;
						  this.starters_asc=false;
						  this.starters_desc=false;
						  this.interview_sucess_asc=false;
						  this.interview_sucess_desc=false;
						  this.name_asc=false;
						  this.name_desc=false;
						//remove red line

$('.makeinline .name').removeClass('sort-active');
$('.makeinline .pos').removeClass('sort-active');
$('.makeinline .email').removeClass('sort-active');
$('.makeinline .id').removeClass('sort-active');
$('.makeinline .status').removeClass('sort-active');
$('.makeinline .site_id').removeClass('sort-active');
$('.makeinline .redeemed').removeClass('sort-active');
$('.makeinline .points').removeClass('sort-active');
$('.makeinline .bonus_points').removeClass('sort-active');
$('.makeinline .referral_points').removeClass('sort-active');
$('.makeinline .points_paid').removeClass('sort-active');
$('.makeinline .total_shares').removeClass('sort-active');
$('.makeinline .candidates').removeClass('sort-active');
$('.makeinline .starters').removeClass('sort-active');
$('.makeinline .interview_sucess').removeClass('sort-active');

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.created_at_asc=true;
                            this.created_at_desc=false;
                        }
                        else
                        {
                            this.created_at_asc=false;
                            this.created_at_desc=true;
                        }
                    }

                }
			if(this.siteComparison==1){
			  this.getSites();
			  this.sitecls = 'siteclass';
			 }else{
			   this.sitecls = '';
			   this.leaderboardData();
			 }

			},
			leaderboardDataSe(){
			  if (this.searchVar.length > 2){
					 this.leaderboardData();
				}
			},
			leaderboardData(page, init) {
                    this.showLoad = true;
                    var self = this;
					var search ='';
					var sitefilter = '';
					if(self.siteComparison==1){
					sitefilter ='';
					}
					else if(self.siteComparison==2){
					sitefilter ='';
					}else{
					sitefilter = self.siteComparison;
					}

                    axios.get('/dashboard/points/reset-leaderboard', { params:{
                        'searchVar': self.searchVar,
                        'leader_page': '',
                        'page': page,
                        'column_name': self.currentSort,
                        'sort': self.currentSortDir,
                        'filterStartDate': self.filterStartDate,
                        'filterEndDate': self.filterEndDate,
                        'statusFilter': self.statusFilter,
                        'siteFilter': sitefilter,

                    }})
                        .then(function (response) {

						var leadsData = response.data.leaderboardData;
						var paging = response.data.pagination;
						if (response.data.leaderboardData.length == 0){
						self.query= true;
						self.ranking = response.data.ranking;
						self.values = response.data.leaderboardData;
		       self.message = "None of your app user’s have any activity during this leaderboard period yet. Check back soon!";
			   self.paginationData.current_page = 0;
                                self.paginationData.from = 0;
                                self.paginationData.last_page = 0;
                                self.paginationData.per_page = 0;
                                self.paginationData.to = 0;
                                self.paginationData.total = 0;
                       self.showLoad = false;
					}else{

                            if(response.data.leaderboardData) {
                                self.values = response.data.leaderboardData;
                                self.ranking = response.data.ranking;
                               self.positions = response.data.positionArr;
                                self.paginationData.current_page = paging.current_page;
                                self.paginationData.from = paging.from;
                                self.paginationData.last_page = paging.last_page;
                                self.paginationData.per_page = paging.per_page;
                                self.paginationData.to = paging.to;
                                self.paginationData.total = paging.total;
                            }
						 }
							self.showLoad = false;
                        })
                        .catch(function (error) {
                            console.log(error);
                        })


            },
            searchLeaderboard: function () {
                if (this.searchVar.length > 0) {
                    this.showLoad = true;
                    var self = this;
                    axios.post('/dashboard/points/search', {searchVar:this.searchVar})
                        .then(function (response) {
                            if(response.data.users.data) {
                                self.values = response.data.users.data;
                                self.paginationData.current_page = response.data.users.current_page;
                                self.paginationData.from = response.data.users.from;
                                self.paginationData.last_page = response.data.users.last_page;
                                self.paginationData.per_page = response.data.users.per_page;
                                self.paginationData.to = response.data.users.to;
                                self.paginationData.total = response.data.users.total;
                            }
							self.showLoad = false;
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                        /*.finally(function () {
                            self.showLoad = false;
                        })*/
                }
            },
            searchLeaderboardData: function () {
                if (this.searchVar.length <= 0) {
                    this.leaderboardData();
                } else {
                    this.searchLeaderboard();
                }
            },
            paginatorClickCallback: function (pageNum) {
                var url = window.location.href.split('siteId=');
                if(url && url[0] && url[1]) {
                    if(url[0].indexOf('page') != -1) {
                        var url2 = url[1].split('#');
                    } else {
                        var url2 = url[1].split('&');
                    }
                }
                if(url2 && url2[0]) {
                    window.location = window.location.origin + '/dashboard/app-users?page=' + pageNum + '&siteId=' + url2[0] + '#leaderboard';
                } else {
                    window.location = window.location.origin + '/dashboard/app-users?page=' + pageNum + '#leaderboard';
                }
                return false;
            },
            exportcsv: function(){
                // href="/dashboard/leaderboardexport"
                axios.get('/dashboard/leaderboardexport?search='+this.searchVar + '&export=1')
                    .then(response => {
                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Export Completed',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                    })

            },
			onClick() {
			    $(".leftpopup").click(function(e){
                 e.stopPropagation();
                });

			},


        filtertoggle() {
			  if (this.showFilter == true) {
				this.showFilter = false;
			  } else {
				this.showFilter = true;
			  }
			},




	     resetLeaderboards() {
                Vue.swal({
                    title: 'Reseting...',
                    html: '',
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });
                var self = this;
				self.runupdate= true;
                axios.post("/dashboard/account/reset-leaderboard-data")
                    .then(function (response) {
                        self.deleteCheck = false;
                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Reset Leaderboard',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                      location.reload();
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            },

			changeRoute(){
			if(this.siteComparison==1){
			  this.values =[];
			  this.sitecls = 'siteclass';
			  this.getSites();
			 }else if(this.siteComparison==2){
			 this.values =[];
               this.sitecls = '';
			   this.sort('pos');
			 }else{
			   this.values =[];
               this.sitecls = '';
			   this.sort('pos');
			 }
			},

			getSites(page, init) {
                    this.showLoad = true;
                    var self = this;
					var search ='';

                    axios.get('/dashboard/points/get-leaderboard-sites', { params:{
                        'searchVar': self.searchVar,
                        'leader_page': '',
                        'page': page,
                        'column_name': self.currentSort,
                        'sort': self.currentSortDir,
                        'filterStartDate': self.filterStartDate,
                        'filterEndDate': self.filterEndDate,
                        'statusFilter': self.statusFilter,
                        'siteFilter': self.siteFilter,

                    }})
                        .then(function (response) {

						var leadsData = response.data.leaderboardData;
						var paging = response.data.pagination;
						if (response.data.leaderboardData.length == 0){
						self.ranking = response.data.ranking;
						self.query= true;
						self.values = response.data.leaderboardData;
		       self.message = "None of your app user’s have any activity during this leaderboard period yet. Check back soon!";
			   self.paginationData.current_page = 0;
                                self.paginationData.from = 0;
                                self.paginationData.last_page = 0;
                                self.paginationData.per_page = 0;
                                self.paginationData.to = 0;
                                self.paginationData.total = 0;
                       self.showLoad = false;
					}else{

                            if(response.data.leaderboardData) {
                                self.values = response.data.leaderboardData;
                                self.ranking = response.data.ranking;
                               self.positions = response.data.positionArr;
                                self.paginationData.current_page = paging.current_page;
                                self.paginationData.from = paging.from;
                                self.paginationData.last_page = paging.last_page;
                                self.paginationData.per_page = paging.per_page;
                                self.paginationData.to = paging.to;
                                self.paginationData.total = paging.total;
                            }
						 }
							self.showLoad = false;
                        })
                        .catch(function (error) {
                            console.log(error);
                        })


            },


        },

        mounted() {
		    var self = this;

			if (this.allSites) {
      if (this.allSites.length > 1) {
        var site = {
          id: 1,
          name: "Sites comparison"
        };
        this.siteselection.push(site);
      }
      for (var i = 0; i < this.allSites.length; i++) {
        site = {
          id: this.allSites[i].id,
          name: this.allSites[i].name
        };
        this.siteselection.push(site);
      }
	  var site = {
          id: 2,
          name: "All app users"
        };
		this.siteselection.push(site);
    }
	 self.sort('pos');
        }
    };
</script>
<style lang="scss" scoped>
#leaderboards{
  img .leaderbord-open-col{
  width: 24px;
  height: 24px;
  margin: 14px 10px 13px 26px;
  object-fit: contain;
}

.column-setup-mask {
  width: 288px;
  height: 348px;
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.Element-DropdownMenu-Header {
  height: 45px;
  padding: 15px 7px 5px 7px;
  float: left;
  width:100%;
  text-align:left;
}
.column-outer-div {
  width: 100%;
  height: 40px;
  padding: 8px 7px 8px 7px;
  clear: both;
}
.Menu-Item {
  width: calc(100% - 30px);
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #363b54;
  float:left;
  text-align:left;
  text-transform: capitalize;
}
img.Element-Control-Checkbox-Selected {
  width: 24px;
  height: 24px;
  object-fit: contain;
  float:right;
}
img.Element-Control-Checkbox-Idle {
  width: 24px;
  height: 15px;
  object-fit: contain;
  float:right;
}
.Common-Countries {
  width: 91px;
  height: 16px;
  margin: 0 0 9px;
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
}
.leftpopup .dropdown-pane{
  width: 288px !important;
  height: 348px;
  max-height : 348px;
  /*margin-right: 5%;
  margin-top: 57px;*/
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  overflow-x: hidden;
  top: 12px !important;
  left: -80px !important;
}
.leftpopup .Rectangle {
  width: 288px;
  height: 1px;
  margin: 40px 10px 8px 0px;
  background-color: rgba(42, 49, 81, 0.1);
  padding: 0px !important;
}
.leftpopup .rightsidecheckbox {
  width:30px;
  display: inline-block;
}
.makeinline {
   width: 100%;
   display: inherit;
}
.leftpopup {
   float:right;
   padding-top:10px;
}
.pagination-custom{
  border-bottom-left-radius: 8px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 0 3px 0 rgba(0, 0, 0, 0.05);
background-color: #fff;
border-bottom-right-radius: 8px;
width: 100%;
padding-top:25px;
padding-bottom:23px;
}
#openleadoption{
cursor:pointer;
}
.cf-table{
  /*overflow-x: auto;*/
  border-radius: 0px !important;
  margin-bottom: 0px !important;
  }
  .sort-active{
  border-bottom: 2px solid #ef5d72;
  padding-bottom: 0px !important;
  background-color: #f0f1f7 !important;
  }
  .fa{
    width: 24px;
    padding-left: 12px;
    padding-bottom: 0px;
  }
  .cf-table .cf-table-head p strong{
    background:  none !important;
	padding:0 5px;
  }
  .cf-table .cell.auto p{
    margin: 14px 0 !important;
	text-align: center;
  }
  .cf-table .maindivsite .name .auto p{
	text-align: left;
  }

  .cf-table .maindivsite .pos, .cf-table .makeinline .pos{
  max-width:72px !important;
  }


  .dropdown-action button{
    width: 28px;
    height: 28px;
    background:url("/images/more.png") no-repeat center center;
    border-radius:100%;
    text-indent: -9999px;
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .cf-table .cf-table-head{
    padding: 0px 18px 0px 18px;
	background: #fafafc;
	word-wrap: break-word;
  }
  .my-tooltip-class{
  width: 125px !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: #363b54;
  position: absolute;
  left:-40px !important;
  padding-top: 0px !important;
  font-family: InterUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.sortable-chosen{
  background-color: #f0f1f7;
  /*width: 173px;
  height: 52px;*/
  cursor:url('/images/cursor-arrow-leader.png'), auto;
}
.sortable-chosen:hover{
  background-color: #f0f1f7;
  /*width: 173px;
  height: 52px;*/
  cursor:url('/images/cursor-arrow-leader.png'), auto;
}
 .colhighlight {
    background: #f0f1f7;
  }
  .makeinline .item:hover{
  background-color: #f0f1f7;
  /*width: 173px;
  height: 48px;*/
  padding:0px !important;
  margin:0px !important;
  cursor:url('/images/cursor-arrow-leader.png'), auto;
  }

}

.empty-state {
    background: #fafafc;
    margin-top: 25px;
    width: 100%;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;
        padding: 10px 21px 8px;
    }

    p {
        width: 100%;
        margin: 35px 0 33px;
        font-family: InterUI;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: center;
        color: #363b54;
    }

    input {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.empty-state-back {
    background: unset !important;
}
.positions-lead{
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #363b54;
  padding-left:10px;
}
.reset-lead .cell:first-child {
    max-width: 280px !important;
}
.siteclass .site_id, .siteclass .status, .siteclass .created_at, .siteclass .id, .siteclass .redeemed  {
   display:none !important;
}
.reset-lead .cell:last-child {
    max-width: 136px;
    padding-left: 0px;
}
.maindivsite .points_paid, .maindivsite .referral_points, .maindivsite .bonus_points{
      word-wrap: break-word;
}
</style>
<style>
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {

}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 36px !important;
}
.style-chooser .vs--single .vs__selected{
  height: 34px !important;
}
.style-chooser .vs__selected{
  height: 34px !important;
}

.style-chooser .vs__dropdown-menu{
  width:250px @important;
}
.style-chooser .vs__dropdown-option--highlight {
  width:200px @important;
  overflow-wrap: break-word;
}
.style-chooser .vs__dropdown-option {
  width:200px @important;
  overflow-wrap: break-word;
}
.style-chooser #vs8__listbox{
  width:250px @important;
  overflow-wrap: break-word;
}
.style-chooser ul{
  width:250px @important;
  overflow-wrap: break-word;
}
.style-chooser ul li{
  width:250px @important;
  overflow-wrap: break-word;
}
.style-chooser .vs__search, .style-chooser .vs__search:focus {
    position: absolute;
   width: 250px;
}
#leaderboards .total_inline{
   float: left;
   width: 100%;
   padding: 7px 18px;
}
#leaderboards .total-points-bonus{
   font-family: Helvetica !important;
}
#leaderboards .tot-num-bonus {
    width: 150px;
    height: 24px;
    font-family: InterUI;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #363b54;
}
.rrlead .v-select li{
   width: 249px;
   overflow-wrap: break-word;
   word-wrap: break-word;
   white-space: normal !important;
}

</style>