<template>
  <div>
    <label v-if="!notShowLabel" class="bonus-label">SELECT USERS</label>
    <v-select
      v-model="user_id"
      class="phone-recruiter"
      placeholder="Add user"
      label="name"
      :options="filteredUsers"
      :close-on-select="true"
      :getOptionKey="opt => getOptionKey(opt)"
      @input="value => selectedUserId(value.id)"
      @search="(search, loading) => handleSearch(search, loading)"
    />
    <div class="input-tagged">
      <span v-if="selectedName">
        <div class=" tag-input__tag tag-right">
          <span class="lablesize">{{ selectedName }}</span>
          <span class="deselect-pad"
            ><span class="deselect" @click="removeNotiTags"></span
          ></span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["users", "notShowLabel", "selectedUserIds"],
  data() {
    return {
      availableUsers: [],
      filteredUsers: [],
      user_id: this.selectedUserIds,
      selectedName: ""
    };
  },
  watch: {
    user_id: function(val) {
      if (val) {
        this.$emit("selectedUser", val.id);
      }
    },
    users: function(newUsers) {
      const availableUsers = this.getAvailableUsers(newUsers);
      this.availableUsers = availableUsers;
      this.filteredUsers = availableUsers;
    }
  },
  methods: {
    handleSearch(searchString, loading) {
      loading(true);
      const matchedUsers = this.users.filter(user => {
        const name = `${user.first_name.toLowerCase()} ${user.surname.toLowerCase()}`;
        const lowercaseSearchString = searchString.toLowerCase();
        return name.includes(lowercaseSearchString);
      });
      this.filteredUsers = this.processUsers(matchedUsers);
      loading(false);
    },
    getOptionKey(option) {
      return `${option.id}|${option.name}`;
    },
    getAvailableUsers(usersArray) {
      return this.processUsers(usersArray);
    },
    processUsers(usersArray) {
      if (!usersArray) {
        return [];
      }

      return usersArray.map(userObj => {
        const userType = userObj.active === "1" ? "User" : "Invitee";
        return {
          name: `${userObj.first_name} ${userObj.surname}`,
          id: `${userObj.id}-${userType}`
        };
      }).sort((a, b) => a.name.localeCompare(b.name));
    },
    selectedUserId(value) {
      this.selectedName = this.availableUsers.find(
        user => user.id === value
      )?.name;
    },
    removeNotiTags: function() {
      this.user_id = null;
      this.selectedName = "";
    }
  },
  mounted() {
    const availableUsers = this.getAvailableUsers(this.users);
    this.availableUsers = availableUsers;
    this.filteredUsers = availableUsers;
    this.selectedUserId(this.selectedUserIds);
  },
  computed: {
    isShow: function() {
      return !!this.user_id;
    }
  }
};
</script>
<style lang="scss" scoped>
.input-tagged {
  float: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
  flex-basis: 100%;
  flex-grow: 1;
}
.tag-right {
  margin-right: 5px;
}
</style>
