<template>
  <div class="app-settings">
    <!--APP SETTING Start-->
    <p class="app-settings-sub-heading">You can use this page to tailor the experience of your app user</p>
    <section class="app-setting-row">
      <div class="inner-box">
        <div class="app-setting-cards">
          <h3 class="inner-heading-border">
            Peer-to-peer app sharing
          </h3>
          <div class="left-box">
            <p>
              To maximise staff uptake, we suggest asking existing app users to share the {{ brandName }} app link
              with <br />colleagues who aren't using {{ brandName }}, via SMS, Whatsapp, Facebook. Staff must be
              pre-<br />authorised to use the app.
              <a
                href="https://support.carefriends.co.uk/articles/6281957-peer-to-peer-app-sharing-a-guide-for-admin-users"
                target="_blank"
                >Find out more here</a
              >
            </p>
            <p>
              You can also set-up automated points awards to reward employees for successfully referring  <br />colleagues
              as new app users.
              <a
                href="https://support.carefriends.co.uk/articles/6281942-setting-up-an-automated-bonus-award-for-peer-to-peer-app-sharing"
                target="_blank"
                >Find out more here</a
              >
            </p>
            <p>Boosts app sign-ups by promoting this feature on the app homepage.</p>
            <div class="switch-label grid-x" v-if="isCheckboxImageLoaded">
<!--              <label for="promote_sharing">-->
<!--                <input-->
<!--                  type="checkbox"-->
<!--                  name="promote_sharing"-->
<!--                  true-value="1"-->
<!--                  false-value="0"-->
<!--                  class=""-->
<!--                  id="promote_sharing"-->
<!--                  v-model="promote_sharing"-->
<!--                />-->
<!--              </label> Promote sharing on the app homescreen-->

              <label class="custom-checkbox-label">
                <input type="checkbox" class="custom-checkbox" true-value="1" false-value="0" v-model="promote_sharing" />
                <span class="checkmark""></span>
                Promote sharing on the app homescreen
              </label>
            </div>
          </div>
        </div>
        <div class="keep-space"></div>
        <div class="app-setting-cards">
          <h3 class="inner-heading-border">
            Leaderboard
          </h3>
          <div class="left-box">
            <div class="radio-section ">
              <label class="radio-header">ANONYMISE LEADERBOARD</label>
              <div class="keep-space"></div>

               <div class="switch-label grid-x" v-if="isCheckboxImageLoaded">
                 <label class="custom-checkbox-label">
                   <input :disabled="!canManageCompanyDetails" type="checkbox" class="custom-checkbox" true-value="1" false-value="0" v-model="leaderboardAnon" />
                   <span class="checkmark" ></span>
                   Anonymise in-app leaderboard
                 </label>
              </div>


            </div>
          </div>

        </div>


        <div class="keep-space"></div>
        <div class="jobs-section app-setting-cards" v-if="this.is_job_section_visible">
          <h3 class="inner-heading-border">
            Jobs
          </h3>


          <div class="job-radio-user">
            <div class="radio-section ">
              <label class="radio-header">WHICH JOBS CAN THE USER SEE?</label>
              <div
                class="radio-option-1"
              >
                <label class="radio-btn-label">
                  <input
                    v-model="app_user_can_view_all_sites"
                    type="radio"
                    name="app_user_can_view_all_sites"
                    :value="0"
                    class="mouse-hover"
                  />
                  <span class="radiobox mouse-hover"></span>Own site only
                </label>
                <br/>
              </div>
              <div
                class="radio-option-2"
              >
                <label class="radio-btn-label">
                  <input
                    v-model="app_user_can_view_all_sites"
                    type="radio"
                    name="app_user_can_view_all_sites"
                    :value="1"
                    class="mouse-hover"
                  />
                  <span class="radiobox mouse-hover"></span>All sites
                </label>
              </div>

            </div>
          </div>

          <div v-if="app_user_can_view_all_sites === 1" class="job-radio-site">
            <div class="">
              <label class="radio-header">WHICH SITE IS SELECTED BY DEFAULT?</label>
              <div
                class="radio-option-1"
              >
                <label class="radio-btn-label">
                  <input
                    v-model="user_default_site_selected_is_all"
                    type="radio"
                    name="user_default_site_selected_is_all"
                    :value="0"
                    class="mouse-hover"
                  />
                  <span class="radiobox mouse-hover"></span>Own site
                </label>
                <br/>
              </div>
              <div
                class="radio-option-2"
              >
                <label class="radio-btn-label">
                  <input
                    v-model="user_default_site_selected_is_all"
                    type="radio"
                    name="user_default_site_selected_is_all"
                    :value="1"
                    class="mouse-hover"
                  />
                  <span class="radiobox mouse-hover"></span>All sites
                </label>
              </div>

            </div>
          </div>
        </div>
        <div class="keep-space"></div>
        <div class="app-setting-cards">
          <h3 class="inner-heading-border">
            Welcome message
          </h3>

          <div class="left-box">
            <p>
              During the app registration process, your app users will see a custom "Welcome" message, set by you. <br>
              The more personal, the better! We recommend using this to thank them for downloading the app and <br>
              encouraging them to share jobs.
            </p>
            <div class="input-box" style="position: relative;">
              <label>
                WORDING FOR THE MESSAGE(100 MAX CHARACTERS)
              </label>
              <textarea v-model="data.message" :maxlength="100" placeholder="Type your message here ... "></textarea>
              <span class="text-count">{{ 100 - data.message.length }}/100</span>
            </div>

            <div class="input-box">
              <label>MESSAGE TO BE SENT FROM</label>
              <input class="message-to-be-sent-from-input" type="text" v-model="data.name" placeholder="Name" />
            </div>

            <div class="input-box">
              <strong class="title">UPLOAD A HEADSHOT TO SENDER</strong>
            </div>

            <div class="input-box" style="display:flex, align-items:center">
              <div class="upload-placeholder">
                <div class="round-thumb">
                  <div v-if="images.length" class="images-preview-thumb image-placeholder-thumb">
                    <div class="img-wrapper-thumb" v-for="(image, index) in images" :key="index">
                      <img v-bind:src="image" />
                    </div>
                  </div>
                  <div v-else>{{ this.nameprefix }}</div>
                </div>
              </div>
              <div class="upload-btn-wrapper">
                <button @click="$refs.fileInput.click()" class="Success-trans-btn">Upload</button>
                <input
                  type="file"
                  class=""
                  name="myfile"
                  id="file"
                  ref="fileInput"
                  @change="onInputChange"
                />
                <br/>
                <span class="suggest-details"> Suggested dimension: 300 x 300px </span>
                <br>
                <span class="suggest-details">Max file size: 2MB</span>
              </div>
            </div>
          </div>
          <div class="right-box">
            <div class="frame">
              <img src="/images/mobile-img.png" alt="img" />
              <div class="caption">
                <div class="message-box">
                  <strong class="title">Hi Olivia,</strong>
                  <p>{{ data.message }}</p>
                </div>


                <div class="btm-outer">
                  <div class="text-btm-row">
                    <div class="round-thumb">
                      <img v-if="images.length" :src="images[0]" alt="img" />
                      <img
                        v-else-if="currentuser.customer.logo"
                        :src="`/storage/${currentuser.customer.logo}`"
                        alt="img"
                      />
                      <img v-else src="/images/user-img.png" alt="img" />
                    </div>
                    <div class="text-col">
                      <strong class="name">{{ data.name }}</strong>
                      <span>{{ data.organization }}</span>
                    </div>
                  </div>
                </div>
                <div class="mobile-img-actions">
                    <div>
                      <a class="btn-started">Skip ></a>
                    </div>
                    <div style="display:inline-flex">
                      <div class="center-circle-icon"></div>
                      <div class="center-circle-icon"></div>
                      <div class="center-circle-icon"></div>
                      <div class="center-circle-icon"></div>
                      <div class="center-circle-icon-long"></div>
                    </div>
                    <div>
                      <a href="#" class="btn-started">Start ></a>
                    </div>
                </div>



              </div>
            </div>
          </div>
        </div>
        <div v-if="currentuser.account_check" class="btn-row">
          <input @click="update()" class="btn btn_pink button float-right app-settings-save-btn" type="submit" value="Save" />
        </div>
      </div>
    </section>
    <!--APP SETTING End-->
  </div>
</template>

<script>
export default {
  props: ["currentuser", "is_job_section_visible"],
  data() {
    return {
      data: {
        message: `Welcome to our ${this.brandName} app. Who do you know that is perfect to join the team?`,
        name: "",
        organization: "",
        image: "",
      },
      files: [],
      images: [],
      nameprefix: "N",
      promote_sharing: this.currentuser.customer.promote_sharing,
      user_default_site_selected_is_all: this.currentuser.customer.user_default_site_selected_is_all ?? null,
      app_user_can_view_all_sites: this.currentuser.customer.app_user_can_view_all_sites ?? null,
      leaderboardAnon: this.currentuser.customer.leaderboardAnon,
      isCheckboxImageLoaded: false,
      checkboxImageUrl: '/images/svg/enabled_checkbox.svg',
    };
  },
  methods: {
    initialize() {
      try {
        if (this.currentuser.customer.app_message.length > 0) {
        this.data.message = this.currentuser.customer.app_message;
        } else
          this.data.message = `Welcome to our ${this.brandName} app. Who do you know that is perfect to join the team?`;

        if (this.currentuser.customer.message_name.length > 0) {
          this.data.name = this.currentuser.customer.message_name;
        } else {
          if (this.currentuser.first_name.length || this.currentuser.surname.length) {
            this.data.name = this.currentuser.first_name + " " + this.currentuser.surname;
          }
        }

        if (this.currentuser.customer.name.length > 0) {
          this.data.organization = this.currentuser.customer.name;
        }

        if (this.currentuser.customer.thumb_image.length > 0) {
          this.images.push(this.currentuser.customer.thumb_image);
        }
        this.namePrefix();
      } catch (e) {
        console.error(e);
      }
    },
    onInputChange(e) {
      this.files = [];
      this.images = [];
      const files = e.target.files;
      Array.from(files).forEach(file => this.addImage(file));
    },

    addImage(file) {
      if (!file.type.match("image.*")) {
        this.$toastr.e(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const img = new Image(),
        reader = new FileReader();
      reader.onload = e => this.images.push(e.target.result);
      reader.readAsDataURL(file);
    },
    namePrefix() {
      this.nameprefix = this.currentuser.first_name.charAt(0) + this.currentuser.surname.charAt(0);
    },
    update() {
      console.log("save Candidate: ", this.data);
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("name", this.data.name);
      formData.append("message", this.data.message);
      formData.append("promote_sharing", this.promote_sharing);

      if (this.app_user_can_view_all_sites != null) {
        formData.append("app_user_can_view_all_sites", this.app_user_can_view_all_sites);

        if (this.app_user_can_view_all_sites) {
          formData.append("user_default_site_selected_is_all", this.user_default_site_selected_is_all);
        }
      }

      formData.append("leaderboard_anon", this.leaderboardAnon);

      this.files.forEach(file => {
        formData.append("images[]", file, file.name);
      });
      axios
        .post("/dashboard/seetings/app", formData, {})
        .then(function (response) {
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> App Settings Updated',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> App Settings Could not be Updated',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#ec5168",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        });
    },
  },
  computed: {
    canManageCompanyDetails() {
      if (!this.currentuser) {
        return false;
      }

      return this.currentuser.account_check;
    }
  },
  mounted() {
    this.initialize();
  },
  created() {
    let img = new Image();
    img.src = this.checkboxImageUrl;
    img.onload = () => {
      this.isCheckboxImageLoaded = true;
    }
  },
  updated() {
    if (!this.app_user_can_view_all_sites) {
      this.user_default_site_selected_is_all = 0;
    }
  },
};
</script>

<style lang="scss" scoped>
  .jobs-section {
    margin-bottom: 2em;
  }

  .job-radio-user, .job-radio-site {
    padding-left: 3px !important;
    margin-bottom: 12px !important;
    font-weight: 600 !important;
  }
  .mouse-hover {
    cursor: pointer !important;
  }

  input[type="radio"] {
    -webkit-appearance: none !important;
    width: 22px !important;
    height: 22px !important;
    border: 2px solid #d1d3e0 !important;
    border-radius: 50% !important;
    outline: none !important;
    vertical-align: middle !important;
    margin: 0px 6px 2px 0px !important;
  }
  input[type="radio"]:before {
    content: "" !important;
    display: block !important;
    width: 60% !important;
    height: 60% !important;
    margin: 20% auto !important;
    border-radius: 50% !important;
  }
  input[type="radio"]:checked {
    border: 2px solid #5dc2a6 !important;
  }
  .radio-btn-label{
    cursor:pointer;
    display: inline !important;
    margin: 0 !important;
    font: 400 14px "Poppins", sans-serif;
    line-height: 3;
    color: #3C3C3C;
  }
  .app-setting-cards {
    margin: 0 auto;
    display: block;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: none;
    background-color: #ffffff;
    padding: 15px 23px !important;
  }
  .inner-heading-border {
    border-bottom: 1px solid #f2f3f4;
    padding-bottom: 12px;
  }
  .keep-space{
    margin:12px 0px;
  }
  .upload-placeholder {
    float: left;
    width: auto;
  }
  .upload-placeholder .round-thumb {
    float: left;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background: #d8f1ea;
    text-align: center;
    color: #5dc2a6;
    font: 600 20px/87px "Poppins", sans-serif;
    text-transform: uppercase;
    margin-top: 0px;
}
.upload-btn-wrapper .suggest-details{
  font: 400 14px/0px "Poppins", sans-serif;
  color:#989898;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    margin: 0px 0px 0px 50px !important;
}
.Success-trans-btn{
    background-color: #ffffff;
    border: 2px solid #5DC2A6;
    border-radius: 20px;
    color: #5DC2A6;
    font: 700 16px/10px "Poppins", sans-serif;
    padding: 12px 18px;
    cursor: pointer !important;
}
.Success-trans-btn:hover{
    background-color: #ffffff;
    border: 2px solid #5DC2A6;
    border-radius: 20px;
    color: #5DC2A6;
    font: 700 16px/15px "Poppins", sans-serif;
    padding: 12px 18px;
    cursor:pointer !important;
}
.text-count{
  position: absolute;
  top: 123px;
  left: 352px;
  font: 400 12px/15px "Poppins", sans-serif;
  color:#989898;
}

.app-settings-sub-heading, .switch-label, .checkbox-custom-label, textarea, .message-to-be-sent-from-input{
    font: 400 14px/24px "Poppins", sans-serif !important;
    color: #3C3C3C !important;
}

h3 {
    font: 600 18px/28px "Poppins", sans-serif;
    color: #3C3C3C;
    margin: 0 0 10px 0;
}
.radio-header{
    font: 600 12px/24px "Poppins", sans-serif;
    color:#6A6F8E;
}
input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #CCCCCC;
    border-radius: 6px;
    position: relative;
    margin: 0 1rem 0 0rem !important;
}
input[type="checkbox"]:checked {
    background-color: #5dc2a6;
    border: 2px solid #5dc2a6;
}
input[type="checkbox"]:checked::before {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
    font-weight: bold;
}
.center-circle-icon {
  border-radius: 50%;
  background-color: #19B489;
  border: 1px solid #19B489;
  color: white;
  padding: 0.28em;
  opacity: 35%;
  margin: 0px 1px 0px 1px;
}
.center-circle-icon-long {
    border-radius: 30px;
    background-color: #19B489;
    border: 1px solid #19B489;
    color: white;
    padding: 0em 0.6em;
    margin: 0px 1px 0px 1px;
    height: 10px;
}
.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 2px solid #CCCCCC;
  border-radius: 6px;
  cursor: pointer !important;
}
.checkmark-container {
  border: 2px solid #CCCCCC;
}

.custom-checkbox:checked ~ .checkmark {
  background-image: url(/images/svg/enabled_checkbox.svg);
  border: none;
  background-position: center;
  background-size: 26px 26px;
  cursor: pointer !important;
}
.custom-checkbox {
  display: none
}
.custom-checkbox-label {
  position: relative;
  padding-left: 35px !important; /* Adjust to position text correctly */
  font: 400 14px/24px "Poppins", sans-serif !important;
  color: #3C3C3C !important;
}
.custom-checkbox-label input[disabled] + .checkmark {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.app-settings-save-btn {
  text-transform: capitalize !important;
}
</style>
