<script>
export default {
  name: 'CompanyDetailsIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_431_2071" fill="white">
      <path d="M4 7C4 5.89543 4.89543 5 6 5H11C12.1046 5 13 5.89543 13 7V19C13 19.5523 12.5523 20 12 20H5C4.44772 20 4 19.5523 4 19V7Z"/>
    </mask>
    <path d="M6 6H11V4H6V6ZM12 7V19H14V7H12ZM12 19H5V21H12V19ZM5 19V7H3V19H5ZM5 19H5H3C3 20.1046 3.89543 21 5 21V19ZM12 19V21C13.1046 21 14 20.1046 14 19H12ZM11 6C11.5523 6 12 6.44772 12 7H14C14 5.34315 12.6569 4 11 4V6ZM6 4C4.34315 4 3 5.34315 3 7H5C5 6.44772 5.44772 6 6 6V4Z" fill="currentColor" mask="url(#path-1-inside-1_431_2071)"/>
    <rect x="6" y="7" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="8" y="7" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="10" y="7" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="6" y="9" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="8" y="9" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="10" y="9" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="6" y="11" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="8" y="11" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="10" y="11" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="6" y="13" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="8" y="13" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="10" y="13" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="6" y="15" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="8" y="15" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="10" y="15" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="6" y="17" width="1" height="1" rx="0.25" fill="currentColor"/>
    <rect x="8" y="17" width="1" height="3" rx="0.25" fill="currentColor"/>
    <rect x="10" y="17" width="1" height="1" rx="0.25" fill="currentColor"/>
    <path d="M15 11.25C15 11.1119 15.1119 11 15.25 11H15.75C15.8881 11 16 11.1119 16 11.25V11.75C16 11.8881 15.8881 12 15.75 12H15.25C15.1119 12 15 11.8881 15 11.75V11.25Z" fill="currentColor"/>
    <path d="M17 11.25C17 11.1119 17.1119 11 17.25 11H17.75C17.8881 11 18 11.1119 18 11.25V11.75C18 11.8881 17.8881 12 17.75 12H17.25C17.1119 12 17 11.8881 17 11.75V11.25Z" fill="currentColor"/>
    <path d="M15 13.25C15 13.1119 15.1119 13 15.25 13H15.75C15.8881 13 16 13.1119 16 13.25V13.75C16 13.8881 15.8881 14 15.75 14H15.25C15.1119 14 15 13.8881 15 13.75V13.25Z" fill="currentColor"/>
    <path d="M17 13.25C17 13.1119 17.1119 13 17.25 13H17.75C17.8881 13 18 13.1119 18 13.25V13.75C18 13.8881 17.8881 14 17.75 14H17.25C17.1119 14 17 13.8881 17 13.75V13.25Z" fill="currentColor"/>
    <path d="M15 15.25C15 15.1119 15.1119 15 15.25 15H15.75C15.8881 15 16 15.1119 16 15.25V15.75C16 15.8881 15.8881 16 15.75 16H15.25C15.1119 16 15 15.8881 15 15.75V15.25Z" fill="currentColor"/>
    <path d="M17 15.25C17 15.1119 17.1119 15 17.25 15H17.75C17.8881 15 18 15.1119 18 15.25V15.75C18 15.8881 17.8881 16 17.75 16H17.25C17.1119 16 17 15.8881 17 15.75V15.25Z" fill="currentColor"/>
    <path d="M15 17.25C15 17.1119 15.1119 17 15.25 17H15.75C15.8881 17 16 17.1119 16 17.25V19.75C16 19.8881 15.8881 20 15.75 20H15.25C15.1119 20 15 19.8881 15 19.75V17.25Z" fill="currentColor"/>
    <path d="M17 17.25C17 17.1119 17.1119 17 17.25 17H17.75C17.8881 17 18 17.1119 18 17.25V17.75C18 17.8881 17.8881 18 17.75 18H17.25C17.1119 18 17 17.8881 17 17.75V17.25Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 9V10H18C18.5523 10 19 10.4477 19 11V19L14 19V20H19C19.5523 20 20 19.5523 20 19V11C20 9.89543 19.1046 9 18 9H14Z" fill="currentColor"/>
  </svg>
</template>