<script>
    export default {
        name: "SingleMonthlyCarryOverBudgetIcon",
    };
</script>

<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_80_5116)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 8C16.5 10.21 14.71 12 12.5 12C10.29 12 8.5 10.21 8.5 8C8.5 5.79 10.29 4 12.5 4C14.71 4 16.5 5.79 16.5 8ZM4.5 18C4.5 15.34 9.83 14 12.5 14C12.5452 14 12.5911 14.0004 12.6378 14.0012C12.4881 14.3331 12.4052 14.7006 12.405 15.0852L12.405 15.0864L12.4015 20H5.5C4.95 20 4.5 19.55 4.5 19V18Z" fill="#3C3C3C"/>
        <g clip-path="url(#clip1_80_5116)">
        <path d="M21.9946 14.0909H21.4946V13.5909C21.4946 13.3159 21.2696 13.0909 20.9946 13.0909C20.7196 13.0909 20.4946 13.3159 20.4946 13.5909V14.0909H16.4946V13.5909C16.4946 13.3159 16.2696 13.0909 15.9946 13.0909C15.7196 13.0909 15.4946 13.3159 15.4946 13.5909V14.0909H14.9946C14.4396 14.0909 13.9996 14.5409 13.9996 15.0909L13.9946 22.0909C13.9946 22.6409 14.4396 23.0909 14.9946 23.0909H21.9946C22.5446 23.0909 22.9946 22.6409 22.9946 22.0909V15.0909C22.9946 14.5409 22.5446 14.0909 21.9946 14.0909ZM21.9946 21.5909C21.9946 21.8659 21.7696 22.0909 21.4946 22.0909H15.4946C15.2196 22.0909 14.9946 21.8659 14.9946 21.5909V16.5909H21.9946V21.5909Z" fill="#3C3C3C"/>
        <g clip-path="url(#clip2_80_5116)">
        <path d="M19.9547 18.7727C19.5343 18.4068 18.9888 18.1818 18.3866 18.1818C17.4411 18.1818 16.6275 18.7318 16.2411 19.5295C16.1684 19.6818 16.2502 19.8636 16.4116 19.9182C16.5456 19.9636 16.6911 19.9 16.7525 19.7727C17.0479 19.1682 17.6684 18.75 18.3866 18.75C18.8297 18.75 19.2343 18.9136 19.5502 19.1773L19.1161 19.6114C18.9729 19.7545 19.0729 20 19.2752 20H20.5456C20.6706 20 20.7729 19.8977 20.7729 19.7727V18.5023C20.7729 18.3 20.5275 18.1977 20.3843 18.3409L19.9547 18.7727Z" fill="#3C3C3C"/>
        </g>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_80_5116">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
        <clipPath id="clip1_80_5116">
        <rect width="12" height="12" fill="white" transform="translate(12.5 12)"/>
        </clipPath>
        <clipPath id="clip2_80_5116">
        <rect width="5.45455" height="5.45455" fill="white" transform="translate(15.7729 16.3636)"/>
        </clipPath>
        </defs>
    </svg>
</template>
