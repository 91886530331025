<template>
    <questions-table-view ref="questions-table-view"
                          v-if="questions"
                          :questions="questions"
                          :loading="loading"
                          :currentuser="currentuser"
                          @on-update="updateQuestion" />
</template>

<script>
import QuestionsTableView from "./QuestionsTableView.vue";

export default {
    name: "QuestionsTableData",
    props: {
        currentuser: { type: Object, required: true },
        microsite: { type: Object, required: true }
    },
    components: {
        QuestionsTableView,
    },
    data() {
        return {
            questions: [],
            loading: true,
        };
    },
    methods: {
        async getCountry() {
            const countryCode = this.currentuser.customer.microsite.country_code;
            const countries = {
                IE: 'Republic of Ireland',
                AU: 'Australia',
                NL: 'Netherlands'
            };

            return countries[countryCode] || 'UK';
        },
        async getQuestions() {
            this.loading = true;

            try {
                const { data } = await axios.get("/dashboard/screening-questions");
                this.questions = data;
                const countryName = await this.getCountry();
                this.questions.forEach(question => {
                    question.text = question.text.replace('[country]', countryName);
                });

                this.loading = false;
            } catch (err) {
                Vue.swal({
                    title: "Oops!",
                    text: "Something went wrong.",
                    confirmButtonColor: "#ec5168",
                    allowOutsideClick: false,
                });
                this.loading = false;
            }
        },
        async updateQuestion(data) {
            const index = this.questions.findIndex((question) => (question.id === data.old_question_id));

            if (index !== -1) {
                this.$set(this.questions, index, data);
            }
        }
    },
    async created() {
        await this.getQuestions();
    },
};
</script>