<script>
export default {
    name: "SortIcon",
};
</script>

<template>
    <svg width="10"
         height="18"
         viewBox="0 0 10 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M5.00001 2.82997L7.46001 5.28997C7.85001 5.67997 8.48001 5.67997 8.87001 5.28997C9.26001 4.89997 9.26001 4.26997 8.87001 3.87997L5.70001 0.699971C5.31001 0.309971 4.68001 0.309971 4.29001 0.699971L1.12001 3.87997C0.730015 4.26997 0.730015 4.89997 1.12001 5.28997C1.51001 5.67997 2.14001 5.67997 2.53001 5.28997L5.00001 2.82997ZM5.00001 15.17L2.54001 12.71C2.15001 12.32 1.52001 12.32 1.13001 12.71C0.740015 13.1 0.740015 13.73 1.13001 14.12L4.30001 17.3C4.69001 17.69 5.32001 17.69 5.71001 17.3L8.88001 14.13C9.27001 13.74 9.27001 13.11 8.88001 12.72C8.49001 12.33 7.86001 12.33 7.47002 12.72L5.00001 15.17Z"
              fill="#5DC2A6" />
    </svg>
</template>
