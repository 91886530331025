<script>
export default {
  name: 'ArrowDownIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8805 9.29055L12.0005 13.1705L8.12047 9.29055C7.73047 8.90055 7.10047 8.90055 6.71047 9.29055C6.32047 9.68055 6.32047 10.3105 6.71047 10.7005L11.3005 15.2905C11.6905 15.6805 12.3205 15.6805 12.7105 15.2905L17.3005 10.7005C17.6905 10.3105 17.6905 9.68055 17.3005 9.29055C16.9105 8.91055 16.2705 8.90055 15.8805 9.29055Z" fill="currentColor"/>
  </svg>
</template>