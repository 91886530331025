<template>
    <section :style="cssVariables">
        <div class="templates-header">
            <h2 style="padding: 0 !important;">
                Use this page to view and manage your organisation’s message templates. These templates can then be used
                in other areas of the portal e.g. auto-responding to new candidates
            </h2>
        </div>

        <templates-table ref="templates-table" :currentuser="currentuser" />
    </section>
</template>

<script>
import TemplatesTable from "../components/TemplatesTable";
import { cssVariables } from "../../../lib/css-variables";

export default {
    props: {
        currentuser: { type: Object, required: true }
    },
    components: { TemplatesTable },
    name: "Templates",
    created() {
        this.cssVariables = cssVariables();
    },
};
</script>

<style scoped lang="scss">
@import "./Templates.scss";
</style>