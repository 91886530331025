<template>
    <div v-if="selectedJobs.length > 0"
         id="job-selected"
         class="invitee-users-bar">
        <div v-if="backdropVisible"
             class="modal-backdrop fade show"></div>
        <section class="task-bar-row">
            <div class="inner-box">
                <div class="inner-row-left">
                    <strong class="number"></strong>
                    <a @click="closeTaskBar()"
                       class="cross">
                        <img src="/images/cancel.png"
                             alt="img" />
                    </a>
                    <strong class="title">
                        {{ selectedJobs.length }} Job(s) selected
                    </strong>
                </div>
                <ul class="inner-row-right">
                    <li
                        v-if="pointTariffsFeatureFlag && currentuser.customer.variable_points_tariffs && currentuser.tariff_check">
                        <a @click="bulkAssignTariff">
                            <icon name="plus" />
                            <span>Assign tariff</span>
                        </a>
                    </li>
                    <li v-if="currentuser.job_check == 1">
                        <a @click="bulkActivate()">
                            <icon name="play" />
                            <span>Activate</span>
                        </a>
                    </li>
                    <li v-if="currentuser.job_check == 1">
                        <a @click="bulkDeactivate()">
                            <icon name="pause" />
                            <span>Deactivate</span>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import { Icon } from '../../../../app/components/Icon';

export default {
    name: 'TaskBar',
    props: {
        currentuser: { type: Object, required: true },
        jobs: { type: Array, default: () => [] },
        jobChecked: { type: Array, default: () => [] },
        pointTariffsFeatureFlag: { type: Boolean, default: false }
    },
    components: {
        Icon,
    },
    data() {
        return {
            selectedJobs: this.jobChecked,
            backdropVisible: false,
        };
    },
    methods: {
        bulkAssignTariff() {
            const filteredJobs = this.jobs.filter((value) => this.selectedJobs.includes(value.id));

            if (filteredJobs.length) {
                this.$emit('assign-bulk-tariffs', filteredJobs);
            }
        },
        bulkActivate() {
            this.showModalLoading();
            var self = this;
            axios
                .post("/dashboard/jobs/bulk-activate", {
                    jobs: this.selectedJobs
                })
                .then(response => {
                    this.$emit('on-bulk-update');
                    self.closeTaskBar();
                    Vue.swal.close();
                    this.showSuccessToast(response.data.successCount + ' Job(s) Activated');
                })
                .catch(error => {
                    console.error("bulkActivate Error:", error);
                });
        },
        bulkDeactivate() {
            this.showModalLoading();
            var self = this;
            axios
                .post("/dashboard/jobs/bulk-deactivate", {
                    jobs: this.selectedJobs
                })
                .then(response => {
                    this.$emit('on-bulk-update');
                    self.closeTaskBar();
                    Vue.swal.close();
                    this.showSuccessToast(response.data.successCount + ' Job(s) Deactivated');
                })
                .catch(error => {
                    console.error("bulkDeactivate Error:", error);
                });
        },
        showTaskBar() {
            $("#job-selected").show();
            this.backdropVisible = false;
        },
        closeTaskBar() {
            this.selectedJobs = [];
            $("#job-selected").hide();
            this.backdropVisible = false;
            this.$emit('update:jobChecked', this.selectedJobs);
        },
        showModalLoading() {
            Vue.swal({
                title: "Updating...",
                html: "",
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Vue.swal.showLoading();
                }
            });
        },
        showSuccessToast(message) {
            Vue.swal({
                html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> ' + message,
                confirmButtonColor: "#5dc2a6",
                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: {
                    container: "candidate-toast"
                },
                showClass: {
                    popup: "animated fadeInLeft fast",
                    icon: "animated heartBeat delay-1s"
                },
                hideClass: {
                    popup: "animated fadeOutLeft slow"
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
            });
        }
    },
    watch: {
        jobChecked(newValue) {
            this.selectedJobs = newValue;
        },
    }
};
</script>

<style scoped lang="scss">
@import "./TaskBar.scss";
</style>