<template>
  <section>
    <header v-if="title">
      <h2>{{ title }}</h2>
    </header>
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./WidgetBox.scss";
</style>
