<template>
  <div>
   <div class="input-box">
    <input class="idle b_margin_1-25" type="text" placeholder="eg. Facebook champion" v-model="content.Title">
   </div>
    <span :class="messageStatus" v-if="message">
        {{ message }} <br>
    </span>

    <button type="button" style="float:right" @click="save()" :class="btnClass">Add new reward category</button>

  </div>
</template>

<script>
    export default {
        props: ['subComponent'],
        data(){
          return {
            content : {
              Title: '',
            },
            message: null,
            messageStatus: 'green',
            btnStatus: false,
            btnClass: "button pink_btn b_margin_0 no_wrap_btn disabled",
          }
        },
        watch: {
          btnStatus: function(val){
            if (this.btnStatus) {
              this.btnClass = "button pink_btn b_margin_0 no_wrap_btn";
            } else {
              this.btnClass = "button pink_btn b_margin_0 no_wrap_btn disabled";
            }
          },
          "content.Title": function(val){
            if (this.content.Title != '') {
             this.btnStatus = true;
            } else {
              this.btnStatus = false;
            }
          }
        },
        methods: {
          save(){
              this.message = null;
              var self = this;
              axios.post('/dashboard/account/reward-cat/add', this.content)
                  .then(function (response) {
                      // handle success
                      self.messageStatus = "green";
                      self.message = response.data.message;
                      self.content.Title = "";
                      // self.$parent.updateData();
                      self.subComponent.$emit("updateCategories");
                  })
                  .catch(function (error) {
                      // handle error
                      self.message = error.response.data.error;
                      self.messageStatus = "red";

                  })
          },
      }
    }
</script>
