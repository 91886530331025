<template>
    <div>
        <label v-if="!notShowLabel" class="bonus-label">SELECT SITE</label>
        <v-select class="bonus-placeholder" placeholder="Add site" v-model="site_id" :reduce="referrer => referrer.id" label="name"
                  :options="availableSites" :clearable="false"></v-select>
        <input type="hidden" name="site_id" :value="site_id">
    </div>
</template>

<script>
    export default {
        props: ['sites', 'notShowLabel', 'siteId'],
        data() {
            return {
                availableSites: [],
                site_id: this.siteId
            }
        },
        watch: {
            site_id: function (val) {
                this.$emit('selectedSite', this.site_id)
            }
        },
        methods: {
            getAvailableSites() {
                //Create array of available users for dropdown
                var toProcess = Object.keys(this.sites);

                for (var i = toProcess.length - 1; i >= 0; i--) {
                    var site = {
                        name: this.sites[toProcess[i]].name,
                        id: this.sites[toProcess[i]].id
                    }
                    this.availableSites.push(site);
                }
            }
        },
        mounted() {
            this.getAvailableSites();
        }
    }
</script>
