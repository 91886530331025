<template>
  <div class="tabs-content box_shadow candidate-width-head">
    <div v-show="showLoad" class="show-load-candidate">
      <img :src="myImage" />
    </div>
    <div v-if="showEmptyState" class="empty-state empty-state-back">
      <img src="/images/icon-empty-state-points.png" />
      <p>You haven’t created any bonus point categories yet.</p>
      <template>
        <input
          type="button"
          value="Add Category"
          class="button pink_btn b_margin_0 no_wrap_btn"
          @click="addCategoryPressed"
        />
      </template>

      <br />
    </div>
    <div v-else>
      <table>
        <thead class="table-body-styling">
          <tr class="table-history-styling">
            <th class="table-head-styling-catagory table-content" scope="col">
              CATEGORY NAME
            </th>
            <th class="table-head-styling1" scope="col">POINTS</th>
            <th class="table-head-styling1" scope="col">TITLE</th>
            <th class="table-head-styling1" scope="col">IMAGE</th>
            <th class="table-head-styling1" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(category, index) in categories" class="table-row-aliment">
            <td
              scope="row"
              class="table-notification history-table-row-styling"
            >
              <div class="lock-icon-styling">
                <!-- {{ category.name }} -->

                <span
                  v-b-tooltip.hover.html="{
                    customClass: 'Notification-tooltip',
                    title: category.name,
                  }"
                  class="p-stylingbonus tool-tip-content"
                  v-html="category.name"
                >
                </span>

                <img
                  class="lock-img custom-lock"
                  v-if="category.points_type == 'fixed'"
                  src="/images/icon-24-lock@2x.png"
                  alt=""
                />

                <!-- <i
                class="fas fa-lock custom-lock"
                v-if="category.type == 'fixed'"
              ></i> -->
              </div>
            </td>
            <td
              class="table-CREATED-styling1 history-table-row-styling points-w"
            >
              <div class="lock-icon-styling">
                <span
                  class="p-stylingbonus tool-tip-content pointstips"
                  v-html="category.points"
                >
                </span>

                <img
                  class="lock-img custom-lock"
                  v-if="category.content_type == 'fixed'"
                  src="/images/icon-24-lock@2x.png"
                  alt=""
                />

                <!-- <i
                class="fas fa-lock custom-lock"

              ></i> -->
              </div>
            </td>
            <td class="Title-row-tooltip">
              <span
                v-b-tooltip.hover.html="{
                  customClass: 'Notification-tooltip',
                  title: category.Title,
                }"
                class="p-stylingbonus tool-tip-content-title"
                v-html="
                  category.Title &&
                  category.Title.replace(/<\/?[^>]+(>|$)/g, '')
                "
              >
              </span>
            </td>
            <td class="table-data-styling history-table-row-styling">
              <img
                v-if="category.image_url"
                class="bonus-img"
                :src="category.image_url"
              />
            </td>
            <td
              class="
                table-data-styling
                history-table-row-styling
                more-icon-align
              "
            >
              <div v-if="currentuser.bonus_categories_check == 1">
                <button
                  class="dropdown text-align-left"
                  type="button"
                  :id="'d' + index"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="showMenu()"
                >
                  <img
                    class="more-icon-categories"
                    src="/images/icon-24-more-2.png"
                    alt=""
                  />
                </button>
                <div
                  class="dropdown-menu dropdown-pane radius-3"
                  :aria-labelledby="'d' + index"
                  data-alignment="right"
                >
                  <ul>
                    <template>
                      <div class="more-edit-category">
                        <li
                          class="list-style-categories"
                          @click="editCategory(category, index)"
                        >
                          <a>Edit</a>
                        </li>
                        <li
                          class="list-style-categories"
                          @click="deleteCategory(category, index)"
                        >
                          <a>Delete</a>
                        </li>
                      </div>
                    </template>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <edit-category-modal
      @categoryEdited="categoryEdited"
      :content="categoryContent"
    ></edit-category-modal>
    <b-modal class="tab-modal" id="modal_delete" size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <div class="discard-header4">
              <h3 class="discard-header-title">Are you sure you want to delete this category</h3>
            </div>
            <br>
            <p class="if-you-discard-chang">It can not be undone.</p>
          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input
          class="button btn_lg btn pink_btn"
          type="submit"
          value="Delete"
          style="
            float: right;
            margin-right: -13px;
            margin-bottom: 13px;
            margin-left: 7px;
          "
          @click="deleteCategoryPermanent"
        />
        <input
          type="button"
          class="button btn_lg btn orange_btn anon-can"
          value="Cancel"
          @click="closeModal"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import EditCategoryModal from "./EditCategoryModal";
export default {
  components: { EditCategoryModal },
  props: ["currentuser"],
  data: function () {
    return {
      categories: [],
      showEmptyState: false,
      showLoad: false, // setting it true by default.
      myImage: "/images/loading-circle.gif",
      categoryContent: {},
    };
  },
  computed: {},
  methods: {
    getData: function () {
      let width = this.$refs.box.clientWidth;
      let height = this.$refs.box.clientHeight;
      console.log(width + ", " + height);
    },

    addCategoryPressed() {
      if (this.currentuser.bonus_categories_check == 1) {
        this.$bvModal.show("modal-notification");
      } else {
        this.$bvModal.show("modal_unaunthenticated");
      }
    },
    showDeleteNotificationModal(id, index) {
      this.currentIndex = index;

      let value = this.notifications[index];
      this.currentObject = Object.assign({}, value);
      this.$bvModal.show("modal_delete");
    },
    fetchRewardCatagories() {
      var self = this;
      self.showLoad = true;
      axios
        .get("/dashboard/account/reward-cat-display")
        .then(function (response) {
          self.showLoad = false;
          // handle success
          self.categories = response.data.rewardCatagories;
          if (response.data.rewardCatagories.length < 1) {
            self.showEmptyState = true;
          } else {
            self.showEmptyState = false;
          }
        })
        .catch(function (error) {
          self.showLoad = false;
          // handle error
          console.log(error);
        });
    },
    showMenu() {
      $(this.$el).foundation();
    },
    editCategory(category, index) {
      this.categoryContent = {
        index: index,
        id: category.id,
        Title: category.Title,
        name: category.name,
        message: category.message,
        type: category.type,
        points_type: category.points_type,
        content_type: category.content_type,
        image_url: category.image_url,
        image_name: "",
        points: category.points,
        max_points: category.max_points,
      };
      this.$bvModal.show("edit-category-modal");
    },
    deleteCategory(category, index) {
      this.categoryContent = {
        index: index,
        id: category.id,
      };
      this.$bvModal.show("modal_delete");
    },
    deleteCategoryPermanent() {
      var self = this;
      axios
        .post("/dashboard/account/reward-cat/delete", {
          id: this.categoryContent.id,
        })
        .then(function (response) {
          if (response.status == 200) {
            let temp = JSON.parse(JSON.stringify(self.categories));
            if (self.categoryContent.index > -1) {
              temp.splice(self.categoryContent.index, 1);
            }
            self.categories = JSON.parse(JSON.stringify(temp));
            self.$bvModal.hide("modal_delete");

            if (temp.length < 1) {
              self.showEmptyState = true;
            } else {
              self.showEmptyState = false;
            }

            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Category Deleted',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          } else {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> Could not delete Category',
              confirmButtonColor: "#ea2020",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#ea2020",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
        })
        .catch(function (error) {});
    },
    categoryEdited(updatedContent) {
      let temp = JSON.parse(JSON.stringify(this.categories));
      temp[updatedContent.index] = updatedContent;
      this.categories = JSON.parse(JSON.stringify(temp));
    },
    closeModal() {
      this.$bvModal.hide("modal_delete");
    },
  },
  mounted() {
    this.fetchRewardCatagories();
    this.$root.$on("categoryAdded", (updatedContent) => {
      let temp = JSON.parse(JSON.stringify(this.categories));
      temp.push(updatedContent);
      this.categories = JSON.parse(JSON.stringify(temp));

      if (temp.length < 1) {
        this.showEmptyState = true;
      } else {
        this.showEmptyState = false;
      }
    });
  },
};
</script>

<style scoped>
.tabs-content.box_shadow.candidate-width-head {
  width: 100%;
  height: 70vh;
  overflow: auto;
  /* background-color: #ffffff; */
  border-radius: 8px;
}

.table-body-styling1 {
  background-color: #f9f9f9 !important ;
  border-top: 1px solid #ffffff;
}

th.table-head-styling1.table-content {
  /* padding-left: 40px !important; */
  /* padding-left: 0; */
}

.table-head-styling1 {
  /* padding: 0.5rem 0.625rem 0.625rem; */
  font-weight: 600;
  text-align: left;
  font-size: 11px;
  font-family: "InterUI";
  color: #9297ae;
  /* // padding-left: 14px !important; */
  height: 56px !important;
  /* padding-left: 25px; */
}

.bonus-img {
  width: 38px;
  height: 38px;
  /* border-radius: 4px;*/
}

.three-dot-img {
  width: 40px;
}

th.table-CREATED-styling {
  font-size: 14px;
  font-family: "InterUI";
  letter-spacing: normal;
  color: #363b54;
  font-weight: 600;
  text-align: left;
  background-color: #fff !important;
  /* padding-left: 18px; */
  vertical-align: baseline;
  padding-top: 15px;
  padding-left: 18px;
  vertical-align: middle;
  padding-left: 26px;
}

td.table-CREATED1-styling {
  background-color: #fff;
  font-family: InterUI;
  font-size: 14px;
  font-weight: normal;
  color: #363b54;
  padding-left: 25px;
  padding-top: 25px;
}

.history-table-row-styling {
  border-bottom: 1px solid rgba(42, 49, 81, 0.1) !important;
  background-color: #fff;
}

.lock-icon-styling {
  display: flex;
  justify-content: space-between;
}

.table-row-aliment {
  vertical-align: middle;
  font-size: 13px;
  background-color: #fff;
  border-bottom: 1px solid rgba(42, 49, 81, 0.1);
}

.table-head-styling-catagory {
  padding-left: 25px;
  font-weight: 600;
  text-align: left;
  font-size: 11px;
  font-family: "InterUI";
  color: #9297ae;
  /* // padding-left: 14px !important; */
  height: 56px !important;
}

td.table-notification.history-table-row-styling {
  padding-left: 25px;
}
.custom-lock {
  /* margin-top: 2px; */
}

/* .tool-tip-content {
  word-break: break-all;
} */

.more-icon-categories {
  cursor: pointer;
}

.list-style-categories {
  list-style: none;
  color: black;
}
.list-style-categories a {
  color: #363b54;
  font: 400 13px/40px "InterUI", sans-serif;
  padding: 0 15px;
  display: block;
}

.more-edit-category {
  width: 180px;
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 5%), 0 0 4px 0 rgb(0 0 0 / 5%);
  border: solid 1px #e9eaed !important;
  background: #fff;
  position: absolute;
  /* transform: translate3d(1678px, 308px, 0px); */
  top: 0px;
  right: -127px;
  /* will-change: transform; */
  text-align: left;
}
.lock-img {
  width: 24px;
  height: 24px;
}
.pointstips {
  padding-top: 4px !important;
}
.table-data-styling {
  color: #363b54;
  font-size: 18px;
  padding-right: 7px;
  padding-top: 7px !important;
}

td.table-CREATED-styling1.history-table-row-styling.points-w {
  /* width: 20%; */
  max-width: 10px;
}

td.table-data-styling.history-table-row-styling.more-icon-align {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

thead,
tbody,
tfoot {
  border: none !important;
  background-color: #ffffff;
}

tr.table-history-styling {
  background-color: #fafafc;
}

td.table-notification.history-table-row-styling {
  padding-left: 25px;
  width: 31%;
}
.table-row-aliment {
  vertical-align: middle;
  font-size: 13px;
  background-color: #fff;
  border-bottom: 1px solid rgba(42, 49, 81, 0.1);
  height: 55px;
}

/* .p-stylingbonus {
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.tool-tip-content {
  /* word-break: break-all; */
  overflow: hidden;
  width: 439px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tool-tip-content-title {
  overflow: hidden;
  display: block;
  width: 550px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td.Title-row-tooltip {
  width: 42%;
}

@media screen and (max-width: 1596px) {
  .tool-tip-content {
    /* word-break: break-all; */
    overflow: hidden;
    width: 235px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .tool-tip-content-title {
    overflow: hidden;
    display: block;
    width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
