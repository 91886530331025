<script>
export default {
  name: 'ResourceToolkitIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22011 15.6128L7.74817 14.0847C7.96525 14.5111 8.24526 14.9085 8.59316 15.2564C8.94106 15.6043 9.33774 15.8836 9.76484 16.1014L8.23678 17.6294C7.83938 17.3763 7.4625 17.08 7.11601 16.7335C6.76953 16.387 6.47325 16.0101 6.22011 15.6128ZM8.47224 11.5278C8.63771 10.2168 9.61351 9.0501 10.8997 8.62371C11.493 8.42643 12.0877 8.39178 12.6703 8.51764L10.5936 10.5944C10.3595 10.8285 10.3595 11.2089 10.5936 11.4429L12.4066 13.256C12.6406 13.49 13.0211 13.49 13.2551 13.256L15.3319 11.1792C15.4578 11.7619 15.4224 12.3558 15.2265 12.9491C14.7994 14.236 13.6327 15.2118 12.3217 15.3773C11.2441 15.5131 10.194 15.1602 9.44169 14.4078C8.68862 13.6548 8.33577 12.6047 8.47224 11.5278ZM7.19097 7.19182C9.84262 4.54017 14.1227 4.50693 16.7327 7.11687C19.3426 9.7268 19.3087 14.0062 16.657 16.6579C14.675 18.6399 11.7836 19.1582 9.35966 18.2036L11.0362 16.527C11.5022 16.6098 11.9844 16.6296 12.4723 16.5673C14.2592 16.3418 15.7873 15.0697 16.3657 13.326C16.6796 12.3756 16.6874 11.4203 16.3869 10.4876L16.3862 10.4869C16.2801 10.1574 16.0142 9.91135 15.6727 9.82862C15.3262 9.74518 14.967 9.84559 14.7139 10.0987L12.8301 11.9825L11.8664 11.0187L13.7501 9.13495C14.0032 8.8818 14.1043 8.5233 14.0202 8.17611C13.9382 7.83529 13.6935 7.56941 13.3619 7.46264C12.4292 7.16212 11.4732 7.16919 10.5221 7.48385C8.77984 8.06227 7.50704 9.58962 7.28148 11.3765C7.21996 11.8651 7.23905 12.3466 7.32249 12.8133L5.64594 14.4899C4.69134 12.0659 5.20895 9.17384 7.19097 7.19182ZM17.5055 17.5064C20.6253 14.3866 20.6592 9.34496 17.5819 6.26763C14.5039 3.18959 9.46219 3.22354 6.34244 6.34329C3.78625 8.89948 3.30612 12.7454 4.90843 15.745C4.90913 15.7471 4.91055 15.7485 4.91196 15.7513C5.26481 16.4097 5.71382 17.0284 6.26749 17.582C6.81903 18.1336 7.43492 18.5812 8.09041 18.9326C8.09606 18.9354 8.10031 18.9397 8.10596 18.9425C11.1048 20.5427 14.95 20.0619 17.5055 17.5064Z" fill="currentColor"/>
  </svg>
</template>