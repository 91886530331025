<template>
  <div class="grid-container full background_care">
    <div class="grid-x grid-padding-x align-center-middle full_height">
      <div class="cell large-4 medium-8 small-10">
        <div class="login-inner grid-padding-x padding-x-lg">
          <div class="login-small">
            <div class="logo-login-form">
              <img :src="logo" />
            </div>
            <div class="grey-font-sml sml-font-padding">
              <p class="login-bold">
                To help us give you the best experience, please choose the option that best describes your role:
              </p>
            </div>

            <div class="form_container t_margin_2">
              <div class="input-group">
                <v-select
                  v-model="fields.role"
                  :options="roleOptions"
                  :close-on-select="true"
                  style="width: 100%"
                ></v-select>
              </div>
            </div>

            <div class="t_margin_2">
              <button @click="submit" type="submit" class="btn button btn-lg expanded redis_btn bold btn-secondary">
                Submit
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["userroles", "brandLogo"],
  data() {
    return {
      fields: {
        role: "Choose your role",
        role_id: "",
      },
      roleOptions: [],
      error: "",
      logo: this.brandLogo || "/images/cf-login-logo.png",
    };
  },
  methods: {
    submit() {
      for (var i = 0; i < this.userroles.length; i++) {
        if (this.userroles[i].name == this.fields.role) {
          this.fields.role_id = this.userroles[i].id;
        }
      }

      axios
        .post("/assign-role", this.fields)
        .then(function (response) {
          console.log("Rele is Succesfully Updated", response.data);

          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> New Role is assigned',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          window.location.href = "/dashboard";
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log("Error Adding role - error: ", error);
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Role Already Assigned',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        });
    },
  },
  mounted() {
    if (this.userroles.length > 0) {
      for (var i = 0; i < this.userroles.length; i++) {
        this.roleOptions.push(this.userroles[i].name);
      }
    }
  },
};
</script>
