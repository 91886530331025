<template>
    <b-modal class="tab-modal"
             :modal-class="loading ? 'modal--loading' : ''"
             id="assign-tariff"
             hide-header-close
             @hide="clearModal">
        <h2>Assign Tariff</h2>
        <div class="grid-y">
            <div class="grid-x input-box-2">
                <div class="cell auto input-box">
                    <label>
                        THE TARIFF SELECTED WILL BE USED FOR NEW SHARES AND CANDIDATES FOR THE JOB(S) SELECTED.
                    </label>
                    <multiselect class="base-select"
                                 v-model="tariff"
                                 :options="tariffs"
                                 track-by="id"
                                 label="name"
                                 :searchable="false"
                                 :allowEmpty="false"
                                 :showLabels="false"
                                 placeholder="Select Tariff..."></multiselect>
                </div>
            </div>
        </div>
        <template slot="modal-footer"
                  slot-scope="{ cancel }">
            <button class="cancel-button"
                    @click="cancel">Cancel</button>
            <button class="process-button"
                    :disabled="!tariff"
                    @click="submit">Assign</button>
        </template>
    </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'AssignTariff',
    components: {
        Multiselect
    },
    data() {
        return {
            tariffs: [],
            jobs: [],
            tariff: null,
            loading: false
        }
    },
    methods: {
        getTariffs() {
            if (this.tariffs.length > 0) {
                return
            }

            this.loading = true

            axios.get('/point-tariffs?active_only=1')
                .then((response) => {
                    this.tariffs = response.data.data.map(tariff => {
                        return {
                            id: tariff.id,
                            name: tariff.name
                        }
                    })
                })
                .catch(() => {
                    Vue.swal({
                        title: "Oops!",
                        text: "Something went wrong. Please try again.",
                        confirmButtonColor: "#ec5168",
                        allowOutsideClick: false,
                    });
                })
                .finally(() => {
                    this.loading = false
                })
        },
        showModal(jobs) {
            this.getTariffs()

            this.jobs = jobs

            if (jobs.length == 1) {
                this.tariff = {
                    id: jobs[0].point_tariff.id,
                    name: jobs[0].point_tariff.name
                }
            }

            this.$bvModal.show('assign-tariff')
        },
        hideModal() {
            this.$bvModal.hide('assign-tariff')
        },
        clearModal() {
            this.tariff = null
            this.jobs = []
        },
        submit() {
            this.loading = true

            axios.patch('/jobs/assign-tariff', {
                jobIds: this.jobs.map(job => job.id),
                pointTariffId: this.tariff.id
            })
                .then((response) => {
                    this.$emit('on-tariff-assign', response.data.data.jobs)
                    this.hideModal()

                    Vue.swal({
                        html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Job(s) updated successfully!',
                        confirmButtonColor: "#5dc2a6",
                        confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                        background: "#5dc2a6",
                        customClass: {
                            container: "candidate-toast",
                        },
                        showClass: {
                            popup: "animated fadeInLeft fast",
                            icon: "animated heartBeat delay-1s",
                        },
                        hideClass: {
                            popup: "animated fadeOutLeft slow",
                        },
                        toast: true,
                        timer: 5000,
                        position: "top-right",
                        target: "body",
                    });
                })
                .catch(() => {
                    Vue.swal({
                        title: "Oops!",
                        text: "Something went wrong. Please try again.",
                        confirmButtonColor: "#ec5168",
                        allowOutsideClick: false,
                    });
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
}
</script>
<style scoped lang="scss">
@import "./AssignTariff.scss";
</style>