<template>
    <div class="radio-box">
        <label v-for="field in fields"
               :key="field.value"
               class="points-check eoi-radio-label">
            <input type="radio"
                   :name="name"
                   v-model="selectedValue"
                   :value="field.value"
                   @change="emitSelectedValue" />
            <span class="radiomark"></span>
            <span class="radiomark-label">
                {{ field.name }}
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'Radio',
    props: {
        question: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            fields: [],
            selectedValue: null
        };
    },
    created() {
        try {
            this.fields = JSON.parse(this.question.field_options);
        } catch (error) {
            console.error("Invalid JSON in field_options:", error);
        }
    },
    methods: {
        emitSelectedValue() {
            this.$emit('input', this.selectedValue);
        }
    }
};
</script>
