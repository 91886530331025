<template>
    <div>
       <input id="trdfix" type="hidden" name="Description" v-model="content" />
        <ckeditor type="inline" ref="trix" v-model="content" @input="updateField" :height="'300px'" :config="editorConfig"></ckeditor>

    </div>
</template>

<script>
    //import Trix from 'trix';
    import CKEditor from 'ckeditor4-vue/dist/legacy.js';

    Vue.use( CKEditor );
    export default {
        props: ['my'],
        data() {
            if(this.my.Description=='undefined'){ var contents= ""; }else{ var contents=this.my.Description;}
            return {
                content:  contents,
                editorConfig: {
                    plugins: 'basicstyles,undo,link,mentions,emoji',

                    toolbar: [
                        [ 'Bold', 'Italic' ],
                        [ 'NumberedList', 'BulletedList' ],
                        [ 'Undo', 'Redo' ],
                        [ 'EmojiPanel' ]
                    ]
                }
            };
        },

        methods: {
            updateField: function() {

                this.$emit('input',String(this.content));
            }
        },
        watch: {
            value: function() {
                this.content = this.value;
            }
        },
        components: {
            CKEditor
        }
    }


</script>
<style>
    iframe .cke_editable {
        font-size: 1rem !important;
    }
    .job-trix-wrap .cke_editable p{
        font-size: 1rem !important;
    }
    body .cke_editable {
        font-size: 1rem !important;
    }
    body .cke_editable p{
        font-size: 1rem !important;
    }
</style>
