<template>
    <div v-if="isDropdownOpen" class="dropdown-wrapper holders-drop" :style="css" >
       <div ref="dropdown" class="dropdown ">
         <span class="filterHeader"><span class="filter-capitalise"> Filter </span>  <span class="filter-lowercase"> by {{filterby}}</span> </span>
            <div v-if="filterby=='name'" id="filter-name" class="filter-search">
                <input class="filter-search-input" type="text" :placeholder="'Search budget ' + filterby + 's...'" v-model="searchedText" />
                </br>
                <div class="list-container">
                  <ul >
                      <li style="style-type: none" v-for="item in filteredNameItems">
                        <div>
                          <label>
                            <input type="checkbox" class="custom-checkbox" v-model="checkedItems" :value="item" />
                            <span class="checkmark"></span>
                            {{ item }}
                          </label>
                        </div>
                      </li>
                  </ul>
                </div>
            </div>
            <div v-else-if="filterby=='holder'" id="filter-name">
                <input class="filter-search-input" type="text" :placeholder="'Search budget ' + filterby + 's...'" v-model="searchedText" />
                </br>
                <div class="list-container">
                  <ul>
                      <li v-for="item in filteredHolderItems" style="style-type: none">
                      <div>
                          <label>
                            <input type="checkbox" class="custom-checkbox" v-model="checkedItems" :value="item" />
                            <span class="checkmark"></span>
                            {{ item }}
                          </label>
                          </div>
                      </li>
                  </ul>
                </div>
            </div>
            <div v-else id="filter-name">
                <input class="filter-search-input" type="text" :placeholder="'Search budget ' + filterby + 's...'" v-model="searchedText" />
                </br>
                <div class="list-container">
                  <ul>
                      <li v-for="item in filteredTypeItems" style="style-type: none">
                      <div>
                          <label>
                            <input type="checkbox" class="custom-checkbox" v-model="checkedItems" :value="item" />
                            <span class="checkmark"></span>
                            {{ item }}
                          </label>
                          </div>
                      </li>
                  </ul>
                </div>
            </div>
            <hr>
            <div style="display:flex;justify-content: space-between;">
                <a @click='clearFilter' href="javascript:void(0) " class="clear_btn float-right">
                  <span class="button-title">Clear</span> &nbsp;filter
                </a>
                <a @click="handleButtonClick" href="javascript:void(0)" :style="applyBtnDynamicStyle" class="apply_btn float-right">
                  <span class="button-title">Apply</span> &nbsp;filter
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default   {
        props:{
            filterby:String,
            css:String,
            isDropdownOpen: {
                type: Boolean,
                default: false,
            },
            itemsName: {
                type: Array,
                default: () => [],
            },
            itemsHolder: {
                type: Array,
                default: () => [],
            },
            itemsType: {
                type: Array,
                default: () => [],
            },
            checkboxes: Array,


        },
        data(){
            return{
                query:'',
                checkedItems: [],
                searchedText: "",
                toClear: false,
                isOpen: false,
            };
        },
        methods: {
            clearFilter(){
                this.toClear = true;
                this.checkedItems = [];
                this.searchedText = "";
                this.handleButtonClick();
            },
            handleButtonClick() {
                if (this.toClear || this.checkedItems.length > 0) {
                  this.toClear = false;
                  this.$emit("selectedValues", this.checkedItems);
                }
            },
        },
        computed:{
            applyBtnDynamicStyle() {
              return {
                backgroundColor: this.checkedItems.length > 0 ? '#55c2a7' :'#CCCCCC',
                cursor: this.checkedItems.length > 0 ? 'pointer' : 'default',
              }
            },
            filteredNameItems:function(){
                var self=this;
                console.log(self.itemsName);
                return self.itemsName.filter(function(val){
                    return val.toLowerCase().indexOf(self.searchedText.toLowerCase()) !== -1;
                })
            },
            filteredHolderItems:function(){
                var self=this;
                return self.itemsHolder.filter(function(val){
                    return val.toLowerCase().indexOf(self.searchedText.toLowerCase()) !== -1;
                })
            },
            filteredTypeItems: function () {
                var self = this;
                return self.itemsType.filter(function (val) {
                    return val.toLowerCase().indexOf(self.searchedText.toLowerCase()) !== -1;
                });
            },
        },
    }
</script>
<style lang="scss" scoped>
.button-title {
  text-transform: capitalize !important;
}
.list-container {
  margin-top: 10px;
  height: auto;
  max-height: 300px;  /* adjust this value according to your requirements */
  overflow-y: auto;
}
label {
  position: relative;
  padding-left: 45px; /* Adjust to position text correctly */
  cursor: pointer;
  font-size: 14px;
  color: #545454;
  line-height: 24px;
  display: block;
  margin-bottom: 12px;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust to position checkmark closer to text */
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 2px solid #CCCCCC;
  border-radius: 6px;
}

.custom-checkbox:checked ~ .checkmark {
  background-image: url(/images/svg/enabled_checkbox.svg);
  border: none;
  background-position: center;
  background-size: 26px 26px;
}

.custom-checkbox {
  display: none
}

.holders-drop{
    top: 43px;
    scrollbar-width: thin;
    position:absolute !important;
    overflow-y: hidden;
    overflow-x: hidden;
    background: var(--greys-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
}

.holders-drop{
  .dropdown {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 12px;
      padding: 22px;
      width: 350px;
  }
  #filter-name ul {
      list-style: none;
      padding: 0;
      margin-left: 2px !important;
  }
  #filter-name ul li {
      margin-bottom: 5px;
      padding: 4px 1px;
  }
  #filter-name ul li div {
      display: flex;
      font-size: 14px;
      font-family:"Poppins", sans-serif;
      font-weight:500;
      text-transform: capitalize;
  }
  #filter-name ul li div input {
      margin-right: 5px;
  }
  #filter-name [type="checkbox"]{
      margin-right:10px !important;
      width:20px;
      height:20px;
  }
  #filter-name [type="text"] {
      border-radius:12px;
      margin-top: 10px;
  }

  #filter-name [type="text"] {
    margin-top: 10px;
  }
  #filter-name [type="checkbox"]:checked {
      accent-color: #5dc2a6 !important;
  }
  .clear_btn {
      background-color: #FFFFFF;
      border-radius: 24px;
      height: 40px;
      border: 2px solid #5dc2a6;
      padding: 0 22px !important;
      color: #55c2a7;
      letter-spacing: 0.2px;
      font: 700 16px "Poppins", sans-serif;
      text-transform: lowercase;
      display: flex;
      justify-content: center; /* centers horizontally */
      align-items: center;
      box-shadow: 0px 0px rgba(0, 0, 0, 0);
      transition: all 0.3s;
  }
  .clear_btn:hover, .clear_btn:focus{
      background-color: #41a98c !important;
      color: #ffffff;
      border: 2px solid #41a98c;
  }
  .apply_btn{
      border-radius: 24px;
      height: 40px;
      border: 0;
      padding: 0 22px !important;
      color: #ffffff;
      letter-spacing: 0.2px;
      font: 700 16px "Poppins", sans-serif;
      text-transform: lowercase;
      display: flex;
      justify-content: center; /* centers horizontally */
      align-items: center;
      box-shadow: 0px 0px rgba(0, 0, 0, 0);
      transition: all 0.3s;
  }
  .apply_btn:hover, .apply_btn:focus{
    background-color: #41a98c;
    color: #ffffff;
  }
  .filterHeader{
      font: 600 16px "Poppins", sans-serif;
      color:#3C3C3C;
      margin: 0 0 10px 0;
  }
  .filter-capitalise {
    text-transform: capitalize;
  }
  .filter-lowercase {
    text-transform: lowercase;
  }
  .filter-search-input {
    background: #fff url(/images/search.png) no-repeat;
    font-size: 14px !important;
    background-position: 10px;
    padding-left: 40px;
  }

  .disabled-button {
    background-color:#CCCCCC;
  }
}
</style>