// Found here https://medium.com/@tommaso.marcelli/setting-up-vue-2-and-foundation-6-3f858b4ad20 - Yay!
module.exports = {
  mounted() {
    // eslint-disable-next-line
    $(this.$el).foundation();
  },
  destroyed() {
    // eslint-disable-next-line
    $(this.$el).foundation.destroy();
  }
};
