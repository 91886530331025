<template>
  <div class="bonus-points-table">
    <div v-if="currentuser.bonus_check == 1" class>
      <div class="cell auto">
      </div>
    </div>

    <bonus-point-history-component
      :link="BonusPointsHistoryLink"
      :taxName="taxName"
      :dropdown-categories="this.dropdownCategories"
    ></bonus-point-history-component>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue/dist/legacy.js";

export default {
  props: {
    users: {
      type: Array
    },
    currentUserId: {
      type: Number,
      default: ""
    },
    mobileUsers: {
      type: Array
    },
    sites: {
      type: Array
    },
    currentuser: {
      type: Object
    },
    taxName: {
        type: String
    },
    dropdownCategories: {
      type: Array
    }
  },
  data() {
    return {
      childComponant: new Vue(),
        subComponent: new Vue(),
      BonusPointsHistoryLink: new Vue(),
      form: {
        user_id: null,
        reward_catagory_id: null,
        reason_for_allocation: null,
        points_no: null,
        allocted_by: this.currentUserId,
        who: null,
        site_id: null
      },
      initalForm: this.form,
      btnStatus: false,
      message: null,
      select_who: "",
      selected_who: "",
      items: [],

      // ckeditor configuration
      editorConfig: {
        toolbar: [
          [
            "Bold",
            "NumberedList",
            "BulletedList",
            "EmojiPanel",
            "Undo",
            "Redo",
            "Paste"
          ]
        ],
        removeButtons: "Italic",
        extraPlugins: "emoji"
        //enterMode: 3
      },
      val: 5,
      userCount: 0,
      userlen: 0
    };
  },
  watch: {
    "form.user_id": function (val) {
      //Check if all fields are filled in
      this.allFieldsFilled();
    },
    "form.site_id": function (val) {
      //Check if all fields are filled in
      this.allFieldsFilled();
    },
    "form.reward_catagory_id": function (val) {
      //Check if all fields are filled in
      this.allFieldsFilled();
    },
    "form.reason_for_allocation": function (val) {
      this.allFieldsFilled();
    },
    "form.points_no": function (val) {
      //Check if all fields are filled in
      this.allFieldsFilled();
    },
    select_who: function (val) {
      this.selected_who = val;
      this.form.who = val;
	  this.allFieldsFilled();
    }
  },
  methods: {
    submitBonusPoints: function () {
      Vue.swal({
        title: "Loading...",
        html: "Allocating bonus points!",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        }
      });
      var self = this;
      //console.log(this.form);
      axios
        .post("/dashboard/points/bonus/add", this.form)
        .then(function (response) {
		  //$('#awardBonusModal').hide();
		  self.$bvModal.hide("awardBonusModal");
		  //$("body").removeClass("modal-open");
          //$('.modal-backdrop').remove();
          //self.addbtn = false;
          self.val = 5;
          self.userCount = 0;
          self.BonusPointsHistoryLink.$emit("fetchBonusData");
		  $('#awardBonusModal .close').click(); // modal proper event has issues here.
		  self.resetForm();

          self.message = "Bonus points have been assigned!";
          Vue.swal.close();
          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Points awarded',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "points-toast"
            },
            showClass: {
              popup: "animated fadeInLeft fast swal2-margin-top",
              icon: "animated heartBeat delay-1s"
            },
            hideClass: {
              popup: "animated fadeOutLeft slow swal2-margin-top"
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs"
          });
		  self.$bvModal.hide("awardBonusModal");
		  $("body").removeClass("modal-open");
		  $('.modal-backdrop.show').hide();
        })
        .catch(function (error) {
          // handle error
          self.message = "An error has occurred";
          console.log(error);
        })

    },
    updateData: function () {
      this.childComponant.$emit("fetchRewardCatagories");
    },
      selectedUser: function(value) {
        this.form.user_id = value;

		this.userlen = this.form.user_id.length*2;
        if (this.userCount > 0 && this.form.user_id.length > 0 && this.form.user_id.length < this.userCount) {
            if (this.form.user_id.length % 2 == 0) {
                this.val -= 5;
                this.userCount = this.form.user_id.length;
            }
        } else if (this.form.user_id.length == 0) {
            this.val = 5;
            this.userCount = 0;
        } else if (this.selected_who == 'Multiple app users' && this.form.user_id) {
            if (this.form.user_id.length % 2 == 0) {
                this.val += 5;
                this.userCount = this.form.user_id.length;
            }
        }
    },
    selectedSite: function (value) {
      this.form.site_id = value;
    },
    selectWho: function (value) {
      this.select_who = value;
    },
    selectedReward: function (value) {
      this.form.reward_catagory_id = value;
    },
    resetForm() {
      this.form = {
        user_id: null,
        reward_catagory_id: null,
        reason_for_allocation: null,
        points_no: null,
        allocted_by: this.currentUserId,
        who: null,
        site_id: null
      };
      this.initalForm = this.form;
      this.btnStatus = false;
      this.message = null;
      this.select_who = "";
      this.selected_who = "";
      this.$refs.selectReward.clear();
    },
    allFieldsFilled() {
      if (
        this.select_who &&
        this.form.reward_catagory_id &&
        this.form.reason_for_allocation &&
        this.form.points_no
      ) {
        this.btnStatus = true;
      } else {
        this.btnStatus = false;
      }
    },
    getMobileUsers() {
      return this.$attrs.mobileusers;
    },
    onInput(event) {
      this.form.reason_for_allocation = event.data;
    }
  },
  mounted() {
      this.subComponent.$on('updateCategories', this.updateData);
    if(this.sites.length >1){
	this.items= ["Single app user", "Multiple app users", "All users at site", "All users at organisation", "All current employees"];
	}else{
	this.items= ["Single app user", "Multiple app users", "All users at organisation", "All current employees"];
	}
  },
  components: {
    CKEditor
  },
  computed: {
    isShow: function () {
      if (this.select_who) {
        return true;
      } else return false;
    }
  }
};
</script>
