<script>
export default {
  name: 'SidebarToggleIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.495 14.8185C4.726 14.581 4.721 14.2015 4.4835 13.97L2.46 12L4.4835 10.03C4.721 9.799 4.726 9.419 4.495 9.182C4.2635 8.944 3.8835 8.939 3.6465 9.1705L1.1815 11.57C1.0655 11.683 1 11.8385 1 12C1 12.162 1.0655 12.317 1.1815 12.43L3.6465 14.83C3.763 14.9435 3.914 15 4.065 15C4.2215 15 4.3775 14.9395 4.495 14.8185ZM20.469 12C20.469 11.669 20.2005 11.4 19.869 11.4H7.069C6.738 11.4 6.469 11.669 6.469 12C6.469 12.3315 6.738 12.6 7.069 12.6H19.869C20.2005 12.6 20.469 12.3315 20.469 12ZM20.469 17.4C20.469 17.0685 20.2005 16.8 19.869 16.8H7.069C6.738 16.8 6.469 17.0685 6.469 17.4C6.469 17.7315 6.738 18 7.069 18H19.869C20.2005 18 20.469 17.7315 20.469 17.4ZM19.869 7.2005H7.069C6.738 7.2005 6.469 6.9315 6.469 6.6C6.469 6.269 6.738 6 7.069 6H19.869C20.2005 6 20.469 6.269 20.469 6.6C20.469 6.9315 20.2005 7.2005 19.869 7.2005Z" fill="white"/>
  </svg>
</template>