<template>
  <div class="grid-container full background_care portal-otp">
    <div class="grid-x grid-padding-x align-center-middle full_height">
      <div class="cell large-4 medium-8 small-10">
        <div class="login-inner grid-padding-x padding-x-lg">
          <div class="login-small">
            <div class="logo-login-form">
              <img :src="logo">
            </div>
            <h3 class="login-padding">Type in your code</h3>
            <div v-if="skipError" class="skip-error">
              <div class="cell small-6">
                <div class="grid-x">
                  <div class="cell small-1">
                    <img src="/images/skip-error.png">
                  </div>
                  <div class="cell medium-5">
                    <p>The code you have entered is not valid, please check and try again.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="grey-font-sml sml-font-padding">
              <p class="login-bold">We've sent a security code to {{ data.number }}</p>
            </div>
            <div class="form_container t_margin_2">
              <div class="cell small-10 enter-otp">
                <div class="grid-x">
                  <div class="cell small-1 key">
                    <img src="/images/key.png">
                  </div>
                  <div class="cell medium-8">
                    <input
                      type="text"
                      class="no-outline"
                      v-model="otp"
                      placeholder="Enter 6 digit code"
                    />
                  </div>
                  <div class="cell small-1 resend">
                    <a @click="resendOTP">Resend</a>
                  </div>
                </div>
              </div>
              <div class="input-group">
                <label class="skip-step">Skip this step for 30 days
                  <input
                    id="skip"
                    v-model="skipPassword"
                    type="checkbox"
                  />
                  <span class="skip-checkmark"></span>
                </label>
              </div>
            </div>
            <div class="t_margin_2">
              <b-button
                @click="submit()" v-on:keyup.13="submit" type="submit" id="login-button"
                class="button btn-lg expanded redis_btn bold" :disabled="btnDisabled">
                <img src="/images/svg/white.svg" :class="progress">
                VERIFY
              </b-button>
<!--              <p class="s1"><a>Use another authentication method</a></p>-->
              <p class="s2">Need a hand? <a id="my_custom_link">We can help</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["data", "brandLogo", "hash"],
    data() {
      return {
        progress: "progress-white hide",
        skipError: 0,
        otp: '',
        skipPassword: false,
        logo: this.brandLogo || "/images/cf-login-logo.png"
      }
    },
    methods: {
      resendOTP() {
        axios.get("/resend-otp/" + this.data.id)
          .then(function (response) {
            Vue.swal({
              html:
                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> OTP resent',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "points-toast"
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s"
              },
              hideClass: {
                popup: "animated fadeOutLeft slow"
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#edit-company"
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      submit() {
        var self = this;
        axios.post("/verify-code", {
          id: this.data.id,
          otp: this.otp,
          email: this.data.email,
          password: this.data.password,
          skip: this.skipPassword,
          hash: this.hash
        })
          .then(function (response) {
            if (response.data == 'error') {
              self.skipError = 1;
            } else {
              location.reload();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    computed: {
      btnDisabled: function () {
        if (this.otp.length == 6) {
          return false;
        } else
          return true;
      }
    }
  };
</script>