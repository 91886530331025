<template>
    <div>
        <v-select v-model="site_id" :reduce="referrer => referrer.id" label="name"
                  :options="siteoption"></v-select>
        <input type="hidden" name="site_id" :value="site_id">
    </div>

</template>

<script>
    export default {
        props: ['sitesarr'],
        data() {
            return {
                siteoption: [],
                site_id: this.sitesarr.length > 0 ? this.sitesarr[0].id : ''
            }
        },
        watch: {
            site_id: function (val) {
                this.$emit('selectedsite_id', this.site_id)
            }
        },
        mounted() {
	             var sites = {
                        name: 'All Sites/Head Office',
                        id: ''
                 }
				this.siteoption.push(sites);
				for (var i =0; i<this.sitesarr.length; i++) {
					sites = {
					    name: this.sitesarr[i].name,
					    id: this.sitesarr[i].id
					}
				this.siteoption.push(sites);
				}

        }
    }
</script>
