<template>
  <!--Tabs Start-->
  <section class="tabs-section input-max-520">
    <div class>
      <h2>{{ personal.FirstName }} {{ personal.Surname }}</h2>
      <div class="tabs-row">
        <section class="bg-white" style="overflow: hidden">
          <div class="input-box-2">
            <div class="input-box">
              <label>First Name</label>
              <input
                type="text"
                class
                placeholder="First name"
                v-model="personal.FirstName"
                required
              />
            </div>
            <div class="input-box">
              <label>Last Name</label>
              <input
                type="text"
                class
                placeholder="Last name"
                v-model="personal.Surname"
                required
              />
            </div>
          </div>
          <div class="input-box">
            <label>Role</label>
            <input
              type="text"
              class
              placeholder="role"
              v-model="personal.role"
              disabled
            />
          </div>

          <profile-upload-component
            :users="users"
            :childComponant="childComponant"
            design="btn-upload"
            :initiates="initiates"
          ></profile-upload-component>
        </section>

        <section class="bg-white bg-hight-fixed">
          <p class="dark">login Details</p>

          <div class="input-box">
            <label>EMAIL</label>
            <input
              type="text"
              class
              placeholder="Email"
              v-model="personal.Email"
              required
            />
          </div>
          <br>
          <div class="input-box">
            <label>PHONE NUMBER</label>
            <input
              type="text"
              placeholder="Phone number"
              v-model="personal.Phone"
              required
            />
          </div>

          <div class="update-email" v-if="emailUpdate">
            <input
              class="button btn_lg btn pink_btn"
              type="submit"
              :disabled="btnProfileUpdate"
              value="Update"
              v-on:click="submitpersonalinfo"
            />
          </div>

          <div class="input-box">
            <span class="lock-icon"
              ><i class="fa fa-2x" aria-hidden="true"></i
            ></span>
            <div class="password-box">
              <label>Password</label>
              <span class="password-dots"
                >&#183; &#183; &#183; &#183; &#183; &#183; &#183; &#183;</span
              >
              <!-- <input type="password" class v-model="personal.password" placeholder="0000000" /> -->
            </div>

            <a href="#" v-b-modal.changePassword>Change Password</a>

            <b-modal id="changePassword" modal-class="reset-password" size="sm" centered>
              <h3>Change Password</h3>
              <div v-if="currentError" class="pass-error portal-error">
                <div class="cell small-12">
                  <div class="grid-x">
                    <div class="cell small-1">
                      <img src="/images/skip-error.png">
                    </div>
                    <div class="cell medium-11">
                      <p>{{ current_errors }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell small-12 input-group-field no-height">
                <div class="grid-x">
                  <div class="cell small-11">
                    <input
                      :type="type2"
                      class="no-outline"
                      name="password_confirmation"
                      v-model="pw.CurrentPassword"
                      placeholder="Current password"
                      @change="currentPassword"
                    />
                  </div>
                  <div class="cell small-1 pass-2 pass-2-margin">
                    <img @click="changeConfirmImage" :src="image2">
                  </div>
                </div>
              </div>
              <br>
              <div v-if="passError" class="pass-error portal-error">
                <div class="cell small-12">
                  <div class="grid-x">
                    <div class="cell small-1">
                      <img src="/images/skip-error.png">
                    </div>
                    <div class="cell medium-11">
                      <p>{{ errors }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell small-12 input-group-field no-height">
                <div class="grid-x">
                  <div class="cell small-11">
                    <input
                      :type="type"
                      class="no-outline"
                      name="password"
                      v-model="pw.NewPassword"
                      placeholder="New password"
                      @input="validatePassword"
                    />
                  </div>
                  <div class="cell small-1 pass-2 pass-2-margin">
                    <img @click="changeImage" :src="image">
                  </div>
                </div>
              </div>
              <div class="cell small-4 pass-4">
                <div class="grid-x">
                  <div class="cell small-1 correct-pass">
                    <img :src="pass_image">
                    <img class="tick-margin" src="/images/white-tick.png">
                  </div>
                  <div class="cell small-3 min-pass min-pass-margin">
                    <p>Min 10 characters</p>
                  </div>
                </div>
              </div>
              <div class="cell small-12 input-group-field no-height">
                <div class="grid-x">
                  <div class="cell small-11">
                    <input
                      :type="type3"
                      class="no-outline"
                      name="password_confirmation"
                      v-model="pw.NewPassword1"
                      placeholder="Confirm new password"
                    />
                  </div>
                  <div class="cell small-1 pass-2 pass-2-margin">
                    <img @click="changeConfirmPassImage" :src="image3">
                  </div>
                </div>
              </div>
              <br>
              <div class="popup-button">
                <input
                  class="button btn_lg btn pink_btn"
                  type="submit"
                  :disabled="btnPassUpdate"
                  value="Update"
                  @click="submitpw"
                />
              </div>
            </b-modal>
          </div>
        </section>

        <section class="bg-white" style="overflow: hidden;">
          <h3>Email Notifications</h3>

          <div class>
            <label class="label-dark">Notify me via email when someone:</label>
            <div class="input-box">
              <input type="hidden" name="request_payment" value="0" />
            </div>
            <label class="points-check">
              <input
                type="checkbox"
                name="request_payment"
                v-model="personal.request_payment"
                id="request_payment"
              />
              <span class="mark"></span>
              <span class="checkbox-text">Requests a payment </span>
            </label>
          </div>

		  <div class>
             <label class="label-dark">Send weekly summary:</label>
            <div class="input-box">
              <input type="hidden" name="request_payment" value="0" />
            </div>
            <label class="points-check">

              <input
                type="checkbox"
                name="overdue_reminder"
                v-model="personal.overdue_reminder"
                id="overdue_reminder"
              />
              <span class="mark"></span>
              <span class="checkbox-text">Candidate response times </span>
            </label>
          </div>

          <div class>
            <input
              class="button btn_lg btn pink_btn"
              type="submit"
              :disabled="btnProfileUpdate"
              value="Update"
              v-on:click="submitpersonalinfo"
            />
          </div>
        </section>
      </div>
    </div>
  </section>
  <!--Tabs End-->
</template>
<script>
import axios from "axios";

export default {
  props: ["users", "sitesarr"],
  data() {
    return {
      childComponant: new Vue(),
      homeTab: 1,
      profileTab: 0,
      messagesTab: 0,
      tab: 1,
      btnStatus: false,
      btnnoti: false,
      btnpw: false,
      visibility1: "password",
      visibility2: "password",
      password: "dummypw",
      noti: {
        request_payment: this.users.request_payment,
		overdue_reminder: this.users.overdue_reminder,
      },
      personal: {
        FirstName: this.users.first_name,
        Surname: this.users.surname,
        Email: this.users.email,
        site_id: this.users.site_id,
        ImageBase64: this.users.ImageBase64,
        role: this.users.currentRole,
        request_payment: this.users.request_payment,
        overdue_reminder: this.users.overdue_reminder,
        Phone: this.users.phone_number
      },
      pw: {
        CurrentPassword: "",
        NewPassword: "",
        NewPassword1: "",
        Error_CurrentPassword: "",
        Error_NewPassword: "",
        Error_NewPassword1: "",
      },

      type: "password",
      type2: "password",
      image: "/images/show-password.png",
      image2: "/images/show-password.png",
      pass_image: "/images/pass-initial.png",
      passValidation: false,
      passError: false,
      errors: '',
      passCurrent: false,
      currentError: false,
      current_errors: '',
      type3: "password",
      image3: "/images/show-password.png",
    };
  },
  watch: {
    "personal.FirstName": function (val) {
      //Check if all fields are filled in
      this.allFieldsFilled();
    },
    "personal.Surname": function (val) {
      //Check if all fields are filled in
      this.allFieldsFilled();
    },
    "personal.Email": function (val) {
      //Check if all fields are filled in
      this.allFieldsFilled();
    },
    "personal.site_id": function (val) {
      //Check if all fields are filled in
      this.allFieldsFilled();
    },
    "pw.CurrentPassword": function (val) {
      console.log(val);
      this.pwfieldfilled();
    },
    "pw.NewPassword": function (val) {
      //Check if all fields are filled in
      this.pwfieldfilled();
    },
    "pw.NewPassword1": function (val) {
      //Check if all fields are filled in
      this.pwfieldfilled();
    },
  },
  methods: {
    validatePassword() {
      this.passValidation = false;
      this.passError = false;
      this.pass_image = '/images/pass-initial.png';
      if (this.pw.NewPassword.length >= 10) {
        var self = this;
        this.pass_image = '/images/pass-final.png';
        axios.post("/validate-password-portal", {email: this.personal.Email, password: this.pw.NewPassword})
          .then(function (response) {
            if (response.data.error) {
              if (response.data.error.password) {
                self.errors = response.data.error.password[0];
              } else {
                self.errors = response.data.error;
              }
              self.passError = true;
            } else {
              self.passValidation = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    currentPassword() {
      this.passCurrent = false;
      this.currentError = false;
      if (this.pw.CurrentPassword.length > 0) {
        var self = this;
        axios.post("/current-password-portal", {email: this.personal.Email, current_password: this.pw.CurrentPassword})
          .then(function (response) {
            if (response.data.error) {
              self.current_errors = response.data.error;
              self.currentError = true;
            } else {
              self.passCurrent = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    changeImage() {
      if (this.image == '/images/show-password.png') {
        this.image = '/images/hide-password.png';
        this.type = "text";
      } else {
        this.image = '/images/show-password.png';
        this.type = "password";
      }
    },
    changeConfirmImage() {
      if (this.image2 == '/images/show-password.png') {
        this.image2 = '/images/hide-password.png';
        this.type2 = "text";
      } else {
        this.image2 = '/images/show-password.png';
        this.type2 = "password";
      }
    },
    changeConfirmPassImage() {
      if (this.image3 == '/images/show-password.png') {
        this.image3 = '/images/hide-password.png';
        this.type3 = "text";
      } else {
        this.image3 = '/images/show-password.png';
        this.type3 = "password";
      }
    },
    isActive(tab) {
      if (tab == 1) {
        this.homeTab = 1;
        this.profileTab = 0;
        this.messagesTab = 0;
        this.tabId = 1;
      } else if (tab == 2) {
        this.homeTab = 0;
        this.profileTab = 1;
        this.messagesTab = 0;
        this.tabId = 2;
      } else if (tab == 3) {
        this.homeTab = 0;
        this.profileTab = 0;
        this.messagesTab = 1;
        this.tabId = 3;
      }
    },
    submitnotification(e) {
      // e.preventDefault();
      Vue.swal({
        title: "Saving...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });

      const axios = require("axios");
      axios
        .put("/dashboard/account/account-settings", this.noti, {})
        .then((response) => {
          console.log(response.data);
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Email notification saved.',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submitpersonalinfo(e) {
      // e.preventDefault();
      Vue.swal({
        title: "Saving...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      const axios = require("axios");
      axios
        .put("/dashboard/account/account-settings", this.personal, {})
        .then((response) => {
          $("#myModal").hide();
          $(".modal-backdrop.show").hide();
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Personal info saved.',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    submitpw() {
      //e.preventDefault();

      Vue.swal({
        title: "Updating...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      var error = '';
      var self = this;
      var x = {};
      x["CurrentPassword"] = this.pw.CurrentPassword;
      x["password"] = this.pw.NewPassword;
      const axios = require("axios");
      axios
        .put("/dashboard/account/edit-password", x, {})
        .then((response) => {
          Vue.swal.close();
          if (response.data.error) {
            if (response.data.error.password) {
              error = response.data.error.password[0];
            } else {
              error = response.data.error;
            }
            Vue.swal({
              title: error,
              html: "",
              allowOutsideClick: false,
            });
          } else {
            self.$bvModal.hide("changePassword");
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Password changed.',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
        })
        .catch((error) => {
          Vue.swal.close();
          Vue.swal({
            title: error.response.data,
            html: "",
            allowOutsideClick: false,
          });
        });
    },
    allFieldsFilled() {
      if (
        this.personal.FirstName &&
        this.personal.Surname &&
        this.personal.Email
      ) {
        this.btnStatus = true;
      } else {
        this.btnStatus = false;
      }
    },
    pwfieldfilled() {
      if (
        this.pw.CurrentPassword &&
        this.pw.NewPassword &&
        this.pw.NewPassword1
      ) {
        this.btnpw = true;
      } else {
        this.btnpw = false;
      }
    },

    closemodel(id) {
      this.$bvModal.hide(id);
    },

    showPassword(i) {
      if (i == 1) {
        this.visibility1 = "text";
      } else if (i == 2) {
        this.visibility2 = "text";
      }
    },
    hidePassword(i) {
      if (i == 2) {
        this.visibility2 = "password";
      } else if (i == 1) {
        this.visibility1 = "password";
      }
    },
  },
  mounted: function () {
    this.$root.$on("EditAccount::Success", (EditAccountData) => {
      if (EditAccountData.hasOwnProperty("ImageBase64")) {
        console.log("in mounted");
        console.log(EditAccountData.ImageBase64);
        this.personal.ImageBase64 = EditAccountData.ImageBase64;
      }
    });
  },
  computed: {
    initiates: function () {
      var fname = this.personal.FirstName;
      var lanme = this.personal.Surname;
      return fname.charAt(0) + "" + lanme.charAt(0);
    },

    btnPassUpdate: function () {
      if (this.passValidation && this.passCurrent && this.pw.NewPassword == this.pw.NewPassword1) {
        return false;
      } else {
        return true;
      }
    },

    btnProfileUpdate: function () {
      if (
        this.personal.FirstName &&
        this.personal.Surname &&
        this.personal.Email
      ) {
        return false;
      } else {
        return true;
      }
    },
    emailUpdate: function () {
      if (this.personal.Email != this.users.email || this.personal.Phone != this.users.phone_number) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.bg-hight-fixed {
  height: 300px;
}
</style>
