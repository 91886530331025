<template>
    <div>
        <div class="grid-x">
            <div class="cell auto font_0875">
                <div class="grid-x">
                    <div class="cell small-5">
                        <input
                            class="jobs-search"
                            placeholder="Search"
                            v-model="searchVar"
                            name="searchVar"
                            @keyup="searchSuper()"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- USER HEADER-->
        <div class="grid-x grid-padding-x red_bg t_margin_1 align-middle">
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>First:</strong></p>
            </div>
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>Last:</strong></p>
            </div>
            <div class="cell auto white">
                <p class="tb_margin_1"><strong>Email:</strong></p>
            </div>
            <!-- <div class="cell auto white" align="center">
                <p class="tb_margin_1"><strong>Dashboard Rights:</strong></p>
            </div> -->
            <div class="cell auto white" align="center">
                <p class="tb_margin_1"><strong>Site:</strong></p>
            </div>
            <div class="cell auto white" align="center">
                <p class="tb_margin_1"><strong>Status:</strong></p>
            </div>
            <div class="cell auto white" align="center">
                &nbsp;
            </div>
            <div class="cell medium-2 white" align="center">
                &nbsp;
            </div>
            <div class="cell medium-2 white" align="center">
                &nbsp;
            </div>
        </div>
        <div
            v-for="(user, index) in users"
            class="grid-x grid-padding-x align-middle b_border_b1"
        >
            <div class="cell auto">
                <p class="tb_margin_1">
                    <strong>{{ user.first_name }}</strong>
                </p>
            </div>
            <div class="cell auto">
                <p class="tb_margin_1">
                    <strong>{{ user.surname }}</strong>
                </p>
            </div>
            <div class="cell auto">
                <p class="tb_margin_1 overflow-ellipsis">
                    <a :href="'mailto:' + user.email"
                        ><strong>{{ user.email }}</strong></a
                    >
                </p>
            </div>
            <!-- <div class="cell auto" align="center">
                <p class="tb_margin_1">
                    {{ user.advanced_admin ? "Advanced" : "Basic" }}
                </p>
            </div> -->
            <div class="cell auto" align="center">
                <p class="tb_margin_1">
                    {{ user.site_name ? user.site_name : "" }}
                </p>
            </div>
            <div class="cell auto" align="center">
                <p class="tb_margin_1">
                    {{ user.active === 1 ? "Active" : "Inactive" }}
                </p>
            </div>
            <div class="cell auto mobile-squeeze">
                <div
                    class="button orange_btn b_margin_0 no_wrap_btn"
                    v-b-modal="'edit-super-' + index"
                    @click="toggleEditMode()"
                >
                    Edit
                </div>
            </div>
            <div class="cell medium-2 mobile-squeeze">
                <div
                    class="button pink_btn b_margin_0 no_wrap_btn"
                    @click="toggleActive(user.id, index)"
                >
                    {{ !user.active ? "Activate" : "Deactivate" }}
                </div>
            </div>
            <div class="cell medium-2 mobile-squeeze">
                <div class="tb_margin_1">
                    <a
                        class="button pink_btn b_margin_0"
                        @click="sendDetails(index)"
                        >{{ !user.logon ? (user.locked ? "Unlock" : "Send log-on") : "Details sent!" }}</a
                    >
                </div>
            </div>
            <b-modal
                v-if="editMode"
                :id="'edit-super-' + index"
                size="xl"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                @hide="clearEditMode"
            >
                <div
                    class="grid-x grid-padding-x align-middle b_border_b1 background_grey"
                >
                    <div class="cell t_margin_2">
                        <form data-abide novalidate style="width: 100%">
                            <div class="grid-x grid-padding-x align-bottom">
                                <div class="cell auto">
                                    <label
                                        >First Name*
                                        <input
                                            type="text"
                                            class="white_form b_margin_1-25"
                                            v-model="user.first_name"
                                            placeholder=""
                                            aria-errormessage="firstName-edit"
                                            required
                                        />
                                        <span
                                            class="form-error"
                                            id="firstName-edit"
                                        >
                                            Please enter a first name.
                                        </span>
                                    </label>
                                </div>
                                <div class="cell auto ">
                                    <label
                                        >Surname*
                                        <input
                                            type="text"
                                            class="white_form b_margin_1-25"
                                            v-model="user.surname"
                                            placeholder=""
                                            aria-errormessage="surName-edit"
                                            required
                                        />
                                        <span
                                            class="form-error"
                                            id="surName-edit"
                                        >
                                            Please enter a surname.
                                        </span>
                                    </label>
                                </div>
                                <div class="cell auto" align="right">
                                    <div
                                        class="button orange_btn no_wrap_btn"
                                        @click="clearEditMode"
                                    >
                                        Cancel
                                    </div>
                                    <div
                                        class="button pink_btn no_wrap_btn"
                                        @click="save(user.id, index)"
                                    >
                                        Update
                                    </div>
                                    <i
                                        v-if="savedStatus == 'saving'"
                                        class="fa fa-spinner fa-pulse fa-3x fa-fw red"
                                    ></i>
                                    <i
                                        class="fa fa-check fa-3x fa-fw green"
                                        v-if="savedStatus == 'saved'"
                                    ></i>
                                </div>
                            </div>
                            <div class="grid-x grid-padding-x">
                                <div class="cell auto">
                                    <label
                                        >Email address*
                                        <input
                                            type="email"
                                            v-model="user.email"
                                            class="white_form b_margin_1-25"
                                            placeholder=""
                                            aria-errormessage="email-edit"
                                            required
                                        />
                                        <span
                                            class="form-error"
                                            id="email-edit"
                                            pattern="email"
                                        >
                                            Please enter an email address.
                                        </span>
                                    </label>
                                </div>
                                <div
                                    class="cell auto"
                                    v-if="availableSites.length > 0"
                                >
                                    <label
                                        >Select Site*
                                        <v-select
                                            ref="siteSelect"
                                            placeholder="Select Site"
                                            label="name"
                                            v-model="user.site_id"
                                            :reduce="referrer => referrer.id"
                                            :options="availableSites"
                                        ></v-select>
                                    </label>
                                </div>
                                <!-- <div class="cell auto" v-if="advanced">
                                    <div style="margin-top: 30px" class="b_margin_minus20px yellow_link" align="right">
                                        <span data-tooltip tabindex="1"
                                              title="Allows user to view and create others sites"><i
                                            class="far fa-question-circle"></i></span></div>
                                    <input id="advanced-dashboard-edit" type="checkbox"
                                           v-model="user.advanced_admin"/><label for="advanced-dashboard">Allow advanced
                                    dashboard rights to this user</label>
                                </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </b-modal>
        </div>
        <template v-if="paginationData.last_page > 1">
            <paginate
                v-model="paginationData.current_page"
                :page-count="paginationData.last_page"
                :page-range="3"
                :margin-pages="2"
                :click-handler="getData"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination-custom'"
                :page-class="'page-item'"
                :active-class="'active'"
            ></paginate>
        </template>
    </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
    components: { paginate: Paginate },
    props: ["advanced", "id"],
    data() {
        return {
            users: [],
            editMode: false,
            savedStatus: null,
            availableSites: [],
            sites: [],
            paginationData: {
                current_page: 0,
                from: 0,
                last_page: 0,
                per_page: 0,
                to: 0,
                total: 0
            },
            searchVar: "",
            param: ""
        };
    },
    methods: {
        searchSuper() {
            if (this.searchVar.length > 0) {
                this.param = "&search=" + this.searchVar;
                this.getData(1);
            } else {
                this.param = "";
                this.getData(1);
            }
        },
        getData(pageNum) {
            let params = "?page=" + pageNum;
            if (this.param.length > 0) {
                params += this.param;
            }
            var self = this;
            axios
                .get("/dashboard/super/clients/users/" + this.id + params)
                .then(function(response) {
                    // handle success
                    if (response.data) {
                        self.users = response.data.portalUsers.data;
                        self.paginationData.current_page =
                            response.data.portalUsers.current_page;
                        self.paginationData.from =
                            response.data.portalUsers.from;
                        self.paginationData.last_page =
                            response.data.portalUsers.last_page;
                        self.paginationData.per_page =
                            response.data.portalUsers.per_page;
                        self.paginationData.to = response.data.portalUsers.to;
                        self.paginationData.total =
                            response.data.portalUsers.total;
                    }
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        },
        getSites() {
            var self = this;
            axios
                .post("/dashboard/super/clients/sites", { id: this.id })
                .then(function(response) {
                    // handle success
                    if (response.data) {
                        self.sites = response.data.sites;
                        self.getAvailableSites();
                    }
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        },
        clearEditMode() {
            this.editMode = false;
        },
        toggleEditMode() {
            this.editMode = true;
        },
        toggleActive(id, index) {
            if (!this.users[index].active) {
                this.users[index].active = true;
            } else {
                this.users[index].active = false;
            }
            axios
                .post("/dashboard/account/toggle-active", { id: id })
                .then(function(response) {
                    // handle success
                    console.log(response);
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        },
        getAvailableSites() {
            //Create array of available sites for dropdown
            var allSites = Object.keys(this.sites);
            var availableSites = [];
            var noSite = {
                id: null,
                name: "No Site"
            };
            availableSites.push(noSite);
            for (var i = allSites.length - 1; i >= 0; i--) {
                availableSites.push(this.sites[allSites[i]]);
            }
            this.availableSites = availableSites;
        },
        save(id, index) {
            this.savedStatus = "saving";
            var self = this;
            axios
                .post("/dashboard/account/update-user", this.users[index])
                .then(function(response) {
                    // handle success
                    self.editMode = false;
                    self.savedStatus = "saved";
                    self.getData(self.paginationData.current_page);
                    console.log(response);
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        },
        sendDetails(index) {
            var self = this;
            axios
                .post("/dashboard/account/send-details", this.users[index])
                .then(function(response) {
                    // handle success
                    self.users[index].logon = true;
                    console.log(response);
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        }
    },
    mounted() {
        this.getData(1);
        this.getSites();
    }
};
</script>
