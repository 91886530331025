<template>
  <div>
    <b-modal modal-class="add-points-budget-modal"
             @hidden="resetEditMode()"
             class="modal-p-div"
             ref="edit-budget-modal"
             id="edit-budget-modal"
             size="XL">
      <section class="edit-budget">
        <span class="edit-budget-header">Edit Budget</span>
        <form ref="edit_Budget_Form">
          <div class="modal-styling-content">

            <div class="mt-20">
              <label class="main_label"
                     for="">BUDGET NAME<span class="asterisk">*</span></label>
              <input type="text"
                     placeholder="Enter budget name.."
                     v-model="activePointBudgetRecord.Name"
                     :class="{ 'error-input': showError && (activePointBudgetRecord.Name === null ||activePointBudgetRecord.Name === '') }" />
              <span class="error-message"
                    v-if="showError && (activePointBudgetRecord.Name === null ||activePointBudgetRecord.Name === '')">
                Please enter the budget name.
              </span>
            </div>

            <div class="radio-btn-oneoff-monthly mouse-hover mt-20">
              <label class="main_label one-off-monthly-label">IS THIS A ONE-OFF OR MONTHLY BUDGET?<span
                      class="asterisk">*</span></label>
              <input disabled
                     type="radio"
                     id="oneoffradio"
                     value="0"
                     class="mouse-hover"
                     name="IsMonthly"
                     v-bind:style="[
                                                          activePointBudgetRecord.IsMonthly === 0
                                                              ? { border: '1px solid #5dc2a6 !important' }
                                                              : { border: '1px solid #D1D3E0 !important' },
                                                          ]"
                     v-model="activePointBudgetRecord.IsMonthly" />
              <label for="oneoffradio"
                     class="radio-oneoff mouse-hover">One-off</label>
              <br />
              <input disabled
                     type="radio"
                     id="monthlyradio"
                     value="1"
                     name="IsMonthly"
                     class="mouse-hover"
                     v-bind:style="[
                                                          activePointBudgetRecord.IsMonthly === 1
                                                              ? { border: '1px solid #5dc2a6 !important' }
                                                              : { border: '1px solid #D1D3E0 !important' },
                                                          ]"
                     v-model="activePointBudgetRecord.IsMonthly" />
              <label for="monthlyradio"
                     class="radio-monthly mouse-hover">Monthly</label><br />
              <br />
              <span class="error-message"
                    v-if="showError && (activePointBudgetRecord.IsMonthly === null)">Please select an option.</span>
            </div>

            <div v-if="Number(activePointBudgetRecord.IsMonthly) === 1"
                 class="radio-btn-rollover mouse-hover mt-20">
              <label class="main_label">SHOULD ANY UNDISTRIBUTED POINTS ROLL OVER TO THE NEXT MONTH?<span
                      class="asterisk">*</span></label>
              <input disabled
                     type="radio"
                     id="rolloverradio"
                     name="isRolledOver"
                     v-bind:value="true"
                     class="mouse-hover"
                     v-bind:style="[
                                                          activePointBudgetRecord.IsRolledOver
                                                              ? { border: '1px solid #5dc2a6 !important' }
                                                              : { border: '1px solid #D1D3E0 !important' },
                                                          ]"
                     v-model="activePointBudgetRecord.IsRolledOver" />
              <label for="rolloverradio"
                     class="radio-rollover mouse-hover"
                     style="font-family:'Poppins',sans-serif">Roll over</label>
              <br />
              <input disabled
                     type="radio"
                     id="dnrolloverradio"
                     name="isRolledOver"
                     v-bind:value="false"
                     class="mouse-hover"
                     v-bind:style="[
                                                          !activePointBudgetRecord.IsRolledOver
                                                              ? { border: '1px solid #5dc2a6 !important' }
                                                              : { border: '1px solid #D1D3E0 !important' },
                                                          ]"
                     v-model="activePointBudgetRecord.IsRolledOver" />
              <label for="dnrolloverradio"
                     class="radio-dnrollover mouse-hover"
                     style="font-family:'Poppins',sans-serif">Do not roll over</label><br />
            </div>

            <div class="mt-20">
              <label class="main_label">BUDGET TYPE<span class="asterisk">*</span></label>
              <single-select-component v-model="activePointBudgetRecord.BudgetType"
                                       :options="budgetTypes"
                                       :isSelectEmptyAndSaved="isBudgetTypeEmptyAndSaved"
                                       :disabled="true" />
              <span class="error-message"
                    v-if="showError && !activePointBudgetRecord.BudgetType">Please select budget type.</span>
            </div>

            <div class="mt-20"
                 v-if="activePointBudgetRecord.BudgetType === 1">
              <label class="main_label main_label_span">WHO SHOULD HOLD THIS BUDGET?<span class="asterisk">*</span><br />
                <span class="sub-label-text"> Each user selected will be assigned the budget amount.</span>
                <span class="IconInfoRoundIdle"
                      v-b-tooltip.hover
                      title="Only portal users who do not already have the same budget type will display.">
                  <img src="/images/icon-info-round-idle@2x.png"
                       alt="img" />
                </span>
              </label>
              <single-select-component :searchable="true"
                                       :options="usersForSeparateBudget"
                                       v-model="selectedUsers_Separate"
                                       :isNotValid="isUserSeparateBudgetNotValid"
                                       placeholder="Select user..." />
              <span class="error-message"
                    v-if="isUserSeparateBudgetNotValid">
                Please select users.
              </span>
            </div>

            <div class="mt-20"
                 v-if="activePointBudgetRecord.BudgetType === 2">
              <label class="main_label main_label_span">WHO SHOULD SHARE THIS BUDGET?<span class="asterisk">*</span><br />
                <span class="sub-label-text"> All selected users will share the budget amount.</span>
                <span class="IconInfoRoundIdle"
                      v-b-tooltip.hover
                      title="Only portal users who do not already have the same budget type will display.">
                  <img src="/images/icon-info-round-idle@2x.png"
                       alt="img" />
                </span>
              </label>
              <multi-select-component :searchable="true"
                                      :options="usersForSharedBudget"
                                      v-model="selectedUsers_Shared"
                                      :isNotValid="isUserSharedBudgetNotValid"
                                      placeholder="Select users..." />
              <span class="error-message"
                    v-if="isUserSharedBudgetNotValid">
                Please select users.
              </span>
            </div>

            <div class="mt-20"
                 v-if="activePointBudgetRecord.BudgetType === 3">
              <label class="main_label main_label_span">WHICH SITE SHOULD HOLD THIS BUDGET?<span
                      class="asterisk">*</span><br />
                <span class="sub-label-text">All portal users with permission to award bonus points will share the budget
                  amount.</span>
                <span class="IconInfoRoundIdle"
                      v-b-tooltip.hover
                      title="Only sites that do not already have a budget will display.">
                  <img src="/images/icon-info-round-idle@2x.png"
                       alt="img" />
                </span>
              </label>
              <single-select-component :searchable="true"
                                       :options="sitesForSharedBudget"
                                       v-model="selectedSite_Shared"
                                       :isNotValid="isSiteSharedBudgetNotValid"
                                       placeholder="Select site..." />
              <span class="error-message"
                    v-if="isSiteSharedBudgetNotValid">
                Please select a site.
              </span>
            </div>
            <div class="mt-20">
              <label class="main_label"
                     for="">NUMBER OF POINTS<span class="asterisk">*</span></label>
              <input type="number"
                     v-model="activePointBudgetRecord.NumberOfPoints"
                     placeholder="Enter number of points.."
                     :class="{ 'error-input': showError && invalidNumberOfPoints(activePointBudgetRecord.NumberOfPoints) }" />
              <span class="error-message"
                    v-if="showError && invalidNumberOfPoints(activePointBudgetRecord.NumberOfPoints)">Please enter the
                number of points. Must be 0 and above.</span>
            </div>
          </div>
        </form>

        <div class="modal_footer mt-20">
          <button class="control-btn danger-trans-btn"
                  @click="closeModal">Cancel</button>
          <button class="control-btn success-btn"
                  @click="updateBudget()">Save</button>
        </div>
      </section>

    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { Icon } from "../../../app/components/Icon";
import SingleSelectComponent from "./SingleSelectComponent.vue";
import MultiSelectComponent from "../MultiSelectComponent.vue";

export default {
  components: { MultiSelectComponent, SingleSelectComponent, Icon },
  props: {
    editMode: {
      type: Boolean,
      required: false,
    },
    activePointBudgetRecord: {
      type: Object,
      required: true,
    },
    budgetTypes: { type: Array, required: true },
    oneOffUsersForSeparateBudget: { type: Array, required: true },
    monthlyRolloverUsersForSeparateBudget: { type: Array, required: true },
    monthlyNonRolloverUsersForSeparateBudget: { type: Array, required: true },
    oneOffUsersForSharedBudget: { type: Array, required: true },
    monthlyRolloverUsersForSharedBudget: { type: Array, required: true },
    monthlyNonRolloverUsersForSharedBudget: { type: Array, required: true },
  },
  data() {
    return {
      localRecord: {},
      testArr: [],
      showError: false,
      sitesForSharedBudget: [],
      usersForSeparateBudget: [],
      usersForSharedBudget: [],
      selectedUsers_Separate: null,
      selectedUsers_Shared: [],
      selectedSite_Shared: null,
    }
  },
  watch: {
    editMode: {
      async handler(newVal) {
        if (newVal === true) {
          const isMonthly = this.activePointBudgetRecord.IsMonthly;
          const isRolledOver = isMonthly ? Number(this.activePointBudgetRecord.IsRolledOver) : null;
          switch (this.activePointBudgetRecord.BudgetType) {
            // TODO: PHASE 2
            // If we need to enable the isMonthly and isRolledOver inputs, we need to check the
            // OLD isMonthly and isRolledOver values of the record and only pass it to the endpoint that is a match
            // so that we don't incorrectly include in the dropdown a user with a BUDGET TYPE that they already have,
            // which can cause unwanted duplicate results
            case 1:
              await this.getUsersForSeparateBudgetV2(isMonthly, isRolledOver, this.activePointBudgetRecord.associated_user_ids);
              break;

            case 2:
              await this.getUsersForSharedBudgetV2(isMonthly, isRolledOver, this.activePointBudgetRecord.associated_user_ids);
              break;

            case 3:
              await this.getSitesForSharedBudget(this.activePointBudgetRecord.BudgetType === 3 ? this.activePointBudgetRecord.associated_site_id[0] : 0);
              break;
          }
        }
      }
    },
    activePointBudgetRecord: {
      async handler(newValue) {
        this.localRecord = JSON.parse(JSON.stringify(newValue));
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    /*
      Validation Methods
     */
    isFormIncomplete() {
      const Name = this.activePointBudgetRecord.Name === null || this.activePointBudgetRecord.Name === "";
      const IsMonthly = this.activePointBudgetRecord.IsMonthly === null;
      const IsRolledOver = (Number(this.activePointBudgetRecord.IsMonthly) === 1 && this.activePointBudgetRecord.IsRolledOver === null);
      const BudgetType = !this.activePointBudgetRecord.BudgetType ||
        (Number(this.activePointBudgetRecord && this.activePointBudgetRecord.BudgetType) === 1 && (this.selectedUsers_Separate?.length === 0 || this.selectedUsers_Separate === null)) ||
        (Number(this.activePointBudgetRecord && this.activePointBudgetRecord.BudgetType) === 2 && (this.selectedUsers_Shared?.length === 0 || this.selectedUsers_Shared === null)) ||
        (Number(this.activePointBudgetRecord && this.activePointBudgetRecord.BudgetType) === 3 && this.selectedSite_Shared === null)
      const NumberOfPoints = this.invalidNumberOfPoints(this.activePointBudgetRecord.NumberOfPoints);

      return Name || IsMonthly || IsRolledOver || BudgetType || NumberOfPoints;
    },
    invalidNumberOfPoints(numberOfPoints) {
      return numberOfPoints === null || numberOfPoints === '' || isNaN(Number(numberOfPoints)) || Number(numberOfPoints) < 0;
    },
    /*
      End of Validation Methods
     */
    async getUsersForSeparateBudgetV2(isMonthly, canRollOver, userIds = []) {
      console.log(userIds);
      let params;

      if (!isMonthly) {
        params = {
          params: {
            isMonthly,
            userIds: userIds.length > 0 ? userIds : null
          }
        }
      } else {
        params = {
          params: {
            isMonthly,
            canRollOver,
            userIds: userIds.length > 0 ? userIds : null
          }
        }
      }

      await axios.get(`/points-budgets/get-users-for-separate-budget/${this.activePointBudgetRecord.CustomerId}`, params)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.usersForSeparateBudget = [];
            response.data.forEach(user => this.usersForSeparateBudget.push({
              id: user.id,
              name: `${user.first_name} ${user.surname}`
            }));
            if (this.usersForSeparateBudget.length > 0) {
              this.selectedUsers_Separate = this.activePointBudgetRecord.associated_user_ids[0]
            }
          }
        })
        .catch(error => {
          let userType;
          if (!isMonthly) {
            userType = "one-off";
          } else if (isMonthly && canRollOver) {
            userType = "monthly roll-over";
          } else if (isMonthly && !canRollOver) {
            userType = "monthly non roll-over";
          }
          console.error(`Error fetching ${userType} users for separate budget type: `, error);
        });
    },

    async getUsersForSharedBudgetV2(isMonthly, canRollOver, userIds = []) {
      let params;

      if (!isMonthly) {
        params = {
          params: {
            isMonthly,
            userIds: userIds.length > 0 ? userIds : null
          }
        }
      } else {
        params = {
          params: {
            isMonthly,
            canRollOver,
            userIds: userIds.length > 0 ? userIds : null
          }
        }
      }

      await axios.get(`/points-budgets/get-users-for-shared-budget/${this.activePointBudgetRecord.CustomerId}`, params)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.usersForSharedBudget = [];
            response.data.forEach(user => this.usersForSharedBudget.push({
              id: user.id,
              name: `${user.first_name} ${user.surname}`
            }));
            if (this.usersForSharedBudget.length > 0) {
              this.selectedUsers_Shared = this.activePointBudgetRecord.associated_user_ids;
            }
          }
        })
        .catch(error => {
          let userType;
          if (!isMonthly) {
            userType = "one-off";
          } else if (isMonthly && canRollOver) {
            userType = "monthly roll-over";
          } else if (isMonthly && !canRollOver) {
            userType = "monthly non roll-over";
          }
          console.error(`Error fetching ${userType} users for shared budget type: `, error);
        });
    },

    async getUsersForSeparateBudget(userIds = []) {
      await axios.get(`/points-budgets/get-users-for-separate-budget/${this.activePointBudgetRecord.CustomerId}`, userIds.length > 0 ? { params: { userIds } } : null)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            response.data.forEach(user => this.usersForSeparateBudget.push({
              id: user.id,
              name: `${user.first_name} ${user.surname}`
            }));
            if (this.usersForSeparateBudget.length > 0) {
              this.selectedUsers_Separate = this.activePointBudgetRecord.associated_user_ids[0];
            }
          }
        })
        .catch(error => {
          console.error("Error fetching users for separate budget type: ", error);
        });
    },
    async getUsersForSharedBudget(userIds = []) {
      await axios.get(`/points-budgets/get-users-for-shared-budget/${this.activePointBudgetRecord.CustomerId}`, userIds.length > 0 ? { params: { userIds } } : null)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            response.data.forEach(user => this.usersForSharedBudget.push({
              id: user.id,
              name: `${user.first_name} ${user.surname}`
            }));
            if (this.usersForSharedBudget.length > 0) {
              this.selectedUsers_Shared = this.activePointBudgetRecord.associated_user_ids;
            }
          }
        })
        .catch(error => {
          console.error("Error fetching users for shared budget type: ", error);
        });
    },
    async getSitesForSharedBudget(siteId = 0) {
      await axios.get(`/points-budgets/get-sites-for-shared-budget/${this.activePointBudgetRecord.CustomerId}`, siteId !== 0 ? { params: { siteId } } : null)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            response.data.forEach(site => this.sitesForSharedBudget.push({ id: site.id, name: site.name }));
            if (this.sitesForSharedBudget.length > 0) {
              this.selectedSite_Shared = this.activePointBudgetRecord.associated_site_id[0];
            }
          }
        })
        .catch(error => {
          console.error("Error fetching sites for shared budget type: ", error);
        });
    },
    onBudgetTypeChangeWhileInEditMode() {
      // this.activePointBudgetRecord.MultipleUserHolderSeparate = [];
      // this.activePointBudgetRecord.MultipleUserHolderShared = [];
      // this.activePointBudgetRecord.SingleSiteHolder = null;
    },
    resetEditMode() {
      this.editMode = false;
      this.usersForSeparateBudget = [];
      this.usersForSharedBudget = [];
      this.sitesForSharedBudget = [];
      this.selectedUsers_Separate = [];
      this.selectedUsers_Shared = [];
      this.selectedSite_Shared = null;
      this.$emit('resetEditMode', this.editMode);
    },
    updateBudget() {
      console.log(this.selectedUsers_Separate);
      console.log(this.selectedUsers_Shared);
      this.showError = false;
      const formIncomplete = this.isFormIncomplete();
      console.log('FORM INCOMPLETE:')
      console.log(formIncomplete);

      if (formIncomplete) {
        this.showError = true;
        return;
      }

      this.showError = false;
      this.activePointBudgetRecord.IsMonthly = Boolean(Number(this.activePointBudgetRecord.IsMonthly));
      if (!this.activePointBudgetRecord.IsMonthly) {
        this.activePointBudgetRecord.IsRolledOver = null;
      }
      this.activePointBudgetRecord.NumberOfPoints = Number(this.activePointBudgetRecord.NumberOfPoints);
      this.activePointBudgetRecord.SingleSiteHolder = this.selectedSite_Shared ?? null;
      this.activePointBudgetRecord.MultipleUserHolderShared = this.selectedUsers_Shared ?? null;
      this.activePointBudgetRecord.MultipleUserHolderSeparate = this.selectedUsers_Separate ?? null;

      this.$emit('budgetupdated', this.activePointBudgetRecord);

      this.closeModal();
      this.reset_form();
    },
    limitText(count) {
      return `+${count} others`
    },
    closeModal() {
      this.isVisible = false;
      this.$refs["edit-budget-modal"].hide();
      this.reset_form();
    },
    reset_form() {
      this.activePointBudgetRecord = {
        ID: null,
        Name: null,
        IsMonthly: null,
        IsRolledOver: null,
        BudgetType: null,
        NumberOfPoints: null,
        users: {},
        allocation: {
          used: 0,
          remaining: null,
          days_remaining: 30,
        },
        individual_budget_selected_users: [],
        shared_budget_selected_users: [],
        shared_budget_selected_site: [],
      };
    },
  },
  computed: {
    isBudgetTypeEmptyAndSaved() {
      return this.showError && !this.activePointBudgetRecord.BudgetType;
    },
    isUserSharedBudgetNotValid() {
      return this.showError && this.activePointBudgetRecord.BudgetType === 2 &&
        Array.isArray(this.selectedUsers_Shared) &&
        (this.selectedUsers_Shared === null || this.selectedUsers_Shared.length === 0);
    },
    isSiteSharedBudgetNotValid() {
      return this.showError &&
        this.activePointBudgetRecord.BudgetType === 3 &&
        this.selectedSite_Shared === null
    },
    isUserSeparateBudgetNotValid() {
      return this.showError &&
        this.activePointBudgetRecord.BudgetType === 1 &&
        this.selectedUsers_Separate === null
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .modal-dialog {
  width: 656px !important;
  max-width: 656px !important;
}

::v-deep .multiselect__single,
.multiselect__option,
.multiselect__content,
.multiselect__input {
  font-size: 14px !important;
}

::v-deep .multiselect__tag {
  position: relative;
  display: inline-block;
  line-height: 1;
  background: #ffffff !important;
  margin-bottom: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  color: #363b54 !important;
  font-family: "Poppins", sans-serif !important;
  padding: 0px 2px 0px 0px !important;
  border-radius: 5px;
  margin-right: 0px !important;
}

::v-deep .multiselect__tags {
  padding: 10px 40px 0px 8px !important;
  font-family: "Poppins", sans-serif !important;
}

::v-deep .multiselect__strong {
  /* margin-bottom: 10px !important; */
  line-height: 16px !important;
  /* display: inline-block; */
  /* vertical-align: super !important; */
  color: #363b54 !important;
  font-weight: normal !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  padding-left: 2px !important;
}

::v-deep .multiselect__tag-icon {
  display: none !important
}

.edit-points-budget-modal .modal-dialog {
  width: 656px !important;
  max-width: 656px !important;
}

.sub-label-text {
  font-size: 12px;
  font-weight: lighter;
}

.one-off-monthly-label {
  padding-bottom: 3px;
}

.control-btn:not(:last-child) {
  margin-right: 15px;
}

.control-btn {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.multiselect__content-wrapper .multiselect__content {
  font-family: "Poppins", sans-serif;
}

.edit-budget-header {
  font: 600 22px/34px "Poppins", sans-serif;
  color: #3C3C3C;
  margin: 0 0 10px 0;
}

.error-input {
  border: 1px solid red !important;
  border-radius: 12px !important;
}

.multiselect.error-input {
  border-radius: 6px !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 500;
}

.mt-20 {
  margin-top: 20px;
}

.edit-budget {
  .success-btn {
    background-color: #5dc2a6;
    border-radius: 20px;
    border: 2px solid #5dc2a6;
    font: 700 16px/15px "Poppins", sans-serif;
    color: #ffffff;
    padding: 12px 28px;
  }

  .success-btn:hover,
  .success-btn:focus {
    background-color: #469f86;
    border: 2px solid #469f86 !important;
    color: #ffffff;
  }

  .danger-trans-btn {
    background-color: #ffffff;
    border: 2px solid #E61414;
    border-radius: 20px;
    color: red;
    font: 700 16px/15px "Poppins", sans-serif;
    padding: 12px 18px;
  }

  .danger-trans-btn:hover,
  .danger-trans-btn:focus {
    background-color: #E61414;
    color: #ffffff;
  }

  .modal_footer {
    text-align: center;
  }

  .main_label {
    color: #6A6F8E;
    font: 600 12px/22px "Poppins", sans-serif !important;
  }

  .main_label_span {
    margin-bottom: 10px;
  }

  [type=text],
  select,
  [type=number],
  .multiselect__tags {
    height: 44px;
    border-radius: 12px;
    box-shadow: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #3C3C3C;
    padding-left: 10px;
  }

  select {
    margin: 0;
  }
}

.radio-btn-oneoff-monthly,
.radio-btn-rollover {
  padding-left: 3px;
  margin-bottom: 12px;
  font-weight: 600;
}

input[type=radio][data-v-64fd8701]:before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  margin: 17% auto;
  border-radius: 50%;
}

label.radio-oneoff,
label.radio-rollover {
  padding: 3px !important;
  color: #363b54;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

label.radio-monthly,
label.radio-dnrollover {
  padding: 3px !important;
  color: #363b54;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

.radio-monthly,
.radio-dnrollover {
  font-family: "Poppins", sans-serif;
  cursor: no-drop;
}

.mouse-hover {
  cursor: pointer;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #5dc2a6 !important;
}

[type="file"],
[type="checkbox"],
[type="radio"] {
  margin: 0 0 0 !important;
}

label.oneoff,
label.rollover {
  margin-bottom: 2px;
  font-weight: 600;
}

label.monthly,
label.dnrollover {
  margin-bottom: 7px;
  font-weight: 600;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #d1d3e0 !important;
  border-radius: 510%;
  outline: none;
  vertical-align: middle;
}

.asterisk {
  color: red;
}

.i_icon {
  background-color: #d7d7d7;
  border-radius: 50%;
  color: #293e8d;
  padding: 0px 5px;
}

.validate-error {
  padding: 1px 8px;
  margin-top: 0px;
  color: #E61414;
  font: 13px/22px "poppins";
  font-weight: 500;
}


.tooltip {
  font-family: "Poppins", sans-serif !important;
  text-align: left !important;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -.5rem;
  right: -8rem;
}
</style>