
<template>
    <div>
        <div v-show="showLoad" class="show-load">
            <img :src="myImage" />
        </div>
        <div class="grid-x">
            <div class="cell auto font_0875 no-padding">
                <div class="grid-x">
                    <div class="cell small-6">
                        <input
                            class="jobs-search"
                            placeholder="Search: Enter atleast 3 characters"
                            v-model="searchVar"
                            name="searchVar"
                            @keyup.delete="searchUsersData()"
                            @keyup="searchUsers()"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="currentuser.admin_check == 1"
                class="cell small-3 cell-padding-right"
                align="right"
            >
                <button
                    v-b-modal.addnewadmin
                    class="button pink_btn b_margin_0 no_wrap_btn job-add"
                >Invite new admin user</button>
            </div>

            <b-modal id="addnewadmin" size="xl" :hide-footer="true">
                <h3>Invite a Portal User</h3>
                <div class="cell">
                    <form data-abide novalidate style="width: 100%">
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    First Name*
                                    <input
                                        type="text"
                                        class
                                        v-model="newuser.first_name"
                                        placeholder
                                        aria-errormessage="firstName"
                                        required
                                    />
                                    <span class="form-error" id="firstName">Please enter a first name.</span>
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Last Name*
                                    <input
                                        type="text"
                                        class
                                        v-model="newuser.surname"
                                        placeholder
                                        aria-errormessage="surName"
                                        required
                                    />
                                    <span class="form-error" id="surName">Please enter a Last Name.</span>
                                </label>
                            </div>
                        </div>
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    Email address*
                                    <input
                                        type="email"
                                        v-model="newuser.email"
                                        class="white_form b_margin_1-25"
                                        placeholder
                                        aria-errormessage="email-edit"
                                        required
                                    />
                                    <span
                                        class="form-error"
                                        id="email-edit"
                                        pattern="email"
                                    >Please enter an email address.</span>
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Mobile number*
                                    <input
                                        type="text"
                                        v-model="newuser.phone_number"
                                        aria-errormessage="phone-edit"
                                        required
                                    />
                                    <span
                                        class="form-error"
                                        id="phone-edit"
                                        pattern="text"
                                    >Please enter a mobile number.</span>
                                </label>
                            </div>
                        </div>
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box" v-if="availableSites.length > 0 && currentuser.customer.site_type == 'multi'">

                                <label>
                                    Select Site*
									<span class="yellow_link">
									<b-tooltip target="add-sites" triggers="hover" placement="topleft">If you select a single site, the portal user will ONLY be able to view and edit data for that site. If the portal user needs to see data for more than one site, please select 'All sites'.</b-tooltip>
									<span id="add-sites">
                                        <i class="fa fa-question-circle-o"></i></span>
                                    </span>
                                </label>
                                <multiselect class="multi-sites" v-model="newuser.site_id" :options="availableSites"
      @input="selecttag(newuser.site_id)"                                       :multiple="true" :clear-on-select="false" openDirection="below"
                                             :showLabels="false" placeholder="Select site(s)" label="name"
                                             track-by="name">
                                </multiselect>

                                <div class="input-tagged">
                                    <span v-for="(element, index) in newuser.site_id" v-bind:key="element.id">
                                        <div class="tag-input__tag tag-right">
                                        <span class="lablesize">{{ element.name }}</span>
                                        <span class="deselect-pad"
                                            ><span class="deselect" @click="removeSelectTag(index)"></span
                                        ></span>
                                        </div>
                                    </span>
                                    </div>
                            </div>
                            <div class="cell auto input-box" style="margin:0 0px auto">
                                <label>
                                    Eligible to earn referral points?

                                    <span class="yellow_link">
									<b-tooltip target="messages" triggers="hover" placement="topright">If you select 'No' this user will be able to share jobs, but will not earn any referral points for doing so. They will still be able to be awarded bonus points.</b-tooltip>
									<span id="messages">
                                        <i class="fa fa-question-circle-o"></i></span>
                                    </span>
                                    <v-select
                                        placeholder="Select"
                                        label="name"
                                        v-model="newuser.points_eligible"
                                        :reduce="referrer => referrer.id"
                                        :options="eligibleOptions"
                                    ></v-select>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="cell t_margin_2">
                    <!-- <form data-abide novalidate style="width: 100%"> -->

                    <!-- <input type="hidden" name="id" v-model="newuser.user_id"> -->
                    <p>
                        Permissions
                        <br />NB - all admin users have read only access to the core pages of the portal
                    </p>

                    <div class="switch-label grid-x">
                        <label class="switch" for="candidate_check">
                            <input
                                type="checkbox"
                                name="candidate_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.candidate_check"
                                class="custom-control-input"
                                id="candidate_check"
                            />
                            <span class="slider round"></span>
                        </label>Edit candidate details and manage candidate progress
                    </div>

                    <!--<div class="switch-label grid-x">
                        <label class="switch" for="progress_check">
                            <input
                                type="checkbox"
                                name="progress_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.progress_check"
                                class="custom-control-input"
                                id="progress_check"
                            />
                            <span class="slider round"></span>
                        </label>Edit and update candidates
                    </div>-->

                    <div class="switch-label grid-x" for="bonus_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="bonus_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.bonus_check"
                                class="custom-control-input"
                                id="bonus_check"
                            />
                            <span class="slider round"></span>
                        </label>Allocate bonus points
                    </div>

                    <div class="switch-label grid-x" for="bonus_categories_check">
                      <label class="switch">
                        <input
                          type="checkbox"
                          name="bonus_categories_check"
                          true-value="1"
                          false-value="0"
                          v-model="newuser.bonus_categories_check"
                          class="custom-control-input"
                          id="bonus_categories_check"
                        />
                        <span class="slider round"></span>
                      </label>Create/Edit bonus point categories
                    </div>

                    <div class="switch-label grid-x" for="approve_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="approve_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.approve_check"
                                class="custom-control-input"
                                id="approve_check"
                            />
                            <span class="slider round"></span>
                        </label> Approve payment requests
                    </div>

                    <div class="switch-label grid-x" for="paid_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="paid_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.paid_check"
                                class="custom-control-input"
                                id="paid_check"
                            />
                            <span class="slider round"></span>
                        </label>Mark payment requests as paid
                    </div>

                    <div class="switch-label grid-x" for="notification_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="notification_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.notification_check"
                                class="custom-control-input"
                                id="notification_check"
                            />
                            <span class="slider round"></span>
                        </label>Send and edit notifications
                    </div>

                    <div class="switch-label grid-x" for="users_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="users_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.users_check"
                                class="custom-control-input"
                                id="users_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage app users
                    </div>

                    <div class="switch-label grid-x" for="admin_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="admin_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.admin_check"
                                class="custom-control-input"
                                id="admin_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage admin users
                    </div>

                    <div class="switch-label grid-x" for="account_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="account_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.account_check"
                                class="custom-control-input"
                                id="account_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage account settings
                    </div>

                    <div class="switch-label grid-x" for="job_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="job_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.job_check"
                                class="custom-control-input"
                                id="job_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage job settings
                    </div>

                    <div v-if="pointTariffsFeatureFlag && currentuser.customer.variable_points_tariffs" class="switch-label grid-x" for="tariff_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="tariff_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.tariff_check"
                                class="custom-control-input"
                                id="tariff_check"
                            />
                            <span class="slider round"></span>
                        </label>Assign referral points tariffs
                    </div>

                    <div class="cell auto popup-button">
                        <button
                            class="button pink_btn no_wrap_btn"
                            style="float: right"
                            @click="AddAdminUser()"
                            :disabled="newbtnDisbaled"
                        >Invite</button>
                    </div>

                    <!-- </form> -->
                </div>
            </b-modal>
        </div>
        <div class="cf-table">
            <div class="grid-x grid-padding-x cf-table-head red_bg t_margin_1 align-middle">
                <div class="cell auto white">
                    <p class="tb_margin_1">
                        <strong>User</strong>
                    </p>
                </div>

                <div class="cell auto white">
                    <p class="tb_margin_1">
                        <strong>Email</strong>
                    </p>
                </div>

                <div class="cell auto white">
                    <p class="tb_margin_1">
                        <strong>Mobile number</strong>
                    </p>
                </div>
                <div v-if="show_sites" class="cell auto white">
                    <p class="tb_margin_1">
                        <strong>Site</strong>
                    </p>
                </div>
                <div class="cell auto white">
                    <p class="tb_margin_1">
                        <strong>Status</strong>
                    </p>
                </div>
                <div class="cell auto white">
                    <p class="tb_margin_1">
                        <strong>Points eligibility</strong>
                    </p>
                </div>
                <div class="cell medium-1 white">&nbsp;</div>
            </div>
            <template v-for="(user, i) in users">
                <div class="grid-x grid-padding-x align-middle b_border_b1">
                    <div class="cell auto">
                        <p class="tb_margin_1">
                            <strong>{{ user.first_name }} {{ user.surname }}</strong>
                        </p>
                    </div>
                    <div class="cell auto">
                        <p class="tb_margin_1 overflow-ellipsis">
                            <a :href="'mailto:'+user.email">
                                <strong>
                                    {{ user.email
                                    }}
                                </strong>
                            </a>
                        </p>
                    </div>
                    <div class="cell auto">
                        <p class="tb_margin_1">
                            <strong>{{ user.phone_number }}</strong>
                        </p>
                    </div>
                    <div v-if="show_sites" class="cell auto">
                        <p class="tb_margin_1">
						<span
                v-if="user.sites_users.length > 1">Multiple Sites</span>
				<span
                v-else>
				<span
                v-if="user.sites_users.length ==1 && user.sites_users[0].site_id !=null && user.sites_users[0].site_id !='-1'">{{user.sites_users[0].name}}</span>
				<span
                v-else>All sites</span>
				</span>
				</p>
                    </div>
                    <div class="cell auto">
                        <p class="tb_margin_1" v-if="!user.portal_user && user.active">Deactivated</p>
                        <p class="tb_margin_1" v-else-if="user.portal_user && user.active">Active</p>
                        <p class="tb_margin_1" v-else-if="!user.active">Deactivated</p>
                        <p class="tb_margin_1" v-else-if="user.active">Active</p>
                    </div>
                    <div class="cell auto">
                        <p class="tb_margin_1">{{ user.points_eligible ? 'Referral & Bonus' : 'Bonus only' }}</p>
                    </div>

                    <div v-if="currentuser.admin_check == 1" class="cell medium-1 shrink mobile squeeze dropdown-action" align="center">
                        <button

                            class="dropdown button"
                            type="button"
                            :id="'d' + i"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            @click="showMenu()"
                        ></button>
                        <div class="dropdown-menu pane-leftside dropdown-pane pane-width radius-3" :aria-labelledby="'d' + i" data-alignment="right">
                            <ul>
                                <li v-b-modal="'permission-' + user.id">
                                    <a href="javascript:void(0)" @click="editPermissions(user)">Edit User</a>
                                </li>
                                <li>
                                    <a v-on:click="sendDetails(user)">Send Log-on</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" @click="decideActive(user)">
                                      <template v-if="!user.portal_user && user.active">Reactivate user</template>
                                      <template v-else-if="user.portal_user && user.active">Deactivate user</template>
                                      <template v-else-if="!user.active">Reactivate user</template>
                                      <template v-else-if="user.active">Deactivate user</template>
                                    </a>
                                </li>
                                <li v-if="user.locked && current == primaryUser">
                                    <a @click="unlockUser(user.id)">Unlock user</a>
                                </li>
                                <li v-if="user.id != primaryUser">
                                    <a v-on:click="deleteUser(user.id)">Delete user</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!--<div v-if="editPermit">-->
                <b-modal :id="'permission-' + user.id" size="xl" :hide-footer="true">
                    <h3>Edit {{ user.first_name + user.surname }}</h3>
                    <div class="cell">
                        <!-- <form data-abide novalidate style="width: 100%"> -->
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    First Name*
                                    <input
                                        type="text"
                                        class
                                        v-model="user.first_name"
                                        placeholder
                                        aria-errormessage="firstName-edit"
                                        required
                                    />
                                    <span class="form-error" id="firstName-edit">Please enter a first name.</span>
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Last Name*
                                    <input
                                        type="text"
                                        class
                                        v-model="user.surname"
                                        placeholder
                                        aria-errormessage="surName-edit"
                                        required
                                    />
                                    <span class="form-error" id="surName-edit">Please enter a Last Name.</span>
                                </label>
                            </div>
                        </div>
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    Email address*
                                    <input
                                        type="email"
                                        v-model="user.email"
                                        class="white_form b_margin_1-25"
                                        placeholder
                                        aria-errormessage="email-edit"
                                        required
                                    />
                                    <span
                                        class="form-error"
                                        id="email-edit"
                                        pattern="email"
                                    >Please enter an email address.</span>
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Mobile number*
                                    <input
                                        type="text"
                                        v-model="user.phone_number"
                                        aria-errormessage="phone-edit"
                                        required
                                    />
                                    <span class="form-error" id="phone-edit">Please enter a mobile number.</span>
                                </label>
                            </div>
                        </div>
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box" v-if="availableSites.length > 0 && currentuser.customer.site_type == 'multi'">

                                <label>
                                    Select Site*
									<span class="yellow_link">
									<b-tooltip target="edit-sites" triggers="hover" placement="topleft">If you select a single site, the portal user will ONLY be able to view and edit data for that site. If the portal user needs to see data for more than one site, please select 'All sites'.</b-tooltip>
									<span id="edit-sites">
                                        <i class="fa fa-question-circle-o"></i></span>
                                    </span>
                                </label>
                                <multiselect class="multi-sites" v-model="user.sites_users" :options="availableSites"
                                             :multiple="true" :clear-on-select="false" openDirection="below"
                                           :showLabels="false" placeholder="Select site(s)" label="name" @input="selectedtag"
                                             track-by="name">
                                </multiselect>
                                <div class="input-tagged">
                                    <span v-for="(element, index) in user.sites_users" v-bind:key="element.id">
                                        <div class="tag-input__tag tag-right">
                                        <span class="lablesize">{{ element.name }}</span>
                                        <span class="deselect-pad"
                                            ><span class="deselect" @click="removeSelectedTag(index, user.sites_users)"></span
                                        ></span>
                                        </div>
                                    </span>
                                    </div>

                            </div>
                            <div class="cell auto input-box" style="margin:0 0px auto">
                                <label>
                                    Eligible to earn referral points?
                                    <span class="yellow_link">

									<b-tooltip target="messages-1" triggers="hover" placement="topright">If you select 'No' this user will be able to share jobs, but will not earn any referral points for doing so. They will still be able to be awarded bonus points.</b-tooltip>
									<span id="messages-1"  data-tooltip tabindex="1"
                                          title="If you select 'No' this user will be able to share jobs, but will not earn any referral points for doing so. They will still be able to be awarded bonus points."><i

                                        class="fa fa-question-circle-o"></i></span>
                                    </span>
                                    <v-select
                                        placeholder="Select"
                                        label="name"
                                        v-model="user.points_eligible"
                                        :reduce="referrer => referrer.id"
                                        :options="eligibleOptions"
                                    ></v-select>
                                </label>
                            </div>
                        </div>
                        <!-- </form> -->
                    </div>

                    <div class="cell t_margin_2">
                        <!-- <form data-abide novalidate style="width: 100%"> -->
                        <input type="hidden" name="id" v-model="user.user_id" />
                        <p>
                            Permissions
                            <br />NB - all admin users have read only access to the core pages of the portal
                        </p>

                        <div class="switch-label grid-x">
                            <label class="switch" for="candidate_check">
                                <input
                                    type="checkbox"
                                    name="candidate_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.candidate_check"
                                    class="custom-control-input"
                                    id="candidate_check"
                                />
                                <span class="slider round"></span>
                            </label>Edit candidate details and manage candidate progress
                        </div>

                        <!--<div class="switch-label grid-x">
                            <label class="switch" for="progress_check">
                                <input
                                    type="checkbox"
                                    name="progress_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.progress_check"
                                    class="custom-control-input"
                                    id="progress_check"
                                />
                                <span class="slider round"></span>
                            </label>Edit and update candidates
                        </div>-->

                        <div class="switch-label grid-x" for="bonus_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="bonus_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.bonus_check"
                                    class="custom-control-input"
                                    id="bonus_check"
                                />
                                <span class="slider round"></span>
                            </label>Allocate bonus points
                        </div>

                        <div class="switch-label grid-x" for="bonus_categories_check">
                          <label class="switch">
                            <input
                              type="checkbox"
                              name="bonus_categories_check"
                              true-value="1"
                              false-value="0"
                              v-model="user.bonus_categories_check"
                              class="custom-control-input"
                              id="bonus_categories_check"
                            />
                            <span class="slider round"></span>
                          </label>Create/Edit bonus point categories
                        </div>

                        <div class="switch-label grid-x" for="approve_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="approve_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.approve_check"
                                    class="custom-control-input"
                                    id="approve_check"
                                />
                                <span class="slider round"></span>
                            </label> Approve payment requests
                        </div>

                        <div class="switch-label grid-x" for="paid_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="paid_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.paid_check"
                                    class="custom-control-input"
                                    id="paid_check"
                                />
                                <span class="slider round"></span>
                            </label>Mark payment requests as paid
                        </div>

                        <div class="switch-label grid-x" for="notification_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="notification_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.notification_check"
                                    class="custom-control-input"
                                    id="notification_check"
                                />
                                <span class="slider round"></span>
                            </label>Send and edit notifications
                        </div>

                        <div class="switch-label grid-x" for="users_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="users_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.users_check"
                                    class="custom-control-input"
                                    id="users_check"
                                />
                                <span class="slider round"></span>
                            </label>Manage app users
                        </div>

                        <div class="switch-label grid-x" for="admin_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="admin_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.admin_check"
                                    class="custom-control-input"
                                    id="admin_check"
                                />
                                <span class="slider round"></span>
                            </label>Manage admin users
                        </div>

                        <div class="switch-label grid-x" for="account_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="account_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.account_check"
                                    class="custom-control-input"
                                    id="account_check"
                                />
                                <span class="slider round"></span>
                            </label>Manage account settings
                        </div>

                        <div class="switch-label grid-x" for="job_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="job_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.job_check"
                                    class="custom-control-input"
                                    id="job_check"
                                />
                                <span class="slider round"></span>
                            </label>Manage job settings
                        </div>

                        <div v-if="pointTariffsFeatureFlag && currentuser.customer.variable_points_tariffs" class="switch-label grid-x" for="tariff_check">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    name="tariff_check"
                                    true-value="1"
                                    false-value="0"
                                    v-model="user.tariff_check"
                                    class="custom-control-input"
                                    id="tariff_check"
                                />
                                <span class="slider round"></span>
                            </label>Assign referral points tariffs
                        </div>

                        <div class="cell auto popup-button">
                            <button
                                class="button pink_btn no_wrap_btn"
                                style="float: right"
                                @click="updatePermission(user)"
                            >Update</button>
                        </div>
                        <!-- </form> -->
                    </div>
                </b-modal>
                <b-modal :id="'deactive-modal-' + user.id" size="lg" modal-class="deactive-modal">
                  <div class="grid-y">
                    <div class="grid-x input-box-2">
                      <div class="cell auto input-box">
                        <h3>Deactivate user</h3>
                        <p>The user is also an app user, you have the option to deactivate only the portal access.</p>
                      </div>
                    </div>
                  </div>
                  <div class="cell auto popup-button">
                    <input
                      type="button"
                      class="button btn_lg btn orange_btn anon-can"
                      value="Deactivate portal and app"
                      @click="toggleActive(user, 1)"
                    />
                    <input
                      class="button btn_lg btn pink_btn"
                      type="submit"
                      value="Deactivate portal only"
                      @click="toggleActive(user, 2)"
                    />
                  </div>
                </b-modal>
                <!--</div>-->
            </template>
        </div>
        <div class="modal fade" id="duplicate-user" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog only_csv" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4><br>Whoops! That email address is registered to an existing user.</h4>
						<p>Please check your list of portal and app users to see if they are already registered. If the email is being used by an app user, and you want them to be able to access your portal, you can convert them into an app user. To find out more, please check out our help article <a href="https://support.carefriends.co.uk/articles/5089665-how-to-covert-an-app-user-into-an-admin-user" target="_blank">here</a>.</p>

                        <div class="cell auto popup-button">
                            <input @click="closeDup()" type="button" class="button btn_lg btn pink_btn" value="Ok">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="paginationData.last_page > 1">
            <paginate
                v-model="paginationData.current_page"
                :page-count="paginationData.last_page"
                :page-range="3"
                :margin-pages="2"
                :click-handler="paginatorClickCallback"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination-custom'"
                :page-class="'page-item'"
                :active-class="'active'"
            ></paginate>
        </template>
    </div>
</template>

<script>
    import Paginate from "vuejs-paginate";
    import Multiselect from 'vue-multiselect'
    import eventBus from '../../utils/event-bus.js';

    Vue.component('multiselect', Multiselect)

    export default {
        components: { paginate: Paginate, Multiselect, eventBus },
        props: ['advanced', 'current', 'currentuser', 'primaryUser', 'pointTariffsFeatureFlag'],
        data(){
            return {
                users: [],
                editMode: false,
                editPermit: false,
                savedStatus: null,
                sentDetails: false,
                availableSites: [],
                searchVar: '',
                myImage: '/images/loading.gif',
                showLoad: false,
                paginationData: {
                    current_page: 0,
                    from: 0,
                    last_page: 0,
                    per_page: 0,
                    to: 0,
                    total: 0,
                },
                newuser:{
                    first_name:" ",
                    surname:"",
                    email:"",
                    phone_number:"",
                    candidate_check:false,
                    bonus_check:false,
                    bonus_categories_check:false,
                    approve_check:false,
                    paid_check:false,
                    notification_check:false,
                    users_check:false,
                    admin_check:false,
                    account_check:false,
                    job_check:false,
                    progress_check:false,
                    tariff_check:false,
                    site_id:[],
                    points_eligible: 1
                },
				user:{
				    site_id:[]
				},
                userData: [],
                siteId: '',
                sites: [],
				aaa: [289, 291],
                eligibleOptions: [{id: 1, name: 'Yes'}, {id: 0, name: 'No'}],
              show_sites: false
            }
        },
    methods: {
        selecttag(value) {
            if (!value || value.length === 0) {
                return;
            }

            const allSitesOption = this.availableSites.find(site => site.id === -1);
            const selectedAllSites = value.find(site => site.id === -1);
            const selectedAllSitesIndex = value.findIndex(site => site.id === -1);

            if (selectedAllSites) {
                if (selectedAllSitesIndex === 0 && value.length > 1) {
                    this.newuser.site_id = value.slice(1);
                } else {
                    this.newuser.site_id = [allSitesOption];
                }
            } else {
                this.newuser.site_id = value.filter(site => site.id !== -1);
            }
        },
				selectedtag(value){
				var self=this;

		         for(var i=0; i < value.length; i++){
					if( value[i].id == -1 || value[i].name=='All Sites'){
					  if(value.length >1 && value[0].id== -1 || value[0].name=='All Sites'){
					  let va = value.splice(i, 1); self.user.sites_users = value;
					  }else if(value.length >1 && value[i].id== -1 || value[i].name=='All Sites'){

               value.splice(0, i);

            self.user.sites_users = value;
					  }else{
					  self.user.sites_users = value[i];
					  }
					}else{
					 self.user.sites_users = value;
					}
				  }

				},
            unlockUser(id) {
                var self = this;
                axios.post('/dashboard/account/unlock-user', {
                    id: id,
                })
                    .then(function (response) {
                        // handle success
                        self.fetchUsers();
                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> User unlocked',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
            },
            closeDup() {
                $('#duplicate-user').hide();
            },
            showMenu() {
                $(this.$el).foundation();
            },
			selectedSites: function( flag = null) {
			    //alert(this.newuser.site_id);
                if (flag) {
                    if (this.newuser.site_id[0] == -1) {
                        this.newuser.site_id.splice(0, 1);
                    }
                }
                if (this.newuser.site_id.length > 1) {
                    if (this.newuser.site_id[0] == -1) {
                        this.newuser.site_id.splice(0, 1);
                    } else {
                        for (var i = 0; i < this.newuser.site_id.length; i++) {
                            if (i == 0) {
                                continue;
                            }
                            if (this.newuser.site_id[i] == -1) {
                                this.newuser.site_id = [];
                                this.newuser.site_id[0] = -1;
                            }
                        }
                    }
                }
                let selected_filter = [];
                for (var i = 0; i < this.availableSites.length; i++) {
                    if($.inArray( this.availableSites[i].id, this.newuser.site_id ) !== -1) {
                        this.availableSites[i].image = '/images/selected.png';
                        selected_filter.push(this.availableSites[i].name);
                    } else {
                        this.availableSites[i].image = '/images/idle.png';
                    }
                }
				 this.newuser.site_id =this.newuser.site_id;
				//alert(values);
                if(selected_filter.length > 0){
                    //this.newuser.site_id=values;
					//alert(values);//alert(this.newuser.site_id);//this.selected_filter_site_tags = 'Site: ' + selected_filter.join(", ");
                }

            },
			selectededitSites: function( flag = null) {
			    alert(this.user.site_id);
                if (flag) {
                    if (this.user.site_id[0] == -1) {
                        this.user.site_id.splice(0, 1);
                    }
                }
                if (this.user.site_id.length > 1) {
                    if (this.user.site_id[0] == -1) {
                        this.user.site_id.splice(0, 1);
                    } else {
                        for (var i = 0; i < this.user.site_id.length; i++) {
                            if (i == 0) {
                                continue;
                            }
                            if (this.user.site_id[i] == -1) {
                                this.user.site_id = [];
                                this.user.site_id[0] = -1;
                            }
                        }
                    }
                }
                let selected_filter = [];
                for (var i = 0; i < this.availableSites.length; i++) {
                    if($.inArray( this.availableSites[i].id, this.user.site_id ) !== -1) {
                        this.availableSites[i].image = '/images/selected.png';
                        selected_filter.push(this.availableSites[i].name);
                    } else {
                        this.availableSites[i].image = '/images/idle.png';
                    }
                }
				 this.user.site_id =this.user.site_id;
				//alert(values);
                if(selected_filter.length > 0){
                    //this.newuser.site_id=values;
					//alert(values);//alert(this.newuser.site_id);//this.selected_filter_site_tags = 'Site: ' + selected_filter.join(", ");
                }

            },
            decideActive(user) {
              if (user.mobile_user && user.active && user.portal_user) {
                this.$bvModal.show('deactive-modal-' + user.id);
              } else {
                this.toggleActive(user);
              }
            },
            toggleActive(user, type = null){
		    	      var self = this;
                if (user.active==1) {
                    user.active = 0;
                } else {
                    user.active = 1;
                }
                axios.post('/dashboard/account/toggle-active', {
                    id: user.id,
                    portal: 1,
                    type: type
                })
                    .then(function (response) {
                        self.$bvModal.hide('deactive-modal-' + user.id);
                        // handle success
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
            },
            getAvailableSites(){
                //Create array of available sites for dropdown
                var allSites = Object.keys(this.sites);
                var availableSites = [];
                var noSite = {
                    id: -1,
                    name: "All sites",
					image: '/images/idle.png'
                };
                availableSites.push(noSite);
                for (var i = allSites.length - 1; i >= 0; i--) {
                  if (this.sites[allSites[i]].name != 'None') {
                    availableSites.push(this.sites[allSites[i]]);
                  }
                }
                this.availableSites = availableSites;
            },
            editUsers() {
                var self = this;
                self.editMode = true;
            },
            AddAdminUser(){
                // alert('Invite Sent')
                Vue.swal({
					title: "Invite sending...",
					html: "",
					allowOutsideClick: false,
					onBeforeOpen: () => {
					  Vue.swal.showLoading();
					}
				  });
                this.savedStatus = 'saving';
                var self = this;
                axios.post('/dashboard/account/new-admin', this.newuser)
                    .then(function (response) {
					 Vue.swal.close();
                        if(response.data.message && response.data.message == 'Email Already Exists') {
                            $('#duplicate-user').show();
                            return;
                        }
                        // handle success

                        self.fetchUsers();
						self.resetForm();


                        self.savedStatus = 'saved';
                        self.$bvModal.hide("addnewadmin");

                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invite Sent',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                    })
                    .catch(function (error) {
                        // handle error
                        if(error.response.data.errors.email[0] != null) {
                            Vue.swal({
                                title: error.response.data.errors.email[0],
                                confirmButtonColor: '#F84366'
                            });
                        }
                    })
            },
			resetForm() {
			  this.newuser = {
				    first_name:"",
                    surname:"",
                    email:"",
                    phone_number:"",
                    candidate_check:false,
                    bonus_check:false,
                    bonus_categories_check:false,
                    approve_check:false,
                    paid_check:false,
                    notification_check:false,
                    users_check:false,
                    admin_check:false,
                    account_check:false,
                    job_check:false,
                    progress_check:false,
                    tariff_check:false,
                    site_id:"",
                    points_eligible: 1
			  };

			},
            updatePermission(user){
			Vue.swal({
				title: "Updating...",
				html: "",
				allowOutsideClick: false,
				onBeforeOpen: () => {
				  Vue.swal.showLoading();
				}
			  });
                this.savedStatus = 'saving';
                var self = this;
                axios.post('/dashboard/account/editportalPermissions', user)
                    .then(function (response) {
                        // handle success
                        self.savedStatus = 'saved';
                        self.paginatorClickCallback(self.paginationData.current_page);
                        self.$bvModal.hide("permission-"+user.id);
                        eventBus.$emit('userPermissionChanges', 'yes');
                        self.fetchSites();

                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Success',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                    })
                    .catch(function (error) {
                        // handle error
                        if(error.response.data.errors.email[0] != null) {
                            Vue.swal({
                                title: error.response.data.errors.email[0],
                                confirmButtonColor: '#F84366'
                            });

                            Vue.swal({
                                html:
                                    '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Could not send Invite',
                                confirmButtonColor: "#5dc2a6",
                                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                background: "#f55d73",
                                customClass: {
                                    container: "candidate-toast"
                                },
                                showClass: {
                                    popup: "animated fadeInLeft fast",
                                    icon: "animated heartBeat delay-1s"
                                },
                                hideClass: {
                                    popup: "animated fadeOutLeft slow"
                                },
                                toast: true,
                                timer: 5000,
                                position: "top-right",
                                target: "#example-tabs"
                            });

                        }
                    })
            },
            deleteUser(userId) {
                this.$swal({
                    title: 'Are you sure you want to delete this user?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#F84366',
                    cancelButtonColor: '#FA9748',
                    confirmButtonText: 'Yes, delete user'
                }).then((result) => {
                        if (result.value) {
                            if (userId) {
                                axios.post('/dashboard/account/user/delete/' + userId,
                                    {
                                        userId: result.value,
                                        portal: 1
                                    })
                                    .then(function (response) {
                                        // handle success
                                        if(response.data.success) {
                                            //Show delete successful message
                                            Vue.swal({
                                                title: 'Deleted!',
                                                text: 'The user has been deleted',
                                                type: 'success',
                                                confirmButtonColor: '#FA9748',
                                            });
                                            window.location = '/dashboard/account/#dashboard-users';
                                        }
                                    })
                                    .catch(function (error) {
                                        // handle error
                                        console.log(error);
                                    })
                            }
                        }
                    }
                )
            },
            editPermissions(user){
                var self = this;
                self.editPermit = true;
			    //this.editsites(user);
            },
            sendDetails(user){
                var self = this;
                Vue.swal({
                    title: 'Processing...Please wait',
                    confirmButtonColor: '#FA9748'
                });
                Vue.swal.showLoading();
                axios.post('/dashboard/account/send-details', user)
                    .then(function (response) {
                        // handle success
                        self.sentDetails = true;
                        Vue.swal({
                            title: 'Details sent!',
                            confirmButtonColor: '#FA9748'
                        });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);

                    })
            },
            searchUsers: function () {
                if(this.searchVar.length > 2) {
                    this.showLoad = true;
                    var self = this;
                    axios.post('/dashboard/account/search', {searchVar:this.searchVar})
                        .then(function (response) {
                            if(response.data.users.data) {
                                self.users = response.data.users.data;
                                $(self.$el).foundation();
                                self.paginationData.current_page = response.data.users.current_page;
                                self.paginationData.from = response.data.users.from;
                                self.paginationData.last_page = response.data.users.last_page;
                                self.paginationData.per_page = response.data.users.per_page;
                                self.paginationData.to = response.data.users.to;
                                self.paginationData.total = response.data.users.total;
                            }
                            self.showLoad = false;
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                    /* .finally(function () {
                         self.showLoad = false;
                     })*/
                } else {
                    this.users = this.userData.data;
                    $(this.$el).foundation();
                    this.paginationData.current_page = this.userData.current_page;
                    this.paginationData.from = this.userData.from;
                    this.paginationData.last_page = this.userData.last_page;
                    this.paginationData.per_page = this.userData.per_page;
                    this.paginationData.to = this.userData.to;
                    this.paginationData.total = this.userData.total;
                }
            },
            searchUsersData: function () {
                if(this.searchVar.length <= 2) {
                    this.users = this.userData.data;
                    $(this.$el).foundation();
                    this.paginationData.current_page = this.userData.current_page;
                    this.paginationData.from = this.userData.from;
                    this.paginationData.last_page = this.userData.last_page;
                    this.paginationData.per_page = this.userData.per_page;
                    this.paginationData.to = this.userData.to;
                    this.paginationData.total = this.userData.total;
                } else {
                    this.searchUsers();
                }
            },
            paginatorClickCallback: function (pageNum) {
                let extraParams = '?page=' + pageNum;
                if (this.siteId != '') {
                    extraParams += '&siteId=' + this.siteId;
                }
                var self = this;
                axios.get('/dashboard/account/get-portal-users' + extraParams)
                    .then(function (response) {
                        if (response.data.users.data) {
                            self.users = response.data.users.data;
                            $(self.$el).foundation();
                            self.paginationData.current_page = response.data.users.current_page;
                            self.paginationData.from = response.data.users.from;
                            self.paginationData.last_page = response.data.users.last_page;
                            self.paginationData.per_page = response.data.users.per_page;
                            self.paginationData.to = response.data.users.to;
                            self.paginationData.total = response.data.users.total;
                            self.siteId = response.data.siteId;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchUsers: function () {
                var self = this;
                axios.post('/dashboard/account/fetch')
                    .then(function (response) {
                        if(response.data.users.data) {
                            self.users = response.data.users.data;
                            $(self.$el).foundation();
                            self.paginationData.current_page = response.data.users.current_page;
                            self.paginationData.from = response.data.users.from;
                            self.paginationData.last_page = response.data.users.last_page;
                            self.paginationData.per_page = response.data.users.per_page;
                            self.paginationData.to = response.data.users.to;
                            self.paginationData.total = response.data.users.total;
                            self.userData = response.data.users;
                            self.siteId = response.data.siteId;
                            self.show_sites = response.data.show_sites;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchSites: function () {
                var self = this;
                axios.get('/dashboard/account/get-portal-sites')
                    .then(function (response) {
                        if (response.data.sites) {
                            self.sites = response.data.sites;
                            if (self.sites) {
                                self.getAvailableSites();
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
			editsites(sites){
			var self = this;
			for (var i = 0; i<sites.sites_users.length; i++) {
                    this.user.site_id.push(sites.sites_users[i].site_id);
                }
                this.user.site_id = this.user.site_id;

			},
            removeSelectTag: function (index) {
                this.newuser.site_id.splice(index, 1)
            },
            removeSelectedTag: function (index, sites_users) {
                sites_users.splice(index, 1)
                this.user.sites_users = sites_users;
            },
        },
        mounted() {
            this.fetchUsers();
            this.fetchSites();

        },
        computed:{
            newbtnDisbaled(){
                if(this.newuser.first_name && this.newuser.surname && this.newuser.email && this.newuser.phone_number){
                    return false;
                }else return true;
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.pane-leftside
{
    left: -7% !important;
}
.multiselect__content{
 font:400 13px/20px "InterUI", sans-serif !important;
}
.multiselect__option {
color: #363b54;
  font:400 13px/20px "InterUI", sans-serif !important;
  text-transform: none;
  cursor: pointer;
  white-space: nowrap;
 }
 .multiselect, .multiselect__input, .multiselect__single{
   color: #363b54;
   font:400 13px/20px "InterUI", sans-serif !important;
 }
 .multiselect:focus, .multiselect__input:focus, .multiselect__single:focus{
   color: #363b54;
   font:400 13px/20px "InterUI", sans-serif !important;
 }
 .multiselect, .multiselect__input, .multiselect__single{
   color: #363b54;
   font:400 13px/20px "InterUI", sans-serif !important;
 }
 .multi-sites .multiselect__tags{
 color: #363b54;
   font:400 13px/20px "InterUI", sans-serif !important;
 }
 .multiselect__input::placeholder {
  color: #363b54;
  font: 400 13px/20px "InterUI", sans-serif !important;
}
.multiselect--active .multiselect__input::placeholder {
  color: #363b54;
  font: 400 13px/20px "InterUI", sans-serif !important;
}
.multiselect__input:focus, .multiselect__input:hover, {
  color: #363b54;
  font: 400 13px/20px "InterUI", sans-serif !important;
}
.input-tagged {
  float: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
  flex-basis: 100%;
  flex-grow: 1;
}
.tag-right {
  margin-right: 5px;
}

</style>
