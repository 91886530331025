<template>
    <div class="job-filter-container">
        <div class="grid-x">
            <div class="cell auto font_0875 no-padding">
                <div class="grid-x"
                     style="margin-bottom: -10px;">
                    <div class="cell small-2 pull-left"
                         style="padding-top: 12px; padding-left: 25px;">
                        <div class="tot-num-bonus">{{ totalJobsCount }}</div>
                        <div class="total-points-bonus">Jobs</div>
                    </div>
                    <div class="cell small-10 pull-right bonus-history-top"
                         style="padding-top: 12px;padding-right: 12px;">
                        <div class="cell shrink">
                            <a href="javascript:void(0)"
                               :class="filterBtn"
                               :id="'filters'"
                               data-toggle="dropdown"
                               aria-haspopup="true"
                               aria-expanded="false"
                               @click="showMenu()">
                                <span>
                                    <icon :name="filterIcon" />
                                </span>
                                Filters
                                <span v-if="filterCount > 0"
                                      class="filter-count">
                                    ({{ filterCount }})
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-pane filter-dropdown"
                                 :aria-labelledby="'filters'"
                                 @click.stop>

                                <template>
                                    <div class="more-edit-category"
                                         style="padding: 20px">
                                        <h4>Filters</h4>
                                        <div class="form-group">
                                            <label for="inputName">STATUS</label>
                                            <single-select v-model="statusFilterQuery"
                                                           :options="statusDropdownOptions"
                                                           placeholder="Select status..." />
                                        </div>
                                        <div class="form-group">
                                            <label for="inputEmail">SITE</label>
                                            <multi-select v-model="sitesFilterQuery"
                                                          :options="sitesDropdownOptions"
                                                          placeholder="Select site(s)..." />
                                            <span class="error-message"
                                                  v-if="false">
                                                Please select site(s)...
                                            </span>
                                        </div>

                                        <div class="form-group">
                                            <button class="filter-btn-1"
                                                    @click="clearFilters">Clear filters</button>
                                            <button class="filter-btn-2 float-right"
                                                    @click="applyFilters()">Apply filters</button>
                                        </div>

                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="cell shrink">
                            <a class="float-right filter-menu-btn"
                               :href="exportUrl">
                                <span>
                                    <icon name="export" />
                                </span> Export
                            </a>
                        </div>
                        <div class="cell shrink">
                            <div class="float-right"
                                 style="padding-right: 12px;">
                                <input class="filter-search"
                                       placeholder="Search..."
                                       v-model="searchQuery"
                                       name="searchVar"
                                       @keyup.delete="getJobs()"
                                       @keyup="getJobs()" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from "../../../../app/components/Icon";
import MultiSelect from "../MultiSelect/MultiSelect.vue";
import SingleSelect from "../SingleSelect/SingleSelect.vue";


export default {
    name: "JobFilter",
    props: {
        totalJobsCount: { type: Number, required: true },
        searchVar: { type: String, required: true },
        statusFilter: { type: Number, required: true },
        sitesDropdownOptions: { type: Array, required: true },
        siteFilter: { type: Array, required: true },
        currentSort: { type: String, default: "" },
        currentSortDir: { type: String, default: "" }
    },
    components: {
        Icon,
        MultiSelect,
        SingleSelect
    },
    data() {
        return {
            searchQuery: this.searchVar,
            statusFilterQuery: this.statusFilter,
            sitesFilterQuery: this.siteFilter,
            filterCount: 0,
            statusDropdownOptions: [
                { id: 2, name: "Active", image: "/images/idle.png" },
                { id: 3, name: "Inactive", image: "/images/idle.png" }
            ]
        };
    },
    methods: {
        showMenu() {
            $(this.$el).foundation();
        },
        applyFilters() {
            this.getJobs();
            this.closeMenu();
        },
        closeMenu() {
            const dropdown = this.$el.querySelector('.filter-dropdown');
            if (dropdown && dropdown.classList.contains('show')) {
                dropdown.classList.remove('show');
            }
        },
        getJobs() {
            this.$emit('update:searchVar', this.searchQuery);
            this.$emit('update:statusFilter', this.statusFilterQuery);
            this.$emit('update:siteFilter', this.sitesFilterQuery);
            this.$emit("getJobs");
        },
        clearFilters() {
            this.statusFilterQuery = 0;
            this.sitesFilterQuery = [];
            this.applyFilters();
        },
        updateFilterCount() {
            let count = 0;
            if (this.statusFilterQuery) count++;
            if (this.sitesFilterQuery.length > 0) count++;
            this.filterCount = count;
        }
    },
    watch: {
        searchVar(newValue) {
            this.searchQuery = newValue;
        },
        statusFilterQuery() {
            this.updateFilterCount();
        },
        sitesFilterQuery() {
            this.updateFilterCount();
        }
    },
    computed: {
        exportUrl() {
            return `/dashboard/jobsexport?searchVar=${this.searchQuery}&siteId=${this.sitesFilterQuery}&filter=${this.statusFilterQuery}&column_name=${this.currentSort}&sort=${this.currentSortDir}`;
        },
        filterBtn() {
            return this.filterCount > 0 ? 'float-right filter-menu-btn-fill' : 'float-right filter-menu-btn';
        },
        filterIcon() {
            return this.filterCount > 0 ? 'WhiteFilterTable' : 'FilterTable';
        }
    }
};
</script>

<style scoped>
@import "./JobFilter.scss";

.left-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.right-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
</style>