<template>
    <div>
	veer singh
        <!--<v-select placeholder="All sites" 
		    v-model="site_id" 
			:reduce="referrer => referrer.id" 
			label="name"
            :options="siteoption">
		</v-select>-->
        <input type="hidden" name="site_id" :value="site_id">
    </div>
	
</template>

<script>
   export default {
	   props: {
         sitedata: Array
         }, 
        data() {
            return {
                siteoption: [],
                site_id: ''
            }
        },
        watch: {
            site_id: function (val) {
                this.$emit('selectedsite_id', this.site_id)
            }
        },
        mounted() {
	               
            /*for (var i =0; i<this.sitedata.length; i++) {
                var sites = {
                        name: this.sitedata[i].name,
                        id: this.sitedata[i].id
                }
				console.log(sites);
                this.siteoption.push(sites);
            }*/
        }
    }
</script>
