<template>
  <div>
    <v-select class="bonus-placeholder" placeholder="Select" v-model="reward_catagory" label="Title" :options="availableRewards"></v-select>
  </div>
</template>

<script>
    export default {
        props: ['childComponant'],
        data(){
          return {
            availableRewards: [],
            reward_catagory: []
          }
        },
        watch: {
            reward_catagory: function(val, oldVal){
                if(val){
                  this.$emit('selectedReward', val.id)
                }
            }
        },
        methods: {
          clear() {
            this.reward_catagory = null;
          },
          fetchRewardCatagories(){
            var self = this;
            axios.get('/dashboard/account/reward-cat')
              .then(function (response) {
                // handle success
                self.availableRewards = response.data.rewardCatagories;
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              })
          }
        },
        mounted(){
          this.childComponant.$on('fetchRewardCatagories', this.fetchRewardCatagories);
          this.fetchRewardCatagories();
        }
    }
</script>
