<template>
    <div>
        <v-select placeholder="Call to action" v-model="CTA" :reduce="referrer => referrer.id" label="name"
                  :options="ctaoptions"></v-select>
        <input type="hidden" name="CTA" :value="CTA">
    </div>
</template>

<script>
    export default {
        data() {
            return {
			ctaoptions: [{id: '', name: 'No action'}, {id: 'POINTS', name: 'View the leaderboard'}, {id: 'JOBS', name: 'Go to jobs'}],
                CTA: ''
            }
        },
        watch: {
            CTA: function (val) {
                this.$emit('selectedCTA', this.CTA)
            }
        }
    }
</script>
