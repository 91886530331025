<template>
    <div class="dropdown-container"
         :class="{'empty-input': applyRedBorder}"
         ref="dropdown">
        <div class="selected-option"
             :style="{color: resetField ? '#cacaca' : (selectedOption ? '#3C3C3C' : '#cacaca')}"
             @click="toggleDropdown">
            {{ resetField ? placeholder : (selectedOption || placeholder) }}
            <img :class="['arrow', { 'arrow-up': isOpen }]"
                 src="/images/svg/dropdown_icon.svg"
                 alt="Dropdown arrow">
        </div>
        <transition name="fade">
            <ul v-if="isOpen"
                class="options-list">
                <li v-if="searchable"
                    :style="{padding: '10px'}"
                    :key="0">
                    <input ref="filterSingleSearchInput"
                           class="filter-search-input"
                           type="text"
                           v-model="searchKeyword"
                           placeholder="Enter name to filter" />
                </li>
                <li class="single-option"
                    v-for="option in filteredOptions"
                    :key="option.id"
                    @click="selectOption(option.id, option.name)"
                    :style="{fontWeight: option.id === value ? '700' : 'normal'}">
                    {{ option.name }}
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        options: { type: Array, required: true },
        placeholder: { type: String, default: 'Select items...' },
        resetField: { type: Boolean, default: false },
        searchable: { type: Boolean, default: false },
        value: { type: [String, Number] }
    },
    data() {
        return {
            isOpen: false,
            selectedOption: null,
            applyRedBorder: false,
            searchKeyword: '',
            filteredOptions: this.options,
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectOption(id, name) {
            this.selectedOption = name;
            this.$emit('input', id);
            this.isOpen = false;
        },
        handleOutsideClick(event) {
            if (this.isOpen && !this.$refs.dropdown.contains(event.target)) {
                this.isOpen = false;
            }
        },
        filterOptions() {
            const query = this.searchKeyword.toLowerCase().trim();
            this.filteredOptions = query
                ? this.options.filter(option => option.name.toLowerCase().includes(query))
                : this.options;
        },
    },
    mounted() {
        if (this.value !== null) {
            this.selectedOption = this.options.find(option => option.id === this.value)?.name;
        }
        document.addEventListener("mousedown", this.handleOutsideClick);
    },
    beforeDestroy() {
        document.removeEventListener("mousedown", this.handleOutsideClick);
    },
    watch: {
        value(newVal) {
            this.selectedOption = this.options.find(option => option.id === newVal)?.name;
        },
        searchKeyword: 'filterOptions',
        options: 'filterOptions',
    },
};
</script>

<style scoped>
@import "./SingleSelect.scss";
</style>