<template>
  <div>
    <div
      v-show="showLoad"
      style="
        position: fixed;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        z-index: 9999;
      "
    >
      <img :src="myImage" />
    </div>
    <div v-if="showImg" class="show-load-client-super">
      <img :src="loadImage" />
    </div>
    <div class="grid-x grid-padding-x">
      <div
        class="cell shrink client-bottom candidate-margin-left"
        @click="postData"
      >
        <a :disabled="enableSaveButton == false" class="btn button orange_btn">
          Update</a
        >
      </div>
      <div class="cell shrink client-bottom" @click="openAddTemplateModal">
        <a class="btn-edit button pink_btn b_margin_0 no_wrap_btn">
          Add Template</a
        >
      </div>
    </div>

    <div class="cf-table client-table">
      <div class="bg-card">
        <div class="container-fluid Goodnews">
          <h4 class="automation-h-new1">Engagement</h4>
        </div>
        <table class="table1">
          <thead class="table-body-styling1">
            <tr>
              <td class="table-head-styling" scope="col">CONTENT</td>
              <!-- <td class="table-head-styling" scope="col">ALL</td> -->
              <td class="table-head-styling" scope="col">UK<br/>CF</td>
              <td class="table-head-styling" scope="col">UK<br/>WF</td>
              <td class="table-head-styling" scope="col">ROI<br/>CF</td>
              <td class="table-head-styling" scope="col">AUS<br>CF</td>
              <td class="table-head-styling" scope="col">NL<br>WF</td>
            </tr>
          </thead>
          <tbody>
            <tr
              class="automation-hover-edit"
              v-for="(template, index) in this.templates['engagement']"
              v-bind:key="template.id"
            >
              <td scope="row" class="text-p-styling-automation">
                <div class="edit-automations-div">
                  <div>
                    <span
                      class="table-title-styling1"
                      v-html="
                        template.title
                          .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 80).length < 79
                          ?   template.title
                              .replace(/<\/?[^>]+(>|$)/g, '')
                              .substring(0, 80)
                          :  template.title
                              .replace(/<\/?[^>]+(>|$)/g, '')
                              .substring(0, 80) + '..'
                      "

                       
                    >
                      <!-- {{ template.title }}
                      -->
                    </span>

                    <!-- .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 80) + '..' -->

                    <p
                      v-b-tooltip.hover.html="{
                        customClass: 'Notification-tooltip',
                        title: template.message,
                      }"
                      class="p-styling1"
                      v-html="
                        template.message
                          .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 80).length < 79
                          ?   template.message
                              .replace(/<\/?[^>]+(>|$)/g, '')
                              .substring(0, 80)
                          :   template.message
                              .replace(/<\/?[^>]+(>|$)/g, '')
                              .substring(0, 80) + '..'
                      "

                      

                    ></p>

                    <!-- <span
                      class="p-styling1"
                      v-html="
                        template.message
                          .replace(/<\/?[^>]+(>|$)/g, '')
                          .substring(0, 80) + '..'
                      "
                    >
                      {{ template.message }}
                    </span> -->
                  </div>

                  <div class="img-automation">
                    <img
                      @click="
                        editNotification(template.id, index, template.category)
                      "
                      src="/images/icon-24-edit-02.png"
                      alt=""
                    />

                    <img
                      @click="
                        showDeleteNotifcationModal(
                          template.id,
                          index,
                          template.category
                        )
                      "
                      src="/images/icon-24-delete.png"
                      alt=""
                    />
                  </div>
                 </div>
              </td>

              <!-- UK_CF -->
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-bind:checked="
                        template.micrositeIds.includes(
                          microsites.filter(microsite => microsite.code == 'UK_CF')[0].id
                        )
                      "
                      @click="toggleMicrosite(template.id, 'UK_CF')"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>
              
              <!-- UK_WF -->
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-bind:checked="
                        template.micrositeIds.includes(
                          microsites.filter(microsite => microsite.code == 'UK_WF')[0].id
                        )
                      "
                      @click="toggleMicrosite(template.id, 'UK_WF')"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>

              <!-- IE_CF / ROI_CF -->
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-bind:checked="
                        template.micrositeIds.includes(
                          microsites.filter(microsite => microsite.code == 'IE_CF')[0].id
                        )
                      "
                      @click="toggleMicrosite(template.id, 'IE_CF')"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>

              <!-- AU_CF -->
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-bind:checked="
                        template.micrositeIds.includes(
                          microsites.filter(microsite => microsite.code == 'AU_CF')[0].id
                        )
                      "
                      @click="toggleMicrosite(template.id, 'AU_CF')"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>

              <!-- NL_WF -->
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-bind:checked="
                        template.micrositeIds.includes(
                          microsites.filter(microsite => microsite.code == 'NL_WF')[0].id
                        )
                      "
                      @click="toggleMicrosite(template.id, 'NL_WF')"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal
      ref="add-job"
      id="edit-template"
      size="xl"
      :no-close-on-backdrop="true"
    >
      <section class="multi-step-row">
        <div class="pop-up-box">
          <div class="text-box" id="text-box">
            <fieldset>
              <div class="job-leftside">
                <form>
                  <div class="form-group">
                    <p class="Edit-notification">Edit Template</p>
                    <label
                      for="exampleInputEmail1"
                      class="exampleInputEmailtitle"
                      >Title *</label
                    >
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            v-model="currentObject.title"
                                            aria-errormessage="notificationTitle"
                                        /> -->

                    <ckeditor
                      class="form-control text-area-border styling-bottom"
                      placeholder="Add Wording"
                      id="exampleFormControlTextarea1"
                      name="Message"
                      rows="5"
                      v-model="currentObject.title"
                      :config="titleEditorConfig"
                    >
                    </ckeditor>

                    <span class="form-error" id="notificationTitle"
                      >Please enter a notification title</span
                    >
                  </div>

                  <div class="form-group">
                    <label class="wording" for="exampleFormControlTextarea1"
                      >WORDING *</label
                    >
                    <ckeditor
                      class="cheditor-styling"
                      :config="editorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObject.message"
                    ></ckeditor>
                  </div>
                  
                  <div class="imgae-Modal-styling">
                    <p class="img-Content-styling">Image</p>
                  </div>
                  <label class="Image-label-content"></label>
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadEditImage"
                    id="actual-btn"
                    hidden
                  />
                  <label for="actual-btn" class="upload-file-styling">
                    <img class="img-upload-btn" src="/images/Capture.PNG" />
                    <span
                      >Drag and drop an image or browse to choose a file.Min
                      size 180 x 180 px</span
                    >
                  </label>
                </form>

                <!-- <div v-if="currentObject.imageName != ''">
                  <div class="img-upload-div-show">
                    <p class="image-Name-upload">
                      {{ currentObject.imageName }}
                    </p>
                    <span @click="currentObject.imageName = ''"
                      ><img src="/images/icon-24-exit.webp" alt=""
                    /></span>
                  </div>
                </div> -->
                <div
                  class="img-upload-hide"
                  v-if="currentObject.image_url"
                  >
                  <i
                    class="fa fa-times-circle"
                    @click="currentObject.image_url = ''"
                  ></i>
                  <img
                    :src="currentObject.image_url"
                    alt=""
                    class="img-upload-hide-img"
                  />
                </div>
              </div>
              <div class="job-rightside">
                <div class="frame">
                  <img
                    class="img-mobile-notification edit-mobile-image"
                    src="/images/notification-preview-image.png"
                    alt=""
                  />

                  <div class="mobile-notification-title-automation">
                    <div class="mobile-notification-branding-table line-clamp-title">
                        <img src="/images/cf-favicon.png"/>
                        <p>CARE FRIENDS</p>
                    </div>
                    <p
                      class="
                        mobile-notification-title-automation
                        hidden-title
                        line-clamp-title
                      "
                      v-html="currentObject.title"
                    ></p>
                    <p
                      class="mobile-notification-paragraph-automation"
                      v-html="currentObject.message"
                    ></p>
                  </div>
                </div>
              </div>

              <div class="cell auto popup-button pad-top edit-btn-styling">
                <button @click.prevent="closeModal" class="Cancel button btn">
                  Cancel
                </button>
                <input
                  @click="save"
                  type="button"
                  name="Edit"
                  class="next action-button button btn_lg btn pink_btn"
                  value="Save"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>
    <b-modal
      ref="add-template"
      id="add-template"
      size="xl"
      :no-close-on-backdrop="true"
    >
      <section class="multi-step-row">
        <div class="pop-up-box">
          <div class="text-box" id="text-box">
            <fieldset>
              <div class="job-leftside">
                <form>
                  <div class="form-group">
                    <p class="Edit-notification">Add Template</p>

                    <label for="exampleInputEmail1" class="Title-content"
                      >Title *</label
                    >
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            aria-errormessage="notificationTitle"
                                            required
                                            v-model="form.Title"
                                        /> -->
                    <ckeditor
                      class="cheditor-styling-title"
                      :config="titleEditorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObjectAdd.title"
                    ></ckeditor>
                    <span class="form-error" id="notificationTitle"
                      >Please enter a notification title</span
                    >
                  </div>
                  <div class="form-group">
                    <label class="wording" for="exampleFormControlTextarea1"
                      >WORDING *</label
                    >
                    <!-- <div class="text-area-header">
                                            <img
                                                src="/images/icon-text-list-bullet-copy.png"
                                                alt=""
                                            />
                                          <img src="/images/icon-24-undo2.png" alt="" />
                                          <img src="/images/icon-24-redo.png" alt="" />
                                        </div> -->
                    <ckeditor
                      class="cheditor-styling"
                      :config="editorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObjectAdd.message"
                    ></ckeditor>
                    <!-- <textarea
                                            class="form-control text-area-border"
                                            placeholder="Add Wording"
                                            id="exampleFormControlTextarea1"
                                            v-model="form.Message"
                                            name="Message"
                                            rows="5"
                                        >
                                      </textarea> -->
                    <label for="exampleInputEmail1" class="label-catagory-temp"
                      >Catagory *</label
                    >
                    <div class="catagory-automation-dropdown">
                      <select
                        class="select-border"
                        required
                        name="category"
                        id="category"
                        v-model="currentObjectAdd.category"
                      >
                        <option value="engagement">Engagement</option>
                      </select>
                    </div>
                  </div>

                  <div class="imgae-Modal-styling">
                    <p class="img-Content-styling">Image</p>
                  </div>
                  <label class="Image-label-content"></label>
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadImage"
                    id="actual-btn"
                    hidden
                  />
                  <label for="actual-btn" class="upload-file-styling">
                    <img class="img-upload-btn" src="/images/Capture.PNG" />
                    <span
                      >Drag and drop an image or browse to choose a file.Min
                      size 180 x 180 px</span
                    >
                  </label>
                </form>
                <div v-if="currentObjectAdd.imageName != ''">
                  <div class="img-upload-div-show">
                    <p class="image-Name-upload">
                      {{ currentObjectAdd.imageName }}
                    </p>
                    <span @click="currentObjectAdd.imageName = ''"
                      ><img src="/images/icon-24-exit.webp" alt=""
                    /></span>
                  </div>
                </div>
              </div>
              <div class="job-rightside">
                <div class="frame mobile-view">
                  <img
                    class="img-mobile-notification"
                    src="/images/component-notifications-preview.jpg"
                    alt=""
                  />
                  <div class="mobile-notification-title">
                    <img class="img-upload" :src="currentObjectAdd.image_url ? currentObjectAdd.image_url : '/images/cf-login-logo.png'" />
                    <p
                      class="
                        mobile-notification-title
                        hidden-title
                        line-clamp-title
                      "
                      v-html="currentObjectAdd.title"
                    ></p>
                    <p
                      class="mobile-notification-paragraph"
                      v-html="currentObjectAdd.message"
                    ></p>
                  </div>
                </div>
              </div>

              <div class="cell auto popup-button pad-top edit-btn-styling">
                <button
                  @click.prevent="closeAddTemplateModal"
                  class="Cancel button btn"
                >
                  Cancel
                </button>
                <input
                  @click="saveNewTemplate"
                  type="button"
                  name="Edit"
                  class="next action-button button btn_lg btn pink_btn"
                  value="Save"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>
    <b-modal class="tab-modal" id="modal_delete_template" size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <div class="discard-header4">
              <h3 class="discard-header-title">Are you sure to delete it?</h3>
            </div>
            <p class="if-you-discard-chang">It can not be undone.</p>
          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input
          class="button btn_lg btn pink_btn"
          type="submit"
          value="Delete"
          style="
            float: right;
            margin-right: -13px;
            margin-bottom: 13px;
            margin-left: 7px;
          "
          @click="deleteNotificationPermanent"
        />
        <input
          type="button"
          class="button btn_lg btn orange_btn anon-can"
          value="Cancel"
          @click="closeModal"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import CKEditor from "ckeditor4-vue/dist/legacy.js";

export default {
  props: ["templates", "microsites"],
  CKEditor,
  components: { paginate: Paginate },
  data() {
    return {
      myImage: "/images/loading-circle.gif",
      showLoad: false,
      titleEditorConfig: {
        autoParagraph: false,
        autoGrow_maxHeight: 50,
        height: 60,
        editorplaceholder: "Add title",
        // removePlugins : 'autogrow',
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      step: 1,
      currentObject: {
        id: null,
        customer_id: null,
        trigger: "",
        title: "",
        image_url: "",
        imageName: "",
        message: "",
        status: 1,
        category: "",
        push: 1,
        in_app: 1,
        created_at: "",
        updated_at: "",
      },
      currentObjectAdd: {
        id: null,
        customer_id: null,
        trigger: "",
        title: "",
        image_url: "",
        imageName: "",
        message: "",
        status: 1,
        category: "engagement",
        push: 1,
        in_app: 1,
        created_at: "",
        updated_at: "",
      },
      currentIndex: null,
      currentCategory: "",
      editorConfig: {
        editorplaceholder: "Add words",
        autoParagraph: false,
        height: 148,
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      enableSaveButton: false,
    };
  },
  methods: {
    toggleMicrosite(templateId, code) {
      const micrositeCodeId = this.microsites.filter(m => m.code == code)[0].id;
      this.templates.engagement.map((template) => {
        if (template.id == templateId) {
          if (template.micrositeIds.includes(micrositeCodeId)) {
            template.micrositeIds = [...template.micrositeIds].filter(m => m != micrositeCodeId);
          } else {
            const templateMicrositeIds = [...template.micrositeIds, micrositeCodeId];
            templateMicrositeIds.sort();
            template.micrositeIds = templateMicrositeIds;
          };
          template.isMicrositeIdsChanged = true;
        };
      });
      this.enableSaveButton = true;
    },
    saveNewTemplate() {
      let that = this;
      that.showLoad = true;
      axios
        .post("/dashboard/super/add/template", this.currentObjectAdd)
        .then(function (response) {
          that.closeAddTemplateModal();
          that.showLoad = false;

          that.currentObjectAdd.id = response.data.saved.id;
          let temp = Object.assign({}, that.templates);
          if (temp[that.currentObjectAdd.category]) {
            temp[that.currentObjectAdd.category].push(that.currentObjectAdd);
          } else {
            temp[that.currentObjectAdd.category] = [that.currentObjectAdd];
          }

          that.templates = Object.assign({}, temp);

          that.resetValues();

          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> ' + response.data.message,
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log("Error Adding Template Notification - error: ", error);
        })
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.fileName = image.name;
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.currentObjectAdd.imageName = e.target.fileName;
        this.currentObjectAdd.image_url = e.target.result;
      };
    },
    uploadEditImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.fileName = image.name;
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.currentObject.imageName = e.target.fileName;
        this.currentObject.image_url = e.target.result;
      };
    },
    closeAddTemplateModal() {
      this.$bvModal.hide("add-template");
    },
    openAddTemplateModal() {
      this.$bvModal.show("add-template");
    },
    editNotification(id, index, category = "") {
      console.log("called notification # ", id);
      console.log("prev value ::: ", this.currentObject);

      this.currentIndex = index;
      this.currentCategory = category;

      let value = this.templates[category][index];
      value.imageName = "";
      this.currentObject = Object.assign({}, value);

      this.$bvModal.show("edit-template");
    },
    showDeleteNotifcationModal(id, index, category = "") {
      this.currentIndex = index;
      this.currentCategory = category;

      let value = this.templates[category][index];
      this.currentObject = Object.assign({}, value);

      console.log("now value ::: ", this.currentObject);

      this.$bvModal.show("modal_delete_template");
    },
    deleteNotificationPermanent() {
      let that = this;
      that.showLoad = true;
      axios
        .post("/dashboard/super/delete/template", { id: this.currentObject.id })
        .then(function (response) {
          let temp = JSON.parse(JSON.stringify(that.templates));
          temp[that.currentCategory].splice(that.currentIndex, 1);
          that.templates = JSON.parse(JSON.stringify(temp));

          that.$bvModal.hide("modal_delete_template");
          that.showLoad = false;

          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> ' + response.data.message,
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log("Error Deleting Template Notification - error: ", error);
        });
    },
    save() {
      let tempArr = JSON.parse(JSON.stringify(this.templates));
      tempArr[this.currentCategory][this.currentIndex] = JSON.parse(
        JSON.stringify(this.currentObject)
      );
      this.templates = JSON.parse(JSON.stringify(tempArr));

      this.$bvModal.hide("edit-template");
    },
    closeModal() {
      this.$bvModal.hide("edit-template");
      this.$bvModal.hide("modal_delete_template");
    },
    postData() {
      if (this.enableSaveButton == false) return;

      this.enableSaveButton = false;
      let that = this;
      that.showLoad = true;
      axios
        .post("/dashboard/super/update/templates", this.templates)
        .then(function (response) {
          const message = 'Update running. Please allow 2-3 minutes for completion.';
          that.showToast(message);

          that.enableSaveButton = false;
          that.templates.engagement.map((e) => {
            e.isMicrositeIdsChanged = false;
          });
        })
        .catch(function (error) {
          if (error.response.status == 423) {
            const message = 'Another job is in progress. Please wait for its completion.';
            that.showToast(message);
          } else {
            console.error(error);
          };
        })
        .finally(function () {
          that.showLoad = false;
        });
    },
    toggleStatus(value, index, category) {
      let tempArr = JSON.parse(JSON.stringify(this.templates));
      tempArr[category][index].status = value;
      this.templates = JSON.parse(JSON.stringify(tempArr));
    },
    resetValues() {
      this.currentObjectAdd = {
        id: null,
        customer_id: null,
        trigger: "",
        title: "",
        image_url: "http://portal.cf-staging.co.uk/images/cf-login-logo.png",
        imageName: "",
        message: "",
        status: 1,
        category: "engagement",
        push: 1,
        in_app: 1,
        created_at: "",
        updated_at: "",
      };
    },
    showToast(message) {
      Vue.swal({
        html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> ' + message,
        confirmButtonColor: "#5dc2a6",
        confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
        background: "#5dc2a6",
        customClass: {
          container: "candidate-toast",
        },
        showClass: {
          popup: "animated fadeInLeft fast",
          icon: "animated heartBeat delay-1s",
        },
        hideClass: {
          popup: "animated fadeOutLeft slow",
        },
        toast: true,
        timer: 5000,
        position: "top-right",
        target: "#example-tabs",
      });
    },
  },
  mounted() {},
  watch: {
    // whenever question changes, this function will run
    templates: function () {
      this.enableSaveButton = true;
    },
  },
};
</script>

<style scoped>
.img-automation {
  display: flex;
}

.img-automation {
  margin-top: 0px;
  /* font-size: 25px; */
  visibility: hidden;
  width: 27px;
  height: 27px;
}

.table-head-styling {
  font-weight: 600;
  text-align: left;
  font-size: 11px;
  font-family: "InterUI";
  color: #9297ae;
  height: 56px !important;
  padding-left: 23px;
}
th.table-CREATED-styling {
  font-size: 14px;
  font-family: "InterUI";
  letter-spacing: normal;
  color: #363b54;
  font-weight: 600;
  text-align: left;
  background-color: #fff !important;
  vertical-align: baseline;
  padding-top: 15px;
  padding-left: 18px;
  vertical-align: middle;
  padding-left: 17px;
}

select#category {
  margin-top: 10px;
  margin-bottom: 4px;
}

.mobile-notification-paragraph-automation {
  height: 27px;
}

.img-upload-hide {
  height: 150px;
  width: 150px;
  text-align: end;
  margin-top: 8px;
}

.img-upload-hide-img {
  height: 150px;
  width: 150px;
  border-radius: 4px;
}

.fa-times-circle {
  cursor: pointer;
  font-size: 17px;
  position: relative;
  top: 7px;
  left: 10px;
  background-color: #fff;
  border-radius: 50%;
}
</style>