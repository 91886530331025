<template>
  <div class="eoi-fields">
    <div class="outer-box">
      <div class="input-col">
        <label>* {{ translations.firstName }}</label>
        <input class="first-letter"
               type="text"
               name="First_Name"
               aria-describedby="First_Name"
               aria-errormessage="First_Name"
               required
               v-model="first_name" />
        <span class="form-error"
              id="First_Name">First name is required</span>
      </div>
    </div>
    <div class="outer-box">
      <div class="input-col">
        <label>* {{ translations.lastName }}</label>
        <input class="first-letter"
               type="text"
               aria-describedby="Surname"
               aria-errormessage="Surname"
               name="Surname"
               required
               v-model="surname" />
        <span class="form-error"
              id="Surname"> Surname is required </span>
      </div>
    </div>

    <div class="outer-box">
      <div class="input-col email-width">
        <label>* {{ translations.emailAddress }}</label>
        <input class=""
               type="text"
               aria-describedby="Email"
               aria-errormessage="Email"
               name="Email"
               required
               v-model="email"
               @blur="validateEmail" />
        <span class="form-error"
              id="Email"> Email address is not valid. </span>
      </div>
    </div>

    <div class="outer-box">
      <div class="select-col">
        <label>* {{ translations.mobile }}</label>
        <vue-tel-input wrapperClasses="vuephonefield"
                       v-bind="bindProps"
                       placeholder=""
                       class="phone-field"
                       type="text"
                       v-on:country-changed="countryChanged"
                       @input="removeZero"
                       aria-describedby="Phone_Number"
                       aria-errormessage="Phone_Number"
                       name="Phone_Number"
                       id="Phone_Number"
                       required
                       v-model="phoneInput"></vue-tel-input>
        <input type="hidden"
               v-model="countryCode"
               name="Country_Code"
               id="Country_Code" />

        <span class="form-error"
              id="Phone_Number"> Phone Number is required </span>
      </div>
    </div>

    <div class="cell medium-5 small-12"></div>

    <div class="outer-box">
      <div class="input-col code-width">
        <label>* {{ translations.postalCode }}</label>
        <input class=""
               type="text"
               name="Postcode"
               :disabled="disabled == 1 ? true : false"
               v-model="postcode" />
      </div>
      <span class="or">{{ translations.or }}</span>
      <label class="points-check living-abroad">
        {{ translations.livingAbroad }}
        <input class=""
               name="Living_Overseas"
               value="1"
               type="checkbox"
               @change="disabled = (disabled + 1) % 2"
               v-model="living_overseas" />
        <span class="mark"></span></label>
    </div>

    <div v-if="isCareSector" class="outer-box">
      <hr />
    </div>

    <template v-if="isCareSector">
      <div class="outer-box tooltip-eoi-wrap">
        <strong class="question">
          {{ careQuestion.text }}
          <i class="tooltip-eoi"
             title="We encourage interest from those with and without paid care experience and we provide appropriate training for all. 
             Your answer will not affect the outcome of your application in any way"></i>
        </strong>
      </div>
      <radio v-if="careQuestion.field_type === 'YES / NO'"
             :question="careQuestion"
             name="newToCareResponse"
             v-model="newToCareResponse" />
    </template>

    <div v-if="!isCareSector && (checkIfEmpty(questionOne) || checkIfEmpty(questionTwo))" class="outer-box">
      <hr />
    </div>

    <template v-if="checkIfEmpty(questionOne)">
      <div class="outer-box tooltip-eoi-wrap">
        <strong class="question">
          {{ processQuestionText(questionOne.text) }}
        </strong>
      </div>
      <radio v-if="questionOne.field_type === 'YES / NO'"
             :question="questionOne"
             name="questionOneResponse"
             v-model="questionOneResponse" />
    </template>

    <template v-if="checkIfEmpty(questionTwo)">
      <div class="outer-box">
        <strong class="question">
          {{ processQuestionText(questionTwo.text) }}
        </strong>
      </div>
      <radio v-if="questionTwo.field_type === 'YES / NO'"
             :question="questionTwo"
             name="questionTwoResponse"
             v-model="questionTwoResponse" />
    </template>

    <div class="outer-box">
      <hr />
    </div>

    <span class="agree for-mobile">
      {{ translations.agreeTermsAndPrivacyPolicyText1 }}

      <a :href="terms_and_privacy_url"
         target="_blank">
        {{ translations.termsAndPrivacyPolicyLink }}
      </a>

      {{ translations.agreeTermsAndPrivacyPolicyText2 }}
    </span>
    <span class="agree for-desktop">
      {{ translations.agreeTermsAndPrivacyPolicyText1 }}

      <a :href="terms_and_privacy_url"
         target="_blank">
        {{ translations.termsAndPrivacyPolicyLink }}
      </a>

      {{ translations.agreeTermsAndPrivacyPolicyText2 }}
    </span>

    <div class="btn-submit-div"
         v-if="btnStatus && !readonly">
      <input type="submit"
             ref="submit"
             @click="validateFields"
             :value="translations.sendMyDetailsButtonText"
             class="btn expanded primary-submit-btn no-cap enabledBtn" />
    </div>
    <div class="btn-submit-div"
         v-if="!btnStatus || readonly">
      <input type="button"
             ref="submit"
             @click="validateFields"
             :value="translations.sendMyDetailsButtonText"
             class="btn expanded primary-submit-btn no-cap"
             disabled />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueTelInput from "vue-tel-input";
import Radio from "./EoiQuestions/Radio.vue";

Vue.use(VueTelInput);
export default {
  props: {
    microsite: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      required: true
    },
    advanceMicrositesFeatureFlag: {
      type: String,
      default: "0"
    },
    isCareSector: {
      type: Boolean,
      default: false
    },
    careQuestion: {
      type: Object,
      default: () => ({})
    },
    questionOne: {
      type: Object,
      default: () => ({})
    },
    questionTwo: {
      type: Object,
      default: () => ({})
    },
    translations: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      message: "Please fill in all above fields",
      btnStatus: false,
      first_name: "",
      surname: "",
      email: null,
      phone: "",
      phoneInput: "",
      countryCode: "",
      postcode: "",
      living_overseas: "",
      newToCareResponse: "",
      questionOneResponse: "",
      questionTwoResponse: "",
      disabled: 0,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      region_expression: this.microsite && this.microsite.region_expression ? this.microsite.region_expression : "",
      bindProps: {
        defaultCountry: this.microsite.def_country,
      },
      terms_and_privacy_url: this.advanceMicrositesFeatureFlag
        ? this.microsite.terms_and_privacy_url
        : this.microsite && this.microsite.base_url
          ? "https://" + this.microsite.base_url + "/candidateterms"
          : "",
    };
  },
  methods: {
    allFieldsFilled() {
      this.btnStatus =
        this.first_name &&
        this.surname &&
        this.reg.test(this.email) &&
        this.phone &&
        (this.living_overseas || this.postcode) &&
        (!this.isCareSector || (this.isCareSector && this.newToCareResponse !== '')) &&
        (!this.checkIfEmpty(this.questionOne) || (this.checkIfEmpty(this.questionOne) && this.questionOneResponse !== '')) &&
        (!this.checkIfEmpty(this.questionTwo) || (this.checkIfEmpty(this.questionTwo) && this.questionTwoResponse !== ''));

      if (this.first_name && this.surname && this.phone && (this.living_overseas || this.postcode)) {
        if (!this.reg.test(this.email)) {
          $("#Email").show();
        } else {
          $("#Email").hide();
        }
      }
    },
    validateEmail() {
      var email = this.email;

      axios
        .post("/dashboard/candidate/vaidate-email", { Email: email, customer_id: this.customerId })
        .then(function (response) {
          if (response.data == "duplicate") {
            Vue.swal({
              title: "Email identified as duplicate",
              confirmButtonColor: "#f55d73",
              confirmButtonText: "OK",
              html: "An application has already been submitted using your details. We will be in touch with you soon. If you are interested in multiple roles with us, please let us know when we contact you.",

              allowOutsideClick: false,
            });
            this.email = "";
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    postCodeCheck() {
      if (this.living_overseas) {
        this.postcode = "";
      }
    },
    validateFields() {
      this.submitfrm();
    },
    submitfrm() {
      this.$refs.submit.click();
      this.btnStatus = false;
    },
    removeZero() {
      if (isNaN(this.phoneInput)) {
        this.phone = this.phoneInput;
      } else {
        this.phone = this.phoneInput;
        this.phoneInput = this.phone;
      }
    },
    countryChanged(country) {
      this.countryCode = country.dialCode;
    },
    checkIfEmpty(obj) {
      return Object.keys(obj).length > 0;
    },
    processQuestionText(text) {
      return text.replace('[country]', this.getCountry());
    },
    getCountry() {
      const countryCode = this.microsite.country_code;
      const countries = {
        IE: 'Republic of Ireland',
        AU: 'Australia',
        NL: 'Netherlands'
      };

      return countries[countryCode] || 'UK';
    },
  },
  watch: {
    first_name: 'allFieldsFilled',
    surname: 'allFieldsFilled',
    email: 'allFieldsFilled',
    phone: 'allFieldsFilled',
    postcode: 'allFieldsFilled',
    living_overseas: function () {
      this.postCodeCheck();
      this.allFieldsFilled();
    },
    newToCareResponse: 'allFieldsFilled',
    questionOneResponse: 'allFieldsFilled',
    questionTwoResponse: 'allFieldsFilled',
  },
};
</script>
