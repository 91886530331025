<template>
    <!-- CANDIDATE -->
    <div class="grid-x t_margin_1 align-stretch normal_font">
      <div class="cell shrink">
          <div class="grey_border_box_inner box_shadow ">
                <div class="grid-y">
                    <div class="show" v-if="!editMode">
                        <div class="grid-x" v-if="saved">
                            <div class="cell" align="right" v-on:click="edit()">
                                <div class="calander b_margin_1">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <!-- Username -->
                        <div class="cell">
                            <strong>{{ candidate.First_Name }} {{ candidate.Surname }}</strong>
                        </div>
                        <!-- Email -->
                        <div class="cell small_text_b r_padding_2">
                            <a :href="'mailto:'+candidate.Email">{{ candidate.Email }}</a>
                        </div>
                        <!-- Telephone -->
                        <div class="cell small_text_b r_padding_2">
                            {{ candidate.Phone_Number }}
                        </div>
                        <!-- Referred by -->
                        <div class="cell small_text_b r_padding_2">
                            Referred by: {{ referredUser[0].name }}
                        </div>
                        <!-- Applied on  -->
                        <div class="cell small_text_b r_padding_2">
                            Applied on: {{ candidate.created_at | moment("DD/MM/YYYY") }}
                        </div>

                        <div v-if="candidate.cv_link">
                            <div class="cell small_text_b r_padding_2">
                                CV link: <br>
                                <a class="button pink_btn b_margin_0 no_wrap_btn alert-info" :href="candidate.cv_link">Go to link</a>
                            </div>
                        </div>

                        <div v-if="candidate.cv_upload">
                            <div class="cell small_text_b r_padding_2">
                                CV upload: <br>
                                <a class="button pink_btn b_margin_0 no_wrap_btn alert-info" :href="candidate.cv_upload">Download</a>
                            </div>
                        </div>
                    </div>
                    <div class="edit" v-if="editMode">
                        <div class="grid-x" v-if="saved">
                            <div class="cell" align="right" v-on:click="show()">
                                <div class="calander b_margin_1">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    <div class="grid-x">
                        <form data-abide novalidate style="width: 100%">
                            <div class="cell">
                                <label>First name*
                                  <input class="white_form" type="text" placeholder="eg. Jane" aria-describedby="example1Hint1" aria-errormessage="firstname-error" v-model="candidate.First_Name" required>
                                  <span class="form-error" id="firstname-error">
                                    The candidates first name is required
                                  </span>
                                </label>

                                <label>Surname*
                                  <input class="white_form" type="text" placeholder="eg. Smith" aria-describedby="example1Hint1" aria-errormessage="surname-error"  v-model="candidate.Surname" required>
                                  <span class="form-error" id="surname-error">
                                    The candidates surname is required
                                  </span>
                                </label>

                                <label>Email*
                                  <input class="white_form" type="text" placeholder="eg. jane@email.com" aria-describedby="example1Hint1" aria-errormessage="email-error" required pattern="email" v-model="candidate.Email">
                                  <span class="form-error" id="email-error">
                                    The candidates email address is required
                                  </span>
                                </label>

                                <label>Phone number*
                                  <input class="white_form" type="text" placeholder="eg. 07777 123456" aria-describedby="example1Hint1" aria-errormessage="phone-error" required  v-model="candidate.Phone_Number">
                                  <span class="form-error" id="phone-error">
                                    A contact number is required for the candidate
                                  </span>
                                </label>

                                <label>Referred by*
                                    <v-select v-model="candidate.user_id" :reduce="referrer => referrer.id" label="name" :options="availableUsers"></v-select>
                                  <span class="form-error" id="referred-error">
                                    Please enter the name of the person who referred the candidate
                                  </span>
                                </label>

                                <label>Applied on*
                                  <input type="text"  class="datepicker-here white_form" data-language="en" data-position="right top" aria-describedby="example1Hint1" aria-errormessage="applied-error" required>
                                  <span class="form-error" id="applied-error">
                                    Please enter the date the candidate applied
                                  </span>
                                </label>

                                <!-- BUTTONS -->
                                <div class="grid-x">
                                    <div class="cell auto">
                                        <button @click="close()" class="button expanded orange_btn b_margin_0 no_wrap_btn ">Cancel</button>
                                    </div>

                                    <div class="cell auto">
                                        <button @click="save()" class="button expanded pink_btn b_margin_0 no_wrap_btn">Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
          </div>
      </div>


      <candidate-status-component :job="job" :candidateId="candidateId" :statusComp="statusComp" stageName="Interview offered"></candidate-status-component>
      <candidate-status-component :job="job" :candidateId="candidateId" :statusComp="statusComp" showNewToCare stageName="Interview successful"></candidate-status-component>
      <candidate-status-component :job="job" :candidateId="candidateId" :statusComp="statusComp" stageName="Job offered"></candidate-status-component>
      <candidate-status-component :job="job" :candidateId="candidateId" :statusComp="statusComp" stageName="Started work"></candidate-status-component>
      <candidate-status-component :job="job" :candidateId="candidateId" :statusComp="statusComp" stageName="Employed for 12 months"></candidate-status-component>
    </div>

</template>

<script>
    export default {
        props: ['job', 'users'],
        data() {
            return {
                saved: false,
                editMode: true,
                options: this.users,
                availableUsers: [],
                candidateId: null,
                referredUser: {},
                candidate: {
                    Email: "",
                    First_Name: "",
                    Phone_Number: "",
                    Surname: "",
                    cv_link: "",
                    cv_upload: "",
                    job_id: this.job.id,
                    progress: [],
                    user_id: null,
                },
                statusComp: new Vue(),
            }
        },
        methods: {
            save(){
                console.log(this.candidate);
                var self = this;
                if(!this.saved){
                    axios.post('/dashboard/candidates', this.candidate)
                        .then(function (response) {
                            self.candidateId = response.data;
                            self.saved = true;
                            self.editMode = false;

                            self.referredUser = self.availableUsers.filter(obj => {
                               return obj.id === self.candidate.user_id
                            })
                        })
                        .catch(function (error) {
                            // handle error
                            console.log(error);
                        })
                }
            },
            close () {
              // destroy the vue listeners, etc
              this.$destroy();

              // remove the element from the DOM
              this.$el.parentNode.removeChild(this.$el);
            },
            edit(){
                if(this.editMode == false){
                    this.editMode = true;
                }
            },
            show(){
                if(this.editMode){
                    this.editMode = false;
                }
            },
            cancel(){
                this.editMode = false;
            },
        },
        mounted(){
            //Create array of available users for reffer dropdown
            for (var i = this.users.length - 1; i >= 0; i--) {
                var user = {
                    name: this.users[i].first_name+" "+this.users[i].surname,
                    id: this.users[i].id
                }
                this.availableUsers.push(user);
            }
        }
    }
</script>
