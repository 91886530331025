<script>
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { colors } from "../../config/branding";

const defaultOptions = {
  confirmButtonColor: colors.green,
  autoCloseTimer: 3000,
};

const subOptions = {
  success: {
    icon: "success",
  },
  error: { icon: "error" },
};

export default {
  components: [VueSweetalert2],

  props: {
    title: {
      required: true,
      type: String,
    },
    autoClose: {
      default: false,
      type: Boolean,
    },
    type: {
      default: "",
      type: String,
    },
  },

  data: function () {
    let options = { ...defaultOptions };

    if (Object.prototype.hasOwnProperty.call(subOptions, this.type)) {
      options = {
        ...options,
        ...subOptions[this.type],
      };
    }

    return {
      ...options,
      timer: this.autoClose ? options.autoCloseTimer : false,
    };
  },

  mounted: function () {
    this.showDialog();
  },

  methods: {
    showDialog() {
      this.$swal({
        titleText: this.title,
        confirmButtonColor: this.confirmButtonColor,
        icon: this.icon,
        timer: this.timer,
        timerProgressBar: this.timer > 0,
        customClass: {
          title: "swal2-title popupbox-title",
        },
      });
    },
  },

  render: function () {
    return "";
  },
};
</script>

<!-- Don't scope -->
<style lang="scss">
@import "./PopupBox.scss";
</style>
