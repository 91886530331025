<template>
    <div>
        <div class="template-table">
            <div class="template-row">
                <div class="template-column template-column--with-select">
                    <label class="template-label" for="sender">
                        SENDER <span>*</span>
                    </label>
                    <v-select
                        class="v-sender"
                        name="sender_id"
                        v-model="selectedSender"
                        :reduce="referrer => referrer.id"
                        label="name"
                        :options="filteredSenders"
                        :close-on-select="true"
                        @input="updateSender"
                        @search="onSearch"
                        :disabled="isViewMode"
                    >
                        <template #selected-option>
                            <div>{{ userName() }}</div>
                        </template>
                        <template #no-options="{ search }">
                            <span v-if="search.length < 3">Type at least 3 characters...</span>
                            <span v-else>Sorry, No Matching Options</span>
                        </template>
                    </v-select>
                    <div v-if="errors.sender" class="template-error">
                        <icon name="validation-error" />
                        <span>{{ errors.sender[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="template-row">
                <div class="template-column template-column--with-input">
                    <label class="template-label" for="subject">
                        SUBJECT <span>*</span>
                    </label>
                    <ckeditor
                        v-model="form.subject"
                        name="subject"
                        id="subject"
                        ref="subject"
                        :config="subjectEditorConfig"
                    />
                    <div v-if="errors.subject" class="template-error">
                        <icon name="validation-error" />
                        <span>{{ errors.subject[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="template-row">
                <div class="template-column template-column--with-input">
                    <label class="template-label" for="message">
                        MESSAGE <span>*</span>
                    </label>
                    <ckeditor
                        v-model="form.message"
                        name="message"
                        id="message"
                        ref="message"
                        :config="messageEditorConfig"
                    />
                    <div v-if="errors.message" class="template-error">
                        <icon name="validation-error" />
                        <span>{{ errors.message[0] }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from "../../../../components/Icon";
import CKEditor from "ckeditor4-vue/dist/legacy.js";
import axios from 'axios';
import Vue from 'vue';

export default {
    name: 'TemplateForm',
    props: {
        template: { type: Object, default: null },
        currentuser: { type: Object, required: true },
        errors: { type: Object, default: () => ({}) }
    },
    components: { Icon, CKEditor },
    data() {
        return {
            selectedSender: null,
            senders: [],
            search: '',
            form: {
                name: null,
                sender_id: null,
                subject: null,
                message: null
            }
        }
    },
    computed: {
        isViewMode() {
            return ((this.currentuser.account_check === 0 || this.currentuser.account_check === false) || (this.currentuser.customer.edit_templates_feature === 0 || this.currentuser.customer.edit_templates_feature === false) ); 
        },
        subjectEditorConfig() {
            return {
                autoParagraph: false,
                autoGrow_maxHeight: 65,
                height: 65,
                toolbar: [["EmojiPanel"]],
                removeButtons: "Italic",
                extraPlugins: ["emoji"],
                enterMode: 2,
                readOnly: this.isViewMode
            };
        },
        messageEditorConfig() {
            return {
                autoParagraph: false,
                autoGrow_maxHeight: 250,
                height: 250,
                toolbar: [
                    {
                        name: 'basicstyles',
                        items: [
                            'Bold',
                            'BulletedList',
                            'EmojiPanel',
                            'Paste',
                            'CarefriendsLink'
                        ]
                    },
                    {
                        name: 'custom',
                        items: [
                            'carefriendsfieldsCombo'
                        ]
                    }
                ],
                removeButtons: 'Italic',
                extraPlugins: 'emoji,carefriendsfields,carefriendsLink',
                allowedContent: 'a[href,target]; img[src,alt,width,height]; p h1 h2 h3 h4 h5 h6; ul ol li; strong em; blockquote;',
                enterMode: CKEDITOR.ENTER_P,
                readOnly: this.isViewMode,
                removePlugins: 'link'
            };
        },
        filteredSenders() {
            if (this.search.length < 3) {
                return this.senders;
            }
            return this.senders.filter((user) =>
                user.name.toLowerCase().includes(this.search.toLowerCase())
            );
        }
    },
    methods: {
        submit() {
            this.$emit('on-submit', this.form);
        },
        async getSenders() {
            try {
                const response = await axios.get("/senders");
                const senderResponse = response.data.portalUsers;
                if (!senderResponse || !Array.isArray(senderResponse)) {
                    throw new Error('Invalid sender data received');
                }
                this.senders = senderResponse.map(sender => ({
                    id: sender.id,
                    name: sender.name
                }));
            } catch (err) {
                Vue.swal({
                    title: "Oops!",
                    text: err,
                    confirmButtonColor: "#ec5168",
                    allowOutsideClick: false,
                });
            }
        },
        onSearch(query) {
            this.search = query;
        },
        userName() {
            const user = this.senders.find(
                availableUser => availableUser.id === this.form.sender_id
            );
            return user ? user.name : '';
        },
        updateSender() {
            this.form.sender_id = this.selectedSender;
        }
    },
    async created() {
        if (this.template) {
            this.form = {
                ...this.template
            };
            this.selectedSender = this.template.sender_id;
        }
        await this.getSenders();
    }
};
</script>

<style scoped lang="scss">
@import "./TemplateForm.scss";
</style>