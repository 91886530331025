/**
 * Change event listener which triggers readURL and
 * preview the uploaded image.
 */
$(document).ready(function () {
    $("#client-logo-input").change(function () {
        readURL(this);
    });
});

/**
 * Preview an image before it is uploaded
 * @param input
 */
function readURL(input) {
    if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = function (e) {
            $('#imgUpload').attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
    }
}
