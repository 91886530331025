<script>
export default {
    name: 'SitesIcon'
}
</script>

<template>
    <svg width="20"
         height="17"
         viewBox="0 0 20 17"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M4.85349 8.71978C4.9465 8.58234 5.10164 8.5 5.26759 8.5H15.224C15.3994 8.5 15.562 8.59194 15.6525 8.74228L16.0174 8.52279L15.6525 8.74228L18.66 13.7423C18.8604 14.0755 18.6204 14.5 18.2315 14.5H1.88413C1.48322 14.5 1.24535 14.0518 1.47003 13.7198L4.85349 8.71978Z"
              stroke="#6A6F8E" />
        <path d="M7.31983 8.61626C7.31983 8.61626 5.49973 14.0001 8.49973 12.0001C11.4997 10.0001 13.9997 14.0001 13.9997 14.0001"
              stroke="#6A6F8E"
              stroke-linecap="round" />
        <path d="M17.5 3.5C17.5 3.87828 17.3168 4.5396 16.9874 5.38367C16.667 6.20448 16.2368 7.1291 15.8024 8.00212C15.3687 8.87366 14.9344 9.68635 14.6084 10.2816C14.5707 10.3503 14.5346 10.4161 14.5 10.4787C14.4654 10.4161 14.4293 10.3503 14.3916 10.2816C14.0656 9.68635 13.6313 8.87366 13.1976 8.00212C12.7632 7.1291 12.333 6.20448 12.0126 5.38367C11.6832 4.5396 11.5 3.87828 11.5 3.5C11.5 1.84315 12.8431 0.5 14.5 0.5C16.1569 0.5 17.5 1.84315 17.5 3.5Z"
              fill="#E5E5E5"
              stroke="#6A6F8E" />
        <circle cx="14.5"
                cy="3.5"
                r="1.5"
                fill="#6A6F8E" />
    </svg>
</template>