<template>
  <div>
    <div class="integrations">
      <div class="step">
        <div class="step-badge" :class="[mergeIsIntegrated === true ? 'step-badge--green' : 'step-badge--grey']">
          <span v-if="mergeIsIntegrated !== true">1</span>
          <widget-check-icon v-else></widget-check-icon>
        </div>

        <div class="step-title">
          <span>{{
            mergeIsIntegrated === true ? "Connected to " + mergeAtsPlatform : "Connect and authorise your ATS"
          }}</span>
        </div>

        <a
          class="step-button"
          :class="[mergeIsIntegrated === true ? 'step-button--border' : 'step-button--fill']"
          href="javascript:void(0)"
          @click="$emit('on-manage-click')"
          >{{ mergeIsIntegrated === true ? "Manage" : "Connect" }}</a
        >
      </div>

      <div class="step" v-if="offices.length !== 1">
        <div class="step-badge" :class="[integratedOffices.length !== 0 ? 'step-badge--green' : 'step-badge--grey']">
          <span v-if="integratedOffices.length === 0">2</span>
          <widget-check-icon v-else></widget-check-icon>
        </div>
        <!-- multiselect__input -->
        <div class="step-title">
          <span>Select offices to integrate</span>
        </div>

        <div class="step-select">
          <form @submit.prevent="submitForm" ref="form">
            <multiselect
              v-model="integratedOffices"
              :options="offices"
              :multiple="true"
              :close-on-select="true"
              openDirection="below"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="true"
              @select="$emit('select', $event)"
              @remove="$emit('remove', $event)"
              :placeholder="'Select offices...'"
              label="name"
              track-by="id"
            >
              <template slot="caret" slot-scope="{ toggle }">
                <div class="multiselect__select" @mousedown.prevent.stop="toggle()">
                  <icon name="arrow-down" />
                </div>
              </template>

              <span class="checkbox-label" slot="option" slot-scope="scope">
                <input class="option" type="checkbox" v-model="scope.option.checked" @focus.prevent />
                <label>{{ scope.option.name }}</label>
              </span>

              <template slot="noResult"> No matching options found. </template>
              <template slot="selection" slot-scope="{ values }">
                <span class="multiselect__tag" v-for="option in values.slice(0, 3)" :key="option.id">
                  {{ option.name }}
                </span>
                <span class="multiselect__tag" v-if="integratedOffices.length > 3">
                  +{{ integratedOffices.length - 3 }} others
                </span>
              </template>
            </multiselect>
          </form>
        </div>
      </div>

      <div class="step" v-if="mergeIsIntegrated && (offices.length === 1 || integratedOffices.length !== 0)">
        <div class="step-badge" :class="[lastJobSyncTime === '' ? 'step-badge--grey' : 'step-badge--green']">
          <span v-if="lastJobSyncTime === ''">{{ offices.length === 1 ? "2" : "3" }}</span>
          <widget-check-icon v-else></widget-check-icon>
        </div>

        <div class="step-title">
          <span v-if="lastJobSyncTime === ''">Sync your existing jobs from your ATS into {{ brandName }}<br />N.B On initial synch, jobs will all be created with the status 'inactive'</span>
          <span v-else
            >Jobs synced on <b>{{ lastJobSyncTime }}</b></span
          >
        </div>

        <a
          class="step-button"
          :class="[lastJobSyncTime !== '' ? 'step-button--border' : 'step-button--fill']"
          href="javascript:void(0)"
          @click="$emit('on-sync-click')"
          >{{ lastJobSyncTime !== "" ? "Re-sync Jobs" : "Sync Jobs" }}</a
        >
      </div>

      <div class="step" v-if="mergeIsIntegrated && lastJobSyncTime    && (offices.length === 1 || integratedOffices.length !== 0)">
        <div class="step-column">
          <div class="step-row">
            <div class="step-badge" :class="[(jobStatusOnCreate == null || jobStatusOnUpdate == null || showUpdateJobStatus == true) ? 'step-badge--grey' : 'step-badge--green']">
              <span v-if="(jobStatusOnCreate == null || jobStatusOnUpdate == null || showUpdateJobStatus == true)">4</span>
              <widget-check-icon v-else></widget-check-icon>
            </div>

            <div class="step-title">
              <span></span>
              <span v-if="(jobStatusOnCreate == null || jobStatusOnUpdate == null)">
                Select how the status of new and updated jobs should be handled from now on:
              </span>
              <span v-else>
                The future status of jobs is determined according to the below settings:
              </span>
            </div>
          </div>

          <div class="step-row">
            <span class="step-badge"></span>

            <div class="step-title">
              <span v-if="showUpdateJobStatus == true">When a new jobs is created in the ATS, it’s status in {{ brandName }} should be</span>
              <span v-else>When a new jobs is created in the ATS, it’s status in {{ brandName }} will be <b>{{ jobSettingOptions["ON_CREATE"][jobStatusOnCreate] }}</b></span>
            </div>

            <div class="step-select" v-if="showUpdateJobStatus == true">
                <multiselect
                  v-model="jobStatusOnCreateView"
                  :options="jobSettingOnCreateOptions"
                  @select="$emit('job-status-select', $event, 'ON_CREATE')"
                  @remove="$emit('job-status-select', null, 'ON_CREATE')"
                  :close-on-select="true"
                  openDirection="below"
                  :showLabels="false"
                  :placeholder="'Select job status...'"
                  label="display"
                  track-by="id"
                />
            </div>
          </div>

          <div class="step-row">
            <span class="step-badge"></span>

            <div class="step-title">
              <span v-if="showUpdateJobStatus == true">When a job’s status is updated in the ATS, the status in {{ brandName }} should be</span>
              <span v-else>When a job’s status is updated in the ATS, the status in {{ brandName }} will <b>{{ jobSettingOptions["ON_UPDATE"][jobStatusOnUpdate] }}</b></span>
            </div>

            <div class="step-select" v-if="showUpdateJobStatus == true">
              <form @submit.prevent="submitForm" ref="form">
                <multiselect
                  v-model="jobStatusOnUpdateView"
                  :options="jobStatusOnUpdateOptions"
                  @select="$emit('job-status-select', $event, 'ON_UPDATE')"
                  @remove="$emit('job-status-select', null, 'ON_UPDATE')"
                  :close-on-select="true"
                  openDirection="below"
                  :showLabels="false"
                  :placeholder="'Select job status...'"
                  label="display"
                  track-by="id"
                />
              </form>
            </div>
          </div>
        </div>

        <a
          class="step-button"
          :class="[(showUpdateJobStatus == true) ? 'step-button--fill' : 'step-button--border']"
          href="javascript:void(0)"
          @click="$emit('on-store-job-status-click')"
        >
          {{ showUpdateJobStatus == true ? "Save" : "Update job settings" }}
        </a>
      </div>

      <div
        class="step"
        v-if="mergeIsIntegrated && lastJobSyncTime && (offices.length === 1 || integratedOffices.length !== 0)"
      >
        <div class="step-badge" :class="[mappingExists === false ? 'step-badge--grey' : 'step-badge--green']">
          <span v-if="mappingExists === false">{{ offices.length === 1 ? "3" : "4" }}</span>
          <widget-check-icon v-else></widget-check-icon>
        </div>

        <div class="step-title">
          <span></span>
          <span v-if="mappingExists === false"
            >Configure how your ATS recruitment stages map to your {{ brandName }} recruitment stages.
          </span>
          <span v-else
            >Your recruitment stages are mapped, as show below. Please note, if changes to mapped <br />
            stages are made in your ATS, you will need to update the mappings in {{ brandName }} also.
          </span>
        </div>
        <a
          v-if="mappingExists && showUpdateButton"
          class="step-button step-button--border"
          href="javascript:void(0)"
          @click="allowMappingUpdate(false)"
          >Update mappings</a
        >
      </div>
    </div>
    <recruitment-stages-mapping-data
      v-if="mergeIsIntegrated && lastJobSyncTime && (offices.length === 1 || integratedOffices.length !== 0)"
      :mappingExists="mappingExists"
      :showUpdateButton="showUpdateButton"
      @allow-mapping-update="allowMappingUpdate"
      @mapping-created="mappingCreated"
    />

    <transition name="fade">
      <div v-show="loading" class="loading-overlay">
        <span>Loading...</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { WidgetCheckIcon } from "../../components/WidgetCheckIcon";
import { Icon } from "../../../../components/Icon";
import RecruitmentStagesMappingData from "../RecruitmentStagesMapping/RecruitmentStagesMappingData.vue";
import { cssVariables } from "../../../../lib/css-variables";

export default {
  name: "IntegrationStepsView",
  props: {
    mergeAtsPlatform: { type: String, default: "" },
    mergeIsIntegrated: { type: Boolean, default: false },
    lastJobSyncTime: { type: String, default: "" },
    offices: { type: Array, default: () => [] },
    jobSettingOptions: { type: Object, default: null },
    jobStatusOnCreate: { type: String | null, default: null },
    jobStatusOnUpdate: { type: String | null, default: null },
    integratedOffices: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    stepCheck: { type: Boolean, default: false },
    mergeLinkToken: { type: Boolean, default: false },
    mappingExists: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    showUpdateJobStatus: { type: Boolean, default: false },
  },

  data() {
    return {
      showUpdateButton: true,
      jobStatusOnCreateView: null,
      jobStatusOnUpdateView: null,
    };
  },
  methods: {
    allowMappingUpdate(value) {
      this.showUpdateButton = value;
    },
    mappingCreated() {
      this.$emit("mapping-created");
    },
  },
  components: {
    WidgetCheckIcon,
    RecruitmentStagesMappingData,
    Icon,
  },
  computed: {
    jobSettingOnCreateOptions: function () {
      const options = [];

      Object.keys(this.jobSettingOptions.ON_CREATE)
        .forEach((key, index) => {
          const display = this.jobSettingOptions.ON_CREATE[key];
          const option = { id: index, display: display, value: key };

          if (key == this.jobStatusOnCreate) {
            this.jobStatusOnCreateView = option;
          };

          options.push(option);
        });

      return options;
    },
    jobStatusOnUpdateOptions: function () {
      const options = [];

      Object.keys(this.jobSettingOptions.ON_UPDATE)
        .forEach((key, index) => {
          const display = this.jobSettingOptions.ON_UPDATE[key];
          const option = { id: index, display: display, value: key };

          if (key == this.jobStatusOnUpdate) {
            this.jobStatusOnUpdateView = option;
          };

          options.push(option);
        });

      return options;
    },
  },
  async created() {
    this.cssVariables = cssVariables();
  }
};
</script>

<style scoped lang="scss">
@import "./IntegrationSteps.scss";
</style>
