<script>
export default {
  name: 'PaymentsIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5635 14.803C17.5635 15.1345 17.295 15.403 16.9635 15.403H15.229C14.8975 15.403 14.6285 15.1345 14.6285 14.803C14.6285 14.4715 14.8975 14.203 15.229 14.203H16.9635C17.295 14.203 17.5635 14.4715 17.5635 14.803ZM17.5995 16.675H6.4005C5.7385 16.675 5.2 16.1365 5.2 15.475V10.6715H18.8V15.475C18.8 16.1365 18.2615 16.675 17.5995 16.675ZM6.4005 7.2H17.5995C18.2615 7.2 18.8 7.7385 18.8 8.4V9.4715H5.2V8.4C5.2 7.7385 5.7385 7.2 6.4005 7.2ZM17.5995 6H6.4005C5.077 6 4 7.0765 4 8.4V15.475C4 16.7985 5.077 17.8755 6.4005 17.8755H17.5995C18.9235 17.8755 20 16.7985 20 15.475V8.4C20 7.0765 18.9235 6 17.5995 6Z" fill="currentColor"/>
  </svg>
</template>