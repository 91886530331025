<template>
  <!-- MAIN CONTAINER -->
  <div class="cell small-11 t_margin_2">
    <div v-show="showLoad" class="show-load-candidate">
      <img :src="myImage" />
    </div>
    <div class="tabs-sticky">
      <ul
        class="tabs sticky-element-tab candidate-width-head"
        data-tabs
        id="example-tabs"
        data-deep-link="true"
        data-update-history="true"
        data-deep-link-smudge="true"
        :class="{ change_color: scrollPosition > 50 }"
      >
        <li class="tabs-title is-active">
          <a
            href="#awards"
            aria-selected="true"
            v-on:click="isActive(1)"
            >Awards</a
          >

        </li>
        <li class="tabs-title">
          <a
            data-tabs-target="categories"
            href="#categories"
            v-on:click="isActive(2)"
            >Categories</a
          >
        </li>
        <li class="tabs-title">
          <a
            data-tabs-target="automations"
            href="#automations"
            v-on:click="isActive(3)"
            >Automations</a
          >
        </li>
        <li class="tabs-title" v-if="this.currentuser.customer.bonus_points_budgets_feature">
          <a
            data-tabs-target="point-budgets"
            href="#point-budgets"
            v-on:click="isActive(4)"
            >Point Budgets</a
          >
        </li>
      </ul>

      <template v-if="liveTab == 1">
        <template>
          <div
            class="candidate-margin-left candidate-padding-right"
            style="float: right"
          >
            <award-points-modal-component
              :sitesarr="this.sites"
              :currentuser="this.currentuser"
              :current-user-id="this.currentUserId"
              :has_permission_to_all_sites="has_permission_to_all_sites"
            ></award-points-modal-component>
          </div>
        </template>
      </template>
      <template v-if="archivedTab == 1">
        <template>
          <div
            class="candidate-margin-left candidate-padding-right"
            style="float: right"
          >
            <award-category
              :currentuser="this.currentuser"
              :sitesarr="this.sites"
            ></award-category>
          </div>
        </template>
      </template>
    </div>
    <div
      class="tabs-content box_shadow candidate-width-head"
      data-tabs-content="example-tabs"
    >
      <div
        v-if="liveTab == 1"
        class="tabs-panel no-padding is-active"
        id="awards"
      >
        <allocated-bonus-points-component
          :users="this.users"
          :mobile-users="this.mobileUsers"
          :current-user-id="this.currentUserId"
          :currentuser="this.currentuser"
          :sites="this.sites"
          :taxName="taxName"
          :dropdown-categories="this.dropdown_categories"
        ></allocated-bonus-points-component>
      </div>
      <div
        v-if="archivedTab == 1"
        class="tabs-panel no-padding is-active"
        id="categories"
      >
        <categories-list-component
          :currentuser="this.currentuser"
        ></categories-list-component>
      </div>
      <div
        v-if="startersTab == 1"
        class="tabs-panel no-padding is-active"
        id="automations"
      >
        <automation-app-user-points-component
          :currentuser="this.currentuser"
          :autopoints="this.autopoints"
          :userpoints="this.userpoints"
          :checksharepoints="this.checksharepoints"
          :appsharepoints="this.appsharepoints"
          :primaryuser="this.primaryuser"
          :should_show_promote="this.should_show_promote"
          @closePromote="closePromotePopUp"
        >
        </automation-app-user-points-component>
      </div>
      <div
        v-if="budgetsTab == 1"
        class="tabs-panel no-padding is-active"
        id="point-budgets"
      >
        <point-budgets-component
          v-if="this.currentuser.customer.bonus_points_budgets_feature"
          :currentuser="this.currentuser"
          :budget-types="this.budgetTypes"
        ></point-budgets-component>
      </div>
    </div>
  </div>
</template>
<script>
import Vue2Filters from "vue2-filters";
import { filter } from "minimatch";
import ClickOutside from "vue-click-outside";
Vue.use(Vue2Filters);
import Paginate from "vuejs-paginate";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import AllocatedBonusPointsComponent from "../candidates/AllocatedBonusPointsComponent";
import AutomationAppUserPointsComponent from "../account/AutomationAppUserPointsComponent";
import AwardPointsModalComponent from "./AwardPointsModalComponent";
import AwardCategory from "./AwardCategory";
import CategoriesListComponent from "./CategoriesListComponent";
import PointBudgetsComponent from "./point-budgets/PointBudgetsComponent.vue";

export default {
  mixins: [Vue2Filters.mixin],
  props: [
    "users",
    "currentuser",
    "mobileUsers",
    "currentUserId",
    "sites",
    "autopoints",
    "userpoints",
    "primaryuser",
    "has_permission_to_all_sites",
    "taxName",
    "checksharepoints",
    "appsharepoints",
    "budgetTypes",
    "assignedBudgets",
    "should_show_promote",
    "dropdown_categories",
  ],
  components: {
    CategoriesListComponent,
    PointBudgetsComponent,
    AwardCategory,
    AwardPointsModalComponent,
    paginate: Paginate,
    DateRangePicker: DateRangePicker,
    AutomationAppUserPointsComponent,
    AllocatedBonusPointsComponent
  },
  data() {
    return {
      childComponant: new Vue(),
      savedStatus: null,
      sortByOptions: [
        { id: 1, sort: "by update date" },
        { id: 2, sort: "alphabetically" },
        { id: 3, sort: "by EOI date" },
      ],
      searchOptions: [
        { id: 1, search: "Candidates" },
        { id: 2, search: "Jobs" },
        { id: 3, search: "Sites" },
      ],
      sortByLive: "",
      sortByArchived: "",
      sortByStarters: "",
      searchBy: 1,
      searchVarLive: "",
      searchVarArchived: "",
      searchVarStarters: "",
      liveArray: [],
      archivedArray: [],
      startersArray: [],
      liveTab: 0,
      archivedTab: 0,
      startersTab: 0,
      budgetsTab: 0,
      currentHref: "",
      tabId: 1,
      arr: [],
      addCandidate: true,
      isShow: false,
      showLoad: false, // setting it true by default.
      myImage: "/images/loading-circle.gif",
      newCandidates: [],
      scrollPosition: null,

      // Handling tabs pagination in Vuejs way
      livePaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      archivedPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      startersPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      budgetsPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },

      siteId: this.$root.getParamsFromUrl("siteId"),
      showFilterLive: false,
      showFilterArchived: false,
      showFilterStarter: false,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      stageOptions: [
        {
          id: "applied",
          name: "Expressed interest",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview offered",
          name: "Contact made",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview successful",
          name: "Interview complete",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview booked",
          name: "Interview booked",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Started work",
          name: "Started work",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Job offered",
          name: "Job offered",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Employed for 12 months",
          name:
            "Employed for " + this.currentuser.customer.milestone + " months",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
      ],

      statusOptions: [
        {
          id: "attempting_to_contact",
          name: "Attempted to contact - try again",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "unable_to_contact",
          name: "Attempted to contact - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "doesnt_meet_criteria",
          name: "Ineligible",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "suitable_to_progress",
          name: "Contacted - suitable to progress",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "not_suitable_to_progress",
          name: "Contacted - unsuitable to progress",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "schedule_interview",
          name: "Schedule interview",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "more_info_requested",
          name: "More info requested",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "in_review",
          name: "In review",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "candidate_not_responding",
          name: "Candidate not responding - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "No show - reschedule",
          name: "No show - reschedule",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview successful",
          name: "Interview successful",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Interview unsuccessful",
          name: "Interview unsuccessful",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "no_show_archive",
          name: "No show - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Job offered - accepted",
          name: "Job offered - accepted",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Job offered - not accepted",
          name: "Job offered - not accepted",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "awaiting_decision",
          name: "Job offered - awaiting decision",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Job not offered - archive",
          name: "Job not offered - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Started work",
          name: "Started work",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "Failed to start - archive",
          name: "Failed to start - archive",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "police_checks_received",
          name: "Police checks received",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "references_complete",
          name: "References complete",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "police_checks_references_received",
          name: "Police checks & references received",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "yes",
          name: "Yes",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
        {
          id: "no",
          name: "No",
          image: "/images/idle.png",
          image_archived: "/images/idle.png",
          image_starter: "/images/idle.png",
        },
      ],
      data: {
        stage: "",
        jobs: "",
        sites: "",
        status: "",
        sortby_live: "",
        sortby_Archived: "",
        sortby_Starters: "",
        stage_archived: "",
        jobs_archived: "",
        sites_archived: "",
        status_archived: "",
        stage_starter: "",
        jobs_starter: "",
        sites_starter: "",
        status_starter: "",
      },
      seleted_stage: [],
      selected_stage_tags: "",
      selected_date_tags: "",
      availableJobs: [],
      selected_jobs_tags: "",
      export_job_ids: [],
      export_site_ids: [],
      export_status_ids: [],
      availableSites: [],
      tags: [],
      selected_sites_tags: "",
      selected_status_tags: "",
      filterStartDate: "",
      filterEndDate: "",
      selected_sortby_tags: "",
      selected_sortby_archived_tags: "",
      selected_sortby_starters_tags: "",
      seleted_stage_archived: [],
      selected_stage_tags_archived: "",
      selected_date_tags_archived: "",
      selected_jobs_tags_archived: "",
      export_job_ids_archived: [],
      export_site_ids_archived: [],
      export_status_ids_archived: [],
      tags_archived: [],
      selected_sites_tags_archived: "",
      selected_status_tags_archived: "",
      filterStartDate_archived: "",
      filterEndDate_archived: "",

      seleted_stage_starter: [],
      selected_stage_tags_starter: "",
      selected_date_tags_starter: "",
      selected_jobs_tags_starter: "",
      export_job_ids_starter: [],
      export_site_ids_starter: [],
      export_status_ids_starter: [],
      tags_starter: [],
      selected_sites_tags_starter: "",
      selected_status_tags_starter: "",
      filterStartDate_starter: "",
      filterEndDate_starter: "",

      userCount: 0,
      ranges: {
        default() {
          let today = new Date();
          today.setHours(0, 0, 0, 0);

          let yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);

          let thisMonthStart = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          let thisMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );

          return {
            Today: [today, today],
            Yesterday: [yesterday, yesterday],
            "This week": [yesterday, yesterday],
            "Last week": [yesterday, yesterday],
            "This month": [thisMonthStart, thisMonthEnd],
            "This year": [
              new Date(today.getFullYear(), 0, 1),
              new Date(today.getFullYear(), 11, 31),
            ],
            "Last month": [
              new Date(today.getFullYear(), today.getMonth() - 1, 1),
              new Date(today.getFullYear(), today.getMonth(), 0),
            ],
          };
        },
      },
      message: "",
      query: false,
    };
  },
  methods: {
    isActive(tab) {
      // this.showLoad = true;

      if (tab === 1) {
        this.liveTab = 1;
        this.archivedTab = 0;
        this.startersTab = 0;
        this.budgetsTab = 0;
        this.tabId = 1;
        $("#awards").last().addClass("is-active");
      } else if (tab === 2) {
        this.liveTab = 0;
        this.archivedTab = 1;
        this.startersTab = 0;
        this.budgetsTab = 0;
        this.tabId = 2;
        $("#categories").last().addClass("is-active");
      } else if (tab === 3) {
        this.liveTab = 0;
        this.archivedTab = 0;
        this.startersTab = 1;
        this.budgetsTab = 0;
        this.tabId = 3;
        $("#automations").addClass("is-active");
      } else if (tab === 4) {
        this.liveTab = 0;
        this.archivedTab = 0;
        this.startersTab = 0;
        this.budgetsTab = 1;
        this.tabId = 4;
        $("#point-budgets").last().addClass("is-active");
      }
      // this.showLoad = false;
    },
    closePromotePopUp() {
      this.should_show_promote = false;
    }
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.currentHref = window.location.href;
    if (this.currentHref.indexOf("live") != -1) {
      this.tabId = 1;
    } else if (this.currentHref.indexOf("archived") != -1) {
      this.tabId = 2;
    } else if (this.currentHref.indexOf("starters") != -1) {
      this.tabId = 3;
    } else if (this.currentHref.indexOf("budgets") != -1) {
      this.tabId = 4;
    } else {
      this.tabId = 1;
    }

    if (this.should_show_promote == 1) {
      this.tabId = 3;
    };

    this.isActive(this.tabId);
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style>
.vs__search,
.vs__search:focus {
  color: #cbcddb !important;
}
#status-filter-starter #vs3__listbox {
  width: 300px;
}
#status-filter-archived #vs3__listbox {
  width: 300px;
}
#status-filter #vs3__listbox {
  width: 300px;
}
.middle-serarch {
  width: 30%;
}
.candidate-total-block {
  width: 40%;
  padding-left: 15px;
}
.live-filter-block {
  width: 30%;
}
.candidates-search-top {
  height: 40px;
  width: 95%;
  padding: 0 11px 0 43px;
  border: 1px solid #dcdee6;
  border-radius: 3px;
  color: #9297ae;
  font: 400 14px "InterUI", sans-serif;
  background: #fff url(/images/search.png) no-repeat 7px center;
}
.filters-wrappers .cell input[type="search"] {
  color: #85899e !important;
}
.datelabelclass-candidate {
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #85899e;
}
@media only screen and (min-width: 1400px) {
  .middle-serarch {
    width: 22%;
  }
  .candidate-total-block {
    width: 56%;
  }
  .live-filter-block {
    width: 20%;
  }
}
input[type="radio"]:checked:before {
  background: #5dc2a6 !important;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #5dc2a6 !important;
  border-radius: 510%;
  outline: none;
  vertical-align: middle;
}
</style>
