<template>
    <div class="view-points-user">
        <div class="grid-x grid-padding-x align-middle">
            <div class="cell auto name-id-points">
                <h2 align="left">
                    <strong>{{ usero.first_name }} {{ usero.surname }}</strong>
                </h2>
                <span class="id-profileCard"> ID: {{ usero.id }} </span>
            </div>
        </div>
        <br />
        <br>

        <div class="grid-x grid-padding-x align-middle">
            <div class="cell auto">
                <label>Phone </label>
                <p class="user-clickable" v-if="usero.first_name === 'Anonymised' || usero.status=='Leaver' || usero.mark_as_leaver ==1"></p>
                <p v-else>{{ usero.phone_number }}</p>
            </div>

            <div class="cell auto">
                <label>Email</label>
                <p class="user-clickable" v-if="usero.first_name == 'Anonymised' || usero.status=='Leaver' || usero.mark_as_leaver ==1"></p>
                <p class="user-clickable" v-else>{{ usero.email }}</p>
            </div>
        </div>

        <div class="grid-x grid-padding-x align-middle">
            <div class="cell auto">
                <label>Site </label>
                <p>{{ site_name }}</p>
            </div>
            <div class="cell auto">
                <label>{{ payment_title }}</label>
                <p>{{ usero.PaymentInfo }}</p>
            </div>
        </div>

        <div class="grid-x grid-padding-x align-middle">
            <div class="cell auto">
                <label> Status </label>
                <p>{{ user_status }}</p>
            </div>

            <div class="cell auto">
                <label> Date registered </label>
                <p>
                    <template v-if="dateReg[0]">{{
                        dateReg[0] | moment("DD-MM-YYYY")
                        }}</template>
                </p>
            </div>
        </div>
        <div class="grid-x grid-padding-x align-middle">
            <div class="cell auto">
                <h3>Referrals breakdown</h3>
            </div>
            <div class="cell auto"></div>
        </div>
        <div class="grid-x grid-padding-x align-middle">
            <div class="cell auto viewpoint">
                <h3 align="center">{{ leaderboardValues.totalShares }}</h3>
                <p align="center">Shares</p>
            </div>
            <div class="cell auto viewpoint">
                <h3 align="center">{{ leaderboardValues.totalApplied }}</h3>
                <p align="center">Candidates</p>
            </div>
            <div class="cell auto viewpoint">
                <h3 align="center">{{ pointValues.Verified }}</h3>
                <p align="center">Verified</p>
            </div>
            <div class="cell auto viewpoint">
                <h3 align="center">{{ pointValues.CountHires }}</h3>
                <p align="center">Started</p>
            </div>
            <div class="cell auto viewpoint">
                <h3 align="center">{{ pointValues.CountEmployed12Months }}</h3>
                <p align="center">12 months</p>
            </div>
            <div class="cell auto viewpoint">
                <h3 align="center">{{ pointValues.CountNewToCare }}</h3>
                <p align="center">N2C</p>
            </div>
        </div>
        <div class="grid-x grid-padding-x align-middle activity-log">
            <div class="cell auto medium-9">
                <h3>Activity log</h3>
            </div>
            <div class="cell auto activity-dropdown">
                <v-select
                    placeholder="All Activity"
                    @input="viewPoints(user)"
                    :options="activityTypes"
                    :reduce="(referrer) => referrer.id"
                    label="name"
                    v-model="activityType"
                ></v-select>
            </div>
        </div>
        <div class="grid-x grid-padding-x align-middle">
            <table id="activity-table" class="cf-table cell auto">
                <thead>
                <tr>
                    <th width="130px">Date</th>
                    <th>Type</th>
                    <th width="230px">Reason</th>
                    <th class="align-right">Points</th>
                    <th class="align-right">Balance</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="viewPointsData" v-for="(value, index) in viewPointsData">
                    <!-- Candidates history start here -->
                    <td v-if="value.candidate_history == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.candidate_history == 1">
                        Referral
                    </td>
                    <td v-if="value.candidate_history == 1">
                        {{ value.history }}
                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 1"
                    >
                        +{{ value.points }}

                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 1"
                    >
                        {{ balance[index] }}
                    </td>

                    <!--delete case-->
                    <td v-if="value.candidate_history == 2">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.candidate_history == 2">
                        Referral
                    </td>
                    <td v-if="value.candidate_history == 2">
                        {{ value.history }}
                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 2"
                    >
                        -{{ value.points }}

                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 2"
                    >
                        {{ balance[index] }}
                    </td>

                    <!--Undone case-->
                    <td v-if="value.candidate_history == 3">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.candidate_history == 3">
                        Referral
                    </td>
                    <td v-if="value.candidate_history == 3">
                        {{ value.history }}
                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 3"
                    >
                        -{{ value.points }}

                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 3"
                    >
                        {{ balance[index] }}
                    </td>

                    <!--Reassigned case-->
                    <td v-if="value.candidate_history == 4">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.candidate_history == 4">
                        Referral
                    </td>
                    <td v-if="value.candidate_history == 4">
                        {{ value.history }}
                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 4"
                    >
                        -{{ value.points }}

                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 4"
                    >
                        {{ balance[index] }}
                    </td>

                    <!--Assigned case-->
                    <td v-if="value.candidate_history == 5">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.candidate_history == 5">
                        Referral
                    </td>
                    <td v-if="value.candidate_history == 5">
                        {{ value.history }}
                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 5"
                    >
                        <div v-if="usero.points_eligible == 1">
                            +{{ value.points }}
                        </div>
                        <div v-else>
                            n/a
                        </div>


                    </td>
                    <td
                        class="align-right"
                        v-if="value.candidate_history == 5"
                    >
                        {{ balance[index] }}
                    </td>


                    <!--  Candidates history end here -->
                    <td v-if="value.new_to_care != 1 && value.candidatesProgress == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.new_to_care != 1 && value.candidatesProgress == 1">
                        Referral
                    </td>
                    <td v-if="value.new_to_care != 1 && value.candidatesProgress == 1">
                        {{ value.stage | capitalize }} :
                        {{ value.candidate_name ? value.candidate_name : "" }}
                    </td>
                    <td
                        class="align-right"
                        v-if="value.new_to_care != 1 && value.candidatesProgress == 1"
                    >
                        <div v-if="value.points_awarded == 0"> n/a</div>
                        <div v-else>
                            <div v-if="usero.points_eligible == 1">
                                +{{ value.points }}
                            </div>
                            <div v-else>
                                n/a
                            </div>
                        </div>
                    </td>
                    <td
                        class="align-right"
                        v-if="value.new_to_care != 1 && value.candidatesProgress == 1"
                    >
                        {{ balance[index] }}
                    </td>
                    <td v-if="value.new_to_care == 1 && value.stage != 'Started work'">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.new_to_care == 1 && value.stage != 'Started work'">
                        Referral
                    </td>
                    <td v-if="value.new_to_care == 1 && value.stage != 'Started work'">
                        {{ value.stage | capitalize }} :
                        {{ value.candidate_name ? value.candidate_name : "" }}
                    </td>
                    <td
                        class="align-right"
                        v-if="value.new_to_care == 1 && value.stage != 'Started work'"
                    >
                        <div v-if="value.points_awarded == 0"> n/a</div>
                        <div v-else>
                            <div v-if="usero.points_eligible == 1">
                                +{{ value.points }}
                            </div>
                            <div v-else>
                                n/a
                            </div>
                        </div>
                    </td>
                    <td
                        class="align-right"
                        v-if="value.new_to_care == 1 && value.stage != 'Started work'"
                    >
                        {{ balance[index] }}
                    </td>
                    <td v-if="value.new_to_care == 1 && value.stage == 'Started work'">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.new_to_care == 1 && value.stage == 'Started work'">
                        Referral
                    </td>
                    <td v-if="value.new_to_care == 1 && value.stage == 'Started work'">
                        Started work & New to care :
                        {{ value.candidate_name ? value.candidate_name : "" }}
                    </td>
                    <td
                        class="align-right"
                        v-if="value.new_to_care == 1 && value.stage == 'Started work'"
                    >
                        <div v-if="!value.points_awarded"> n/a</div>
                        <div v-else>
                            <div v-if="usero.points_eligible == 1">
                                +{{ value.points }}
                            </div>
                            <div v-else>
                                n/a
                            </div>
                        </div>
                    </td>
                    <td
                        class="align-right"
                        v-if="value.new_to_care == 1 && value.stage == 'Started work'"
                    >
                        {{ balance[index] }}
                    </td>
                    <td v-if="value.share == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.share == 1">Referral</td>
                    <td v-if="value.share == 1">Share</td>
                    <td class="align-right" v-if="value.share == 1">
                        +{{ value.points }}
                    </td>
                    <td class="align-right" v-if="value.share == 1">
                        {{ balance[index] }}
                    </td>
                    <td v-if="value.paidPaymentRequest == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.paidPaymentRequest == 1">Paid</td>
                    <td v-if="value.paidPaymentRequest == 1">
                        Request
                        {{ value.updated_at }} for
                        {{ value.amount }} marked as paid by {{ value.name }}
                    </td>
                    <td class="align-right" v-if="value.paidPaymentRequest == 1">
                        -{{ value.points }}
                    </td>
                    <td class="align-right" v-if="value.paidPaymentRequest == 1">
                        {{ balance[index] }}
                    </td>
                    <td v-if="value.paymentRequest == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.paymentRequest == 1">Request</td>
                    <td v-if="value.paymentRequest == 1">
                        Request cash in {{ value.amount }}
                    </td>
                    <td class="align-right"  v-if="value.paymentRequest == 1">n/a</td>
                    <td class="align-right" v-if="value.paymentRequest == 1">
                        {{ balance[index] }}
                    </td>
                    <!-- added for reject request-->
                    <td v-if="value.rejectedPaymentRequest == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.rejectedPaymentRequest == 1">Rejected</td>
                    <td v-if="value.rejectedPaymentRequest == 1">
                        Rejected Request cash in {{ value.amount }}
                    </td>
                    <td class="align-right"  v-if="value.rejectedPaymentRequest == 1">n/a</td>
                    <td class="align-right" v-if="value.rejectedPaymentRequest == 1">
                        {{ balance[index] }}
                    </td>
                    <!-- End rejected payment request -->
                    <td v-if="value.approvedByPaymentRequest == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.approvedByPaymentRequest == 1">Approved</td>
                    <td v-if="value.approvedByPaymentRequest == 1">
                        Request {{ value.created_at ? value.created_at : "Not set" }} for
                        {{ value.amount }} approved by {{ value.name }}
                    </td>
                    <td class="align-right" v-if="value.approvedByPaymentRequest == 1">n/a</td>
                    <td class="align-right" v-if="value.approvedByPaymentRequest == 1">
                        {{ balance[index] }}
                    </td>
                    <td v-if="value.bonus == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.bonus == 1">Bonus</td>
                    <td v-if="value.bonus == 1">
                        {{ value.reward_catagory_name }} : {{ getPostBody(value.event_data.reason_for_allocation) }} : {{ value.allocated_by }}
                    </td>
                    <td class="align-right" v-if="value.bonus == 1">
                        +{{ value.points }}
                    </td>
                    <td class="align-right" v-if="value.bonus == 1">
                        {{ balance[index] }}
                    </td>
                    <td v-if="value.removal == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.removal == 1">{{ value.type }}</td>
                    <td v-if="value.removal == 1">{{ value.reason }}</td>
                    <td class="align-right" v-if="value.removal == 1">
                        {{ value.type == "Removal" ? "-" : "+" }}{{ value.points }}
                    </td>
                    <td class="align-right" v-if="value.removal == 1">
                        {{ balance[index] }}
                    </td>
                    <td v-if="value.new == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.new == 1">Welcome points</td>
                    <td v-if="value.new == 1">New app user registered</td>
                    <td class="align-right" v-if="value.new == 1">
                        +{{ value.points }}
                    </td>
                    <td class="align-right" v-if="value.new == 1">
                        {{ balance[index] }}
                    </td>
                    <!-- leaver user -->
                    <td v-if="value.leaver == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.leaver == 1">Leaver </td>
                    <td v-if="value.leaver == 1">Marked as leaver </td>
                    <td class="align-right" v-if="value.leaver == 1">
                        n/a
                    </td>
                    <td class="align-right" v-if="value.leaver == 1">
                        {{ balance[index] }}
                    </td>
                    <!-- Deactivated user -->
                    <td v-if="value.deactive == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.deactive == 1">Deactivated </td>
                    <td v-if="value.deactive == 1">User deactivated </td>
                    <td class="align-right" v-if="value.deactive == 1">
                        n/a
                    </td>
                    <td class="align-right" v-if="value.deactive == 1">
                        {{ balance[index] }}
                    </td>
                    <!-- Reactivated user -->
                    <td v-if="value.reactive == 1">
                        {{ value.updated_at }}
                    </td>
                    <td v-if="value.reactive == 1">Reactivated </td>
                    <td v-if="value.reactive == 1">User reactivated </td>
                    <td class="align-right" v-if="value.reactive == 1">
                        n/a
                    </td>
                    <td class="align-right" v-if="value.reactive == 1">
                        {{ balance[index] }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import Paginate from "vuejs-paginate";
    export default {
        components: { paginate: Paginate },
        props: ["user"],
        data() {
            return {
                viewPointsData: [],
                type_id: "",
                activityType: 0,
                activityTypes: [
                    { id: 0, name: "All Activity" },
                    { id: 1, name: "Referral Activity" },
                    { id: 2, name: "Bonus Activity" },
                    { id: 3, name: "Payment Activity" },
                ],
                filteredPoints: [],
                site_name: "",
                dateReg: [],
                balance: [],
                leaderboardValues: [],
                pointValues: [],
                usero:this.user,
                payment_title: 'NI number',
                user_status: ''
            };
        },
        methods: {
            filterViewPoints() {
                this.filteredPoints = [];
                for (var i = 0; i < this.viewPointsData.length; i++) {
                    if (this.activityType == 2) {
                        if (this.viewPointsData[i].hasOwnProperty("event_data")) {
                            this.filteredPoints.push(this.viewPointsData[i]);
                        }
                    } else if (this.activityType == 1) {
                        if (
                            this.viewPointsData[i].type == "Referral" ||
                            this.viewPointsData[i].hasOwnProperty("reffered_by") ||
                            this.viewPointsData[i].hasOwnProperty("share") ||
                            this.viewPointsData[i].hasOwnProperty("candidate_history")
                        ) {
                            this.filteredPoints.push(this.viewPointsData[i]);
                        }
                    } else if (this.activityType == 3) {
                        if (this.viewPointsData[i].hasOwnProperty("paidPaymentRequest")) {
                            if (this.viewPointsData[i].paidPaymentRequest == 1) {
                                this.filteredPoints.push(this.viewPointsData[i]);
                            }
                        }
                        if (this.viewPointsData[i].hasOwnProperty("approvedByPaymentRequest")) {
                            if (this.viewPointsData[i].approvedByPaymentRequest == 1) {
                                this.filteredPoints.push(this.viewPointsData[i]);
                            }
                        }
                        if (this.viewPointsData[i].hasOwnProperty("rejectedPaymentRequest")) {
                            if (this.viewPointsData[i].rejectedPaymentRequest == 1) {
                                this.filteredPoints.push(this.viewPointsData[i]);
                            }
                        }
                        if (this.viewPointsData[i].hasOwnProperty("paymentRequest")) {
                            if (this.viewPointsData[i].paymentRequest == 1) {
                                this.filteredPoints.push(this.viewPointsData[i]);
                            }
                        }
                    } else if (this.activityType == 4) {
                        if (
                            this.viewPointsData[i].hasOwnProperty("approvedByPaymentRequest")
                        ) {
                            if (this.viewPointsData[i].approvedByPaymentRequest == 1) {
                                this.filteredPoints.push(this.viewPointsData[i]);
                            }
                        }
                    } else if (this.activityType == 5) {
                        if (this.viewPointsData[i].hasOwnProperty("removal")) {
                            if (this.viewPointsData[i].removal == 1) {
                                if (this.viewPointsData[i].type == "Removal") {
                                    this.filteredPoints.push(this.viewPointsData[i]);
                                }
                            }
                        }
                    } else if (this.activityType == 6) {
                        if (this.viewPointsData[i].hasOwnProperty("paymentRequest")) {
                            this.filteredPoints.push(this.viewPointsData[i]);
                        }
                    } else {
                        this.filteredPoints = [];
                    }
                }
                this.viewPointsData = this.filteredPoints;
            },
            viewPoints(user) {
                var self = this;
                axios
                    .get("/old-view-points/" + user.id)
                    .then(function (response) {
                        if (response.data.activityLog) {
                            console.log(response.data.activityLog);
                            self.viewPointsData = response.data.activityLog;
                            var i;
                            for (i = self.viewPointsData.length - 1; i >= 0; i--) {
                                if (i == self.viewPointsData.length - 1) {
                                    self.balance[i] = self.viewPointsData[i].points;
                                } else {
                                    if (self.balance[i + 1] == null) {
                                        self.balance[i + 1] = 0;
                                    }
                                    if (self.viewPointsData[i].points == null) {
                                        self.viewPointsData[i].points = 0;
                                    }
                                    if (self.viewPointsData[i].paidPaymentRequest == 1) {
                                        self.balance[i] =
                                            self.balance[i + 1] - self.viewPointsData[i].points;
                                    } else if (self.viewPointsData[i].type == "Removal") {
                                        self.balance[i] =
                                            self.balance[i + 1] - self.viewPointsData[i].points;
                                    } else if (self.viewPointsData[i].candidate_history == 1) {
                                        self.balance[i] =
                                            parseFloat(self.balance[i + 1]) + parseFloat(self.viewPointsData[i].points);
                                    } else if (self.viewPointsData[i].candidate_history == 2) {
                                        self.balance[i] =
                                            parseFloat(self.balance[i + 1]) - parseFloat(self.viewPointsData[i].points);
                                    } else if (self.viewPointsData[i].candidate_history == 3) {
                                        self.balance[i] =
                                            parseFloat(self.balance[i + 1]) - parseFloat(self.viewPointsData[i].points);
                                    } else if (self.viewPointsData[i].candidate_history == 4) {
                                        self.balance[i] =
                                            parseFloat(self.balance[i + 1]) - parseFloat(self.viewPointsData[i].points);
                                    }else if (self.viewPointsData[i].candidate_history == 5) {
                                        self.balance[i] =
                                            parseFloat(self.balance[i + 1]);
                                    }else if (self.viewPointsData[i].reactive == 1) {
                                        self.balance[i] =
                                            parseFloat(self.balance[i + 1]);
                                    }else if (self.viewPointsData[i].deactive == 1) {
                                        self.balance[i] =
                                            parseFloat(self.balance[i + 1]);
                                    }else if (self.viewPointsData[i].leaver == 1) {
                                        self.balance[i] =
                                            parseFloat(self.balance[i + 1]);
                                    } else {
                                        self.balance[i] =
                                            self.balance[i + 1] + self.viewPointsData[i].points;
                                    }
                                }
                            }
                            if (self.activityType > 0) {
                                self.filterViewPoints();
                            }
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
                self.editMode = true;
                if (user.created_at) {
                    self.dateReg = user.created_at.split(" ");
                }
            },

            getUserInfo(id) {
                var self = this;
                var microsite_id = null;
                axios
                    .post("/dashboard/get-user-info", { id: id })
                    .then(function (response) {
                        if (response.data.info) {
                            self.leaderboardValues = response.data.info.leaderboardValues;
                            self.pointValues = response.data.info.pointValues;
                            self.site_name = response.data.info.site_name;
                            self.user_status = response.data.info.status;
                            if(self.site_name == null){
                                self.site_name = '-';
                            }
                            microsite_id = response.data.info.microsite_id;
                            if (microsite_id) {
                                if (microsite_id == 1) {
                                    self.payment_title = 'NI number';
                                } else if (microsite_id == 2) {
                                    self.payment_title = 'TF number';
                                } else if (microsite_id == 3) {
                                    self.payment_title = 'PPS number';
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getPostBody (post) {
                let body = this.stripTags(post);
                return body.length > 15 ? body.substring(0, 15) + '...' : body;
            },
            stripTags (markup) {
                const temp = document.createElement("div");
                temp.innerHTML = markup;
                return temp.textContent || temp.innerText || "";
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return "";
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        },
        mounted() {
            this.getUserInfo(this.user.id);
            this.activityType = 0;
            this.viewPoints(this.user);
            this.usero = this.user;
            if(this.usero.email == null){
                this.usero.email = '-';
            }
            if(this.usero.phone_number == null){
                this.usero.phone_number= '-';
            }
            if(this.usero.PaymentInfo == null){
                this.usero.PaymentInfo = '-';
            }
        },
        computed: {},
    };
</script>
