<template>
  <recruitment-stages-mapping-view
    :mapping-exists="mappingExists"
    :mapped-stages="mappedStages"
    :show-update-button="showUpdateButton"
    :selected-stages="selectedStages"
    @select="onSelect"
    @remove="onRemove"
    @allow-mapping-update="allowMappingUpdate"
    @mapping-created="mappingCreated"
    @update-mappings="updateMappings"
    @reset="onReset"
  />
</template>

<script>
import axios from "axios";
import RecruitmentStagesMappingView from "./RecruitmentStagesMappingView.vue";

export default {
  name: "RecruitmentStagesMappingData",
  props: {
    mappingExists: { type: Boolean, default: false },
    showUpdateButton: { type: Boolean, default: false },
  },
  components: {
    RecruitmentStagesMappingView,
  },
  data() {
    return {
      mappedStages: null,
      initialMappedStages: null,
      selectedStages: [],
      loading: false,
    };
  },
  methods: {
    /**
     * get the mapped candidate stages
     */
    async getMappedStages() {
      const { data } = await axios.get("/merge.dev/get-mapped-stages");
      this.mappedStages = data;
      this.initialMappedStages = JSON.parse(JSON.stringify(this.mappedStages));

      Object.keys(this.mappedStages).forEach(mappableStage => {
        this.selectedStages[mappableStage] = "";
        if (this.mappedStages[mappableStage].mapped_ats_stages.length > 0) {
          this.selectedStages[mappableStage] = Array.prototype.map
            .call(this.mappedStages[mappableStage].mapped_ats_stages, s => s.name)
            .toString();
        }
      });
    },

    onReset() {
      this.mappedStages = JSON.parse(JSON.stringify(this.initialMappedStages));
    },

    onSelect(option, cfStage) {
      let index = this.mappedStages[cfStage].options.indexOf(option);
      this.mappedStages[cfStage].options[index].checked = true;

      Object.entries(this.mappedStages).forEach(([key, value]) => {
        if (key == cfStage) {
          return;
        }
        this.mappedStages[key].options[index].$isDisabled = true;
      });
    },

    onRemove(option, cfStage) {
      let index = this.mappedStages[cfStage].options.indexOf(option);
      this.mappedStages[cfStage].options[index].checked = false;

      Object.keys(this.mappedStages).forEach(key => {
        if (key == cfStage) {
          return;
        }
        this.mappedStages[key].options[index].$isDisabled = false;
      });
    },

    allowMappingUpdate(value) {
      this.$emit("allow-mapping-update", value);
    },

    mappingCreated() {
      this.$emit("mapping-created");
    },

    updateMappings(mappedStages) {
      this.mappedStages = mappedStages;

      Object.keys(this.mappedStages).forEach(mappableStage => {
        this.selectedStages[mappableStage] = "";
        if (this.mappedStages[mappableStage].mapped_ats_stages.length > 0) {
          this.selectedStages[mappableStage] = Array.prototype.map
            .call(this.mappedStages[mappableStage].mapped_ats_stages, s => s.name)
            .toString();
        }
      });
    },
  },
  async created() {
    this.getMappedStages();
  },
};
</script>
