<script>
export default {
  name: 'ContactUsIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.722 11.7285C14.568 11.8805 14.483 12.0825 14.483 12.2985C14.483 12.5155 14.569 12.7185 14.722 12.8685C14.879 13.0235 15.084 13.1005 15.289 13.1005C15.494 13.1005 15.699 13.0235 15.855 12.8705C16.01 12.7185 16.095 12.5155 16.095 12.2985C16.095 12.0825 16.01 11.8785 15.856 11.7285C15.542 11.4195 15.035 11.4185 14.722 11.7285ZM8.146 11.7275L8.144 11.7285C7.991 11.8805 7.906 12.0825 7.906 12.2985C7.906 12.5155 7.991 12.7185 8.144 12.8685C8.301 13.0225 8.506 13.1005 8.711 13.1005C8.916 13.0995 9.121 13.0225 9.279 12.8685C9.432 12.7165 9.517 12.5145 9.517 12.2985C9.517 12.0825 9.432 11.8805 9.277 11.7275C8.966 11.4215 8.458 11.4205 8.146 11.7275ZM11.434 11.7275L11.433 11.7285C11.28 11.8805 11.195 12.0825 11.195 12.2985C11.195 12.5145 11.28 12.7165 11.434 12.8705C11.591 13.0235 11.796 13.1005 12.001 13.1005C12.206 13.1005 12.41 13.0235 12.566 12.8705C12.721 12.7185 12.806 12.5155 12.806 12.2985C12.806 12.0825 12.721 11.8785 12.567 11.7285C12.256 11.4205 11.748 11.4205 11.434 11.7275ZM17.872 15.6035L18.573 18.3655L15.759 17.6715C15.611 17.6355 15.457 17.6555 15.325 17.7285C14.31 18.2905 13.16 18.5865 12 18.5865C8.251 18.5865 5.201 15.5845 5.201 11.8935C5.201 8.20251 8.251 5.20051 12 5.20051C15.75 5.20051 18.8 8.20251 18.8 11.8935C18.8 13.0335 18.5 14.1625 17.932 15.1595C17.856 15.2935 17.834 15.4535 17.872 15.6035ZM20 11.8935C20 7.54151 16.411 3.99951 12 3.99951C7.589 3.99951 4 7.54151 4 11.8935C4 16.2465 7.589 19.7875 12 19.7875C13.284 19.7875 14.555 19.4785 15.695 18.8915L19.257 19.7695C19.304 19.7815 19.352 19.7875 19.4 19.7875C19.558 19.7875 19.712 19.7245 19.826 19.6095C19.974 19.4605 20.034 19.2435 19.982 19.0395L19.094 15.5375C19.687 14.4145 20 13.1595 20 11.8935Z" fill="currentColor"/>
  </svg>
</template>