<template>
    <b-modal v-model="modalShow"
             no-enforce-focus
             :modal-class="{ 'modal--loading': loading }"
             centered
             hide-header-close
             no-close-on-backdrop>
        <slot></slot>
        <template #modal-footer="{ cancel }">
            <button class="cancel-button"
                    @click="currentStep === 1 ? cancel() : preStep()">
                {{ currentStep === 1 ? 'Cancel' : 'Back' }}
            </button>
            <button class="process-button"
                    :disabled="isButtonDisabled"
                    @click="currentStep === 5 ? process() : nextStep()">
                {{ currentStep === 5 ? 'Finish' : 'Next' }}
            </button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: 'JobModal',
    props: {
        title: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        currentuser: {
            type: Object,
            required: true
        },
        currentStep: {
            type: Number,
            default: 1
        },
        stepValidities: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            modalShow: false
        };
    },
    computed: {
        isButtonDisabled() {
            return !this.stepValidities[this.currentStep];
        }
    },
    methods: {
        show() {
            this.modalShow = true;
        },
        hide() {
            this.modalShow = false;
        },
        process() {
            this.$emit('on-process');
        },
        nextStep() {
            this.$emit('next-step');
        },
        preStep() {
            this.$emit('pre-step');
        }
    }
};
</script>

<style scoped lang="scss">
@import "./JobModal.scss";
</style>
