<script>
    export default {
        name: "SiteMonthlyBudgetIcon",
    };
</script>

<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_80_5204)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 5V11H19.5C19.9853 11 20.4317 11.1752 20.779 11.4654C20.1258 11.5441 19.5627 11.9182 19.2276 12.45H17.8452C17.4668 11.8495 16.7977 11.45 16.0364 11.45C15.2106 11.45 14.4934 11.9199 14.1379 12.6066C13.1182 12.9762 12.4055 13.957 12.405 15.0852V15.0864L12.4036 17H11.5V20C11.5 20.55 11.05 21 10.5 21H5.5C4.4 21 3.5 20.1 3.5 19V9C3.5 7.9 4.4 7 5.5 7H7.5V5C7.5 3.9 8.4 3 9.5 3H15.5C16.6 3 17.5 3.9 17.5 5ZM5.5 19H7.5V17H5.5V19ZM5.5 15H7.5V13H5.5V15ZM5.5 11H7.5V9H5.5V11ZM9.5 15H11.5V13H9.5V15ZM9.5 11H11.5V9H9.5V11ZM9.5 7H11.5V5H9.5V7ZM13.5 11H15.5V9H13.5V11ZM13.5 7H15.5V5H13.5V7Z" fill="#3C3C3C"/>
        <g clip-path="url(#clip1_80_5204)">
        <path d="M21.9946 14.0909H21.4946V13.5909C21.4946 13.3159 21.2696 13.0909 20.9946 13.0909C20.7196 13.0909 20.4946 13.3159 20.4946 13.5909V14.0909H16.4946V13.5909C16.4946 13.3159 16.2696 13.0909 15.9946 13.0909C15.7196 13.0909 15.4946 13.3159 15.4946 13.5909V14.0909H14.9946C14.4396 14.0909 13.9996 14.5409 13.9996 15.0909L13.9946 22.0909C13.9946 22.6409 14.4396 23.0909 14.9946 23.0909H21.9946C22.5446 23.0909 22.9946 22.6409 22.9946 22.0909V15.0909C22.9946 14.5409 22.5446 14.0909 21.9946 14.0909ZM21.9946 21.5909C21.9946 21.8659 21.7696 22.0909 21.4946 22.0909H15.4946C15.2196 22.0909 14.9946 21.8659 14.9946 21.5909V16.5909H21.9946V21.5909Z" fill="#3C3C3C"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_80_5204">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
        <clipPath id="clip1_80_5204">
        <rect width="12" height="12" fill="white" transform="translate(12.5 12)"/>
        </clipPath>
        </defs>
    </svg>
</template>
