<template>
    <fieldset>
        <div class="main-form-div job-table">
            <h2 class="step-heading">{{ titleWithSubtitle }}</h2>
            <div class="job-row">
                <label class="job-label"
                       for="Notification_Emails">
                    Who would you like to be notified of new candidates?
                    <span>*</span>
                    <span class="tooltip-span"
                          id="Notification_Emails"
                          style="padding: 0 2px">
                        <img src="/images/InfoIcon.png"
                             aria-label="Site Info Tooltip" />
                    </span>
                    <b-tooltip target="Notification_Emails"
                               triggers="hover">
                        Please select the portal user you would like to be notified by email when a new candidate
                        expresses interest in this role.
                    </b-tooltip>
                </label>
                <single-select v-model="form.Notification_Emails"
                               :options="notificationOptions"
                               placeholder="Please select..."
                               :resetField="true"
                               :class="{ 'is-invalid': !isFieldValid(form.Notification_Emails) }" />
                <span v-if="!isFieldValid(form.Notification_Emails)"
                      class="error-message">
                    "Field" is required.
                </span>
                <div class="input-tagged">
                    <div v-for="(element, index) in form.Notification_Emails_selected"
                         :key="index"
                         class="selected-tags">
                        <span class="lablesize">{{ element }}</span>
                        <span class="deselect-pad">
                            <button @click="removeNotification1Selected(index)">
                                <img src="/images/cancel.png" />
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="job-row">
                <label class="job-label"
                       for="hr_notification_emails">
                    Who would you like to be notified when starters reach their retention milestone?
                    <span>*</span>
                    <span class="tooltip-span"
                          id="hr_notification_emails"
                          style="padding: 0 2px">
                        <img src="/images/InfoIcon.png"
                             aria-label="Site Info Tooltip" />
                    </span>
                    <b-tooltip target="hr_notification_emails"
                               triggers="hover">
                        Please select the portal user you would like to be notified by email when starters reach their
                        'still-in-post' milestone. This person should be able to confirm if the starter is still in post
                        or not.
                    </b-tooltip>
                </label>
                <single-select v-model="form.hr_notification_emails"
                               :options="notificationOptions"
                               placeholder="Please select..."
                               :resetField="true"
                               :class="{ 'is-invalid': !isFieldValid(form.hr_notification_emails) }" />
                <span v-if="!isFieldValid(form.hr_notification_emails)"
                      class="error-message">
                    "Field" is required.
                </span>
                <div class="input-tagged">
                    <div v-for="(element, index) in form.hr_notification_emails_selected"
                         :key="index"
                         class="selected-tags">
                        <span class="lablesize">{{ element }}</span>
                        <span class="deselect-pad">
                            <button @click="removeNotification2Selected(index)">
                                <img src="/images/cancel.png" />
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</template>

<script>
import SingleSelect from '../../SingleSelect/SingleSelect.vue';

export default {
    name: 'Step5',
    components: {
        SingleSelect,
    },
    props: {
        form: {
            type: Object,
            default: () => ({
                Notification_Emails: "",
                hr_notification_emails: "",
                Notification_Emails_selected: [],
                hr_notification_emails_selected: []
            }),
        },
        pusers: {
            type: Array,
            default: () => [],
        },
        currentuser: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: 'Job Details',
        },
    },
    data() {
        return {
            notificationOptions: this.pusers.map(user => ({
                id: user.email,
                name: user.email,
            }))
        };
    },
    computed: {
        titleWithSubtitle() {
            return `${this.title} - Notifications`;
        },
        checkValidation() {
            const requiredFields = this.currentuser.customer.merge_ats_integration
                ? [this.form.hr_notification_emails_selected]
                : [this.form.Notification_Emails_selected, this.form.hr_notification_emails_selected];

            return requiredFields.every(this.isFieldValid);
        },
    },
    methods: {
        isFieldValid(field) {
            if (Array.isArray(field)) {
                return field.length > 0;
            }

            return field !== '' && field !== null;
        },
        removeNotification1Selected(index) {
            this.form.Notification_Emails = "";
            this.form.Notification_Emails_selected.splice(index, 1);
        },
        removeNotification2Selected(index) {
            this.form.hr_notification_emails = "";
            this.form.hr_notification_emails_selected.splice(index, 1);
        },
        addUniqueSelection(selectedArray, newValue) {
            if (!selectedArray.includes(newValue)) {
                selectedArray.push(newValue);
            }
        },
        checkNotifications() {
            if (this.form.Notification_Emails !== '') {
                this.form.Notification_Emails_selected = this.form.Notification_Emails.split(',').map(email => email.trim());
            }

            if (this.form.hr_notification_emails !== '') {
                this.form.hr_notification_emails_selected = this.form.hr_notification_emails.split(',').map(email => email.trim());
            }
        }
    },
    watch: {
        'form.Notification_Emails'(newVal) {
            if (newVal) {
                this.addUniqueSelection(this.form.Notification_Emails_selected, newVal);
            }
        },
        'form.hr_notification_emails'(newVal) {
            if (newVal) {
                this.addUniqueSelection(this.form.hr_notification_emails_selected, newVal);
            }
        },
        form: {
            deep: true,
            handler() {
                this.$emit('update-step-validity', this.checkValidation);
            },
        },
    },
    mounted() {
        this.checkNotifications();
        this.$emit('update-step-validity', this.checkValidation);
    },
};
</script>

<style scoped lang="scss">
@import "./Steps.scss";
</style>