<template>
    <div class="bonus-points-table">
        <div v-show="showLoad"
             class="show-load-app">
            <img :src="myImage">
        </div>

        <div class="cell auto add-req-btn">
            <button class="btn-edit button pink_btn b_margin_0 float-right no_wrap_btn btn_award"
                    :disabled="!canCreateRequest"
                    @click="showModal">
                add request
            </button>
        </div>

        <b-modal :id="modalKey"
                 size="sm"
                 :no-close-on-backdrop="true"
                 data-keyboard="false"
                 data-backdrop="static">
            <h3>Add Payment Request</h3>

            <form id="add-request"
                  @submit.prevent="submitAddRequest()"
                  action="#"
                  method="post"
                  autocomplete="off">
                <v-select class="bonus-placeholder"
                          placeholder="Select"
                          :options="getDropdownItems()"
                          @input="value => setSelectedOption(value)"
                          :clearable="false" />

                <div v-if="selectedOption"
                     class="cell medium-12 full-width input-box">
                    <div v-if="selectedOption == 'Single app user'"
                         class="cell auto no-label multi multi-bonus">
                        <select-customer-user-component @selectedUser="selectedUser"
                                                        :users="getMobileUsers()"></select-customer-user-component>
                        <span class="form-error"
                              id="user">Please select a user for allocation.</span>

                        <div class="amount-option col-md-6 cell auto no-label">
                            <label class="bonus-label">AMOUNT</label>
                            <v-select class="bonus-placeholder"
                                      placeholder="Choose"
                                      @input="amountSelection"
                                      v-model="request_amount"
                                      :options="amount_options"
                                      :clearable="false"></v-select>
                            <!-- <select class="form-control" v-model="request_amount" @change="amountSelection($event)">
                                <option value="" selected disabled>Choose</option>
                                <option v-for="(amount_option,index) in amount_options" :value="amount_option" :key="index">{{ amount_option }}</option>
                            </select> -->
                        </div>

                        <div v-if="if_specific_amount"
                             class="amount-val col-md-6 input-box">
                            <label class="bonus-label">Number of Points:</label>
                            <input class
                                   type="number"
                                   :max="max_specific_val"
                                   v-model="form.points_no"
                                   placeholder="Number of points" />
                            <span class="form-error"
                                  id="points">Please select a number of points</span>
                        </div>

                        <div class="cell auto popup-button no_margin_bottom">
                            <div v-if="btnStatus">
                                <input class="btn button pink_btn btn_lg"
                                       type="submit"
                                       value="ADD" />
                            </div>
                            <div v-if="!btnStatus">
                                <input class="button btn_lg btn pink_btn"
                                       type="submit"
                                       disabled
                                       value="ADD" />
                            </div>
                        </div>
                    </div>

                    <div v-else-if="selectedOption == 'Multiple app users'"
                         class="cell auto no-label multi multi-bonus">
                        <multiple-select-payment-component :users="mobile_users_arr"
                                                           @userSelect="multiSelectedUser"></multiple-select-payment-component>

                        <span class="form-error"
                              id="user">Please select a user for allocation.</span>

                        <div class="amount-option col-md-6 cell auto no-label">
                            <label class="bonus-label">AMOUNT</label>

                            <select name="amount"
                                    id="amount"
                                    disabled>
                                <option value="all">All</option>
                                <option value="specify">Specify</option>
                            </select>
                        </div>
                        <div v-if="if_specific_amount"
                             class="amount-val col-md-6 input-box">
                            <label class="bonus-label">Number of Points:</label>
                            <input class
                                   type="number"
                                   :max="max_specific_val"
                                   v-model="form.points_no"
                                   placeholder="Number of points" />
                            <span class="form-error"
                                  id="points">Please select a number of points</span>
                        </div>

                        <div class="cell auto popup-button no_margin_bottom">
                            <div v-if="btnStatus">
                                <input class="btn button pink_btn btn_lg"
                                       type="submit"
                                       value="ADD" />
                            </div>
                            <div v-if="!btnStatus">
                                <input class="button btn_lg btn pink_btn"
                                       type="submit"
                                       disabled
                                       value="ADD" />
                            </div>
                        </div>
                    </div>

                    <div v-else-if="selectedOption == 'All users at site'"
                         class="cell auto no-label">
                        <div>
                            <site-select-customer-user-component @selectedSite="selectedSite"
                                                                 :sites="sites"></site-select-customer-user-component>
                            <span class="form-error"
                                  id="user">Please select a user for allocation.</span>
                        </div>
                        <div class="cell auto popup-button margin_top">
                            <div v-if="btnStatus">
                                <input class="btn button pink_btn btn_lg"
                                       type="submit"
                                       value="ADD" />
                            </div>
                            <div v-if="!btnStatus">
                                <input class="button btn_lg btn pink_btn"
                                       type="submit"
                                       disabled
                                       value="ADD" />
                            </div>
                        </div>
                    </div>

                    <div v-else-if="selectedOption == 'All users at organisation'"
                         class="cell auto no-label">
                        <div class="cell auto popup-button no_margin_bottom">
                            <div>
                                <input class="btn button pink_btn btn_lg"
                                       type="submit"
                                       value="ADD" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <b-modal class="tab-modal"
                 :id="modalKey + '-confirmmsg'"
                 size="lg"
                 :no-close-on-backdrop="true"
                 data-keyboard="false"
                 data-backdrop="static">
            <div class="grid-y">
                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                        <h4>{{title}}</h4>
                        <p>{{message}}</p>
                    </div>
                </div>
            </div>
            <div class="cell auto popup-button">
                <input class="delete-candidate"
                       value="Okay"
                       type="submit"
                       @click="confirmCandidate()">
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: ["show_sites", "user_site_count", "modalKeySuffix", "currentUser"],
    data() {
        return {
            mobile_users_arr: [],
            myImage: '/images/loading-circle.gif',
            showLoad: false,
            selectedOption: "",
            btnStatus: false,
            amount_val: '0.00',
            if_specific_amount: false,
            max_specific_val: 0,
            form: {
                user_id: null,
                reward_catagory_id: null,
                reason_for_allocation: null,
                points_no: 0,
                allocted_by: this.currentUserId,
                who: null,
                site_id: null
            },
            request_amount: "All",
            amount_options: ["All", "Specify"],
            confirmmsgCheck: false,
            sites: '',
            mobileUsers: '',
            title: '',
            message: '',
            modalKey: `addRequestModal-${this.modalKeySuffix}`
        };
    },
    computed: {
        canCreateRequest() {
            if (!this.currentUser) {
                return false;
            }

            return this.currentUser.users_check || this.currentUser.approve_check || this.currentUser.paid_check;
        }
    },
    methods: {
        showModal: function () {
            this.$bvModal.show(this.modalKey);
        },
        getDropdownItems: function () {
            let items = ["Multiple app users", "Single app user"];
            if (this.show_sites) {
                items = [...items, "All users at site"];
            }
            if (this.user_site_count === 0) {
                items = [...items, "All users at organisation"];
            }
            return items;
        },
        selectedUser: function (value) {
            this.form.user_id = value;
            var userIdData = this.form.user_id.split("-");

            if (userIdData[1] === 'invitation') {
                axios.post('points/get-invite-redeemable-balance', {
                    user_id: userIdData[0]
                }).then((response) => {
                    this.form.points_no = response.data.redeemable_balance;
                    this.max_specific_val = response.data.redeemable_balance;
                });
            }

            if (userIdData[1] === 'user') {
                axios.post('points/get-user-redeemable-balance', {
                    user_id: userIdData[0]
                }).then((response) => {
                    this.form.points_no = response.data.redeemable_balance;
                    this.max_specific_val = response.data.redeemable_balance;
                });
            }
        },
        multiSelectedUser: function (valueArr) {
            // let value = valueArr[valueArr.length - 1]
            // console.log('valueArr ::: ', value);
            this.form.user_id = valueArr;
            if (valueArr.length > 0) {
                this.btnStatus = true;
            } else {
                this.btnStatus = false;
            }
        },
        amountSelection(value) {
            this.if_specific_amount = false;
            if (value === 'Specify') {
                this.if_specific_amount = true;
            }
        },
        selectedSite(value) {
            this.form.site_id = value;
        },
        setSelectedOption(value) {
            this.selectedOption = value;
        },
        confirmCandidate() {
            this.$bvModal.hide(this.modalKey + '-confirmmsg');
        },
        submitAddRequest() {
            if (this.form.who === 'Single app user') {
                Vue.swal({
                    title: "Payment Request...",
                    html: "",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading();
                    }
                });
                axios.post('points/create-payment-request', {
                    user_id: this.form.user_id,
                    amount: this.form.points_no,
                    amount_type: this.request_amount,
                })
                    .then((response) => {
                        var self = this;
                        Vue.swal.close();
                        self.$bvModal.hide(self.modalKey);
                        if (response.status == 201) {
                            Vue.swal({
                                html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>  ' + response.data.message + '',
                                confirmButtonColor: "#5dc2a6",
                                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                background: "#5dc2a6",
                                customClass: {
                                    container: "candidate-toast"
                                },
                                showClass: {
                                    popup: "animated fadeInLeft fast",
                                    icon: "animated heartBeat delay-1s"
                                },
                                hideClass: {
                                    popup: "animated fadeOutLeft slow"
                                },
                                toast: true,
                                timer: 6000,
                                position: "top-right",
                                target: "#example-tabs"
                            });
                            this.$root.$emit("paymentRequests");

                        } else if (response.status == 200) { //request already in process, don't allow to submit new.
                            var self = this;

                            if (response.data.title == 'Request Not Created') {
                                self.title = response.data.title;
                                self.message = 'The amount you have entered is less than the minimum required by your organisations rules. Please enter a higher amount.';
                            } else {
                                self.title = 'Request Already Pending';
                                self.message = 'There is an outstanding payment request for this user. To create a new one, please process the outstanding request first.';
                            }

                            self.confirmmsgCheck = true;
                            self.$bvModal.show(self.modalKey + '-confirmmsg');

                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (this.form.who === 'All users at site') {
                Vue.swal({
                    title: "Loading...",
                    html: "Adding points for all the users at site!!",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading();
                    }
                });
                axios.post('points/create-site-payment-request', {
                    site_id: this.form.site_id,
                })
                    .then((response) => {
                        Vue.swal.close();
                        var self = this;
                        self.$bvModal.hide(self.modalKey);

                        if (response.status == 201) {
                            Vue.swal({
                                html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>  ' + response.data.message + '',
                                confirmButtonColor: "#5dc2a6",
                                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                background: "#5dc2a6",
                                customClass: {
                                    container: "candidate-toast"
                                },
                                showClass: {
                                    popup: "animated fadeInLeft fast",
                                    icon: "animated heartBeat delay-1s"
                                },
                                hideClass: {
                                    popup: "animated fadeOutLeft slow"
                                },
                                toast: true,
                                timer: 6000,
                                position: "top-right",
                                target: "#example-tabs"
                            });
                            this.$root.$emit("paymentRequests");
                        } else if (response.status == 200) {
                            Vue.swal.fire({
                                icon: 'error',
                                customClass: "all-users-site-swal",
                                allowOutsideClick: false,
                                title: response.data.message,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (this.form.who === 'Multiple app users') {
                Vue.swal({
                    title: "Loading...",
                    html: "Adding points for Multiple app users!",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading();
                    }
                });
                // console.log('this.form ::: ', this.form, ' this.if_specific_amount ::: ', this.if_specific_amount);
                axios.post('points/create-payment-request-for-multiple-users', {
                    user_id_array: this.form.user_id,
                    amount_type: "All",
                })
                    .then((response) => {
                        Vue.swal.close();
                        var self = this;
                        self.$bvModal.hide(self.modalKey);

                        if (response.status == 201) {
                            Vue.swal({
                                html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>  ' + response.data.message + '',
                                confirmButtonColor: "#5dc2a6",
                                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                background: "#5dc2a6",
                                customClass: {
                                    container: "candidate-toast"
                                },
                                showClass: {
                                    popup: "animated fadeInLeft fast",
                                    icon: "animated heartBeat delay-1s"
                                },
                                hideClass: {
                                    popup: "animated fadeOutLeft slow"
                                },
                                toast: true,
                                timer: 5000,
                                position: "top-right",
                                target: "#example-tabs"
                            });
                            location.reload();
                        } else if (response.status == 200) { //handing validation errors
                            Vue.swal.fire({
                                icon: 'error',
                                customClass: "all-users-site-swal",
                                allowOutsideClick: false,
                                //showCloseButton: true,
                                //showCancelButton: true,
                                //cancelButtonText: "CANCEL",
                                title: response.data.message,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                Vue.swal({
                    customClass: "all-users-at-org-swal",
                    title: "Are you sure?",
                    html: "<div style='text-align: justify;text-justify: inter-word; line-height: 1.57;'>This will create a payment request for all the selected users and they will all be sent a push notification confirming this. You will then be able to process the request as normal.</div>",
                    showCancelButton: true,
                    confirmButtonText: "CONTINUE",
                    cancelButtonText: "CANCEL",
                    reverseButtons: true,
                    confirmButtonColor: "#f55d73",
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.value) { // if confirmed
                        Vue.swal({
                            title: "Loading...",
                            html: "Adding points for all the users at organization!!",
                            allowOutsideClick: false,
                            onBeforeOpen: () => {
                                Vue.swal.showLoading();
                            }
                        });
                        axios.post('points/create-org-payment-request')
                            .then((response) => {
                                Vue.swal.close();
                                var self = this;
                                self.$bvModal.hide(self.modalKey);
                                if (response.status == 201) {
                                    Vue.swal({
                                        html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>  ' + response.data.message + '',
                                        confirmButtonColor: "#5dc2a6",
                                        confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                        background: "#5dc2a6",
                                        customClass: {
                                            container: "candidate-toast"
                                        },
                                        showClass: {
                                            popup: "animated fadeInLeft fast",
                                            icon: "animated heartBeat delay-1s"
                                        },
                                        hideClass: {
                                            popup: "animated fadeOutLeft slow"
                                        },
                                        toast: true,
                                        timer: 5000,
                                        position: "top-right",
                                        target: "#example-tabs"
                                    });
                                    location.reload();
                                } else if (response.status == 200) { //handing validation errors
                                    Vue.swal.fire({
                                        icon: 'error',
                                        customClass: "all-users-site-swal",
                                        allowOutsideClick: false,
                                        title: response.data.message,
                                    });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        console.log('Not confirmed');
                    }
                });
            }
        },
        getMobileUsers() {
            return this.$attrs.mobile_users;
        },
        getMobileUsersForMultipleApp() {
            // let mobileUsersArr = [...this.$attrs.mobile_users];
            let mobileUsersArr = [];

            axios.get('get/mobile-users').then((response) => {
                this.mobile_users_arr = response.data.activeAppUsers;
            });


            // let tempArr = [];
            // mobileUsersArr.map((val, index) => {
            //     if(val.points.redeemable_balance > 0){
            //         tempArr.push(val);
            //     }
            // })
            //
            // // tempArr.map((val, index) => {
            // //     console.log('name ::: ', val.first_name, ' ', val.surname, ' redeemable bal ::: ', val.points.redeemable_balance);
            // // })
            // this.mobile_users_arr = tempArr;
            //
            // return tempArr;

        },
        allFieldsFilled() {
            // if ((this.form.who) && //common all all condition
            //     ((this.form.user_id && this.form.points_no > 0) || //Single app user
            //         (this.form.site_id))) {
            //     this.btnStatus = true;
            // } else {
            //     this.btnStatus = false;
            // }

            if ((this.form.who) && //common all all condition
                ((this.form.user_id && this.form.points_no >= 0) || //Single app user
                    (this.form.site_id))) {
                this.btnStatus = true;
            } else {
                this.btnStatus = false;
            }
        },
        getAllSites() {
            var self = this;
            return axios.get('/dashboard/points/get-all-sites')
                .then(function (response) {
                    self.sites = response.data.sites;
                });
        },
        activeAppUsers() {
            var self = this;
            return axios.get('/dashboard/points/active-app-users')
                .then(function (response) {
                    self.mobileUsers = response.data.mobile_users;
                });
        }
    },
    watch: {
        "form.points_no": function (val) {
            this.allFieldsFilled();
        },
        "form.site_id": function (val) {
            this.allFieldsFilled();  //Check if all fields are filled in
        },
        selectedOption: function (val) {
            this.form.who = val;
        }
    },
    mounted() {
        this.getAllSites().then(() =>
            this.getMobileUsersForMultipleApp()
        )
    }
};
</script>

<style>
.add-req-btn {
    margin-top: 3%;
}

.amount-option {
    padding: 0 10px 0 0;
    float: left;
    margin: 22px 0;
}

.amount-val {
    padding: 0 0 0 3px;
    float: left;
    margin: 22px 0;
}

.margin_top {
    margin-top: 22px;
}

.cell.auto.add-req-btn button.btn-edit.button.pink_btn {
    margin-top: 4px;
}

#addRequestModal___BV_modal_body_ {
    padding: 0 30px 44px;
    text-align: left;
}

.cell.auto.no-label.multi-bonus {
    position: relative;
}

.cell.auto.no-label.multi.multi-bonus .multiple_bonus {
    position: relative;
}

#addRequestModal .multi-bonus .multiple_bonus .vs__dropdown-toggle {
    height: auto;
    border: 0;
    padding: 50px 0 0 !important;
}

#addRequestModal .multi-bonus .multiple_bonus .vs__dropdown-toggle:before {
    content: '';
    border: 1px solid #DCDEE6;
    width: 100%;
    height: 42px;
    position: absolute;
    top: 0;
}

#addRequestModal .multi-bonus .multiple_bonus .vs__dropdown-toggle .vs__selected-options {
    top: 0;
    position: initial;
}

#addRequestModal .multi-bonus .multiple_bonus .vs__dropdown-toggle .vs__selected-options .vs__search {
    position: absolute;
    top: 0;
    left: 0;
}

#addRequestModal .multi-bonus .multiple_bonus .vs__dropdown-toggle .vs__actions {
    position: absolute;
    top: 0;
    right: 5px;
    height: 42px;
}

#addRequestModal .vs__dropdown-option--highlight {
    background: #f7f8fc !important;

}

#addRequestModal .vs__dropdown-option--disabled {
    background: #f7f8fc !important;

}

#addRequestModal .vs__dropdown-menu li:focus,
#addRequestModal .vs__dropdown-menu li:focus a {
    width: 180px;
    background: #f7f8fc !important;
}

#addRequestModal .vs__dropdown-menu {
    min-width: 180px;
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e9eaed;
    background-color: #ffffff;
    padding: 0;
}

#addRequestModal .vs__dropdown-menu li {
    color: #363b54;
    font: 400 13px/20px "InterUI", sans-serif;
    padding: 9px 15px 11px 15px;
    text-transform: capitalize;
}

#addRequestModal .vs__dropdown-menu li:focus,
.modal .vs__dropdown-menu li:focus a {
    background: #f7f8fc !important;
}

#addRequestModal .vs__dropdown-menu li:hover,
.modal .vs__dropdown-menu li:hover a {
    background: #f7f8fc !important;
}

#vs8__listbox {
    width: 220px !important;
}
</style>
