<template>
    <div ref="template-actions-wrapper"
         class="template-actions-wrapper">
        <button class="template-actions__button"
                :class="{'template-actions__button--active': modal}"
                @click="toggleModal">
            <icon name="ellipsis-menu"
                  class="ellipsis" />
        </button>

        <ul v-if="modal"
            class="template-actions-modal">
            <div class="template-actions">
                <li class="template-action"
                    @click="editTemplate">
                    <icon :name="iconName" />
                    <span>{{ buttonText }}</span>
                </li>
            </div>
        </ul>

        <edit-template ref="edit-template"
                       :template="template"
                       :currentuser="currentuser"
                       @on-update="onUpdateTemplate" />
    </div>
</template>

<script>
import { Icon } from "../../../../components/Icon";
import EditTemplate from "../EditTemplate";

export default {
    name: 'TemplateActions',
    props: {
        template: { type: Object, required: true },
        currentuser: { type: Object, required: true }
    },
    components: { Icon, EditTemplate },
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        isViewMode() {
            return ((this.currentuser.account_check === 0 || this.currentuser.account_check === false) || (this.currentuser.customer.edit_templates_feature === 0 || this.currentuser.customer.edit_templates_feature === false) ); 
        },

        buttonText() {
            return this.isViewMode ? 'Preview' : 'Edit';
        },
        iconName() {
            return this.isViewMode ? 'view' : 'pencil';
        }
    },
    methods: {
        toggleModal() {
            this.modal ? this.hideModal() : this.showModal();
        },
        showModal() {
            this.modal = true;
            document.addEventListener('click', this.handleClick);
        },
        hideModal() {
            this.modal = false;
            document.removeEventListener('click', this.handleClick);
        },
        handleClick(event) {
            const wrapper = this.$refs['template-actions-wrapper'];
            const outsideClick = !wrapper.contains(event.target);

            if (outsideClick) {
                this.hideModal();
            }
        },
        onUpdateTemplate(data) {
            this.$emit('on-update', data);
            this.hideModal();
        },
        editTemplate() {
            this.$refs['edit-template'].showModal();
            this.hideModal()
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick);
    }
}
</script>

<style scoped lang="scss">
@import "./TemplateActions.scss";
</style>