<template>
  <div>
    <b-modal
      class="modal-p-div"
      ref="edit-notification"
      id="edit-notification-modal"
      size="xl"
      :no-close-on-backdrop="true"
    >
      <div v-show="showLoad" class="show-load-app">
        <img :src="myImage" />
      </div>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
      </template>
      <section class="multi-step-row multi-step-p">
        <div class="pop-up-box pop-up-box-styling">
          <div class="top-check-row" v-if="step != 1">
            <ul>
              <li
                v-bind:class="{
                  selected: isActive(1),
                  completed: done1,
                  selected: active1,
                }"
              >
                <a @click="step = 1" href="javascript:void(0)">1</a>
              </li>
              <li
                v-bind:class="{
                  selected: isActive(2),
                  completed: done2,
                  selected: active2,
                }"
              >
                <a @click="step = 2" href="javascript:void(0)">2</a>
              </li>
              <li
                v-bind:class="{
                  selected: isActive(3),
                  completed: done3,
                  selected: active3,
                }"
              >
                <a @click="step = 3" href="javascript:void(0)">3</a>
              </li>
            </ul>
          </div>
          <div class="text-box" id="text-box">
            <p class="add-a-job" v-if="step != 1">EDIT NOTIFICATION</p>
            <p class="key-center" v-if="step != 1">
              <span class="key-details" v-if="step == 1">Key details</span>
              <span class="key-details" v-if="step == 2">Content</span>
              <span class="key-details" v-if="step == 3">Scheduling</span>
            </p>

            <fieldset v-if="step == 1">
              <div class="ENGAGEMENT_temp">
                <div class="Notification-title-div">
                  <h2 class="notification-p">Edit Notification</h2>
                  <div @click="closeModal" class="x-icon">
                    <p>
                      <img src="/images/icon-24-exit-3.png" alt="" />
                    </p>
                  </div>
                </div>

                <div
                  class="flex-container Rectangle"
                  @click="
                    step = 2;
                    form.image_url = '';
                    initialImageUrl = '';
                    form.imageName = '';
                    imageName = '';
                  "
                >
                  <div>
                    <p class="notification-custom">
                      Create custom notification
                    </p>
                  </div>
                  <div>
                    <i
                      class="fa fa-arrow-right fafa-icon-styling"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>

                <div class="choose-template">
                  <p class="template-p">- Or choose from template -</p>
                </div>

                <div v-if="engagementTemplates.length > 0">
                  <p class="ENGAGEMENT">ENGAGEMENT</p>
                </div>

                <div
                  class="card-div"
                  v-for="(engagementTemplate, rowIndex) in engagementTemplates"
                >
                  <div
                    v-for="(template, index) in engagementTemplate"
                    class="Rectangle-Copy-6"
                    @click="setTemplate(rowIndex, index, template.category)"
                  >
                    <h2
                      class="Notification-name line-clamp-title"
                      v-html="template.title"
                    ></h2>
                    <div class="flex-container card-img-notification">
                      <div>
                        <h2 class="Notification-title"></h2>
                      </div>
                    </div>

                    <div class="tem-modal line-clamp">
                      <p
                        class="Nullam-maximus-nibh"
                        v-html="template.message"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="otherTemplates.length > 0">
                <p class="ENGAGEMENT">OTHER</p>
              </div>

              <div
                class="card-div"
                v-for="(otherTemplate, rowIndex) in otherTemplates"
              >
                <div
                  v-for="(template, index) in otherTemplate"
                  class="Rectangle-Copy-6"
                  @click="setTemplate(rowIndex, index, template.category)"
                >
                  <h2
                    class="Notification-name line-clamp-title"
                    v-html="template.title"
                  ></h2>
                  <div class="flex-container card-img-notification">
                    <div>
                      <h2 class="Notification-title"></h2>
                    </div>
                  </div>

                  <p
                    class="Nullam-maximus-nibh line-clamp"
                    v-html="template.message"
                  ></p>
                </div>
              </div>
            </fieldset>
            <fieldset v-if="step == 2">
              <div class="job-leftside">
                <form>
                  <div class="form-group create-modal-title">
                    <label for="exampleInputEmail1" class="Title-content"
                      >Title *</label
                    >
                    <!-- <input
                                          type="text"
                                          class="form-control"
                                          placeholder="eg. Jane"
                                          aria-describedby="Title"
                                          name="Title"
                                          aria-errormessage="notificationTitle"
                                          required
                                          v-model="form.Title"
                                      /> -->

                    <!-- <ckeditor
                                                  class="form-control text-area-border styling-bottom"
                                                  placeholder="Add Wording"
                                                  id="exampleFormControlTextarea1"
                                                  name="Message"
                                                  rows="5"
                                                  v-model="
                                                      currentObject.title
                                                  "
                                                  :config="titleEditorConfig"
                                              >
                                              </ckeditor> -->

                    <ckeditor
                      class="cheditor-styling-title"
                      id="exampleFormControlTextarea1"
                      v-model="form.Title"
                      :config="titleEditorConfig"
                    ></ckeditor>

                    <span class="form-error" id="notificationTitle"
                      >Please enter a notification title</span
                    >
                  </div>

                  <div class="form-group">
                    <label class="wording" for="exampleFormControlTextarea1"
                      >WORDING *</label
                    >
                    <!-- <div class="text-area-header">
                                          <img
                                              src="/images/icon-text-list-bullet-copy.png"
                                              alt=""
                                          />
                                        <img src="/images/icon-24-undo2.png" alt="" />
                                        <img src="/images/icon-24-redo.png" alt="" />
                                      </div> -->
                    <ckeditor
                      class="cheditor-styling"
                      :config="editorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="form.Message"
                    ></ckeditor>

                    <!-- <textarea
                                          class="form-control text-area-border"
                                          placeholder="Add Wording"
                                          id="exampleFormControlTextarea1"
                                          v-model="form.Message"
                                          name="Message"
                                          rows="5"
                                      >
                                    </textarea> -->
                  </div>

                  <div class="imgae-Modal-styling">
                    <p class="img-Content-styling">Image</p>
                  </div>

                  <label class="Image-label-content"></label>

                  <label for="actual-btn" class="upload-file-styling">
				    <div
                            class="uploader"
                            @dragenter="OnDragEnter"
                            @dragleave="OnDragLeave"
                            @dragover.prevent
                            @drop="onDrop"
                            :class="{ dragging: isDragging }"
                            >
                  <input
                    type="file"
                    accept="image/png,image/jpeg"
                    @change="uploadImage"
                    id="actual-btn"
                    hidden
                  />
                    <img class="img-upload-btn" src="/images/Capture.PNG" align="left" />
                    <span class="upload-btn-text"
                      ><p style="height:15px;"></p>Drag and drop an image or <a class="upload_noti">browse</a> to choose a file. Min
                      size 180 x 180 px</span
                    >
					</div>
                  </label>

                </form>

                <div v-if="imageName != ''">
                  <div class="img-upload-div-show">
                    <p class="image-Name-upload">
                      {{ imageName }}
                    </p>
                    <span @click="removeImage"
                      ><img src="/images/icon-24-exit.webp" alt=""
                    /></span>
                  </div>
                </div>
              </div>
              <div class="job-rightside">
                <div class="frame mobile-view">
                  <img
                    class="img-mobile-notification"
                    src="/images/notification-preview-image.png"
                    alt=""
                  />

                  <div class="mobile-notification-title">
                    <div class="mobile-notification-branding-table line-clamp-title">
                        <img
                            v-if="brand.is_care_sector"
                            src="/images/cf-favicon.png"
                        />
                        <img
                            v-else
                            src="/images/workfriends-logomark-2000px-full-colour.png"
                        />
                        <p>
                            {{ brand.name }}
                        </p>
                    </div>
                    <div class="img-upload">
                        <img
                            v-if="form.image_url"
                            :src="form.image_url"
                        />
                    </div>
                    <p
                      v-bind:class="{
                        'mobile-notification-title-table': form.Title ? true : false,
                        'mobile-notification-title-transparent': !form.Title
                          ? true
                          : false,
                      }"
                      class="hidden-title line-clamp-title"
                      v-html="form.Title"
                    ></p>
                    <p
                      :class="{
                        'mobile-notification-paragraph': form.Message
                          ? true
                          : false,
                        'mobile-notification-paragraph-transparent':
                          !form.Message ? true : false,
                      }"
                      class="line-clamp"
                      v-html="form.Message"
                    ></p>
                  </div>

                  <p
                    @click="sendPreview"
                    :class="validateStepTwo ? 'Valid' : 'NotValid'"
                  >
                    Send preview to my number
                  </p>
                </div>
              </div>

              <div class="cell auto popup-button pad-top">
                <button @click.prevent="goback()" class="back button btn">
                  Back
                </button>
                <input
                  @click="step = 3"
                  type="button"
                  name="next"
                  class="next action-button button btn_lg btn pink_btn"
                  value="Next"
                  :disabled="!validateStepTwo"
                />
              </div>
            </fieldset>
            <fieldset v-if="step == 3">
              <div class="main-form-div">
                <form class="form-dropdown-styling justify-content-center">
                  <div class="form-group input-sendto POC">
                    <label class="form-lable" for="exampleFormControlSelect1"
                      >SEND TO</label
                    >
                    <v-select
                      class="bonus-placeholder"
                      placeholder="Select"
                      v-model="form.send_action"
                      :options="can_notify_all_users ? items : items_site"
                      :clearable="false"
                      :reduce="(item) => item.value"
                      label="name"
                      @input="
                        form.user_id = '';
                        form.site_id = '';
                      "
                    ></v-select>

                    <div
                      v-if="form.send_action"
                      class="cell medium-12 full-width input-box"
                    >
                      <div
                        v-if="form.send_action == 'Single app user'"
                        class="cell auto no-label multi multi-bonus"
                      >
                        <single-app-user-notifications-component
                          @selectedUser="selectedUser"
                          :users="mobile_users_arr"
                          :selectedUserIds="form.user_id"
                          :notShowLabel="true"
                        ></single-app-user-notifications-component>
                      </div>

                      <div
                        v-else-if="form.send_action == 'Multiple app users'"
                        class="cell auto no-label multi multi-bonus"
                      >
                        <multiple-select-users-notifications-component
                          @selectedUser="multiSelectedUser"
                          :users="mobile_users_arr"
                          :selectedUserIds="form.user_id"
                          :notShowLabel="true"
                        ></multiple-select-users-notifications-component>
                      </div>

                      <div
                        v-else-if="form.send_action == 'All users at site'"
                        class="cell auto no-label"
                      >
                        <div>
                          <site-select-customer-user-component
                            @selectedSite="selectedSite"
                            :site-id="form.site_id"
                            :sites="this.siteOptions"
                            :notShowLabel="true"
                          ></site-select-customer-user-component>
                        </div>
                      </div>

                      <div
                        v-else-if="
                          form.send_action == 'All users at organisation'
                        "
                      ></div>
                    </div>

                    <div v-if="selectUser" class="cell auto" id="send-to-users">
                      <label>
                        <!-- @todo -->
                        <select-customer-user-component
                          @selectedUser="selectedUserId"
                          :site-id="0"
                        ></select-customer-user-component
                        >Please select someone to send this notification to
                      </label>
                    </div>

                    <div v-if="selectSite" class="cell auto" id="send-to-sites">
                      <label>
                        <v-select
                          placeholder="Select user..."
                          name="site_id"
                          v-model="form.site_id"
                          :reduce="(referrer) => referrer.id"
                          label="name"
                          :options="this.siteOptions"
                        ></v-select>
                      </label>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="form-lable" for="exampleFormControlSelect1"
                      >SEND</label
                    >
                    <v-select
                      class="form-control form-control-styling"
                      placeholder="Select"
                      v-model="form.send_immediately"
                      label="label"
                      :options="[
                        {
                          label: 'Immediately',
                          value: true,
                        },
                        {
                          label: 'On future date',
                          value: false,
                        },
                      ]"
                      :reduce="(data) => data.value"
                      :clearable="false"
                    ></v-select>
                    <!-- <div>
                                          <div class="input-box-2">
                                              <input type="time" id="appt" name="appt">
                                          </div>
                                      </div> -->
                  </div>

                  <div
                    class="date-time-modal-styling"
                    v-if="!form.send_immediately"
                  >
                    <datepicker
                      :disabled-dates="disabledDates"
                      highlighted="highlightedDates"
                      monday-first="true"
                      :value="datePickerValue"
                      @selected="(date) => onSelectDate(date)"
                      class="datetime-picker-modal"
                      full-month-name="true"
                      :minimumView="'day'"
                      :maximumView="'day'"
                    ></datepicker>
                    <vue-timepicker
                      v-model="form.scheduleTime"
                      format="hh:mm a"
                      :minute-interval="1"
                      :second-interval="10"
                      hide-clear-button="true"
                      manual-input
                      hide-dropdown
                    ></vue-timepicker>
                  </div>

                  <div v-if="this.currentObject.Repeat != 'no repeat'" class="form-group">
                    <v-select
                      v-model="form.Repeat"
                      id="send-to-action"
                      required
                      :reduce="(referrer) => referrer.id"
                      label="name"
                      :options="senttooptions"
                      class="time-drop-down"
                    ></v-select>
                  </div>
                </form>
              </div>

              <div class="btn-space-styling">
                <div class="cell auto popup-button pad-top">
                  <button @click.prevent="goback()" class="back button btn">
                    Back
                  </button>
                  <input
                    @click="showEditConfirmModal(form.id)"
                    type="button"
                    name="next"
                    class="action-button button btn_lg btn pink_btn"
                    value="Save"
                    :disabled="!validateFields"
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>

	<b-modal class="tab-modal" id="modal_edit_confirm" ref="modal_edit_confirm" size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <div class="discard-header4">
              <h3 class="discard-header-title">Edit recurring notifications</h3>
            </div>

			<div class="form-group input-sendto">
                <div class="radio-btn-input">
                    <input
                        type="radio"
                        id="currentevent"
                        value="currentevent"
                        v-model="editevents"
                        v-bind:style="[
                            editevents == 'currentevent'
                            ? { border: '1px solid #5dc2a6 !important' }
                             : { border: '1px solid #D1D3E0 !important' },
                        ]"
                    />
                           
                    <label for="currentevent" class="following"
                            >This notification</label
                          ><br />
                           
                    <input
                            type="radio"
                            id="following"
                            value="following"
                            v-model="editevents"
                            v-bind:style="[
                              editevents == 'following'
                                ? { border: '1px solid #5dc2a6 !important' }
                                : { border: '1px solid #D1D3E0 !important' },
                            ]"
                          />
                    <label for="following" class="following">This and following notifications</label><br />
					<!-- <input
                            type="radio"
                            id="allevents"
                            value="allevents"
                            v-model="editevents"
                            v-bind:style="[
                              editevents == 'allevents'
                                ? { border: '1px solid #5dc2a6 !important' }
                                : { border: '1px solid #D1D3E0 !important' },
                            ]"
                          />
                    <label for="allevents" class="following">All notifications</label><br /> -->
                </div>
            </div>

          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input
          class="button btn_lg btn pink_btn"
          type="submit"
          value="OK"
		  :disabled="seleteeditoption"
          style="
            float: right;
            margin-right: -13px;
            margin-bottom: 13px;
            margin-left: 7px;
          "
          @click="edit"
        />
        <input
          type="button"
          class="button btn_lg btn orange_btn anon-can"
          value="Cancel"
          @click="closeEditconfirmModal"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue/dist/legacy.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    CKEditor,
    VueTimepicker,
    Datepicker,
    //  name:'imageUpload',
  },
  props: ["sitesarr", "templates", "currentObject", "show_sites", "can_notify_all_users", "brand"],
  data() {
    return {
      notification_ids: [],
      myImage: "/images/loading-circle.gif",
      showLoad: false,
      regex: /<div>(.*)<\/div>/g,
      mobile_users_arr: null,
      replaceWith: " ",
      highlightedDates: {
        daysOfMonth: [9, 13],
      },
      isNotValid: true,
      stepTwoValid: false,
      previewImage: "http://portal.cf-staging.co.uk/images/cf-login-logo.png",
      imageName: "",
      datePickerValue: new Date(),
      disabledDates: { to: new Date(Date.now() - 86400000) },
      step: 2,
      done1: false,
      active1: true,
      current1: false,
      done2: false,
      active2: false,
      current2: false,
      done3: false,
      active3: false,
      current3: false,
      templates: [],
      engagementTemplates: [],
      otherTemplates: [],
      senttooptions: [
        { id: "daily", name: "Daily" },
        { id: "weekly", name: "Weekly" },
        { id: "monthly", name: "Monthly" },
      ],
      selectUser: false,
      selectSite: false,
      siteOptions: [],
      select_who: "",
      selected_who: "",
      items: [
        { value: "Multiple app users", name: "Multiple Users" },
        { value: "Single app user", name: "Individual" },
        { value: "All users at site", name: "All Users at Site" },
        {
          value: "All users at organisation",
          name: "All Users at Organisation",
        },
      ],
      initialImageUrl: "",
      form: {
        id: "",
        Title: "",
        CTA: "",
        send_action: "",
        site_id: "",
        Scheduled: "",
        send_immediately: true,
        Message: "",
        image_url: "",
        imageName: "",
        Repeat: "no repeat",
        timezone: "",
        user_id: "",
        scheduleDate: moment(new Date()).format("DD-MM-YY"),
        scheduleTime: "12:00 pm",
      },
      titleEditorConfig: {
        autoParagraph: false,
        height: 60,
        editorplaceholder: "Add title",
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
        autoGrow_maxHeight: 50,
      },
      editorConfig: {
        autoParagraph: false,
        height: 100,
        editorplaceholder: "Add wording",
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      files: [],
      images: [],
      isDragging: false,
      dragCount: 0,
      editevents: '',
      edit_current_id: '',
      items_site: [
        {value: "Multiple app users", name: "Multiple Users"},
        {value: "Single app user", name: "Individual"},
        {value: "All users at site", name: "All Users at Site"}
      ]
    };
  },

  methods: {
    getMobileUsers() {
      return this.$attrs.mobile_users;
    },
	OnDragEnter(e) {
        e.preventDefault();
        this.dragCount++;
        this.isDragging = true;
        return false;
    },
    OnDragLeave(e) {
        e.preventDefault();
        this.dragCount--;
        if (this.dragCount <= 0) this.isDragging = false;
    },
    onInputChange(e) {
        const files = e.target.files;
        Array.from(files).forEach(file => this.addImage(file));
    },
    onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        this.isDragging = false;
	    this.addImage(e);
    },
	addImage(e) {
        const image = e.dataTransfer.files[0];
	    const reader = new FileReader();
        reader.fileName = image.name;
        reader.readAsDataURL(image);
			reader.onload = (e) => {
				this.imageName = e.target.fileName;
				this.form.imageName = e.target.fileName;
				this.form.image_url = e.target.result;
				this.previewImage = e.target.result;
			};
        },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();

      reader.fileName = image.name;

      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.imageName = e.target.fileName;
        this.form.imageName = e.target.fileName;
        this.form.image_url = e.target.result;
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
    onSelectDate(date) {
      this.datePickerValue = date;
      this.form.scheduleDate = moment(date).format("DD-MM-YY");
    },
    goback() {
      if (this.step == 1) {
        return false;
      }
      if (this.step == 2) {
        this.step = 1;
        return true;
      }
      if (this.step == 3) {
        this.step = 2;
        return true;
      }
      if (this.step == 4) {
        this.step = 3;
        return true;
      }
    },
    prev() {
      this.step--;
    },
    isActive(id) {
      if (this.step <= id) {
        if (id == 1 && this.step <= 1) {
          return true;
        }
        return false;
      } else {
        if (this.step == 2) {
          this.done1 = true;
          this.active2 = true;
          this.current2 = true;
          return true;
        }
        if (this.step == 3) {
          this.done2 = true;
          this.active3 = true;
          this.current3 = true;
          return true;
        }
        if (this.step == 4) {
          this.current4 = true;
          this.active4 = true;
          this.done3 = true;
        }

        return true;
      }
    },
    selectedUser(value) {
      this.form.user_id = value;
    },
    selectedSite(value) {
      this.form.site_id = value;
    },
    sendtoOptions() {
      console.log("this.form :: ", this.form);

      if (this.form.send_action == "site") {
        this.selectSite = true;
        this.selectUser = false;
      } else if (this.form.send_action == "user") {
        this.selectUser = true;
        this.selectSite = false;
      } else {
        this.selectUser = false;
        this.selectSite = false;
      }
    },
    edit() {
      this.form.Scheduled =
        this.form.scheduleDate + " " + this.form.scheduleTime;
      console.log("form ::: ", this.form);
      var self = this;
	  self.$refs["edit-notification"].hide();
      self.$refs["modal_edit_confirm"].hide();
	  Vue.swal({
                      title: 'Updating...',
                      html: '',
                      allowOutsideClick: false,
                      onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });
	  let editType = self.editevents;
      axios
        .post("/dashboard/notifications/update/notification", {
          data: self.form,
          notification_ids: self.notification_ids,
		  editType : editType,
        })
        .then(function (response) {
          if (response.status == 200) {
            self.$root.$emit("notificationCreated");
            self.resetForm();
            self.$refs["edit-notification"].hide();
            self.$refs["modal_edit_confirm"].hide();
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Notification is Edited',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          } else {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> Could not Edit Notification!',
              confirmButtonColor: "#ea2020",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#ea2020",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }

          // location.reload();
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log("Error Adding New Notification - error: ", error);
        });
		self.editevents='';
    },
    removeImage() {
      this.imageName = "";
      this.form.image_url = this.initialImageUrl;
      this.form.imageName = "";
      this.previewImage =
        "http://portal.cf-staging.co.uk/images/cf-login-logo.png";
    },
    resetForm() {
      (this.datePickerValue = new Date()),
        (this.form = {
          Title: "",
          CTA: "",
          send_action: "",
          site_id: "",
          Scheduled: "",
          send_immediately: true,
          Message: "",
          image_url: "",
          imageName: "",
          Repeat: "no repeat",
          timezone: "",
          user_id: "",
          scheduleDate: moment(new Date()).format("DD-MM-YY"),
          scheduleTime: "12:00 pm",
        });
      this.imageName = "";
      this.initialImageUrl = "";
      this.done1 = false;
      this.done2 = false;
      this.done3 = false;
      this.done4 = false;
      this.current1 = true;
      this.current2 = false;
      this.current3 = false;
      this.current4 = false;
      this.step = 1;
    },
    getTemplates() {
      return {
        engagement: [
          {
            id: 1,
            title: "title_1",
            message:
              "message 1 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.",
            image_url: "https://picsum.photos/id/237/200/300",
            category: "engagement",
          },
          {
            id: 2,
            title: "title_1",
            message:
              "message 2 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.",
            image_url: "https://picsum.photos/id/237/200/300",
            category: "engagement",
          },
          {
            id: 3,
            title: "title_1",
            message:
              "message 3 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.",
            image_url: "https://picsum.photos/id/237/200/300",
            category: "engagement",
          },
          {
            id: 4,
            title: "title_1",
            message:
              "message 4 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.",
            image_url: "https://picsum.photos/id/237/200/300",
            category: "engagement",
          },
        ],
        others: [
          {
            id: 5,
            title: "title_1",
            message:
              "message 5 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.",
            image_url: "https://picsum.photos/id/237/200/300",
            category: "others",
          },
          {
            id: 6,
            title: "title_1",
            message:
              "message 6 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.",
            image_url: "https://picsum.photos/id/237/200/300",
            category: "others",
          },
          {
            id: 7,
            title: "title_1",
            message:
              "message 7 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.",
            image_url: "https://picsum.photos/id/237/200/300",
            category: "others",
          },
        ],
      };
    },
    setTemplate(rowIndex, index, category) {
      // let templates = [...this.templates];
      let val = "";
      if (category == "engagement") {
        val = this.engagementTemplates[rowIndex][index];
      } else {
        val = this.otherTemplates[rowIndex][index];
      }
      this.form.Title = val.title;
      this.form.Message = val.message;
      this.form.image_url = val.image_url;
      this.initialImageUrl = val.image_url;
      this.imageName = "";
      this.form.imageName = "";
      this.done1 = true;
      this.step = 2;
    },
    getMobileUsersForMultipleApp() {
      let mobileUsersArr = [];
      var that = this;

      axios.get("get/registered-mobile-users").then((response) => {
        console.log("response ::", response.data);
        that.mobile_users_arr = response.data.activeAppUsers;
      });
    },
    multiSelectedUser: function (valueArr) {
      this.form.user_id = valueArr.length > 0 ? valueArr : "";
    },
    closeModal() {
      this.$refs["edit-notification"].hide();
    },
    sendPreview() {
      var self = this;
      let form = {
        title: this.form.Title,
        message: this.form.Message,
        image: this.form.image_url,
        imageName: this.form.imageName,
      };
      axios
        .post("/dashboard/notifications/send/preview", form)
        .then(function (response) {
          if (response.status == 200) {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Preview Sent',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          } else {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> Could not Sent',
              confirmButtonColor: "#ea2020",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#ea2020",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }

          // location.reload();
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log("Error Sending Preview - error: ", error);
        });
    },
    getSameNotifications(id, startDate) {
      var self = this;
	  self.form.id = id;
      self.step = 2;
      self.showLoad = true;
      axios
        .post("/dashboard/notification/get/same/notifications", {
          id: id,
          start_date: startDate
        })
        .then(function (response) {
          if (response.status == 200) {
            console.log("same notifications data : ", response.data);
            console.log(self.currentObject.Repeat);
            console.log(response.data.user_ids);
            self.form.site_id = response.data.site_id;
            self.form.send_action = response.data.send_action;
            self.form.Repeat = self.currentObject.Repeat
            self.form.image_url = self.currentObject.image_url
            self.initialImageUrl = self.currentObject.image_url
            self.form.user_id = response.data.user_ids;
            self.form.Title = self.currentObject.Title
            self.form.Message = self.currentObject.Message
            self.form.send_immediately = false;
            self.form.scheduleDate = moment
              .utc(response.data.Scheduled, "YYYY-MM-DD hh:mm")
              .local()
              .format("DD-MM-YY");
            self.form.start_date = response.data.Scheduled;
            self.form.scheduleTime = moment
              .utc(response.data.Scheduled, "YYYY-MM-DD hh:mm")
              .local()
              .format("hh:mm a");
            self.notification_ids = response.data.notification_ids;
            self.datePickerValue = new Date(response.data.Scheduled);
            self.showLoad = false;
          } else {
            self.showLoad = false;
          }

          // location.reload();
        })
        .catch(function (error) {
          // handle error
          self.showLoad = false;
          self.message = "Error!";
          console.log("Error editing New Notification - error: ", error);
        });
    },
	showEditConfirmModal(id) {
   if (this.currentObject.Repeat == this.form.Repeat && this.currentObject.Repeat != 'no repeat') {
     this.$bvModal.show("modal_edit_confirm");
   } else {
    this.editevents = this.currentObject.Repeat != 'no repeat' ? 'following' : 'currentevent';
    this.edit();
   };
      
    },
	closeEditconfirmModal() {
      this.$bvModal.hide("modal_edit_confirm");
      this.$bvModal.hide("edit-notification-modal");
    },
  },
  filters: {},
  mounted() {
    //called event when modal is about to show/visible
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "edit-notification-modal") {
        this.getMobileUsersForMultipleApp();
      }
    });

    this.form.timezone = new Date().getTimezoneOffset();
    $("#filter-date-picker").val(moment(new Date()).format("DD-MM-YY hh:mm a"));

    let engagementTemplates = [];
    if (this.templates["engagement"]) {
      engagementTemplates = [...this.templates["engagement"]];
    } else {
    }
    let arr = [];
    let pushArr = [];
    engagementTemplates.map((template, index) => {
      if (index % 2 == 0 && index != 0) {
        arr.push(pushArr);
        pushArr = [];
        pushArr.push(template);
      } else {
        pushArr.push(template);
      }
    });
    pushArr.length > 0 ? arr.push(pushArr) : null;
    arr.length > 0 ? (this.engagementTemplates = [...arr]) : null;
    //for others
    let otherTemplates = [];
    if (this.templates["others"]) {
      otherTemplates = [...this.templates["others"]];
    } else {
    }
    arr = [];
    pushArr = [];
    otherTemplates.map((template, index) => {
      if (index % 2 == 0 && index != 0) {
        arr.push(pushArr);
        pushArr = [];
        pushArr.push(template);
      } else {
        pushArr.push(template);
      }
    });

    pushArr.length > 0 ? arr.push(pushArr) : null;
    arr.length > 0 ? (this.otherTemplates = [...arr]) : null;
    console.log("otherTemplates.length :: ", this.otherTemplates);
    //

    this.$root.$on("selectedUser", this.form.user_id);
    if (this.sitesarr.length > 0) {
      for (var i = 0; i < this.sitesarr.length; i++) {
        let x = new Object();
        x.id = this.sitesarr[i].id;
        x.name = this.sitesarr[i].name;
        this.siteOptions.push(x);
      }
    } else if (this.sitesarr) {
      let x = new Object();
      x.id = this.sitesarr.id;
      x.name = this.sitesarr.name;
      this.siteOptions.push(x);
    }
  },
  computed: {
    validateFields() {
      if (this.form.Title && this.form.Message && this.form.send_action) {
        if (
          (this.form.send_action === "All users at site" &&
            this.form.site_id) ||
          (this.form.send_action === "Single app user" && this.form.user_id) ||
          (this.form.send_action === "Multiple app users" &&
            this.form.user_id) ||
          this.form.send_action === "All users at organisation"
        ) {
          return (this.isNotValid = true);
        } else {
          return (this.isNotValid = false);
        }
      } else {
        return (this.isNotValid = false);
      }
    },
    validateStepTwo() {
      if (this.form.Title && this.form.Message) {
        return (this.stepTwoValid = true);
      } else {
        return (this.stepTwoValid = false);
      }
    },
	seleteeditoption: function () {
      if (this.editevents) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    currentObject: function (newVal, oldVal) {
      // watch it
      this.getSameNotifications(newVal.id, newVal.start_date);
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-tagged {
  float: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  flex-basis: 100%;
  flex-grow: 1;
}
.tag-right {
  margin-right: 5px;
}
#Message .cke_chrome,
#cke_editor1 .cke_chrome {
  border: 1px solid #dcdee6 !important;
}

#Message .cke_top,
#cke_editor1 .cke_top {
  border-bottom: none !important;
}

.date-time-modal-styling {
  display: flex;
  margin-bottom: 10px;
  // margin-top: -33px !important;
}

.datetime-picker-modal {
  width: 180px;
  margin: 0 8px 0 0;
  background-color: #ffffff;
}

.datetime-time-modal {
  width: 180px;
  margin: 0 8px 0 0;
  background-color: #ffffff;
}

.modal-content {
  width: 800px;
}

.modal-content {
  width: 743px !important;
  height: 799px !important;
  overflow-y: scroll;
}

#text-box .vs_dropdown-menu,
.modal .vs_dropdown-menu {
  min-width: 1000px !important;
  width: 100% !important;
}

#vs8_listbox {
  min-width: 1000px !important;
}
.modal-body .input-box {
  float: left;
  width: 100%;
  margin-bottom: 10px !important;
  /* height: 94px; */
  margin-top: -16px !important;
}
.form-group.input-sendto {
  margin-bottom: 12px;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 510%;
  outline: none;
  // box-shadow: 0 0 5px 0px green inset;
  vertical-align: middle;
}

input[type="radio"]::before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}


input[type="radio"]:checked:before {
  background: #5dc2a6 !important;
}
label.following{
    margin-bottom: 2px;
    font-weight: 600;
	cursor: pointer;
	vertical-align: top;
}
[type="radio"]{
    margin: 0 0 0 !important;
}
.upload_noti{
  text-decoration: underline;
  color: #f24c6d;
}
</style>
