<template>
<div class="upload-profile">
  <div class="upload-logo grid-x grid-padding-x" v-if="design=='box-upload'">
  
      <div
        class="uploader"
        @dragenter="OnDragEnter"
        @dragleave="OnDragLeave"
        @dragover.prevent
        @drop="onDrop"
        :class="{ dragging: isDragging }"
      >
        <!--<div v-show="!images.length">-->
          <img src="/images/group-10.png">
          <p>
            Drag and drop an image or
            <input
              data-button-text="browse"
              type="file"
			  style="display:none"
              id="file"
			  ref="fileInput"
              @change="onInputChange"
              value="Browse"
            /> <a href="javascript:void(0)" @click="$refs.fileInput.click()" id="uploadTrigger">browse</a> to choose a file. Minimum size 300 x 300 px.
          </p>
        <!--</div>-->
      </div>
     <div style="clear:both"></div>
     
    
    <div class="image-drop-box" id="companypreview">
      <div class="single-mobile-screen" >
       
		<div class="images-preview" v-if="!images.length">
		 <div class="img-wrapper" v-if="customer.logo">
		  <div class="img-wrapper" v-if="showimg === true">
		    <div v-on:click="removeimg();">
		    <i aria-hidden="true" class="fa fa-close  fa-lg "></i>
			</div>
            <img :src="`/storage/${customer.logo}`"></img>
		
          </div>
		 </div>
        </div>
		
        <div class="images-preview" v-else>
          <div class="img-wrapper"  v-for="(image, index) in images" :key="index">
		  
		   <div v-if="index == (images.length-1)">
		    <div v-on:click="removeimg();">
		    <i aria-hidden="true" class="fa fa-close  fa-lg "></i>
			</div>
			
            <img :src="image"  />
			</div>
          </div>
        </div>
      </div>
	  </div>

  </div>
      <div v-else-if="design == 'btn-upload'" class="btn-upload">
      <div class="input-box">
        <label class="label-dark" style="padding-bottom:30px;">Logo</label>
		
        <div class="orgination-logo-box" v-if="avatorshow==2" style="background: #FFFFFF;background-position: center;
background-size: cover;" :style="{backgroundImage: `url(/storage/${customer.logo})`}">		    	
        </div>
		
		<div class="orgination-logo-box"  style="background: #FFFFFF;background-position: center;
background-size: cover;"  v-if="avatorshow==1" :style="{backgroundImage: `url(${images[images.length-1]})`}">
        </div>
        
          
        <div v-if="avatorshow==3" class="orgination-logo-box"><img src="/images/icon-24-no-logo.png" style="padding-top: 24px; padding-left: 24px;"  /></div>
        

        <p class="btn-upload-logo" v-if="avatorshow==3">
          <input
            data-button-text="browse"
            type="file"
            style="display:none"
            id="file"
            ref="fileInput"
            @change="onInputChange"
            value="Browse"
          />
		  
          <button
            href="javascript:void(0)"
            @click="$refs.fileInput.click()"
            id="uploadTrigger"
            class="btn btn-orange btn-lg"
          >CHOOSE FILE</button>
		  
        </p>
		<p class="btn-upload-pic" v-else>
          <input
            data-button-text="browse"
            type="file"
            style="display:none"
            id="file"
            ref="fileInput"
            @change="onInputChange"
            value="Browse"
          />
		  <button
            href="javascript:void(0)"
            @click="$refs.fileInput.click()"
            id="uploadTrigger"
            class="btn btn-orange btn-lg"
          >UPLOAD NEW IMAGE</button>
        </p>

        <div v-if="deletebtnshow">
          <div class="delete_icon" v-on:click="removeimg();">
            <i aria-hidden="true" class="far fa-trash-alt"></i>
          </div>
        </div>
		<p class="org-logo-text">JPEG or PNG. Square format e.g. 300x300px</p>
      </div>
	  
    </div>
  
 </div>
</template>

<script>
export default {
  props: ['customer',"design"],
  data: () => ({
    deleted: false,
    showimg: true,
    isDragging: false,
    dragCount: 0,
    files: [],
    images: []
  }),
  methods: {
    OnDragEnter(e) {
      e.preventDefault();

      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onInputChange(e) {
      const files = e.target.files;
      Array.from(files).forEach(file => this.addImage(file));
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      Array.from(files).forEach(file => this.addImage(file));
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        this.$toastr.e(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const img = new Image(),
        reader = new FileReader();
      reader.onload = e => this.images.push(e.target.result);
      reader.readAsDataURL(file);
	  
	  
	  const formData = new FormData();

      this.files.forEach(file => {
        formData.append("images[]", file, file.name);
      });
        Vue.swal({
                      title: 'Uploading...',
                      html: '',
                      allowOutsideClick: false,
                      onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });
      //   @todo url
      axios
        .post("/dashboard/upload-logo", formData)
        .then(response => {
		  Vue.swal({
                            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> logo uploaded successfully',
                            confirmButtonColor: '#5dc2a6',
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: '#5dc2a6',
                            customClass: {
                    		    container: 'company-toast',
                    		  },
                    		 showClass: {
							  popup: "animated fadeInLeft fast swal2-margin-top",
							  icon: "animated heartBeat delay-1s"
							},
							hideClass: {
							  popup: "animated fadeOutLeft slow swal2-margin-top"
							},
                    		toast: true,
                            timer: 5000,
                            position: 'top-right',
                            target: '#example-tabs',
                        });
						this.deleted = true;
          this.customer.logo = response.data.logo;
          console.log(response.data.logo);
		      this.$root.$emit("EditCompany::Success", response.data);
           console.log("Company updated sucessfully. ",response.data.logo );
        });
	  
	  
	  
    },
    getFileSize(size) {
      const fSExt = ["Bytes", "KB", "MB", "GB"];
      let i = 0;

      while (size > 900) {
        size /= 1024;
        i++;
      }
      return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
    },
    upload() {
      const formData = new FormData();

      this.files.forEach(file => {
        formData.append("images[]", file, file.name);
      });
        Vue.swal({
                      title: 'Uploading...',
                      html: '',
                      allowOutsideClick: false,
                      onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });
      //   @todo url
      axios
        .post("/dashboard/upload-logo", formData)
        .then(response => {
		  Vue.swal({
                            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Logo updated successfully',
                            confirmButtonColor: '#5dc2a6',
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: '#5dc2a6',
                            customClass: {
                    		    container: 'company-toast',
                    		  },
                    		showClass: {
                                popup: 'animated fadeInLeft fast',
                                icon: 'animated heartBeat delay-1s'
                              },
                              hideClass: {
                                popup: 'animated fadeOutLeft slow',
                              },
                    		toast: true,
                            timer: 5000,
                            position: 'top-right',
                            target: '#example-tabs',
                        });
          
          
        });
    },
	removeimg() {
      this.images.push('');
	  this.showimg=false;
        Vue.swal({
                      title: 'Deleting...',
                      html: '',
                      allowOutsideClick: false,
                      onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });
      //   @todo url
      axios
        .post("/dashboard/delete-logo", '')
        .then(response => {
		  Vue.swal({
                            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Logo deleted.',
                            confirmButtonColor: '#5dc2a6',
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: '#5dc2a6',
                            customClass: {
                    		    container: 'company-toast',
                    		  },
                    		showClass: {
                                popup: 'animated fadeInLeft fast',
                                icon: 'animated heartBeat delay-1s'
                              },
                              hideClass: {
                                popup: 'animated fadeOutLeft slow',
                              },
                    		toast: true,
                            timer: 5000,
                            position: 'top-right',
                            target: '#example-tabs',
                        });
						
          this.showimg=false;
          this.images = [];
          this.files = [];
		  this.customer.logo ='';
        });
    }
  },
  computed: {
    btnDisabled: function() {
      if (this.images.length > 0) {
        return false;
      } else return true;
    },

    deletebtnshow() {
      if (this.images.length > 0 || this.customer.logo) {
        return true;
      } else return false;
    },
	avatorshow(){
      if(this.images.length>0){
        return 1;
      }else if(this.customer.logo !=''){
        return 2;
      }else return 3;
    },
  }
};
</script>

<style lang="scss" scoped>
.uploader {
   float: left;
    background: #f9fafb;
    border-radius: 8px;
    border: dashed 1px #dadfe8 !important;
    height: auto;
    padding: 15px;
    margin-bottom: 20px;
	width:100%;
	margin-left: 10px;
    margin-top: -14px;
  p {
    font: 400 12px/19px "InterUI", sans-serif;
    overflow: hidden;
    color: #656a85;
  }
  p a {
    text-decoration: underline;
    color: #f24c6d;
  }
  img {
    float: left;
    margin-right: 15px;
   }
  &.dragging {
    background: #fff;
    color: #2196f3;
    border: 3px dashed #2196f3;
    .file-input label {
      background: #2196f3;
      color: #fff;
    }
  }
  i {
    font-size: 85px;
  }
  .file-input {
    width: 200px;
    margin: auto;
    height: 68px;
    position: relative;
    label,
    input {
      background: #fff;
      color: #2196f3;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 10px;
      border-radius: 4px;
      margin-top: 7px;
      cursor: pointer;
    }
    input {
      opacity: 0;
      z-index: -2;
    }
  }
  .images-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .img-wrapper {
      margin: 10px;
      img {
        margin: 0;
    width: 100%;
    height: auto;
      }
    }
    .details {
      font-size: 12px;
      background: #fff;
      color: #000;
      display: flex;
      flex-direction: column;
      align-items: self-start;
      padding: 3px 6px;
      .name {
        overflow: hidden;
        height: 18px;
      }
    }
  }
  .upload-control {
    position: absolute;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px;
    padding-bottom: 4px;
    text-align: right;
    margin-top: 30px;
  }
}
.delete_icon{
    cursor: pointer;
}
</style>