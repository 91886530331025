<template>
    
    <div class="bonus-points-history-table">
        <!-- <div class="cell medium-5">
            <input class="jobs-search" placeholder="Search" v-model="searchVar" name="searchVar"
                @keyup.delete="searchBonusData()" @keyup="searchBonus()">
        </div> -->
	    <div class="table-top-area">
	       <div class="grid-x">
                <div class="cell small-12">
                    <div class="cell small-6 pull-left bonus-history-top">
                    <div class="tot-num-bonus">1340</div>
                    <div class="total-points-bonus">Total Events</div>
                    </div>
                    <div class="cell small-6 pull-right bonus-history-top">

                    <div class="cell shrink"><a href="javascript:void(0)" @click="filtertoggle()" class="btn button orange_btn float-right bonus-filter-cls"> FILTERS <img src="/images/filter-icon.png" alt="img" /></a></div>
                    <div class="cell shrink"><a :href="'/dashboard/bonuspointexport?allcat_to[]='+this.awardId+'&allcat_by[]='+userAllocatedBy+'&cat_id[]='+caregoryId+'&points[]='+pointsId+'&start_date='+filterStartDate+'&end_date='+filterEndDate" class="btn button orange_btn float-right"> EXPORT </a></div>
                    </div>
                </div>
			</div>

	        <div v-show="showFilter">
			 <div class="grid-x filters-wrappers">
		       <div class="cell small-2 padright" >
                    <div class="input-box">
					<label></label>
			        <date-range-picker
						v-model="dateRange"
						id="date-range-filter"
						ref="dateRangeFilter"

						opens="right"
						class="phone-recruiter"
						@keydown.enter='addTag'
						@keydown.188='addTag'
                        @select="addTag"
						@keydown.delete='removeLastTag'
					>
			         <!--    input slot (new slot syntax)-->
                    <template #input="picker"> <label class="datelabelclass">Date</label></template>

			        </date-range-picker>

	                </div>

	            </div>


                <div class="cell small-2 padright" >
                    <div class="input-box">
					  <v-select
					      class="phone-recruiter"
					      id="awardedto-filter"
					      ref='awardedtoFilter'
					      @input="selectedAwardTo(data.allocated_to)"
    					  v-model="data.allocated_to"
    					  :reduce="referrer => referrer.id"
    					  label="name"
    					  :options="allocated_to_user"
    					  :close-on-select="true"
    					  multiple
						  taggable
    					  placeholder="Awarded To"
    					>
                          <template slot="option" slot-scope="option">
                              {{ option.name }}
                              <img style="float: right" :src="option.image"/>
                          </template>
						</v-select>
			        </div>
               </div>

			   <div class="cell small-2 padright" >
                    <div class="input-box">
					  <v-select
					      id="cat-filter"
					      ref="categoriesFilter"
						  class="phone-recruiter"
						  @input="selectedCategory(data.reward_catagory_name)"
					  v-model="data.reward_catagory_name"
					  :reduce="referrer => referrer.id"
					  label="name"
					  :options="cat_arr"
					  :close-on-select="true"
					  multiple
					  taggable
					  placeholder="Category"
					>
					<template slot="option" slot-scope="option">
                              {{ option.name }}
                              <img style="float: right" :src="option.image"/>
                    </template>
					</v-select>
			        </div>
               </div>



			    <div class="cell small-2 padright">
                    <div class="input-box">
					  <v-select
					      id="awardedby-filter"
					      ref="awardedbyFilter"
						  class="phone-recruiter"
						  @input="selectedAllocatedBy(data.allocated_by)"
					  v-model="data.allocated_by"
					  :reduce="referrer => referrer.id"
					  label="name"
					  :options="allocated_by_user"
					  :close-on-select="true"
					  multiple
					  taggable
					  placeholder="Awarded By"
					>
					<template slot="option" slot-scope="option">
                        {{ option.name }}
                        <img style="float: right" :src="option.image"/>
                    </template>
					</v-select>
			        </div>
               </div>
			 </div>

			 <!--for displaying tags -->
			 <div class="grid-x" v-if="selected_date_tags != ''">
			    <div class=" tag-input__tag">
				 <span class="lablesize">{{selected_date_tags}}</span>
				 <span class="deselect-pad"><button class="deselect" @click="removeDateTags()"></button></span>

				</div>
			 </div>
			 <div class="grid-x" v-if="selected_awardedto_tags != ''">
			    <div class=" tag-input__tag">
				 <span class="lablesize">{{selected_awardedto_tags}}</span>
				 <span class="deselect-pad"><button class="deselect" @click="removeAwardedToTags()"></button></span>

				</div>
			 </div>
			 <div class="grid-x" v-if="selected_categories_tags != ''">
			    <div class=" tag-input__tag">
				 <span class="lablesize">{{selected_categories_tags}}</span>
				 <span class="deselect-pad"><button class="deselect" @click="removeCategoriesTags()"></button></span>

				</div>
			 </div>
			 <div class="grid-x" v-if="selected_awardedby_tags != ''">
			    <div class=" tag-input__tag">
				 <span class="lablesize">{{selected_awardedby_tags}}</span>
				 <span class="deselect-pad"><button class="deselect" @click="removeAwardedByTags()"></button></span>

				</div>
			 </div>

            </div>

	   </div>





        <div class="cf-table">
            <!-- BONUS POINT HISTORY -->
            <div class="grid-x grid-padding-x">
                <div class="cell"></div>
                <div class="cell">

                </div>
            </div>
            <!-- LEADERBOARD HEADER-->
            <div class="grid-x grid-padding-x cf-table-head t_margin_1 align-middle">
                <div @click="sort('created_at')" class="cell auto grey" >
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': dateActive}">Date <i v-bind:class="{ 'fa fa-sort': sortboth, 'fa fa-sort-asc': created_atsortasc, 'fa fa-sort-desc': created_atsortdesc }"></i></strong></p>
                </div>
                <div @click="sort('allocated_to')" class="cell auto grey">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': toActive}">Awarded to <i  v-bind:class="{ 'fa fa-sort': sortboth, 'fa fa-sort-asc': allocated_to_sortasc, 'fa fa-sort-desc': allocated_to_sortdesc }"></i></strong></p>
                </div>
                <div @click="sort('points_no')" class="cell auto grey" align="center">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': pointActive}">Amount <i v-bind:class="{ 'fa fa-sort': sortboth, 'fa fa-sort-asc': points_no_sortasc, 'fa fa-sort-desc': points_no_sortdesc }"></i></strong></p>
                </div>

                <div @click="sort('reward_catagory_name')" class="cell auto grey category" align="center">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': catActive}">Category <i v-bind:class="{ 'fa fa-sort': sortboth, 'fa fa-sort-asc': reward_catagory_name_sortasc, 'fa fa-sort-desc': reward_catagory_name_sortdesc }"></i></strong></p>
                </div>
                <div @click="sort('allocated_by')" class="cell auto grey" align="center">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': byActive}">Awarded by <i v-bind:class="{ 'fa fa-sort': sortboth, 'fa fa-sort-asc': allocated_by_sortasc, 'fa fa-sort-desc': allocated_by_sortdesc }"></i></strong></p>
                </div>
                <div @click="sort('reason_for_allocation')" class="cell auto grey" align="center">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': msgActive}">Message<i v-bind:class="{ 'fa fa-sort': sortboth, 'fa fa-sort-asc': reason_for_allocation_sortasc, 'fa fa-sort-desc': reason_for_allocation_sortdesc }"></i></strong></p>
                </div>
            </div>

			<div v-show="showLoad" class="show-load-app">
              <img :src="myImage">
            </div>

            <div v-for="(transaction, index) in userDatas">
                <div class="grid-x grid-padding-x align-middle b_border_b1" v-if="pointdataloading" >
                    <div class="cell auto" style="width: 5%">
                        <p class="tb_margin_1">{{ transaction.created_at| moment("DD/MM/YYYY") }}</p>
                    </div>
                    <div class="cell auto">
                        <p class="tb_margin_1 cursor-custom" v-b-modal="'modal-' + index"><strong class="user-clickable">{{ transaction.allocated_to }}</strong></p>
                    </div>
                    <div class="cell auto" align="center">
                        <p class="tb_margin_1">{{ transaction.event_data.points_no }}</p>
                    </div>

                    <div class="cell auto" align="center">
                        <p class="tb_margin_1">{{ transaction.reward_catagory_name }}</p>
                    </div>
                    <div class="cell auto" align="center">
                        <p class="tb_margin_1">{{ transaction.allocated_by }}</p>
                    </div>
                    <div class="cell auto" align="center">

                        <b-tooltip :target="'tool-'+index"><div v-html="transaction.event_data.reason_for_allocation"></div></b-tooltip>
                        <div :id="'tool-'+index" variant="outline-success" class="bonus-points-view-message">
                            {{ getPostBody(transaction.event_data.reason_for_allocation) }}
                        </div>
                    </div>
                </div>

				<b-modal :id="'modal-' + index" size="xl" hide-footer>
                            <view-bonus-component :user="transaction" :taxName="taxName"></view-bonus-component>

                        </b-modal>

            </div>

            <div class="pagination-container margin-top-2 test-2">
                <template v-if="historyPaginationData.last_page > 1">
                    <paginate
                        :page-count="historyPaginationData.last_page"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="loadBonusPoints"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :prev-class="'paginate-controls'"
                        :next-class="'paginate-controls'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :active-class="'active'">
                    </paginate>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import Paginate from "vuejs-paginate";
    import DateRangePicker from 'vue2-daterange-picker'
    //you need to import the CSS manually (in case you want to override it)
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        components: {
            paginate: Paginate,
			DateRangePicker :DateRangePicker,
        },
        props: ['link', 'taxName'],
        data(){
            return {
                // userDatas: [],
				 tags: [],
				dateRange: {
				  startDate: '',
				  endDate: '',
				},
                userDatas: this.userDatas,
                fetchUserPaginationData: [],
                fetchUserData: [],
                currentSort:'created_at',
                currentSortDir:'SORT_DESC',
                created_atsortasc : false,
                created_atsortdesc : true,
                loading: false,
                pointdataloading: false,
                sortboth:true,

                allocated_to_sortasc: false,
                allocated_to_sortdesc: false,
                points_no_sortasc: false,
                points_no_sortdesc: false,
                reason_for_allocation_sortasc: false,
                reason_for_allocation_sortdesc: false,
                reward_catagory_name_sortasc: false,
                reward_catagory_name_sortdesc: false,
                allocated_by_sortasc: false,
                allocated_by_sortdesc: false,
                searchVar: '',
				 val: 0,

        userCount: 0,
        userAllocatedBy: [],
		awardId: [],
		caregoryId: [],
		pointsId: [],
		test:'',
		dateActive: false,
		toActive: false,
		pointActive:false,
		byActive:false,
		catActive:false,
		msgActive:false,
		data: {
				allocated_to: "",
				points_no: "",
				reward_catagory_name: "",
				allocated_by: ""
			  },

		 allocated_by_user: this.allocatedByArr,
		 allocated_to_user: this.allocatedToArr,
		 cat_arr: this.cat_arr,
		 points_arr: this.points_arr,
		 showLoad: false,
         myImage: '/images/loading-circle.gif',
		 showFilter: false,
		 filterStartDate : '',
		 filterEndDate : '',
		 total_points:0,
		 ranges: {
            default () {
              let today = new Date()
              today.setHours(0, 0, 0, 0)

              let yesterday = new Date()
              yesterday.setDate(today.getDate() - 1)
              yesterday.setHours(0, 0, 0, 0);

              let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
              let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

              return {
                'Today': [today, today],
                'Yesterday': [yesterday, yesterday],
    			'This week': [yesterday, yesterday],
    			'Last week': [yesterday, yesterday],
                'This month': [thisMonthStart, thisMonthEnd],
                'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
              }
            }
          },
                // Handling tabs pagination in Vuejs way
                historyPaginationData: {
                    current_page: 0,
                    from: 0,
                    last_page: 0,
                    per_page: 0,
                    to: 0,
                    total: 0,
                },

                selected_awardedto_tags: '',
                selected_categories_tags: '',
                selected_awardedby_tags:'',
                selected_date_tags: '',
            }
        },

        methods: {
		 /*dateFormat (classes, date) {
			if (!classes.disabled) {
			  classes.disabled = date.getTime() < new Date()
			}
			return classes
		  },*/

		 removeDateTags: function(){
            this.removeTag(0);
            //this.selected_date_tags = '';
         },
		 removeAwardedToTags: function(){
            this.$refs.awardedtoFilter.clearSelection();
            this.selected_awardedto_tags = '';
             if (this.allocated_to_user) {
                 for (i = 0; i < this.allocated_to_user.length; i++) {
                     if (this.allocated_to_user[i].image == '/images/selected.png') {
                         this.allocated_to_user[i].image = '/images/idle.png';
                     }
                 }
             }
         },
         removeCategoriesTags: function(){
            this.$refs.categoriesFilter.clearSelection();
            this.selected_categories_tags = '';
			if (this.allocated_to_user) {
                 for (i = 0; i < this.cat_arr.length; i++) {
                     if (this.cat_arr[i].image == '/images/selected.png') {
                         this.cat_arr[i].image = '/images/idle.png';
                     }
                 }
             }
         },
         removeAwardedByTags: function(){
            this.$refs.awardedbyFilter.clearSelection();
            this.selected_awardedby_tags = '';
			if (this.allocated_by_user) {
                 for (i = 0; i < this.allocated_by_user.length; i++) {
                     if (this.allocated_by_user[i].image == '/images/selected.png') {
                         this.allocated_by_user[i].image = '/images/idle.png';
                     }
                 }
             }
         },
		 selectedAwardTo: function(value) {
		   this.awardId = value;
   
          if (this.userCount > 0 && this.awardId.length > 0 && this.awardId.length < this.userCount) {
              if(this.awardId.length % 2 == 0) {
                  this.val -= 4;
              }
              this.userCount = this.awardId.length;
          } else if (this.awardId.length == 0) {
              this.val = 0;
              this.userCount = 0;
          } else if (this.awardId) {
              if(this.awardId.length % 2 == 0) {
                  this.val += 4;
              }
              this.userCount = this.awardId.length;
          }

            /**
             * Code to managed selected tags for Awarded To
             */
            let selected_awardedto = [];
            let i;
            for (i = 0; i < this.allocated_to_user.length; ++i) {
                if($.inArray( this.allocated_to_user[i].id, this.awardId ) !== -1){
                    this.allocated_to_user[i].image = '/images/selected.png';
                    selected_awardedto.push(this.allocated_to_user[i].name);
                }
            }
            if(selected_awardedto.length > 0){
                this.selected_awardedto_tags = 'Awarded To: ' + selected_awardedto.join(", ");
            }
			
            /**
             * Ends Code to managed selected tags for Awarded To
             */

		 this.loadBonusPoints();
		  },

		 selectedCategory: function(value) {
          this.caregoryId = value;

          if (this.userCount > 0 && this.caregoryId.length > 0 && this.caregoryId.length < this.userCount) {
              if(this.caregoryId.length % 2 == 0) {
                  this.val -= 4;
              }
              this.userCount = this.caregoryId.length;
          } else if (this.caregoryId.length == 0) {
              this.val = 0;
              this.userCount = 0;
          } else if (this.caregoryId) {
              if(this.caregoryId.length % 2 == 0) {
                  this.val += 4;
              }
              this.userCount = this.caregoryId.length;
          }

            /**
             * Code to managed selected tags for Category
             */
            let selected_cats = [];
            let i;
            for (i = 0; i < this.cat_arr.length; ++i) {
                if($.inArray( this.cat_arr[i].id, this.caregoryId ) !== -1){
                    //console.log('selected name: ', this.cat_arr[i].name);
					this.cat_arr[i].image = '/images/selected.png';
                    selected_cats.push(this.cat_arr[i].name);
                }
            }
            if(selected_cats.length > 0){
                this.selected_categories_tags = 'Categories: ' + selected_cats.join(", ");
            }
            /**
             * Ends Code to managed selected tags for Category
             */

		 this.loadBonusPoints();
      },
	  selectedPoints: function(value) {

          this.pointsId = value;
          if (this.userCount > 0 && this.pointsId.length > 0 && this.pointsId.length < this.userCount) {
              if(this.pointsId.length % 2 == 0) {
                  this.val -= 4;
              }
              this.userCount = this.pointsId.length;
          } else if (this.pointsId.length == 0) {
              this.val = 0;
              this.userCount = 0;
          } else if (this.pointsId) {
              if(this.pointsId.length % 2 == 0) {
                  this.val += 4;
              }
              this.userCount = this.pointsId.length;
          }
		 this.loadBonusPoints();
      },
	  selectedAllocatedBy: function(value) {

          this.userAllocatedBy = value;
		  if(this.userAllocatedBy.length >0){
		   var pad = 30*this.userAllocatedBy.length+"px";
		   this.allocatedbystyles = {'padding-bottom': pad};
		   }
          if (this.userCount > 0 && this.userAllocatedBy.length > 0 && this.userAllocatedBy.length < this.userCount) {
              if(this.userAllocatedBy.length % 2 == 0) {
                  this.val -= 4;
              }
              this.userCount = this.userAllocatedBy.length;
          } else if (this.userAllocatedBy.length == 0) {
              this.val = 0;
              this.userCount = 0;
          } else if (this.userAllocatedBy) {
              if(this.userAllocatedBy.length % 2 == 0) {
                  this.val += 4;
              }
              this.userCount = this.userAllocatedBy.length;
          }

            /**
             * Code to managed selected tags for Awarded By
             */
            let selected_awarded_by = [];
            let i;
            for (i = 0; i < this.allocated_by_user.length; ++i) {
                if($.inArray( this.allocated_by_user[i].id, this.userAllocatedBy ) !== -1){
              /*console.log('selected name: ', this.allocated_by_user[i].name);*/
			  /* for checkbox */
			     this.allocated_by_user[i].image = '/images/selected.png';
                 selected_awarded_by.push(this.allocated_by_user[i].name);
                }
            }
            if(selected_awarded_by.length > 0){
                this.selected_awardedby_tags = 'Awarded By: ' + selected_awarded_by.join(", ");
            }
            /**
             * Ends Code to managed selected tags for Awarded By
             */

		 this.loadBonusPoints();
      },
            searchBonus: function () {
                if(this.searchVar.length > 2) {
                    var init = true;
                    this.showLoad=true;
                    this.pointdataloading=false;

                    let searchTerm = '';
                    if(this.searchVar.length > 2) {
                        searchTerm = this.searchVar;
                    }
                    axios.get('/dashboard/points/bonus/search', { params:{
                            'column_name': this.currentSort,
                            'bonus_page': this.getParamsFromUrl('bonus_page', window.location.href),
                            'sort': this.currentSortDir,
                            'searchVar': searchTerm,
                            'siteId': $("#selected-site").val()
                        }}).then((response) => {

                        this.userDatas = response.data.bonusTransactions;
                        if(init) {
                            this.fetchUserData = response.data.bonusTransactions;
                            this.paginationData = response.data.pagination;
                        }
                        this.pagination = response.data.pagination;

                        if (response.data.hasOwnProperty('pagination')) {
                            let paginationArr = response.data.pagination;

                            this.historyPaginationData.current_page = paginationArr.current_page;
                            this.historyPaginationData.from = paginationArr.from;
                            this.historyPaginationData.last_page = paginationArr.last_page;
                            this.historyPaginationData.per_page = paginationArr.per_page;
                            this.historyPaginationData.to = paginationArr.to;
                            this.historyPaginationData.total = paginationArr.total;
                        }

                        this.showLoad=false;
                        this.pointdataloading=true;

                    }).catch((error) => {
                        console.log(error);
                    });
                }
            },
            searchBonusData: function () {
                if(this.searchVar.length <= 2) {
                    this.loadBonusPoints();
                } else {
                    this.searchBonus();
                }
            },
            sort:function(s) { 
                this.currentSort = s;
				 if(s=='created_at'){
				 this.dateActive = true;
				 }else{
				 this.dateActive = false;
				 }
				 if(s=='allocated_to'){
				 this.toActive = true;
				 }else{
				 this.toActive = false;
				 }
				 if(s=='points_no'){
				 this.pointActive = true;
				 }else{
				 this.pointActive = false;
				 }
				 if(s=='reward_catagory_name'){
				 this.catActive = true;
				 }else{
				 this.catActive = false;
				 }
				 if(s=='allocated_by'){
				 this.byActive = true;
				 }else{
				 this.byActive = false;
				 }
				 if(s=='reason_for_allocation'){
				 this.msgActive = true;
				 }else{
				 this.msgActive = false;
				 }

                //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='SORT_ASC'?'SORT_DESC':'SORT_ASC';

                    if(this.currentSort=='created_at'){

                        this.allocated_to_sortasc=false;
                        this.allocated_to_sortdesc=false;
                        this.points_no_sortasc=false;
                        this.points_no_sortdesc=false;
                        this.reason_for_allocation_sortasc=false;
                        this.reason_for_allocation_sortdesc=false;
                        this.reward_catagory_name_sortasc=false;
                        this.reward_catagory_name_sortdesc=false;
                        this.allocated_by_sortasc=false;
                        this.allocated_by_sortdesc=false;

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.created_atsortasc=true;
                            this.created_atsortdesc=false;
                        }
                        else
                        {
                            this.created_atsortasc=false;
                            this.created_atsortdesc=true;
                        }
                    }
                    if(this.currentSort=='allocated_to'){

                        this.created_atsortasc=false;
                        this.created_atsortdesc=false;
                        this.points_no_sortasc=false;
                        this.points_no_sortdesc=false;
                        this.reason_for_allocation_sortasc=false;
                        this.reason_for_allocation_sortdesc=false;
                        this.reward_catagory_name_sortasc=false;
                        this.reward_catagory_name_sortdesc=false;
                        this.allocated_by_sortasc=false;
                        this.allocated_by_sortdesc=false;

                        if(this.currentSortDir=='SORT_ASC')
                        {
                            this.allocated_to_sortasc=true;
                        }
                        else{
                            this.allocated_to_sortasc=false;
                            this.allocated_to_sortdesc=true;
                        }
                    }

                    if(this.currentSort=='points_no'){

                        this.created_atsortasc=false;
                        this.created_atsortdesc=false;
                        this.allocated_to_sortasc=false;
                        this.allocated_to_sortdesc=false;
                        this.reason_for_allocation_sortasc=false;
                        this.reason_for_allocation_sortdesc=false;
                        this.reward_catagory_name_sortasc=false;
                        this.reward_catagory_name_sortdesc=false;
                        this.allocated_by_sortasc=false;
                        this.allocated_by_sortdesc=false;

                        if(this.currentSortDir=='SORT_ASC'){
                            this.points_no_sortasc=true;
                        }
                        else
                        {
                            this.points_no_sortdesc=true;
                            this.points_no_sortasc=false;
                        }
                    }

                    if(this.currentSort=='reason_for_allocation'){

                        this.created_atsortasc=false;
                        this.created_atsortdesc=false;
                        this.allocated_to_sortasc=false;
                        this.allocated_to_sortdesc=false;
                        this.points_no_sortdesc=false;
                        this.points_no_sortasc=false;
                        this.reward_catagory_name_sortasc=false;
                        this.reward_catagory_name_sortdesc=false;
                        this.allocated_by_sortasc=false;
                        this.allocated_by_sortdesc=false;

                        if(this.currentSortDir=='SORT_ASC'){
                            this.reason_for_allocation_sortasc=true;
                        }
                        else{
                            this.reason_for_allocation_sortdesc=true;
                            this.reason_for_allocation_sortasc=false;
                        }
                    }

                    if(this.currentSort=='reward_catagory_name'){
                        this.created_atsortasc=false;
                        this.created_atsortdesc=false;
                        this.allocated_to_sortasc=false;
                        this.allocated_to_sortdesc=false;
                        this.points_no_sortdesc=false;
                        this.points_no_sortasc=false;
                        this.reason_for_allocation_sortasc=false;
                        this.reason_for_allocation_sortdesc=false;
                        this.allocated_by_sortasc=false;
                        this.allocated_by_sortdesc=false;

                        if(this.currentSortDir=='SORT_ASC'){
                            this.reward_catagory_name_sortasc=true;
                        }
                        if(this.currentSortDir=='SORT_DESC'){
                            this.reward_catagory_name_sortdesc=true;
                            this.reward_catagory_name_sortasc=false;
                        }
                    }

                    if(this.currentSort=='allocated_by'){
                        this.created_atsortasc=false;
                        this.created_atsortdesc=false;
                        this.allocated_to_sortasc=false;
                        this.allocated_to_sortdesc=false;
                        this.points_no_sortdesc=false;
                        this.points_no_sortasc=false;
                        this.reason_for_allocation_sortasc=false;
                        this.reason_for_allocation_sortdesc=false;
                        this.reward_catagory_name_sortasc=false;
                        this.reward_catagory_name_sortdesc=false;

                        if(this.currentSortDir=='SORT_ASC'){
                            this.allocated_by_sortasc=true;
                        }
                        if(this.currentSortDir=='SORT_DESC'){
                            this.allocated_by_sortdesc=true;
                            this.allocated_by_sortasc=false;
                        }
                    }

                }

                this.loadBonusPoints();
            },

            showMenu() {
                $(this.$el).foundation();
            },

            /**
             * Send AJAX request to get the bonus points
             */
            loadBonusPoints(page, init) {

                //this.loading=true;
				this.showLoad = true;
                this.pointdataloading=false;

                let searchTerm = '';
				let awarduserid = '';
				let searchAllocatedBy = '';
					let searchcaregoryId = '';
					let searchpointsId = '';
					let searchStartDate = '';
					let searchEndDate = '';
                if(this.searchVar.length > 2) {
                    searchTerm = this.searchVar;
                }
				if(this.awardId.length > 0) {
                    awarduserid = this.awardId;
                }
				if(this.awardId.length > 0) {
                        searchTerm = this.awardId;
                    }
                    if(this.userAllocatedBy.length > 0) {
                        searchAllocatedBy = this.userAllocatedBy;
                    }
				   if(this.caregoryId.length > 0) {
                        searchcaregoryId = this.caregoryId;
                    }
					if(this.pointsId.length > 0) {
                        searchpointsId = this.pointsId;
                    }
					if(this.filterStartDate.length > 0) {
                        searchStartDate = this.filterStartDate;
                    }
					if(this.filterEndDate.length > 0) {
                        searchEndDate = this.filterEndDate;
                    }

                axios.get('/dashboard/points/bonus', { params:{
                        'column_name': this.currentSort,
                        'bonus_page': page,
                        'sort': this.currentSortDir,
                        'searchVar': searchTerm,
						'allcat_to': searchTerm,
						'allcat_by': searchAllocatedBy,
						'cat_id': searchcaregoryId,
						'points': searchpointsId,
						'start_date': searchStartDate,
						'end_date': searchEndDate,
						'awid': awarduserid,
                        'siteId': $("#selected-site").val()
                    }}).then((response) => {
					 //console.log(response.data);
                    this.userDatas = response.data.bonusTransactions;
					this.total_points = response.data.total_points;


                    if(init) {
                        this.fetchUserData = response.data.bonusTransactions;
                        this.paginationData = response.data.pagination;
                    }
                    this.pagination = response.data.pagination;

                    if (response.data.hasOwnProperty('pagination')) {
                        let paginationArr = response.data.pagination;

                        this.historyPaginationData.current_page = paginationArr.current_page;
                        this.historyPaginationData.from = paginationArr.from;
                        this.historyPaginationData.last_page = paginationArr.last_page;
                        this.historyPaginationData.per_page = paginationArr.per_page;
                        this.historyPaginationData.to = paginationArr.to;
                        this.historyPaginationData.total = paginationArr.total;
                    }
                     this.showLoad = false;
                    //this.loading=false;
                    this.pointdataloading=true;

                }).catch((error) => {
                    console.log(error);
                });
            },
            getPostBody (post) {
                let body = this.stripTags(post);
                return body.length > 15 ? body.substring(0, 15) + '...' : body;
            },
			stripTags (markup) {
				const temp = document.createElement("div");
				temp.innerHTML = markup;
				return temp.textContent || temp.innerText || "";
			},

			loaddropdowns() {
                axios.get('/dashboard/points/dropdown', { params:{
                        'siteId': $("#selected-site").val()
                    }}).then((response) => {
					 //console.log(response.data);

					this.allocated_by_user = response.data.allocatedByArr;
					this.allocated_to_user = response.data.allocatedToArr;
					this.points_arr = response.data.pointArr;
					this.cat_arr = response.data.catArr;

                }).catch((error) => {
                    console.log(error);
                });
            },

			loadbonuspointfilter() {
			        var init = true;
                    this.showLoad=true;
                    this.pointdataloading=false;
                    let searchTerm = '';
					let searchAllocatedBy = '';
					let searchcaregoryId = '';
					let searchpointsId = '';
					let searchStartDate = '';
					let searchEndDate = '';
                    if(this.awardId.length > 0) {
                        searchTerm = this.awardId;
                    }
                    if(this.userAllocatedBy.length > 0) {
                        searchAllocatedBy = this.userAllocatedBy;
                    }
				   if(this.caregoryId.length > 0) {
                        searchcaregoryId = this.caregoryId;
                    }
					if(this.pointsId.length > 0) {
                        searchpointsId = this.pointsId;
                    }
					if(this.filterStartDate.length > 0) {
                        searchStartDate = this.filterStartDate;
                    }
					if(this.filterEndDate.length > 0) {
                        searchEndDate = this.filterEndDate;
                    }


                axios.get('/dashboard/points/bonus/filter', {  params:{
                            'column_name': this.currentSort,
                            'bonus_page': this.getParamsFromUrl('bonus_page', window.location.href),
                            'sort': this.currentSortDir,
                            'searchVar': searchTerm,
							'allcat_to': searchTerm,
							'allcat_by': searchAllocatedBy,
							'cat_id': searchcaregoryId,
							'points': searchpointsId,
							'start_date': searchStartDate,
							'end_date': searchEndDate,
                            'siteId': $("#selected-site").val()
                        }}).then((response) => {
					//console.log(response.data);

					this.userDatas = response.data.bonusTransactions;
                        if(init) {
                            this.fetchUserData = response.data.bonusTransactions;
                            this.paginationData = response.data.pagination;
                        }
                        this.pagination = response.data.pagination;

                        if (response.data.hasOwnProperty('pagination')) {
                            let paginationArr = response.data.pagination;

                            this.historyPaginationData.current_page = paginationArr.current_page;
                            this.historyPaginationData.from = paginationArr.from;
                            this.historyPaginationData.last_page = paginationArr.last_page;
                            this.historyPaginationData.per_page = paginationArr.per_page;
                            this.historyPaginationData.to = paginationArr.to;
                            this.historyPaginationData.total = paginationArr.total;
                        }

                        this.showLoad=false;
                        this.pointdataloading=true;

                }).catch((error) => {
                    console.log(error);
                });
            },
			filtertoggle(){
			   if(this.showFilter==true){
			      this.showFilter=false;
			   }else{
			      this.showFilter=true;
			   }
			},

			addTag (event) { //alert(event.endDate);

			   $(".cancelBtn").trigger("click");
				// alert(event.startDate.toLocaleDateString());
				var newstartDate = event.startDate.getDate();
				var newstartMonth =event.startDate.getMonth() + 1;
				var newstartYear =event.startDate.getFullYear();
				var FinalStartDate = newstartYear+"-"+newstartMonth+"-"+newstartDate;
				var newendDate = event.endDate.getDate();
				var newendMonth =event.endDate.getMonth() + 1;
				var newendYear =event.endDate.getFullYear();
				var FinalEndDate = newendYear+"-"+newendMonth+"-"+newendDate;

				var Difference_In_Days = (event.endDate-event.startDate) / (1000 * 3600 * 24);

				this.filterStartDate = FinalStartDate;
				this.filterEndDate = FinalEndDate;
				var d = new Date();
				var currentDate = d.getDate();
				var currentMonth = d.getMonth() + 1;
				var currentYear = d.getFullYear();
				// this method evaulate 'This Month' or 'Last Month' conditions.
				let monthSpan = this.evaluateMonthSpan(newstartMonth, Difference_In_Days, newstartDate, newstartYear);


				if(FinalStartDate==FinalEndDate){

				var todayDate = currentYear+"-"+currentMonth+"-"+currentDate;
				var yesDate = currentYear+"-"+currentMonth+"-"+(currentDate-1);

					  if(FinalStartDate==todayDate){
					   this.removeTag(this.tags.length - 1);
					   this.tags.push("Today  ");
					  }else if(FinalStartDate==yesDate){
					   this.removeTag(this.tags.length - 1);
					   this.tags.push("Yesterday ");
					  }else{
					   this.removeTag(this.tags.length - 1);
					   this.tags.push(FinalStartDate+' to '+FinalEndDate);
					 }
				 }
				 else if(Difference_In_Days>1 && Difference_In_Days<28){
				    this.removeTag(this.tags.length - 1);
				    this.tags.push(FinalStartDate+' to '+FinalEndDate);
				 }
				 else if(currentMonth == newstartMonth && Difference_In_Days > 28 && monthSpan){


				    this.removeTag(this.tags.length - 1);
				    this.tags.push("This Month ");
				 }
				 else if(currentMonth - 1 == newstartMonth && Difference_In_Days > 28 && monthSpan){
				    this.removeTag(this.tags.length - 1);
				    this.tags.push("Last Month ");
				 }
				 else if(currentYear && Difference_In_Days==365){
				  this.removeTag(this.tags.length - 1);
				    this.tags.push("This year ");
				 }
				 else{
				    this.removeTag(this.tags.length - 1);
				    this.tags.push(FinalStartDate+' to '+FinalEndDate);
				 }
				 this.selected_date_tags = 'Date: ' + this.tags[0];
				// this.loadBonusPoints();
				 /* var val = event.target.value.trim()
				  if (val.length > 0) {
					this.tags.push(val)
					event.target.value = ''
				  }*/
			},
			removeTag (index) {
			    if (index === 0){
                    this.filterStartDate = '';
                    this.filterEndDate = '';
                }
                this.selected_date_tags = '';
                this.tags.splice(index, 1);
                this.loadBonusPoints();
			},
			removeLastTag(event) {
			    console.log('866 - removeLastTag: ', event.target);
				  if (event.target.value.length === 0) {

				  this.filterStartDate='';
				  this.filterEndDate='';
					this.removeTag(this.tags.length - 1);

				  }
			},

			evaluateMonthSpan: function(newstartMonth, Difference_In_Days, newstartDate, newstartYear){

			    // Transform below code to find total days in month.
				let monthTotalDays = [
    				    31, // Jan
    				    28, // Feb
    				    31, // March
    				    30, // April
    				    31, // May
    				    30, // June
    				    31, // July
    				    31, // Aug
    				    30, // Sep
    				    31, // Oct
    				    30, // Nov
    				    31, // Dec
				    ]

				let monthIndex = newstartMonth-1;
				let monthDays = monthTotalDays[monthIndex];
				let totalSelectedSpan = Difference_In_Days+1; // catering previous dev coded result.

                // February Case
                if(newstartMonth == 2){
                    // When it is leap year like 2020, Feb would be off 29
                    if(newstartYear%4 == 0 && totalSelectedSpan == 29 && newstartDate == 1){
                        return true;
                    }else if(newstartYear%4 != 0 && totalSelectedSpan == 28 && newstartDate == 1){
                        return true;
                    }
                    return false;
                } else {
			        // Non February Cases
			        if(totalSelectedSpan == monthDays  && newstartDate == 1){
			            return true;
			        }
			        return false;
			    }
			},
	    },

        mounted(){
            //this.BonusPointsHistoryLink.$on('fetchData', this.fetchData);
			this.loaddropdowns();
            this.loadBonusPoints(this.getParamsFromUrl('bonus_page', window.location.href), true);
            this.link.$on('fetchBonusData', this.loadBonusPoints);


        }
    }
</script>
<style>
.daterangepicker .drp-buttons {
    display: none !important;
}
.datelabelclass{
   height: 20px;
   font-family: InterUI;
   font-size: 13px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.54;
   letter-spacing: normal;
   color: #85899e;
}
.reportrange-text{
	height: 44px;
	border-radius: 4px;
	border: solid 1px #dcdee6;
	background-color: #ffffff;
	color: #363b54;
	font: 400 13px "InterUI", sans-serif;
	text-transform: capitalize;
	width: 150px !important;
	background: url(/images/svg/select_dropdown.png) no-repeat right center !important;
}
.deselect{
  width:24px;
  height:24px;
 background: url(/images/exit.png) no-repeat right center !important;
 display: inline-flex;
 cursor: pointer;
}
.show-load-app{
 top:80% !important;
}
.padright{
  padding-bottom: 10px;
}
.btn_award{
 margin-bottom:15px !important;
 margin-top:-26px;
}
.bonus-history-top{
 width:50%;
 height: 68px;
 padding-top:16px;
 padding-bottom:16px;
}
.bonus-filter-cls{
 margin-left:12px;
}
.tot-num-bonus
 {
  width: 39px;
  height: 24px;
  font-family: InterUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363b54;
}
.total-points-bonus {
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #85899e;
}
.daterangepicker.show-calendar .ranges {
    margin-top: 8px;
    float: left;
}
.fa-calendar{
  display:none;
}
.bonus-points-history-table {
    width:100%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05), 0 0 12px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    padding: 0px 0px 20px 0px;
    min-height: 520px;
}
.bonus-points-history-table .table-top-area {
    padding-right: 20px;
    padding-left: 20px;
}
.bonus-points-history-table .cf-table{
    margin-top: 9px;
}
    .spiner-example {
        height: 200px;
        padding-top: 70px;
    }
    .sk-spinner-wave.sk-spinner {
        margin: 0 auto;
        width: 50px;
        height: 30px;
        text-align: center;
        font-size: 10px;
    }
    .sk-spinner-wave div {
        background-color: #ED5475;
        height: 100%;
        width: 6px;
        display: inline-block;
        -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
        animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    }
    .sk-spinner-wave .sk-rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }
    .sk-spinner-wave .sk-rect3 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }
    .sk-spinner-wave .sk-rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }
    .sk-spinner-wave .sk-rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }
    @-webkit-keyframes sk-waveStretchDelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
        }
        20% {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
    @keyframes sk-waveStretchDelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
        }
        20% {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9em;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
}
span.lablesize{
  height: 16px;
  font-family: InterUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #656a85;
  padding: 8px 2px 8px 10px;
  display: block;
  float: left;
}
.deselect-pad{
  padding-top: 5px;
  display: inline-table;
  cursor: pointer;
  opacity: 0.75;
}
.tag-input__tag {
  height: 32px;
  border-radius: 16px;
  background-color: #f0f1f7;
  margin-bottom: 10px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 0.9em;
  line-height: 50px;
  background: none;
}
.bonus-points-history-table .vue-daterange-picker.phone-recruiter .reportrange-text label{
    margin-top: 6px;
}
.bonus-points-history-table .input-box .vs__selected-options{
    top: 0px;
  }
.bonus-points-history-table .input-box .vs__selected-options:after {
	content: "";
	display: table;
	clear: both;
	}

element {

    margin: 10px 8px;

}


.daterangepicker .calendar-table th{
   height: 24px;
  font-family: InterUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #363b54;
}
 .daterangepicker .calendar-table tbody th{
    height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #363b54;
}
.daterangepicker .calendar-table td{
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #85899e;
}
.daterangepicker td.active, .daterangepicker td.active:hover{
 background-color: #ef5d72 !important;
   font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  width:26px;
  height:26px;
  border-radius:50% !important;
}
.daterangepicker .ranges li{
  padding:11px 0;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #363b54;
}
.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date{
color: #FFF;
}
.daterangepicker .calendar-table td, .daterangepicker .calendar-table th{
  width:36px;
  height:36px;
  min-width:36px;
}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
    color: #85899e;
    border: solid #85899e;
	border-width: 0 2px 2px 0;
	}
	.v-select .dropdown-toggle .clear {
    display: none;
}
.phone-recruiter .span.vs__selected{display:none;}
.phone-recruiter .vs__selected{ display:none !important; }
.daterangepicker.show-ranges.show-weeknumbers, .daterangepicker.show-ranges{
min-width:702px;
}
.daterangepicker.opensright[data-v-4f8eb193]{
 left:0px !important;
}
.sort-active{
  border-bottom: 2px solid #c9cbda;
  }
  
  

.filters-wrappers .cell:first-child{
    max-width: 91px;
    padding-right: 12px;
}
.filters-wrappers .reportrange-text{
    max-width:79px;
} 
.filters-wrappers .cell:nth-child(2){
    max-width: 133px;
    padding-right: 12px;
}
.filters-wrappers .cell:nth-child(3){
    max-width: 119px;
    padding-right: 12px;
}
.filters-wrappers .cell:last-child{
    max-width: 136px;
    padding-right: 12px;
}
.filters-wrappers .cell input[type=search]{
	color:#85899e;
}
.bonus-history-top{
	height:72px;
}

.filters-wrappers .daterangepicker td.off, .filters-wrappers .daterangepicker td.off.end-date, .filters-wrappers .daterangepicker td.off.in-range, .filters-wrappers .daterangepicker td.off.start-date{
    background-color: #fff !important;
}
.filters-wrappers .daterangepicker .calendar-table td{
    color: #363b54;
}
.filters-wrappers .daterangepicker .calendar-table tbody th {
    background: #f7f8fc;
    height: 36px;
}

.filters-wrappers .daterangepicker td.active, .filters-wrappers .daterangepicker td.active:hover {
    background-color: #fbd8dd !important;
    border-radius: 0 !important;
}

.filters-wrappers .daterangepicker td.active, .filters-wrappers .daterangepicker td.active:hover {
    background-color: inherit !important;
    border-radius: 0;
    position: relative;
z-index:2;
}
.filters-wrappers .daterangepicker td.active, .filters-wrappers .daterangepicker td:hover{
color:#FFF !important;
}
.filters-wrappers .daterangepicker td.active:after, .filters-wrappers .daterangepicker td:hover:after {
    width: 26px !important;
    content: '';
    height: 26px !important;
    background: #ef5d72 !important;
    display: block !important;
    position: absolute;
    left: 50% !important;
    margin-left: -13px;
    top: 50% !important;
    z-index: -1 !important;
    border-radius: 50%;
    margin-top: -13px;
}

.filters-wrappers .daterangepicker td.active:before, .filters-wrappers .daterangepicker td:hover:before {
    content: '';
    display: block;
    width: 50%;
    background: #fbd8dd;
    position: absolute;
    z-index: -1;
    left: -1px;
    top: 50%;
margin-top:-13px;
    height: 26px;
}
.filters-wrappers tbody tr:nth-child(2n){
background:none;
}
.filters-wrappers .daterangepicker .calendar-table td{
position:relative;
z-index:2;
}
.filters-wrappers .daterangepicker td.in-range{
background:none;
}
.filters-wrappers .daterangepicker td.in-range:after {
    content: '';
    background: #fbd8dd;
    display: block;
    position: absolute;
    left: -1px;
    width: calc(100% + 1px);
    height: 26px;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    z-index: -3;
}
.filters-wrappers tbody{
border:0;
}

.filters-wrappers .daterangepicker td.active.start-date:before, .filters-wrappers .daterangepicker td.active.start-date:hover:before {
    left: inherit;
    right: 0;
}
.daterangepicker.show-calendar .ranges{
padding: 0 0;
margin-top: 0;
}
.daterangepicker .ranges li {
padding: 11px 10px;
}
.filters-wrappers .daterangepicker td.active:before, .filters-wrappers .daterangepicker td:hover:before {
 background: none;
}
.filters-wrappers .daterangepicker td.end-date.active:before, .filters-wrappers .daterangepicker td.end-date:hover:before, .filters-wrappers .daterangepicker td.start-date.active:before, .filters-wrappers .daterangepicker td.start-date:hover:before {
    background: #FBD8DD;
}

</style>
