<script>
export default {
  name: 'ValidationErrorIcon'
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5.25C9.4125 5.25 9.75 5.5875 9.75 6V9C9.75 9.4125 9.4125 9.75 9 9.75C8.5875 9.75 8.25 9.4125 8.25 9V6C8.25 5.5875 8.5875 5.25 9 5.25ZM8.9925 1.5C4.8525 1.5 1.5 4.86 1.5 9C1.5 13.14 4.8525 16.5 8.9925 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 8.9925 1.5ZM9 15C5.685 15 3 12.315 3 9C3 5.685 5.685 3 9 3C12.315 3 15 5.685 15 9C15 12.315 12.315 15 9 15ZM9.75 12.75H8.25V11.25H9.75V12.75Z" fill="#E61414"/>
  </svg>
</template>