<template>
  <div class="recruitment-stages">
    <div class="stage-row">
      <div class="stage-column header">Name of Stage / Status in your ATS</div>
      <div class="stage-column header">Equivalent in {{ brandName }}</div>
    </div>

    <form @submit.prevent="submitForm" ref="form">
      <div class="stage-row body" v-for="key in Object.keys(mappedStages).slice(0, -1)" :key="key">
        <div class="stage-column">
          <div v-if="mappingExists && showUpdateButton" class="stage-maping">
            <p class="stage-name" :class="[selectedStages[key] ? '' : 'stage-name--not-selected']">
              {{ selectedStages[key] ? selectedStages[key] : "No ATS Stage Selected" }}
            </p>
            <widget-arrow-icon></widget-arrow-icon>
          </div>
          <multiselect
            v-else
            v-model="mappedStages[key].mapped_ats_stages"
            :options="mappedStages[key].options"
            :multiple="true"
            :close-on-select="true"
            openDirection="below"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="true"
            @select="$emit('select', $event, key)"
            @remove="$emit('remove', $event, key)"
            :placeholder="'Select ATS Mapping ' + (mappedStages[key].required ? '(Required) ' : '') + '...'"
            label="name"
            track-by="name"
            :class="[
              formSubmitted && mappedStages[key].required && !mappedStages[key].mapped_ats_stages.length
                ? 'error-background'
                : '',
            ]"
          >
            <template slot="caret" slot-scope="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle()">
                <icon name="arrow-down" />
              </div>
            </template>

            <span class="checkbox-label" slot="option" slot-scope="scope">
              <input class="option" type="checkbox" v-model="scope.option.checked" @focus.prevent />
              <label>{{ scope.option.name }}</label>
            </span>
          </multiselect>
          <span
            v-show="formSubmitted && mappedStages[key].required && !mappedStages[key].mapped_ats_stages.length"
            class="error"
            >This field is required</span
          >
        </div>
        <div class="stage-column">
          <p class="stage-name">{{ key }}</p>
        </div>
      </div>
      <div class="stage-row body">
        <p class="rejected-stage-info">
          By default, if a candidate is marked as rejected in your ATS, it will be moved to archived in {{ brandName }}. If
          you have any additional stages you would like to trigger the candidate to be archived, you can select them
          here.
        </p>
      </div>
      <div class="stage-row body">
        <div class="stage-column">
          <div v-if="mappingExists && showUpdateButton" class="stage-maping">
            <p
              class="stage-name"
              :class="[selectedStages['Candidate has been rejected'] ? '' : 'stage-name--not-selected']"
            >
              {{
                selectedStages["Candidate has been rejected"]
                  ? selectedStages["Candidate has been rejected"]
                  : "No ATS Stage Selected"
              }}
            </p>
            <widget-arrow-icon></widget-arrow-icon>
          </div>
          <multiselect
            v-else
            v-model="mappedStages['Candidate has been rejected'].mapped_ats_stages"
            :options="mappedStages['Candidate has been rejected'].options"
            :multiple="true"
            :close-on-select="true"
            openDirection="below"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="true"
            @select="$emit('select', $event, 'Candidate has been rejected')"
            @remove="$emit('remove', $event, 'Candidate has been rejected')"
            :placeholder="'Select ATS Mapping...'"
            label="name"
            track-by="name"
          >
            <template slot="caret" slot-scope="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle()">
                <icon name="arrow-down" />
              </div>
            </template>

            <span class="checkbox-label" slot="option" slot-scope="scope">
              <input class="option" type="checkbox" v-model="scope.option.checked" @focus.prevent />
              <label>{{ scope.option.name }}</label>
            </span>
          </multiselect>
        </div>
        <div class="stage-column">
          <p class="stage-name">Candidate has been rejected</p>
        </div>
      </div>

      <div v-if="!mappingExists || showUpdateButton === false" class="stage-buttons">
        <button type="button" class="cancel_btn button" @click="resetForm">Cancel</button>
        <button type="submit" class="save_btn button">SAVE</button>
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { Icon } from "../../../../components/Icon";
import { WidgetArrowIcon } from "../../components/WidgetArrowIcon";

import { cssVariables } from "../../../../lib/css-variables";

export default {
  name: "RecruitmentStagesMappingView",
  props: {
    mappingExists: { type: Boolean, default: false },
    showUpdateButton: { type: Boolean, default: false },
    mappedStages: { type: Object, default: null },
    selectedStages: { type: Array, default: [] },
    error: { type: String, default: "" },
    isRequired: { type: Boolean, default: true },
    translations: { type: [Object, null], default: null }
  },
  components: {
    Multiselect,
    Icon,
    WidgetArrowIcon,
  },
  data() {
    return {
      formSubmitted: false,
    };
  },
  methods: {
    submitForm() {
      this.formSubmitted = true;
      let hasErrors = false;

      Object.keys(this.mappedStages).forEach(mappableStage => {
        if (this.mappedStages[mappableStage].required && !this.mappedStages[mappableStage].mapped_ats_stages.length) {
          hasErrors = true;
          return false;
        }
      });

      if (!hasErrors) {
        this.save();
        this.formSubmitted = false;
      }
    },
    resetForm() {
      this.$emit("allow-mapping-update", true);
      this.formSubmitted = false;
      this.$emit("reset");
    },
    save() {
      axios
        .post("/merge.dev/map-candidate-stages", { mappedStages: this.mappedStages })
        .then(response => {
          let toastObj = {
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>Could not save candidate mappings!',
            confirmButtonColor: "#F84366",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#F84366",
          };

          if (response.data.success) {
            toastObj = {
              html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>Candidate mappings saved successfully!',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
            };
          }
          Vue.swal({
            ...toastObj,
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      this.$emit("allow-mapping-update", true);
      this.$emit("mapping-created");
      this.$emit("update-mappings", this.mappedStages);
    },
  },

  async created() {
    this.cssVariables = cssVariables();
  },
};
</script>

<style scoped lang="scss">
@import "./RecruitmentStagesMapping.scss";
</style>
