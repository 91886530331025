<script>
export default {
  name: 'AppUsersIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.212 16.096L17.0735 15.426C16.912 14.646 16.312 14.035 15.5395 13.8585C15.273 13.7665 13.8135 13.2925 12 13.2925C10.186 13.2925 8.727 13.7665 8.4605 13.8585C7.6875 14.035 7.0875 14.646 6.9265 15.426L6.7875 16.096C5.798 14.9425 5.2 13.459 5.2 11.8395C5.2 8.1785 8.2505 5.2 12 5.2C15.7495 5.2 18.7995 8.1785 18.7995 11.8395C18.7995 13.459 18.2015 14.9425 17.212 16.096ZM7.814 17.0615L8.1015 15.669C8.169 15.344 8.4215 15.091 8.7455 15.0245C8.774 15.019 8.8025 15.0105 8.8295 15.001C8.8435 14.996 10.2545 14.4925 12 14.4925C13.745 14.4925 15.156 14.996 15.1705 15.001C15.1975 15.0105 15.226 15.019 15.254 15.0245C15.578 15.091 15.831 15.344 15.898 15.669L16.1855 17.0615C15.0305 17.9465 13.579 18.4795 12 18.4795C10.4205 18.4795 8.9695 17.9465 7.814 17.0615ZM12 4C7.5885 4 4 7.517 4 11.8395C4 16.1625 7.5885 19.6795 12 19.6795C16.411 19.6795 20 16.1625 20 11.8395C20 7.517 16.411 4 12 4ZM12 11.4995C11.049 11.4995 10.5355 10.534 10.5355 9.626C10.5355 8.815 11.1925 8.156 12 8.156C12.807 8.156 13.464 8.815 13.464 9.626C13.464 10.534 12.951 11.4995 12 11.4995ZM12 6.956C10.531 6.956 9.3355 8.1535 9.3355 9.626C9.3355 11.116 10.2695 12.6995 12 12.6995C13.7305 12.6995 14.664 11.116 14.664 9.626C14.664 8.1535 13.469 6.956 12 6.956Z" fill="currentColor"/>
  </svg>
</template>