<template>
  <div class="grid-x grid-padding-x">
    <div class="cell">
      <div class="grey_border_box box_shadow t_margin_2">
        <div class="grid-x grid-padding-x align-middle">
          <div class="cell auto super-viewpoint">
            <p align="center">Total app users</p>
            <h3 align="center">{{totals.total_app_users}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Total job shares</p>
            <h3 align="center">{{totals.total_shares}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Total candidates</p>
            <h3 align="center">{{totals.total_eoi}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Total starters</p>
            <h3 align="center">{{totals.total_starters}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Total starters NTC</p>
            <h3 align="center">{{totals.total_starters_new_to_care}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Value points awarded</p>
            <h3 align="center">{{totals.total_value_of_points_earnt}}</h3>
          </div>
        </div>
      </div>

      <div class="grey_border_box box_shadow t_margin_2">
        <div class="grid-x grid-padding-x align-middle">
          <div class="cell auto super-viewpoint">
            <p align="center">Total value of bonus points awarded</p>
            <h3 align="center">{{totals.total_manual_bonus_points}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Total value of points marked as paid</p>
            <h3 align="center">{{totals.total_paid_points}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Current clients</p>
            <h3 align="center">{{totals.total_current_clients}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Current staff eligible</p>
            <h3 align="center">{{totals.total_staff_eligible}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Current % staff registered</p>
            <h3 align="center">{{totals.total_staff_registered}}</h3>
          </div>

          <div class="cell auto super-viewpoint">
            <p align="center">Current % staff active</p>
            <h3 align="center">{{totals.total_staff_active}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['micrositeId'],
  data: function () {
    return {
      totals: {
        total_app_users: 0,
        total_shares: 0,
        total_eoi: 0,
        total_starters: 0,
        total_starters_new_to_care: 0,
        total_value_of_points_earnt: 0,
        total_manual_bonus_points: 0,
        total_paid_points: 0,
        total_current_clients: 0,
        total_staff_eligible: 0,
        total_staff_registered: 0,
        total_staff_active: 0,
      }
    };
  },
  methods: {
    getTotals() {
      var self = this;

      axios.post('/dashboard/super/clients/get-worldwide-totals', { id: this.micrositeId })
        .then((response) => {
          if (response.data.totals) {
            self.totals.total_app_users = response.data.totals.total_app_users;
            self.totals.total_shares = response.data.totals.total_shares;
            self.totals.total_eoi = response.data.totals.total_eoi;
            self.totals.total_starters = response.data.totals.total_starters;
            self.totals.total_starters_new_to_care = response.data.totals.total_starters_new_to_care;
            self.totals.total_value_of_points_earnt = response.data.totals.total_value_of_points_earnt;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAdditionalStats() {
      var self = this;

      axios.post('/dashboard/super/clients/get-stats', { id: this.micrositeId })
        .then((response) => {
          if (response.data.stats) {
            self.totals.total_manual_bonus_points = response.data.stats.total_manual_bonus_points;
            self.totals.total_paid_points = response.data.stats.total_paid_points;
            self.totals.total_current_clients = response.data.stats.total_current_clients;
            self.totals.total_staff_eligible = response.data.stats.total_staff_eligible;
            self.totals.total_staff_registered = response.data.stats.total_staff_registered;
            self.totals.total_staff_active = response.data.stats.total_staff_active;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getTotals();
    this.getAdditionalStats();
  }
};
</script>
