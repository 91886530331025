<script>
export default {
  name: "UndoIcon",
};
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1995_66438)">
      <path
        d="M6.34998 6.34999C7.97998 4.71999 10.29 3.77999 12.83 4.03999C16.5 4.40999 19.52 7.38999 19.93 11.06C20.48 15.91 16.73 20 12 20C8.80998 20 6.06998 18.13 4.78998 15.44C4.46998 14.77 4.94998 14 5.68998 14C6.05998 14 6.40998 14.2 6.56998 14.53C7.69998 16.96 10.41 18.5 13.37 17.84C15.59 17.35 17.38 15.54 17.85 13.32C18.69 9.43999 15.74 5.99999 12 5.99999C10.34 5.99999 8.85998 6.68999 7.77998 7.77999L9.28998 9.28999C9.91998 9.91999 9.47998 11 8.58998 11H4.99998C4.44998 11 3.99998 10.55 3.99998 9.99999V6.40999C3.99998 5.51999 5.07998 5.06999 5.70998 5.69999L6.34998 6.34999Z"
        fill="#9297AE"
      />
    </g>
    <defs>
      <clipPath id="clip0_1995_66438">
        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
      </clipPath>
    </defs>
  </svg>
</template>
