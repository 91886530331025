<template>
    <div>
        <a href="javascript:void(0)"
           class="action-btn"
           v-b-modal="'delete-' + job.id">
            <icon name="trash" />
            <span>Delete</span>
        </a>

        <b-modal class="tab-modal"
                 :id="'delete-' + job.id"
                 :modal-class="loading ? 'modal--loading' : ''"
                 size="lg"
                 hide-header-close
                 no-close-on-backdrop>
            <h2>Delete Job</h2>
            <div class="grid-y">
                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                        <p v-if="job.have_candidates">
                            There are one or more candidates
                            currently associated with this job. If
                            you delete it, these candidates will no
                            longer be linked to a job.
                        </p>
                        <p v-else>
                            Are you sure? You won’t be able to revert this!
                        </p>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <button class="cancel-button"
                        @click="$bvModal.hide('delete-' + job.id)">Cancel</button>
                <button class="process-button"
                        @click="handleDeleteJob">Delete job</button>

            </template>
        </b-modal>
    </div>
</template>

<script>
import { Icon } from '../../../../app/components/Icon';

export default {
    name: 'DeleteJob',
    props: {
        currentuser: { type: Object, required: true },
        job: { type: Object, required: true },
        index: { type: Number, default: 0 }
    },
    components: {
        Icon,
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        handleDeleteJob() {
            this.loading = true;
            axios
                .get("/dashboard/jobs/" + this.job.id + "/delete")
                .then(response => {
                    if (response.data.hasOwnProperty("success")) {
                        this.$emit('on-job-deleted', this.index);
                        this.$bvModal.hide('delete-' + this.job.id);
                        this.showSuccessToast('Job deleted');
                    }
                })
                .catch(error => {
                    console.error("handleDeleteJob Error: ", error);
                })
                .finally(() => {
                    this.loading = false
                });
        },
        showSuccessToast(message) {
            Vue.swal({
                html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> ' + message,
                confirmButtonColor: "#5dc2a6",
                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: {
                    container: "candidate-toast"
                },
                showClass: {
                    popup: "animated fadeInLeft fast",
                    icon: "animated heartBeat delay-1s"
                },
                hideClass: {
                    popup: "animated fadeOutLeft slow"
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
            });
        }
    }
};
</script>

<style scoped lang="scss">
@import "./DeleteJob.scss";
</style>