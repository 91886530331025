<template>
    <div class="cell auto single-job-status">
        <div :class="statusClass()+' bottom'">
            <div v-if="recent == 1" class="grid-x" style="float:right">
                <div class="cell" align="right">
                    <a v-b-modal="'undo-' + data.candidate_id" @click="displayModalWithAdditionalQuestions('undoCheck')"><img class="undo-icon" src="/images/undo-icon.png"></a>
                </div>
            </div>
            <div v-else class="undo-icon-else"></div>
            <div
                :class="statusBoxClass() + ' candidate_display align-middle text-center progress-' + uid  + ' ' "
                style="height: 100%;"
                :data-toggle="uid"
                @click="onClickStatus"
            >
                {{ (touch && statusData.status) ? prettyStatus(statusData.status, stageName) : (status ? prettyStatus(status, stageName): prettyTitle(stageName)) }}
                <div v-if="data.date" @mouseover="hover = true" @mouseleave="hover = false">
                    {{ data.date | moment("DD/MM/YYYY") }}<img v-if="hover" @click="displayModalWithAdditionalQuestions('showCalendar')"
                                                               v-b-modal="'calendar-' + data.candidate_id" src="/images/edit.png">
                </div>
            </div>
        </div>
        <b-modal v-if="undoCheck" class="tab-modal" :id="'undo-' + data.candidate_id" size="lg">
            <div class="grid-y">
                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
					   <h3>Are you sure?</h3>
                        <p>This will undo the most recent update to this candidates progress. Any points that were awarded for this step (only) will be removed.  The candidate will not be deleted and any points awarded for previous stages in the recruitment process will not be removed. The app user who referred this candidate will receive a push notification letting them know this stage has been undone.</p>
                    </div>
                </div>
            </div>
            <div class="cell auto popup-button">
                <input class="delete-candidate" type="submit" value="Continue" @click="undoProgress(data.id)">
                <input type="button" class="button btn_lg btn orange_btn anon-can" value="Cancel" @click="undoCheck=false">
            </div>
        </b-modal>
        <div>
            <div
                class="dropdown-pane candidate-drop"
                :id="uid"
                data-dropdown
                data-auto-focus="true"
                data-close-on-click="true"
                data-position="bottom"
                data-alignment="center"
            >
                <div :class="{ arrow_box: isActive, arr_box: isDisplay }" v-if="showStatusOptions">
                    <form class="status-form">
                        <fieldset id="group-1" class="cell" align="center">
                            <div
                                v-if="stageName == 'Interview offered'"
                                @click="touchCard()"
                                class="custom-candidate-radio"
                            >
                                <input
                                    v-if="attemptingContactLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="attempting_to_contact"
                                    id="attemptingContact"

                                />
                                <label for="attemptingContact" >
								<span class="dot" :style="{background: color3}"></span>
                                    <strong>{{ attemptingContactLabel }}</strong>
                                </label>
                                <input
                                    v-if="unableToContactLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="unable_to_contact"
                                    id="unableTo"

                                />
                                <label for="unableTo" >
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ unableToContactLabel }}</strong>
                                </label>
                                <br />
                                <input
                                    v-if="doesntMeetCriteriaLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="doesnt_meet_criteria"
                                    id="doesntMeet"
                                    required

                                />
                                <label for="doesntMeet" >
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ doesntMeetCriteriaLabel }}</strong>
                                </label>
                                <br />
                                <input
                                    v-if="suitableToProgressLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="suitable_to_progress"
                                    id="suitable"

                                />
                                <label for="suitable">
                                  <div>
								                    <span class="dot" :style="{background: color2}"></span>
                                    <strong>{{ suitableToProgressLabel }}</strong>
                                    <span v-if="pointTariffsFeatureFlag && job.customer.only_award_eoi_points_to_verified_candidates_check" class="stage-points">+
                                        {{ candidate.point_tariff.one_job_application }}
                                    </span>
                                  </div>
                                </label>

                                <br />
                                <input
                                    v-if="notSuitableToProgressLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="not_suitable_to_progress"
                                    id="notSuitable"
                                />
                                <label for="notSuitable">
                                  <div>
								                    <span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ notSuitableToProgressLabel }}</strong>
                                    <span v-if="pointTariffsFeatureFlag && job.customer.only_award_eoi_points_to_verified_candidates_check" class="stage-points">+
                                        {{ candidate.point_tariff.one_job_application }}
                                    </span>
                                  </div>
                                </label>
                                <br />
                            </div>
                            <div
                                v-else-if="stageName == 'Interview booked'"
                                class="custom-candidate-radio"
                                @click="touchCard()"
                            >
                                <input
                                    @click="displayModalWithAdditionalQuestions('isShow')"
                                    v-b-modal="'modal-' + candidateId"
                                    v-if="scheduleInterview"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="schedule_interview"
                                    id="schedule"
                                />
                                <label for="schedule" >
								<span class="dot" :style="{background: color2}"></span>
                                    <strong>{{ scheduleInterview }}</strong>
                                </label>
                                <br />
                                <input
                                    v-if="moreInfoRequested"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="more_info_requested"
                                    id="more"

                                />
                                <label for="more">
								<span class="dot" :style="{background: color3}"></span>
                                    <strong>{{ moreInfoRequested }}</strong>
                                </label>
                                <br />
                                <input
                                    v-if="inReview"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="in_review"
                                    id="in"

                                />
                                <label for="in">
								<span class="dot" :style="{background: color3}"></span>
                                    <strong>{{ inReview }}</strong>
                                </label>
                                <br />
                                <input
                                    v-if="candidateNotResponding"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="candidate_not_responding"
                                    id="candidate"

                                />
                                <label for="candidate">
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ candidateNotResponding }}</strong>
                                </label>
                                <br />
                            </div>
                            <div
                                v-else-if="stageName == 'Interview successful'"
                                @click="touchCard()"
                                class="custom-candidate-radio"
                            >
                                <input
                                    v-if="noShowLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="no_show"
                                    id="successfulNoShow"

                                />
                                <label for="successfulNoShow">
								<span class="dot" :style="{background: color3}"></span>
                                    <strong>{{ noShowLabel }}</strong>
                                </label>
                                <input
                                    v-if="yesLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="yes"
                                    id="successfulYes"
                                    required

                                />
                                <label for="successfulYes">
								    <span class="dot" :style="{background: color2}"></span>
                                    <strong>{{ yesLabel }}</strong>
                                    <span v-if="pointTariffsFeatureFlag" class="stage-points">+{{ candidate.point_tariff.interview_attended }}</span>
                                </label>
                                <input
                                    v-if="noLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="no"
                                    id="successfulNo"

                                />
                                <label for="successfulNo">
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ noLabel }}</strong>
                                </label>
                                <input
                                    v-if="noShowArchiveLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="no_show_archive"
                                    id="noShowArchive"

                                />
                                <label for="noShowArchive">
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ noShowArchiveLabel }}</strong>
                                </label>
                            </div>
                            <div
                                v-else-if="stageName == 'Job offered'"
                                @click="touchCard()"
                                class="custom-candidate-radio"
                            >
                                <input
                                    v-if="offered"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="yes"
                                    id="yes"

                                />
                                <label for="yes">
								<span class="dot" :style="{background: color2}"></span>
                                    <strong>{{ offered }}</strong>
                                </label>
                                <input
                                    v-if="notAcceptLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="no_show"
                                    id="notAccepted"

                                />
                                <label for="notAccepted">
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ notAcceptLabel }}</strong>
                                </label>
                                <input
                                    v-if="awaitingDecisionLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="awaiting_decision"
                                    id="awaitingDecision"

                                />
                                <label
                                    v-if="stageName == 'Job offered'"
                                    for="awaitingDecision"

                                ><span class="dot" :style="{background: color3}"></span>
                                    <strong>{{ awaitingDecisionLabel }}</strong>
                                </label>
                                <input
                                    v-if="notOffered"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="no"
                                    id="no"

                                />
                                <label for="no">
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ notOffered }}</strong>
                                </label>
                            </div>
                            <div
                                v-else-if="stageName == 'Started work'"
                                @click="touchCard()"
                                class="custom-candidate-radio"
                            >
                                <input
                                    v-if="police"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="police_checks_received"
                                    id="police"

                                />
                                <label for="police" ><span class="dot" :style="{background: color3}"></span>
                                    <strong>{{ police }}</strong>
                                </label>
                                <input
                                    v-if="references"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="references_complete"
                                    id="references"

                                />
                                <label for="references" >
								<span class="dot" :style="{background: color3}"></span>
                                    <strong>{{ references }}</strong>
                                </label>
                                <input
                                    v-if="policeReferences"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="police_checks_references_received"
                                    id="policeReferences"

                                />
                                <label for="policeReferences">
								<span class="dot" :style="{background: color3}"></span>
                                    <strong>{{ policeReferences }}</strong>
                                </label>
                                <input
                                    @click="displayModalWithAdditionalQuestions('showWork')"
                                    v-b-modal="'modal-started-' + candidateId"
                                    v-if="started"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="yes"
                                    id="started"
                                />
                                <label for="started" >
								    <span class="dot" :style="{background: color2}"></span>
                                    <strong>{{ started }}</strong>
                                    <span v-if="pointTariffsFeatureFlag" class="stage-points">+
                                      {{
                                        candidate.new_to_care === 1 ?
                                          candidate.point_tariff.hired + candidate.point_tariff.new_to_care :
                                          candidate.point_tariff.hired
                                      }}
                                    </span>
                                </label>
                                <input
                                    v-if="failed"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="no"
                                    id="failed"
                                />
                                <label for="failed">
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ failed }}</strong>
                                </label>
                            </div>
                            <div
                                v-else-if="stageName == 'Employed for 12 months'"
                                @click="touchCard()"
                                class="custom-candidate-radio"
                            >
                                <input
                                    v-if="yesLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="yes"
                                    id="yes-emp"

                                />
                                <label for="yes-emp">
								    <span class="dot" :style="{background: color2}"></span>
                                    <strong>{{ yesLabel }}</strong>
                                    <span v-if="pointTariffsFeatureFlag" class="stage-points">+{{ candidate.point_tariff.employed_x_months }}</span>
                                </label>
                                <input
                                    @click="displayModalWithAdditionalQuestions('showLeft')"
                                    v-b-modal="'modal-left-' + candidateId"
                                    v-if="noLabel"
                                    type="radio"
                                    name="successful"
                                    v-model="selectedStatus"
                                    value="no"
                                    id="no-emp"

                                />
                                <label for="no-emp">
								<span class="dot" :style="{background: color1}"></span>
                                    <strong>{{ noLabel }}</strong>
                                </label>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
            <b-modal v-if="isShow" :id="'modal-' + candidateId" size="sm" ok-only ok-title="Save" :no-close-on-backdrop="true"
                     :no-close-on-esc="true" @hide="clearSelectedStatus">
                <h3>Book Interview Date:</h3>
                <div class="grid-x">
                    <div class="cell auto input-box">
                        <datepicker
                            class="select-date"
                            name="selectDate"
                            format="dd/MM/yyyy"
                            :value="date2"
                            v-model="date2"
                            placeholder="Today"
                        ></datepicker>
                    </div>
                </div>
                <div class="popup-button">
                    <button
                        id="cancel-interview-btn"
                        type="button"
                        class="button orange_btn"
                        @click = "hidemodalinterview(candidateId)"
                    > Cancel </button>
                    <button
                        id="book-interview-btn-confirm"
                        type="button"
                        @click="closeinterviewmodel()"
                        class="button btn pink_btn"
                    >OKAY</button>
                </div>
            </b-modal>
            <b-modal v-if="showWork" :id="'modal-started-' + candidateId" size="sm" ok-only ok-title="Save" :no-close-on-backdrop="true"
                     :no-close-on-esc="true" @hide="clearSelectedStatus">
                <h3>What date did the candidate start work?</h3>
                <div class="grid-x">
                    <div class="cell auto input-box">
                        <datepicker
                            class="select-date"
                            name="selectDate"
                            format="dd/MM/yyyy"
                            :value="date2"
                            v-model="date2"
                            placeholder="Today"
                        ></datepicker>
                    </div>
                </div>
                <div class="popup-button">
                    <button
                        id="cancel-interview-btn"
                        type="button"
                        class="button  orange_btn"
                        @click = "hidemodalstarted(candidateId)"
                    > Cancel </button>

                    <button
                        id="book-started-btn-confirm"
                        type="button"
                        @click="candidateStartedDate()"
                        class="button  pink_btn"
                    >Okay</button>
                </div>
            </b-modal>
            <b-modal v-if="showLeft" :id="'modal-left-' + candidateId" size="sm" ok-only ok-title="Update"
                     :no-close-on-backdrop="true"
                     :no-close-on-esc="true" @hide="clearSelectedStatus">
                <h3>What date did the candidate leave?</h3>
                <div class="grid-x">
                    <div class="cell auto input-box">
                        <datepicker
                            class="select-date"
                            name="selectDate"
                            format="dd/MM/yyyy"
                            :value="date2"
                            v-model="date2"
                            placeholder="Today"
                        ></datepicker>
                    </div>
                </div>
                <div class="popup-button">
                    <button
                        id="cancel-left-btn"
                        type="button"
                        class="button orange_btn"
                        @click="hideModalLeft(candidateId)"
                    > Cancel
                    </button>

                    <button
                        id="book-left-btn-confirm"
                        type="button"
                        @click="candidateLeftDate()"
                        class="button pink_btn"
                    >Update
                    </button>
                </div>
            </b-modal>
            <b-modal v-if="showCalendar" :id="'calendar-' + candidateId" size="sm" ok-only :no-close-on-backdrop="true"
                     :no-close-on-esc="true" @hide="closeCalendarModal">
                <h3>Edit date of the stage:</h3>
                <div class="grid-x">
                    <div class="cell auto input-box">
                        <datepicker
                            class="select-date"
                            name="selectDate"
                            format="dd/MM/yyyy"
                            :value="editDate"
                            v-model="editDate"
                            placeholder="Today"
                        ></datepicker>
                    </div>
                </div>
                <div class="popup-button">
                    <button
                        id="cancel-calendar-btn"
                        type="button"
                        class="button orange_btn"
                        @click = "hideCalendarModal(candidateId)"
                    > Cancel </button>
                    <button
                        id="calendar-btn-confirm"
                        type="button"
                        @click="calendarModal()"
                        class="button btn pink_btn"
                    >Update</button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import Datepicker from "vuejs-datepicker";
    export default {
        components: {
            Datepicker
        },
        props: {
            pointTariffsFeatureFlag: {
                type: Boolean,
                default: false
            },
            stageName: String,
            candidateId: Number,
            progressStep: String,
            showNewToCare: {
                type: Boolean,
                default: false
            },
            status: {
                type: String,
                default: ""
            },
            yesLabel: {
                type: String,
                default: "Yes"
            },
            noLabel: {
                type: String,
                default: "No"
            },
            notOffered: {
                type: String,
                default: "Job not offered - archive"
            },
            offered: {
                type: String,
                default: "Job offered - accepted"
            },
            noShowLabel: {
                type: String
            },
            notAcceptLabel: {
                type: String
            },
            police: {
                type: String,
                default: "Police checks received"
            },
            references: {
                type: String,
                default: "References complete"
            },
            policeReferences: {
                type: String,
                default: "Police checks & references received"
            },
            started: {
                type: String,
                default: "Started work"
            },
            failed: {
                type: String,
                default: "Failed to start - archive"
            },
            noShowArchiveLabel: {
                type: String
            },
            doesntMeetCriteriaLabel: {
                type: String,
                default: "Ineligible"
            },
            unableToContactLabel: {
                type: String,
                default: "Attempted to contact - archive"
            },
            scheduleInterview: {
                type: String,
                default: "Schedule interview"
            },
            moreInfoRequested: {
                type: String,
                default: "More info requested"
            },
            inReview: {
                type: String,
                default: "In review"
            },
            candidateNotResponding: {
                type: String,
                default: "Candidate not responding - archive"
            },
            suitableToProgressLabel: {
                type: String,
                default: "Contacted - suitable to progress"
            },
            notSuitableToProgressLabel: {
                type: String,
                default: "Contacted - unsuitable to progress"
            },
            awaitingDecisionLabel: {
                type: String,
                default: "Job offered - awaiting decision"
            },
            attemptingContactLabel: {
                type: String,
                default: "Attempted to contact - try again"
            },
            statusComp: Object,
            job: Object,
            candidate: Object,
            data: {
                type: Object,
                default: function() {
                    return {
                        id: null
                    };
                }
            },
            recent: Number,
            currentuser: Object
        },
        methods: {
            closeCalendarModal() {
                this.showCalendar = false;
            },
            countDownSet(message) {
                Vue.swal.close();
                clearTimeout(this.timeoutHandle);
                this.countDown = 10;
                this.countDownTimer();
                Vue.swal({
                    html: message,
                    confirmButtonColor: "#363b54",
                    confirmButtonText: '<span class="countDownText" style="color: #f24c6d; font-weight: bold;">UNDO 10</span>',
                    showCancelButton: true,
                    cancelButtonColor: "#363b54",
                    cancelButtonText: '<img src="/images/svg/exit.svg">',
                    background: "#363b54",
                    customClass: {
                        container: "candidate-toast-undo"
                    },
                    showClass: {
                        popup: "animated fadeInLeft fast",
                        icon: "animated heartBeat delay-1s"
                    },
                    hideClass: {
                        popup: "animated fadeOutLeft slow"
                    },
                    toast: true,
                    timer: 10000,
                    position: "top-right",
                    target: "#example-tabs"
                }).then((result) => {
                        if (result) {
                            if (result.value) {
                                this.undoCountDown();
                            }
                        }
                    }
                )
            },
            undoCountDown() {
                var self = this;
                axios.post("/dashboard/candidates/undo-update", {
                    progress_id: this.statusData.id,
                    notification_id: this.notification_id,
                    previous_state: this.previous_state,
                    previous_progress: this.previous_progress
                }).then(function (response) {
                    self.$root.$emit("Event::DataUpdated");
                }).catch(function (error) {
                    console.log(error);
                });
            },
            candidateStartedDate(){
                this.showWork = false;
                if(!this.date2){
                    this.date2 = new Date(Date.now());
                }
                this.save();
            },
            candidateLeftDate(){
                this.showLeft = false;
                if(!this.date2){
                    this.date2 = new Date(Date.now());
                }
                this.save();
            },
            /**
             * click handler for candidate progress
             * It restricts user to follow the progress step by step.
             */
            onClickStatus(event) {
                if (!this.checkPermissions()) return;
                this.setAllowedStatus();
                if (
                    event.target.classList &&
                    event.target.classList.contains("allowed")
                ) {
                    this.showStatusOptions = true;
                } else if (
                    event.target.classList &&
                    event.target.classList.contains("not-allowed")
                ) {
                    this.showStatusOptions = false;
                } else {
                    this.showStatusOptions = false;
                }
            },
            setAllowedStatus() {
                var check = this.currentuser.candidate_check;
                $("#candidate-" + this.candidateId + " > .single-job-status").each(
                    function() {
                        const statusBox = $(this).find(".status-box");
                        if (!check || statusBox.hasClass("archived")) {
                          statusBox.addClass("not-allowed");
                          return false;
                        } else if (statusBox.hasClass("selected")) {
                            const statusBoxOptions = $(this).find(".arrow_box");
                            if (statusBoxOptions && statusBoxOptions.length) {
                                statusBox.addClass("allowed");
                                return false;
                            } else {
                                return;
                            }
                        }
                        if (statusBox.hasClass("not-selected")) {
                            statusBox.addClass("allowed");
                            return false;
                        }
                    }
                );
            },
            prettyTitle(title) {
                switch (title) {
                    case "Interview offered":
                        return "Contact made?";
                        break;

                    case "Interview booked":
                        return "Interview booked?";
                        break;

                    case "Interview successful":
                        return "Interview complete?";
                        break;

                    case "Job offered":
                        return "Job offered?";
                        break;

                    case "Started work":
                        return "Started work?";
                        break;

                    case "Employed for 12 months":
                        var milestone = this.currentuser.customer.milestone ? this.currentuser.customer.milestone : 12;
                        return (
                            "Is the candidate still working after " + milestone + ` month${milestone == 1 ? "" : "s"}?`
                        );
                        break;

                    default:
                        return title;
                        break;
                }
            },
            prettyStatus(status, title) {
                switch (status) {
                    case "no_show":
                        if (title == "Interview successful") {
                            return "No show - reschedule";
                            break;
                        } else if (title == "Job offered") {
                            return "Job offered - not accepted";
                            break;
                        }

                    case "yes":
                        if (title == "Interview successful") {
                            return "Interview successful";
                            break;
                        } else if (title == "Job offered") {
                            return "Job offered - accepted";
                            break;
                        } else if (title == "Started work") {
                            return "Started work";
                            break;
                        } else if (title == "Employed for 12 months") {
                            var milestone = this.currentuser.customer.milestone ? this.currentuser.customer.milestone : 12;
                            return "Candidate is still working after " + milestone + " months";
                            break;
                        }

                    case "no":
                        if (title == "Interview successful") {
                            return "Interview unsuccessful";
                            break;
                        } else if (title == "Job offered") {
                            return "Job not offered - archive";
                            break;
                        } else if (title == "Started work") {
                            return "Failed to start - archive";
                            break;
                        } else if (title == "Employed for 12 months") {
                            var milestone = this.currentuser.customer.milestone ? this.currentuser.customer.milestone : 12;
                            return "Candidate is not working after " + milestone + " months";
                            break;
                        }

                    case "police_checks_received":
                        return "Police checks received";
                        break;

                    case "references_complete":
                        return "References complete";
                        break;

                    case "police_checks_references_received":
                        return "Police checks & references";
                        break;

                    case "doesnt_meet_criteria":
                        return "Ineligible";
                        break;

                    case "no_show_archive":
                        return "No show - archive";
                        break;

                    case "unable_to_contact":
                        return "Attempted to contact - archive";
                        break;

                    case "not_suitable_to_progress":
                        return "Contacted - unsuitable to progress";
                        break;

                    case "suitable_to_progress":
                        return "Contacted - suitable to progress";
                        break;

                    case "awaiting_decision":
                        return "Job offered - awaiting decision";
                        break;

                    case "attempting_to_contact":
                        return "Attempted to contact - try again";
                        break;

                    case "more_info_requested":
                        return "More info requested";
                        break;

                    case "schedule_interview":
                        return "Schedule interview";
                        break;

                    case "in_review":
                        return "In Review";
                        break;

                    case "candidate_not_responding":
                        return "Candidate not responding - archive";
                        break;

                    default:
                        return status;
                        break;
                }
            },
            statusClass() {
                var status, title;
                if (this.touch && this.displayClass) {
                    status = this.displayClass;
                } else {
                    status = this.data.status;
                }
                if (this.data.stage) {
                    title = this.data.stage;
                } else {
                    title = this.stageName;
                }
                switch (status) {
                    case "yes":
                        return "green_box";
                        break;

                    case "no":
                        return "red_box";
                        break;

                    case "no_show":
                        if (title == "Job offered") {
                            return "red_box";
                            break;
                        } else {
                            return "orange_box";
                            break;
                        }

                    case "references_complete":
                        return "orange_box";
                        break;

                    case "police_checks_references_received":
                        return "orange_box";
                        break;

                    case "police_checks_received":
                        return "orange_box";
                        break;

                    case "no_show_archive":
                        return "red_box";
                        break;

                    case "doesnt_meet_criteria":
                        return "red_box";
                        break;
                    case "unable_to_contact":
                        return "red_box";
                        break;

                    case "suitable_to_progress":
                        return "green_box";
                        break;

                    case "not_suitable_to_progress":
                        return "red_box";
                        break;

                    case "awaiting_decision":
                        return "orange_box";
                        break;

                    case "attempting_to_contact":
                        return "orange_box";
                        break;

                    case "schedule_interview":
                        return "green_box";
                        break;

                    case "more_info_requested":
                        return "orange_box";
                        break;

                    case "in_review":
                        return "orange_box";
                        break;

                    case "candidate_not_responding":
                        return "red_box";
                        break;

                    default:
                        return "grey_box";
                        break;
                }
            },
            statusBoxClass() {
                var status, title, select, archived;
                if (this.touch && this.displayClass) {
                    status = this.displayClass;
                } else {
                    status = this.data.status;
                }

                if (this.data.stage) {
                    title = this.data.stage;
                } else {
                    title = this.stageName;
                }
                select = "selected ";
                archived = "archived ";
                switch (status) {
                    case "yes":
                        return select + "status-box";
                        break;

                    case "no":
                        return archived + "status-box";
                        break;

                    case "no_show_archive":
                        return archived + "status-box";
                        break;

                    case "doesnt_meet_criteria":
                        return archived + "status-box";
                        break;

                    case "unable_to_contact":
                        return archived + "status-box";
                        break;

                    case "suitable_to_progress":
                        return select + "status-box";
                        break;

                    case "not_suitable_to_progress":
                        return archived + "status-box";
                        break;

                    case "schedule_interview":
                        return select + "status-box";
                        break;

                    case "candidate_not_responding":
                        return archived + "status-box";
                        break;

                    case "no_show":
                        if (title == "Job offered") {
                            return archived + "status-box";
                            break;
                        }

                    default:
                        return "status-box not-selected";
                        break;
                }
            },
            classToUse() {
                if (this.touch) {
                    this.displayClass = this.selectedStatus;
                }
            },
            randomString(length) {
                var result = "";
                var characters =
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(
                        Math.floor(Math.random() * charactersLength)
                    );
                }
                return result;
            },
            createID() {
                if (this.candidateId) {
                    var id = this.candidateId;
                } else {
                    var id = this.randomString(15);
                }

                if (this.stageName == "Employed for 12 months") {
                    return id + "-employed-for-twelve-months";
                } else {
                    var noSpaces = this.stageName.replace(" ", "-");
                    return id + "-" + noSpaces.toLowerCase();
                }
            },
            setSavedStatus() {
                setTimeout(function() {
                    this.saved = "saved";
                }, 1000);
            },
            updateDate() {
                var self = this;
                var date = moment(this.editDate).format('YYYY-MM-DD');
                axios.post("/dashboard/candidates/edit-date", {id: this.data.id, date: date})
                    .then(function (response) {
                        if (response.data == 1) {
                            self.$root.$emit("Event::DataUpdated");
                            Vue.swal({
                                html:
                                    '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Date updated',
                                confirmButtonColor: "#5dc2a6",
                                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                background: "#5dc2a6",
                                customClass: {
                                    container: "candidate-toast"
                                },
                                showClass: {
                                    popup: "animated fadeInLeft fast",
                                    icon: "animated heartBeat delay-1s"
                                },
                                hideClass: {
                                    popup: "animated fadeOutLeft slow"
                                },
                                toast: true,
                                timer: 5000,
                                position: "top-right",
                                target: "#example-tabs"
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            save() {
                if (this.touch && this.selectedStatus) {
                    if (this.saved == "unsaved") {
                        if (this.date2) {
                            this.statusData.date = moment(this.date2).format('YYYY-MM-DD');
                        }
                        this.saved = "saving";
                        var self = this;
                        const candidateId = this.statusData.candidate_id.toString();
                        const candidateSelectedElement = document.getElementById("candidate-" + candidateId);
                        const elementDistanceToTop = candidateSelectedElement.offsetTop 
                            - (candidateSelectedElement.offsetHeight * 2);

                        axios
                            .post("/dashboard/condidates/progress/save", this.statusData)
                            .then(function (response) {
                                let message = 'Candidate updated';
                                if (response.data.state == 'archived') {
                                    message = 'Candidate archived';
                                }
                                self.statusData.id = response.data.candidate_progress_id;
                                self.notification_id = response.data.notification_id;
                                self.previous_state = response.data.previous_state;
                                self.previous_progress = response.data.previous_progress;
                                self.countDownSet(message);
                                self.saved = "saved";
                                self.showStatusOptions = false;
                                self.setAllowedStatus();
                                $(".selected").removeClass("allowed");
                                self.statusData.date = "";
                                self.data.date = "";
                                self.$root.$emit("Event::DataUpdated", elementDistanceToTop);
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                }
            },
            closeinterviewmodel() {
                if(!this.date2){
                    this.date2 = new Date(Date.now());
                }
                this.isShow = false;
                this.save();
            },
            calendarModal() {
                if(!this.editDate){
                    this.editDate = new Date(Date.now());
                }
                this.showCalendar = false;
                this.updateDate();
            },
            hidemodalinterview(id){
                this.selectedStatus = "";
                this.isShow = false;
                var x = '#modal-'+id;
                $(x).hide();
                $('.modal-backdrop').hide();
            },
            hidemodalstarted(id){
                this.selectedStatus = "";
                this.showWork = false;
                var x = '#modal-started-'+id;
                $(x).hide();
                $('.modal-backdrop').hide();
            },
            hideCalendarModal(id){
                this.showCalendar = false;
                var x = '#calendar-' + id;
                $(x).hide();
                $('.modal-backdrop').hide();
            },
            hideModalLeft(id) {
                this.selectedStatus = "";
                this.showLeft = false;
                var x = '#modal-left-' + id;
                $(x).hide();
                $('.modal-backdrop').hide();
            },
            clearSelectedStatus() {
                this.selectedStatus = "";
            },
            hideDrop() {
                $('.dropdown-pane').removeClass('is-open');
            },
            undoProgress(id) {
                Vue.swal({
                    title: 'Submitting...',
                    html: '',
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading()
                    },
                });
                var self = this;
                const candidateId = this.candidate.id.toString();
                const candidateSelectedElement = document.getElementById("candidate-" + candidateId);
                const elementDistanceToTop = candidateSelectedElement.offsetTop 
                    - (candidateSelectedElement.offsetHeight * 2);
                
                axios.post("/dashboard/candidates/undo-progress", { id: id, user_id: this.candidate.user_id })
                    .then(function (response) {
                        self.$root.$emit("Event::DataUpdated", elementDistanceToTop);

                        Vue.swal.close();
                        self.undoCheck = false;
                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Candidate updated',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });

                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
                /*.finally(function () {
                    Vue.swal.close();
                    self.undoCheck = false;
                    Vue.swal({
                        html:
                            '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Candidate updated',
                        confirmButtonColor: "#5dc2a6",
                        confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                        background: "#5dc2a6",
                        customClass: {
                            container: "candidate-toast"
                        },
                        showClass: {
                            popup: "animated fadeInLeft fast",
                            icon: "animated heartBeat delay-1s"
                        },
                        hideClass: {
                            popup: "animated fadeOutLeft slow"
                        },
                        toast: true,
                        timer: 5000,
                        position: "top-right",
                        target: "#example-tabs"
                    });
                });*/
            },
            countDownTimer() {
                if(this.countDown > 0) {
                    this.timeoutHandle = setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)

                    $('.countDownText').text('UNDO ' + this.countDown);
                }
            },
            clear() {
                this.touch = false;
                this.selectedStatus = "";
            },
            checkPermissions() {
              let result = false;
              const referrerId = this.candidate.user_id;
              if (!this.currentuser.account_check && referrerId === this.currentuser.id) {
                Vue.swal({
                  title: 'Unfortunately, you cannot update the status of this candidate as you are the referrer. Please speak to your Care Friends lead for help with this.',
                  confirmButtonColor: "#F84366",
                  width: '500px',
                });
              } else {
                result = true;
              }

              return result;
            },
            displayModalWithAdditionalQuestions(modalName) {
              if (!this.checkPermissions()) return;
              this[modalName] = true;
            },
            touchCard() {
              this.touch = true;
              this.hideDrop();
            },
        },
        data() {
            return {
                showStatusOptions: false,
                candidateDomProgressCount: 0,
                initalLoad: true,
                saved: "new",
                uid: this.createID(),
                displayClass: "",
                otherLabel: "",
                statusLabels: {
                    yes: "Yes",
                    no: "No",
                    no_show: "No Show"
                },
                selectedStatus: "",
                newToCare: this.data.new_to_care,
                statusData: {
                    candidate_id: this.candidateId,
                    created_at: this.data.created_at,
                    customer_id: this.job.customer_id,
                    date: '',
                    id: this.data.id,
                    new_to_care: this.data.new_to_care,
                    stage: this.stageName,
                    status: this.status,
                    updated_at: this.data.updated_at
                },
                date: "",
                // date2: new Date(Date.now()),
                date2: '',
                color1: "#ef5d72",
                color2: "#5dc2a6",
                color3: "#faab5f",
                isShow: false,
                touch: false,
                disabledDates: {
                    to: new Date(Date.now() - 8640000)
                },
                isActive: false,
                isDisplay: true,
                undoCheck: false,
                showWork: false,
                countDown : 10,
                timeoutHandle: null,
                notification_id: null,
                previous_state: null,
                previous_progress: null,
                showLeft: false,
                showCalendar: false,
                editDate: this.data.date,
                hover: false
            };
        },
        watch: {
            data: function(val) {
                // this.statusData = this.data; // Commented out delibrately to test bugs.
                // do nothing for the sake of testing.
            },
            status: function(val) {
                if (this.touch) {
                    this.selectedStatus = this.status;
                }
            },
            "statusData.date": function(val) {
                //if (val != "") {
                this.data.date = val;
                //}

                if (this.touch) {
                    this.saved = "unsaved";
                }
            },
            selectedStatus: function(val) {
                if (this.touch) {
                    this.classToUse();
                    this.statusData.status = this.selectedStatus;
                    this.saved = "unsaved";
                    if (!this.isShow && !this.showWork && !this.showLeft) {
                        this.save();
                    }
                }
            },
            newToCare: function(val) {
                this.classToUse();
            },
            candidateId: function(val) {
                this.statusData.candidate_id = val;
            },
            statusData: function(val) {
                if (this.statusData.id && this.initalLoad) {
                    this.saved = "saved";
                    this.initalLoad = false;
                }
            }
        },
        mounted: function() {
            // this fixes the issue of Progression click pop-up after switching tabs.
            $(this.$el).foundation();

            this.statusComp.$on("save", this.save);

            this.statusComp.$on("clear", this.clear);

            var self = this;
            $("." + this.uid + "-datpicker").datepicker({
                onSelect: function(selectedDate, datePicker) {
                    self.statusData.date = selectedDate;
                }
            });
        },
        computed: {
            btnConfirm() {
                if (this.date2) {
                    return false;
                } else return true;
            }
        }
    };
</script>

<style lang="css" scoped>
.stage-points {
    float: right;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    background: #5DC2A6;
    border-radius: 4px;
    padding: 1px 7px;
    margin-top: 1px;
}
</style>
