<template>
    <div class="auditlog-history-table">

	   <div class="table-top-area">
	       <div class="grid-x">
		    <div class="cell medium-8">
			    <div class="text_container">
                    <div class="r_padding_3" style="white-space: nowrap; padding-bottom:10px;">
                        <h3><strong>Audit: {{customer.name}} </strong></h3>
                    </div>
                </div>
			</div>

	        <div class="cell medium-5">
                <input class="jobs-search" placeholder="Search" v-model="searchVar" name="searchVar"
                       @keyup.delete="searchAuditLogData()" @keyup="searchAuditLog()">
            </div>

			<div class="cell small-12">
                <div class="cell small-6 pull-left bonus-history-top">
				   <div class="tot-num-bonus">{{ this.historyPaginationData.total }}</div>
				   <div class="total-points-bonus">Total Events</div>
				</div>
				<div class="cell small-6 pull-right bonus-history-top">

				 <div class="cell shrink"><a href="javascript:void(0)" @click="filtertoggle()" class="btn button orange_btn float-right bonus-filter-cls"> FILTERS <img src="/images/filter-icon.png" alt="img" /></a></div>
				 <div class="cell shrink"><a :href="'/dashboard/super/clients/audit/export/'+this.id+'?portal_filters='+this.portalId+'&event_filters='+eventId+'&search='+this.searchVar+'&start_date='+filterStartDate+'&end_date='+filterEndDate" class="btn button orange_btn float-right"> EXPORT </a></div>
				</div>
            </div>
			</div>

	        <div v-show="showFilter">
			 <div class="grid-x filters-wrappers">
		       <div class="cell small-2 padright" >
                    <div class="input-box">
					<label></label>
			        <date-range-picker
						v-model="dateRange"
						id="date-range-filter"
						ref="dateRangeFilter"

						opens="right"
						class="phone-recruiter"
						@keydown.enter='addTag'
						@keydown.188='addTag'
                        @select="addTag"
						@keydown.delete='removeLastTag'
					>
			         <!--    input slot (new slot syntax)-->
                    <template #input="picker"> <label class="datelabelclass">Date</label></template>

			        </date-range-picker>

	                </div>

	            </div>


                <div class="cell small-2 padright" >
                    <div class="input-box">
					  <v-select
					      class="phone-recruiter"
					      id="portal-filter"
					      ref='portalFilter'
					      @input="selectedPortalUser(data.portal_id)"
    					  v-model="data.portal_id"
    					  :reduce="referrer => referrer.id"
    					  label="name"
    					  :options="portaloptions"
    					  :close-on-select="true"
    					  multiple
						  taggable
    					  placeholder="Portal user"
    					>
                          <template slot="option" slot-scope="option">
                              {{ option.name }}
                              <img style="float: right" :src="option.image"/>
                          </template>
						</v-select>
			        </div>
               </div>

			   <div class="cell small-2 padright" >
                    <div class="input-box">
					  <v-select
					      id="event-filter"
					      ref="eventFilter"
						  class="phone-recruiter"
						  @input="selectedEvent(data.event_name)"
					  v-model="data.event_name"
					  :reduce="referrer => referrer.id"
					  label="name"
					  :options="eventoptions"
					  :close-on-select="true"
					  multiple
					  taggable
					  placeholder="Event name"
					>
					<template slot="option" slot-scope="option">
                              {{ option.name }}
                              <img style="float: right" :src="option.image"/>
                    </template>
					</v-select>
			        </div>
               </div>

			 </div>

			 <!--for displaying tags -->
			 <div class="grid-x" v-if="selected_date_tags != ''">
			    <div class=" tag-input__tag">
				 <span class="lablesize">{{selected_date_tags}}</span>
				 <span class="deselect-pad"><button class="deselect" @click="removeDateTags()"></button></span>

				</div>
			 </div>
			 <div class="grid-x" v-if="selected_portaluserto_tags != ''">
			    <div class=" tag-input__tag">
				 <span class="lablesize">{{selected_portaluserto_tags}}</span>
				 <span class="deselect-pad"><button class="deselect" @click="removePortaltoTags()"></button></span>

				</div>
			 </div>
			 <div class="grid-x" v-if="selected_events_tags != ''">
			    <div class=" tag-input__tag">
				 <span class="lablesize">{{selected_events_tags}}</span>
				 <span class="deselect-pad"><button class="deselect" @click="removeEventsTags()"></button></span>

				</div>
			 </div>

            </div>

	   </div>





        <div class="cf-table">
            <!-- BONUS POINT HISTORY -->
            <div class="grid-x grid-padding-x">
                <div class="cell"></div>
                <div class="cell">

                </div>
            </div>
            <!-- Audit log HEADER-->
            <div class="grid-x grid-padding-x cf-table-head t_margin_1 align-middle">
                <div class="cell auto grey" >
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': dateActive}">Date </strong></p>
                </div>
                <div  class="cell auto grey">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': toActive}">Portal User </strong></p>
                </div>
                <div class="cell auto grey" align="center">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': pointActive}">Event Name </strong></p>
                </div>

				<div class="cell auto grey" align="center">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': msgActive}">Detail</strong></p>
                </div>

                <div  class="cell auto grey category" align="center">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': catActive}">Points Impact </strong></p>
                </div>
                <div class="cell auto grey" align="center">
                    <p class="tb_margin_1"><strong v-bind:class="{'sort-active': byActive}">Affected User </strong></p>
                </div>

            </div>

			<div v-show="showLoad" class="show-load-app">
              <img :src="myImage">
            </div>

            <div v-for="(transaction, index) in userDatas">
                <div class="grid-x grid-padding-x align-middle b_border_b1" v-if="pointdataloading" >
                    <div class="cell auto" style="width: 5%">
                        <p class="tb_margin_1">{{ transaction.created_at| moment("DD/MM/YYYY HH:mm:ss") }} GMT</p>
                    </div>
                    <div class="cell auto" align="center">
                        <p class="tb_margin_1 cursor-custom" v-b-modal="'modal-' + index"><strong class="user-clickable">{{ transaction.portal_user }}</strong></p>
                    </div>
                    <div class="cell auto" align="center">
                        <p class="tb_margin_1">{{ transaction.event_name }}</p>
                    </div>

					<div class="cell auto formatdetail" align="center">

                        <b-tooltip :target="'tool-'+index"><div v-html="transaction.detail"></div></b-tooltip>
                        <div :id="'tool-'+index" variant="outline-success" class="bonus-points-view-message">
                            {{ transaction.detail }}
                        </div>
                    </div>

                    <div class="cell auto" align="center">
                        <p class="tb_margin_1"  v-if="transaction.points_impact !== null">
						{{ transaction.points_impact }}
						</p>
						<p class="tb_margin_1"  v-else>
						N/A
						</p>
                    </div>
                    <div class="cell auto" align="center">
                        <p class="tb_margin_1"  v-if="transaction.affected_users !== null">{{ transaction.affected_users }}
						</p>
						<p class="tb_margin_1" v-else>{{ transaction.affected_users }}
						</p>
                    </div>

                </div>

            </div>

            <div class="pagination-container margin-top-2 test-2">
                <template v-if="historyPaginationData.last_page > 1">
                    <paginate
                        :page-count="historyPaginationData.last_page"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="loadauditlog"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :prev-class="'paginate-controls'"
                        :next-class="'paginate-controls'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :active-class="'active'">
                    </paginate>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import Paginate from "vuejs-paginate";
    import DateRangePicker from 'vue2-daterange-picker'
    //you need to import the CSS manually (in case you want to override it)
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        components: {
            paginate: Paginate,
			DateRangePicker :DateRangePicker,
        },
        props: ['link', 'customer', 'id'],
        data(){
            return {
				 tags: [],
				dateRange: {
				  startDate: '',
				  endDate: '',
				},
                userDatas: this.userDatas,
                fetchUserPaginationData: [],
                fetchUserData: [],
                currentSort:'created_at',

                currentSortDir:'SORT_DESC',
                loading: false,
                pointdataloading: false,
                searchVar: '',
				 val: 0,

        userCount: 0,
		portalId: [],
		eventId: [],
		test:'',
		dateActive: false,
		toActive: false,
		pointActive:false,
		byActive:false,
		catActive:false,
		msgActive:false,
		data: {
				portal_id: "",
				event_name: "",
			  },
         portaloptions: [],
		 eventoptions : [],

		 showLoad: false,
         myImage: '/images/loading-circle.gif',
		 showFilter: false,
		 filterStartDate : '',
		 filterEndDate : '',
		 total_points:0,
		 ranges: {
            default () {
              let today = new Date()
              today.setHours(0, 0, 0, 0)

              let yesterday = new Date()
              yesterday.setDate(today.getDate() - 1)
              yesterday.setHours(0, 0, 0, 0);

              let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
              let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

              return {
                'Today': [today, today],
                'Yesterday': [yesterday, yesterday],
    			'This week': [yesterday, yesterday],
    			'Last week': [yesterday, yesterday],
                'This month': [thisMonthStart, thisMonthEnd],
                'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
              }
            }
          },
                // Handling tabs pagination in Vuejs way
                historyPaginationData: {
                    current_page: 0,
                    from: 0,
                    last_page: 0,
                    per_page: 0,
                    to: 0,
                    total: 0,
                },

                selected_portaluserto_tags: '',
                selected_events_tags: '',
                selected_awardedby_tags:'',
                selected_date_tags: '',
            }
        },

        methods: {
		 removeDateTags: function(){
		    //this.selected_date_tags = '';
			this.removeTag(9);


         },
		 removePortaltoTags: function(){
            this.$refs.portalFilter.clearSelection();
            this.selected_portaluserto_tags = '';
			 let i;
             if (this.portaloptions) {
                 for (i = 0; i < this.portaloptions.length; i++) {
                     if (this.portaloptions[i].image == '/images/selected.png') {
                         this.portaloptions[i].image = '/images/idle.png';
                     }
                 }
             }
         },
         removeEventsTags: function(){
            this.$refs.eventFilter.clearSelection();
            this.selected_events_tags = '';
			 let i;
			if (this.eventoptions) {
                 for (i = 0; i < this.eventoptions.length; i++) {
                     if (this.eventoptions[i].image == '/images/selected.png') {
                         this.eventoptions[i].image = '/images/idle.png';
                     }
                 }
             }
         },

		 selectedPortalUser: function(value) {
		   this.portalId = value;

          if (this.userCount > 0 && this.portalId.length > 0 && this.portalId.length < this.userCount) {
              if(this.portalId.length % 2 == 0) {
                  this.val -= 4;
              }
              this.userCount = this.portalId.length;
          } else if (this.portalId.length == 0) {
              this.val = 0;
              this.userCount = 0;
          } else if (this.portalId) {
              if(this.portalId.length % 2 == 0) {
                  this.val += 4;
              }
              this.userCount = this.portalId.length;
          }

            /**
             * Code to managed selected tags for Awarded To
             */
            let selected_portaluserto = [];

            let i;
            for (i = 0; i < this.portaloptions.length; ++i) {
                if($.inArray( this.portaloptions[i].id, this.portalId ) !== -1){
                    this.portaloptions[i].image = '/images/selected.png';
                    selected_portaluserto.push(this.portaloptions[i].name);
                }
            }
            if(selected_portaluserto.length > 0){
                this.selected_portaluserto_tags = 'Portal User: ' + selected_portaluserto.join(", ");
            }

            /**
             * Ends Code to managed selected tags for Awarded To
             */

		 this.loadauditlog();
		  },

		 selectedEvent: function(value) {
          this.eventId = value;

          if (this.userCount > 0 && this.eventId.length > 0 && this.eventId.length < this.userCount) {
              if(this.eventId.length % 2 == 0) {
                  this.val -= 4;
              }
              this.userCount = this.eventId.length;
          } else if (this.eventId.length == 0) {
              this.val = 0;
              this.userCount = 0;
          } else if (this.eventId) {
              if(this.eventId.length % 2 == 0) {
                  this.val += 4;
              }
              this.userCount = this.eventId.length;
          }

            /**
             * Code to managed selected tags for Events
             */
            let selected_events = [];

            let i;
            for (i = 0; i < this.eventoptions.length; ++i) {
                if($.inArray( this.eventoptions[i].id, this.eventId ) !== -1){
                    //console.log('selected name: ', this.eventoptions[i].name);
					this.eventoptions[i].image = '/images/selected.png';
                    selected_events.push(this.eventoptions[i].name);
                }
            }
            if(selected_events.length > 0){
                this.selected_events_tags = 'Event: ' + selected_events.join(", ");
            }
            /**
             * Ends Code to managed selected tags for Category
             */

		 this.loadauditlog();
      },

            searchAuditLog: function () {
                if(this.searchVar.length > 2) {
                    var init = true;
                    this.showLoad=true;
                    this.pointdataloading=false;

                    let searchTerm = '';
                    if(this.searchVar.length > 2) {
                        searchTerm = this.searchVar;
                    }
                    this.loadauditlog();
                }
            },
            searchAuditLogData: function () {
                if(this.searchVar.length <= 2) {
                    this.loadauditlog();
                } else {
                    this.loadauditlog();
                }
            },

            showMenu() {
                $(this.$el).foundation();
            },

            /**
             * Send AJAX request to get the client Audit log data
             */
            loadauditlog(page, init) {
				this.showLoad = true;
                this.pointdataloading=false;

                let searchTerm = '';
				let searcheventId = '';
				let searchportaluser = '';
				let searchStartDate = '';
				let searchEndDate = '';


                if(this.searchVar.length > 2) {
                    searchTerm = this.searchVar;
                }

				if(this.portalId.length > 0) {
                    searchportaluser = this.portalId;
                }

				if(this.eventId.length > 0) {
                    searcheventId = this.eventId;
                }

				if(this.filterStartDate.length > 0) {
                    searchStartDate = this.filterStartDate;
                }
				if(this.filterEndDate.length > 0) {
                    searchEndDate = this.filterEndDate;
                }

    axios.get('/dashboard/super/clients/audit/'+this.id, { params:{
                        'audit_page': page,
                        'search': searchTerm,
						'portal_filters': searchportaluser,
						'event_filters': searcheventId,
						'start_date': searchStartDate,
						'end_date': searchEndDate
                    }}).then((response) => {
					//console.log(response.data);
                    this.userDatas = response.data.auditLogdata;
					this.total_points = response.data.total_points;


                    if(init) {
                        this.fetchUserData = response.data.auditLogdata;
                        this.paginationData = response.data.pagination;
                    }
                    this.pagination = response.data.pagination;

                    if (response.data.hasOwnProperty('pagination')) {
                        let paginationArr = response.data.pagination;

                        this.historyPaginationData.current_page = paginationArr.current_page;
                        this.historyPaginationData.from = paginationArr.from;
                        this.historyPaginationData.last_page = paginationArr.last_page;
                        this.historyPaginationData.per_page = paginationArr.per_page;
                        this.historyPaginationData.to = paginationArr.to;
                        this.historyPaginationData.total = paginationArr.total;
                    }
                     this.showLoad = false;
                    //this.loading=false;
                    this.pointdataloading=true;

                }).catch((error) => {
                    console.log(error);
                });
            },
            getPostBody (post) {
               // let body = this.stripTags(post);
                return post.length > 15 ? post.substring(0, 15) + '...' : post;
            },
			stripTags (markup) {
				const temp = document.createElement("div");
				temp.innerHTML = markup;
				return temp.textContent || temp.innerText || "";
			},
            removeptag(markup) {
				return markup.replace(/(<p)/igm, '<div').replace(/<\/p>/igm, '</div>');
			},
			loadportaldropdowns() {
                axios.get('/dashboard/super/clients/get-portal-users/'+this.id, { params:{

                    }}).then((response) => {
					 //console.log(response.data);
					for (var i =0; i<response.data.portalUsers.length; i++) {
							var site = {
								name: response.data.portalUsers[i].name,
								id: response.data.portalUsers[i].id,
								image: '/images/idle.png'
							}
						this.portaloptions.push(site);
					}

                }).catch((error) => {
                    console.log(error);
                });
            },
			loadeventdropdowns() {
                axios.get('/dashboard/super/clients/get-events/'+this.id, { params:{

                    }}).then((response) => {
					for (var i =0; i<response.data.eventData.length; i++) {
							var event = {
								name: response.data.eventData[i].event_name,
								id: response.data.eventData[i].event_name,
								image: '/images/idle.png'
							}
						this.eventoptions.push(event);
					}

                }).catch((error) => {
                    console.log(error);
                });
            },

			filtertoggle(){
			   if(this.showFilter==true){
			      this.showFilter=false;
			   }else{
			      this.showFilter=true;
			   }
			},

			addTag (event) { //alert(event.endDate);

			   $(".cancelBtn").trigger("click");
				// alert(event.startDate.toLocaleDateString());
				var newstartDate = event.startDate.getDate();
				var newstartMonth =event.startDate.getMonth() + 1;
				var newstartYear =event.startDate.getFullYear();
				var FinalStartDate = newstartYear+"-"+newstartMonth+"-"+newstartDate;
				var newendDate = event.endDate.getDate();
				var newendMonth =event.endDate.getMonth() + 1;
				var newendYear =event.endDate.getFullYear();
				var FinalEndDate = newendYear+"-"+newendMonth+"-"+newendDate;

				var Difference_In_Days = (event.endDate-event.startDate) / (1000 * 3600 * 24);

				this.filterStartDate = FinalStartDate;
				this.filterEndDate = FinalEndDate;
				var d = new Date();
				var currentDate = d.getDate();
				var currentMonth = d.getMonth() + 1;
				var currentYear = d.getFullYear();
				// this method evaulate 'This Month' or 'Last Month' conditions.
				let monthSpan = this.evaluateMonthSpan(newstartMonth, Difference_In_Days, newstartDate, newstartYear);


				if(FinalStartDate==FinalEndDate){

				var todayDate = currentYear+"-"+currentMonth+"-"+currentDate;
				var yesDate = currentYear+"-"+currentMonth+"-"+(currentDate-1);

					  if(FinalStartDate==todayDate){
					   this.removeTag(this.tags.length - 1);
					   this.tags.push("Today  ");
					  }else if(FinalStartDate==yesDate){
					   this.removeTag(this.tags.length - 1);
					   this.tags.push("Yesterday ");
					  }else{
					   this.removeTag(this.tags.length - 1);
					   this.tags.push(FinalStartDate+' to '+FinalEndDate);
					 }
				 }
				 else if(Difference_In_Days>1 && Difference_In_Days<28){
				    this.removeTag(this.tags.length - 1);
				    this.tags.push(FinalStartDate+' to '+FinalEndDate);
				 }
				 else if(currentMonth == newstartMonth && Difference_In_Days > 28 && monthSpan){


				    this.removeTag(this.tags.length - 1);
				    this.tags.push("This Month ");
				 }
				 else if(currentMonth - 1 == newstartMonth && Difference_In_Days > 28 && monthSpan){
				    this.removeTag(this.tags.length - 1);
				    this.tags.push("Last Month ");
				 }
				 else if(currentYear && Difference_In_Days==365){
				  this.removeTag(this.tags.length - 1);
				    this.tags.push("This year ");
				 }
				 else{
				    this.removeTag(this.tags.length - 1);
				    this.tags.push(FinalStartDate+' to '+FinalEndDate);
				 }
				 this.selected_date_tags = 'Date: ' + this.tags[0];
				// this.loadauditlog();
				 /* var val = event.target.value.trim()
				  if (val.length > 0) {
					this.tags.push(val)
					event.target.value = ''
				  }*/
			},

			removeTag (index) {
			    if (index === 9){
                    this.filterStartDate = '';
                    this.filterEndDate = '';
                }
                this.selected_date_tags = '';
                this.tags.splice(index, 1);
                this.loadauditlog();
			},
			removeLastTag(event) {
			    console.log('866 - removeLastTag: ', event.target);
				  if (event.target.value.length === 0) {

				  this.filterStartDate='';
				  this.filterEndDate='';
					this.removeTag(this.tags.length - 1);

				  }
			},

			evaluateMonthSpan: function(newstartMonth, Difference_In_Days, newstartDate, newstartYear){

			    // Transform below code to find total days in month.
				let monthTotalDays = [
    				    31, // Jan
    				    28, // Feb
    				    31, // March
    				    30, // April
    				    31, // May
    				    30, // June
    				    31, // July
    				    31, // Aug
    				    30, // Sep
    				    31, // Oct
    				    30, // Nov
    				    31, // Dec
				    ]

				let monthIndex = newstartMonth-1;
				let monthDays = monthTotalDays[monthIndex];
				let totalSelectedSpan = Difference_In_Days+1; // catering previous dev coded result.

                // February Case
                if(newstartMonth == 2){
                    // When it is leap year like 2020, Feb would be off 29
                    if(newstartYear%4 == 0 && totalSelectedSpan == 29 && newstartDate == 1){
                        return true;
                    }else if(newstartYear%4 != 0 && totalSelectedSpan == 28 && newstartDate == 1){
                        return true;
                    }
                    return false;
                } else {
			        // Non February Cases
			        if(totalSelectedSpan == monthDays  && newstartDate == 1){
			            return true;
			        }
			        return false;
			    }
			},
	    },


        mounted(){
			this.loadeventdropdowns();
			this.loadportaldropdowns();
            this.loadauditlog(this.getParamsFromUrl('audit_page', window.location.href), true);
        }
    }
</script>
<style>
.formatdetail{
 word-break: break-word;
}
.daterangepicker .drp-buttons {
    display: none !important;
}
.datelabelclass{
   height: 20px;
   font-family: InterUI;
   font-size: 13px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.54;
   letter-spacing: normal;
   color: #85899e !important;

}
.reportrange-text{
	height: 44px;
	border-radius: 4px;
	border: solid 1px #dcdee6;
	background-color: #ffffff;
	color: #363b54;
	font: 400 13px "InterUI", sans-serif;
	text-transform: capitalize;
	width: 150px !important;
	background: url(/images/svg/select_dropdown.png) no-repeat right center !important;
}
.deselect{
  width:24px;
  height:24px;
 background: url(/images/exit.png) no-repeat right center !important;
 display: inline-flex;
 cursor: pointer;
}
.show-load-app{
 top:80% !important;
}
.padright{
  padding-bottom: 10px;
}
.btn_award{
 margin-bottom:15px !important;
 margin-top:-26px;
}
.bonus-history-top{
 width:50%;
 height: 68px;
 padding-top:16px;
 padding-bottom:16px;
}
.bonus-filter-cls{
 margin-left:12px;
}
.tot-num-bonus
 {
  width: 39px;
  height: 24px;
  font-family: InterUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363b54;
}
.total-points-bonus {
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #85899e;
}
.daterangepicker.show-calendar .ranges {
    margin-top: 8px;
    float: left;
}
.fa-calendar{
  display:none;
}
.auditlog-history-table {
    width:100%;
    margin: 0 auto;
    display: block;
    overflow: hidden;

    padding: 0px 0px 20px 0px;
    min-height: 520px;
}
.auditlog-history-table .table-top-area {
    padding-right: 20px;
    padding-left: 20px;
}
.auditlog-history-table .cf-table{
    margin-top: 9px;
}
    .spiner-example {
        height: 200px;
        padding-top: 70px;
    }
    .sk-spinner-wave.sk-spinner {
        margin: 0 auto;
        width: 50px;
        height: 30px;
        text-align: center;
        font-size: 10px;
    }
    .sk-spinner-wave div {
        background-color: #ED5475;
        height: 100%;
        width: 6px;
        display: inline-block;
        -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
        animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    }
    .sk-spinner-wave .sk-rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }
    .sk-spinner-wave .sk-rect3 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }
    .sk-spinner-wave .sk-rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }
    .sk-spinner-wave .sk-rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }
    @-webkit-keyframes sk-waveStretchDelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
        }
        20% {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
    @keyframes sk-waveStretchDelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
        }
        20% {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9em;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
}
span.lablesize{
  height: 16px;
  font-family: InterUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #656a85;
  padding: 8px 2px 8px 10px;
  display: block;
  float: left;
}
.deselect-pad{
  padding-top: 5px;
  display: inline-table;
  cursor: pointer;
  opacity: 0.75;
}
.tag-input__tag {
  height: 32px;
  border-radius: 16px;
  background-color: #f0f1f7;
  margin-bottom: 10px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 0.9em;
  line-height: 50px;
  background: none;
}
.auditlog-history-table .vue-daterange-picker.phone-recruiter .reportrange-text label{
    margin-top: 6px;
}
.auditlog-history-table .input-box .vs__selected-options{
    top: 0px;
  }
.auditlog-history-table .input-box .vs__selected-options:after {
	content: "";
	display: table;
	clear: both;
	}

element {

    margin: 10px 8px;

}


.daterangepicker .calendar-table th{
   height: 24px;
  font-family: InterUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #363b54;
}
 .daterangepicker .calendar-table tbody th{
    height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #363b54;
}
.daterangepicker .calendar-table td{
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #85899e;
}
.daterangepicker td.active, .daterangepicker td.active:hover{
 background-color: #ef5d72 !important;
   font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  width:26px;
  height:26px;
  border-radius:50% !important;
}
.daterangepicker .ranges li{
  padding:11px 0;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #363b54;
}
.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date{
color: #FFF;
}
.daterangepicker .calendar-table td, .daterangepicker .calendar-table th{
  width:36px;
  height:36px;
  min-width:36px;
}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
    color: #85899e;
    border: solid #85899e;
	border-width: 0 2px 2px 0;
	}
	.v-select .dropdown-toggle .clear {
    display: none;
}
.phone-recruiter .span.vs__selected{display:none;}
.phone-recruiter .vs__selected{ display:none !important; }
.daterangepicker.show-ranges.show-weeknumbers, .daterangepicker.show-ranges{
min-width:702px;
}
.daterangepicker.opensright[data-v-4f8eb193]{
 left:0px !important;
}
.sort-active{
  border-bottom: 2px solid #c9cbda;
  }

.filters-wrappers .cell:first-child{
    max-width: 91px;
    padding-right: 12px;
}
.filters-wrappers .reportrange-text{
    max-width:79px;

}
.filters-wrappers .cell:nth-child(2){
    max-width: 133px;
    padding-right: 12px;
}
.filters-wrappers .cell:nth-child(3){
    max-width: 119px;
    padding-right: 12px;
}
.filters-wrappers .cell:last-child{
    max-width: 136px;
    padding-right: 12px;
}
.filters-wrappers .cell input[type=search]{
	color:#85899e;
}
.bonus-history-top{
	height:72px;
}

.filters-wrappers .daterangepicker td.off, .filters-wrappers .daterangepicker td.off.end-date, .filters-wrappers .daterangepicker td.off.in-range, .filters-wrappers .daterangepicker td.off.start-date{
    background-color: #fff !important;
}
.filters-wrappers .daterangepicker .calendar-table td{
    color: #363b54;
}
.filters-wrappers .daterangepicker .calendar-table tbody th {
    background: #f7f8fc;
    height: 36px;
}

.filters-wrappers .daterangepicker td.active, .filters-wrappers .daterangepicker td.active:hover {
    background-color: #fbd8dd !important;
    border-radius: 0 !important;
}

.filters-wrappers .daterangepicker td.active, .filters-wrappers .daterangepicker td.active:hover {
    background-color: inherit !important;
    border-radius: 0;
    position: relative;
z-index:2;
}
.filters-wrappers .daterangepicker td.active, .filters-wrappers .daterangepicker td:hover{
color:#FFF !important;
}
.filters-wrappers .daterangepicker td.active:after, .filters-wrappers .daterangepicker td:hover:after {
    width: 26px !important;
    content: '';
    height: 26px !important;
    background: #ef5d72 !important;
    display: block !important;
    position: absolute;
    left: 50% !important;
    margin-left: -13px;
    top: 50% !important;
    z-index: -1 !important;
    border-radius: 50%;
    margin-top: -13px;
}

.filters-wrappers .daterangepicker td.active:before, .filters-wrappers .daterangepicker td:hover:before {
    content: '';
    display: block;
    width: 50%;
    background: #fbd8dd;
    position: absolute;
    z-index: -1;
    left: -1px;
    top: 50%;
margin-top:-13px;
    height: 26px;
}
.filters-wrappers tbody tr:nth-child(2n){
background:none;
}
.filters-wrappers .daterangepicker .calendar-table td{
position:relative;
z-index:2;
}
.filters-wrappers .daterangepicker td.in-range{
background:none;
}
.filters-wrappers .daterangepicker td.in-range:after {
    content: '';
    background: #fbd8dd;
    display: block;
    position: absolute;
    left: -1px;
    width: calc(100% + 1px);
    height: 26px;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    z-index: -3;
}
.filters-wrappers tbody{
border:0;
}

.filters-wrappers .daterangepicker td.active.start-date:before, .filters-wrappers .daterangepicker td.active.start-date:hover:before {
    left: inherit;
    right: 0;
}
.daterangepicker.show-calendar .ranges{
padding: 0 0;
margin-top: 0;
}
.daterangepicker .ranges li {
padding: 11px 10px;
}
.filters-wrappers .daterangepicker td.active:before, .filters-wrappers .daterangepicker td:hover:before {
 background: none;
}
.filters-wrappers .daterangepicker td.end-date.active:before, .filters-wrappers .daterangepicker td.end-date:hover:before, .filters-wrappers .daterangepicker td.start-date.active:before, .filters-wrappers .daterangepicker td.start-date:hover:before {
    background: #FBD8DD;
}

.cell.auto.no-label.multi-bonus {
      position: relative;
}
.cell.auto.no-label.multi.multi-bonus .multiple_bonus {
   position: relative;
}
#app .modal .multi-bonus .multiple_bonus .vs__dropdown-toggle {
	height: auto;
	border: 0;
	padding: 50px 0 0 !important;
}
#app .modal .multi-bonus .multiple_bonus .vs__dropdown-toggle:before {
    content: '';
    border: 1px solid #DCDEE6;
    width: 100%;
    height: 42px;
    position: absolute;
    top: 0;
}
#app .modal .multi-bonus .multiple_bonus .vs__dropdown-toggle .vs__selected-options {
    top: 0;
    position: initial;
}
#app .modal .multi-bonus .multiple_bonus .vs__dropdown-toggle .vs__selected-options .vs__search {
    position: absolute;
    top: 0;
    left: 0;
}
#app .modal .multi-bonus .multiple_bonus .vs__dropdown-toggle .vs__actions {
    position: absolute;
    top: 0;
    right: 5px;
    height: 42px;
}
</style>
