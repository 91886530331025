export const fieldTypes = [
    {
        name: "Yes / No"
    },
    {
        name: "Single choice"
    },
    {
        name: "Multiple choice"
    },
    {
        name: "Short answer"
    }
];

export const emailTemplates = [
    {
        id: 1,
        name: "Candidate expresses interest",
        sender: "Natalie Johnson",
        subject: "Thank you - your details have been received",
        message: "<p>Hi [Candidate first name],</p><p>We've received your details and we are really pleased to hear you are interested in joining [Organisation name].</p><p>Someone from our team will be in touch very soon, but in the meantime, if you would like to find out a bit more about us, please check out our website [here].</p><p>Speak soon!</p><p>[Sender full name]<br>[Organisation name]</p>"
    },
    {
        id: 2,
        name: "Expression of interest rejected",
        sender: "Natalie Johnson",
        subject: "Unfortunately your application cannot be processed",
        message: "<p>Hi [Candidate first name],</p><p>Thank you for your application.</p><p>Unfortunately, you do not meet the criteria for this role and therefore we are unable to progress your application at this time.</p><p>All the best in your search for a job.</p><p>[Sender full name]<br>[Organisation name]</p>"
    },
];