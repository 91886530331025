<script>
export default {
  name: 'NotificationsIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8086 16.4945C17.7766 16.5515 17.7012 16.647 17.5571 16.647H6.44216C6.29947 16.647 6.22455 16.5535 6.19258 16.497C6.16443 16.4475 6.10669 16.3145 6.18686 16.158C6.33002 15.878 6.51469 15.6 6.70939 15.3055C7.27964 14.4445 7.92577 13.4685 7.92577 11.942V9.597C7.92577 7.1315 9.71098 5.2005 11.9901 5.2005C14.2515 5.2005 16.0239 7.1315 16.0239 9.597V11.942C16.0239 13.473 16.69 14.45 17.2779 15.312C17.4764 15.6035 17.6645 15.879 17.811 16.1555C17.8935 16.3105 17.8367 16.444 17.8086 16.4945ZM12.0049 18.8C11.4823 18.8 11.0533 18.384 10.9827 17.8475H13.0275C12.9569 18.384 12.5279 18.8 12.0049 18.8ZM18.8121 15.5725C18.6337 15.2365 18.4184 14.9205 18.2099 14.6145C17.6745 13.8295 17.1691 13.0885 17.1691 11.942V9.597C17.1691 6.4585 14.8943 4 11.9901 4C9.06866 4 6.78049 6.4585 6.78049 9.597V11.942C6.78049 13.093 6.28898 13.8355 5.76835 14.6215C5.56363 14.9305 5.35176 15.2505 5.17758 15.5905C4.92991 16.0745 4.94184 16.644 5.21003 17.1135C5.47201 17.573 5.93299 17.8475 6.44216 17.8475H9.83743C9.91378 19.0465 10.851 20 12.0049 20C13.1587 20 14.0964 19.0465 14.1723 17.8475H17.5571C18.071 17.8475 18.5339 17.569 18.7954 17.103C19.0622 16.628 19.0684 16.056 18.8121 15.5725Z" fill="currentColor"/>
  </svg>
</template>