export class TextReplacementService {
  constructor(dictionary) {
    this.dictionary = dictionary ?? {};
    this.text = "";
    this.current = 0;
  }

  interpolateText(text) {
    this.text = text;
    this.current = 0;

    for (const token of this._scan()) {
      let value = this._getReplacement(token.identifier);
      if (value) {
        value = this._applyModifiers(value, token.modifiers);
      }

      this._replaceSubstring(value, token.start, token.length);

      // Make sure we're not skipping ahead
      this.current = token.start;
    }

    return this.text;
  }

  lookup(key) {
    const keyComponents = key.split(".");

    const identifier = keyComponents[0].trim().toUpperCase();

    const modifiers = [];
    for (let i = 1; i < keyComponents.length; i++) {
      modifiers.push(keyComponents[i].trim());
    }

    let value = this._getReplacement(identifier);
    if (value) {
      value = this._applyModifiers(value, modifiers);
    }

    return value;
  }

  _getReplacement(key) {
    return this.dictionary[key] ?? "";
  }

  *_scan() {
    try {
      while (this.current < this.text.length) {
        const char = this._advance();

        // Check for the start of a token
        if (
          char === TextReplacementService.OPEN_DELIMITER[0] &&
          this._peek() === TextReplacementService.OPEN_DELIMITER[1]
        ) {
          const token = {};
          token.start = this.current - 1;

          // Get end index
          this._consume();
          token.length = this.current - token.start;

          this._parse(token);

          yield token;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  _advance() {
    return this.text[this.current++];
  }

  _peek() {
    return this.text[this.current];
  }

  _consume() {
    const start = this.current;

    while (
      !(
        this._advance() === TextReplacementService.CLOSE_DELIMITER[0] &&
        this._peek() === TextReplacementService.CLOSE_DELIMITER[1]
      )
    ) {
      // Check we've not reached the end of the text
      if (this.current >= this.text.length - 1) {
        throw new Error(
          `Expected closing '${TextReplacementService.CLOSE_DELIMITER}' after '${this.text.slice(0, start + 1)}'`
        );
      }
    }

    this._advance();
    return;
  }

  _parse(token) {
    const substring = this.text.slice(token.start + 2, token.start + token.length - 3);
    const tokenComponents = substring.split(".");

    token.identifier = tokenComponents[0].trim().toUpperCase();

    token.modifiers = [];
    for (let i = 1; i < tokenComponents.length; i++) {
      token.modifiers.push(tokenComponents[i].trim());
    }
  }

  _replaceSubstring(value, start, length) {
    this.text = this.text.slice(0, start) + value + this.text.slice(start + length);
  }

  _applyModifiers(value, modifiers) {
    modifiers.forEach(modifier => {
      value = this._applyModifier(value, modifier);
    });

    return value;
  }

  _applyModifier(value, modifier) {
    switch (modifier) {
      case "aa":
        return value.toLowerCase();
      case "AA":
        return value.toUpperCase();
      case "Aa":
        return value[0].toUpperCase() + value.slice(1).toLowerCase();
      default:
        return value;
    }
  }
}

TextReplacementService.OPEN_DELIMITER = "[[";
TextReplacementService.CLOSE_DELIMITER = "]]";
