<script>
export default {
  name: "PlusIcon",
};
</script>

<template>
  <svg width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5.5C11 4.94772 10.5523 4.5 10 4.5C9.44772 4.5 9 4.94772 9 5.5H11ZM9 14.5C9 15.0523 9.44771 15.5 10 15.5C10.5523 15.5 11 15.0523 11 14.5H9ZM14.5 11C15.0523 11 15.5 10.5523 15.5 10C15.5 9.44771 15.0523 9 14.5 9V11ZM5.5 9C4.94772 9 4.5 9.44771 4.5 10C4.5 10.5523 4.94772 11 5.5 11V9ZM18 10C18 14.4183 14.4183 18 10 18V20C15.5228 20 20 15.5228 20 10H18ZM10 18C5.58172 18 2 14.4183 2 10H0C0 15.5228 4.47715 20 10 20V18ZM2 10C2 5.58172 5.58172 2 10 2V0C4.47715 0 0 4.47715 0 10H2ZM10 2C14.4183 2 18 5.58172 18 10H20C20 4.47715 15.5228 0 10 0V2ZM9 5.5L9 10H11L11 5.5H9ZM9 10V14.5H11V10H9ZM14.5 9H10V11H14.5V9ZM10 9H5.5V11H10V9Z"
          fill="#6A6F8E" />
  </svg>
</template>
