<template>
    <div id="template-view"
         class="template-table">
        <div class="template-row">
            <div class="template-column template-column--with-input">
                <label class="template-label"
                       for="sender">
                    SENDER
                </label>
                <input type="text"
                       id="sender"
                       v-model="form.sender"
                       disabled />
            </div>
        </div>

        <div class="template-row">
            <div class="template-column template-column--with-input">
                <label class="template-label"
                       for="subject">
                    SUBJECT
                </label>
                <input type="text"
                       id="subject"
                       v-model="form.subject"
                       disabled />
            </div>
        </div>

        <div class="template-row">
            <div class="template-column template-column--with-textarea">
                <label class="template-label"
                       for="message">
                    MESSAGE
                </label>
                <div v-html="formattedHtmlMessage"
                     class="formatted-html-message"></div>
            </div>
        </div>
    </div>
</template>

<script>
import CKEditor from "ckeditor4-vue/dist/legacy.js";

export default {
    name: 'ViewEmailTemplateForm',
    props: {
        currentuser: { type: Object, required: true },
        template: { type: Object, default: null }
    },
    components: { CKEditor },
    data() {
        return {
            form: {
                sender: null,
                subject: null,
                message: null
            },
            formattedHtmlMessage: ''
        };
    },
    methods: {
        formatMessageWithBoldHtml(html) {
            let formattedHtml = html
                .replace(/\[Candidate first name\]/g, '<strong>[Candidate first name]</strong>')
                .replace(/\[Sender full name\]/g, '<strong>[Sender full name]</strong>')
                .replace(/\[Title\]/g, '<strong>[Organisation name]</strong>')
                .replace(/\[Organisation name\]/g, '<strong>[Organisation name]</strong>');
            return formattedHtml;
        }
    },
    async created() {
        if (this.template) {
            this.form = {
                ...this.template
            };

            this.formattedHtmlMessage = this.formatMessageWithBoldHtml(this.form.message);
        }
    }
};
</script>

<style scoped lang="scss">
@import "./ViewEmailTemplateForm.scss";
</style>