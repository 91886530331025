var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"add-job notification-modal-main-div"},[_c('div',[_c('b-modal',{ref:"edit-category",staticClass:"modal-p-div",attrs:{"id":"edit-category-modal","size":"xl","no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return undefined}}])},[_vm._v(" "),_c('section',{staticClass:"multi-step-row multi-step-p"},[_c('div',{staticClass:"pop-up-box pop-up-box-styling"},[_c('div',{staticClass:"top-check-row"},[_c('ul',[_c('li',{class:{
                    selected: _vm.isActive(1),
                    completed: _vm.done1,
                    selected: _vm.active1,
                  }},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.step = 1}}},[_vm._v("1")])]),_vm._v(" "),_c('li',{class:{
                    selected: _vm.isActive(2),
                    completed: _vm.done2,
                    selected: _vm.active2,
                  }},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.step = 2}}},[_vm._v("2")])])])]),_vm._v(" "),_c('div',{staticClass:"text-box",attrs:{"id":"text-box"}},[_c('p',{staticClass:"add-a-job"},[_vm._v("EDIT BONUS POINTS CATEGORY")]),_vm._v(" "),_c('p',{staticClass:"key-center"},[(_vm.step == 1)?_c('span',{staticClass:"key-details"},[_vm._v("Category and points")]):_vm._e(),_vm._v(" "),(_vm.step == 2)?_c('span',{staticClass:"key-details"},[_vm._v("Content")]):_vm._e()]),_vm._v(" "),(_vm.step == 1)?_c('fieldset',[_c('div',{staticClass:"job-leftside"},[(_vm.imageName != '')?_c('div'):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"main-form-div"},[_c('div',{staticClass:"job-leftside"},[(_vm.imageName != '')?_c('div'):_vm._e()]),_vm._v(" "),_c('form',{staticClass:"form-dropdown-styling justify-content-center"},[_c('div',{staticClass:"form-group input-sendto"},[_c('label',{staticClass:"bonus-form-lable",attrs:{"for":"exampleFormControlSelect1"}},[_vm._v("CATEGORY NAME")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content.name),expression:"content.name"}],attrs:{"type":"text","placeholder":"Add name"},domProps:{"value":(_vm.content.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.content, "name", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"bonus-form-lable-POINTS",attrs:{"for":"exampleFormControlSelect1"}},[_vm._v("POINTS")]),_vm._v(" "),_c('div',{staticClass:"radio-btn-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content.points_type),expression:"content.points_type"}],style:([
                            _vm.content.points_type == 'suggested'
                              ? { border: '1px solid #5dc2a6 !important' }
                              : { border: '1px solid #D1D3E0 !important' } ]),attrs:{"type":"radio","id":"suggestedstart","value":"suggested"},domProps:{"checked":_vm.content.points_type == 'suggested',"checked":_vm._q(_vm.content.points_type,"suggested")},on:{"change":function($event){return _vm.$set(_vm.content, "points_type", "suggested")}}}),_vm._v(" "),_c('label',{staticClass:"Suggested",attrs:{"for":"suggestedStart"}},[_vm._v("Suggested")]),_c('br'),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content.points_type),expression:"content.points_type"}],style:([
                            _vm.content.points_type == 'fixed'
                              ? { border: '1px solid #5dc2a6 !important' }
                              : { border: '1px solid #D1D3E0 !important' } ]),attrs:{"type":"radio","id":"fixedStart","value":"fixed"},domProps:{"checked":_vm.content.points_type == 'fixed',"checked":_vm._q(_vm.content.points_type,"fixed")},on:{"change":function($event){return _vm.$set(_vm.content, "points_type", "fixed")}}}),_vm._v(" "),_c('label',{staticClass:"Fixed",attrs:{"for":"fixedStart"}},[_vm._v("Fixed")]),_c('br')])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content.points),expression:"content.points"}],attrs:{"type":"number","placeholder":"Add points","oninput":"validity.valid||(value='');"},domProps:{"value":(_vm.content.points)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.content, "points", $event.target.value)}}}),_vm._v(" "),(_vm.content.points_type != 'fixed')?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bonus-form-lable-max-points",attrs:{"for":"exampleFormControlSelect1"}},[_vm._v("MAX POINTS")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content.max_points),expression:"content.max_points"}],attrs:{"type":"number","placeholder":"Add max","oninput":"validity.valid||(value='');"},domProps:{"value":(_vm.content.max_points)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.content, "max_points", $event.target.value)}}})]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"cell auto popup-button pad-top"},[_c('input',{staticClass:"next action-button button btn_lg btn pink_btn",attrs:{"type":"button","name":"next","value":"Next","disabled":!_vm.validateStepOne},on:{"click":function($event){_vm.step = 2}}})])]):_vm._e(),_vm._v(" "),(_vm.step == 2)?_c('fieldset',[_c('form',[_c('div',{staticClass:"modal-styling-content"},[_c('div',{staticClass:"radio-btn-suggested-fixed"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content.content_type),expression:"content.content_type"}],style:([
                          _vm.content.points_type == 'fixed'
                            ? { border: '1px solid #5dc2a6 !important' }
                            : { border: '1px solid #D1D3E0 !important' } ]),attrs:{"type":"radio","id":"suggestededit","value":"suggested"},domProps:{"checked":_vm._q(_vm.content.content_type,"suggested")},on:{"change":function($event){return _vm.$set(_vm.content, "content_type", "suggested")}}}),_vm._v(" "),_c('label',{staticClass:"radio-Suggested",attrs:{"for":"suggestededit"}},[_vm._v("Suggested")]),_c('br'),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content.content_type),expression:"content.content_type"}],style:([
                          _vm.content.points_type == 'fixed'
                            ? { border: '1px solid #5dc2a6 !important' }
                            : { border: '1px solid #D1D3E0 !important' } ]),attrs:{"type":"radio","id":"fixededit","value":"fixed"},domProps:{"checked":_vm._q(_vm.content.content_type,"fixed")},on:{"change":function($event){return _vm.$set(_vm.content, "content_type", "fixed")}}}),_vm._v(" "),_c('label',{staticClass:"radio-fixed",attrs:{"for":"fixededit"}},[_vm._v("Fixed")]),_c('br')]),_vm._v(" "),_c('div',{staticClass:"bonus-form-lable"},[_c('label',{staticClass:"bonus-form-lable",attrs:{"for":"exampleInputEmail1"}},[_vm._v("TITLE *")]),_vm._v(" "),_c('ckeditor',{staticClass:"cheditor-styling-titleBonus",attrs:{"id":"exampleFormControlTextareaBonus","config":_vm.titleEditorConfig},model:{value:(_vm.content.Title),callback:function ($$v) {_vm.$set(_vm.content, "Title", $$v)},expression:"content.Title"}}),_vm._v(" "),_c('span',{staticClass:"form-error",attrs:{"id":"notificationTitle"}},[_vm._v("Please enter a notification title")])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bonus-form-lable-wording",attrs:{"for":"exampleFormControlTextareaBonus"}},[_vm._v("WORDING *")]),_vm._v(" "),_c('ckeditor',{staticClass:"cheditor-styling",attrs:{"config":_vm.editorConfig,"id":"exampleFormControlTextareaBonus"},model:{value:(_vm.content.message),callback:function ($$v) {_vm.$set(_vm.content, "message", $$v)},expression:"content.message"}})],1),_vm._v(" "),_c('div',{staticClass:"imgae-Modal-styling"},[_c('p',{staticClass:"img-Content-styling"},[_vm._v("Image")]),_vm._v(" "),(_vm.isHidden)?_c('p',{staticClass:"Paste-image",on:{"click":function($event){_vm.isHidden = !_vm.isHidden;
                          _vm.content.image_url = '';
                          _vm.content.image_name = '';}}},[_vm._v("\n                        Paste image URL\n                      ")]):_c('p',{staticClass:"Paste-image",on:{"click":function($event){_vm.isHidden = !_vm.isHidden;
                          _vm.content.image_url = '';
                          _vm.content.image_name = '';}}},[_vm._v("\n                        Upload Image\n                      ")])]),_vm._v(" "),(!_vm.isHidden)?_c('div',{staticClass:"Img-url-paste"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content.image_url),expression:"content.image_url"}],attrs:{"placeholder":"example.png","type":"text"},domProps:{"value":(_vm.content.image_url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.content, "image_url", $event.target.value)}}})]):_c('div',[_c('label',{staticClass:"Image-label-content"}),_vm._v(" "),_c('input',{attrs:{"type":"file","accept":"image/*","id":"actual-btn","hidden":""},on:{"change":_vm.uploadImage}}),_vm._v(" "),_c('label',{staticClass:"upload-file-styling-bonus",attrs:{"for":"actual-btn"}},[_c('img',{staticClass:"img-upload-btn",attrs:{"src":"/images/Capture.PNG"}}),_vm._v(" "),_c('span',{staticClass:"upload-btn-text"},[_vm._v("Drag and drop an image or"),_c('span',[_vm._v("browse")]),_vm._v("to\n                          choose a file.")])]),_vm._v(" "),_c('p',{staticClass:"bonus-upload-img-text"},[_vm._v("\n                        PNG or JPEG. Minimum height and width 300px,1Mb max.\n                        Landscape or it will be cropped by ios and Android\n                      ")]),_vm._v(" "),(_vm.content.image_url)?_c('div',{staticClass:"img-upload-hide"},[_c('i',{staticClass:"fa fa-times-circle",on:{"click":function($event){_vm.content.image_url = '';
                            _vm.content.image_name = '';}}}),_vm._v(" "),_c('img',{staticClass:"img-upload-hide-img",attrs:{"src":_vm.content.image_url,"alt":""}})]):_vm._e()])])]),_vm._v(" "),(1 == 0)?_c('div',[_c('label',{staticClass:"Title-content",attrs:{"for":"exampleInputEmail1"}},[_vm._v("TITLE")]),_vm._v(" "),_c('input',{attrs:{"type":"text"}}),_vm._v(" "),_c('label',{staticClass:"Title-content",attrs:{"for":"exampleInputEmail1"}},[_vm._v("MESSAGE")]),_vm._v(" "),_c('textarea',{attrs:{"id":"w3review","name":"w3review","rows":"4","cols":"50"}}),_vm._v(" "),_c('div',[_c('img',{staticClass:"img-upload-hide",attrs:{"src":"/images/cflogodashboard.png","alt":""}})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cell auto popup-button pad-top"},[_c('button',{staticClass:"back button btn",on:{"click":function($event){$event.preventDefault();return _vm.goback()}}},[_vm._v("\n                    Back\n                  ")]),_vm._v(" "),_c('input',{staticClass:"next action-button button btn_lg btn pink_btn",attrs:{"type":"button","name":"EDIT","value":"EDIT","disabled":!_vm.validateFinish},on:{"click":_vm.editCategory}})])]):_vm._e()])])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }