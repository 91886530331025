<template>
    <div>
        <div class="jobs-table">
            <div class="job-row job-row--head">
                <div class="job-column job-column--checkbtn-align">
                    <label class="contain-check">
                        <input @click="taskBar()"
                               type="checkbox"
                               v-model="selectAll" />
                        <span class="checkmark-check"></span>
                    </label>
                </div>
                <div class="job-column job-column--title-align">
                    <span class="heading"
                          @click="sort('Job_Title')">
                        TITLE
                        <icon name="sort" />
                    </span>
                </div>
                <div class="job-column job-column--site-align"
                     v-if="show_sites">SITE</div>
                <div class="job-column job-column--tariff-align"
                     v-if="pointTariffsFeatureFlag">TARIFF</div>
                <div class="job-column job-column--text-align">SHARES</div>
                <div class="job-column job-column--text-align">CANDIDATES</div>
                <div class="job-column job-column--text-align">STARTERS</div>
                <div class="job-column job-column--created-align">
                    <span class="heading"
                          @click="sort('created_at')">
                        CREATED
                        <icon name="sort" />
                    </span>
                </div>
                <div class="job-column job-column--tag-align">STATUS</div>
                <div class="job-column job-column--tag-align"></div>
                <div class="job-column job-column--action-align"></div>
            </div>

            <div v-if="jobs.length > 0">
                <div class="job-row"
                     v-for="(job, index) in jobs"
                     :key="job.id">
                    <div class="job-column job-column--checkbtn-align">
                        <label class="contain-check">
                            <input :value="job.id"
                                   v-model="jobChecked"
                                   @click="taskBar()"
                                   type="checkbox" />
                            <span class="checkmark-check"></span>
                        </label>
                    </div>
                    <div class="job-column job-column--title-align">
                        <a :href="'/jobs/preview/' + job.Token"
                           target="_blank">
                            {{ job.Job_Title }}
                        </a>
                    </div>
                    <div class="job-column job-column--site-align"
                         v-if="show_sites">
                        {{ job.site_name ? job.site_name : "All sites" }}
                    </div>
                    <div class="job-column job-column--tariff-align"
                         v-if="pointTariffsFeatureFlag">
                        <div v-if="job.point_tariff !== null">
                            {{ job.point_tariff.name }}
                        </div>
                    </div>
                    <div class="job-column job-column--text-align">
                        {{ job.shares }}
                    </div>
                    <div class="job-column job-column--text-align">
                        {{ job.candidates }}
                    </div>
                    <div class="job-column job-column--text-align">
                        {{ job.starters }}
                    </div>
                    <div class="job-column job-column--created-align">
                        {{ formattedDate(job.created_at) }}
                    </div>
                    <div class="job-column job-column--tag-align">
                        <p class="active-status"
                           v-if="job.Status == 1">
                            <span>Active</span>
                        </p>
                        <p class="inactive-status"
                           v-else>
                            <span>Inactive</span>
                        </p>
                    </div>
                    <div class="job-column job-column--tag-align">
                        <pin-job :currentuser="currentuser"
                                 :job="job"
                                 :index="index"
                                 @on-job-pinned="onJobPinned" />
                    </div>
                    <div class="job-column job-column--action-align">
                        <job-action ref="job-action"
                                    :job="job"
                                    :sites="sites"
                                    :pusers="pusers"
                                    :index="index"
                                    :brand="brand"
                                    :currentuser="currentuser"
                                    :pointTariffsFeatureFlag="pointTariffsFeatureFlag"
                                    @on-job-deleted="onJobDeleted"
                                    @open-assign-tariff-modal="openAssignTariffModal"
                                    @on-job-toggle="onJobToggle"
                                    @on-job-duplicated="onJobDuplicated"
                                    @on-job-edited="onJobEdited" />
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="job-row">
                    <div class="job-column job-column--tag-align"
                         style="max-width: 100%; text-align: center;">
                        {{ haveJobs ? 'Loading...' : '0 records' }}
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div v-show="loading"
                 class="loading-overlay">
                <span>Loading...</span>
            </div>
        </transition>
        <assign-tariff v-if="pointTariffsFeatureFlag"
                       ref="assign-tariff"
                       @on-tariff-assign="onTariffAssign" />

        <task-bar ref="task-bar"
                  :jobs="jobs"
                  :currentuser="currentuser"
                  :jobChecked="jobChecked"
                  :pointTariffsFeatureFlag="pointTariffsFeatureFlag"
                  @update:jobChecked="jobChecked = $event"
                  @on-bulk-update="onBulkUpdate"
                  @assign-bulk-tariffs="openAssignTariffModal" />
    </div>
</template>

<script>
import { Icon } from "../../../../app/components/Icon";
import PinJob from "../PinJob/PinJob.vue";
import AssignTariff from "../AssignTariff/AssignTariff.vue";
import JobAction from "../Action/JobAction.vue";
import TaskBar from "../TaskBar/TaskBar.vue";

export default {
    name: "JobsTableView",
    props: {
        brand: {
            type: Object,
            required: true
        },
        currentuser: {
            type: Object,
            required: true
        },
        jobs: {
            type: Array,
            default: () => []
        },
        sites: {
            type: Array,
            default: () => []
        },
        pusers: {
            type: Array,
            default: () => [],
        },
        show_sites: {
            type: Boolean,
            default: false
        },
        pointTariffsFeatureFlag: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            haveJobs: true,
            loading: false,
            jobChecked: []
        };
    },
    components: {
        Icon,
        PinJob,
        AssignTariff,
        JobAction,
        TaskBar
    },
    watch: {
        jobs(newJobs) {
            if (newJobs && newJobs.length > 0) {
                this.haveJobs = false;
            } else {
                this.haveJobs = true;
            }
        }
    },
    methods: {
        formattedDate(dateToFormatt) {
            const dateMoment = moment(dateToFormatt);
            if (dateMoment.isSame(new Date(), 'year')) {
                return dateMoment.format("D MMM");
            } else {
                return dateMoment.format("D MMM 'YY");
            }
        },
        taskBar() {
            this.$refs['task-bar'].showTaskBar();
        },
        openAssignTariffModal(selectedJobs) {
            this.$refs['assign-tariff'].showModal(selectedJobs);
        },
        onJobPinned(pinnedJob) {
            this.$emit('on-job-pinned', pinnedJob);
        },
        onJobDeleted(deletedJob) {
            this.$emit('on-job-deleted', deletedJob);
        },
        onTariffAssign(updatedJobs) {
            this.$emit('on-tariff-assign', updatedJobs);
            this.jobChecked = [];
        },
        onJobToggle() {
            this.$emit('on-job-toggle');
        },
        onJobDuplicated(duplicatedJob) {
            this.$emit('on-job-duplicated', duplicatedJob);
        },
        onBulkUpdate() {
            this.$emit('on-bulk-update');
        },
        onJobEdited() {
            this.$emit('on-job-edited');
        },
        sort(column) {
            this.$emit('on-job-sort', column);
        }
    },
    computed: {
        selectAll: {
            get: function () {
                if (this.jobs.length > 0) {
                    return this.jobs
                        ? this.jobChecked.length == this.jobs.length
                        : false;
                }
            },
            set: function (value) {
                if (value) {
                    this.jobChecked = [];
                    for (var i = 0; i < this.jobs.length; i++) {
                        this.jobChecked.push(this.jobs[i].id);
                    }
                } else {
                    this.jobChecked = [];
                }
            }
        },
    }
};
</script>

<style scoped lang="scss">
@import "./JobsTable.scss";
</style>