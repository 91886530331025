<template>
    <div class="frame">
        <img src="/images/add-job-layout-preview-new.png"
             alt="img" />

        <div class="caption">
            <div v-if="form.Image !== null && form.Image !== ''"
                 class="perview-jobimage-placeholder">
                <img :src="form.Image"
                     style="width: 100%; height: 57px; object-fit: cover; border-top-left-radius: 16px; border-top-right-radius: 16px;" />
            </div>
            <div v-else
                 class="perview-jobimage-placeholder">
                <img v-if="currentuser.customer.default_job_logo"
                     :src="`/storage/${currentuser.customer.default_job_logo}`"
                     style="width: 100%; height: 57px; object-fit: cover; border-top-right-radius: 16px; border-top-left-radius: 16px;" />
            </div>
            <div class="perview-logo-placeholder">
                <div class="perview-logo">
                    <img v-if="currentuser.customer.logo"
                         :src="`/storage/${currentuser.customer.logo}`" />
                </div>
            </div>
            <div class="perview-job-title">
                <p class="job-title-class"
                   v-if="form.Job_Title"
                   style="background-color:#fff">
                    {{ form.Job_Title }}
                </p>
            </div>
            <div class="perview-job-location">
                <p class="job-location-class"
                   v-if="form.Location"
                   style="background-color:#fff">
                    {{ form.Location }}
                </p>
            </div>
            <div class="perview-job-salary"
                 v-if="form.JobType == 'Do not specify'">
                <p class="job-location-class"
                   style="background-color:#fff">
                    Competitive rates of pay
                </p>
            </div>
            <div class="perview-job-salary"
                 v-else-if="form.JobType == 'Other'">
                <p class="job-location-class"
                   v-if=" form.others_jobtype != '' "
                   style="background-color:#fff">
                    {{ form.others_jobtype }}
                </p>
            </div>
            <div class="perview-job-salary"
                 v-else>
                <p class="job-location-class"
                   v-if=" getPayRate != '' "
                   style="background-color:#fff">
                    {{ getPayRate }}
                </p>
            </div>
            <div class="perview-job-type">
                <p class="job-location-class"
                   v-if="form.Hours"
                   style="background-color:#fff">
                    {{ form.Hours }}
                </p>
            </div>
            <div class="perview-job-description">
                <p class="job-description-class"
                   v-if="form.Description"
                   style="background-color:#fff"
                   v-html="form.Description"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JobPreview',
    props: {
        form: {
            type: Object,
            default: () => ({})
        },
        currentuser: {
            type: Object,
            required: true
        },
        currency: {
            type: String,
            required: true
        },
    },
    computed: {
        getPayRate() {
            const { JobType, RateMin, RateMax } = this.form;

            if (!JobType) return "";

            if (RateMin && RateMax) {
                return `${this.currency}${RateMin}-${RateMax}/${JobType}`;
            }

            if (RateMin) {
                return `${this.currency}${RateMin}/${JobType}`;
            }

            if (RateMax) {
                return `${this.currency}${RateMax}/${JobType}`;
            }

            return "";
        }
    }
};
</script>