<template>
  <div>
    <div v-show="showLoad" class="show-load-app">
      <img :src="myImage" />
    </div>

    <!-- FILTERS -->
    <div class="grid-x lead-top-full">
      <div class="cell auto font_0875 no-padding">
        <div class="grid-x lead-grid-margin">
          <div class="cell small-5 pull-left lead-lefttop"></div>
          <div
            class="cell small-7 pull-right bonus-history-top lead-history-top"
          >
            <div class="cell shrink">
              <a
                href="javascript:void(0)"
                style="height: 36px"
                class="btn button orange_btn float-right bonus-filter-cls"
                @click="filtertoggle()"
              >
                FILTERS <img src="/images/filter-icon.png" alt="img"
              /></a>
            </div>
            <div class="cell shrink">
              <a
                href="javascript:void(0)"
                style="height: 36px"
                class="btn button orange_btn float-right"
                @click="exportcsv()"
                >EXPORT</a
              >
            </div>
            <div class="cell shrink">
              <div class="float-right" style="padding-right: 12px">
                <input
                  class="jobs-search"
                  placeholder="Search"
                  v-model="searchVar"
                  name="searchVar"
                  @keyup.delete="leaderboardData()"
                  @keyup="leaderboardDataSe()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- filter start-->
    <div class="grid-x" style="background: #fff">
      <div v-show="showFilter">
        <div class="grid-x filters-wrappers lead-lefttop">
          <div class="cell small-2 padright" style="width: 100px">
            <div class="input-box">
              <label></label>
              <date-range-picker
                v-model="dateRange"
                id="date-range-filter"
                ref="dateRangeFilter"
                :ranges="dateArr"
                opens="right"
                class="phone-recruiter"
                @select="addTag"
                @keydown.delete="removeLastTag"
              >
                <!--    input slot (new slot syntax)-->
                <template #input="picker">
                  <label class="datelabelclass">Date</label>
                </template>
              </date-range-picker>
            </div>
          </div>
          <div class="cell small-2 lead-paddcell">
            <div class="input-box">
              <v-select
                class="phone-recruiter"
                ref="selectedFilter"
                v-model="statusFilter"
                :reduce="(referrer) => referrer.id"
                label="name"
                :options="filters"
                :clearable="false"
                @input="selectedCategory()"
                :close-on-select="true"
                multiple
                taggable
                placeholder="Status:"
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }}
                  <img style="float: right" :src="option.image" />
                </template>
              </v-select>
            </div>
          </div>

          <div
            v-if="sitesInviteOptions.length > 1"
            class="cell small-2 padright"
            style="width: 133px"
          >
            <div class="input-box">
              <v-select
                class="phone-recruiter"
                ref="selectedSiteFilter"
                v-model="siteFilter"
                :reduce="(referrer) => referrer.id"
                label="name"
                :options="sitesInviteOptions"
                :clearable="false"
                @input="selectedSiteCategory()"
                placeholder="Sites:"
                :close-on-select="true"
                multiple
                taggable
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }}
                  <img style="float: right" :src="option.image" />
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <!-- for displaying tags-->
        <div class="grid-x">
          <div v-if="selected_date_tags != ''" style="padding-left: 12px">
            <div class="tag-input__tag">
              <span class="lablesize">{{ selected_date_tags }}</span>
              <span class="deselect-pad"
                ><button class="deselect" @click="removeDateTags()"></button
              ></span>
            </div>
          </div>
          <div v-if="selected_filter_tags" style="padding-left: 12px">
            <div class="tag-input__tag">
              <span class="lablesize">{{ selected_filter_tags }}</span>
              <span class="deselect-pad"
                ><button class="deselect" @click="removeFilterTags()"></button
              ></span>
            </div>
          </div>
          <div v-if="selected_filter_site_tags" style="padding-left: 12px">
            <div class="tag-input__tag">
              <span class="lablesize">{{ selected_filter_site_tags }}</span>
              <span class="deselect-pad"
                ><button
                  class="deselect"
                  @click="removeSiteFilterTags()"
                ></button
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- LEADERBOARD HEADER-->
    <div style="margin-top: 0px" class="cf-table">
      <div
        class="
          grid-x grid-padding-x
          red_bg
          cf-table-head
          t_margin_1
          align-middle
        "
      >
        <draggable
          v-model="headData"
          tag="div"
          class="makeinline"
          draggable=".item"
          @choose="startDrag"
          @unchoose="onDropdrag"
          :move="handleMove"
          @end="handleDragEnd"
        >
          <div
            align="center"
            scope="col"
            :class="header + ' cell auto white item'"
            v-for="header in headData"
            :key="header"
            @click="sort(header)"
          >
            <!--<p class="tb_margin_1" v-if="header=='pos'" style="text-align:left;padding-left: 5px;">
                        <strong>POS. <i v-bind:class="{'fa fa-sort-asc': pos_asc, 'fa fa-sort-desc': pos_desc }"></i></strong>

                    </p>-->
            <p
              class="tb_margin_1"
              v-if="header == 'name'"
              style="text-align: left"
            >
              <strong
                >Name
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': name_asc,
                    'fa fa-sort-desc': name_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p
              class="tb_margin_1"
              v-if="header == 'email'"
              style="text-align: left"
            >
              <strong
                >Email
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': email_asc,
                    'fa fa-sort-desc': email_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'id'">
              <strong
                >id
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': id_asc,
                    'fa fa-sort-desc': id_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p
              class="tb_margin_1"
              v-if="header == 'status'"
              style="text-align: left"
            >
              <strong
                >Status
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': status_asc,
                    'fa fa-sort-desc': status_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p
              v-if="header == 'site_id' && sitesInviteOptions.length > 1"
              class="tb_margin_1"
              style="text-align: left"
            >
              <strong
                >Site
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': site_id_asc,
                    'fa fa-sort-desc': site_id_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'redeemed'">
              <strong
                >Current balance
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': redeemed_asc,
                    'fa fa-sort-desc': redeemed_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'points'">
              <strong
                >Points awarded
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': points_asc,
                    'fa fa-sort-desc': points_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'bonus_points'">
              <strong
                >Bonus Points
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': bonus_points_asc,
                    'fa fa-sort-desc': bonus_points_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'referral_points'">
              <strong
                >Referral points
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': referral_points_asc,
                    'fa fa-sort-desc': referral_points_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'points_paid'">
              <strong
                >Points paid
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': points_paid_asc,
                    'fa fa-sort-desc': points_paid_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'total_shares'">
              <strong
                >Shares
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': total_shares_asc,
                    'fa fa-sort-desc': total_shares_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'candidates'">
              <strong
                >Candidates
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': candidates_asc,
                    'fa fa-sort-desc': candidates_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'starters'">
              <strong
                >Starters
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': starters_asc,
                    'fa fa-sort-desc': starters_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'interview_sucess'">
              <strong
                >Successful Interviews
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': interview_sucess_asc,
                    'fa fa-sort-desc': interview_sucess_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'total_unique_views'">
              <strong
                >Unique Views
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': total_unique_views_asc,
                    'fa fa-sort-desc': total_unique_views_desc,
                  }"
                ></i
              ></strong>
            </p>
            <p class="tb_margin_1" v-if="header == 'created_at'">
              <strong
                >Date registered
                <i
                  v-bind:class="{
                    'fa fa-sort-asc': created_at_asc,
                    'fa fa-sort-desc': created_at_desc,
                  }"
                ></i
              ></strong>
            </p>
          </div>

          <div class="dropdown-action leftpopup" draggable="false">
            <div
              class="dropdown"
              id="openleadoption"
              data-toggle="dropdown"
              aria-haspopup="true"
              ref="dropdown"
              aria-expanded="false"
              style="position: relative"
              @click="callLeaderboad"
            >
              <img
                v-b-tooltip.left.hover="{ customClass: 'my-tooltip-class' }"
                title="Column setup"
                src="/images/leaderbord-open-col.png"
                class="leaderbord-open-col"
              />
            </div>
            <div
              data-alignment="left"
              class="
                dropdown-menu
                pane-leftside
                dropdown-pane
                pane-width
                radius-3
                column-setup-mask
              "
              aria-labelledby="openleadoption"
              style="right: 0"
              @click="onClick"
            >
              <div class="Element-DropdownMenu-Header">
                <span class="Common-Countries">Column setup</span>
              </div>
              <div class="Rectangle"></div>

              <!--<div class="column-outer-div">
				<span class="Menu-Item">Pos.</span>
				<span class="rightsidecheckbox">
			    <label class="points-check">
				<input type="checkbox"
                name="pos"
				v-model="pos"
                id="pos"
				disabled="disabled"
                />
               <span class="mark"></span>
               </label>
			   </span>
			</div>-->

              <div class="column-outer-div">
                <span class="Menu-Item">Name</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="name"
                      v-model="name"
                      id="name"
                      disabled="disabled"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>

              <div class="column-outer-div">
                <span class="Menu-Item">Email</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="email"
                      v-model="email"
                      id="email"
                      @click="manageHeader('email')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">App user ID</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="id"
                      v-model="id"
                      id="id"
                      @click="manageHeader('id')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>

              <div class="column-outer-div">
                <span class="Menu-Item">Status</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="status"
                      v-model="status"
                      id="status"
                      @click="manageHeader('status')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div
                v-if="sitesInviteOptions.length > 1"
                class="column-outer-div"
              >
                <span class="Menu-Item">Site</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="site_id"
                      v-model="site_id"
                      id="site_id"
                      @click="manageHeader('site_id')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Current balance</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="redeemed"
                      v-model="redeemed"
                      id="redeemed"
                      @click="manageHeader('redeemed')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>

              <div class="column-outer-div">
                <span class="Menu-Item">Total points awarded</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="points"
                      v-model="points"
                      id="points"
                      @click="manageHeader('points')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Bonus points awarded</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="bonus_points"
                      v-model="bonus_points"
                      id="bonus_points"
                      @click="manageHeader('bonus_points')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Referral points awarded</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="referral_points"
                      v-model="referral_points"
                      id="referral_points"
                      @click="manageHeader('referral_points')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Points marked as paid</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="points_paid"
                      v-model="points_paid"
                      id="points_paid"
                      @click="manageHeader('points_paid')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Number of shares</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="total_shares"
                      v-model="total_shares"
                      id="total_shares"
                      @click="manageHeader('total_shares')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Number of candidates</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="candidates"
                      v-model="candidates"
                      id="candidates"
                      @click="manageHeader('candidates')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Number of successful interviews</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="interview_sucess"
                      v-model="interview_sucess"
                      id="interview_sucess"
                      @click="manageHeader('interview_sucess')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Number of starters</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="starters"
                      v-model="starters"
                      id="starters"
                      @click="manageHeader('starters')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Unique Views</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="total_unique_views"
                      v-model="total_unique_views"
                      id="total_unique_views"
                      @click="manageHeader('total_unique_views')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Date registered</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input
                      type="checkbox"
                      name="created_at"
                      v-model="created_at"
                      id="created_at"
                      @click="manageHeader('created_at')"
                    />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <!--<div class="maindivsite leaderboard-container-ss">-->
      <div
        v-if="values.length == 0 && query == true"
        class="empty-state empty-state-back"
      >
        <img src="/images/icon-empty-state-points.png" />
        <p>{{ message }}</p>
        <br />
      </div>
      <div v-if="values.length > 0">
        <div
          v-for="(value, index) in values"
          :key="value.id"
          class="maindivsite"
        >
          <!-- LEADERBOARD TABLE - SINGLE ROW (NO MOVE) -->
          <div class="grid-x grid-padding-x align-middle b_border_b1">
            <div
              :class="header + ' cell auto'"
              v-for="header in headData"
              :key="header"
            >
              <!--<div class="cell" v-if="header=='pos'">
                            <p class="tb_margin_1" style="text-align:left;padding-left:5px;">
                          <div v-for="posi in positions" v-if="posi.uid==value.id" class="positions-lead">
                                {{ getNumberWithOrdinal(posi.pos + 1, value.points) }}
                            </div>
                            </p>
                        </div>-->
              <div v-if="header == 'name'">
                <p
                  class="tb_margin_1 cursor-custom"
                  v-b-modal="'modal-leaderboard-' + index"
                  style="
                    text-align: left;
                    word-wrap: break-word;
                    padding-left: 5px;
                  "
                >
                  <strong class="user-clickable"
                    >{{ value.first_name }} {{ value.surname }}</strong
                  >
                </p>
              </div>
              <div v-else-if="header == 'email'">
                <p
                  class="tb_margin_1"
                  style="word-wrap: break-word; text-align: left"
                >
                  {{ value.email }}
                </p>
              </div>
              <div v-else-if="header == 'id'">
                <p class="tb_margin_1">{{ value.id }}</p>
              </div>
              <div v-else-if="header == 'status'">
                <p
                  class="tb_margin_1"
                  v-if="value.mark_as_leaver == 1 && value.active == 2"
                  style="text-align: left; word-wrap: break-word"
                >
                  Leaver
                </p>
                <p
                  class="tb_margin_1"
                  v-else-if="value.reactivated == 1 && value.password == 0"
                  style="text-align: left; word-wrap: break-word"
                >
                  Deactivated
                </p>
                <p
                  class="tb_margin_1"
                  v-else-if="value.active == 1"
                  style="text-align: left; word-wrap: break-word"
                >
                  Active
                </p>
                <p
                  class="tb_margin_1"
                  v-else-if="value.active == 0"
                  style="text-align: left; word-wrap: break-word"
                >
                  Deactivated
                </p>
              </div>
              <div
                v-else-if="header == 'site_id' && sitesInviteOptions.length > 1"
              >
                <p
                  class="tb_margin_1"
                  style="text-align: left; word-wrap: break-word"
                >
                  {{ value.sitename }}
                </p>
              </div>
              <div v-else-if="header == 'redeemed'">
                <p class="tb_margin_1">{{ value.redeemed }}</p>
              </div>
              <div v-else-if="header == 'points'">
                <p class="tb_margin_1">{{ value.points }}</p>
              </div>
              <div v-else-if="header == 'bonus_points'">
                <p class="tb_margin_1">{{ value.bonus_points }}</p>
              </div>
              <div v-else-if="header == 'referral_points'">
                <p class="tb_margin_1">{{ value.referral_points }}</p>
              </div>
              <div v-else-if="header == 'points_paid'">
                <p class="tb_margin_1">{{ value.points_paid }}</p>
              </div>
              <div v-else-if="header == 'total_shares'">
                <p class="tb_margin_1">{{ value.total_shares }}</p>
              </div>
              <div v-else-if="header == 'candidates'">
                <p class="tb_margin_1">{{ value.candidates }}</p>
              </div>
              <div v-else-if="header == 'starters'">
                <p class="tb_margin_1">{{ value.starters }}</p>
              </div>
              <div v-else-if="header == 'interview_sucess'">
                <p class="tb_margin_1">{{ value.interview_sucess }}</p>
              </div>
              <div v-else-if="header == 'total_unique_views'">
                <p class="tb_margin_1">{{ value.total_unique_views }}</p>
              </div>
              <div v-else-if="header == 'created_at'">
                <p class="tb_margin_1" style="word-wrap: break-word">
                  {{ value.created_at | moment("DD-MM-YYYY") }}
                </p>
              </div>
            </div>
            <!--header row end -->
            <div
              class="dropdown-action"
              align="center"
              style="width: 25px; float: right"
            >
              &nbsp;
            </div>
          </div>
          <!--<div v-if="editMode">-->
          <b-modal :id="'modal-leaderboard-' + index" size="xl" hide-footer>
            <profile-card-component 
              :user="value"
              :isCareSector="isCareSector"
              :taxName="taxName"
            >
          </profile-card-component>
          </b-modal>
          <!--</div>-->
        </div>
      </div>
      <!--</div>-->
    </div>

    <template v-if="paginationData.last_page > 1">
      <paginate
        v-model="paginationData.current_page"
        :page-count="paginationData.last_page"
        :page-range="3"
        :margin-pages="2"
        :click-handler="leaderboardData"
        :prev-text="'<'"
        :next-text="'>'"
        :prev-class="'paginate-controls'"
        :next-class="'paginate-controls'"
        :container-class="'pagination-custom'"
        :page-class="'page-item'"
        :active-class="'active'"
      >
      </paginate>
    </template>
    <div class="pagination-container margin-top-2 test-2"></div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Paginate from "vuejs-paginate";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
let leaderboardLoadDebounce = 0;
let cancelToken = axios.CancelToken;
let source = cancelToken.source();
export default {
  components: {
    paginate: Paginate,
    draggable: draggable,
    DateRangePicker: DateRangePicker,
  },
  props: ["allSites", "isCareSector", "taxName"],
  name: "table-column-example",
  display: "Table Column",
  order: 9,

  data: function () {
    return {
      search: "",
      //values: this.users.data,
      values: [],
      positions: [],
      query: false,
      searchVar: "",
      paginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      //page: this.paginationData.current_page,
      showLoad: false,
      myImage: "/images/loading-circle.gif",
      viewPointsData: [],
      balance: [],
      editMode: false,
      leaderboardValues: [],
      pointValues: [],
      site_name: null,
      pos: 0,
      name: 1,
      email: 0,
      id: 0,
      status: 0,
      site_id: 1,
      redeemed: 1,
      points: 0,
      bonus_points: 0,
      referral_points: 0,
      points_paid: 0,
      total_shares: 1,
      candidates: 1,
      starters: 1,
      interview_sucess: 0,
      total_unique_views: 0,
      created_at: 0,
      headData: [
        "name",
        "site_id",
        "redeemed",
        "total_shares",
        "candidates",
        "starters",
      ],
      dragging: false,
      showFilter: this.showFilters,
      selected_filter_tags: "",
      showClass: false,
      siteFilter: [],
      selected_filter_site_tags: "",
      sitesOptions: [],
      sitesInviteOptions: [],
      dateArr: {},
      statusFilter: [],
      filters: [
        { id: 1, name: "All", image: "/images/idle.png" },
        { id: 4, name: "Registered", image: "/images/idle.png" },
      ],
      currentSort: "pos",
      currentSortDir: "SORT_ASC",
      name_asc: false,
      name_desc: false,
      email_asc: false,
      email_desc: false,
      id_asc: false,
      id_desc: false,
      status_asc: false,
      status_desc: false,
      site_id_asc: false,
      site_id_desc: false,
      redeemed_asc: false,
      redeemed_desc: false,
      points_asc: false,
      points_desc: false,
      bonus_points_asc: false,
      bonus_points_desc: false,
      referral_points_asc: false,
      referral_points_desc: false,
      points_paid_asc: false,
      points_paid_desc: false,
      total_shares_asc: false,
      total_shares_desc: false,
      candidates_asc: false,
      candidates_desc: false,
      starters_asc: false,
      starters_desc: false,
      interview_sucess_asc: false,
      interview_sucess_desc: false,
      total_unique_views_asc: false,
      total_unique_views_desc: false,
      created_at_asc: false,
      created_at_desc: false,
      pos_asc: false,
      pos_desc: false,
      message: "",
      tags: [],
      dateRange: {
        startDate: "",
        endDate: "",
      },
      showFilter: false,
      filterStartDate: "",
      filterEndDate: "",
      /*ranges: {
            default () {
              let today = new Date()
              today.setHours(0, 0, 0, 0)

              let yesterday = new Date()
              yesterday.setDate(today.getDate() - 1)
              yesterday.setHours(0, 0, 0, 0);

              let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
              let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

              return {
                'Today': [today, today],
                'Yesterday': [yesterday, yesterday],
    			'This week': [yesterday, yesterday],
    			'Last week': [yesterday, yesterday],
                'This month': [thisMonthStart, thisMonthEnd],
                'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
              }
            }
          },*/
      selected_date_tags: "",
    };
  },
  methods: {
    /**
     * Set points position for customers.
     *
     * @param leaderBoardPos
     * @param points
     * @returns {string|number|*}
     */
    getNumberWithOrdinal(leaderBoardPos, points) {
      if (
        this.currentSort === null ||
        this.currentSort === "points" ||
        this.currentSort === "pos"
      ) {
        /* if (points === 0) {
                        return 0;
                    }
                    if (!points) {
                        return '';
                    }*/

        /* const pageNumber = parseInt(this.getParamsFromUrl('page'));
                     const pagination = 25;
                     if (pageNumber > 1) {
                         const currentPage = (pageNumber - 1) * pagination;
                         leaderBoardPos = currentPage + leaderBoardPos;
                     }*/

        const s = ["th", "st", "nd", "rd"],
          v = leaderBoardPos % 100;
        return leaderBoardPos + (s[(v - 20) % 10] || s[v] || s[0]);
      } else {
        const s = ["th", "st", "nd", "rd"],
          v = leaderBoardPos % 100;
        return leaderBoardPos + (s[(v - 20) % 10] || s[v] || s[0]);
      }
    },
    setLink() {
      const direction =
        this.getParamsFromUrl("direction") === "desc" ? "asc" : "";
      const sort = this.getParamsFromUrl("sort");
      var status = this.getParamsFromUrl("sort");
      var startDate = this.filterStartDate;
      var endDate = this.filterEndDate;
      var site = this.getParamsFromUrl("sort");
      var pangenum = this.paginationData.currentPage;

      const baseLink = "/dashboard/app-users";
      var extraparm = "?page=" + pangenum;
      if (direction != "") {
        extraparm += "&sort=" + sort + "&direction=" + direction;
      }
      if (startDate != "") {
        extraparm += "&startDate=" + startDate + "&endDate=" + endDate;
      }
      if (this.statusFilter != "") {
        extraparm += "&status=" + statusFilter;
      }
      if (this.siteFilter != "") {
        extraparm += "&site=" + siteFilter;
      }
      //alert(baseLink + extraparm + this.getParamsFromUrl('siteId'));
      window.location.href =
        baseLink + extraparm + this.getParamsFromUrl("siteId");
      //return baseLink + extraparm + this.getParamsFromUrl('siteId');
    },
    setSortingLink(column) {
      const direction =
        this.getParamsFromUrl("direction") === "desc" ? "asc" : "desc";
      const baseLink = "/dashboard/app-users";

      return (
        baseLink +
        "?sort=" +
        column +
        "&direction=" +
        direction +
        "&siteId=" +
        this.getParamsFromUrl("siteId")
      );
    },
    setDirectionClass(column) {
      return this.getParamsFromUrl("sort") === column
        ? this.getParamsFromUrl("direction")
        : "";
    },
    sort: function (s) {
      this.currentSort = s;

      if (s == "name") {
        $(".makeinline .name").addClass("sort-active");
      } else {
        $(".makeinline .name").removeClass("sort-active");
      }
      if (s == "pos") {
        $(".makeinline .pos").addClass("sort-active");
      } else {
        $(".makeinline .pos").removeClass("sort-active");
      }

      if (s == "email") {
        $(".makeinline .email").addClass("sort-active");
      } else {
        $(".makeinline .email").removeClass("sort-active");
      }
      if (s == "id") {
        $(".makeinline .id").addClass("sort-active");
      } else {
        $(".makeinline .id").removeClass("sort-active");
      }
      if (s == "status") {
        $(".makeinline .status").addClass("sort-active");
      } else {
        $(".makeinline .status").removeClass("sort-active");
      }
      if (s == "site_id") {
        $(".makeinline .site_id").addClass("sort-active");
      } else {
        $(".makeinline .site_id").removeClass("sort-active");
      }
      if (s == "redeemed") {
        $(".makeinline .redeemed").addClass("sort-active");
      } else {
        $(".makeinline .redeemed").removeClass("sort-active");
      }
      if (s == "points") {
        $(".makeinline .points").addClass("sort-active");
      } else {
        $(".makeinline .points").removeClass("sort-active");
      }
      if (s == "bonus_points") {
        $(".makeinline .bonus_points").addClass("sort-active");
      } else {
        $(".makeinline .bonus_points").removeClass("sort-active");
      }
      if (s == "referral_points") {
        $(".makeinline .referral_points").addClass("sort-active");
      } else {
        $(".makeinline .referral_points").removeClass("sort-active");
      }
      if (s == "points_paid") {
        $(".makeinline .points_paid").addClass("sort-active");
      } else {
        $(".makeinline .points_paid").removeClass("sort-active");
      }
      if (s == "total_shares") {
        $(".makeinline .total_shares").addClass("sort-active");
      } else {
        $(".makeinline .total_shares").removeClass("sort-active");
      }
      if (s == "candidates") {
        $(".makeinline .candidates").addClass("sort-active");
      } else {
        $(".makeinline .candidates").removeClass("sort-active");
      }
      if (s == "starters") {
        $(".makeinline .starters").addClass("sort-active");
      } else {
        $(".makeinline .starters").removeClass("sort-active");
      }
      if (s == "interview_sucess") {
        $(".makeinline .interview_sucess").addClass("sort-active");
      } else {
        $(".makeinline .interview_sucess").removeClass("sort-active");
      }
      if (s == "total_unique_views") {
        $(".makeinline .total_unique_views").addClass("sort-active");
      } else {
        $(".makeinline .total_unique_views").removeClass("sort-active");
      }
      if (s == "created_at") {
        $(".makeinline .created_at").addClass("sort-active");
      } else {
        $(".makeinline .created_at").removeClass("sort-active");
      }

      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir =
          this.currentSortDir === "SORT_ASC" ? "SORT_DESC" : "SORT_ASC";

        if (this.currentSort == "name") {
          (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false),
            (this.site_id_desc = false),
            (this.redeemed_asc = false);
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;

          this.interview_sucess_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.pos_asc = false;
          this.pos_desc = false;
          //remove red line

          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.name_asc = true;
            this.name_desc = false;
          } else {
            this.name_asc = false;
            this.name_desc = true;
          }
        }

        if (this.currentSort == "pos") {
          (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false),
            (this.site_id_desc = false),
            (this.redeemed_asc = false);
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.pos_asc = true;
            this.pos_desc = false;
          } else {
            this.pos_asc = false;
            this.pos_desc = true;
          }
        }

        if (this.currentSort == "email") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false),
            (this.site_id_desc = false),
            (this.redeemed_asc = false);
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.email_asc = true;
            this.email_desc = false;
          } else {
            this.email_asc = false;
            this.email_desc = true;
          }
        }

        if (this.currentSort == "id") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false),
            (this.site_id_desc = false),
            (this.redeemed_asc = false);
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.id_asc = true;
            this.id_desc = false;
          } else {
            this.id_asc = false;
            this.id_desc = true;
          }
        }
        if (this.currentSort == "status") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.site_id_asc = false),
            (this.site_id_desc = false),
            (this.redeemed_asc = false);
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.status_asc = true;
            this.status_desc = false;
          } else {
            this.status_asc = false;
            this.status_desc = true;
          }
        }
        if (this.currentSort == "site_id") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.redeemed_asc = false);
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.site_id_asc = true;
            this.site_id_desc = false;
          } else {
            this.site_id_asc = false;
            this.site_id_desc = true;
          }
        }
        if (this.currentSort == "redeemed") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.redeemed_asc = true;
            this.redeemed_desc = false;
          } else {
            this.redeemed_asc = false;
            this.redeemed_desc = true;
          }
        }

        if (this.currentSort == "points") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.points_asc = true;
            this.points_desc = false;
          } else {
            this.points_asc = false;
            this.points_desc = true;
          }
        }
        if (this.currentSort == "bonus_points") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.referral_points_asc = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.bonus_points_asc = true;
            this.bonus_points_desc = false;
          } else {
            this.bonus_points_asc = false;
            this.bonus_points_desc = true;
          }
        }
        if (this.currentSort == "referral_points") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.referral_points_asc = true;
            this.referral_points_desc = false;
          } else {
            this.referral_points_asc = false;
            this.referral_points_desc = true;
          }
        }
        if (this.currentSort == "points_paid") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_as = false;
          this.referral_points_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.points_paid_asc = true;
            this.points_paid_desc = false;
          } else {
            this.points_paid_asc = false;
            this.points_paid_desc = true;
          }
        }
        if (this.currentSort == "total_shares") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_as = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.total_shares_asc = true;
            this.total_shares_desc = false;
          } else {
            this.total_shares_asc = false;
            this.total_shares_desc = true;
          }
        }
        if (this.currentSort == "candidates") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_as = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.candidates_asc = true;
            this.candidates_desc = false;
          } else {
            this.candidates_asc = false;
            this.candidates_desc = true;
          }
        }
        if (this.currentSort == "starters") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_as = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.starters_asc = true;
            this.starters_desc = false;
          } else {
            this.starters_asc = false;
            this.starters_desc = true;
          }
        }
        if (this.currentSort == "interview_sucess") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_as = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.interview_sucess_asc = true;
            this.interview_sucess_desc = false;
          } else {
            this.interview_sucess_asc = false;
            this.interview_sucess_desc = true;
          }
        }

        if (this.currentSort == "total_unique_views") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_as = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.created_at_asc = false;
          this.created_at_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .created_at").removeClass("sort-active");
          this.total_unique_views_asc = false;
          this.total_unique_views_desc = false;
          if (this.currentSortDir == "SORT_ASC") {
            this.total_unique_views_asc = true;
            this.total_unique_views_desc = false;
          } else {
            this.total_unique_views_asc = false;
            this.total_unique_views_desc = true;
          }
        }

        if (this.currentSort == "created_at") {
          (this.pos_asc = false),
            (this.pos_desc = false),
            (this.email_asc = false),
            (this.email_desc = false),
            (this.id_asc = false),
            (this.id_desc = false),
            (this.status_asc = false),
            (this.status_desc = false),
            (this.site_id_asc = false);
          this.site_id_desc = false;
          this.redeemed_asc = false;
          this.redeemed_desc = false;
          this.points_asc = false;
          this.points_desc = false;
          this.bonus_points_asc = false;
          this.bonus_points_desc = false;
          this.referral_points_as = false;
          this.referral_points_desc = false;
          this.points_paid_asc = false;
          this.points_paid_desc = false;
          this.total_shares_asc = false;
          this.total_shares_desc = false;
          this.candidates_asc = false;
          this.candidates_desc = false;
          this.starters_asc = false;
          this.starters_desc = false;
          this.interview_sucess_asc = false;
          this.interview_sucess_desc = false;
          this.name_asc = false;
          this.name_desc = false;
          //remove red line

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .pos").removeClass("sort-active");
          $(".makeinline .email").removeClass("sort-active");
          $(".makeinline .id").removeClass("sort-active");
          $(".makeinline .status").removeClass("sort-active");
          $(".makeinline .site_id").removeClass("sort-active");
          $(".makeinline .redeemed").removeClass("sort-active");
          $(".makeinline .points").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_paid").removeClass("sort-active");
          $(".makeinline .total_shares").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .interview_sucess").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.created_at_asc = true;
            this.created_at_desc = false;
          } else {
            this.created_at_asc = false;
            this.created_at_desc = true;
          }
        }
      }
      this.leaderboardData();
    },
    leaderboardDataSe() {
      if (this.searchVar.length > 2) {
        this.leaderboardData();
      }
    },
    leaderboardData(page, init, runRequest = false) {
      if (!runRequest) {
        if (leaderboardLoadDebounce > 0) {
          clearTimeout(leaderboardLoadDebounce);
        }
        leaderboardLoadDebounce = setTimeout(
          () => this.leaderboardData(page, init, true),
          1500
        );
        return;
      }
      this.showLoad = true;
      var self = this;
      var search = "";

      source.cancel("New request recieved");
      cancelToken = axios.CancelToken;
      source = cancelToken.source();

      axios
        .get("/dashboard/points/leaderboard", {
          cancelToken: source.token,
          params: {
            searchVar: self.searchVar,
            leader_page: page,
            page: page,
            column_name: self.currentSort,
            sort: self.currentSortDir,
            filterStartDate: self.filterStartDate,
            filterEndDate: self.filterEndDate,
            statusFilter: self.statusFilter,
            siteFilter: self.siteFilter,
          },
        })
        .then(function (response) {
          var leadsData = response.data.leaderboardData;
          var paging = response.data.pagination;
          if (response.data.leaderboardData.length == 0) {
            self.query = true;
            self.values = response.data.leaderboardData;
            self.message =
              "None of your app user’s have any activity during this leaderboard period yet. Check back soon!";
            self.paginationData.current_page = 0;
            self.paginationData.from = 0;
            self.paginationData.last_page = 0;
            self.paginationData.per_page = 0;
            self.paginationData.to = 0;
            self.paginationData.total = 0;
            self.showLoad = false;
          } else {
            if (response.data.leaderboardData) {
              self.values = response.data.leaderboardData;
              self.positions = response.data.positionArr;

              self.paginationData.current_page = paging.current_page;
              self.paginationData.from = paging.from;
              self.paginationData.last_page = paging.last_page;
              self.paginationData.per_page = paging.per_page;
              self.paginationData.to = paging.to;
              self.paginationData.total = paging.total;
            }
          }
          self.showLoad = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    callLeaderboad() {
      var self = this;
      //self.showLoad = false;
      self.sort("redeemed");
      //self.showLoad = false;
    },
    searchLeaderboard: function () {
      if (this.searchVar.length > 0) {
        this.showLoad = true;
        var self = this;
        axios
          .post("/dashboard/points/search", { searchVar: this.searchVar })
          .then(function (response) {
            if (response.data.users.data) {
              self.values = response.data.users.data;
              self.paginationData.current_page =
                response.data.users.current_page;
              self.paginationData.from = response.data.users.from;
              self.paginationData.last_page = response.data.users.last_page;
              self.paginationData.per_page = response.data.users.per_page;
              self.paginationData.to = response.data.users.to;
              self.paginationData.total = response.data.users.total;
            }
            self.showLoad = false;
          })
          .catch(function (error) {
            console.log(error);
          });
        /*.finally(function () {
                            self.showLoad = false;
                        })*/
      } else {
        /*this.values = this.users.data;
                    this.paginationData.current_page = this.users.current_page;
                    this.paginationData.from = this.users.from;
                    this.paginationData.last_page = this.users.last_page;
                    this.paginationData.per_page = this.users.per_page;
                    this.paginationData.to = this.users.to;
                    this.paginationData.total = this.users.total;*/
      }
    },
    searchLeaderboardData: function () {
      if (this.searchVar.length <= 0) {
        this.leaderboardData();
      } else {
        this.searchLeaderboard();
      }
    },
    paginatorClickCallback: function (pageNum) {
      var url = window.location.href.split("siteId=");
      if (url && url[0] && url[1]) {
        if (url[0].indexOf("page") != -1) {
          var url2 = url[1].split("#");
        } else {
          var url2 = url[1].split("&");
        }
      }
      if (url2 && url2[0]) {
        window.location =
          window.location.origin +
          "/dashboard/app-users?page=" +
          pageNum +
          "&siteId=" +
          url2[0] +
          "#leaderboard";
      } else {
        window.location =
          window.location.origin +
          "/dashboard/app-users?page=" +
          pageNum +
          "#leaderboard";
      }
      return false;
    },
    exportcsv: function () {
      // href="/dashboard/leaderboardexport"
      axios
        .post("/dashboard/leaderboardexport", {
          searchVar: this.searchVar,
          column: this.headData,
          filterStartDate: this.filterStartDate,
          filterEndDate: this.filterEndDate,
          statusFilter: this.statusFilter,
          siteFilter: this.siteFilter,
          column_name: this.currentSort,
          sort: this.currentSortDir,
        })
        .then(({ data }) => {
          let toastObj = {
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>Export will be sent to your email once completed!',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
          };
          if (data.status !== "success") {
            const msg = data.message || "Export Failed";
            toastObj = {
              html: `<span class="success-circle"><i class="fas fa-check-circle"></i></span>${msg}`,
              confirmButtonColor: "#F84366",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#F84366",
            };
          }
          Vue.swal({
            ...toastObj,
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        });
    },
    onClick() {
      $(".leftpopup").click(function (e) {
        e.stopPropagation();
      });
    },
    setLeaderboardHeader() {
      var self = this;
      if (localStorage.getItem("leadHeaders") == null) {
        var leadHeaders = self.headData;
        localStorage.setItem("leadHeaders", JSON.stringify(leadHeaders));
        self.headData = JSON.parse(localStorage.getItem("leadHeaders"));
      } else {
        self.headData = JSON.parse(localStorage.getItem("leadHeaders"));

        self.email = 0;
        self.id = 0;
        self.status = 0;
        self.site_id = 0;
        self.redeemed = 0;
        self.points = 0;
        self.bonus_points = 0;
        self.referral_points = 0;
        self.points_paid = 0;
        self.total_shares = 0;
        self.candidates = 0;
        self.starters = 0;
        self.interview_sucess = 0;
        self.total_unique_views = 0;
        self.created_at = 0;

        for (var i = 0; i < self.headData.length; i++) {
          if (self.headData[i] == "site_id") {
            self.site_id = 1;
          }
          if (self.headData[i] == "email") {
            self.email = 1;
          }
          if (self.headData[i] == "status") {
            self.status = 1;
          }
          if (self.headData[i] == "id") {
            self.id = 1;
          }
          if (self.headData[i] == "redeemed") {
            self.redeemed = 1;
          }
          if (self.headData[i] == "points") {
            self.points = 1;
          }
          if (self.headData[i] == "bonus_points") {
            self.bonus_points = 1;
          }
          if (self.headData[i] == "referral_points") {
            self.referral_points = 1;
          }
          if (self.headData[i] == "points_paid") {
            self.points_paid = 1;
          }
          if (self.headData[i] == "total_shares") {
            self.total_shares = 1;
          }
          if (self.headData[i] == "candidates") {
            self.candidates = 1;
          }
          if (self.headData[i] == "starters") {
            self.starters = 1;
          }
          if (self.headData[i] == "interview_sucess") {
            self.interview_sucess = 1;
          }
          if (self.headData[i] == "total_unique_views") {
            self.total_unique_views = 1;
          }
          if (self.headData[i] == "created_at") {
            self.created_at = 1;
          }
        }
      }
    },
    manageHeader(val) {
      var self = this;
      //for status
      self.headData = JSON.parse(localStorage.getItem("leadHeaders"));
      if (val == "status") {
        if (self.status == true) {
          if (self.headData.indexOf("status") != "-1") {
            self.headData.splice(self.headData.indexOf("status"), 1);
          }
        }
        if (self.status == false) {
          if (self.headData.indexOf("status") == "-1") {
            self.headData.push("status");
          }
        }
      }
      //for id
      if (val == "id") {
        if (self.id == true) {
          if (self.headData.indexOf("id") != "-1") {
            self.headData.splice(self.headData.indexOf("id"), 1);
          }
        }
        if (self.id == false) {
          if (self.headData.indexOf("id") == "-1") {
            self.headData.push("id");
          }
        }
      }
      //for email
      if (val == "email") {
        if (self.email == true) {
          if (self.headData.indexOf("email") != "-1") {
            self.headData.splice(self.headData.indexOf("email"), 1);
          }
        }
        if (self.email == false) {
          if (self.headData.indexOf("email") == "-1") {
            self.headData.push("email");
          }
        }
      }
      //for site_id
      if (val == "site_id") {
        if (self.site_id == true) {
          if (self.headData.indexOf("site_id") != "-1") {
            self.headData.splice(self.headData.indexOf("site_id"), 1);
          }
        }
        if (self.site_id == false) {
          if (self.headData.indexOf("site_id") == "-1") {
            self.headData.push("site_id");
          }
        }
      }
      //for redeemed
      if (val == "redeemed") {
        if (self.redeemed == true) {
          if (self.headData.indexOf("redeemed") != "-1") {
            self.headData.splice(self.headData.indexOf("redeemed"), 1);
          }
        }
        if (self.redeemed == false) {
          if (self.headData.indexOf("redeemed") == "-1") {
            self.headData.push("redeemed");
          }
        }
      }
      //for points
      if (val == "points") {
        if (self.points == true) {
          if (self.headData.indexOf("points") != "-1") {
            self.headData.splice(self.headData.indexOf("points"), 1);
          }
        }
        if (self.points == false) {
          if (self.headData.indexOf("points") == "-1") {
            self.headData.push("points");
          }
        }
      }
      //for bonus_points
      if (val == "bonus_points") {
        if (self.bonus_points == true) {
          if (self.headData.indexOf("bonus_points") != "-1") {
            self.headData.splice(self.headData.indexOf("bonus_points"), 1);
          }
        }
        if (self.bonus_points == false) {
          if (self.headData.indexOf("bonus_points") == "-1") {
            self.headData.push("bonus_points");
          }
        }
      }

      //for referral_points
      if (val == "referral_points") {
        if (self.referral_points == true) {
          if (self.headData.indexOf("referral_points") != "-1") {
            self.headData.splice(self.headData.indexOf("referral_points"), 1);
          }
        }
        if (self.referral_points == false) {
          if (self.headData.indexOf("referral_points") == "-1") {
            self.headData.push("referral_points");
          }
        }
      }

      //for points_paid
      if (val == "points_paid") {
        if (self.points_paid == true) {
          if (self.headData.indexOf("points_paid") != "-1") {
            self.headData.splice(self.headData.indexOf("points_paid"), 1);
          }
        }
        if (self.points_paid == false) {
          if (self.headData.indexOf("points_paid") == "-1") {
            self.headData.push("points_paid");
          }
        }
      }
      //for total_shares
      if (val == "total_shares") {
        if (self.total_shares == true) {
          if (self.headData.indexOf("total_shares") != "-1") {
            self.headData.splice(self.headData.indexOf("total_shares"), 1);
          }
        }
        if (self.total_shares == false) {
          if (self.headData.indexOf("total_shares") == "-1") {
            self.headData.push("total_shares");
          }
        }
      }
      //for candidates
      if (val == "candidates") {
        if (self.candidates == true) {
          if (self.headData.indexOf("candidates") != "-1") {
            self.headData.splice(self.headData.indexOf("candidates"), 1);
          }
        }
        if (self.candidates == false) {
          if (self.headData.indexOf("candidates") == "-1") {
            self.headData.push("candidates");
          }
        }
      }
      //for starters
      if (val == "starters") {
        if (self.starters == true) {
          if (self.headData.indexOf("starters") != "-1") {
            self.headData.splice(self.headData.indexOf("starters"), 1);
          }
        }
        if (self.starters == false) {
          if (self.headData.indexOf("starters") == "-1") {
            self.headData.push("starters");
          }
        }
      }
      //for interview_sucess
      if (val == "interview_sucess") {
        if (self.interview_sucess == true) {
          if (self.headData.indexOf("interview_sucess") != "-1") {
            self.headData.splice(self.headData.indexOf("interview_sucess"), 1);
          }
        }
        if (self.interview_sucess == false) {
          if (self.headData.indexOf("interview_sucess") == "-1") {
            self.headData.push("interview_sucess");
          }
        }
      }
      //for total unique views
      if (val == "total_unique_views") {
        if (self.total_unique_views == true) {
          if (self.headData.indexOf("total_unique_views") != "-1") {
            self.headData.splice(
              self.headData.indexOf("total_unique_views"),
              1
            );
          }
        }
        if (self.total_unique_views == false) {
          if (self.headData.indexOf("total_unique_views") == "-1") {
            self.headData.push("total_unique_views");
          }
        }
      }
      //for created_at
      if (val == "created_at") {
        if (self.created_at == true) {
          if (self.headData.indexOf("created_at") != "-1") {
            self.headData.splice(self.headData.indexOf("created_at"), 1);
          }
        }
        if (self.created_at == false) {
          if (self.headData.indexOf("created_at") == "-1") {
            self.headData.push("created_at");
          }
        }
      }

      var leadHeaders = self.headData;
      localStorage.setItem("leadHeaders", JSON.stringify(leadHeaders));

      self.headData = JSON.parse(localStorage.getItem("leadHeaders"));
    },
    startDrag: function (evt) {
      var colname = "";
      return false; // disable sort
    },
    onDropdrag: function (e) {
      $(".grid-x ." + this.headData[e.oldIndex]).removeClass("colhighlight");
      $(".makeinline ." + this.headData[e.oldIndex]).removeClass("sort-active");

      return false; // disable sort
    },
    handleDragEnd() {
      this.futureItem = this.headData[this.futureIndex];
      this.movingItem = this.headData[this.movingIndex];
      const _items = Object.assign([], this.headData);
      _items[this.futureIndex] = this.movingItem;
      _items[this.movingIndex] = this.futureItem;

      this.headData = _items;
      //
      //this.movingIndex = index;
      //this.futureIndex = futureIndex;

      var colname = "";
      colname = this.headData[this.futureIndex];
      // console.log("Moving: "+this.futureIndex);
      // console.log("Col: "+colname);
      //console.log("heraf: "+this.headData);
      $(".grid-x ." + colname).removeClass("colhighlight");
      $(".makeinline ." + colname).removeClass("sort-active");
      $(".maindivsite .name").each(function (index, element) {
        return $(".maindivsite ." + colname + ":eq(" + index + ")").css({
          "padding-top": "0px",
          "padding-bottom": "0px",
        });
      });
    },
    handleMove(e) {
      const { index, futureIndex } = e.draggedContext;
      this.movingIndex = index;
      this.futureIndex = futureIndex;

      //console.log("Name "+this.headData[this.movingIndex]);

      var colname = "";
      colname = this.headData[this.movingIndex];

      $(".grid-x ." + colname).addClass("colhighlight");
      $(".makeinline ." + colname).addClass("sort-active");
      $(".maindivsite .name").each(function (index, element) {
        var parentHeight = 0;
        var parentHeight = $(this).height();

        if (parentHeight > 60) {
          var sct = (parentHeight - 48) / 2 + 20;

          return $(".maindivsite ." + colname + ":eq(" + index + ")").css({
            "padding-top": sct + "px",
            "padding-bottom": sct + "px",
          });
        }
      });

      return false; // disable sort
    },
    filtertoggle() {
      if (this.showFilter == true) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
    },
    removeFilterTags: function () {
      if (this.statusFilter.length > 1) {
        let length = this.statusFilter.length;
        this.statusFilter.splice(length - 1, 1);
      } else {
        this.$refs.selectedFilter.clearSelection();
      }

      this.selected_filter_tags = "";
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].image == "/images/selected.png") {
          this.filters[i].image = "/images/idle.png";
        }
      }
      this.selectedCategory();
    },
    removeSiteFilterTags: function () {
      if (this.siteFilter.length > 1) {
        let length = this.siteFilter.length;
        this.siteFilter.splice(length - 1, 1);
      } else {
        this.$refs.selectedSiteFilter.clearSelection();
      }
      this.selected_filter_site_tags = "";
      for (var i = 0; i < this.sitesInviteOptions.length; i++) {
        if (this.sitesInviteOptions[i].image == "/images/selected.png") {
          this.sitesInviteOptions[i].image = "/images/idle.png";
        }
      }
      this.selectedSiteCategory();
    },
    selectedCategory: function (flag = null) {
      /**
       * Code to managed selected tags for Filters
       */
      if (this.statusFilter.length > 1) {
        let length = this.statusFilter.length;
        this.statusFilter.splice(0, 1);
      }
      if (flag) {
        if (this.statusFilter[0] == 1) {
          this.statusFilter.splice(0, 1);
        }
      }
      if (this.statusFilter.length > 1) {
        if (this.statusFilter[0] == 1) {
          this.statusFilter.splice(0, 1);
        } else {
          for (var i = 0; i < this.statusFilter.length; i++) {
            if (i == 0) {
              continue;
            }
            if (this.statusFilter[i] == 1) {
              this.statusFilter = [];
              this.statusFilter[0] = 1;
            }
          }
        }
      }
      let selected_filter = [];
      for (var i = 0; i < this.filters.length; i++) {
        if ($.inArray(this.filters[i].id, this.statusFilter) !== -1) {
          this.filters[i].image = "/images/selected.png";
          selected_filter.push(this.filters[i].name);
        } else {
          this.filters[i].image = "/images/idle.png";
        }
      }
      if (selected_filter.length > 0) {
        this.selected_filter_tags = "Status: " + selected_filter;
      }

      if (!flag) {
        if (this.statusFilter != "") {
          this.leaderboardData();
        } else {
          this.$refs.selectedFilter.clearSelection();
          this.leaderboardData();
        }
      }
    },
    selectedSiteCategory: function (flag = null) {
      if (flag) {
        if (this.siteFilter[0] == -1) {
          this.siteFilter.splice(0, 1);
        } else if (this.siteFilter[0] == 0) {
          this.siteFilter.splice(0, 1);
        }
      }
      if (this.siteFilter.length > 1) {
        if (this.siteFilter[0] == -1) {
          this.siteFilter.splice(0, 1);
        } else if (this.siteFilter[0] == 0) {
          this.siteFilter.splice(0, 1);
        } else {
          for (var i = 0; i < this.siteFilter.length; i++) {
            if (i == 0) {
              continue;
            }
            if (this.siteFilter[i] == -1) {
              this.siteFilter = [];
              this.siteFilter[0] = -1;
            }

            if (this.siteFilter[i] == 0) {
              this.siteFilter = [];
              this.siteFilter[0] = 0;
            }
          }
        }
      }
      let selected_filter = [];
      for (var i = 0; i < this.sitesInviteOptions.length; i++) {
        if ($.inArray(this.sitesInviteOptions[i].id, this.siteFilter) !== -1) {
          this.sitesInviteOptions[i].image = "/images/selected.png";
          selected_filter.push(this.sitesInviteOptions[i].name);
        } else {
          this.sitesInviteOptions[i].image = "/images/idle.png";
        }
      }
      if (selected_filter.length > 0) {
        this.selected_filter_site_tags = "Site: " + selected_filter.join(", ");
      }

      /**
       * Ends Code to managed selected tags for Category
       */
      if (!flag) {
        this.leaderboardData();
      }
    },
    removeDateTags: function () {
      this.removeTag(0);
      this.leaderboardData();
      //this.selected_date_tags = '';
    },
    addTag(event) {
      //alert(event.endDate);
      //console.log(event);
      this.removeTag(0);
      $(".cancelBtn").trigger("click");
      if (event.startDate == null) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("All time ");
        this.selected_date_tags = "Date: " + this.tags[0];
        this.$refs.dateRangeFilter.popupVisible = false;
        this.leaderboardData();
        return true;
      }

      // alert(event.startDate.toLocaleDateString());
      var newstartDate = event.startDate.getDate();
      var newstartMonth = event.startDate.getMonth() + 1;
      var newstartYear = event.startDate.getFullYear();
      var FinalStartDate =
        newstartYear + "-" + newstartMonth + "-" + newstartDate;
      var newendDate = event.endDate.getDate();
      var newendMonth = event.endDate.getMonth() + 1;
      var newendYear = event.endDate.getFullYear();
      var FinalEndDate = newendYear + "-" + newendMonth + "-" + newendDate;

      var Difference_In_Days =
        (event.endDate - event.startDate) / (1000 * 3600 * 24);

      this.filterStartDate = FinalStartDate;
      this.filterEndDate = FinalEndDate;
      var d = new Date();
      var currentDate = d.getDate();
      var currentMonth = d.getMonth() + 1;
      var currentYear = d.getFullYear();
      // this method evaulate 'This Month' or 'Last Month' conditions.
      let monthSpan = this.evaluateMonthSpan(
        newstartMonth,
        Difference_In_Days,
        newstartDate,
        newstartYear
      );

      if (FinalStartDate == FinalEndDate) {
        var todayDate = currentYear + "-" + currentMonth + "-" + currentDate;
        var yesDate =
          currentYear + "-" + currentMonth + "-" + (currentDate - 1);

        if (FinalStartDate == todayDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Today  ");
        } else if (FinalStartDate == yesDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Yesterday ");
        } else {
          this.removeTag(this.tags.length - 1);
          this.tags.push(FinalStartDate + " to " + FinalEndDate);
        }
      } else if (Difference_In_Days > 1 && Difference_In_Days < 28) {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      } else if (
        currentMonth == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This Month ");
      } else if (Difference_In_Days >= 90 && Difference_In_Days < 93) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("Last 90 days ");
      } else if (
        currentMonth - 1 == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("Last Month ");
      } else if (currentYear && Difference_In_Days == 365) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This year ");
      } else if (event.startDate == null && event.endDate == null) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("All time ");
      } else {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      }
      this.selected_date_tags = "Date: " + this.tags[0];
      this.leaderboardData();
      // this.loadBonusPoints();
      /* var val = event.target.value.trim()
				  if (val.length > 0) {
					this.tags.push(val)
					event.target.value = ''
				  }*/
    },
    removeTag(index) {
      var self = this;
      if (index === 0) {
        self.filterStartDate = "";
        self.filterEndDate = "";
        self.dateRange.startDate = "";
        self.dateRange.endDate = "";
        //this.leaderboardData();
      }
      self.dateRange.startDate = "";
      self.dateRange.endDate = "";
      self.selected_date_tags = "";
      self.tags.splice(index, 1);
    },
    removeLastTag(event) {
      console.log("866 - removeLastTag: ", event.target);
      if (event.target.value.length === 0) {
        this.filterStartDate = "";
        this.filterEndDate = "";
        this.removeTag(this.tags.length - 1);
      }
    },

    evaluateMonthSpan: function (
      newstartMonth,
      Difference_In_Days,
      newstartDate,
      newstartYear
    ) {
      // Transform below code to find total days in month.
      let monthTotalDays = [
        31, // Jan
        28, // Feb
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // Aug
        30, // Sep
        31, // Oct
        30, // Nov
        31, // Dec
      ];

      let monthIndex = newstartMonth - 1;
      let monthDays = monthTotalDays[monthIndex];
      let totalSelectedSpan = Difference_In_Days + 1; // catering previous dev coded result.

      // February Case
      if (newstartMonth == 2) {
        // When it is leap year like 2020, Feb would be off 29
        if (
          newstartYear % 4 == 0 &&
          totalSelectedSpan == 29 &&
          newstartDate == 1
        ) {
          return true;
        } else if (
          newstartYear % 4 != 0 &&
          totalSelectedSpan == 28 &&
          newstartDate == 1
        ) {
          return true;
        }
        return false;
      } else {
        // Non February Cases
        if (totalSelectedSpan == monthDays && newstartDate == 1) {
          return true;
        }
        return false;
      }
    },
    dateRanges: function () {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      /* 90 days logic start here */
      let date = new Date();
      let currentMonth = date.getMonth();
      let currentDate = date.getDate();
      let currentYear = date.getFullYear();

      let defaltRangeStart = new Date(
        currentYear,
        currentMonth,
        currentDate - 90
      );

      let defaltRangeEnd = new Date(currentYear, currentMonth, currentDate);

      /* 90 days logic end here */

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      return (this.dateArr = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "This month": [thisMonthStart, thisMonthEnd],
        "This year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Last 90 days": [defaltRangeStart, defaltRangeEnd],
        "All time": ["no", "no"],
      });
    },
  },

  mounted() {
    var self = this;
    /*self.paginationData.current_page = self.users.current_page;
            self.paginationData.from = self.users.from;
            self.paginationData.last_page = self.users.last_page;
            self.paginationData.per_page = self.users.per_page;
            self.paginationData.to = self.users.to;
            self.paginationData.total = self.users.total;*/
    //self.leaderboardData();

    //self.setLeaderboardHeader();
    localStorage.setItem("leadHeaders", JSON.stringify(self.headData));
    self.headData = JSON.parse(localStorage.getItem("leadHeaders"));
    if (this.allSites) {
      if (this.allSites.length > 1) {
        var site = {
          id: -1,
          name: "All Sites",
          image: "/images/idle.png",
        };
        this.sitesInviteOptions.push(site);
      }
      for (var i = 0; i < this.allSites.length; i++) {
        site = {
          id: this.allSites[i].id,
          name: this.allSites[i].name,
          image: this.allSites[i].image,
        };
        this.sitesInviteOptions.push(site);
      }
    }

    self.dateRanges();

    /*
   self.showFilter=true;
    let date = new Date();
	let currentMonth = date.getMonth();
	let currentDate = date.getDate();
	let currentYear = date.getFullYear();

	let defaltRangeStart = new Date(currentYear, currentMonth, currentDate-90);

	let defaltRangeEnd = new Date(currentYear, currentMonth, currentDate);
   this.addTag({ "startDate":defaltRangeStart,"endDate":defaltRangeEnd});*/

    self.leaderboardData();
    //self.sort('redeemed');
  },
};
</script>
<style lang="scss" scoped>
#reports {
  img .leaderbord-open-col {
    width: 24px;
    height: 24px;
    margin: 14px 10px 13px 26px;
    object-fit: contain;
  }

  .column-setup-mask {
    width: 288px;
    height: 348px;
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
  }
  .Element-DropdownMenu-Header {
    height: 45px;
    padding: 15px 7px 5px 7px;
    float: left;
    width: 100%;
    text-align: left;
  }
  .column-outer-div {
    width: 100%;
    height: 40px;
    padding: 8px 7px 8px 7px;
    clear: both;
  }
  .Menu-Item {
    width: calc(100% - 30px);
    height: 20px;
    font-family: InterUI;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #363b54;
    float: left;
    text-align: left;
    text-transform: capitalize;
  }
  img.Element-Control-Checkbox-Selected {
    width: 24px;
    height: 24px;
    object-fit: contain;
    float: right;
  }
  img.Element-Control-Checkbox-Idle {
    width: 24px;
    height: 15px;
    object-fit: contain;
    float: right;
  }
  .Common-Countries {
    width: 91px;
    height: 16px;
    margin: 0 0 9px;
    font-family: InterUI;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.2px;
    color: #9297ae;
  }
  .leftpopup .dropdown-pane {
    width: 288px !important;
    height: 348px;
    max-height: 348px;
    /*margin-right: 5%;
  margin-top: 57px;*/
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    overflow-x: hidden;
    top: 12px !important;
    left: -80px !important;
  }
  .leftpopup .Rectangle {
    width: 288px;
    height: 1px;
    margin: 40px 10px 8px 0px;
    background-color: rgba(42, 49, 81, 0.1);
    padding: 0px !important;
  }
  .leftpopup .rightsidecheckbox {
    width: 30px;
    display: inline-block;
  }
  .makeinline {
    width: 100%;
    display: inherit;
  }
  .leftpopup {
    float: right;
    padding-top: 10px;
  }

  .jobs-search {
    height: 36px;
    width: 280px;
    padding: 6px 6px 6px 38px;
    border: 1px solid #dcdee6;
    border-radius: 3px;
    color: #9297ae;
    font: 400 14px "InterUI", sans-serif;
    background: #fff url(/images/search.png) no-repeat 7px center;
    margin-left: 12px;
  }
  .pagination-custom {
    border-bottom-left-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 0 3px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    border-bottom-right-radius: 8px;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 23px;
  }
  #openleadoption {
    cursor: pointer;
  }
  .cf-table {
    /*overflow-x: auto;*/
    border-radius: 0px !important;
    margin-bottom: 0px !important;
  }
  .sort-active {
    border-bottom: 2px solid #ef5d72;
    padding-bottom: 0px !important;
    background-color: #f0f1f7 !important;
  }
  .fa {
    width: 24px;
    padding-left: 12px;
    padding-bottom: 0px;
  }
  .cf-table .cf-table-head p strong {
    background: none !important;
    padding: 0 5px;
  }
  .cf-table .cell.auto p {
    margin: 14px 0 !important;
    text-align: center;
  }
  .cf-table .maindivsite .name .auto p {
    text-align: left;
  }

  .cf-table .maindivsite .pos,
  .cf-table .makeinline .pos {
    max-width: 66px !important;
  }

  .dropdown-action button {
    width: 28px;
    height: 28px;
    background: url("/images/more.png") no-repeat center center;
    border-radius: 100%;
    text-indent: -9999px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .cf-table .cf-table-head {
    padding: 0px 18px 0px 18px;
    background: #fafafc;
    word-wrap: break-word;
  }
  .my-tooltip-class {
    width: 125px !important;
    height: 32px !important;
    border-radius: 4px;
    background-color: #363b54;
    position: absolute;
    left: -40px !important;
    padding-top: 0px !important;
    font-family: InterUI;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .sortable-chosen {
    background-color: #f0f1f7;
    /*width: 173px;
  height: 52px;*/
    cursor: url("/images/cursor-arrow-leader.png"), auto;
  }
  .sortable-chosen:hover {
    background-color: #f0f1f7;
    /*width: 173px;
  height: 52px;*/
    cursor: url("/images/cursor-arrow-leader.png"), auto;
  }
  .colhighlight {
    background: #f0f1f7;
  }
  .makeinline .item:hover {
    background-color: #f0f1f7;
    /*width: 173px;
  height: 48px;*/
    padding: 0px !important;
    margin: 0px !important;
    cursor: url("/images/cursor-arrow-leader.png"), auto;
  }
}

.empty-state {
  background: #fafafc;
  margin-top: 25px;
  width: 100%;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
    padding: 10px 21px 8px;
  }

  p {
    width: 100%;
    margin: 35px 0 33px;
    font-family: InterUI;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: center;
    color: #363b54;
  }

  input {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.empty-state-back {
  background: unset !important;
}
.positions-lead {
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #363b54;
}
.lead-top-full {
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.lead-grid-margin {
  margin-bottom: -10px;
}
.lead-lefttop {
  padding-top: 12px;
}
.lead-history-top {
  padding-top: 12px;
  padding-right: 12px;
}
.lead-paddcell {
  width: 133px;
  padding-right: 0px;
}
</style>