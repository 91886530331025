<template>
  <div>
		<add-tariff-button @click.native="showModal"></add-tariff-button>

		<tariff-modal ref="tariff-modal" title="Add Tariff" :loading="loading" @on-process="$refs.form.submit()">
			<tariff-form
				ref="form"
				:isCareSector="isCareSector"
				:milestone="milestone"
				:loading="loading"
				:errors="errors"
				@on-submit="addTariff"
			></tariff-form>
		</tariff-modal>
  </div>
</template>

<script>
import AddTariffButton from './AddTariffButton.vue';
import TariffForm from '../TariffForm/TariffForm.vue';
import TariffModal from '../TariffModal/TariffModal.vue';

export default {
	name: 'AddTariff',
	props: {
		isCareSector: { type: Boolean, default: false },
		milestone: { type: Number, default: 12 },
	},
	components: { AddTariffButton, TariffForm, TariffModal },
	data() {
		return {
			loading: false,
			errors: {},
		}
	},
	methods: {
		showModal() {
			this.errors = {};

			this.$refs['tariff-modal'].show();
		},
		hideModal() {
			this.$refs['tariff-modal'].hide();
		},
		async addTariff(data) {
			this.loading = true;

			await axios.post('/point-tariffs', data)
				.then(response => {
					this.loading = false;

					this.hideModal();

					this.$emit('on-add', response.data.data);

					Vue.swal({
						html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>Tariff created successfully!',
            			confirmButtonColor: "#5dc2a6",
              			confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
             			background: "#5dc2a6",
						customClass: {
							container: "candidate-toast",
						},
						showClass: {
							popup: "animated fadeInLeft fast",
							icon: "animated heartBeat delay-1s",
						},
						hideClass: {
							popup: "animated fadeOutLeft slow",
						},
						toast: true,
						timer: 5000,
						position: "top-right",
						target: "#example-tabs",
					});
				})
				.catch(error => {
					this.loading = false;

					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						Vue.swal({
							title: "Oops!",
							text: "Something went wrong. Please try again.",
							confirmButtonColor: "#ec5168",
							allowOutsideClick: false,
						});
					}
				});
		}
	}
}
</script>