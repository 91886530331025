<template>
    <section id="question-form"
             class="question-form multi-step-row multi-step-p">
        <div class="pop-up-box pop-up-box-styling">
            <div class="top-check-row">
                <ul>
                    <li :class="{ selected: isActive(1), completed: done1 }">
                        <a href="javascript:void(0)">1</a>
                    </li>
                    <li :class="{ selected: isActive(2), completed: done2 }">
                        <a href="javascript:void(0)">2</a>
                    </li>
                </ul>
                <h2 class="model-heading">Edit Screening Question</h2>
                <div v-if="affectedJobs > 0"
                     class="question-alert">
                    <img src="/images/warning.png"
                         alt="img">
                    <span>Please note, any changes made to this question will be applied to {{ affectedJobs }}
                        job(s)
                        immediately.
                    </span>
                </div>
            </div>

            <div class="text-box"
                 id="text-box">
                <fieldset v-if="step === 1">
                    <div class="main-form-div question-table">
                        <div class="question-row">
                            <label class="question-label"
                                   for="question">
                                QUESTION (AS SEEN BY THE CANDIDATE) <span>*</span>
                            </label>
                            <input v-model="form.text"
                                   type="text"
                                   id="question" />
                            <span v-if="errors.text"
                                  class="error-message">{{ errors.text }}</span>
                        </div>
                        <div class="question-row">
                            <label class="question-label"
                                   for="label">
                                LABEL (AS SEEN BY RECRUITERS) <span>*</span>
                            </label>
                            <input v-model="form.label"
                                   type="text"
                                   id="label"
                                   disabled />
                            <span v-if="errors.label"
                                  class="error-message">{{ errors.label }}</span>
                        </div>
                        <div class="question-row">
                            <label class="question-label"
                                   for="fieldType">
                                QUESTION TYPE <span>*</span>
                            </label>
                            <v-select class="question-fieldType"
                                      id="fieldType"
                                      v-model="form.field_type"
                                      placeholder="Selection question type..."
                                      :options="fieldTypes"
                                      :clearable="false"
                                      :reduce="option => option.name"
                                      label="name"
                                      disabled></v-select>
                            <span v-if="errors.field_type"
                                  class="error-message">{{ errors.field_type }}</span>
                        </div>
                    </div>
                </fieldset>
                <fieldset v-if="step === 2">
                    <div class="main-form-div question-table"
                         style="margin: 30px 0">
                        <label class="question-label">AUTO-REJECT CANDIDATES?</label>
                        <div class="question-reject">
                            <div class="switch-label grid-x">
                                <label class="switch"
                                       for="auto_reject_enabled">
                                    <input type="checkbox"
                                           name="auto_reject_enabled"
                                           v-model="form.auto_reject_enabled"
                                           class="custom-control-input"
                                           id="auto_reject_enabled"
                                           disabled />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>

                        <transition name="fade"
                                    @before-enter="beforeEnter"
                                    @enter="enter"
                                    @leave="leave">
                            <div v-if="form.auto_reject_enabled">
                                <div class="question-row">
                                    <label class="question-label"
                                           for="auto_reject_on">
                                        REJECT ON (SELECT ALL THAT APPLY) <span>*</span>
                                    </label>
                                    <v-select class="question-fieldType"
                                              id="auto_reject_on"
                                              v-model="form.auto_reject_on.value"
                                              placeholder="Select responses to reject on..."
                                              :options="form.field_options"
                                              :clearable="false"
                                              :reduce="option => option.value"
                                              label="name"></v-select>
                                    <span v-if="errors.auto_reject_on"
                                          class="error-message">{{ errors.auto_reject_on }}</span>
                                </div>

                                <div class="question-row">
                                    <label class="question-label"
                                           for="rejection_email_template">
                                        EMAIL TEMPLATE <span>*</span>
                                        <img src="/images/eye-open.png"
                                             @click="viewEmailTemplate"
                                             alt="img"
                                             style="cursor: pointer">
                                    </label>
                                    <v-select class="question-fieldType"
                                              id="rejection_email_template"
                                              v-model="form.rejection_email_template"
                                              placeholder="Select email template to be sent to candidate..."
                                              :options="emailTemplates"
                                              :clearable="false"
                                              :reduce="option => option.id"
                                              label="name"
                                              disabled></v-select>
                                    <span v-if="errors.rejection_email_template"
                                          class="error-message">{{ errors.rejection_email_template }}</span>
                                </div>
                            </div>
                        </transition>
                    </div>
                </fieldset>
            </div>
        </div>
        <view-email-template ref="view-email-template"
                             :currentuser="currentuser"
                             :template="selectedEmailTemplate" />
    </section>
</template>

<script>
import ViewEmailTemplate from "./ViewEmailTemplate.vue";
import { fieldTypes, emailTemplates } from './formData.js';

export default {
    name: 'QuestionForm',
    props: {
        question: { type: Object, default: () => ({}) },
        currentuser: { type: Object, required: true },
        errors: { type: Object, default: () => ({}) },
        currentStep: { type: Number, default: 1 },
        affectedJobs: { type: Number, default: 0 },
    },
    components: {
        ViewEmailTemplate
    },
    data() {
        return {
            step: this.currentStep,
            done1: false,
            done2: false,
            form: {
                customer_id: null,
                label: null,
                text: null,
                field_type: null,
                field_options: null,
                auto_reject_enabled: null,
                question_state: null,
                auto_reject_on: null,
                can_delete: null,
                modified_by: null,
                rejection_email_template: null
            },
            fieldTypes: fieldTypes,
            emailTemplates: emailTemplates,
            selectedEmailTemplate: null
        };
    },
    methods: {
        setStep(step) {
            this.step = step;
            if (step === 2) {
                this.done1 = true;
            } else {
                this.done1 = false;
            }
        },
        isActive(step) {
            return this.step === step;
        },
        submitForm() {
            this.$emit('on-submit', this.form);
        },
        beforeEnter(el) {
            el.style.opacity = 0;
        },
        enter(el, done) {
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 1;
            done();
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 0;
            done();
        },
        viewEmailTemplate() {
            this.$refs['view-email-template'].showModal();
        }
    },
    computed: {
        isFormValid() {
            return (this.form.text !== '' && this.form.label !== '' && this.form.field_type !== '');
        },
        isStep2Valid() {
            if (!this.form.auto_reject_enabled) {
                return true;
            } else {
                return (this.form.auto_reject_on.value !== '' && this.form.rejection_email_template !== '');
            }
        }
    },
    watch: {
        currentStep(newStep) {
            this.step = newStep;
            this.setStep(newStep);
        },
        'form.rejection_email_template': function (newId) {
            this.selectedEmailTemplate = this.emailTemplates.find(template => template.id === newId) || null;
        },
        form: {
            deep: true,
            handler() {
                this.$emit('update-form-validity', this.isFormValid);
                this.$emit('update-step2-validity', this.isStep2Valid);
            }
        }
    },
    async created() {
        if (this.question) {
            this.form = { ...this.form, ...this.question };
        }

        this.selectedEmailTemplate = this.emailTemplates.find(template => template.id === this.form.rejection_email_template) || null;
    }
};
</script>

<style scoped lang="scss">
@import "./QuestionForm.scss";
</style>