<template>
    <div>
        <input id="Message" type="hidden" name="Message" v-model="Message">
        <ckeditor
            v-model="Message"
            name="Message"
            id="notification_message"
            :config="editorConfig"
            ></ckeditor>
    </div>
</template>

<script>
    import CKEditor from 'ckeditor4-vue/dist/legacy.js';
    export default {
        data() {
            return {
                Message: '',
                
                // ckeditor configuration
                editorConfig: {
                    plugins: "emoji,basicstyles,list,undo,link,wysiwygarea,toolbar",
                    toolbar: [["Bold", "NumberedList", "BulletedList", "EmojiPanel", "Undo", "Redo"]],
                    removeButtons: 'Italic',
                    enterMode: 3
                }
            }
        },
        methods: {
            onInput(event) {
                this.Message = event.data;
            },
            clearTextarea(){
                this.Message = '';
            },  
        },
        components: {
            CKEditor
        }
    }
</script>

<style>
    #notification_message .cke_contents {
        max-height: 95px !important;
    }
</style>