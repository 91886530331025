<template>
  <div class="dropdown-container"
       :class="{'empty-input': applyRedBorder, 'disabled': disabled}"
       ref="dropdown">
    <div class="selected-option"
         :class="{'disabled': disabled}"
         :style="{color: !selectedOption ? '#cacaca' : '#3C3C3C'}"
         @click="toggleDropdown">
      {{ selectedOption || this.placeholder}}
      <img :class="['arrow', { 'arrow-up': isOpen }]"
           src="/images/svg/dropdown_icon.svg"
           alt="Dropdown arrow">
    </div>
    <transition name="fade">
      <ul v-if="isOpen"
          class="options-list">
        <li v-if="searchable"
            :key="0">
          <input ref="filterSearchInput"
                 class="filter-search-input"
                 type="text"
                 v-model="searchKeyword"
                 placeholder="Enter name to filter" />
        </li>
        <li v-for="option in filteredOptions"
            :key="option.id"
            @click="selectOption(option.id, option.name)"
            :style="{fontWeight: option.id === value ? '700' : 'normal'}">
          {{ option.name }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    isNotValid: {
      type: Boolean,
      default: false
    },
    selectedValue: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Select items..."
    },
    value: {
      type: Number,
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
      applyRedBorder: false,
      searchKeyword: '',
      filteredOptions: this.options
    };
  },
  methods: {
    toggleDropdown() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
      if (this.isOpen) {
        this.$nextTick(() => {
          this.$refs.filterSearchInput.focus();
        });
      }
    },
    selectOption(id, name) {
      this.selectedOption = name;
      this.$emit('input', id);
      this.isOpen = false;
    },
    handleOutsideClick(event) {
      const dropdownEl = this.$refs.dropdown;
      if (dropdownEl && !dropdownEl.contains(event.target)) {
        this.isOpen = false;
      }
    },
    filterOptions() {
      const query = this.searchKeyword.toLowerCase().trim();
      if (!query) {
        this.filteredOptions = this.options;
      } else {
        this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(query));
      }
    }
  },
  mounted() {
    if (this.value !== null) {
      console.log(this.value);
      this.selectedOption = this.options.find(option => option.id === this.value)?.name;
    }
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    isNotValid(newVal) {
      this.applyRedBorder = newVal && !this.selectedOption;
    },
    value(newVal) {
      this.selectedOption = this.options.find(option => option.id === newVal)?.name;
    },
    searchKeyword() {
      this.filterOptions();
    },
    options() {
      this.filterOptions();
    },
  }
}
</script>

<style scoped>
.disabled {
  opacity: 0.5 !important;
  /* Or any other styles to indicate a disabled state */
  cursor: not-allowed !important;
}

.empty-input {
  border: 1px solid #E61414 !important;
}

.dropdown-container {
  width: 100%;
  /* Full-width to fill parent container */
  font-family: "Poppins", sans-serif;
  color: #989898;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #dcdee6;
  border-radius: 12px;
}

.selected-option {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: none;
  background-color: #fff;
  border-color: #dcdee6;
  padding-right: 10px;
  padding-left: 10px;
}

.options-list {
  line-height: 2;
  border-radius: 12px;
  position: absolute;
  width: 100%;
  z-index: 2;
  background-color: #fff;
  top: 50px;
  /* Adjusted based on the height of selected option (includes border) */
  height: auto;
  max-height: 320px;
  /* Limit the height */
  overflow-y: auto;
  border: 1px solid #dcdee6;
  color: #3C3C3C;
  transition: transform 0.2s;
  right: 0;
}

.options-list li {
  padding: 10px 10px;
  /* Added space for alignment */
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f7f7f7;
}

.arrow {
  width: 24px;
  /* Adjust the size if necessary */
  height: 24px;
  /* Adjust the size if necessary */
  transition: transform 0.2s;
  margin-left: auto;
  /* Push the arrow to the right */
  transform: rotate(180deg);
}

.arrow-up {
  transform: rotate(360deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
