<template>
  <div class=""></div>
		
</template>
<script>

   export default {
     props: ['rescode'],
        data(){
          return {
            messagedata: this.rescode,
            
          }
        },
		methods:{
			getMessage: function() { 
			if(this.messagedata==200){
				 Vue.swal({
							title: "Job added",
							type: "success",
							// imageUrl: '/images/sucess_in_sweet.jpg',
							imageWidth: 400,
							imageHeight: 200,
							confirmButtonColor: '#f55d73',
							confirmButtonText: 'OK',
							customClass: {
        					container:'alert-message',
        					confirmButton: 'button pink_btn b_margin_0 no_wrap_btn',
        					cancelButton: 'button orange_btn b_margin_0 no_wrap_btn'
        				},
							
						 });
			}
			else if(this.messagedata==400){
				 Vue.swal({
							title: "Job Deleted",
							type: "success",
							// imageUrl: '/images/sucess_in_sweet.jpg',
							imageWidth: 400,
							imageHeight: 200,
							confirmButtonColor: '#f55d73',
							confirmButtonText: 'OK',
							customClass: {
        					container:'alert-message',
        					confirmButton: 'button pink_btn b_margin_0 no_wrap_btn',
        					cancelButton: 'button orange_btn b_margin_0 no_wrap_btn'
        				},
							
						 });
			}
			else{
			Vue.swal({
						title: "No action set, please select check the form",
						text: "Resubmit",
						type: "warning",
						
					
					 });
			}
			}
		},
		created: function(){
			this.getMessage();
		}
       
          
        
    }
</script>
