<script>
export default {
  name: 'DeactivateIcon'
}
</script>

<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.803 5.199L14.758 18.755L17.755 18.801C17.78 18.801 17.8 18.78 17.8 18.755V5.245L14.803 5.199ZM17.755 20H14.803C14.116 20 13.558 19.441 13.558 18.755V5.245C13.558 4.558 14.116 4 14.803 4H17.755C18.441 4 19 4.558 19 5.245V18.755C19 19.441 18.441 20 17.755 20ZM6.245 5.199L6.2 18.755L9.197 18.801C9.221 18.801 9.241 18.78 9.241 18.755V5.245L6.245 5.199ZM9.197 20H6.245C5.558 20 5 19.441 5 18.755V5.245C5 4.558 5.558 4 6.245 4H9.197C9.883 4 10.442 4.558 10.442 5.245V18.755C10.442 19.441 9.883 20 9.197 20Z" fill="#6A6F8E"/>
    </svg>
</template>