<template>
  <div>
    <div class="bonus-points-history-table"
         v-if="totalCount > 0">
      <div class="table-top-area">
        <div class="grid-x">
          <div class="cell small-12">
            <div class="cell small-6 pull-left bonus-history-top">
              <div class="Total-count">
                <p class="total-count-number">
                  {{ historyPaginationData.total }}
                </p>
                <p class="notification-count-history">Notifications</p>
              </div>
            </div>
            <div class="cell small-6 pull-right bonus-history-top">
              <div class="cell shrink">
                <a href="javascript:void(0)"
                   class="btn button orange_btn float-right bonus-filter-cls"
                   @click="filtertoggle()">
                  FILTERS
                  <img src="/images/filter-icon.png"
                       alt="img" /></a>
              </div>
              <div class="cell shrink">
                <a class="btn button orange_btn float-right"
                   :href="
                    '/dashboard/notifications-page/export-sent-notifications-by-id?sites[]=' +
                    selectedSites +
                    '&date=' +
                    selectedDate +
                    '&startDate=' +
                    filterStartDate +
                    '&endDate=' +
                    filterEndDate +
                    '&timeZone=' +
                    timeZone
                  ">
                  EXPORT
                </a>
              </div>
            </div>
          </div>
        </div>

        <div v-show="showFilter">
          <div class="grid-x filters-wrappers-app-notification filters-wrappers">
            <div class="cell small-2 padright-notification">
              <div class="input-box">
                <label></label>
                <div class="rang-picker">
                  <date-range-picker v-model="dateRange"
                                     id="date-range-filter"
                                     ref="dateRangeFilter"
                                     opens="right"
                                     class="phone-recruiter"
                                     @keydown.enter="addTag"
                                     @keydown.188="addTag"
                                     @select="addTag"
                                     @keydown.delete="removeLastTag">
                    <!--    input slot (new slot syntax)-->
                    <template #input="picker">
                      <label class="datelabelclass">Date</label></template>
                  </date-range-picker>
                </div>
              </div>
            </div>
            <div v-if="show_sites"
                 class="cell small-2 padright">
              <div class="input-box">
                <v-select class="phone-recruiter"
                          id="sites-filter"
                          ref="sitesFilter"
                          @input="pushElement"
                          label="name"
                          :options="this.sitesarr"
                          multiple
                          :close-on-select="true"
                          placeholder="All Sites">
                  <template slot="option"
                            slot-scope="option">
                    {{ option.name }}
                    <img style="float: right"
                         :src="option.image" />
                  </template>
                </v-select>
              </div>
            </div>
            <div v-else
                 class="cell small-2 padright"
                 style="margin-bottom: 44px"></div>
          </div>

          <!-- for displaying tags-->
          <div class="grid-x"
               v-if="selected_date_tags != ''">
            <div class="tag-input__tag">
              <span class="lablesize">{{ selected_date_tags }}</span>
              <span class="deselect-pad"><button class="deselect"
                        @click="removeDateTags()"></button></span>
            </div>
          </div>
          <div class="grid-x full-width-can"
               v-if="selected_sites_tags != ''">
            <div class="tag-input__tag">
              <span class="lablesize">{{ selected_sites_tags }}</span>
              <span class="deselect-pad"><button class="deselect"
                        @click="removeSitesTags()"></button></span>
            </div>
          </div>
        </div>
      </div>

      <!-- USER HEADER-->
      <div class="histoy-notification">
        <div v-show="showLoad"
             class="show-load-app">
          <img :src="myImage" />
        </div>
        <table v-if="historyPaginationData.total > 0">
          <thead class="table-body-styling">
            <tr class="table-history-styling">
              <th class="table-head-styling table-content"
                  scope="col">
                CONTENT
              </th>
              <th class="table-head-styling"
                  scope="col">CREATED BY</th>
              <th class="table-head-styling"
                  scope="col">SENT TO</th>
              <th class="table-head-styling"
                  scope="col">SENT</th>
              <th class="table-head-styling"
                  scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="notification in notifications">
              <td scope="row"
                  class="table-notification history-table-row-styling">
                <span class="table-title-styling"
                      v-html="notification.content.title.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 80) + '..'">
                </span>
                <p v-b-tooltip.hover.html="{
                    customClass: 'Notification-tooltip',
                    title: notification.content.message,
                  }"
                   class="p-styling"
                   v-html="
                      notification.content.message.replace(/<\/?[^>]+(>|$)/g, '').length < 79
                      ? notification.content.message.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 80)
                      : notification.content.message.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 80) + '..'
                  "></p>
              </td>
              <td class="table-CREATED-styling history-table-row-styling">
                {{
                notification.created_by_user
                ? notification.created_by_user.first_name + ' ' + notification.created_by_user.surname
                : notification.first_name + ' ' + notification.surname
                }}
              </td>
              <td class="table-CREATED-styling history-table-row-styling">
                {{ notification.first_name }}
                {{ notification.surname }}
              </td>
              <td class="table-data-styling history-table-row-styling">
                {{ getHumanDate(notification.sent) }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <div class="center">
            <div class="center">
              <img class="empty-history"
                   src="/images/image.png"
                   alt="" />
              <p class="no-data">No Notifications</p>
            </div>
          </div>
        </div>
      </div>

      <template v-if="historyPaginationData.last_page > 1">
        <paginate :page-count="historyPaginationData.last_page"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="paginatorClickCallback"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :prev-class="'paginate-controls'"
                  :next-class="'paginate-controls'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  :active-class="'active'">
        </paginate>
      </template>
    </div>
    <div class="bonus-points-history-table"
         v-else>
      <div class="center">
        <img class="empty-history"
             src="/images/image.png"
             alt="" />
        <p class="no-data">
          You haven’t sent any notifications yet – let’s fix that!
        </p>

        <button v-on:click="getMobileUsersForMultipleApp"
                v-b-modal.modal-notification
                class="button pink_btn b_margin_0 no_wrap_btn job-add">
          New Notification
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    paginate: Paginate,
    Datepicker,
    DateRangePicker: DateRangePicker,
  },
  props: ["status", "sitesarr", "show_sites"],
  data: function () {
    return {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      selectedSitesNames: [],
      selectedSites: [],
      datePickerValue: new Date(),
      selectedDate: "",
      currentSelectedSite: "Sites",
      el: "#drop-downid",
      myModel: {},
      myOption: {},
      myOptions: [
        { value: 1, text: "test1" },
        { value: 2, text: "test2" },
        { value: 3, text: "test3" },
        { value: 4, text: "test4" },
      ],
      myImage: "/images/loading-circle.gif",
      search: "",
      searchVar: "",
      showLoad: false,
      loadImage: "/images/loading-circle.gif",
      showImg: false,
      showimg: true,
      showFilter: false,
      notifications: {},
      testSelected: null,
      totalCount: 1,
      // Handling tabs pagination in Vuejs way
      historyPaginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 1,
      },
      tags: [],
      dateRange: {
        startDate: "",
        endDate: "",
      },
      ranges: {
        default() {
          let today = new Date();
          today.setHours(0, 0, 0, 0);

          let yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);

          let thisMonthStart = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          let thisMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );

          return {
            Today: [today, today],
            Yesterday: [yesterday, yesterday],
            "This week": [yesterday, yesterday],
            "Last week": [yesterday, yesterday],
            "This month": [thisMonthStart, thisMonthEnd],
            "This year": [
              new Date(today.getFullYear(), 0, 1),
              new Date(today.getFullYear(), 11, 31),
            ],
            "Last month": [
              new Date(today.getFullYear(), today.getMonth() - 1, 1),
              new Date(today.getFullYear(), today.getMonth(), 0),
            ],
          };
        },
      },
      filterStartDate: "",
      filterEndDate: "",
      selected_date_tags: "",
      selected_sites_tags: "",
    };
  },

  computed: {
    notificationsdata() {
      if (this.search) {
        return this.notifications.filter((item) => {
          return item.Title.toLowerCase().match(this.search.toLowerCase());
          item.first_name.toLowerCase().startsWith(this.search.toLowerCase());
        });
      } else {
        return this.notifications;
      }
    },
  },

  methods: {
    onSelectDate(date) {
      console.log("selected date", date);
      this.selectedDate = moment(date).format("DD-MM-YYYY");
      this.datePickerValue = date;
      let arr = [...this.selectedSites];
      this.getNotificationsById();
    },
    onDateCleared() {
      this.selectedDate = "";
      this.filterStartDate = "";
      this.filterEndDate = "";
      let arr = [...this.selectedSites];
      this.getNotificationsById(arr);
    },
    popElement() {
      let tempArr = [...this.selectedSitesNames];
      let arr = [...this.selectedSites];
      arr.pop();
      tempArr.pop();
      this.selectedSites = [...arr];
      this.selectedSitesNames = [...tempArr];

      this.getNotificationsById(arr);
    },

    pushElementx(selectedIndex) {
      let tempArr = [...this.selectedSitesNames];
      let arr = [...this.selectedSites];
      if (!arr.includes(this.currentSelectedSite)) {
        tempArr.push(this.sitesarr[selectedIndex - 1].name);
        arr.push(this.currentSelectedSite);
        this.selectedSites = [...arr];
        this.selectedSitesNames = [...tempArr];

        this.getNotificationsById(arr);
      } else {
      }

      this.currentSelectedSite = "Sites";
      if (tempArr.length > 0) {
        this.selected_sites_tags = "Site: " + tempArr.join(", ");
      }
    },
    pushElement(val) {
      let value = val[val.length - 1];
      console.log("selected value", value);

      let arr = [...this.selectedSites];

      if (val.length != arr.length) {
        arr.push(value.id);
        this.selectedSites = [...arr];
        this.getNotificationsById();

        let tempArr = [...this.selectedSitesNames];
        tempArr.push(value.name);
        this.selectedSitesNames = [...tempArr];

        if (tempArr.length > 0) {
          this.selected_sites_tags = "Site: " + tempArr.join(", ");
        }

        let sitesTempArr = [...this.sitesarr];
        sitesTempArr[value.index].image = "/images/selected.png";
        this.sitesarr = [...sitesTempArr];
      }
    },

    getNotificationsById() {
      this.showLoad = true;
      let dateToPost = this.selectedDate;
      let startDate = this.filterStartDate;
      let endDate = this.filterEndDate;
      let sites = this.selectedSites.length > 0 ? this.selectedSites : null;

      axios
        .post("/dashboard/notifications-page/get-sent-notifications-by-id", {
          sites: sites,
          date: dateToPost,
          startDate: startDate,
          endDate: endDate,
        })
        .then((response) => {
          console.log("res noti fi :", response.data.sentNotifications);
          this.notifications = response.data.sentNotifications;
          this.pagination = response.data.pagination;
          this.showLoad = false;

          if (response.data.hasOwnProperty("pagination")) {
            let paginationArr = response.data.pagination;
            this.historyPaginationData.current_page =
              paginationArr.current_page;
            this.historyPaginationData.from = paginationArr.from;
            this.historyPaginationData.last_page = paginationArr.last_page;
            this.historyPaginationData.per_page = paginationArr.per_page;
            this.historyPaginationData.to = paginationArr.to;
            this.historyPaginationData.total = paginationArr.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getNotificationsByIdPagination(sites, page) {
      this.showLoad = true;
      let dateToPost = this.selectedDate;
      let startDate = this.filterStartDate;
      let endDate = this.filterEndDate;
      let extraParams = "";

      if (page != "") {
        extraParams = "?page=" + page;
      } else {
      }

      axios
        .post(
          "/dashboard/notifications-page/get-sent-notifications-by-id" +
          extraParams,
          {
            sites: sites,
            date: dateToPost,
            startDate: startDate,
            endDate: endDate,
          }
        )
        .then((response) => {
          this.notifications = response.data.sentNotifications;
          this.pagination = response.data.pagination;
          this.showLoad = false;
          if (response.data.hasOwnProperty("pagination")) {
            let paginationArr = response.data.pagination;
            this.historyPaginationData.current_page =
              paginationArr.current_page;
            this.historyPaginationData.from = paginationArr.from;
            this.historyPaginationData.last_page = paginationArr.last_page;
            this.historyPaginationData.per_page = paginationArr.per_page;
            this.historyPaginationData.to = paginationArr.to;
            this.historyPaginationData.total = paginationArr.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    filtertoggle() {
      if (this.showFilter == true) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
    },
    /**
     * Send AJAX request to get the sent notifications
     * @param page
     */
    getHumanDate: function (date) {
      return moment
        .utc(date, "YYYY-MM-DD hh:mm")
        .local()
        .format("HH:mm DD/MM/YYYY");
    },
    loadSentNotifications(page) {
      this.showLoad = true;
      let extraParams = "";
      var siteId = "";
      if (page != "") {
        extraParams = "?page=" + page;
        siteId = $("#selected-site").val();
      }

      axios
        .get(
          "/dashboard/notifications-page/get-sent-notifications" + extraParams
        )
        .then((response) => {
          this.notifications = response.data.sentNotifications;
          this.pagination = response.data.pagination;
          this.showLoad = false;
          if (response.data.hasOwnProperty("pagination")) {
            let paginationArr = response.data.pagination;
            this.historyPaginationData.current_page =
              paginationArr.current_page;
            this.historyPaginationData.from = paginationArr.from;
            this.historyPaginationData.last_page = paginationArr.last_page;
            this.historyPaginationData.per_page = paginationArr.per_page;
            this.historyPaginationData.to = paginationArr.to;
            this.historyPaginationData.total = paginationArr.total;
            this.totalCount = paginationArr.total;
          }
        })
        .catch((error) => {
          console.log('loadSentNotifications error: ', error);
        });
    },
    searchsendNotifications: function (page) {
      if (this.searchVar.length > 1) {
        this.showLoad = true;

        var self = this;
        let extraParams = "";
        var siteId = "";
        if (page != "") {
          extraParams = "?page=" + page + "&searchVar=" + this.searchVar;
          siteId = $("#selected-site").val();
        } else if (page == "undefined") {
          extraParams = "?page=1&searchVar=" + this.searchVar;
        } else {
          extraParams = "?searchVar=" + this.searchVar;
        }

        self.notifications = "";
        axios
          .get("/dashboard/notifications-page/search" + extraParams)
          .then(function (response) {
            self.notifications = response.data.sentNotifications;
            self.pagination = response.data.pagination;

            if (response.data.hasOwnProperty("pagination")) {
              let paginationArr = response.data.pagination;
              self.historyPaginationData.current_page =
                paginationArr.current_page;
              self.historyPaginationData.from = paginationArr.from;
              self.historyPaginationData.last_page = paginationArr.last_page;
              self.historyPaginationData.per_page = paginationArr.per_page;
              self.historyPaginationData.to = paginationArr.to;
              self.historyPaginationData.total = paginationArr.total;
            }
            self.showLoad = false;
          })
          .catch(function (error) {
            console.log(error);
          });
        //.finally(function() {
        //    self.showLoad = false;
        // });
      }
    },
    paginatorClickCallback: function (pageNum) {
      console.log("page # :: ", pageNum);
      if (this.searchVar) {
        this.searchsendNotifications(pageNum);
      } else if (
        this.selectedSites.length > 0 ||
        this.filterEndDate != "" ||
        this.filterStartDate != ""
      ) {
        let arr = [...this.selectedSites];
        this.getNotificationsByIdPagination(arr, pageNum);
      } else {
        this.loadSentNotifications(pageNum);
      }
    },
    searchsendNotificationsData: function () {
      if (this.searchVar.length <= 0) {
        this.loadSentNotifications(this.getParamsFromUrl("page"));
      } else {
        this.searchsendNotifications();
      }
    },
    addTag(event) {


      $(".cancelBtn").trigger("click");

      var newstartDate = event.startDate.getDate();
      var newstartMonth = event.startDate.getMonth() + 1;
      var newstartYear = event.startDate.getFullYear();
      var FinalStartDate =
        newstartYear + "-" + newstartMonth + "-" + newstartDate;
      var newendDate = event.endDate.getDate();
      var newendMonth = event.endDate.getMonth() + 1;
      var newendYear = event.endDate.getFullYear();
      var FinalEndDate = newendYear + "-" + newendMonth + "-" + newendDate;

      var Difference_In_Days =
        (event.endDate - event.startDate) / (1000 * 3600 * 24);

      this.filterStartDate = FinalStartDate;
      this.filterEndDate = FinalEndDate;

      var d = new Date();
      var currentDate = d.getDate();
      var currentMonth = d.getMonth() + 1;
      var currentYear = d.getFullYear();
      // this method evaulate 'This Month' or 'Last Month' conditions.
      let monthSpan = this.evaluateMonthSpan(
        newstartMonth,
        Difference_In_Days,
        newstartDate,
        newstartYear
      );

      if (FinalStartDate == FinalEndDate) {
        var todayDate = currentYear + "-" + currentMonth + "-" + currentDate;
        var yesDate =
          currentYear + "-" + currentMonth + "-" + (currentDate - 1);

        if (FinalStartDate == todayDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Today  ");
        } else if (FinalStartDate == yesDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Yesterday ");
        } else {
          this.removeTag(this.tags.length - 1);
          this.tags.push(FinalStartDate + " to " + FinalEndDate);
        }
      } else if (Difference_In_Days > 1 && Difference_In_Days < 28) {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      } else if (
        currentMonth == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This Month ");
      } else if (
        currentMonth - 1 == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("Last Month ");
      } else if (currentYear && Difference_In_Days == 365) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This year ");
      } else {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      }
      this.selected_date_tags = "Date: " + this.tags[0];
    },
    removeTag(index) {
      this.selected_date_tags = "";
      this.tags.splice(index, 1);
      this.getNotificationsById();
    },
    removeLastTag(event) {
      console.log("866 - removeLastTag: ", event.target);
      if (event.target.value.length === 0) {
        this.filterStartDate = "";
        this.filterEndDate = "";
        this.removeTag(this.tags.length - 1);
      }
    },
    evaluateMonthSpan: function (
      newstartMonth,
      Difference_In_Days,
      newstartDate,
      newstartYear
    ) {
      // Transform below code to find total days in month.
      let monthTotalDays = [
        31, // Jan
        28, // Feb
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // Aug
        30, // Sep
        31, // Oct
        30, // Nov
        31, // Dec
      ];

      let monthIndex = newstartMonth - 1;
      let monthDays = monthTotalDays[monthIndex];
      let totalSelectedSpan = Difference_In_Days + 1; // catering previous dev coded result.

      // February Case
      if (newstartMonth == 2) {
        // When it is leap year like 2020, Feb would be off 29
        if (
          newstartYear % 4 == 0 &&
          totalSelectedSpan == 29 &&
          newstartDate == 1
        ) {
          return true;
        } else if (
          newstartYear % 4 != 0 &&
          totalSelectedSpan == 28 &&
          newstartDate == 1
        ) {
          return true;
        }
        return false;
      } else {
        // Non February Cases
        if (totalSelectedSpan == monthDays && newstartDate == 1) {
          return true;
        }
        return false;
      }
    },
    removeDateTags: function () {
      this.filterStartDate = "";
      this.filterEndDate = "";
      this.removeTag(0);
      //this.selected_date_tags = '';
    },
    removeSitesTags: function () {
      this.selected_sites_tags = "";
      this.selectedSitesNames = [];
      this.selectedSites = [];
      this.$refs.sitesFilter.clearSelection();

      let sites = [...this.sitesarr];
      for (let i = 0; i < sites.length; i++) {
        sites[i].image = "/images/idle.png";
      }
      this.sitesarr = [...sites];

      this.getNotificationsById();
    },
    sitesArraySettingforSelectInput() {
      let sites = [...this.sitesarr];
      let tempArr = [];

      for (let i = 0; i < sites.length; i++) {
        let site = {
          name: sites[i].name,
          id: sites[i].id,
          image: "/images/idle.png",
          index: i,
        };
        tempArr.push(site);
      }
      this.sitesarr = [...tempArr];
    },
  },
  mounted() {
    this.sitesArraySettingforSelectInput();
    this.loadSentNotifications(
      this.getParamsFromUrl("page", window.location.href)
    );
    this.$root.$on("notificationCreated", () => {
      this.loadSentNotifications(
        this.getParamsFromUrl("page", window.location.href)
      );
    });
  },
};
</script>

<style scoped>
.bonus-points-history-table .table-top-area {
  padding-right: 20px;
  padding-left: 7px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.table-top-area {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%), 0 0 3px 0 rgb(0 0 0 / 5%);
}

.table-history-styling {
  background-color: #fafafc;
  border-top: 1px solid #fafafc !important;
  border-bottom: 1px solid rgba(42, 49, 81, 0.1);
}

.tooltip .tooltip-inner {
  background: #363b54;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
  overflow-wrap: break-word;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
  float: left;
}

.fa-calendar {
  display: none;
}
</style>
