<template>
  <div class="points_encourage">
    <div v-if="currentuser.account_check == 1" class="cell medium-2" align="right">
      <button class="button pink_btn no_wrap_btn" @click="save()">SAVE</button>
    </div>
    <div class="grey_box_candidates">
      <div class="grid-x grid-padding-x">
        <div class="cell medium-12 b_12">
          <h3 class="margin-top-automation">
            Points to encourage new app users
          </h3>
        </div>
      </div>
      <div class="cell medium-12 h_12">
        <hr class="custom-hr" style="max-width: unset">
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell medium-3 no-padding automate-width">
          <label class="contain auto-padding">Automatically award all app users
            <input v-if="currentuser.account_check != 1" v-b-modal="'permission'" type="checkbox"
                   v-model="auto.auto_points">
            <input v-else type="checkbox" true-value="1" false-value="0" v-model="auto.auto_points">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="cell medium-1 margin-automation no-padding">
          <v-select v-if="currentuser.account_check != 1" v-b-modal="'permission'" placeholder="Select"
                    v-model="auto.new_user_points" :reduce="referrer => referrer.id" label="name"
                    :options="pointsOptions" :clearable="false"></v-select>
          <v-select v-else placeholder="Select" v-model="auto.new_user_points" :reduce="referrer => referrer.id"
                    label="name"
                    :options="pointsOptions" :clearable="false"></v-select>
        </div>
        <div class="cell medium-4 padding-initial no-padding when">
          <label class="contain">when they download and login to the app for the first time.</label>
        </div>
        <div class="cell medium-1 margin-anchor-automation no-padding" style="padding-left: 5px !important">
          <a class="anchor-automation" data-toggle="modal" data-target="#why">Why?</a>
        </div>

        <div class="cell medium-3"></div>
        <div class="cell medium-8 h_8">
          <hr class="custom-hr">
        </div>
        <div class="cell medium-4"></div>
        <template>
          <div class="cell medium-3 no-padding automate-width auto_width">
            <label class="contain auto-padding">Automatically award any referring app user
              <input v-if="currentuser.account_check != 1" v-b-modal="'permission'" type="checkbox"
                     v-model="auto.check_share_points">
              <input v-else type="checkbox" v-model="auto.check_share_points">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="cell medium-1 margin-automation no-padding">
            <v-select v-if="currentuser.account_check != 1" v-b-modal="'permission'" placeholder="Select"
                      v-model="auto.app_share_points" :reduce="referrer => referrer.id" label="name"
                      :options="pointsOptions" :clearable="false"></v-select>
            <v-select v-else placeholder="Select" v-model="auto.app_share_points" :reduce="referrer => referrer.id"
                      label="name"
                      :options="pointsOptions" :clearable="false"></v-select>
          </div>
          <div class="cell medium-4 padding-initial no-padding colleague">
            <label class="contain">if they share the {{ brandName }} app link with a colleague.</label>
          </div>
          <div class="cell medium-4"></div>
          <div class="cell medium-12 padding-initial no-padding" style="padding-left: 65px !important">
            <label class="contain">Points will only be awarded when the colleague registers for the app. Staff must be pre-authorised to use the app for the points to be awarded. </label>
          </div>
          <div class="cell medium-8 h_8 last_hr">
            <hr class="custom-hr">
          </div>
          <div class="cell medium-4"></div>

          <div class="modal fade" id="why" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
            <div class="modal-dialog modal-automation" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h3>Why welcome points?</h3>
                  <p><br>We recommend awarding app users a small number of points as soon as they
                    download the app because this psychologically makes them more likely to want
                    to earn more points
                  </p>
                  <p><br>In our early-bird clients, we found that those who awarded welcome points had
                    on average, xx% more staff registered.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <b-modal :id="'permission'" size="lg" hide-footer>
          <h3>Permissions</h3>
          <p>You don't have the required permissions to edit these<br>settings. If you think this is an error,
            please contact {{ primaryuser.first_name }} {{ primaryuser.surname }}.
          </p>
        </b-modal>
        <b-modal modal-class="app-settings" id="promote" @close="onPromoteClose" size="lg" hide-footer>
          <h3>Peer-to-peer app sharing</h3>
          <p>Boost app sign-ups by promoting this feature on the app homepage.</p>
          <p><a target="_blank" :href="redirect">Click here to promote sharing</a></p>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['currentuser', 'autopoints', 'userpoints', 'primaryuser', 'checksharepoints', 'appsharepoints', 'should_show_promote'],
  data() {
    return {
      points: 5,
      pointsOptions: [{id: 3, name: '3 points'}, {id: 5, name: '5 points'}, {id: 10, name: '10 points'}],
      auto: {
        auto_points: this.autopoints,
        new_user_points: this.userpoints,
        check_share_points: this.checksharepoints,
        app_share_points: this.appsharepoints
      },
      shouldShowPromote: this.should_show_promote,
      promote_sharing: this.currentuser.customer.promote_sharing,
      redirect: location.protocol + "//" + location.hostname + "/dashboard/account#app-settings"
    }
  },
  mounted() {
    var self = this;
    if (this.shouldShowPromote == 1) {
      self.$bvModal.show('promote');
      this.shouldShowPromote == 0;
    };
  },
  methods: {
    save() {
      var self = this;
      axios.post('/dashboard/points/set-user-points', {auto: this.auto})
        .then(function () {
          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Updated',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast"
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s"
            },
            hideClass: {
              popup: "animated fadeOutLeft slow"
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs"
          });
          setTimeout(() => location.reload(), 2500);
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    onPromoteClose() {
      var self = this;
      this.$emit('closePromote');
    }
  }
}
</script>
