<template>
    <div>
        <!-- LEADERBOARD TABLE - SINGLE ROW (NO MOVE) -->
        <div class="grid-x grid-padding-x align-middle b_border_b1">
            <div class="cell auto" style="width: 5%">
                <p class="tb_margin_1">
                    <strong>{{ user.first_name }}</strong>
                </p>
            </div>
            <div class="cell auto">
                <p class="tb_margin_1">
                    <strong>{{ user.surname }}</strong>
                </p>
            </div>
            <div class="cell medium-2 ">
                <p class="tb_margin_1">
                    <a :href="'mailto:' + user.email"
                        ><strong>{{ user.email }}</strong></a
                    >
                </p>
            </div>

            <div class="cell auto">
                <p class="tb_margin_1">
                    {{ this.micrositeName(user.country_admin) }}
                </p>
            </div>

            <div class="cell auto" align="center">
                <p class="tb_margin_1">
                    {{ user.active ? "Active" : "Inactive" }}
                </p>
            </div>
            <div class="cell shrink" align="center">
                <div class="edit--button grey_link" @click="toggleEditMode()">
                    Edit
                </div>
            </div>
            <div class="cell shrink" align="center">
                <div
                    class="button pink_outline_btn b_margin_0"
                    @click="toggleActive"
                >
                    {{ !user.active ? "Activate" : "Deactivate" }}
                </div>
            </div>
            <div class="cell shrink" align="center">
                <div class="tb_margin_1" v-show="!sentDetails">
                    <a class="button pink_btn b_margin_0" @click="sendDetails()"
                        >Send user details</a
                    >
                </div>
                <span class="button pink_btn b_margin_0" v-show="sentDetails"
                    >Details sent!</span
                >
            </div>
        </div>
        <!-- LEADERBOARD TABLE - SINGLE ROW (NO MOVE) -->
        <div
            class="grid-x grid-padding-x align-middle b_border_b1 background_grey"
            v-show="editMode"
        >
            <div class="cell t_margin_2">
                <form data-abide novalidate style="width: 100%">
                    <div class="grid-x grid-padding-x align-bottom">
                        <div class="cell auto">
                            <label
                                >First Name*
                                <input
                                    type="text"
                                    class="white_form b_margin_1-25"
                                    v-model="form.first_name"
                                    placeholder=""
                                    aria-errormessage="firstName-edit"
                                    required
                                />
                                <span class="form-error" id="firstName-edit">
                                    Please enter a first name.
                                </span>
                            </label>
                        </div>
                        <div class="cell auto ">
                            <label
                                >Surname*
                                <input
                                    type="text"
                                    class="white_form b_margin_1-25"
                                    v-model="form.surname"
                                    placeholder=""
                                    aria-errormessage="surName-edit"
                                    required
                                />
                                <span class="form-error" id="surName-edit">
                                    Please enter a surname.
                                </span>
                            </label>
                        </div>
                        <div class="cell auto" align="right">
                            <div
                                class="button orange_btn no_wrap_btn"
                                @click="toggleEditMode()"
                            >
                                Cancel
                            </div>
                            <div
                                class="button pink_btn no_wrap_btn"
                                @click="save()"
                            >
                                Update
                            </div>
                            <i
                                v-if="this.savedStatus == 'saving'"
                                class="fa fa-spinner fa-pulse fa-3x fa-fw red"
                            ></i>
                            <i
                                class="fa fa-check fa-3x fa-fw green"
                                v-if="this.savedStatus == 'saved'"
                            ></i>
                        </div>
                    </div>
                    <div class="grid-x grid-padding-x align-middle">
                        <div class="cell auto">
                            <label
                                >Email address*
                                <input
                                    type="email"
                                    v-model="form.email"
                                    class="white_form b_margin_1-25"
                                    placeholder=""
                                    aria-errormessage="email-edit"
                                    required
                                />
                                <span
                                    class="form-error"
                                    id="email-edit"
                                    pattern="email"
                                >
                                    Please enter an email address.
                                </span>
                            </label>
                        </div>
                        <!-- <div class="cell auto ">
                          <div class="b_margin_minus20px yellow_link" align="right"><span data-tooltip tabindex="1" title="Fancy word for a beetle."><i class="far fa-question-circle"></i></span></div>
                          <input id="advanced-dashboard" type="checkbox" v-model="form.advanced_admin"><label for="advanced-dashboard">Allow advanced dashboard rights to this user</label>
                      </div> -->
                        <div class="cell auto"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["userData", "microsite"],
    data() {
        return {
            user: this.userData,
            editMode: false,
            savedStatus: null,
            sentDetails: false,
            form: {
                user_id: this.userData.id,
                first_name: this.userData.first_name,
                surname: this.userData.surname,
                email: this.userData.email,
                active: this.userData.active,
                advanced_admin: this.userData.advanced_admin
            }
        };
    },
    methods: {
        toggleEditMode() {
            if (!this.editMode) {
                this.editMode = true;
            } else {
                this.editMode = false;
            }
        },
        toggleActive() {
            if (!this.user.active) {
                this.user.active = true;
            } else {
                this.user.active = false;
            }

            var self = this;
            axios
                .post("/dashboard/account/toggle-active", this.form)
                .then(function(response) {
                    // handle success
                    console.log(response);
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        },
        sendDetails() {
            var self = this;
            axios
                .post("/dashboard/account/send-details", this.form)
                .then(function(response) {
                    // handle success
                    self.sentDetails = true;
                    console.log(response);
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        },

        micrositeName($id) {
            if (!$id) {
                return "All";
            }
            var i;
            for (i = 0; i < this.microsite.length; i++) {
                if (this.microsite[i].id == $id) {
                    return this.microsite[i].country;
                }
            }
        },
        save() {
            this.savedStatus = "saving";
            var self = this;
            axios
                .post("/dashboard/account/update-user", this.form)
                .then(function(response) {
                    // handle success
                    self.savedStatus = "saved";
                    self.user = self.form;
                    console.log(response);
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        }
    }
};
</script>
