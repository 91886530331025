<template>
    <div>
        <div v-show="showLoad" class="show-load">
            <img :src="myImage" />
        </div>
        <div class="grid-x">
            <div
                class="cell small-12 cell-padding-right"
                align="right"
            >
                <button
                    v-b-modal.addnewadmin
                    class="button pink_btn b_margin_0 no_wrap_btn job-add"
                >ADD USER</button>
            </div>

            <b-modal id="addnewadmin" size="xl" :hide-footer="true">
                <h3>Invite a Portal User</h3>
                <div class="cell">
                    <form data-abide novalidate style="width: 100%">
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    First Name*
                                    <input
                                        type="text"
                                        class
                                        v-model="newuser.first_name"
                                        placeholder
                                        aria-errormessage="firstName"
                                        required
                                    />
                                    <span class="form-error" id="firstName">Please enter a first name.</span>
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Last Name*
                                    <input
                                        type="text"
                                        class
                                        v-model="newuser.surname"
                                        placeholder
                                        aria-errormessage="surName"
                                        required
                                    />
                                    <span class="form-error" id="surName">Please enter a Last Name.</span>
                                </label>
                            </div>
                        </div>
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    Email address*
                                    <input
                                        type="email"
                                        v-model="newuser.email"
                                        class="white_form b_margin_1-25"
                                        placeholder
                                        aria-errormessage="email-edit"
                                        required
                                    />
                                    <span
                                        class="form-error"
                                        id="email-edit"
                                        pattern="email"
                                    >Please enter an email address.</span>
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Mobile number*
                                    <input
                                        type="text"
                                        v-model="newuser.phone_number"
                                        aria-errormessage="phone-edit"
                                        required
                                    />
                                    <span
                                        class="form-error"
                                        id="phone-edit"
                                        pattern="text"
                                    >Please enter a mobile number.</span>
                                </label>
                            </div>
                        </div>
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box" v-if="availableSites.length > 0">
                                <label>
                                    Select Site*
									<span class="yellow_link">
									<b-tooltip target="add-sites" triggers="hover" placement="topleft">If you select a single site, the portal user will ONLY be able to view and edit data for that site. If the portal user needs to see data for more than one site, please select 'All sites'.</b-tooltip>
									<span id="add-sites">
                                        <i class="fa fa-question-circle-o"></i></span>
                                    </span>
                                </label>
                                <multiselect class="multi-sites" v-model="newuser.site_id" :options="availableSites"
      @input="selecttag(newuser.site_id)"                                       :multiple="true" :clear-on-select="false" openDirection="below"
                                             :showLabels="false" placeholder="Select site(s)" label="name"
                                             track-by="name">
                                </multiselect>

                                <div class="input-tagged">
                                    <span v-for="(element, index) in newuser.site_id" v-bind:key="element.id">
                                        <div class="tag-input__tag tag-right">
                                        <span class="lablesize">{{ element.name }}</span>
                                        <span class="deselect-pad"
                                            ><span class="deselect" @click="removeSelectTag(index)"></span
                                        ></span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="cell auto input-box" style="margin:0 0px auto">
                                <label>
                                    Eligible to earn referral points?

                                    <span class="yellow_link">
									<b-tooltip target="messages" triggers="hover" placement="topright">If you select 'No' this user will be able to share jobs, but will not earn any referral points for doing so. They will still be able to be awarded bonus points.</b-tooltip>
									<span id="messages">
                                        <i class="fa fa-question-circle-o"></i></span>
                                    </span>
                                    <v-select
                                        placeholder="Select"
                                        label="name"
                                        v-model="newuser.points_eligible"
                                        :reduce="referrer => referrer.id"
                                        :options="eligibleOptions"
                                    ></v-select>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="cell t_margin_2">

                    <p>
                        Permissions
                        <br />NB - all admin users have read only access to the core pages of the portal
                    </p>

                    <div class="switch-label grid-x">
                        <label class="switch" for="candidate_check">
                            <input
                                type="checkbox"
                                name="candidate_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.candidate_check"
                                class="custom-control-input"
                                id="candidate_check"
                            />
                            <span class="slider round"></span>
                        </label>Edit candidate details and manage candidate progress
                    </div>


                    <div class="switch-label grid-x" for="bonus_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="bonus_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.bonus_check"
                                class="custom-control-input"
                                id="bonus_check"
                            />
                            <span class="slider round"></span>
                        </label>Allocate bonus points
                    </div>

                    <div class="switch-label grid-x" for="bonus_categories_check">
                      <label class="switch">
                        <input
                          type="checkbox"
                          name="bonus_categories_check"
                          true-value="1"
                          false-value="0"
                          v-model="newuser.bonus_categories_check"
                          class="custom-control-input"
                          id="bonus_categories_check"
                        />
                        <span class="slider round"></span>
                      </label>Create/Edit bonus point categories
                    </div>

                    <div class="switch-label grid-x" for="approve_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="approve_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.approve_check"
                                class="custom-control-input"
                                id="approve_check"
                            />
                            <span class="slider round"></span>
                        </label> Approve payment requests
                    </div>

                    <div class="switch-label grid-x" for="paid_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="paid_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.paid_check"
                                class="custom-control-input"
                                id="paid_check"
                            />
                            <span class="slider round"></span>
                        </label>Mark payment requests as paid
                    </div>

                    <div class="switch-label grid-x" for="notification_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="notification_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.notification_check"
                                class="custom-control-input"
                                id="notification_check"
                            />
                            <span class="slider round"></span>
                        </label>Send and edit notifications
                    </div>

                    <div class="switch-label grid-x" for="users_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="users_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.users_check"
                                class="custom-control-input"
                                id="users_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage app users
                    </div>

                    <div class="switch-label grid-x" for="admin_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="admin_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.admin_check"
                                class="custom-control-input"
                                id="admin_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage admin users
                    </div>

                    <div class="switch-label grid-x" for="account_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="account_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.account_check"
                                class="custom-control-input"
                                id="account_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage account settings
                    </div>

                    <div class="switch-label grid-x" for="job_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="job_check"
                                true-value="1"
                                false-value="0"
                                v-model="newuser.job_check"
                                class="custom-control-input"
                                id="job_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage job settings
                    </div>

                    <div class="cell auto popup-button">
                        <button
                            class="button pink_btn no_wrap_btn"
                            style="float: right"
                            @click="AddAdminUser()"
                            :disabled="newbtnDisbaled"
                        >Invite</button>
                    </div>

                    <!-- </form> -->
                </div>
            </b-modal>
        </div>

        <div class="modal fade" id="duplicate-user" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog only_csv" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4><br>Whoops! That email address is registered to an existing user.</h4>
						<p>Please check your list of portal and app users to see if they are already registered. If the email is being used by an app user, and you want them to be able to access your portal, you can convert them into an app user. To find out more, please check out our help article <a href="https://support.carefriends.co.uk/articles/5089665-how-to-covert-an-app-user-into-an-admin-user" target="_blank">here</a>.</p>

                        <div class="cell auto popup-button">
                            <input @click="closeDup()" type="button" class="button btn_lg btn pink_btn" value="Ok">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Paginate from "vuejs-paginate";
    import Multiselect from 'vue-multiselect'

    Vue.component('multiselect', Multiselect)
    export default {
        components: { Multiselect },
        props: ["advanced", "id", "sitesarr"],
        data(){
            return {
                users: [],
                savedStatus: null,
                sentDetails: false,
                availableSites: [],
                myImage: '/images/loading.gif',
                showLoad: false,
                custId : this.id,
                newuser:{
                    first_name:" ",
                    surname:"",
                    email:"",
                    phone_number:"",
                    candidate_check:false,
                    bonus_check:false,
                    bonus_categories_check:false,
                    approve_check:false,
                    paid_check:false,
                    notification_check:false,
                    users_check:false,
                    admin_check:false,
                    account_check:false,
                    job_check:false,
                    progress_check:false,
                    site_id:[],
                    points_eligible: 1
                },
				user:{
				    site_id:[]
				},
                userData: [],
                siteId: '',
                sites: this.sitesarr ? this.sitesarr : [],
                eligibleOptions: [{id: 1, name: 'Yes'}, {id: 0, name: 'No'}]
            }
        },
        methods: {
		    	selecttag(value) {
                    if (!value || value.length === 0) {
                        return;
                    }

                    const allSitesOption = this.availableSites.find(site => site.id === -1);
                    const selectedAllSites = value.find(site => site.id === -1);
                    const selectedAllSitesIndex = value.findIndex(site => site.id === -1);

                    if (selectedAllSites) {
                        if (selectedAllSitesIndex === 0 && value.length > 1) {
                            this.newuser.site_id = value.slice(1);
                        } else {
                            this.newuser.site_id = [allSitesOption];
                        }
                    } else {
                        this.newuser.site_id = value.filter(site => site.id !== -1);
                    }
                },
                removeSelectTag: function (index) {
                    this.newuser.site_id.splice(index, 1)
                },

				selectedtag(value){
				var self=this;

		         for(var i=0; i < value.length; i++){
					if( value[i].id == -1 || value[i].name=='All Sites'){
					  if(value.length >1 && value[0].id== -1 || value[0].name=='All Sites'){
					  let va = value.splice(i, 1); self.user.sites_users = value;
					  }else if(value.length >1 && value[i].id== -1 || value[i].name=='All Sites'){

               value.splice(0, i);

            self.user.sites_users = value;
					  }else{
					  self.user.sites_users = value[i];
					  }
					}else{
					 self.user.sites_users = value;
					}
				  }

				},
            closeDup() {
                $('#duplicate-user').hide();
            },
            showMenu() {
                $(this.$el).foundation();
            },
			selectedSites: function( flag = null) {
			    //alert(this.newuser.site_id);
                if (flag) {
                    if (this.newuser.site_id[0] == -1) {
                        this.newuser.site_id.splice(0, 1);
                    }
                }
                if (this.newuser.site_id.length > 1) {
                    if (this.newuser.site_id[0] == -1) {
                        this.newuser.site_id.splice(0, 1);
                    } else {
                        for (var i = 0; i < this.newuser.site_id.length; i++) {
                            if (i == 0) {
                                continue;
                            }
                            if (this.newuser.site_id[i] == -1) {
                                this.newuser.site_id = [];
                                this.newuser.site_id[0] = -1;
                            }
                        }
                    }
                }
                let selected_filter = [];
                for (var i = 0; i < this.availableSites.length; i++) {
                    if($.inArray( this.availableSites[i].id, this.newuser.site_id ) !== -1) {
                        this.availableSites[i].image = '/images/selected.png';
                        selected_filter.push(this.availableSites[i].name);
                    } else {
                        this.availableSites[i].image = '/images/idle.png';
                    }
                }
				 this.newuser.site_id =this.newuser.site_id;
				//alert(values);
                if(selected_filter.length > 0){

                }

            },

            getAvailableSites(){
                //Create array of available sites for dropdown
                var allSites = Object.keys(this.sites);
                var availableSites = [];
                var noSite = {
                    id: -1,
                    name: "All sites",
					image: '/images/idle.png'
                };
                availableSites.push(noSite);
                for (var i = allSites.length - 1; i >= 0; i--) {
                  if (this.sites[allSites[i]].name != 'None') {
                    availableSites.push(this.sites[allSites[i]]);
                  }
                }
                this.availableSites = availableSites;
            },

            AddAdminUser(){
                // alert('Invite Sent')
				console.log(this.newuser);
                Vue.swal({
					title: "Invite sending...",
					html: "",
					allowOutsideClick: false,
					onBeforeOpen: () => {
					  Vue.swal.showLoading();
					}
				  });
                this.savedStatus = 'saving';
                var self = this;
				var customerId = self.custId;

                axios.post('/dashboard/super/clients/'+customerId+'/create-user', this.newuser)
                    .then(function (response) {
					 Vue.swal.close();
                        if(response.data.message && response.data.message == 'Email Already Exists') {
                            $('#duplicate-user').show();
                            return;
                        }
                        // handle success


						self.resetForm();


                        self.savedStatus = 'saved';
                        self.$bvModal.hide("addnewadmin");

                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invite Sent',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                    })
                    .catch(function (error) {
                        // handle error
                        if(error.response.data.errors.email[0] != null) {
                            Vue.swal({
                                title: error.response.data.errors.email[0],
                                confirmButtonColor: '#F84366'
                            });
                        }
                    })
            },
			resetForm() {
			  this.newuser = {
				    first_name:"",
                    surname:"",
                    email:"",
                    phone_number:"",
                    candidate_check:false,
                    bonus_check:false,
                    bonus_categories_check:false,
                    approve_check:false,
                    paid_check:false,
                    notification_check:false,
                    users_check:false,
                    admin_check:false,
                    account_check:false,
                    job_check:false,
                    progress_check:false,
                    site_id:"",
                    points_eligible: 1
			  };

			},


        },
        mounted() {
            this.getAvailableSites();

        },
        computed:{
            newbtnDisbaled(){
                if(this.newuser.first_name && this.newuser.surname && this.newuser.email && this.newuser.phone_number){
                    return false;
                }else return true;
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
	.pane-leftside
	{
	    left: -7% !important;
	}
	.multiselect__content{
	 font:400 13px/20px "InterUI", sans-serif !important;
	}
	.multiselect__option {
	color: #363b54;
    font:400 13px/20px "InterUI", sans-serif !important;
    text-transform: none;
    cursor: pointer;
    white-space: nowrap;
   }
   .multiselect, .multiselect__input, .multiselect__single{
     color: #363b54;
     font:400 13px/20px "InterUI", sans-serif !important;
   }
   .multiselect:focus, .multiselect__input:focus, .multiselect__single:focus{
     color: #363b54;
     font:400 13px/20px "InterUI", sans-serif !important;
   }
   .multiselect, .multiselect__input, .multiselect__single{
     color: #363b54;
     font:400 13px/20px "InterUI", sans-serif !important;
   }
   .multi-sites .multiselect__tags{
   color: #363b54;
     font:400 13px/20px "InterUI", sans-serif !important;
   }
   .multiselect__input::placeholder {
    color: #363b54;
    font: 400 13px/20px "InterUI", sans-serif !important;
  }
  .multiselect--active .multiselect__input::placeholder {
    color: #363b54;
    font: 400 13px/20px "InterUI", sans-serif !important;
  }
 .multiselect__input:focus, .multiselect__input:hover, {
    color: #363b54;
    font: 400 13px/20px "InterUI", sans-serif !important;
  }
  .input-tagged {
  float: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
  flex-basis: 100%;
  flex-grow: 1;
}
.tag-right {
  margin-right: 5px;
}

</style>
