<template>
    <div>
	  <ckeditor v-model="editorData"  :config="editorConfig" ></ckeditor>   
        <input id="trix" type="text" :name="name" :value="value">
        <!--<trix-editor  ref="trix" toolbar="trix-toolbar" input="trix" :placeholder="placeholder"><VueEmoji ref="trix" input="trix"  /></trix-editor>-->
    </div>
</template>
<script>
    //import Trix from 'trix';
	import CKEditor from 'ckeditor4-vue/dist/legacy.js';

     Vue.use( CKEditor );
    export default {
        props: {
            name: {
                type: String
            },
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String
            }
        },
		data() {
            return {
                editorData: '',
                editorConfig: {
				    plugins: 'mentions,emoji,basicstyles,undo,link,wysiwygarea,toolbar',
					 
                    toolbar: [
					    [ 'Bold', 'Italic', 'NumberedList', 'NumberedList' ],
						[ 'Undo', 'Redo' ],
						[ 'EmojiPanel' ]
					]
                }
            };
        },
		
        mounted() {
            this.$refs.trix.addEventListener('trix-change', e => {
                this.$emit('input', e.target.innerHTML);
            });
        },
  components: {
      CKEditor
      }
    }
	
	const input = document.querySelector('input');
	const log = document.getElementById('trix');
	if (input !== null) {
		input.addEventListener('input', updateValue);
	}
	function updateValue(e) { alert(e.target.value);
	  log.textContent = e.target.value;
	}

</script>
