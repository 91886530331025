<template>
    <div class="flex-center position-ref full-height">
        <div class="content">
            <div class="m-b-md" style="height:auto; min-height: 750px;">
			  <div v-if="currentuser.customer.custom_scheme_rules==1">
			  <p class="custom-scheme-text">
			  Your organization has the 'custom rules' feature enabled. This allows you to upload a completetly custom set of scheme rules to your account, rather than using our recommended template. All app users are required to agree to these scheme rules when they first register for the app.
			  </p>
			  <p class="custom-scheme-text">
			  you can download our recommended scheme rules <a href='/dashboard/account/downloadschemepolicy/'>here</a>, if you would like to use this as a base to form your own. By uploading your own rules, you accept that the content and wording of those rules are entirely the responsibility of your organization.
			  </p>

			<div class="custom-scheme-content">
                <div class="input-box">
                <label>Scheme rules</label>
				<ckeditor
                  v-model="custom_scheme"
                  name="custom_scheme"
				  ref="custom_scheme"
                  id="custom_scheme"
                  :config="editorConfig"
                ></ckeditor>

                </div>
				<div class="input-box">
				 <div style="width:50%; float:left;">
				  <div class="Preview" data-toggle="modal" data-target="#scheme-preview" v-if="this.custom_scheme != ''">Preview</div>
				 </div>
				 <div v-if="this.custom_scheme != ''">
				 <div style="width:50%; float:left;">
				  <div class="Revert-to-default" v-if="this.custom_scheme.replace(/(<([^>]+)>)/gi, '').length != this.unsaved.replace(/(<([^>]+)>)/gi, '').length+1"" @click="reset"> Revert to default</div>
				 </div>
				 </div>
				</div>
			</div>
			<hr class="line-bar">
			 <div class="cell auto popup-button-scheme bottom-bar">
			 <h3 class="Unsaved-updates" v-if="this.custom_scheme.replace(/(<([^>]+)>)/gi, '').length === this.unsaved.replace(/(<([^>]+)>)/gi, '').length+1"></h3>
			 <h3 class="Unsaved-updates" v-else>
			 <span v-if="this.custom_scheme != ''">
			 Unsaved updates
			 </span>
			  <span v-else>

			 </span>
			 </h3>
			 <button v-if="currentuser.account_check" class="button btn_lg btn orange_btn" @click="reset">Discard</button>
                <input
                  v-if="currentuser.account_check"
                  type="button"
                  name="next"
                  class="button btn_lg btn pink_btn"
                  value="Save"
                  @click="addCustomeScheme()"
				  :disabled="newbtnDisbaled"
                />
              </div>




			<div class="modal fade" id="scheme-preview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog edit-job-modal" role="document">
                <div class="modal-content">
                    <div class="modal-header">

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h3>Scheme policy</h3>

                        <div class="grid-x">
                            <div class="cell auto" v-html="this.custom_scheme">

                            </div>

                        </div>


                        <!--<div class="cell auto popup-button">
                            <input type="button" class="button btn_lg btn orange_btn" data-dismiss="modal" aria-label="Close" value="Cancel">

                        </div>-->

                    </div>
                </div>
            </div>
        </div>


			  </div>
			  <div v-else>

			  <p class="uper-text-schema medium-8">
			  When they login to the app for the first time, every employee is required to read and agree to your scheme rules as part of their registration process. The scheme rules are also accessible to your employees at any time in the 'settings' section of the app.
			  </p>

                <p
                    class="uper-text-schema medium-8"
                >By default, we’ve applied our recommended scheme rules to your account, but there are several ways in which you can customise them to better suit your organisation. You can use this page to view and/or amend the scheme rules shown to your employees.</p>
                <div class="inner-section  tabs-content new-schema">
                    <div class="grid-x grid-padding-x">
                        <div class="cell auto medium-4">
                            <!--<p style="font-size:13px;">Watch the video for explanations and help on setting your custom scheme rules</p>-->

                            <vimeo-player
                                ref="player"
                                :video-id="385993376"
                                @ready="onReady"
                                :player-height="176"
                                :player-width="282"
                            ></vimeo-player>
                        </div>
                    </div>

                    <!-- @submit.prevent="submit" -->
                    <form @submit="checkForm">
                        <p v-if="errors.length">Please correct the following error(s):</p>
                        <ul>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>

                        <!-- // Vue Js Collapsible -->
                        <div class="sub-section">
                            <div class="sub-heading b_border_b1 grid-x grid-padding-x">
                                <i class="fa fa-angle-down"></i>
                                <h4
                                    v-b-toggle.collapse-1
                                    class="m-1 collapsible"
                                    name="staff_eligibility"
                                >1. Staff Eligibility</h4>
                            </div>

                            <b-collapse visible id="collapse-1">
                                <div class>
                                    <p>Who is allowed to participate in the referral scheme?</p>

                                    <label class="points-check">
                                        <input
                                            v-model="fields.allowedparticipantsall"
                                            id="all_employees"
                                            type="checkbox"
                                            name="all_employees"
                                            value="0"
                                        />
                                        <span class="mark"></span>
                                        <span class="checkbox-text">All Employees</span>
                                    </label>

                                    <br />

                                    <label class="points-check">
                                        <input
                                            v-model="fields.allowedparticipantsexp"
                                            id="all_employees_wexcection"
                                            type="checkbox"
                                            name="all_employees_wexcection"
                                            value="1"
                                        />
                                        <span class="mark"></span>
                                        <span class="checkbox-text">All employees with the exception of senior management and those involved in recruitment</span>
                                    </label>
                                    <br />

                                    <label class="points-check">
                                        <input
                                            v-model="fields.haventstarted"
                                            id="haventstarted"
                                            type="checkbox"
                                            name="haventstarted"
                                            value="0"
                                        />
                                        <span class="mark"></span>
                                        <span
                                            class="checkbox-text"
                                        >Candidates who have accepted a position but not yet started work</span>
                                    </label>
                                    <br />
                                </div>
                            </b-collapse>
                        </div>

                        <div class="sub-section form-inline">
                            <div class="sub-heading b_border_b1 grid-x grid-padding-x">
                                <i class="fa fa-angle-down"></i>

                                <h4
                                    v-b-toggle.collapse-2
                                    class="m-1 collapsible"
                                    name="candidates_eligibility"
                                >2. Candidates Eligibility</h4>
                            </div>

                            <b-collapse visible id="collapse-2">
                                <div class="grid-x select-inline">
                                    <div class="inline-text-1">
                                        <p>Candidate must live within</p>
                                    </div>

                                    <div class="inline-box live-within">
                                        <v-select
                                            class="form-control"
                                            placeholder="Live Within:"
                                            v-model="fields.livewithin"
                                            :value="liveWithinOptions.within"
                                            label="Live Within"
                                            :options="liveWithinOptions"
                                            :clearable="false"
                                        ></v-select>
                                    </div>

                                    <div class="inline-text-21">
                                        <p>of their proposed workplace</p>
                                    </div>
                                </div>

                                <div class="grid-x select-inline">
                                    <div class="cell medium-6">
                                        <p>Candidates must not have been previously referred within</p>
                                    </div>

                                    <div class="box-inline reffered-within">
                                        <v-select
                                            placeholder="Refered Within:"
                                            v-model="fields.prevref"
                                            label="Refered Within"
                                            :options="referedwithinOption"
                                            :clearable="false"
                                        ></v-select>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>

                        <div class="sub-section">
                            <div class="sub-heading b_border_b1 grid-x grid-padding-x">
                                <i class="fa fa-angle-down"></i>
                                <h4 v-b-toggle.collapse-3 class="m-1 collapsible" name="payments">3. Payments</h4>
                            </div>
                            <b-collapse visible id="collapse-3">
                                <p class="inline-input">
                                    Before they can request, payment of their points, staff must have earned

                                    <input class="box-inline request-payment"
                                           v-model="fields.requestpaymentpoints"
                                           type="text"
                                           name="requestpaymentpoints"
                                    /> points
                                </p>
                                <p>All staff points will be paid out</p>
                                <label class="points-check">
                                    <input
                                        @click="requestpaymentOptions($event)"
                                        type="checkbox"
                                        v-model="fields.payment_eof_calendar_year"
                                        name="payment_eof_calendar_year"
                                        value="1"
                                    />
                                    <span class="mark"></span>
                                    <span class="checkbox-text">At the end of calendar year</span>
                                </label>

                                <label class="points-check">
                                    <input
                                        @click="requestpaymentOptions($event)"
                                        type="checkbox"
                                        v-model="fields.payment_eof_financial_year"
                                        name="payment_eof_financial_year"
                                        value="1"
                                    />
                                    <span class="mark"></span>
                                    <span class="checkbox-text">At the end of the organizations financial year</span>
                                </label>
                                <label class="points-check">
                                    <input
                                        @click="requestpaymentOptions($event)"
                                        type="checkbox"
                                        v-model="fields.payment_eof_quarter"
                                        name="payment_eof_quarter"
                                        value="1"
                                    />
                                    <span class="mark"></span>
                                    <span class="checkbox-text">At the end of every quarter</span>
                                </label>
                                <div class="select-inline reach-maximum">
                                    <label class="points-check box-included">
                                        <input
                                            class="reach-max-select"
                                            @click="requestpaymentOptions($event)"
                                            v-model="fields.payment_max_point"
                                            type="checkbox"
                                            name="payment_max_point"
                                            value="1"
                                        />
                                        <span class="mark"></span>
                                        <span class="checkbox-text">When they reach maxiumum of</span>
                                        <input
                                            class="reach-maximum-box"
                                            type="text"
                                            name="maxpoints"
                                            value="500"
                                            placeholder="500"
                                            v-model="fields.paymentmaxpoints"
                                            :disabled="paymaxpointsdisabled == true"
                                        /> points
                                    </label>
                                </div>
                                <label class="points-check">
                                    <input
                                        @click="requestpaymentOptions($event)"
                                        type="checkbox"
                                        v-model="payment_none_of_above"
                                        name="payment_none_of_above"
                                        value="1"
                                    />
                                    <span class="mark"></span>
                                    <span class="checkbox-text">None of the above</span>
                                </label>
                            </b-collapse>
                        </div>

                        <div class="sub-section">
                            <div class="sub-heading b_border_b1 grid-x grid-padding-x">
                                <i class="fa fa-angle-down"></i>
                                <h4
                                    v-b-toggle.collapse-4
                                    class="m-1 collapsible"
                                    name="termination_impact"
                                >4. Impact of Termination</h4>
                            </div>
                            <b-collapse visible id="collapse-4">
                                <p>The following types of leavers, may or may not be entitled to their points payments, depending on the circumstances around their leaving</p>

                                <div class="points-check-2 clearfix">
                                    <p>Good leavers who complete their full notice period.</p>
                                    <div class="radio-section clearfix">
                                        <div class="radio-option-1" :class="{selectedRadio:radio.one}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.fullnotice"
                                                    type="radio"
                                                    name="fullperiod"
                                                    value="Yes"
                                                    @change="radio.one = true;radio.two = false; radio.three=false"
                                                />
                                                <span class="radiobox"></span>Yes
                                            </label>
                                        </div>

                                        <div class="radio-option-2" :class="{selectedRadio:radio.two}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.fullnotice"
                                                    type="radio"
                                                    name="fullperiod"
                                                    value="No"
                                                    @change="radio.one = false;radio.two = true; radio.three=false"
                                                />
                                                <span class="radiobox"></span>No
                                            </label>
                                        </div>

                                        <div class="radio-option-3" :class="{selectedRadio:radio.three}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.fullnotice"
                                                    type="radio"
                                                    name="fullperiod"
                                                    value="At the Discretion of Directors"
                                                    @change="radio.one = false;radio.two = false; radio.three=true"
                                                />
                                                <span class="radiobox"></span>At the Discretion of Directors
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="points-check-2 clearfix">
                                    <p>Good leavers who do not complete their full notice period or other requirements</p>
                                    <div class="radio-section clearfix">
                                        <div class="radio-option-1" :class="{selectedRadio:radio.four}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.incompletenotice"
                                                    type="radio"
                                                    name="incompletenotice"
                                                    value="Yes"
                                                    @change="radio.four = true;radio.five = false; radio.six=false"
                                                />
                                                <span class="radiobox"></span>Yes
                                            </label>
                                        </div>

                                        <div class="radio-option-2" :class="{selectedRadio:radio.five}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.incompletenotice"
                                                    type="radio"
                                                    name="incompletenotice"
                                                    value="No"
                                                    @change="radio.four = false;radio.five = true; radio.six=false"
                                                />
                                                <span class="radiobox"></span>No
                                            </label>
                                        </div>

                                        <div class="radio-option-3" :class="{selectedRadio:radio.six}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.incompletenotice"
                                                    type="radio"
                                                    name="incompletenotice"
                                                    value="At the Discretion of Directors"
                                                    @change="radio.four = false;radio.five = false; radio.six=true"
                                                />
                                                <span class="radiobox"></span>At the Discretion of Directors
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="points-check-2">
                                    <p>Bad Leavers</p>
                                    <div class="radio-section clearfix">
                                        <div class="radio-option-1" :class="{selectedRadio:radio.seven}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.badleavers"
                                                    type="radio"
                                                    name="badleavers"
                                                    value="Yes"
                                                    @change="radio.seven = true;radio.eight = false; radio.nine=false"
                                                />
                                                <span class="radiobox"></span>Yes
                                            </label>
                                        </div>

                                        <div class="radio-option-2" :class="{selectedRadio:radio.eight}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.badleavers"
                                                    type="radio"
                                                    name="badleavers"
                                                    value="No"
                                                    @change="radio.seven = false;radio.eight = true; radio.nine=false"
                                                />
                                                <span class="radiobox"></span>No
                                            </label>
                                        </div>

                                        <div class="radio-option-3" :class="{selectedRadio:radio.nine}">
                                            <label class="radio-btn">
                                                <input
                                                    v-model="fields.badleavers"
                                                    type="radio"
                                                    name="badleavers"
                                                    value="At the Discretion of Directors"
                                                    @change="radio.seven = false;radio.eight = false; radio.nine=true"
                                                />
                                                <span class="radiobox"></span>At the Discretion of Directors
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                        <div class="sub-section">
                            <div class="sub-heading b_border_b1 grid-x grid-padding-x">
                                <i class="fa fa-angle-down"></i>

                                <h4
                                    v-b-toggle.collapse-2
                                    class="m-1 collapsible"
                                    name="candidates_eligibility"
                                >5. Share Cap</h4>
                            </div>

                            <b-collapse visible id="collapse-2">
                                <div class="grid-x select-inline">
                                    <div class="inline-text-2">
                                        <p>App users can share jobs as frequently as they wish, but they will only
                                            receive share points for the first</p>
                                    </div>

                                    <div class="inline-box live-within live-within-2">
                                        <v-select
                                            class="form-control"
                                            v-model="fields.share_cap"
                                            :reduce="referrer => referrer.id"
                                            label="name"
                                            :options="shareCapOptions"
                                            :clearable="false"
                                        ></v-select>
                                    </div>

                                    <div class="inline-text-1">
                                        <p>shares per calendar month.</p>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
					<div class="sub-section">
                        <div class="cell auto  del-candidate" style="text-align:right;">
                            <input type="button" class="button btn_lg btn orange_btn" value="Download Scheme Rules"
                                   onclick="window.location='/dashboard/account/downloadschemepolicy/';">
                            <input v-if="currentuser.account_check" type="button" class="btn button pink_btn float-right" @click="checkForm"
                                   :disabled="btnDisabled"
                                   value="Update">
                        </div>
					</div>
                    </form>
                </div>
			  </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vueVimeoPlayer from "vue-vimeo-player";
    import Vue from "vue";
	import CKEditor from 'ckeditor4-vue/dist/legacy.js';

    Vue.use(vueVimeoPlayer);
    export default {
		components: {
			CKEditor
		},
        props: ["schemedata","currentuser","scheme_page"],
        data() {
            return {
                fields: {
                    allowedparticipantsall: this.schemedata.allowedparticipantsall,
                    allowedparticipantsexp: this.schemedata.allowedparticipantsexp,
                    haventstarted: this.schemedata.allowedparticpantshavntshared,
                    livewithin: this.schemedata.livewithin,
                    prevref: this.schemedata.previouslyreffered,

                    requestpaymentpoints: this.schemedata.paymentrequestpoints ? this.schemedata.paymentrequestpoints : 25,
                    payment_eof_calendar_year: this.schemedata.payment_eof_calendar_year,
                    payment_eof_financial_year: this.schemedata.payment_eof_financial_year,
                    payment_eof_quarter: this.schemedata.payment_eof_quarter,
                    payment_max_point: this.schemedata.payment_max_point,

                    fullnotice: this.schemedata.fullnotice ? this.schemedata.fullnotice : 'Yes',
                    incompletenotice: this.schemedata.incompletenotice ? this.schemedata.incompletenotice : 'At the Discretion of Directors',
                    badleavers: this.schemedata.badleavers ? this.schemedata.badleavers : 'No',
                    paymentmaxpoints: this.schemedata.paymentmaxpoints,
                    share_cap: this.schemedata.share_cap ? this.schemedata.share_cap : 10
                },
                errors: {},
                document: {},
                update: 0,
                paymaxpointsdisabled: true,
                requestpayment: false, // it will be used for Payment options selection and enable/disable "Update" button
                payment_none_of_above: true, // it used to enable/disable "Update" button.
                custom_scheme: this.scheme_page.custom_scheme ? this.scheme_page.custom_scheme : '',
                preview:'',
                unsaved:this.scheme_page.custom_scheme? this.scheme_page.custom_scheme : '',
                liveoption: [{id: '5 Miles', name: '5 Miles'}, {id: '10 Miles', name: '10 Miles'}, {id: '15 Miles', name: '15 Miles'}, {id: '20 Miles', name: '20 Miles'}, {id: 'a reasonable commutable distance', name: 'a reasonable commutable distance'}],
                referredoption : [{id: '6 months', name: '6 Months'}, {id: '12 months', name: '1 Year'}],
                radio: {
                    one: false,
                    two: false,
                    three: true,
                    four: false,
                    five: false,
                    six: true,
                    seven: false,
                    eight: false,
                    nine: true
                },
                liveWithinOptions: [
                    "5 Miles",
                    "10 Miles",
                    "15 Miles",
                    "20 Miles",
                    "a reasonable commutable distance"
                ],
                referedwithinOption: ["6 months", "12 months"],
                shareCapOptions : [{id: 5, name: '5'}, {id: 10, name: '10'}],
				      // ckeditor configuration
      editorConfig: {
		//plugins: "emoji,basicstyles,list,undo,link,wysiwygarea,toolbar",
		height :302,
        toolbar: [["Bold", "NumberedList", "BulletedList", "EmojiPanel", "Undo", "Redo", "Paste"]],
        removeButtons: 'Italic',
		extraPlugins : 'emoji'
      }
            };
        },
        methods: {
		    OnDragEnter(e) {
      e.preventDefault();

      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onInputChange(e) {
      const files = e.target.files;
      Array.from(files).forEach(file => this.addImage(file));
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      Array.from(files).forEach(file => this.addImage(file));
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        this.$toastr.e(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const img = new Image(),
      reader = new FileReader();
      reader.onload = e => this.images.push(e.target.result);
      reader.readAsDataURL(file);
    },
            submit() {
                this.errors = {};
                const axios = require("axios");
                axios
                    .post("/dashboard/account/update-policy", this.fields)
                    .then(response => {
                        this.update = 1;
                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Your company Policy is updated',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
            },
            checkForm: function(e) {
                if (
                    //this.requestpayment &&
                    this.fields.fullnotice &&
                    this.fields.incompletenotice &&
                    this.fields.badleavers
                ) {
                    this.submit();
                }

                this.errors = [];

                if (!this.requestpayment) {
                    this.errors.push("Requested Payment Method not added");
                }
                if (!this.fields.fullnotice) {
                    this.errors.push("Plese fill full notice field");
                }
                if (!this.fields.badleavers) {
                    this.errors.push("Please fill bad leavers");
                }
                if (!this.fields.incompletenotice) {
                    this.errors.push("Please fill incomplete Notice Value");
                }
                if (!this.fields.requestpaymentpoints) {
                    this.errors.push("Please fill requested Payment Points");
                }
                if (!this.fields.prevref) {
                    this.errors.push("Please fill Previous Points");
                }

                e.preventDefault();
            },

            onReady() {
                this.playerReady = true;
            },
            play() {
                this.$refs.player.play();
            },
            stop() {
                this.$refs.player.stop();
            },
            updateradio() {
                if (this.schemedata.fullnotice == "Yes") {
                    this.radio.one = true;
                    this.radio.two = false;
                    this.radio.three = false;
                } else if (this.schemedata.fullnotice == "No") {
                    this.radio.one = false;
                    this.radio.two = true;
                    this.radio.three = false;
                } else if (
                    this.schemedata.fullnotice == "At the Description of Directors"
                ) {
                    this.radio.one = false;
                    this.radio.two = false;
                    this.radio.three = true;
                }

                if (this.schemedata.incompletenotice == "Yes") {
                    this.radio.four = true;
                    this.radio.five = false;
                    this.radio.six = false;
                } else if (this.schemedata.incompletenotice == "No") {
                    this.radio.four = false;
                    this.radio.five = true;
                    this.radio.six = false;
                } else if (
                    this.schemedata.incompletenotice == "At the Description of Directors"
                ) {
                    this.radio.four = false;
                    this.radio.five = false;
                    this.radio.six = true;
                }

                if (this.schemedata.badleavers == "Yes") {
                    this.radio.seven = true;
                    this.radio.eight = false;
                    this.radio.nine = false;
                } else if (this.schemedata.badleavers == "No") {
                    this.radio.seven = false;
                    this.radio.eight = true;
                    this.radio.nine = false;
                } else if (
                    this.schemedata.badleavers == "At the Description of Directors"
                ) {
                    this.radio.seven = false;
                    this.radio.eight = false;
                    this.radio.nine = true;
                }
            },
            paymentmaxpointsfunc() {
                if (this.schemedata.payment_max_point == 0) {
                    this.paymaxpointsdisabled = true;
                    this.schemedata.payment_max_point = false;
                    this.schemedata.payment_none_of_above = true;
                    this.payment_none_of_above = true;
                } else {
                    this.paymaxpointsdisabled = false;
                }

            },
            requestpaymentOptions: function(event){

                if(event.target.checked){
                    this.requestpayment = true
                    if(event.target.name == 'payment_none_of_above'){
                        this.payment_none_of_above = true
                        this.fields.payment_eof_calendar_year = false
                        this.fields.payment_eof_financial_year = false
                        this.fields.payment_eof_quarter = false
                        this.fields.payment_max_point = false
                        this.paymaxpointsdisabled = true
                    } else {
                        this.$set(this.fields, event.target.name, true)
                    }
                    if(event.target.name == 'payment_max_point'){
                        this.paymaxpointsdisabled = false;
                    }
                } else {

                    this.requestpayment = false
                    if(event.target.name == 'payment_max_point'){
                        this.paymaxpointsdisabled = true;
                    }
                    if(event.target.name == 'payment_none_of_above'){
                        this.payment_none_of_above = false;
                    } else {
                        this.$set(this.fields, event.target.name, false)
                    }
                    // Check if any other option is already checked
                    if(this.payment_eof_calendar_year){
                        this.requestpayment = true
                    }
                    if(this.fields.payment_eof_calendar_year){
                        this.requestpayment = true
                    }
                    if(this.fields.payment_eof_financial_year){
                        this.requestpayment = true
                    }
                    if(this.fields.payment_eof_calendar_year){
                        this.requestpayment = true
                    }
                    if(this.fields.payment_eof_quarter){
                        this.requestpayment = true
                    }
                    if(this.fields.payment_max_point){
                        this.requestpayment = true
                    }
                }
            },
            checkButtonState: function(){
                if(this.fields.payment_eof_calendar_year){
                    this.requestpayment = true
                }
                if(this.fields.payment_eof_financial_year){
                    this.requestpayment = true
                }
                if(this.fields.payment_eof_calendar_year){
                    this.requestpayment = true
                }
                if(this.fields.payment_eof_quarter){
                    this.requestpayment = true
                }
                if(this.fields.payment_max_point){
                    this.requestpayment = true
                }
            },
			reset() {
				this.custom_scheme = this.scheme_page.custom_scheme ? this.scheme_page.custom_scheme : '';
			},
		    addCustomeScheme(){
                var self = this;
                Vue.swal({
                    title: "Saving...",
                    html: "",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Vue.swal.showLoading();
                    }
                });
				var content = self.custom_scheme;
				//var param = "custom_scheme="+content;
				const formData = new FormData();
              formData.append("custom_scheme", self.custom_scheme);
                axios.post('/dashboard/account/add-custom-scheme', formData)
                    .then(function (response) {

                        self.unsaved=response.data.custom_scheme;
                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> added!',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });

                        $("body").removeClass("modal-open");

                    })
                    .catch(function (error) {
                        // handle error
                        Vue.swal({
                            type: 'error',
                            text: error.response.data,
                        });
                        console.log(error);
                    })
            }
        },

        mounted() {
            this.updateradio();
            this.paymentmaxpointsfunc();
            this.checkButtonState();
            if (this.payment_none_of_above) {
                this.requestpayment = true;
            }
        },
        computed: {
		   newbtnDisbaled() {
                if(this.custom_scheme && this.custom_scheme){
                    return false;
                }else return true;
            },
            btnDisabled() {
                if (
                   // this.requestpayment &&
                    this.fields.fullnotice &&
                    this.fields.incompletenotice &&
                    this.fields.badleavers &&
                    this.fields.incompletenotice &&
                    //this.fields.requestpaymentpoints &&
                    this.fields.prevref
                ) {
                    return false;
                } else return true;
            }
        }
    }
</script>
