<template>
    <div>
        <div class="grid-x grid-padding-x">
            <div class="cell auto font_0875">
                <p>To remove points from an app user's account, start by searching for them in the box below.</p>
                <p class="nb">NB - points should only be removed if they have been awarded in error or for candidates/shares that are ineligible according to your scheme rules.</p>
                <p class="nb">This function is not designed to be used to remove points for any other reason.</p>
            </div>
        </div>
        <div class="grid-x grid-padding-x">
            <div class="cell medium-7 font_0875">
                <label class="step-heading">Step 1: Select the app user you wish to remove points from</label>
                <v-select placeholder="Search for app user" v-model="user_id" :reduce="referrer => referrer.id" label="name"
                          :options="availableUsers"></v-select>
                <input type="hidden" name="user_id" :value="user_id">
            </div>
        </div>
        <br>
        <div v-if="isShow == 1 && user_id" class="grid-x grid-padding-x">
            <div class="cell auto font_0875">
                <label class="step-heading">Step 2: Select the points you wish to remove, from the list of point awarded</label>
                <div class="grid-x grid-padding-x align-middle">
                    <table id="activity-table" class="cf-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Reason</th>
                            <th>Points</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value,index) in viewPointsData">
                            <td align="center"><input type="radio" :name="'radio-' + index" :value="value.id" v-model="type_id"></td>
                            <td v-if="value.new_to_care != 1 && value.candidatesProgress == 1">{{(value.updated_at)?value.updated_at.split(' ')[0]:'Not set'}}</td>
                            <td v-if="value.new_to_care != 1 && value.candidatesProgress == 1">Referral</td>
                            <td v-if="value.new_to_care != 1 && value.candidatesProgress == 1"><div v-if="value.stage =='applied'">Expression of Interest</div><div v-else>
                                {{value.stage | capitalize}}</div></td>
                            <td v-if="value.new_to_care != 1 && value.candidatesProgress == 1">+{{value.points}}</td>
                            <td v-if="value.new_to_care == 1 && value.stage == 'applied'">{{(value.updated_at)?value.updated_at.split(' ')[0]:'Not set'}}</td>
                            <td v-if="value.new_to_care == 1 && value.stage == 'applied'">Referral</td>
                            <td v-if="value.new_to_care == 1 && value.stage == 'applied'"><div v-if="value.stage =='applied'">Expression of Interest</div><div v-else>
                                {{value.stage | capitalize}}</div></td>
                            <td v-if="value.new_to_care == 1 && value.stage == 'applied'">+{{value.points}}</td>
                            <td v-if="value.new_to_care == 1 && value.stage == 'Started work'">{{(value.updated_at)?value.updated_at.split(' ')[0]:'Not set'}}</td>
                            <td v-if="value.new_to_care == 1 && value.stage == 'Started work'">Referral</td>
                            <td v-if="value.new_to_care == 1 && value.stage == 'Started work'">Started work & New to care</td>
                            <td v-if="value.new_to_care == 1 && value.stage == 'Started work'">+{{value.points}}</td>
                            <td v-if="value.share == 1">{{(value.updated_at)?value.updated_at.split(' ')[0]:'Not set'}}</td>
                            <td v-if="value.share == 1">Referral</td>
                            <td v-if="value.share == 1">Share</td>
                            <td v-if="value.share == 1">+{{value.points}}</td>
                            <td v-if="value.bonus == 1">{{(value.updated_at)?value.updated_at.split(' ')[0]:'Not set'}}</td>
                            <td v-if="value.bonus == 1">Bonus</td>
                            <td v-if="value.bonus == 1">{{ value.reward_catagory_name }} : {{ getPostBody(value.event_data.reason_for_allocation) }} : {{ value.allocated_by }}</td>
                            <td v-if="value.bonus == 1">+{{value.points}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="reason_id == 5 && otherReason" class="cell medium-4 font_0875 btn-float">
            <button type="button" class="button pink_btn b_margin_0 no_wrap_btn" @click="confirmRemove(type_id, reason_id)">Confirm</button>
        </div>
        <div v-else-if="reason_id >= 1 && reason_id <= 4 && type_id && isShow == 1 && user_id" class="cell medium-4 font_0875 btn-float">
            <button type="button" class="button pink_btn b_margin_0 no_wrap_btn" @click="confirmRemove(type_id, reason_id)">Confirm</button>
        </div>
        <br>
        <div v-if="type_id && isShow == 1 && user_id" class="grid-x grid-padding-x">
            <div class="cell medium-7 font_0875">
                <label class="step-heading">Step 3: Select the reason for the points removal from the dropdown</label>
                <v-select placeholder="Select reason" v-model="reason_id" :reduce="referrer => referrer.id" label="reason"
                          :options="reasons" class="centered"></v-select>
                <input type="hidden" name="reason_id" :value="reason_id">
                <br />
            </div>
            <div v-if="reason_id == 5" class="cell medium-6 font_0875">
                <label><strong>Reason</strong>
                    <textarea name="otherReason" v-model="otherReason"></textarea>
                </label>
                <br />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['siteId'],
        data() {
            return {
                users: [],
                availableUsers: [],
                viewPointsData: [],
                type_id: '',
                user_id: '',
                reason_id: '',
                reasons: [{id: 1, reason: 'Duplicate candidate'}, {id: 2, reason: 'Bonus points awarded in error'},
                    {id: 3, reason: 'Ineligible candidate'}, {id: 4, reason: 'Ineligible share'}, {id: 5, reason: 'Other'}],
                isShow: 0,
                otherReason: '',
            }
        },
        watch: {
            user_id: function (val) {
                this.$emit('selectedUser', this.user_id);
                if(this.user_id) {
                    this.getLog(this.user_id);
                }
            },
            reason_id: function (val) {
                this.$emit('selectedReason', this.reason_id);
            }
        },
        mounted() {
            this.getAvailableUsers();
        },
        methods: {
            getAvailableUsers() {
                var self = this;
                axios.get('/dashboard/notifications/get-users?siteId=' + self.siteId)
                    .then(function (response) {
                        // handle success
                        self.users = response.data;

                        //Create array of available users for dropdown
                        var toProcess = Object.keys(self.users);
						//console.log("length "+toProcess.length);
						//console.log("aaa "+toProcess);
                        for (var i = toProcess.length - 1; i >= 0; i--) {
                            var user = {
                                name: self.users[toProcess[i]].first_name + " " + self.users[toProcess[i]].surname + " (" + self.users[toProcess[i]].email + ")",
                                id: self.users[toProcess[i]].id
                            }
                            self.availableUsers.push(user);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
            },
            getLog(id) {
                var self = this;
                axios.get('/dashboard/points/get-activity-log/' + id)
                    .then(function (response) {
                        // handle success
                        self.viewPointsData = response.data.activityLog;
                        self.isShow = 1;
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
            },
            confirmRemove(typeId, reason) {
                if (reason == 5) {
                    reason = this.otherReason;
                }
                this.$swal({
                    title: 'Are you sure?',
                    text: "This will cause the app user to receive a notification that the points have been removed and " +
                        "the points will be removed from their total. This action can not be undone.",
                    showCancelButton: true,
                    confirmButtonColor: '#f55d73',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true,
                    customClass: {
                        container:'swal-before-leaving remove-points',
                        confirmButton: 'button pink_btn b_margin_0 no_wrap_btn',
                        cancelButton: 'button orange_btn b_margin_0 no_wrap_btn'
                    },
                }).then((result) => {
                    if (result.value) {
                        if (typeId) {
                            var data = null;
                            var self = this;
                            axios.post('/dashboard/points/delete-points/' + typeId, {
                                reason: reason
                            }).then(function (response) {
                                // handle success
								self.type_id=null;
                                if (response.data.id) {
                                    data = response.data.id;
                                    self.getLog(data);
									self.isShow=0;
                                }
								if(data) {
                                        Vue.swal({
                                            html:
                                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Points removed',
                                            confirmButtonColor: "#5dc2a6",
                                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                            background: "#5dc2a6",
                                            customClass: {
                                                container: "candidate-toast"
                                            },
                                            showClass: {
                                                popup: "animated fadeInLeft fast",
                                                icon: "animated heartBeat delay-1s"
                                            },
                                            hideClass: {
                                                popup: "animated fadeOutLeft slow"
                                            },
                                            toast: true,
                                            timer: 5000,
                                            position: "top-right",
                                            target: "#example-tabs"
                                        });
                                    }
                            })
                                .catch(function (error) {
                                    // handle error
                                    console.log(error);
                                })
                                /*.finally(function () {
                                    if(data) {
                                        Vue.swal({
                                            html:
                                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Points removed',
                                            confirmButtonColor: "#5dc2a6",
                                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                            background: "#5dc2a6",
                                            customClass: {
                                                container: "candidate-toast"
                                            },
                                            showClass: {
                                                popup: "animated fadeInLeft fast",
                                                icon: "animated heartBeat delay-1s"
                                            },
                                            hideClass: {
                                                popup: "animated fadeOutLeft slow"
                                            },
                                            toast: true,
                                            timer: 5000,
                                            position: "top-right",
                                            target: "#example-tabs"
                                        });
                                    }
                                })*/
                        }
                    }
                })
            },
			getPostBody (post) {
		        let body = this.stripTags(post);
		        return body.length > 15 ? body.substring(0, 15) + '...' : body;
	        },
	        stripTags (markup) {
				const temp = document.createElement("div");
				temp.innerHTML = markup;
				return temp.textContent || temp.innerText || "";
			},
        }
    }
</script>
