<template>
  <div class="view-points-user">
    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto name-id-points">
        <h2 align="left">
          <strong>Bonus Point: {{ userinfo.first_name }} {{ userinfo.surname }}</strong>
        </h2>
        <span class="id-profileCard"> ID: {{ userinfo.id }} </span>
      </div>
    </div>
    <br />
    <br>
   
    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto">
        <label>Phone </label>
		<p v-if="userinfo.mobile_number">{{ userinfo.mobile_number }}</p>
		<p v-else>
		<p class="user-clickable" v-if="userinfo.first_name === 'Anonymised' || userinfo.status=='Leaver' || userinfo.mark_as_leaver ==1"></p>
        <p v-else>{{ userinfo.phone_number }}</p>
		</p>
      </div>

      <div class="cell auto">
        <label>Email</label>
        <p class="user-clickable" v-if="userinfo.first_name === 'Anonymised' || userinfo.status=='Leaver' || userinfo.mark_as_leaver ==1"></p>
        <p class="user-clickable" v-else>{{ userinfo.email }}</p>
      </div>
    </div>
	
	<div class="grid-x grid-padding-x align-middle">
      <div class="cell auto">
        <label>Site </label>
        <p>{{ userinfo.site_name }}</p>
      </div>

      <div class="cell auto">
        <label>{{ taxName }}</label>
        <p class="user-clickable">{{ userinfo.PaymentInfo }}</p>
      </div>
    </div>

    

    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto">
        <label> Status </label>
        <p v-if="userinfo.status">{{ userinfo.status }}</p>
        <p v-else>{{ userinfo.active ? "Active" : "Deactivated" }}</p>
      </div>

     
    </div>
    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto">
        <h3>Total Points: {{ total_points }} </h3>
      </div>
      <div class="cell auto"></div>
	  
    </div>

 
    <div class="grid-x grid-padding-x align-middle">
	    
		<table id="activity-table" class="cf-table cell auto">
        <thead>
          <tr>
            <th width="130px">Date</th>
            <th>Awarded to</th>
            <th>Amount</th>
            <th class="align-right">Category</th>
            <th class="align-right">Awarded by</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="pointValues" v-for="(transaction, index) in pointValues">
            <td>
             {{ transaction.created_at| moment("DD/MM/YYYY") }}
            </td>
            <td >
              {{ transaction.allocated_to }}
            </td>
            <td>
              {{ transaction.event_data.points_no }}
            </td>
            <td>
              {{ transaction.reward_catagory_name }}
            </td>
            <td>
              {{ transaction.allocated_by }}
            </td>
          </tr>
        </tbody>
      </table>


    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
  components: { paginate: Paginate },
  props: ["user", "taxName"],
  data() {
    return {
      total_points: 0,
      userinfo: [],
      pointValues: [],
      usero:this.user,
    };
  },
  methods: {

    viewPoints(user,award_type) {
      var self = this;
      axios.get('/dashboard/points/bonus/filter', {  params:{
  							'allcat_to': user, 'award_type': award_type,
                        }}).then((response) => {
          if (response.data) {
          
            this.pointValues=response.data.bonusTransactions;
			this.total_points = response.data.total_points;
            this.userinfo= response.data.user_info;
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      self.editMode = true;
      if (user.created_at) {
        self.dateReg = user.created_at.split(" ");
      }
    },

	 getPostBody (post) {
                let body = this.stripTags(post);
                return body.length > 15 ? body.substring(0, 15) + '...' : body;
            },
			stripTags (markup) {
				const temp = document.createElement("div");
				temp.innerHTML = markup;
				return temp.textContent || temp.innerText || "";
			},
  },
  
  mounted() {
    //this.getUserInfo(this.user.event_data.user_id);
    this.viewPoints(this.user.event_data.user_id,this.user.account_points_uuid );
   console.log("ProfileData: "+this.user.award_type+" <br> "+this.user.account_points_uuid);
  },
  computed: {},
};
</script>
