<template>
  <div>
    <div v-if="!currentuser.site_id && currentuser.account_check == 1"
         class="grid-x grid-padding-x"
         style="margin-top: -68px; position: relative; float: right">
      <div class="cell auto">
        <button v-b-modal.addSite
                class="button pink_btn b_margin_0 no_wrap_btn job-add"
                style="float: right"
                @click="resetForm()">
          Add Site
        </button>
      </div>
    </div>
    <b-modal id="addSite"
             size="lg"
             :hide-footer="true"
             :no-close-on-backdrop="true">
      <h3>Add a site</h3>
      <div class="grid-x input-box-2">
        <div class="cell auto input-box">
          <label>Site name*
            <input type="text"
                   class=""
                   v-model="site.name"
                   required />
          </label>
        </div>
        <div class="cell auto input-box">
          <label>Number of staff at site
            <input type="text"
                   class=""
                   v-model="site.total_staff"
                   required />
          </label>
        </div>
      </div>
      <div class="cell auto input-box">
        <label>Address line 1
          <input type="text"
                 class=""
                 v-model="site.address"
                 required />
        </label>
      </div>
      <div class="grid-x input-box-2">
        <div class="cell auto input-box">
          <label>Town/City
            <input type="text"
                   class=""
                   v-model="site.city"
                   required />
          </label>
        </div>
        <div class="cell auto input-box">
          <label>Postcode*
            <span v-if="currentuser.customer.google_distance_matrix_feature"
                  class="IconInfoRoundIdle"
                  v-b-tooltip.hover
                  title="This postcode will be used to calculate travel distance and times for candidates when they express interest.">
              <img src="/images/icon-info-round-idle@2x.png"
                   alt="img" />
            </span>
            <input type="text"
                   class=""
                   v-model="site.postcode"
                   required />
          </label>
        </div>
      </div>
      <div class="cell auto input-box">
        <label>Careers website (if different to whole organisations)
          <input type="text"
                 v-model="site.website" />
        </label>
      </div>
      <div v-if="currentuser.customer.custom_logo"
           class="cell auto input-box">
        <div class="input-box"
             style="margin-bottom: 0px !important">
          <label>Logo</label>
          <div v-if="images.length > 0"
               class="orgination-logo-box"
               style="
              background: #ffffff;
              background-position: center;
              background-size: cover;
            "
               :style="{ backgroundImage: `url(${images[images.length - 1]})` }"></div>
          <div v-if="images.length == 0"
               class="orgination-logo-box">
            <img src="/images/icon-24-no-logo.png"
                 style="padding-top: 24px; padding-left: 24px" />
          </div>
          <p class="btn-upload-logo"
             v-if="images.length > 0">
            <input data-button-text="browse"
                   type="file"
                   style="display: none"
                   id="file"
                   ref="fileInputs"
                   @change="onInputChange"
                   value="Browse" />

            <button href="javascript:void(0)"
                    @click="$refs.fileInputs.click()"
                    id="uploadTrigger"
                    class="btn btn-orange btn-lg">
              CHOOSE FILE
            </button>
          </p>
          <p class="btn-upload-pic"
             v-else>
            <input data-button-text="browse"
                   type="file"
                   style="display: none"
                   id="file"
                   ref="fileInputs"
                   @change="onInputChange"
                   value="Browse" />
            <button href="javascript:void(0)"
                    @click="$refs.fileInputs.click()"
                    id="uploadTrigger"
                    class="btn btn-orange btn-lg">
              UPLOAD NEW IMAGE
            </button>
          </p>

          <div v-if="deletebtnshow">
            <div class="delete_icon"
                 v-on:click="removeImg()">
              <i aria-hidden="true"
                 class="far fa-trash-alt"></i>
            </div>
          </div>
          <p class="org-logo-text">JPEG or PNG. Square format e.g. 300x300px</p>
        </div>
      </div>
      <h5>Recruiter responsible</h5>
      <div class="grid-x">
        <div class="cell auto input-box">
          <label>Portal User*</label>
          <v-select v-model="site.user_id"
                    :reduce="(referrer) => referrer.id"
                    label="name"
                    :options="availableUsers"
                    :close-on-select="true"></v-select>
        </div>
      </div>
      <!--<div class="grid-x input-box-2">
                <div class="cell auto input-box">
                    <label>Phone number
                       <input
                      type="text"
                      class =""
                      v-model="site.phone"

                      required
                    />

                    </label>
                </div>
                <div class="cell auto input-box">
                    <label>Email
                     <input
                      type="text"
                      class =""
                      v-model="site.email"

                      required
                    />

                    </label>
                </div>
            </div>-->
      <div class="cell auto popup-button">
        <input type="button"
               class="button btn_lg btn orange_btn"
               data-dismiss="modal"
               aria-label="Close"
               value="Cancel"
               @click="$bvModal.hide('addSite')" />
        <input class="button btn_lg btn pink_btn"
               type="submit"
               value="Add"
               @click="addnewsite(site)"
               :disabled="newbtnDisbaled" />
      </div>
    </b-modal>
    <div v-show="showLoad"
         class="show-load-candidate">
      <img :src="myImage" />
    </div>
    <div class="grid-x"
         style="
        background: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      ">
      <div class="cell auto font_0875 no-padding">
        <div class="grid-x">
          <div class="cell small-6 pull-left"
               style="padding-top: 16px; padding-left: 12px">
            <input class="jobs-search"
                   placeholder="Search sites"
                   v-model="searchVar"
                   name="searchVar"
                   @keyup.delete="searchdelSitesData()"
                   @keyup="searchSitesData()" />
          </div>

          <div class="cell small-6 pull-right bonus-history-top"
               style="padding-top: 12px; padding-right: 12px">
            <div class="cell shrink">
              <a href="javascript:void(0)"
                 @click="filtertoggle()"
                 class="btn button orange_btn float-right bonus-filter-cls">
                FILTERS <img src="/images/filter-icon.png"
                     alt="img" /></a>
            </div>
            <div class="cell shrink">
              <a @click="exportSites"
                 href="javascript:void(0)"
                 class="btn button orange_btn float-right">
                EXPORT
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-x"
         style="background: #fff">
      <div v-show="showFilter">
        <div class="grid-x filters-wrappers"
             style="width: 400px">
          <div class="cell small-6 pull-left"
               style="padding-left: 15px">
            <div class="input-box">
              <label></label>
              <date-range-picker v-model="dateRange"
                                 id="date-range-filter"
                                 ref="dateRangeFilter"
                                 opens="right"
                                 class="phone-recruiter"
                                 @keydown.enter="addTag"
                                 @keydown.188="addTag"
                                 @select="addTag"
                                 @keydown.delete="removeLastTag">
                <!--    input slot (new slot syntax)-->
                <template #input="picker">
                  <label class="datelabelclass">Date</label></template>
              </date-range-picker>
            </div>
          </div>

          <div class="cell small-6">
            <div class="input-box rrsite">
              <v-select class="phone-recruiter"
                        id="sites-filter"
                        ref="sitesFilter"
                        @input="seletedsites"
                        v-model="sitesFilter"
                        :reduce="(referrer) => referrer.id"
                        label="name"
                        :options="availableSites"
                        :close-on-select="true"
                        multiple
                        taggable
                        placeholder="Site">
                <template slot="option"
                          slot-scope="option">
                  {{ option.name }}
                  <img style="float: right"
                       :src="option.image" />
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <!--for displaying tags -->
        <div style="padding-top: 4px; padding-bottom: 4px">
          <div class="grid-x"
               v-if="selected_date_tags != ''"
               style="padding-left: 13px">
            <div class="tag-input__tag">
              <span class="lablesize">{{ selected_date_tags }}</span>
              <span class="deselect-pad"><button class="deselect"
                        @click="removeDateTags()"></button></span>
            </div>
          </div>
          <div class="grid-x full-width-can"
               v-if="selected_sites_tags != ''"
               style="padding-left: 13px">
            <div class="tag-input__tag">
              <span class="lablesize">{{ selected_sites_tags }}</span>
              <span class="deselect-pad"><button class="deselect"
                        @click="removeSitesTags()"></button></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="sites.length == 0 && message != ''"
         class="empty-state cf-table">
      <img src="/images/icon-empty-state-locations.png" />
      <p>{{ message }}</p>
      <p>
        <button v-b-modal.addSite
                class="button pink_btn b_margin_0 no_wrap_btn"
                @click="resetForm()">
          Add Site
        </button>
      </p>
      <br />
    </div>

    <div style="margin-top: 0px"
         class="cf-table"
         v-else>
      <div class="
          grid-x grid-padding-x
          red_bg
          cf-table-head
          t_margin_1
          align-middle
        ">
        <draggable v-model="siteheaders"
                   tag="div"
                   class="makeinline"
                   draggable=".item"
                   @choose="startDrag"
                   @unchoose="onDropdrag"
                   :move="handleMove"
                   @end="handleDragEnd">
          <div align="center"
               v-for="header in siteheaders"
               :key="header"
               scope="col"
               :class="header + ' cell auto white item'"
               @click="sort(header)">
            <p class="tb_margin_1"
               v-if="header == 'name'"
               style="text-align: left">
              <strong>Site Name<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': name_sortasc,
                    'fa fa-sort-desc': name_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'total_staff'">
              <strong>Total staff<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': total_staff_sortasc,
                    'fa fa-sort-desc': total_staff_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'reg_user'">
              <strong>Registered users<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': reg_user_sortasc,
                    'fa fa-sort-desc': reg_user_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'per_uptake'">
              <strong>% Registered users<i v-bind:class="{
                    'fa fa-sort-asc': per_uptake_sortasc,
                    'fa fa-sort-desc': per_uptake_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'candidates'">
              <strong>Candidates<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': candidates_sortasc,
                    'fa fa-sort-desc': candidates_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'starters'">
              <strong>Starters<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': starters_sortasc,
                    'fa fa-sort-desc': starters_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'share'">
              <strong>share<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': share_sortasc,
                    'fa fa-sort-desc': share_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'successful_interviews'">
              <strong>Successful interviews<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': successful_interviews_sortasc,
                    'fa fa-sort-desc': successful_interviews_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'still_in_post_at_3_months'">
              <strong>Still in post at 3 months<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': still_sortasc,
                    'fa fa-sort-desc': still_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'points_awarded'">
              <strong>Points awarded<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': points_awarded_sortasc,
                    'fa fa-sort-desc': points_awarded_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'bonus_points'">
              <strong>Bonus points<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': bonus_points_sortasc,
                    'fa fa-sort-desc': bonus_points_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'referral_points'">
              <strong>Referral points<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': referral_points_sortasc,
                    'fa fa-sort-desc': referral_points_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'paid_out'">
              <strong>Points paid out<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': paid_out_sortasc,
                    'fa fa-sort-desc': paid_out_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'points_requested'">
              <strong>Points requested<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': points_requested_sortasc,
                    'fa fa-sort-desc': points_requested_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'new_to_care'">
              <strong>New To Care<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': new_to_care_sortasc,
                    'fa fa-sort-desc': new_to_care_sortdesc,
                  }"></i></strong>
            </p>
            <p class="tb_margin_1"
               v-if="header == 'active_users'">
              <strong>Active Users<i v-bind:class="{
                    'fa fa-sort': sortboth,
                    'fa fa-sort-asc': active_users_sortasc,
                    'fa fa-sort-desc': active_users_sortdesc,
                  }"></i></strong>
            </p>
            <!--<p style="border:1px solid red" class="tb_margin_1" v-if="header=='blank'"><strong></strong></p>-->
          </div>
          <!-- sites column setup option -->

          <div class="dropdown-action leftpopup">
            <div class="dropdown"
                 id="openleadoption"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 ref="dropdown"
                 aria-expanded="false"
                 style="position: relative">
              <img v-b-tooltip.left.hover="{ customClass: 'my-tooltip-classss' }"
                   title="Column setup"
                   src="/images/leaderbord-open-col.png"
                   class="leaderbord-open-col" />
            </div>
            <div data-alignment="left"
                 class="
                dropdown-menu
                pane-leftside
                dropdown-pane
                pane-width
                radius-3
                column-setup-mask
              "
                 aria-labelledby="openleadoption"
                 style="right: 0"
                 @click="onClick">
              <div class="Element-DropdownMenu-Header">
                <span class="Common-Countries">Column setup</span>
              </div>
              <div class="Rectangle"></div>

              <div class="column-outer-div">
                <span class="Menu-Item">Site Name</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="name"
                           v-model="name"
                           id="name"
                           disabled="disabled" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>

              <div class="column-outer-div">
                <span class="Menu-Item">Total staff reported</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="total_staff"
                           v-model="total_staff"
                           id="total_staff"
                           @click="manageHeader('total_staff')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Registered users</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="reg_user"
                           v-model="reg_user"
                           id="reg_user"
                           @click="manageHeader('reg_user')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>

              <div class="column-outer-div">
                <span class="Menu-Item">% registered users</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="per_uptake"
                           v-model="per_uptake"
                           id="per_uptake"
                           @click="manageHeader('per_uptake')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Number candidates</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="candidates"
                           v-model="candidates"
                           id="candidates"
                           @click="manageHeader('candidates')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Number shares</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="share"
                           v-model="share"
                           id="share"
                           @click="manageHeader('share')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>

              <div class="column-outer-div">
                <span class="Menu-Item">Number successful interviews</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="successful_interviews"
                           v-model="successful_interviews"
                           id="successful_interviews"
                           @click="manageHeader('successful_interviews')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Number starters </span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="starters"
                           v-model="starters"
                           id="starters"
                           @click="manageHeader('starters')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Number still in post at 3 months</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="still_in_post_at_3_months"
                           v-model="still_in_post_at_3_months"
                           id="still_in_post_at_3_months"
                           @click="manageHeader('still_in_post_at_3_months')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Points awarded</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="points_awarded"
                           v-model="points_awarded"
                           id="points_awarded"
                           @click="manageHeader('points_awarded')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Bonus points awarded</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="bonus_points"
                           v-model="bonus_points"
                           id="bonus_points"
                           @click="manageHeader('bonus_points')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Referral points awarded</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="referral_points"
                           v-model="referral_points"
                           id="referral_points"
                           @click="manageHeader('referral_points')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Points paid out</span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="paid_out"
                           v-model="paid_out"
                           id="paid_out"
                           @click="manageHeader('paid_out')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>
              <div class="column-outer-div">
                <span class="Menu-Item">Points requested </span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="points_requested"
                           v-model="points_requested"
                           id="points_requested"
                           @click="manageHeader('points_requested')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>

              <div class="column-outer-div">
                <span class="Menu-Item">New to care </span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="new_to_care"
                           v-model="new_to_care"
                           id="new_to_care"
                           @click="manageHeader('new_to_care')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>

              <div class="column-outer-div">
                <span class="Menu-Item">Active Users </span>
                <span class="rightsidecheckbox">
                  <label class="points-check">
                    <input type="checkbox"
                           name="active_users"
                           v-model="active_users"
                           id="active_users"
                           @click="manageHeader('active_users')" />
                    <span class="mark"></span>
                  </label>
                </span>
              </div>


            </div>
          </div>
          <!-- End site column setup options -->
        </draggable>
      </div>

      <div v-for="site in userdata"
           :key="site.name"
           class="maindivsite">
        <div class="grid-x grid-padding-x align-middle b_border_b1">
          <div :class="header + ' cell auto'"
               v-for="header in siteheaders"
               :key="header">
            <div v-if="header == 'name'">
              <p class="tb_margin_1"
                 style="text-align: left; word-wrap: break-word">
                {{ site.name }}
              </p>
            </div>
            <div v-else-if="header == 'total_staff'">
              <p class="tb_margin_1"
                 v-if="site.total_staff">
                {{ site.total_staff }}
              </p>
              <p class="tb_margin_1"
                 v-else>0</p>
            </div>
            <div v-else-if="header == 'reg_user'">
              <p class="tb_margin_1">{{ site.totaluser }}</p>
            </div>
            <div v-else-if="header == 'per_uptake'">
              <p class="tb_margin_1"
                 v-if="site.total_staff > 0">
                {{ site.per_uptake }}
              </p>
              <p class="tb_margin_1"
                 v-else>0</p>
            </div>
            <div v-else-if="header == 'candidates'">
              <p class="tb_margin_1">
                {{ site.candidates }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'starters'">
              <p class="tb_margin_1">
                {{ site.starters }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'share'">
              <p class="tb_margin_1">
                {{ site.share }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'successful_interviews'">
              <p class="tb_margin_1">
                {{ site.successful_interviews }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'still_in_post_at_3_months'">
              <p class="tb_margin_1">
                {{ site.still_in_post_at_3_months }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'points_awarded'">
              <p class="tb_margin_1">
                {{ site.points_awarded }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'bonus_points'">
              <p class="tb_margin_1">
                {{ site.bonus_points }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'referral_points'">
              <p class="tb_margin_1">
                {{ site.referral_points }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'paid_out'">
              <p class="tb_margin_1">
                {{ site.paid_out }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'points_requested'">
              <p class="tb_margin_1">
                {{ site.points_requested }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'new_to_care'">
              <p class="tb_margin_1">
                {{ site.new_to_care }}
              </p>
            </div>
            <div class="tb_margin_1"
                 v-else-if="header == 'active_users'">
              <p class="tb_margin_1">
                {{ site.active_users }}
              </p>
            </div>


            <div v-if="
                header == 'blank' &&
                site.name != 'None' &&
                site.name != 'Multiple allocated sites' &&
                currentuser.account_check == 1
              "
                 class="cell auto large-1 dropdown-action"
                 align="center">
              <button class="dropdown button"
                      :id="'d-' + site.site_id"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"></button>
              <div data-alignment="right"
                   class="
                  dropdown-menu
                  pane-leftside
                  dropdown-pane
                  pane-width
                  radius-3
                "
                   :aria-labelledby="'d-' + site.site_id">
                <ul>
                  <li v-b-modal="'site-' + site.site_id">
                    <a href="javascript:void(0)"
                       @click="updatesites(site)">View/edit contact details</a>
                  </li>
                  <li v-b-modal="'delete-' + site.site_id">
                    <a @click="deleteCheck = true">Delete</a>
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="editsites && header == 'blank'"
                 class="editsites">
              <b-modal :id="'site-' + site.site_id"
                       :hide-footer="true"
                       :no-close-on-backdrop="true">
                <div id="editsitemainblock"
                     class="editsitemainblock">
                  <h3>View/Edit Site Contact Details</h3>

                  <input type="hidden"
                         name="id"
                         v-model="site.id" />

                  <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                      <label>Site name*
                        <input type="text"
                               v-model="site.newname"
                               required />
                      </label>
                    </div>
                    <div class="cell auto input-box">
                      <label>Number of staff at site
                        <input type="text"
                               class=""
                               v-model="site.total_staff"
                               required />
                      </label>
                    </div>
                  </div>
                  <div class="cell auto input-box">
                    <label>Address line 1
                      <input type="text"
                             class=""
                             v-model="site.address"
                             required />
                    </label>
                  </div>
                  <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                      <label>Town/City
                        <input type="text"
                               class=""
                               v-model="site.city"
                               required />
                      </label>
                    </div>
                    <div class="cell auto input-box">
                      <label>Postcode*
                        <span v-if="currentuser.customer.google_distance_matrix_feature"
                              class="IconInfoRoundIdle"
                              v-b-tooltip.hover
                              title="This postcode will be used to calculate travel distance and times for candidates when they express interest.">
                          <img src="/images/icon-info-round-idle@2x.png"
                               alt="img" />
                        </span>
                        <input type="text"
                               class=""
                               v-model="site.postcode"
                               required />
                      </label>
                    </div>
                  </div>
                  <div class="cell auto input-box">
                    <label>Careers website (if different to whole organisations)
                      <input type="text"
                             v-model="site.website" />
                    </label>
                  </div>
                  <div v-if="currentuser.customer.custom_logo"
                       class="cell auto input-box"
                       style="margin-bottom: 0px !important">
                    <div class="input-box"
                         style="margin-bottom: 12px !important">
                      <label>Logo</label>

                      <div class="orgination-logo-box"
                           v-if="avatorshow == 2"
                           style="
                          background: #ffffff;
                          background-position: center;
                          background-size: cover;
                        "
                           :style="{
                          backgroundImage: `url(/storage/${site.logo})`,
                        }"></div>

                      <div v-if="avatorshow == 1"
                           class="orgination-logo-box"
                           style="
                          background: #ffffff;
                          background-position: center;
                          background-size: cover;
                        "
                           :style="{
                          backgroundImage: `url(${images[images.length - 1]})`,
                        }"></div>

                      <div v-if="avatorshow == 3"
                           class="orgination-logo-box">
                        <img src="/images/icon-24-no-logo.png"
                             style="padding-top: 24px; padding-left: 24px" />
                      </div>

                      <p class="btn-upload-logo"
                         v-if="avatorshow == 3">
                        <input data-button-text="browse"
                               type="file"
                               style="display: none"
                               id="edit-file"
                               ref="fileInput"
                               @change="onInputChange"
                               value="Browse" />

                        <button href="javascript:void(0)"
                                @click="$refs.fileInput[0].click()"
                                id="uploadTrigger"
                                class="btn btn-orange btn-lg">
                          CHOOSE FILE
                        </button>
                      </p>
                      <p class="btn-upload-pic"
                         v-else>
                        <input data-button-text="browse"
                               type="file"
                               style="display: none"
                               id="edit-file"
                               ref="fileInput"
                               @change="onInputChange"
                               value="Browse" />
                        <button href="javascript:void(0)"
                                @click="$refs.fileInput[0].click()"
                                id="uploadTrigger"
                                class="btn btn-orange btn-lg">
                          UPLOAD NEW IMAGE
                        </button>
                      </p>

                      <div v-if="deletebtneditshow">
                        <div class="delete_icon"
                             v-on:click="removeEditimg(site.id)">
                          <i aria-hidden="true"
                             class="far fa-trash-alt"></i>
                        </div>
                      </div>
                      <p class="org-logo-text">
                        JPEG or PNG. Square format e.g. 300x300px
                      </p>
                    </div>
                  </div>
                  <h5>Recruiter responsible</h5>
                  <div class="grid-x">
                    <div class="cell auto input-box">
                      <label>Portal User*</label>
                      <v-select v-model="site.user_id"
                                :reduce="(referrer) => referrer.id"
                                label="name"
                                :options="availableUsers"
                                :close-on-select="true"></v-select>
                    </div>
                  </div>
                  <div class="cell auto">
                    <input class="button pink_btn no_wrap_btn"
                           style="float: right"
                           @click="editexistingsites(site)"
                           type="submit"
                           value="Update"
                           :disabled="newbtnDisbaled" />
                  </div>
                </div>
              </b-modal>
            </div>

            <b-modal v-if="deleteCheck && header == 'blank'"
                     class="tab-modal"
                     :id="'delete-' + site.site_id"
                     size="lg">
              <h3>Are you sure?</h3>
              <div class="grid-y">
                <div class="grid-x input-box-2">
                  <div class="cell auto input-box">
                    <p>
                      Deleting the site <strong>{{ site.name }}</strong> means
                      that any jobs, candidates, points, portal users and/or app
                      users allocated to this site will be re-allocated to 'all
                      sites' by default.
                    </p>
                    <p>This CANNOT be undone.</p>
                  </div>
                </div>
              </div>
              <div class="cell auto popup-button">
                <input class="delete-candidate"
                       type="submit"
                       value="Delete Site"
                       @click="deleteSite(site.site_id)" />
                <input type="button"
                       class="button btn_lg btn orange_btn delete-candidate anon-can"
                       value="Cancel"
                       @click="deleteCheck = false" />
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <template v-if="paginationData.last_page > 1">
      <paginate v-model="paginationData.current_page"
                :page-count="paginationData.last_page"
                :page-range="3"
                :margin-pages="2"
                :click-handler="getSites"
                :prev-text="'<'"
                :next-text="'>'"
                :prev-class="'paginate-controls'"
                :next-class="'paginate-controls'"
                :container-class="'pagination-custom'"
                :page-class="'page-item'"
                :active-class="'active'"></paginate>
    </template>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import draggable from "vuedraggable";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "axios";
import eventBus from '../../utils/event-bus.js'

export default {
  props: ["currentuser"],
  name: "table-column-example",
  display: "Table Column",
  order: 9,
  components: {
    paginate: Paginate,
    draggable: draggable,
    DateRangePicker: DateRangePicker,
    eventBus
  },
  data() {
    return {
      searchDebounce: 0,
      sites: [],
      availableSites: [],
      dropSites: [],
      sitesFilter: "",
      selected_sites_tags: "",
      editsites: false,
      editedSiteInfo: {},
      availableUsers: [],
      site: {
        id: "",
        customer_id: "",
        name: "",
        total_staff: "",
        address: "",
        city: "",
        postcode: "",
        phone: "",
        email: "",
        user_id: "",
        website: "",
        logo: "",
        remove: 0,
        newname: "",
      },
      deleteLogo: {
        site_id: 0,
        deleted: 0,
      },
      deleteCheck: false,
      paginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      users: [],
      siteId: "",
      userdata: [],
      dragCount: 0,
      runupdate: false,
      isDragging: false,
      images: [],
      files: [],
      imageName: "",
      message: "",
      query: false,
      showLoad: false,
      myImage: "/images/loading-circle.gif",
      searchVar: "",
      name: 1,
      total_staff: 1,
      reg_user: 1,
      per_uptake: 1,
      candidates: 1,
      starters: 1,
      share: 0,
      successful_interviews: 0,
      still_in_post_at_3_months: 0,
      points_awarded: 0,
      bonus_points: 0,
      referral_points: 0,
      paid_out: 0,
      points_requested: 0,
      new_to_care: 0,
      active_users: 0,
      siteheaders: [
        "name",
        "total_staff",
        "per_uptake",
        "candidates",
        "starters",
        "blank",
      ],
      dragging: false,
      tags: [],
      dateRange: {
        startDate: "",
        endDate: "",
      },
      showFilter: false,
      filterStartDate: "",
      filterEndDate: "",
      currentSort: "name",
      currentSortDir: "SORT_DESC",
      sortboth: false,
      nameActive: false,
      totalstaffActive: false,
      reguserActive: false,
      peruptakeActive: false,
      candidatesActive: false,
      startersActive: false,
      shareActive: false,
      successfulinterviewsActive: false,
      stillActive: false,
      pointsawardedActive: false,
      bonuspointsActive: false,
      referralpointsActive: false,
      paidoutActive: false,
      pointsrequestedActive: false,
      newtocareActive: false,
      name_sortasc: false,
      name_sortdesc: false,
      total_staff_sortasc: false,
      total_staff_sortdesc: false,
      reg_user_sortasc: false,
      reg_user_sortdesc: false,
      per_uptake_sortasc: false,
      per_uptake_sortdesc: false,
      candidates_sortasc: false,
      candidates_sortdesc: false,
      starters_sortasc: false,
      starters_sortdesc: false,
      share_sortasc: false,
      share_sortdesc: false,
      successful_interviews_sortasc: false,
      successful_interviews_sortdesc: false,
      still_sortasc: false,
      still_sortdesc: false,
      points_awarded_sortasc: false,
      points_awarded_sortdesc: false,
      bonus_points_sortasc: false,
      bonus_points_sortdesc: false,
      referral_points_sortasc: false,
      referral_points_sortdesc: false,
      paid_out_sortasc: false,
      paid_out_sortdesc: false,
      points_requested_sortasc: false,
      points_requested_sortdesc: false,
      new_to_care_sortasc: false,
      new_to_care_sortdesc: false,
      active_users_sortasc: false,
      active_users_sortdesc: false,
      ranges: {
        default() {
          let today = new Date();
          today.setHours(0, 0, 0, 0);

          let yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);

          let thisMonthStart = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          let thisMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );

          return {
            Today: [today, today],
            Yesterday: [yesterday, yesterday],
            "This week": [yesterday, yesterday],
            "Last week": [yesterday, yesterday],
            "This month": [thisMonthStart, thisMonthEnd],
            "This year": [
              new Date(today.getFullYear(), 0, 1),
              new Date(today.getFullYear(), 11, 31),
            ],
            "Last month": [
              new Date(today.getFullYear(), today.getMonth() - 1, 1),
              new Date(today.getFullYear(), today.getMonth(), 0),
            ],
          };
        },
      },
      selected_date_tags: "",
    };
  },
  methods: {
    exportSites() {
      axios.get("/dashboard/sites/get-sites-export", {
        params: {
          column: this.siteheaders,
          filterStartDate: this.filterStartDate,
          filterEndDate: this.filterEndDate,
          column_name: this.currentSort,
          sort: this.currentSortDir,
          name: this.searchVar,
          sitesFilter: this.sitesFilter
        }
      }).then(({ data }) => {
        let toastObj = {
          html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span>Export will be sent to your email once completed!',
          confirmButtonColor: "#5dc2a6",
          confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
          background: "#5dc2a6",
        };
        if (data.status !== "success") {
          const msg = data.message || "Export Failed";
          toastObj = {
            html: `<span class="success-circle"><i class="fas fa-check-circle"></i></span>${msg}`,
            confirmButtonColor: "#F84366",
            confirmButtonText:
              '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#F84366",
          };
        }
        Vue.swal({
          ...toastObj,
          customClass: {
            container: "candidate-toast",
          },
          showClass: {
            popup: "animated fadeInLeft fast",
            icon: "animated heartBeat delay-1s",
          },
          hideClass: {
            popup: "animated fadeOutLeft slow",
          },
          toast: true,
          timer: 5000,
          position: "top-right",
          target: "#example-tabs",
        });
      });
    },
    OnDragEnter(e) {
      e.preventDefault();
      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    onInputChange(e) {
      const files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    addImage(file) {
      this.imageName = file.name;
      if (!file.type.match("image.*")) {
        this.showErrorToast(`The image must be in either JPEG or PNG format.`);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          if (image.width <= 300 && image.height <= 300) {
            this.files.push(file);
            this.images.push(e.target.result);
            this.deleteLogo.deleted = 0;
            return true;
          } else {
            this.showErrorToast(`The image cannot be uploaded as it is over the maximum dimensions of 300 x 300 pixels. Please re-size the image and try again.`);
            return false;
          }
        };

        image.onerror = () => {
          this.showErrorToast(`Error loading ${file.name}`);
          return false;
        };
      };

      reader.onerror = () => {
        this.showErrorToast(`Error reading ${file.name}`);
        return false;
      };
    },
    showErrorToast(message) {
      Vue.swal({
        html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> ' + message,
        confirmButtonColor: "#ea2020",
        confirmButtonText:
          '<i class="fa fa-times" aria-hidden="true"></i>',
        background: "#ea2020",
        customClass: {
          container: "points-toast",
        },
        showClass: {
          popup: "animated fadeInLeft fast swal2-margin-top",
          icon: "animated heartBeat delay-1s",
        },
        hideClass: {
          popup: "animated fadeOutLeft slow swal2-margin-top",
        },
        toast: true,
        timer: 5000,
        position: "top-right",
        target: "#example-tabs",
      });
    },
    startDrag: function (evt) {
      var colname = "";
      /*colname = this.siteheaders[evt.oldIndex];
    console.log("STARTDRAG: "+this.siteheaders[evt.oldIndex]);
           $('.grid-x .'+colname).addClass('colhighlight');
         $('.makeinline .'+colname).addClass('sort-active');
         $('.maindivsite .'+colname).each(function(index, element){
            var parentHeight = 0;
          var parentHeight = $(this).height();
          if(parentHeight >60){
            var sct = (parentHeight-48)/2;
              return $('.maindivsite .'+colname+':eq('+index+')').css({'padding-top':sct+'px', 'padding-bottom':sct+'px' });
                     }
          }); */
      return false; // disable sort
    },
    onDropdrag: function (evt) {
      //alert("Drop");
      //console.log(this.siteheaders[evt.oldIndex]+"<br>DDDDD<br>");
      $(".grid-x ." + this.siteheaders[evt.oldIndex]).removeClass(
        "colhighlight"
      );
      $(".makeinline ." + this.siteheaders[evt.oldIndex]).removeClass(
        "sort-active"
      );
    },
    handleDragEnd() {
      this.futureItem = this.siteheaders[this.futureIndex];
      this.movingItem = this.siteheaders[this.movingIndex];
      const _items = Object.assign([], this.siteheaders);
      _items[this.futureIndex] = this.movingItem;
      _items[this.movingIndex] = this.futureItem;

      this.siteheaders = _items;
    },
    handleMove(e) {
      const { index, futureIndex } = e.draggedContext;
      this.movingIndex = index;
      this.futureIndex = futureIndex;
      var colname = "";
      colname = this.siteheaders[this.movingIndex];

      $(".grid-x ." + colname).addClass("colhighlight");
      $(".makeinline ." + colname).addClass("sort-active");
      $(".maindivsite .name").each(function (index, element) {
        var parentHeight = 0;
        var parentHeight = $(this).height();
        if (parentHeight > 60) {
          var sct = (parentHeight - 48) / 2;
          return $(".maindivsite ." + colname + ":eq(" + index + ")").css({
            "padding-top": sct + "px",
            "padding-bottom": sct + "px",
          });
        }
      });

      return false; // disable sort
    },
    removeImg() {
      this.images = [];
      this.files = [];
      this.imageName = "";
      this.site.remove = 1;
    },
    removeEditimg(id) {
      this.runupdate = true;
      Vue.swal({
        title: "Deleting...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      this.deleteLogo.deleted = 1;
      //   @todo url
      axios.post("/dashboard/site-logo-delete", { id: id }).then((response) => {
        Vue.swal({
          html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Logo deleted.',
          confirmButtonColor: "#5dc2a6",
          confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
          background: "#5dc2a6",
          customClass: {
            container: "company-toast",
          },
          showClass: {
            popup: "animated fadeInLeft fast",
            icon: "animated heartBeat delay-1s",
          },
          hideClass: {
            popup: "animated fadeOutLeft slow",
          },
          toast: true,
          timer: 5000,
          position: "top-right",
          target: "#example-tabs",
        });

        this.images = [];
        this.files = [];
        this.site.logo = null;
        this.imageName = "";
        this.avatorshow = 3;
      });
    },
    showMenu() {
      $(this.$el).foundation();
    },
    updatesites(editedSite) {
      this.resetForm();
      this.editedSiteInfo = JSON.parse(JSON.stringify(editedSite));
      this.site = editedSite;
      if (this.site.logo) {
        this.imageName = this.site.logo; 
      }
      this.site.newname = this.site.name;
      this.editsites = true;
    },
    addnewsite(site) {
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("name", site.name);
      formData.append("user_id", site.user_id);
      formData.append("total_staff", site.total_staff);
      formData.append("address", site.address);
      formData.append("city", site.city);
      formData.append("postcode", site.postcode);
      formData.append("website", site.website);
      this.files.forEach((file) => {
        formData.append("images[]", file, file.name);
      });
      var self = this;
      self.runupdate = true;
      Vue.swal({
        title: "Saving...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      axios
        .post("/dashboard/account/create-site", formData, config)
        .then(function (response) {
          // handle success
          self.runupdate = true;
          self.$root.$emit("updatedJobs");
          self.$bvModal.hide("addSite");

          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Site added!',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          Vue.swal({
            type: "error",
            text: error.response.data,
          });
          console.log(error);
        });
    },
    editexistingsites(site) {
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      
      const formData = new FormData();
      formData.append("id", site.site_id);
      formData.append("name", site.name);
      formData.append("name", site.newname);
      formData.append("user_id", site.user_id);
      formData.append("total_staff", site.total_staff);
      formData.append("address", site.address);
      formData.append("city", site.city);
      formData.append("postcode", site.postcode);
      formData.append("website", site.website || "");
      formData.append("remove", this.deleteLogo.deleted);
      this.files.forEach((file) => {
        formData.append("images[]", file, file.name);
      });
      var self = this;

      Vue.swal({
        title: "Updating...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      var self = this;
      axios
        .post("/dashboard/account/update-site-details", formData, config)
        .then(function (response) {
          // handle success
          self.runupdate = true;
          self.site.name = site.newname;

          self.$root.$emit("updatedJobs");
          self.$bvModal.hide("site-" + site.site_id);
          self.$root.$emit("updatedJobs");
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Site updated.',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          // handle error
        });
    },
    deleteSite(id) {
      Vue.swal({
        title: "Deleting...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      var self = this;
      self.runupdate = true;
      axios
        .post("/dashboard/account/site-delete", { id: id })
        .then(function (response) {
          self.$root.$emit("updatedJobs");
          self.deleteCheck = false;
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Site deleted',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          location.reload();
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    paginatorClickCallback: function (pageNum) {
      let extraParams = "?page=" + pageNum;
      if (this.siteId != "") {
        extraParams += "&siteId=" + this.siteId;
      }
      this.getSites(extraParams);
    },
    resetForm() {
      this.site = {
        id: "",
        customer_id: "",
        name: "",
        total_staff: "",
        address: "",
        city: "",
        postcode: "",
        phone: "",
        email: "",
        user_id: "",
        website: "",
        logo: "",
        remove: 0,
      };
      this.images = [];
      this.files = [];
      this.imageName = "";
    },
    searchSitesData: function () {
      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce);
      }
      this.searchDebounce = setTimeout(() => {
        if (this.searchVar.length > 2) {
          this.getSites();
        }
      }, 750);
    },
    searchdelSitesData: function () {
      this.getSites();
    },
    getSites: async function (pageNum, init) {
      var self = this;
      let extraParams = "?page=" + pageNum;
      let searchTerm = "";
      self.showLoad = true;
      if (self.searchVar.length > 0) {
        self.query = true;
        extraParams += "&name=" + this.searchVar;
      }
      if (self.filterStartDate.length > 0) {
        self.query = true;
        extraParams += "&filterStartDate=" + self.filterStartDate;
      }
      if (self.filterEndDate.length > 0) {
        self.query = true;
        extraParams += "&filterEndDate=" + self.filterEndDate;
      }

      if (self.currentSort) {
        extraParams += "&column_name=" + self.currentSort;
      }
      if (self.currentSortDir) {
        extraParams += "&sort=" + self.currentSortDir;
      }
      if (self.sitesFilter) {
        extraParams += "&sitesFilter=" + self.sitesFilter;
      }

      //self.sites =[];
      return axios
        .get("/dashboard/account/get-sites" + extraParams)
        .then(function (response) {
          self.showLoad = false;
          if (response.data.sites.data.length > 0) {
            self.sites = response.data.sites.data;
            $(self.$el).foundation();
            self.paginationData.current_page = response.data.sites.current_page;
            self.paginationData.from = response.data.sites.from;
            self.paginationData.last_page = response.data.sites.last_page;
            self.paginationData.per_page = response.data.sites.per_page;
            self.paginationData.to = response.data.sites.to;
            self.paginationData.total = response.data.sites.total;
            self.userdata = response.data.siteData;
            if (response.data.sites.data.length == 0) {
              self.message =
                "You haven't created any sites yet - let’s fix that!";
            }
          } else {
            self.sites = response.data.sites.data;
            if (self.query == true) {
              self.message = "No sites match your search.";
            } else {
              self.message =
                "You haven't created any sites yet - let’s fix that!";
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAvailableUsers: function () {
      for (var i = this.users.length - 1; i >= 0; i--) {
        var user = {
          name: this.users[i].first_name + " " + this.users[i].surname,
          id: this.users[i].id,
        };

        this.availableUsers.push(user);
      }
    },
    getSitesUsers: async function () {
      var self = this;
      return axios
        .get("/dashboard/account/get-sites-users")
        .then(function (response) {
          if (response.data.users) {
            self.siteId = response.data.siteId;
            self.users = response.data.users;
            if (self.users) {
              self.getAvailableUsers();
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    onClick() {
      $(".leftpopup").click(function (e) {
        e.stopPropagation();
      });
    },
    setSitesHeader() {
      var self = this;
      if (localStorage.getItem("sitesHeaders") == null) {
        var sitesHeaders = self.siteheaders;
        localStorage.setItem("sitesHeaders", JSON.stringify(sitesHeaders));
        self.siteheaders = JSON.parse(localStorage.getItem("sitesHeaders"));
      } else {
        self.siteheaders = JSON.parse(localStorage.getItem("sitesHeaders"));

        self.total_staff = 0;
        self.reg_user = 0;
        self.per_uptake = 0;
        self.candidates = 0;
        self.starters = 0;
        self.share = 0;
        self.successful_interviews = 0;
        self.still_in_post_at_3_months = 0;
        self.points_awarded = 0;
        self.bonus_points = 0;
        self.referral_points = 0;
        self.paid_out = 0;
        self.points_requested = 0;
        self.new_to_care = 0;
        self.active_users = 0;

        for (var i = 0; i < self.siteheaders.length; i++) {
          if (self.siteheaders[i] == "total_staff") {
            self.total_staff = 1;
          }
          if (self.siteheaders[i] == "reg_user") {
            self.reg_user = 1;
          }
          if (self.siteheaders[i] == "per_uptake") {
            self.per_uptake = 1;
          }
          if (self.siteheaders[i] == "candidates") {
            self.candidates = 1;
          }
          if (self.siteheaders[i] == "starters") {
            self.starters = 1;
          }
          if (self.siteheaders[i] == "share") {
            self.share = 1;
          }
          if (self.siteheaders[i] == "successful_interviews") {
            self.successful_interviews = 1;
          }
          if (self.siteheaders[i] == "still_in_post_at_3_months") {
            self.still_in_post_at_3_months = 1;
          }
          if (self.siteheaders[i] == "points_awarded") {
            self.points_awarded = 1;
          }
          if (self.siteheaders[i] == "bonus_points") {
            self.bonus_points = 1;
          }
          if (self.siteheaders[i] == "referral_points") {
            self.referral_points = 1;
          }
          if (self.siteheaders[i] == "paid_out") {
            self.paid_out = 1;
          }
          if (self.siteheaders[i] == "points_requested") {
            self.points_requested = 1;
          }
          if (self.siteheaders[i] == "new_to_care") {
            self.new_to_care = 1;
          }
          if (self.siteheaders[i] == "active_users") {
            self.active_users = 1;
          }
        }
      }
    },
    manageHeader(val) {
      var self = this;
      //for total_staff
      self.siteheaders = JSON.parse(localStorage.getItem("sitesHeaders"));
      if (val == "total_staff") {
        if (self.total_staff == true) {
          if (self.siteheaders.indexOf("total_staff") != "-1") {
            self.siteheaders.splice(self.siteheaders.indexOf("total_staff"), 1);
          }
        }
        if (self.total_staff == false) {
          if (self.siteheaders.indexOf("total_staff") == "-1") {
            self.siteheaders.push("total_staff");
          }
        }
      }
      //for reg_user
      if (val == "reg_user") {
        if (self.reg_user == true) {
          if (self.siteheaders.indexOf("reg_user") != "-1") {
            self.siteheaders.splice(self.siteheaders.indexOf("reg_user"), 1);
          }
        }
        if (self.reg_user == false) {
          if (self.siteheaders.indexOf("reg_user") == "-1") {
            self.siteheaders.push("reg_user");
          }
        }
      }
      //for per_uptake
      if (val == "per_uptake") {
        if (self.per_uptake == true) {
          if (self.siteheaders.indexOf("per_uptake") != "-1") {
            self.siteheaders.splice(self.siteheaders.indexOf("per_uptake"), 1);
          }
        }
        if (self.per_uptake == false) {
          if (self.siteheaders.indexOf("per_uptake") == "-1") {
            self.siteheaders.push("per_uptake");
          }
        }
      }
      //for candidates
      if (val == "candidates") {
        if (self.candidates == true) {
          if (self.siteheaders.indexOf("candidates") != "-1") {
            self.siteheaders.splice(self.siteheaders.indexOf("candidates"), 1);
          }
        }
        if (self.candidates == false) {
          if (self.siteheaders.indexOf("candidates") == "-1") {
            self.siteheaders.push("candidates");
          }
        }
      }
      //for starters
      if (val == "starters") {
        if (self.starters == true) {
          if (self.siteheaders.indexOf("starters") != "-1") {
            self.siteheaders.splice(self.siteheaders.indexOf("starters"), 1);
          }
        }
        if (self.starters == false) {
          if (self.siteheaders.indexOf("starters") == "-1") {
            self.siteheaders.push("starters");
          }
        }
      }
      //for share
      if (val == "share") {
        if (self.share == true) {
          if (self.siteheaders.indexOf("share") != "-1") {
            self.siteheaders.splice(self.siteheaders.indexOf("share"), 1);
          }
        }
        if (self.share == false) {
          if (self.siteheaders.indexOf("share") == "-1") {
            self.siteheaders.push("share");
          }
        }
      }
      //for successful_interviews
      if (val == "successful_interviews") {
        if (self.successful_interviews == true) {
          if (self.siteheaders.indexOf("successful_interviews") != "-1") {
            self.siteheaders.splice(
              self.siteheaders.indexOf("successful_interviews"),
              1
            );
          }
        }
        if (self.successful_interviews == false) {
          if (self.siteheaders.indexOf("successful_interviews") == "-1") {
            self.siteheaders.push("successful_interviews");
          }
        }
      }

      //for still_in_post_at_3_months
      if (val == "still_in_post_at_3_months") {
        if (self.still_in_post_at_3_months == true) {
          if (self.siteheaders.indexOf("still_in_post_at_3_months") != "-1") {
            self.siteheaders.splice(
              self.siteheaders.indexOf("still_in_post_at_3_months"),
              1
            );
          }
        }
        if (self.still_in_post_at_3_months == false) {
          if (self.siteheaders.indexOf("still_in_post_at_3_months") == "-1") {
            self.siteheaders.push("still_in_post_at_3_months");
          }
        }
      }

      //for points_awarded
      if (val == "points_awarded") {
        if (self.points_awarded == true) {
          if (self.siteheaders.indexOf("points_awarded") != "-1") {
            self.siteheaders.splice(
              self.siteheaders.indexOf("points_awarded"),
              1
            );
          }
        }
        if (self.points_awarded == false) {
          if (self.siteheaders.indexOf("points_awarded") == "-1") {
            self.siteheaders.push("points_awarded");
          }
        }
      }
      //for bonus_points
      if (val == "bonus_points") {
        if (self.bonus_points == true) {
          if (self.siteheaders.indexOf("bonus_points") != "-1") {
            self.siteheaders.splice(
              self.siteheaders.indexOf("bonus_points"),
              1
            );
          }
        }
        if (self.bonus_points == false) {
          if (self.siteheaders.indexOf("bonus_points") == "-1") {
            self.siteheaders.push("bonus_points");
          }
        }
      }
      //for referral_points
      if (val == "referral_points") {
        if (self.referral_points == true) {
          if (self.siteheaders.indexOf("referral_points") != "-1") {
            self.siteheaders.splice(
              self.siteheaders.indexOf("referral_points"),
              1
            );
          }
        }
        if (self.referral_points == false) {
          if (self.siteheaders.indexOf("referral_points") == "-1") {
            self.siteheaders.push("referral_points");
          }
        }
      }
      //for starters
      if (val == "paid_out") {
        if (self.paid_out == true) {
          if (self.siteheaders.indexOf("paid_out") != "-1") {
            self.siteheaders.splice(self.siteheaders.indexOf("paid_out"), 1);
          }
        }
        if (self.paid_out == false) {
          if (self.siteheaders.indexOf("paid_out") == "-1") {
            self.siteheaders.push("paid_out");
          }
        }
      }
      //for points_requested
      if (val == "points_requested") {
        if (self.points_requested == true) {
          if (self.siteheaders.indexOf("points_requested") != "-1") {
            self.siteheaders.splice(
              self.siteheaders.indexOf("points_requested"),
              1
            );
          }
        }
        if (self.points_requested == false) {
          if (self.siteheaders.indexOf("points_requested") == "-1") {
            self.siteheaders.push("points_requested");
          }
        }
      }
      //for new_to_care
      if (val == "new_to_care") {
        if (self.new_to_care == true) {
          if (self.siteheaders.indexOf("new_to_care") != "-1") {
            self.siteheaders.splice(
              self.siteheaders.indexOf("new_to_care"),
              1
            );
          }
        }
        if (self.new_to_care == false) {
          if (self.siteheaders.indexOf("new_to_care") == "-1") {
            self.siteheaders.push("new_to_care");
          }
        }
      }
      //for active_users
      if (val == "active_users") {
        if (self.active_users == true) {
          if (self.siteheaders.indexOf("active_users") != "-1") {
            self.siteheaders.splice(
              self.siteheaders.indexOf("active_users"),
              1
            );
          }
        }
        if (self.active_users == false) {
          if (self.siteheaders.indexOf("active_users") == "-1") {
            self.siteheaders.push("active_users");
          }
        }
      }


      if (self.siteheaders.indexOf("blank") != "-1") {
        self.siteheaders.splice(self.siteheaders.indexOf("blank"), 1);
        self.siteheaders.push("blank");
      }
      var sitesHeaders = self.siteheaders;
      localStorage.setItem("sitesHeaders", JSON.stringify(sitesHeaders));

      self.siteheaders = JSON.parse(localStorage.getItem("sitesHeaders"));
    },
    filtertoggle() {
      if (this.showFilter == true) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
    },
    removeDateTags: function () {
      this.removeTag(0);
      //this.selected_date_tags = '';
    },
    addTag(event) {
      //alert(event.endDate);
      var self = this;

      if (!self.siteheaders.includes('new_to_care')) {
        self.manageHeader('new_to_care');
        self.new_to_care = 1;
      }

      if (!self.siteheaders.includes('active_users')) {
        self.manageHeader('active_users');
        self.active_users = 1;
      }

      $(".cancelBtn").trigger("click");
      // alert(event.startDate.toLocaleDateString());
      var newstartDate = event.startDate.getDate();
      var newstartMonth = event.startDate.getMonth() + 1;
      var newstartYear = event.startDate.getFullYear();
      var FinalStartDate =
        newstartYear + "-" + newstartMonth + "-" + newstartDate;
      var newendDate = event.endDate.getDate();
      var newendMonth = event.endDate.getMonth() + 1;
      var newendYear = event.endDate.getFullYear();
      var FinalEndDate = newendYear + "-" + newendMonth + "-" + newendDate;

      var Difference_In_Days =
        (event.endDate - event.startDate) / (1000 * 3600 * 24);

      this.filterStartDate = FinalStartDate;
      this.filterEndDate = FinalEndDate;
      var d = new Date();
      var currentDate = d.getDate();
      var currentMonth = d.getMonth() + 1;
      var currentYear = d.getFullYear();
      // this method evaulate 'This Month' or 'Last Month' conditions.
      let monthSpan = this.evaluateMonthSpan(
        newstartMonth,
        Difference_In_Days,
        newstartDate,
        newstartYear
      );

      if (FinalStartDate == FinalEndDate) {
        var todayDate = currentYear + "-" + currentMonth + "-" + currentDate;
        var yesDate =
          currentYear + "-" + currentMonth + "-" + (currentDate - 1);

        if (FinalStartDate == todayDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Today  ");
        } else if (FinalStartDate == yesDate) {
          this.removeTag(this.tags.length - 1);
          this.tags.push("Yesterday ");
        } else {
          this.removeTag(this.tags.length - 1);
          this.tags.push(FinalStartDate + " to " + FinalEndDate);
        }
      } else if (Difference_In_Days > 1 && Difference_In_Days < 28) {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      } else if (
        currentMonth == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This Month ");
      } else if (
        currentMonth - 1 == newstartMonth &&
        Difference_In_Days > 28 &&
        monthSpan
      ) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("Last Month ");
      } else if (currentYear && Difference_In_Days == 365) {
        this.removeTag(this.tags.length - 1);
        this.tags.push("This year ");
      } else {
        this.removeTag(this.tags.length - 1);
        this.tags.push(FinalStartDate + " to " + FinalEndDate);
      }
      this.selected_date_tags = "Date: " + this.tags[0];
      this.filterStartDate = FinalStartDate;
      this.filterEndDate = FinalEndDate;
      // this.loadBonusPoints();
      /* var val = event.target.value.trim()
          if (val.length > 0) {
          this.tags.push(val)
          event.target.value = ''
          }*/
    },
    removeTag(index) {
      /*if (index === 0){
                    this.filterStartDate = '';
                    this.filterEndDate = '';
                }*/
      this.selected_date_tags = "";
      this.tags.splice(index, 1);
      this.getSites();
    },
    removeLastTag(event) {
      console.log("866 - removeLastTag: ", event.target);
      if (event.target.value.length === 0) {
        this.filterStartDate = "";
        this.filterEndDate = "";
        this.removeTag(this.tags.length - 1);
      }
    },

    evaluateMonthSpan: function (
      newstartMonth,
      Difference_In_Days,
      newstartDate,
      newstartYear
    ) {
      // Transform below code to find total days in month.
      let monthTotalDays = [
        31, // Jan
        28, // Feb
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // Aug
        30, // Sep
        31, // Oct
        30, // Nov
        31, // Dec
      ];

      let monthIndex = newstartMonth - 1;
      let monthDays = monthTotalDays[monthIndex];
      let totalSelectedSpan = Difference_In_Days + 1; // catering previous dev coded result.

      // February Case
      if (newstartMonth == 2) {
        // When it is leap year like 2020, Feb would be off 29
        if (
          newstartYear % 4 == 0 &&
          totalSelectedSpan == 29 &&
          newstartDate == 1
        ) {
          return true;
        } else if (
          newstartYear % 4 != 0 &&
          totalSelectedSpan == 28 &&
          newstartDate == 1
        ) {
          return true;
        }
        return false;
      } else {
        // Non February Cases
        if (totalSelectedSpan == monthDays && newstartDate == 1) {
          return true;
        }
        return false;
      }
    },
    sort: function (s) {
      this.currentSort = s;
      if (s == "name") {
        this.nameActive = true;
        $(".makeinline .name").addClass("sort-active");
        /*$('.grid-x .name').addClass('colhighlight');
         $('.makeinline .name').addClass('sort-active');
         $('.maindivsite .name').each(function(index, element){
            var parentHeight = 0;
          var parentHeight = $(this).height();
          if(parentHeight >60){
            var sct = (parentHeight-48)/2;
              return $('.maindivsite .name:eq('+index+')').css({'padding-top':sct+'px', 'padding-bottom':sct+'px' });
                     }
          }); */
      } else {
        this.nameActive = false;
        $(".makeinline .name").removeClass("sort-active");
        /*$('.grid-x .name').removeClass('colhighlight');
         $('.makeinline .name').removeClass('sort-active');*/
      }
      if (s == "total_staff") {
        this.totalstaffActive = true;
        $(".makeinline .total_staff").addClass("sort-active");
      } else {
        this.totalstaffActive = false;
        $(".makeinline .total_staff").removeClass("sort-active");
      }
      if (s == "reg_user") {
        this.reguserActive = true;
        $(".makeinline .reg_user").addClass("sort-active");
      } else {
        this.reguserActive = false;
        $(".makeinline .reg_user").removeClass("sort-active");
      }

      if (s == "still_in_post_at_3_months") {
        this.stillActive = true;
        $(".makeinline .still_in_post_at_3_months").addClass("sort-active");
      } else {
        this.stillActive = false;
        $(".makeinline .still_in_post_at_3_months").removeClass("sort-active");
      }

      if (s == "per_uptake") {
        this.peruptakeActive = true;
        $(".makeinline .per_uptake").addClass("sort-active");
      } else {
        this.peruptakeActive = false;
        $(".makeinline .per_uptake").removeClass("sort-active");
      }

      if (s == "candidates") {
        this.candidatesActive = true;
        $(".makeinline .candidates").addClass("sort-active");
      } else {
        this.candidatesActive = false;
        $(".makeinline .candidates").removeClass("sort-active");
      }

      if (s == "starters") {
        this.startersActive = true;
        $(".makeinline .starters").addClass("sort-active");
      } else {
        this.startersActive = false;
        $(".makeinline .starters").removeClass("sort-active");
      }

      if (s == "share") {
        this.shareActive = true;
        $(".makeinline .share").addClass("sort-active");
      } else {
        this.shareActive = false;
        $(".makeinline .share").removeClass("sort-active");
      }

      if (s == "successful_interviews") {
        this.successfulinterviewsActive = true;
        $(".makeinline .successful_interviews").addClass("sort-active");
      } else {
        this.successfulinterviewsActive = false;
        $(".makeinline .successful_interviews").removeClass("sort-active");
      }

      if (s == "still") {
        this.stillActive = true;
        $(".makeinline .still").addClass("sort-active");
      } else {
        this.stillActive = false;
        $(".makeinline .still").removeClass("sort-active");
      }

      if (s == "points_awarded") {
        this.pointsawardedActive = true;
        $(".makeinline .points_awarded").addClass("sort-active");
      } else {
        this.pointsawardedActive = false;
        $(".makeinline .points_awarded").removeClass("sort-active");
      }

      if (s == "bonus_points") {
        this.bonuspointsActive = true;
        $(".makeinline .bonus_points").addClass("sort-active");
      } else {
        this.bonuspointsActive = false;
        $(".makeinline .bonus_points").removeClass("sort-active");
      }
      if (s == "referral_points") {
        this.referralpointsActive = true;
        $(".makeinline .referral_points").addClass("sort-active");
      } else {
        this.referralpointsActive = false;
        $(".makeinline .referral_points").removeClass("sort-active");
      }
      if (s == "paid_out") {
        this.paidoutActive = true;
        $(".makeinline .paid_out").addClass("sort-active");
      } else {
        this.paidoutActive = false;
        $(".makeinline .paid_out").removeClass("sort-active");
      }
      if (s == "points_requested") {
        this.pointsrequestedActive = true;
        $(".makeinline .points_requested").addClass("sort-active");
      } else {
        this.pointsrequestedActive = false;
        $(".makeinline .points_requested").removeClass("sort-active");
      }
      if (s == "new_to_care") {
        this.newtocareActive = true;
        $(".makeinline .new_to_care").addClass("sort-active");
      } else {
        this.newtocareActive = false;
        $(".makeinline .new_to_care").removeClass("sort-active");
      }
      if (s == "active_users") {
        this.newtocareActive = true;
        $(".makeinline .active_users").addClass("sort-active");
      } else {
        this.newtocareActive = false;
        $(".makeinline .active_users").removeClass("sort-active");
      }

      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir =
          this.currentSortDir === "SORT_ASC" ? "SORT_DESC" : "SORT_ASC";

        if (this.currentSort == "name") {
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;
          this.starters_sortasc = false;
          this.starters_sortdesc = false;

          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;

          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.name_sortasc = true;
            this.name_sortdesc = false;
          } else {
            this.name_sortasc = false;
            this.name_sortdesc = true;
          }
        }
        if (this.currentSort == "total_staff") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;
          this.starters_sortasc = false;
          this.starters_sortdesc = false;

          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;
          //
          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.total_staff_sortasc = true;
            this.total_staff_sortdesc = false;
          } else {
            this.total_staff_sortasc = false;
            this.total_staff_sortdesc = true;
          }
        }

        if (this.currentSort == "reg_user") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;
          this.starters_sortasc = false;
          this.starters_sortdesc = false;

          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;
          //
          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");
          if (this.currentSortDir == "SORT_ASC") {
            this.reg_user_sortasc = true;
            this.reg_user_sortdesc = false;
          } else {
            this.reg_user_sortasc = false;
            this.reg_user_sortdesc = true;
          }
        }

        if (this.currentSort == "per_uptake") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;

          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;
          this.starters_sortasc = false;
          this.starters_sortdesc = false;

          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;
          //
          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.per_uptake_sortasc = true;
            this.per_uptake_sortdesc = false;
          } else {
            this.per_uptake_sortasc = false;
            this.per_uptake_sortdesc = true;
          }
        }

        if (this.currentSort == "candidates") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.starters_sortasc = false;
          this.starters_sortdesc = false;

          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;
          //
          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");
          if (this.currentSortDir == "SORT_ASC") {
            this.candidates_sortasc = true;
            this.candidates_sortdesc = false;
          } else {
            this.candidates_sortasc = false;
            this.candidates_sortdesc = true;
          }
        }
        if (this.currentSort == "starters") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;
          //
          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");
          if (this.currentSortDir == "SORT_ASC") {
            this.starters_sortasc = true;
            this.starters_sortdesc = false;
          } else {
            this.starters_sortasc = false;
            this.starters_sortdesc = true;
          }
        }

        if (this.currentSort == "share") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.share_sortasc = true;
            this.share_sortdesc = false;
          } else {
            this.share_sortasc = false;
            this.share_sortdesc = true;
          }
        }
        if (this.currentSort == "successful_interviews") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.successful_interviews_sortasc = true;
            this.successful_interviews_sortdesc = false;
          } else {
            this.successful_interviews_sortasc = false;
            this.successful_interviews_sortdesc = true;
          }
        }
        if (this.currentSort == "still_in_post_at_3_months") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");
          if (this.currentSortDir == "SORT_ASC") {
            this.still_sortasc = true;
            this.still_sortdesc = false;
          } else {
            this.still_sortasc = false;
            this.still_sortdesc = true;
          }
        }

        if (this.currentSort == "points_awarded") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.points_awarded_sortasc = true;
            this.points_awarded_sortdesc = false;
          } else {
            this.points_awarded_sortasc = false;
            this.points_awarded_sortdesc = true;
          }
        }

        if (this.currentSort == "bonus_points") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortasc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.bonus_points_sortasc = true;
            this.bonus_points_sortdesc = false;
          } else {
            this.bonus_points_sortasc = false;
            this.bonus_points_sortdesc = true;
          }
        }

        if (this.currentSort == "referral_points") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.referral_points_sortasc = true;
            this.referral_points_sortdesc = false;
          } else {
            this.referral_points_sortasc = false;
            this.referral_points_sortdesc = true;
          }
        }

        if (this.currentSort == "paid_out") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;

          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");

          if (this.currentSortDir == "SORT_ASC") {
            this.paid_out_sortasc = true;
            this.paid_out_sortdesc = false;
          } else {
            this.paid_out_sortasc = false;
            this.paid_out_sortdesc = true;
          }
        }
        if (this.currentSort == "points_requested") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortdesc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;
          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");
          if (this.currentSortDir == "SORT_ASC") {
            this.points_requested_sortasc = true;
            this.points_requested_sortdesc = false;
          } else {
            this.points_requested_sortasc = false;
            this.points_requested_sortdesc = true;
          }
        }


        if (this.currentSort == "new_to_care") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortdesc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.active_users_sortasc = false;
          this.active_users_sortdesc = false;
          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .active_users").removeClass("sort-active");
          if (this.currentSortDir == "SORT_ASC") {
            this.new_to_care_sortasc = true;
            this.new_to_care_sortdesc = false;
          } else {
            this.new_to_care_sortasc = false;
            this.new_to_care_sortdesc = true;
          }
        }

        if (this.currentSort == "active_users") {
          this.name_sortasc = false;
          this.name_sortdesc = false;
          this.total_staff_sortasc = false;
          this.total_staff_sortdesc = false;
          this.reg_user_sortasc = false;
          this.reg_user_sortdesc = false;
          this.per_uptake_sortasc = false;
          this.per_uptake_sortdesc = false;
          this.candidates_sortasc = false;
          this.candidates_sortdesc = false;

          this.starters_sortasc = false;
          this.starters_sortdesc = false;
          this.share_sortasc = false;
          this.share_sortdesc = false;
          this.successful_interviews_sortasc = false;
          this.successful_interviews_sortdesc = false;
          this.still_sortasc = false;
          this.still_sortdesc = false;
          this.points_awarded_sortasc = false;
          this.points_awarded_sortdesc = false;
          this.bonus_points_sortasc = false;
          this.bonus_points_sortdesc = false;
          this.referral_points_sortasc = false;
          this.referral_points_sortdesc = false;
          this.paid_out_sortasc = false;
          this.paid_out_sortdesc = false;
          this.points_requested_sortasc = false;
          this.points_requested_sortdesc = false;
          this.new_to_care_sortasc = false;
          this.new_to_care_sortdesc = false;
          $(".makeinline .name").removeClass("sort-active");
          $(".makeinline .total_staff").removeClass("sort-active");
          $(".makeinline .reg_user").removeClass("sort-active");
          $(".makeinline .still_in_post_at_3_months").removeClass(
            "sort-active"
          );
          $(".makeinline .per_uptake").removeClass("sort-active");
          $(".makeinline .candidates").removeClass("sort-active");
          $(".makeinline .starters").removeClass("sort-active");
          $(".makeinline .share").removeClass("sort-active");
          $(".makeinline .successful_interviews").removeClass("sort-active");
          $(".makeinline .still").removeClass("sort-active");
          $(".makeinline .points_awarded").removeClass("sort-active");
          $(".makeinline .bonus_points").removeClass("sort-active");
          $(".makeinline .referral_points").removeClass("sort-active");
          $(".makeinline .paid_out").removeClass("sort-active");
          $(".makeinline .points_requested").removeClass("sort-active");
          $(".makeinline .new_to_care").removeClass("sort-active");
          if (this.currentSortDir == "SORT_ASC") {
            this.active_users_sortasc = true;
            this.active_users_sortdesc = false;
          } else {
            this.active_users_sortasc = false;
            this.active_users_sortdesc = true;
          }
        }

      }

      return this.getSites();
    },
    changename: function () {
      this.site.name = this.site.newname;
    },

    seletedsites: function (flag = null) {
      if (flag) {
        if (this.sitesFilter[0] == -1) {
          this.sitesFilter.splice(0, 1);
        }
      }
      if (this.sitesFilter.length > 1) {
        if (this.sitesFilter[0] == -1) {
          this.sitesFilter.splice(0, 1);
        } else {
          for (var i = 0; i < this.sitesFilter.length; i++) {
            if (i == 0) {
              continue;
            }
            if (this.sitesFilter[i] == -1) {
              this.sitesFilter = [];
              this.sitesFilter[0] = -1;
            }
          }
        }
      }

      let selected_filter = [];
      for (var i = 0; i < this.availableSites.length; i++) {
        if ($.inArray(this.availableSites[i].id, this.sitesFilter) !== -1) {
          this.availableSites[i].image = "/images/selected.png";
          selected_filter.push(this.availableSites[i].name);
        } else {
          this.availableSites[i].image = "/images/idle.png";
        }
      }
      if (selected_filter.length > 0) {
        this.selected_sites_tags = "Site: " + selected_filter.join(", ");
      }
      this.getSites();
    },
    removeSitesTags: function () {
      if (this.sitesFilter.length > 1) {
        let length = this.sitesFilter.length;
        this.sitesFilter.splice(length - 1, 1);
        this.$refs.sitesFilter.clearSelection();
      } else {
        this.$refs.sitesFilter.clearSelection();
      }
      this.selected_sites_tags = "";
      for (var i = 0; i < this.availableSites.length; i++) {
        if (this.availableSites[i].image == "/images/selected.png") {
          this.availableSites[i].image = "/images/idle.png";
        }
      }
    },
    filterDropDownSites: function () {
      let self = this;
      let i;
      axios
        .get("/dashboard/account/get-portal-sites")
        .then(function (response) {
          self.availableSites = [];
          if (response.data) {
            self.sites = JSON.stringify(response.data);
            if (response.data.sites.length > 1) {
              var site = {
                id: -1,
                name: "All Sites",
                image: "/images/idle.png",
              };
              self.availableSites.push(site);
            }

            for (i = 0; i < response.data.sites.length; i++) {
              site = {
                name: response.data.sites[i].name,
                id: response.data.sites[i].id,
                image: "/images/idle.png",
              };
              self.availableSites.push(site);
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
  },
  mounted() {
    this.$root.$on("updatedJobs", this.getSites);
    this.sort("name").then(() => this.getSitesUsers());
    this.setSitesHeader();
    $(this.$el).foundation();

    /**
     *  for Sites filter dropdown
     */

    let self = this;
    let i;
    axios
      .get("/dashboard/account/get-portal-sites")
      .then(function (response) {
        if (response.data) {
          self.sites = JSON.stringify(response.data);
          if (response.data.sites.length > 1) {
            var site = {
              id: -1,
              name: "All Sites",
              image: "/images/idle.png",
            };
            self.availableSites.push(site);
          }

          for (i = 0; i < response.data.sites.length; i++) {
            site = {
              name: response.data.sites[i].name,
              id: response.data.sites[i].id,
              image: "/images/idle.png",
            };
            self.availableSites.push(site);
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    eventBus.$on('userPermissionChanges', (updatedData) => {
      if (updatedData === 'yes') {
        this.getSites();
        this.filterDropDownSites();
      }
    });
  },
  computed: {
    newbtnDisbaled() {
      if (this.site.name && this.site.user_id && this.site.postcode) {
        return false;
      } else return true;
    },
    editbtnDisabled() {
      if (
        this.editedSiteInfo.name != this.site.name ||
        this.editedSiteInfo.total_staff != this.site.total_staff ||
        this.editedSiteInfo.address != this.site.address ||
        this.editedSiteInfo.city != this.site.city ||
        this.editedSiteInfo.postcode != this.site.postcode ||
        this.editedSiteInfo.user_id != this.site.user_id
      ) {
        return false;
      } else return true;
    },
    deletebtnshow() {
      if (this.images.length > 0) {
        return true;
      } else return false;
    },
    deletebtneditshow() {
      if (this.images.length > 0 || this.site.logo) {
        return true;
      } else return false;
    },
    avatorshow() {
      if (this.images.length > 0) {
        return 1;
      } else if (this.site.logo != null) {
        return 2;
      } else return 3;
    },
  },
};
</script>
<style lang="scss" scoped>
#example-tabs {
  margin-bottom: 14px !important;
}

.modal-md {
  text-align: left !important;
}

.editsitemainblock {
  text-align: left !important;
}

.orgination-logo-box {
  width: 88px;
  height: 88px;
  border-radius: 4px;
  background-color: #f0f1f7;
  margin: 0px 24px 12px 0px;
  float: left;
  background-repeat: no-repeat;
}

.org-logo-text {
  height: 16px;
  margin: 12px 46px 0 0;
  font-family: InterUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #9297ae;
  clear: both;
}

.logo-top-extra {
  float: left;
  width: 100%;
  min-height: 200px;
}

.btn-upload-logo {
  padding-top: 24px;
  float: left;
}

#addSite .delete_icon {
  color: #9297ae;
  float: left;
  padding-top: 30px;
  padding-left: 20px;
}

#addSite .btn-upload-pic {
  padding-top: 24px;
  float: left;
}

.editsitemainblock .delete_icon {
  color: #9297ae;
  float: left;
  padding-top: 34px;
  padding-left: 20px;
}

.editsitemainblock .btn-upload-pic {
  padding-top: 24px;
  float: left;
}

#sites {
  img .leaderbord-open-col {
    width: 24px;
    height: 24px;
    margin: 14px 10px 13px 26px;
    object-fit: contain;
  }

  .column-setup-mask {
    width: 288px;
    height: 348px;
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
  }

  .Element-DropdownMenu-Header {
    height: 45px;
    padding: 15px 7px 5px 7px;
    float: left;
    width: 100%;
    text-align: left;
  }

  .column-outer-div {
    width: 100%;
    height: 40px;
    padding: 8px 7px 8px 7px;
    clear: both;
  }

  .Menu-Item {
    width: calc(100% - 30px);
    height: 20px;
    font-family: InterUI;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #363b54;
    float: left;
    text-align: left;
    text-transform: capitalize;
  }

  img.Element-Control-Checkbox-Selected {
    width: 24px;
    height: 24px;
    object-fit: contain;
    float: right;
  }

  img.Element-Control-Checkbox-Idle {
    width: 24px;
    height: 15px;
    object-fit: contain;
    float: right;
  }

  .Common-Countries {
    width: 91px;
    height: 16px;
    margin: 0 0 9px;
    font-family: InterUI;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.2px;
    color: #9297ae;
  }

  .leftpopup .dropdown-pane {
    width: 288px !important;
    height: 348px;
    max-height: 348px;
    /*margin-right: 5%;
  margin-top: 57px;*/
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    overflow-x: hidden;
    top: 12px !important;
    left: -10px !important;
  }

  .leftpopup .Rectangle {
    width: 288px;
    height: 1px;
    margin: 40px 10px 8px 0px;
    background-color: rgba(42, 49, 81, 0.1);
    padding: 0px !important;
  }

  .leftpopup .rightsidecheckbox {
    width: 30px;
    display: inline-block;
  }

  .makeinline {
    width: 100%;
    display: inherit;
  }

  .leftpopup {
    float: right;
    padding-top: 10px;
  }

  .jobs-search {
    height: 36px;
    width: 280px;
    padding: 6px 6px 6px 34px;
    border: 1px solid #dcdee6;
    border-radius: 3px;
    color: #9297ae;
    font: 400 14px "InterUI", sans-serif;
    background: #fff url(/images/search.png) no-repeat 7px center;
  }

  #openleadoption {
    cursor: pointer;
  }

  .cf-table {
    overflow-x: auto;
    border-radius: 0px !important;
  }

  .sort-active {
    border-bottom: 2px solid #ef5d72;
    padding-bottom: 0px !important;
    background-color: #f0f1f7 !important;
  }

  .fa {
    width: 24px;
    padding-left: 12px;
    padding-bottom: 0px;
  }

  .cf-table .cf-table-head p strong {
    background: none !important;
  }

  .cf-table .cell.auto p {
    margin: 14px 0 !important;
    text-align: center;
  }

  .dropdown-action button {
    width: 28px;
    height: 28px;
    background: url("/images/more.png") no-repeat center center;
    border-radius: 100%;
    text-indent: -9999px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .cf-table .cf-table-head {
    padding: 0px 18px 0px 18px;
  }
}

daterangepicker .drp-buttons {
  display: none !important;
}

.datelabelclass {
  height: 20px;
  font-family: InterUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #85899e;
}

.reportrange-text {
  height: 44px;
  border-radius: 4px;
  border: solid 1px #dcdee6;
  background-color: #ffffff;
  color: #363b54;
  font: 400 13px "InterUI", sans-serif;
  text-transform: capitalize;
  width: 150px !important;
  background: url(/images/svg/select_dropdown.png) no-repeat right center !important;
}

.deselect {
  width: 24px;
  height: 24px;
  background: url(/images/exit.png) no-repeat right center !important;
  display: inline-flex;
  cursor: pointer;
}

.show-load-app {
  top: 80% !important;
}

.padright {
  padding-bottom: 10px;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
  float: left;
}

.fa-calendar {
  display: none;
}

.cf-table-head .blank {
  pointer-events: none;
  max-width: 5px;
  cursor: pointer !important;
}

.blank {
  max-width: 26px;
}

.my-tooltip-classss {
  width: 125px !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: #363b54;
  position: absolute;
  left: 65px !important;
  padding-top: 0px !important;
  font-family: InterUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.sortable-chosen {
  background-color: #f0f1f7;
  width: 173px;
  height: 52px;
  cursor: url("/images/cursor-arrow-default.png"), auto;
}

.sortable-chosen:hover {
  background-color: #f0f1f7;
  /*width: 173px;
  height: 52px;*/
  cursor: url("/images/cursor-arrow-default.png"), auto;
}

.colhighlight {
  background: #f7f8fc;
}

.makeinline .item:hover {
  background-color: #f0f1f7;
  /*width: 173px;
  height: 48px;*/
  padding: 0px !important;
  margin: 0px !important;
  cursor: url("/images/cursor-arrow-default.png"), auto;
}

.rrsite .v-select li {
  width: 249px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal !important;
}

.rrsite .vs__dropdown-option {
  width: 200px !important;
  overflow-wrap: break-word;
}

.rrsite #vs8__listbox {
  width: 250px !important;
  overflow-wrap: break-word;
}

.rrsite ul {
  width: 250px !important;
  overflow-wrap: break-word;
}

.rrsite ul li {
  width: 250px !important;
  overflow-wrap: break-word;
}

.rrsite .vs__dropdown-menu {
  width: 250px !important;
}

.rrsite .vs__dropdown-option--highlight {
  width: 200px !important;
  overflow-wrap: break-word;
}

.rrsite .vs__dropdown-option {
  width: 200px !important;
  overflow-wrap: break-word;
}

.rrsite ul.vs__dropdown-menu {
  width: 200px !important;
}
</style>