<template>
    <fieldset>
        <div class="main-form-div job-table">
            <h2 class="step-heading">{{ titleWithSubtitle }}</h2>
            <div class="job-row">
                <label class="job-label"
                       for="Hours">
                    HOURS <span>*</span>
                </label>
                <input type="text"
                       id="Hours"
                       v-model="form.Hours"
                       :class="{ 'is-invalid': !isFieldValid(form.Hours) }"
                       placeholder="e.g. Full time" />
                <span v-if="!isFieldValid(form.Hours)"
                      class="error-message">
                    "Hours" is required.
                </span>
            </div>
            <div class="job-row">
                <label class="job-label"
                       for="JobType">
                    SALARY TYPE <span>*</span>
                </label>
                <single-select v-model="form.JobType"
                               :options="salaryTypes"
                               placeholder="Select salary type..."
                               :class="{ 'is-invalid': !isFieldValid(form.JobType) }" />
                <span v-if="!isFieldValid(form.JobType)"
                      class="error-message">
                    "Salary type" selection is required.
                </span>
            </div>
            <div v-if="shouldShowRateFields"
                 class="job-row">
                <label class="job-label"
                       for="Rate">
                    RATE ({{ currency }}) <span>*</span>
                </label>
                <div class="grid-x">
                    <div class="cell auto r_padding_05">
                        <div class="grid-x">
                            <div class="cell auto">
                                <input :class="{ 'is-invalid': !isFieldValid(form.RateMin) }"
                                       type="number"
                                       placeholder="Min (numbers only)"
                                       v-model="form.RateMin" />
                                <span v-if="!isFieldValid(form.RateMin)"
                                      class="error-message">
                                    "Min (numbers only)" is required.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="cell shrink r_padding_05">
                        <div class="line"></div>
                    </div>
                    <div class="cell auto">
                        <div class="grid-x">
                            <div class="cell auto">
                                <input :class="{ 'is-invalid': !isFieldValid(form.RateMax) }"
                                       type="number"
                                       placeholder="Max (numbers only)"
                                       v-model="form.RateMax" />
                                <span v-if="!isFieldValid(form.RateMax)"
                                      class="error-message">
                                    "Max (numbers only)" is required.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="shouldShowOtherField"
                 class="job-row">
                <label class="job-label"
                       for="Rate">
                    ENTER CUSTOM TEXT ({{ charCount }} / {{ maxChars }}) <span>*</span>
                </label>
                <input type="text"
                       id="others_jobtype"
                       v-model="form.others_jobtype"
                       :class="{ 'is-invalid': !isFieldValid(form.others_jobtype) }"
                       placeholder="e.g. Competitive pay and benefits"
                       @input="updateCount" />
                <span v-if="!isFieldValid(form.others_jobtype)"
                      class="error-message">
                    "ENTER CUSTOM TEXT" is required.
                </span>
            </div>
        </div>
    </fieldset>
</template>

<script>
import SingleSelect from '../../SingleSelect/SingleSelect.vue';

export default {
    name: 'Step2',
    components: {
        SingleSelect
    },
    props: {
        form: {
            type: Object,
            default: () => ({
                Hours: '',
                JobType: '',
                RateMin: 0,
                RateMax: 0,
                others_jobtype: '',
            })
        },
        title: {
            type: String,
            default: 'Job Details'
        },
        currency: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            salaryTypes: [
                { id: "Yearly", name: 'Yearly' },
                { id: "Monthly", name: 'Monthly' },
                { id: "Weekly", name: 'Weekly' },
                { id: "Hourly", name: 'Hourly' },
                { id: "Do not specify", name: 'Do not specify' },
                { id: "Other", name: 'Other' }
            ],
            maxChars: 65
        };
    },
    computed: {
        titleWithSubtitle() {
            return `${this.title} - Pay and Hours`;
        },
        shouldShowRateFields() {
            return this.form.JobType && !['Do not specify', 'Other'].includes(this.form.JobType);
        },
        shouldShowOtherField() {
            return this.form.JobType === 'Other';
        },
        checkValidation() {
            const commonFields = [
                this.form.Hours,
                this.form.JobType
            ];

            if (this.shouldShowRateFields) {
                return [...commonFields, this.form.RateMax, this.form.RateMin].every(this.isFieldValid);
            }

            if (this.shouldShowOtherField) {
                return [...commonFields, this.form.others_jobtype].every(this.isFieldValid);
            }

            return commonFields.every(this.isFieldValid);
        },
        charCount() {
            return this.form.others_jobtype.length;
        }
    },
    methods: {
        isFieldValid(field) {
            return field !== '' && field !== null;
        },
        updateCount() {
            if (this.charCount > this.maxChars) {
                this.form.others_jobtype = this.form.others_jobtype.slice(0, this.maxChars);
            }
        },
        resetFields() {
            this.form.RateMax = '';
            this.form.RateMin = '';
            this.form.others_jobtype = '';
        },
    },
    watch: {
        'form.JobType': function () {
            this.resetFields();
        },
        form: {
            deep: true,
            handler() {
                this.$emit('update-step-validity', this.checkValidation);
            }
        }
    },
    mounted() {
        this.$emit('update-step-validity', this.checkValidation);
    }
};
</script>

<style scoped lang="scss">
@import "./Steps.scss";
</style>