<script>
export default {
  name: "PencilFilledIcon",
};
</script>

<template>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 15.46V18.5C0 18.78 0.22 19 0.5 19H3.54C3.67 19 3.8 18.95 3.89 18.85L14.81 7.94L11.06 4.19L0.15 15.1C0.0500001 15.2 0 15.32 0 15.46ZM17.71 5.04C18.1 4.65 18.1 4.02 17.71 3.63L15.37 1.29C14.98 0.899999 14.35 0.899999 13.96 1.29L12.13 3.12L15.88 6.87L17.71 5.04Z" fill="#6A6F8E"/>
    </svg>
</template>
