<template>
  <div class="grid-x grid-padding-x">
      <div class="cell">
        <div class="button pink_btn b_margin_0 no_wrap_btn " data-toggle="confirm">Confirm</div>
        <div class="dropdown-pane" id="confirm" data-dropdown data-auto-focus="true" data-position="bottom" data-alignment="center">
          <div class="arrow_box">
                  <fieldset class="cell" align="center">
                    <legend>Are you sure you'd like to confirm?</legend>
                    <input type="radio" v-model="confirmed"  value="true" id="confirmYes" required><label for="confirmYes">YES</label>
                    <input type="radio" v-model="confirmed" value="false" id="confirmNo"><label for="confirmNo">NO</label>
                  </fieldset>
                <div align="right" v-if="confirmed == 'true'">
                    <button type="submit" class="grey_link">Submit <i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                </div>
           </div>
         </div>
      </div>
  </div>
</template>

<script>
    export default {
        data(){
          return {
            confirmed: false
          }
        },
        watch: {
            confirmed: function (val) { 
                console.log(this.confirmed);
            }
        }
    }
</script>
