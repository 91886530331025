<template>
    <div>
        <label v-if="!notShowLabel"
               class="bonus-label">SELECT USER</label>
        <v-select class="bonus-placeholder"
                  placeholder="Add user"
                  v-model="user_id"
                  :reduce="referrer => referrer.id"
                  label="name"
                  :options="availableUsers"
                  :clearable="false"></v-select>
        <input type="hidden"
               name="user_id"
               :value="user_id">
    </div>
</template>

<script>
export default {
    props: ['siteId', 'notShowLabel'],
    data() {
        return {
            users: [],
            availableUsers: [],
            user_id: ''
        }
    },
    watch: {
        user_id: function (val) {
            this.$emit('selectedUser', this.user_id)
        }
    },
    mounted() {
        this.getAvailableUsers();
    },
    methods: {
        getAvailableUsers() {
            var self = this;
            axios.get('/dashboard/notifications/get-users?siteId=' + self.siteId)
                .then(function (response) {
                    self.users = response.data;
                    var toProcess = Object.keys(self.users);
                    for (var i = toProcess.length - 1; i >= 0; i--) {
                        var user = {
                            name: self.users[toProcess[i]].first_name + " " + self.users[toProcess[i]].surname + " (" + self.users[toProcess[i]].email + ")",
                            id: self.users[toProcess[i]].uniqueKey
                        }
                        self.availableUsers.push(user);
                    }
                    self.user_id = self.availableUsers[0].id;
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }
}
</script>
