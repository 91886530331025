<template>
    <fieldset>
        <div class="main-form-div job-table">
            <h2 class="step-heading">{{ titleWithSubtitle }}</h2>
            <div class="job-row">
                <label class="job-label"
                       for="Job_Title">
                    JOB TITLE <span>*</span>
                </label>
                <input type="text"
                       id="Job_Title"
                       v-model="form.Job_Title"
                       :class="{ 'is-invalid': !isFieldValid(form.Job_Title) }"
                       placeholder="e.g. Care Assistant" />
                <span v-if="!isFieldValid(form.Job_Title)"
                      class="error-message">
                    "Job title" is required.
                </span>
            </div>
            <div class="job-row">
                <label class="job-label"
                       for="site_id">
                    SITE <span>*</span>
                    <span class="tooltip-span"
                          id="sites_msg"
                          style="padding: 0 2px">
                        <img src="/images/InfoIcon.png"
                             aria-label="Site Info Tooltip" />
                    </span>
                    <b-tooltip target="sites_msg"
                               triggers="hover">
                        This is for internal organisation purposes only. For security reasons, this is not displayed to
                        the candidate.
                    </b-tooltip>
                </label>
                <single-select v-model="form.site_id"
                               :options="sites"
                               placeholder="Select site..."
                               :class="{ 'is-invalid': !isFieldValid(form.site_id) }" />
                <span v-if="!isFieldValid(form.site_id)"
                      class="error-message">
                    "Site" selection is required.
                </span>
            </div>
            <div class="job-row">
                <label class="job-label"
                       for="Location">
                    LOCATION <span>*</span>
                    <span class="tooltip-span"
                          id="location_msg"
                          style="padding: 0 2px">
                        <img src="/images/InfoIcon.png"
                             aria-label="Location Info Tooltip" />
                    </span>
                    <b-tooltip target="location_msg"
                               triggers="hover">
                        This is the location that is displayed to prospective candidates.
                    </b-tooltip>
                </label>
                <input type="text"
                       id="Location"
                       v-model="form.Location"
                       :class="{ 'is-invalid': !isFieldValid(form.Location) }"
                       placeholder="e.g. London" />
                <span v-if="!isFieldValid(form.Location)"
                      class="error-message">
                    "Location" is required.
                </span>
            </div>
        </div>
    </fieldset>
</template>

<script>
import SingleSelect from '../../SingleSelect/SingleSelect.vue';

export default {
    name: 'Step1',
    components: {
        SingleSelect
    },
    props: {
        form: {
            type: Object,
            default: () => ({
                Job_Title: '',
                Location: '',
                site_id: ''
            })
        },
        title: {
            type: String,
            default: 'Job Details'
        },
        sites: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        titleWithSubtitle() {
            return `${this.title} - Key Details`;
        },
        checkValidation() {
            const requiredFields = [
                this.form.Job_Title,
                this.form.Location
            ];

            return requiredFields.every(this.isFieldValid);
        }
    },
    methods: {
        isFieldValid(field) {
            return field !== '' && field !== null;
        }
    },
    watch: {
        form: {
            deep: true,
            handler() {
                this.$emit('update-step-validity', this.checkValidation);
            }
        }
    },
    mounted() {
        this.$emit('update-step-validity', this.checkValidation);
    }
};
</script>

<style scoped lang="scss">
@import "./Steps.scss";
</style>