<script>
export default {
  name: 'KnowledgeBaseIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.231 17.7554C19.231 18.3314 18.772 18.7994 18.208 18.7994H12.421C12.669 18.0664 13.346 17.5334 14.15 17.5334H16.585C17.363 17.5334 17.997 16.8954 17.997 16.1104V6.84443H18.208C18.772 6.84443 19.231 7.31243 19.231 7.88843V17.7554ZM4.2 17.7554V7.88843C4.2 7.31243 4.659 6.84443 5.223 6.84443H5.435V16.1104C5.435 16.8954 6.068 17.5334 6.846 17.5334H9.281C10.085 17.5334 10.762 18.0664 11.009 18.7994H5.223C4.659 18.7994 4.2 18.3314 4.2 17.7554ZM6.635 5.42243C6.635 5.29943 6.73 5.20043 6.846 5.20043H9.281C10.293 5.20043 11.115 6.03743 11.115 7.06643V16.9754C10.604 16.5794 9.973 16.3334 9.281 16.3334H6.846C6.73 16.3334 6.635 16.2334 6.635 16.1104V5.42243ZM14.15 5.20043H16.585C16.702 5.20043 16.796 5.29943 16.796 5.42243V16.1104C16.796 16.2334 16.702 16.3334 16.585 16.3334H14.15C13.458 16.3334 12.827 16.5794 12.315 16.9754V7.06643C12.315 6.03743 13.139 5.20043 14.15 5.20043ZM18.208 5.64443H17.997V5.42243C17.997 4.63843 17.363 4.00043 16.585 4.00043H14.15C13.154 4.00043 12.269 4.48743 11.715 5.23743C11.162 4.48743 10.276 4.00043 9.281 4.00043H6.846C6.068 4.00043 5.435 4.63843 5.435 5.42243V5.64443H5.223C3.998 5.64443 3 6.65143 3 7.88843V17.7554C3 18.9934 3.998 20.0004 5.223 20.0004H18.208C19.434 20.0004 20.431 18.9934 20.431 17.7554V7.88843C20.431 6.65143 19.434 5.64443 18.208 5.64443Z" fill="currentColor"/>
  </svg>
</template>