<script>
export default {
    name: 'PortalUsersIcon'
}
</script>

<template>
    <svg width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect x="2.5"
              y="4.5"
              width="19"
              height="11"
              rx="0.5"
              stroke="#6A6F8E" />
        <path d="M6.77999 15.618C6.87022 15.5418 6.9845 15.5 7.10258 15.5H17.3902C17.5184 15.5 17.6416 15.5492 17.7346 15.6375L19.8398 17.6375C20.1673 17.9486 19.9471 18.5 19.4955 18.5H4.73416C4.26813 18.5 4.05551 17.9187 4.41157 17.618L6.77999 15.618Z"
              stroke="#6A6F8E" />
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.606 12.1719L14.5367 11.83C14.456 11.432 14.156 11.1203 13.7697 11.0302C13.6365 10.9833 12.9067 10.7415 12 10.7415C11.093 10.7415 10.3635 10.9833 10.2302 11.0302C9.84375 11.1203 9.54375 11.432 9.46325 11.83L9.39375 12.1719C8.899 11.5833 8.6 10.8264 8.6 10.0001C8.6 8.13219 10.1252 6.6125 12 6.6125C13.8747 6.6125 15.3997 8.13219 15.3997 10.0001C15.3997 10.8264 15.1007 11.5833 14.606 12.1719ZM9.90699 12.6639L10.0507 11.9534C10.0845 11.7876 10.2107 11.6585 10.3727 11.6246C10.387 11.6218 10.4012 11.6174 10.4147 11.6126C10.4217 11.61 11.1272 11.3531 12 11.3531C12.8725 11.3531 13.578 11.61 13.5852 11.6126C13.5987 11.6174 13.613 11.6218 13.627 11.6246C13.789 11.6585 13.9155 11.7876 13.949 11.9534L14.0927 12.6639C13.5152 13.1154 12.7895 13.3874 12 13.3874C11.2102 13.3874 10.4847 13.1154 9.90699 12.6639ZM12 6C9.79425 6 8 7.79444 8 9.99987C8 12.2056 9.79425 14 12 14C14.2055 14 16 12.2056 16 9.99987C16 7.79444 14.2055 6 12 6ZM12 9.82623C11.5245 9.82623 11.2678 9.33362 11.2678 8.87034C11.2678 8.45655 11.5963 8.12031 12 8.12031C12.4035 8.12031 12.732 8.45655 12.732 8.87034C12.732 9.33362 12.4755 9.82623 12 9.82623ZM12 7.50859C11.2655 7.50859 10.6677 8.11958 10.6677 8.87088C10.6677 9.63111 11.1347 10.439 12 10.439C12.8652 10.439 13.332 9.63111 13.332 8.87088C13.332 8.11958 12.7345 7.50859 12 7.50859Z"
              fill="#6A6F8E" />
    </svg>
</template>