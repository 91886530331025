<template>
    <div class="">
		<div style="width:35%;float:left;">{{ notificationData.Repeat }} </div>

		<button type="button" class="button btn orange_btn btn-sm" title="Delete Notification" @click="confirmFn( notificationData.id)"><i class="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
	</div>
</template>
<script>
   export default {
        props: ['notification'],
        data(){
          return {
            notificationData: this.notification,

          }
        },
        methods: {
		 confirmFn(id){
                 Vue.swal({
						title: "Are you sure you want to delete this notification?",
						text: "You won't be able to revert this!",
						type: "warning",
						confirmButtonColor: '#f55d73',
						confirmButtonText: 'Yes - Delete',
						cancelButtonText: "Cancel",
						showCancelButton: true,
        				reverseButtons: true,
        				customClass: {
        					container:'swal-before-leaving',
        					confirmButton: 'button pink_btn b_margin_0 no_wrap_btn',
        					cancelButton: 'button orange_btn b_margin_0 no_wrap_btn'
        				},
					 }).then((willDelete) => {
					  if (willDelete.value==true) {
						  axios.get('/dashboard/notifications/delete/'+id)
                            .then(function (response) {
                                // handle success
                                console.log(response);
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error);
                            })



						 Vue.swal({
							title: "Notification Deleted",
							type: "success",
							// imageUrl: '/images/sucess_in_sweet.jpg',
							imageWidth: 400,
							imageHeight: 200,
							confirmButtonColor: '#f55d73',
							confirmButtonText: 'OK',
							customClass: {
        					container:'alert-message',
        					confirmButton: 'button pink_btn b_margin_0 no_wrap_btn',
        					cancelButton: 'button orange_btn b_margin_0 no_wrap_btn'
        				},

						 });
						 //location.reload();
						$("#msg-"+id).hide();

					  }
					});

			}
		},


    }
</script>
