<template>
  <div class="grid-container full background_care" :style="cssVariables">
    <template v-if="askLogin">
      <div class="grid-x grid-padding-x align-center-middle full_height">
        <div class="cell large-4 medium-8 small-10">
          <div class="login-inner grid-padding-x padding-x-lg">
            <div class="login-small">
              <div class="logo-login-form">
                <img :src="logo" />
              </div>

              <h3 class="login-padding">Welcome back dev 1</h3>
              <div class="grey-font-sml sml-font-padding">
                <p class="login-bold">Please enter your login details below</p>
              </div>
              <!-- Login form -->
              <!-- <form> -->
              <div class="form_container t_margin_2">
                <div class="input-group">
                  <input
                    v-model="fields.email"
                    name="email"
                    value
                    class="input-group-field"
                    type="email"
                    placeholder="Email"
                    aria-describedby="example1Hint1"
                    aria-errormessage="example1Error1"
                    v-on:keyup.13="submit"
                    id="example3Input"
                    required
                  />
                </div>

                <div class="b_border_w1"></div>

                <div class="input-group t_margin_75rem">
                  <input
                    v-model="fields.password"
                    class="input-group-field"
                    name="password"
                    type="password"
                    placeholder="Password"
                    aria-describedby="example1Hint1"
                    aria-errormessage="example1Error2"
                    v-on:keyup.13="submit"
                    id="example2Input"
                    required
                  />
                </div>
              </div>
              <div class="t_margin_2 padding-forget">
                <strong><a href="/password/reset/" class>Forgotten your password?</a></strong>
              </div>

              <p class="error-wrapper">{{ error }}</p>

              <div class="t_margin_2">
                <b-button
                  @click="submit()"
                  v-on:keyup.13="submit"
                  type="submit"
                  id="login-button"
                  class="button btn-lg expanded redis_btn bold"
                  :disabled="btnDisabled == true"
                >
                  <img src="/images/svg/white.svg" :class="progress" />
                  LOG IN
                </b-button>
              </div>

              <!-- </form> -->

              <template v-if="providers && providers.length > 0">
                <div class="separator">
                  <span class="line"></span>
                  <span class="separator-text">OR</span>
                  <span class="line"></span>
                </div>
                <a v-for="sso in providers" :key="sso.provider" class="button btn-lg expanded redis_btn bold button-sso" :class="sso.provider" :href="`/login/${sso.provider}`" >
                  <img :src="sso.icon" class="sso-button__icon" :class="sso.provider" />
                  <span class="sso-button__text">Continue with {{ sso.display_name }}</span>
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <portal-otp-component v-if="askMFA" :data="userData" :brand-logo="this.brandLogo"></portal-otp-component>
  </div>
</template>

<script>
export default {
  props: {
    brandLogo: { type: String },
    backgroundImage: { type: String },
    providers: { type: Array },
  },
  data() {
    return {
      fields: {
        email: "",
        password: "",
      },
      error: "",
      progress: "progress-white hide",
      askMFA: 0,
      askLogin: 1,
      userData: [],
      logo: this.brandLogo || "/images/cf-login-logo.png",
    };
  },
  methods: {
    submit() {
      localStorage.clear();
      this.progresStart();
      this.errors = {};
      const axios = require("axios");
      axios
        .post("/login", this.fields)
        .then(response => {
          this.progressStop();
          if (response.data.error) {
            this.error =
              "For security reasons, your account has been temporarily locked. Please contact your account owner for assistance.";
          } else if (response.data.login_error) {
            this.error = response.data.login_error;
          } else {
            if (response.data.ask_mfa) {
              this.askLogin = 0;
              this.askMFA = 1;
              this.userData = response.data.data;
            } else {
              location.reload();
            }
          }
        })
        .catch(error => {
          this.error = "Your Email or Password Do not Match with our Record";
          this.progressStop();
        });
    },
    progresStart() {
      this.progress = "progress-white";
    },
    progressStop() {
      this.progress = "progress-white hide";
    },
  },
  computed: {
    btnDisabled: function () {
      if (this.fields.email && this.fields.password) {
        return false;
      } else return true;
    },
  },
  created() {
    if (this.backgroundImage) {
      this.cssVariables = {
        "--login_background_image_url": `url(${this.backgroundImage})`,
      };
    }
  },
};
</script>


<style scoped>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--grey2);
}

.separator .line {
  flex: 1;
  height: 1px;
  border-bottom: 1px solid var(--grey2);
}

.separator .separator-text {
  margin: 0 10px;
}
</style>