<script>
export default {
  name: "WidgetCheckIcon",
};
</script>

<template>
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.38349 6.92505C3.27174 6.92505 3.15924 6.88247 3.07224 6.79807L0.639243 4.4403C0.459243 4.26619 0.453243 3.97803 0.624243 3.79555C0.796743 3.61307 1.08099 3.60699 1.26099 3.7811L3.38424 5.83854L8.73924 0.675934C8.91924 0.501819 9.20424 0.509422 9.37599 0.6919C9.54699 0.874379 9.53949 1.1633 9.36024 1.33666L3.69399 6.79883C3.60699 6.88323 3.49524 6.92505 3.38349 6.92505Z"
      fill="white"
    />
  </svg>
</template>
