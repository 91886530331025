<template>
    <div>
        <label class="bonus-label">SELECT USERS</label>

        <multiselect class="multi-sites"
                     v-model="selectedUsers"
                     :options="availableUsers"
                     :multiple="true"
                     :clear-on-select="false"
                     label="name"
                     track-by="name"
                     :showLabels="false"
                     :optionsLimit="100"
                     placeholder="Add user">
            <template slot="noResult">No elements found</template>
        </multiselect>

        <div class="input-tagged">
            <span v-for="(user, index) in selectedUsers"
                  v-bind:key="user.id">
                <div class="tag-input__tag tag-right">
                    <span class="lablesize">{{ user.name }}</span>
                    <span class="deselect-pad">
                        <span class="deselect"
                              @click="removeSelectedTag(index)"></span>
                    </span>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    props: {
        users: {
            type: Array, default: () => []
        }
    },
    components: { Multiselect },
    data() {
        return {
            availableUsers: this.users.map((user) => {
                return {
                    id: user.id,
                    type: user.type,
                    name: `${user.first_name} ${user.surname}`
                }
            }),
            selectedUsers: [],
        }
    },
    watch: {
        selectedUsers: function () {
            const mappedUsers = this.selectedUsers.map(user => ({ id: user.id, type: user.type }));
            this.$emit('userSelect', mappedUsers);
            //this.$emit('userSelect', this.selectedUsers.map(user => user.id));
        }
    },
    methods: {
        removeSelectedTag(index) {
            this.selectedUsers.splice(index, 1);
        }
    }
}
</script>

<style scoped>
.input-tagged {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px;
    flex-basis: 100%;
    flex-grow: 1;
}

.tag-right {
    margin-right: 5px;
}

::v-deep .multiselect__input {
    font-size: 14px;
    height: 26px;
    padding-left: 0;
}

::v-deep .multiselect__input:focus {
    border: none !important;
    outline: none !important;
}

::v-deep .multiselect__option {
    font-size: 14px;
}
</style>