var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showImg)?_c('div',{staticClass:"show-load-client-super"},[_c('img',{attrs:{"src":_vm.loadImage}})]):_vm._e(),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell auto font_0875 no-padding"},[_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell small-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchVar),expression:"searchVar"}],staticClass:"jobs-search",attrs:{"disabled":_vm.showImg,"placeholder":"Search","name":"searchVar"},domProps:{"value":(_vm.searchVar)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadClientsData()},"input":function($event){if($event.target.composing){ return; }_vm.searchVar=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"cell small-2 candidate-margin-left"},[_c('v-select',{staticClass:"filter-client",attrs:{"placeholder":"Filter by:","options":_vm.options,"reduce":function (referrer) { return referrer.id; },"label":"name","clearable":false},on:{"input":function($event){return _vm.loadClientsData()}},model:{value:(_vm.superFilter),callback:function ($$v) {_vm.superFilter=$$v},expression:"superFilter"}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"cf-table client-table"},[_c('div',{staticClass:"\n        grid-x grid-padding-x\n        red_bg\n        t_margin_2\n        align-middle\n        cf-table-head\n        sticky-element-super\n        client-margin\n      "},[_c('div',{staticClass:"cell medium-4 white",attrs:{"scope":"col"}},[_c('p',{staticClass:"tb_margin_1 client-height"},[_c('a',{on:{"click":function($event){return _vm.sort('name')}}},[_c('strong',[_vm._v("\n              Client Name\n              "),_c('i',{class:{
                  'fa fa-sort': _vm.sortboth,
                  'fa fa-sort-asc': _vm.namesortasc,
                  'fa fa-sort-desc': _vm.namesortdesc,
                },staticStyle:{"padding-left":"4px"}})])])])]),_vm._v(" "),_c('div',{staticClass:"cell medium-3 white",attrs:{"scope":"col"}},[_c('p',{staticClass:"tb_margin_1 client-height"},[_c('a',{on:{"click":function($event){return _vm.sort('poc')}}},[_c('strong',[_vm._v("\n              POC\n              "),_c('i',{class:{
                  'fa fa-sort': _vm.sortboth,
                  'fa fa-sort-asc': _vm.pocsortasc,
                  'fa fa-sort-desc': _vm.pocsortdesc,
                },staticStyle:{"padding-left":"4px"}})])])])]),_vm._v(" "),_c('div',{staticClass:"cell medium-3 white",attrs:{"scope":"col","align":"center"}},[_c('p',{staticClass:"tb_margin_1 client-height"},[_c('a',{on:{"click":function($event){return _vm.sort('total_staff')}}},[_c('strong',[_vm._v("\n              Total staff\n              "),_c('i',{class:{
                  'fa fa-sort': _vm.sortboth,
                  'fa fa-sort-asc': _vm.total_staffsortasc,
                  'fa fa-sort-desc': _vm.total_staffsortdesc,
                },staticStyle:{"padding-left":"4px"}})])])])]),_vm._v(" "),_c('div',{staticClass:"cell medium-2 white",attrs:{"scope":"col","align":"center"}})]),_vm._v(" "),_vm._l((_vm.clientsData),function(value,index){return _c('div',{key:index},[_c('div',{staticClass:"grid-x grid-padding-x align-middle b_border_b1 client-margin"},[_c('div',{staticClass:"cell medium-4"},[_c('p',{staticClass:"tb_margin_1"},[_vm._v(_vm._s(value.name))])]),_vm._v(" "),_c('div',{staticClass:"cell medium-3"},[_c('p',{staticClass:"tb_margin_1"},[_vm._v(_vm._s(value.poc))])]),_vm._v(" "),_c('div',{staticClass:"cell medium-3",attrs:{"align":"center"}},[_c('p',{staticClass:"tb_margin_1"},[_vm._v(_vm._s(value.total_employees))])]),_vm._v(" "),_c('div',{staticClass:"cell medium-2 dropdown-action",attrs:{"align":"center"}},[_c('button',{staticClass:"dropdown button",attrs:{"id":'d-' + index,"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}}),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-pane radius-3",attrs:{"data-alignment":"right","aria-labelledby":'d-' + index}},[_c('ul',[_c('li',[_c('a',{attrs:{"href":'/dashboard/super/clients/auditlog/' + value.id}},[_vm._v("\n                  View Client Audit\n                ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":'/dashboard/super/clients/' + value.id}},[_vm._v("\n                  View Client Breakdown\n                ")])]),_vm._v(" "),(value.status == 1)?_c('li',[_c('a',{on:{"click":function($event){return _vm.deActivateClient(value.id)}}},[_vm._v("\n                  Deactivate Client\n                ")])]):_c('li',[_c('a',{on:{"click":function($event){return _vm.reActivateClient(value.id)}}},[_vm._v("\n                  Reactivate Client\n                ")])]),_vm._v(" "),(
                  _vm.useremail == 'emma@carefriends.co.uk' ||
                  _vm.useremail == 'nicola@carefriends.co.uk'
                )?_c('li',[_c('a',{on:{"click":function($event){return _vm.deleteClient(value.id)}}},[_vm._v("Delete Client")])]):_vm._e()])])])])])})],2),_vm._v(" "),_c('div',{staticClass:"pagination-container margin-top-2 test-2"},[(_vm.paginationData.last_page > 1)?[_c('paginate',{attrs:{"page-count":_vm.paginationData.last_page,"page-range":3,"margin-pages":2,"click-handler":_vm.loadClientsData,"prev-text":'<',"next-text":'>',"prev-class":'paginate-controls',"next-class":'paginate-controls',"container-class":'pagination pagination-margin',"page-class":'page-item',"active-class":'active'},model:{value:(_vm.paginationData.current_page),callback:function ($$v) {_vm.$set(_vm.paginationData, "current_page", $$v)},expression:"paginationData.current_page"}})]:_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-x grid-padding-x"},[_c('div',{staticClass:"cell shrink client-bottom candidate-margin-left"},[_c('a',{staticClass:"btn button orange_btn",attrs:{"href":"/dashboard/super/clients/export-app-users/1"}},[_vm._v("\n        EXPORT APP USERS\n      ")])]),_vm._v(" "),_c('div',{staticClass:"cell shrink client-bottom"},[_c('a',{staticClass:"btn-edit button pink_btn b_margin_0 no_wrap_btn",attrs:{"href":"/dashboard/super/clients/add"}},[_vm._v("\n        Add a new client\n      ")])])])}]

export { render, staticRenderFns }