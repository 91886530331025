<template>
    <div ref="job-actions-wrapper"
         class="job-actions-wrapper">
        <button class="job-actions__button"
                :id="'d' + index"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="showMenu">
            <icon name="ellipsis-menu"
                  class="ellipsis" />
        </button>

        <div class="dropdown-menu dropdown-pane radius-3"
             :aria-labelledby="'d' + index">
            <ul class="job-actions-modal">
                <div class="job-actions">
                    <template v-if="currentuser.new_admin == 1">
                        <li v-if="currentuser.job_check == 1">
                            <add-job formType="edit"
                                     :job="job"
                                     :sites="sites"
                                     :pusers="pusers"
                                     :brand="brand"
                                     :currentuser="currentuser"
                                     @on-edited="onJobEdited"></add-job>
                        </li>
                        <li v-if="showAssignTariffOption">
                            <a class="action-btn"
                               href="javascript:void(0)"
                               @click="openAssignTariffModal">
                                <icon name="plus" />
                                <span>Assign Tariff</span>
                            </a>
                        </li>
                        <li v-if="currentuser.job_check == 1"
                            @click="showImg = true">
                            <toggle-job :currentuser="currentuser"
                                        :job="job"
                                        :index="index"
                                        @on-job-toggle="onJobToggled" />
                        </li>
                        <li v-if="currentuser.job_check == 1"
                            data-toggle="modal"
                            data-target="#myModal">
                            <duplicate-job :currentuser="currentuser"
                                           :job="job"
                                           :index="index"
                                           @on-job-duplicated="onJobDuplicated" />
                        </li>
                        <li v-if="currentuser.job_check == 1 && !job.Status">
                            <delete-job :currentuser="currentuser"
                                        :job="job"
                                        :index="index"
                                        @on-job-deleted="onJobDeleted" />
                        </li>
                    </template>

                    <template v-else>
                        <li data-toggle="modal"
                            data-target="#myModal">
                            <a class="action-btn"
                               href="javascript:void(0)"
                               @click.prevent="editjob(job, index)">
                                <icon name="pencil" />
                                <span>Edit</span>
                            </a>
                        </li>
                        <li v-if="showAssignTariffOption">
                            <a class="action-btn"
                               href="javascript:void(0)"
                               @click="openAssignTariffModal">
                                <icon name="plus" />
                                <span>Assign Tariff</span>
                            </a>
                        </li>
                        <li>
                            <toggle-job :currentuser="currentuser"
                                        :job="job"
                                        :index="index"
                                        @on-job-toggle="onJobToggled" />
                        </li>
                        <li data-toggle="modal"
                            data-target="#myModal">
                            <duplicate-job :currentuser="currentuser"
                                           :job="job"
                                           :index="index"
                                           @on-job-duplicated="onJobDuplicated" />
                        </li>
                        <li>
                            <a class="action-btn"
                               href="/dashboard/candidates">
                                <span>View candidates</span>
                            </a>
                        </li>
                        <li>
                            <a class="action-btn"
                               href="#">
                                <span>View analytics</span>
                            </a>
                        </li>
                        <li>
                            <delete-job :currentuser="currentuser"
                                        :job="job"
                                        :index="index"
                                        @on-job-deleted="onJobDeleted" />
                        </li>
                    </template>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import { Icon } from '../../../../app/components/Icon';
import AddJob from '../AddJob/AddJob.vue';
import DeleteJob from '../DeleteJob/DeleteJob.vue';
import ToggleJob from '../ToggleJob/ToggleJob.vue';
import DuplicateJob from '../DuplicateJob/DuplicateJob.vue';

export default {
    name: 'JobAction',
    props: {
        brand: {
            type: Object,
            required: true
        },
        currentuser: {
            type: Object,
            required: true
        },
        job: {
            type: Object,
            required: true
        },
        sites: {
            type: Array,
            default: () => []
        },
        pusers: {
            type: Array,
            default: () => []
        },
        index: {
            type: Number,
            default: 0
        },
        pointTariffsFeatureFlag: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Icon,
        AddJob,
        DeleteJob,
        ToggleJob,
        DuplicateJob
    },
    computed: {
        activeOptionText() {
            return this.job.Status ? "Deactivate" : "Activate";
        },
        activeOptionIcon() {
            return this.job.Status ? "pause" : "play";
        },
        showAssignTariffOption() {
            return this.pointTariffsFeatureFlag && this.currentuser.customer.variable_points_tariffs && this.currentuser.tariff_check;
        }
    },
    methods: {
        showMenu() {
            $(this.$el).foundation();
        },
        openAssignTariffModal() {
            this.$emit('open-assign-tariff-modal', [this.job]);
        },
        onJobDeleted(data) {
            this.$emit('on-job-deleted', data);
        },
        onJobToggled() {
            this.$emit('on-job-toggle');
        },
        onJobDuplicated(data) {
            this.$emit('on-job-duplicated', data);
        },
        onJobEdited() {
            this.$emit('on-job-edited');
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick);
    }
}
</script>

<style scoped lang="scss">
@import "./JobAction.scss";
</style>