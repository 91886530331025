<template>
    <div>
        <a href="javascript:void(0)"
           class="action-btn"
           @click="toggleJobStatus(job.id)">
            <icon :name="activeOptionIcon" />
            <span>{{ activeOptionText }}</span>
        </a>
    </div>
</template>

<script>
import { Icon } from '../../../../app/components/Icon';

export default {
    name: 'ToggleJob',
    props: {
        currentuser: { type: Object, required: true },
        job: { type: Object, required: true },
        index: { type: Number, default: 0 }
    },
    components: {
        Icon,
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        activeOptionText() {
            return this.job.Status ? "Deactivate" : "Activate";
        },
        activeOptionIcon() {
            return this.job.Status ? "pause" : "play";
        }
    },
    methods: {
        toggleJobStatus(id) {
            axios
                .post("/dashboard/jobs/toggle", { id })
                .then(response => {
                    this.showSuccessToast(response);
                    this.$emit('on-job-toggle');
                })
                .catch(error => {
                    console.error("JobAction Toogle Error: ", error);
                });
        },
        showSuccessToast(response) {
            const isSuccess = response.data.success;
            const wrapperClass = isSuccess ? 'success-circle' : 'close-circle';
            const wrapperIcon = isSuccess ? 'fas fa-check-circle' : 'fas fa-times-circle';
            const elementColor = isSuccess ? '#5dc2a6' : '#f55d73';

            Vue.swal({
                html: `<span class="${wrapperClass}"><i class="${wrapperIcon}"></i></span> ${response.data.message}`,
                confirmButtonColor: elementColor,
                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                background: elementColor,
                customClass: { container: "candidate-toast" },
                showClass: { popup: "animated fadeInLeft fast", icon: "animated heartBeat delay-1s" },
                hideClass: { popup: "animated fadeOutLeft slow" },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
            });
        }
    }
};
</script>

<style scoped lang="scss">
@import "./ToggleJob.scss";
</style>