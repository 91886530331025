<template>
    <div class="add-edit-job">
        <div v-if="formType === 'add'"
             class="add-job">
            <button @click="showJobModal"
                    class="button add-btn">
                <span>+</span> Add Job
            </button>
        </div>
        <a v-if="formType === 'edit'"
           href="javascript:void(0)"
           class="action-btn"
           @click="showJobModal">
            <icon name="pencil" />
            <span>Edit</span>
        </a>
        <job-modal ref="jobModal"
                   :loading="loading"
                   :currentuser="currentuser"
                   :current-step="currentStep"
                   :step-validities="stepValidities"
                   @on-process="handleProcess"
                   @next-step="nextStep"
                   @pre-step="preStep">
            <job-form ref="form"
                      :formType="formType"
                      :job="job"
                      :sites="sites"
                      :pusers="pusers"
                      :currency="currency"
                      :brand="brand"
                      :currentuser="currentuser"
                      :errors="errors"
                      :current-step="currentStep"
                      @on-submit="processJob"
                      @update-step-validity="handleStepValidation" />
        </job-modal>
    </div>
</template>

<script>
import Icon from '../../../../app/components/Icon/Icon.vue';
import JobModal from '../JobModal/JobModal.vue';
import JobForm from '../JobForm/JobForm.vue';

export default {
    name: 'AddJob',
    components: {
        Icon,
        JobModal,
        JobForm
    },
    props: {
        job: {
            type: Object,
            default: () => ({})
        },
        formType: {
            type: String,
            default: "add"
        },
        sites: {
            type: Array,
            default: () => []
        },
        pusers: {
            type: Array,
            default: () => []
        },
        currency: {
            type: String,
            default: "£"
        },
        brand: {
            type: Object,
            required: true
        },
        currentuser: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            errors: {},
            currentStep: 1,
            stepValidities: {}
        };
    },
    methods: {
        showJobModal() {
            this.currentStep = 1;
            localStorage.removeItem('imageName');
            if (this.job.id && this.job.Image) {
                localStorage.setItem('imageName', this.job.Image);
            }
            this.$refs.jobModal.show();
        },
        hideJobModal() {
            this.$refs.jobModal.hide();
        },
        async processJob(data) {
            this.loading = true;
            const screeningQuestions = [
                { 'value': data.question_type_1, 'order': 1 },
                { 'value': data.question_type_2, 'order': 2 }
            ];

            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                formData.append(key, value);
            });

            screeningQuestions.forEach((question) => {
                if (question.value > 0) {
                    formData.append(`screening_questions[${question.order}]`, question.value);
                }
            });

            try {
                if (this.formType === 'add') {
                    await axios.post("/dashboard/jobs/add", formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });
                    this.showSuccessToast('New job added!');
                    this.$refs.form?.resetForm();
                    this.$emit('on-added');
                } else {
                    await axios.post("/dashboard/jobs/" + this.job.id, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-HTTP-Method-Override": "PUT"
                        }
                    });
                    this.showSuccessToast('Job updated successfully!');
                    this.$emit('on-edited');
                }
                this.loading = false;
                localStorage.removeItem('imageName');
                this.hideJobModal();
            } catch (error) {
                console.error("Error Adding New Job - error:", error);
            }
        },
        showSuccessToast(message) {
            Vue.swal({
                html: `<span class="success-circle"><i class="fas fa-check-circle"></i></span> ${message}`,
                confirmButtonColor: "#5dc2a6",
                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: { container: "candidate-toast" },
                showClass: { popup: "animated fadeInLeft fast", icon: "animated heartBeat delay-1s" },
                hideClass: { popup: "animated fadeOutLeft slow" },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
            });
        },
        handleProcess() {
            this.$refs.form?.submitForm();
        },
        nextStep() {
            this.currentStep++;
        },
        preStep() {
            this.currentStep--;
        },
        handleStepValidation({ step, isValid }) {
            this.$set(this.stepValidities, step, isValid);
        }
    }
};
</script>

<style scoped lang="scss">
@import "./AddJob.scss";
</style>