<template>
  <div>
    <div class="add-job notification-modal-main-div">
      <button
        v-b-modal.award-points-modal
        class="button pink_btn b_margin_0 no_wrap_btn job-add"
      >
        Award Points
      </button>
      <!-- <div class="grey_border_box_inner box_shadow"> -->

      <div>
        <b-modal
          class="modal-p-div"
          ref="award-points-modal"
          id="award-points-modal"
          size="xl"
          :no-close-on-backdrop="true"
        >
          <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
          </template>
          <div v-if="currentuser.bonus_check == 0">
            <div class="grid-y">
              <div class="grid-x input-box-2">
                <div class="cell auto input-box">
                  <div class="discard-header4">
                    <h3 class="discard-header-title-oops">Oops!</h3>
                  </div>
                  <p class="if-you-discard-chang-oops">
                    You don’t have permission to award bonus points.
                    Please ask your manager to help you with this.
                  </p>
                </div>
              </div>
            </div>
            <div class="cell auto popup-button">
              <input
                class="button btn_lg btn pink_btn"
                type="submit"
                value="OK"
                style="
              float: right;
              margin-right: -13px;
              margin-bottom: 13px;
              margin-left: 7px;
            "
                @click="closeModal"
              />
            </div>
          </div>
          <div v-else>
            <section class="multi-step-row multi-step-p">
              <div v-show="showLoad" class="show-load-candidate">
                <img :src="myImage" />
              </div>
              <div class="pop-up-box pop-up-box-styling">
                <div class="top-check-row">
                  <ul>
                    <li
                      v-bind:class="{
                      selected: isActive(1),
                      completed: done1,
                      selected: active1,
                    }"
                    >
                      <a @click="step = 1" href="javascript:void(0)">1</a>
                    </li>
                    <li
                      v-bind:class="{
                      selected: isActive(2),
                      completed: done2,
                      selected: active2,
                    }"
                    >
                      <a @click="step = 2" href="javascript:void(0)">2</a>
                    </li>
                  </ul>
                </div>

                <div class="text-box" id="text-box">
                  <p class="add-a-job">AWARD BONUS POINTS</p>
                  <p class="key-center">
                    <span class="key-details" v-if="step == 1">Details</span>
                    <span class="key-details" v-if="step == 2">Content</span>
                  </p>

                  <fieldset v-if="step == 1">
                    <div class="job-leftside"></div>

                    <div class="main-form-div">
                      <div class="job-leftside"></div>
                      <form class="form-dropdown-styling justify-content-center">
                        <div class="form-group input-sendto">
                          <label
                            class="bonus-form-lable"
                            for="exampleFormControlSelect1"
                          >AWARD TO</label
                          >
                          <v-select
                            class="bonus-placeholder"
                            placeholder="Select"
                            v-model="form.who"
                            :options="has_permission_to_all_sites=='true' ? items : items_site"
                            :clearable="false"
                            :reduce="(item) => item.value"
                            label="name"
                            @input="
                            form.user_id = '';
                            form.site_id = '';
                          "
                          ></v-select>

                          <div
                            v-if="form.who"
                            class="cell medium-12 full-width input-box"
                          >
                            <div
                              v-if="form.who == 'Single app user'"
                              class="cell auto no-label multi multi-bonus"
                            >
                              <single-app-user-notifications-component
                                @selectedUser="selectedUser"
                                :selectedUserIds="form.user_id"
                                :users="mobile_users_arr"
                                :notShowLabel="true"
                              ></single-app-user-notifications-component>
                            </div>

                            <div
                              v-else-if="form.who == 'Multiple app users'"
                              class="cell auto no-label multi multi-bonus"
                            >
                              <multiple-select-users-notifications-component
                                @selectedUser="multiSelectedUser"
                                :users="mobile_users_arr"
                                :selectedUserIds="form.user_id"
                                :notShowLabel="true"
                              ></multiple-select-users-notifications-component>
                            </div>

                            <div
                              v-else-if="form.who == 'All users at site'"
                              class="cell auto no-label"
                            >
                              <div>
                                <site-select-customer-user-component
                                  @selectedSite="selectedSite"
                                  :site-id="form.site_id"
                                  :sites="this.siteOptions"
                                  :notShowLabel="true"
                                  class="select-site"
                                ></site-select-customer-user-component>
                              </div>
                            </div>

                            <div
                              v-else-if="form.who == 'All users at organisation'"
                            ></div>
							<div
                              v-else-if="form.who == 'All current employees'"
                            ></div>
                          </div>

                          <div
                            v-if="selectSite"
                            class="cell auto"
                            id="send-to-sites"
                          >
                            <label>
                              <v-select
                                placeholder="Select user..."
                                name="site_id"
                                v-model="form.site_id"
                                :reduce="(referrer) => referrer.id"
                                label="name"
                                :options="this.siteOptions"
                                class="bonus-placeholder-styling"
                              ></v-select>
                            </label>
                          </div>
                        </div>

                        <div class="form-group">
                          <label
                            class="bonus-form-lable"
                            for="exampleFormControlSelect1"
                          >CATEGORY*</label
                          >
                          <v-select
                            class="bonus-placeholder-styling"
                            placeholder="Select Category"
                            label="name"
                            @input="setCategory"
                            :value="
                            form.selectedCategory && form.selectedCategory.name
                          "
                            :options="categories"
                            :clearable="false"
                          ></v-select>
                        </div>

                        <div class="form-group">
                          <label
                            class="bonus-form-lable"
                            for="exampleFormControlSelect1"
                          >NUMBER OF POINTS*

                            <small v-if="insufficientPointsInBudget" class="bonus-form-lable available-points-to-display">
                              &nbsp;&nbsp;{{ availablePointsToDisplay }}
                            </small>
                          </label>

                          <input
                            class="numberOfPoints"
                            v-if="
                            form.selectedCategory &&
                            form.selectedCategory.points_type == 'fixed'
                          "
                            type="number"
                            placeholder="Add max"
                            :value="form.points_no"
                            @input="() => {}"
                            disabled
                          />
                          <input
                            :class="{ 'not-enough-points': validatePointsError || insufficientPointsInBudget }"
                            v-else
                            type="number"
                            placeholder="Add max"
                            v-model="form.points_no"
                            oninput="validity.valid||(value='');"
                          />
                        </div>
                      </form>

                      <p v-if="validatePointsError || insufficientPointsInBudget" class="points-error-message">
                        <img class="points-error-message-icon" v-if="pointsError !== null" src="/images/svg/red-info-icon.svg" />
                        <span class="points-error-message-text">
                          {{ pointsError }}
                        </span>
                      </p>
                    </div>

                    <div class="cell auto popup-button pad-top">
                      <!-- <button @click.prevent="goback()" class="back button btn">
                        Back
                      </button> -->
                      <input
                        @click="clickNext"
                        type="button"
                        name="next"
                        class="next action-button button btn_lg btn pink_btn"
                        value="Next"
                        :disabled="!validateStepOne"
                      />
                    </div>
                  </fieldset>

                  <fieldset v-if="step == 2">
                    <form
                      v-if="
                      form.selectedCategory &&
                      form.selectedCategory.content_type != 'fixed'
                    "
                    >
                      <div class="bonus-form-lable">
                        <label for="exampleInputEmail1" class="bonus-form-lable"
                        >TITLE *</label
                        >
                        <ckeditor
                          class="cheditor-styling-titleBonus"
                          id="exampleFormControlTextarea-B"
                          v-model="form.selectedCategory.Title"
                          :config="titleEditorConfig"
                        ></ckeditor>

                        <span class="form-error" id="notificationTitle"
                        >Please enter a notification title</span
                        >
                      </div>

                      <div class="form-group">
                        <label
                          class="bonus-form-lable-wording"
                          for="exampleFormControlTextarea-B"
                        >WORDING *</label
                        >
                        <ckeditor
                          class="cheditor-styling"
                          :config="editorConfig"
                          id="exampleFormControlTextarea-B"
                          v-model="form.selectedCategory.message"
                        ></ckeditor>
                      </div>

                      <div class="imgae-Modal-styling">
                        <p class="img-Content-styling">Image</p>
                        <p
                          v-if="isHidden"
                          v-on:click="
                          isHidden = !isHidden;
                          form.selectedCategory.image_url = '';
                          this.imageName = '';
                        "
                          class="Paste-image"
                        >
                          Paste image URL
                        </p>
                        <p
                          v-else
                          v-on:click="
                          isHidden = !isHidden;
                          form.selectedCategory.image_url = '';
                          this.imageName = '';
                        "
                          class="Paste-image"
                        >
                          Upload Image
                        </p>
                      </div>

                      <div v-if="!isHidden" class="Img-url-paste">
                        <input
                          placeholder="example.png"
                          type="text"
                          v-model="form.selectedCategory.image_url"
                        />
                      </div>
                      <div v-else>
                        <label class="Image-label-content"></label>
                        <input
                          type="file"
                          ref="inputFile"
                          accept="image/*"
                          @change="uploadImage"
                          id="actual-btn"
                          hidden
                        />
                        <label for="actual-btn" class="upload-file-styling-bonus">
                          <img
                            draggable="true"
                            class="img-upload-btn"
                            src="/images/Capture.PNG"
                            @dragover.prevent
                            @drop="onDrop"
                          />
                          <span class="upload-btn-text"
                          >Drag and drop an image or
                          <span class="Red-browse">browse</span> to choose a
                          file.</span
                          >
                        </label>
                        <p class="bonus-upload-img-text">
                          PNG or JPEG. Minimum height and width 300px,1Mb max.
                          Landscape or it will be cropped by ios and Android
                        </p>
                        <div
                          class="img-upload-hide"
                          v-if="form.selectedCategory.image_url"
                        >
                          <i
                            class="fa fa-times-circle"
                            @click="
                            form.selectedCategory.image_url = '';
                            this.imageName = '';
                          "
                          ></i>
                          <img
                            :src="form.selectedCategory.image_url"
                            alt=""
                            class="img-upload-hide-img"
                          />
                        </div>
                      </div>
                    </form>

                    <div
                      class="card-margin-content"
                      v-else-if="form.selectedCategory"
                    >
                      <label
                        for="exampleInputEmail1"
                        class="bonus-form-lable-content"
                      >TITLE</label
                      >
                      <input
                        type="text"
                        class="border-content-bonus"
                        @input="() => {}"
                        :value="form.selectedCategory.Title"
                        disabled
                      />
                      <label
                        for="exampleInputEmail1"
                        class="bonus-form-lable-wording-modal"
                      >MESSAGE</label
                      >
                      <textarea
                        rows="4"
                        cols="50"
                        class="border-content-bonus"
                        @input="() => {}"
                        :value="form.selectedCategory.message"
                        disabled
                      >
                    </textarea>
                      <div v-if="form.selectedCategory.image_url">
                        <p class="img-Content-styling">Image</p>
                        <div class="">
                          <img
                            class="upload-img-content"
                            :src="form.selectedCategory.image_url"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="cell auto popup-button pad-top">
                      <button @click.prevent="goback()" class="back button btn">
                        Back
                      </button>
                      <input
                        @click="submitBonusPoints"
                        type="button"
                        name="AWARD"
                        class="next action-button button btn_lg btn pink_btn"
                        value="AWARD"
                        :disabled="!validateAward"
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </section>
          </div>
        </b-modal>
        <b-modal class="tab-modal" id="modal_unaunthenticated" size="lg">
          <div class="grid-y">
            <div class="grid-x input-box-2">
              <div class="cell auto input-box">
                <div class="discard-header4">
                  <h3 class="discard-header-title-oops">Oops!</h3>
                </div>
                <p class="if-you-discard-chang-oops">
                  You don’t have permission to award bonus points.
                  Please ask your manager to help you with this.
                </p>
              </div>
            </div>
          </div>
          <div class="cell auto popup-button">
            <input
              class="button btn_lg btn pink_btn"
              type="submit"
              value="OK"
              style="
              float: right;
              margin-right: -13px;
              margin-bottom: 13px;
              margin-left: 7px;
            "
              @click="closeModal"
            />
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue/dist/legacy.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import Datepicker from "vuejs-datepicker";
import SingleAppUserNotificationsComponent from "../forms/SingleAppUserNotificationsComponent";
import MultipleSelectUsersNotificationsComponent from "../forms/MultipleSelectUsersNotificationsComponent";
import SiteSelectCustomerUserComponent from "../forms/SiteSelectCustomerUserComponent";

export default {
  components: {
    SiteSelectCustomerUserComponent,
    MultipleSelectUsersNotificationsComponent,
    SingleAppUserNotificationsComponent,
    CKEditor,
    VueTimepicker,
    Datepicker,
    //  name:'imageUpload',
  },

  props: ["sitesarr", "templates", "currentUserId", "currentuser", "has_permission_to_all_sites"],
  data() {
    return {
      assignedBudgets: [],
      isHidden: true,
      categories: [],
      showLoad: false, // setting it true by default.
      myImage: "/images/loading-circle.gif",
      pointsError: null,
      regex: /<div>(.*)<\/div>/g,
      mobile_users_arr: null,
      replaceWith: " ",
      highlightedDates: {
        daysOfMonth: [9, 13],
      },
      isNotValid: true,
      stepTwoValid: false,
      previewImage: "http://portal.cf-staging.co.uk/images/cf-login-logo.png",
      imageName: "",
      datePickerValue: new Date(),
      disabledDates: { to: new Date(Date.now() - 86400000) },
      step: 1,
      done1: false,
      active1: true,
      current1: false,
      done2: false,
      active2: false,
      current2: false,
      done3: false,
      active3: false,
      current3: false,
      engagementTemplates: [],
      otherTemplates: [],
      senttooptions: [
        { id: "no repeat", name: "Does not repeat" },
        { id: "daily", name: "Daily" },
        { id: "weekly", name: "Weekly" },
        { id: "monthly", name: "Monthly" },
      ],
      selectUser: false,
      selectSite: false,
      siteOptions: [],
      select_who: "",
      selected_who: "",
      items: [
        { value: "Multiple app users", name: "Multiple Users" },
        { value: "Single app user", name: "Individual" },
        { value: "All users at site", name: "All registered app users at Site" },
        {
          value: "All users at organisation",
          name: "All registered app users",
        },
		{
          value: "All current employees",
          name: "All registered AND authorised users",
        },
      ],
      form: {
        user_id: null,
        reward_catagory_id: null,
        reason_for_allocation: null,
        points_no: null,
        allocted_by: this.currentUserId,
        who: null,
        site_id: null,
        selectedCategory: null,
        use_budgets: null,
      },
      titleEditorConfig: {
        autoParagraph: false,
        height: 60,
        editorplaceholder: "Add title",
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
        autoGrow_maxHeight: 50,
      },
      editorConfig: {
        autoParagraph: false,
        height: 100,
        editorplaceholder: "Add wording",
        toolbar: [["Bold", "BulletedList", "EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      items_site: [
        {value: "Multiple app users", name: "Multiple Users"},
        {value: "Single app user", name: "Individual"},
        { value: "All users at site", name: "All registered app users at Site" },
      ],
      pointsBudgetId: null,
      insufficientPointsInBudget: false,
      availablePointsToDisplay: null,
    };
  },

  methods: {
    addPointsPressed() {
      if (this.currentuser.bonus_check == 1) {
        this.$bvModal.show("award-points-modal");
      } else {
        this.$bvModal.show("modal_unaunthenticated");
      }
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      var files = e.target.files;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeFile() {
      this.image = "";
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();

      reader.fileName = image.name;

      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.imageName = e.target.fileName;
        this.previewImage = e.target.result;
        this.form.selectedCategory.image_url = e.target.result;
        this.$refs.inputFile.value = null;
        console.log(this.previewImage);
      };
    },

    goback() {
      if (this.step == 1) {
        return false;
      }
      if (this.step == 2) {
        this.step = 1;
        return true;
      }
      if (this.step == 3) {
        this.step = 2;
        return true;
      }
      if (this.step == 4) {
        this.step = 3;
        return true;
      }
    },
    prev() {
      this.step--;
    },
    isActive(id) {
      if (this.step <= id) {
        if (id == 1 && this.step <= 1) {
          return true;
        }
        return false;
      } else {
        if (this.step == 2) {
          this.done1 = true;
          this.active2 = true;
          this.current2 = true;
          return true;
        }
        if (this.step == 3) {
          this.done2 = true;
          this.active3 = true;
          this.current3 = true;
          return true;
        }
        if (this.step == 4) {
          this.current4 = true;
          this.active4 = true;
          this.done3 = true;
        }

        return true;
      }
    },
    selectedUser(value) {
      this.form.user_id = value;
    },
    selectedSite(value) {
      this.form.site_id = value;
    },
    getMobileUsersForMultipleApp() {
      let mobileUsersArr = [];
      var that = this;

      axios.get("get/mobile-users?assigned-to-sites-only").then((response) => {
        console.log("response ::", response.data);
        that.mobile_users_arr = response.data.activeAppUsers;
      });
    },
    multiSelectedUser: function (valueArr) {
      this.form.user_id = valueArr.length > 0 ? valueArr : "";
    },
    closeModal() {
      this.$refs["award-points-modal"].hide();
      this.$bvModal.hide("modal_unaunthenticated");
    },
    submitBonusPoints: function () {
      Vue.swal({
        title: "Loading...",
        html: "Allocating bonus points!",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });

      this.form.image_name = this.imageName;

      var self = this;
      //console.log(this.form);
      axios
        .post("/dashboard/points/bonus/add", this.form)
        .then(function (response) {
          // self.$refs["award-points-modal"].hide();
          // self.BonusPointsHistoryLink.$emit("fetchBonusData");
          // $('#awardBonusModal .close').click(); // modal proper event has issues here.

          Vue.swal.close();
          self.$root.$emit("pointsAwarded", "awarded");
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Points awarded',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "points-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast swal2-margin-top",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow swal2-margin-top",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          self.resetForm();
          self.$refs["award-points-modal"].hide();
        })
        .catch(function (error) {
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> An error has occured',
            confirmButtonColor: "#ea2020",
            confirmButtonText:
              '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#ea2020",
            customClass: {
              container: "points-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast swal2-margin-top",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow swal2-margin-top",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          self.resetForm();
          self.$refs["award-points-modal"].hide();
          console.log(error);
        });
    },
    resetForm() {
      this.form = {
        user_id: null,
        reward_catagory_id: null,
        reason_for_allocation: null,
        points_no: null,
        allocted_by: this.currentUserId,
        who: null,
        site_id: null,
        selectedCategory: null,
      };
      this.availablePointsToDisplay = null;
      this.pointsError = null;
      this.step = 1;
    },
    setCategory(val) {
      this.form.reward_catagory_id = val.id;
      this.form.points_no = val.points;
      this.form.selectedCategory = val;
    },
    fetchRewardCatagories() {
      var self = this;
      self.showLoad = true;
      axios
        .get("/dashboard/account/reward-cat-display")
        .then(function (response) {
          self.showLoad = false;
          const responseData = response.data.rewardCatagories;
          const rewardCategories = [];
          Object.keys(responseData)
            .forEach(k => rewardCategories.push(responseData[k]));

          // handle success
          self.categories = rewardCategories;
          console.log(
            "response.data.rewardCatagories :: ",
            response.data.rewardCatagories
          );
        })
        .catch(function (error) {
          self.showLoad = false;
          // handle error
          console.log(error);
        });
    },
    async validatePointsBudget() {
      let error = true;

      if (!this.currentuser.customer.bonus_points_budgets_feature) {
        return false;
      }

      try {
        const res = await axios.get(`/points-budgets/check-points`, {
          params: {
            customerId: this.currentuser.customer.id,
            who: this.form.who,
            userIds: this.form.user_id,
            siteId: this.form.site_id,
            requestedPoints: this.form.points_no
          }
        });

        if (res.status === 200) {
          let availableBudgetPoints = res.data?.payload?.available_points ?? 0;

          if (res.data?.payload?.result === false) {
            this.pointsError = "You do not have enough points available to distribute.";
            this.availablePointsToDisplay = availableBudgetPoints + " AVAILABLE POINTS";
            this.insufficientPointsInBudget = true;
          } else {
            this.form.use_budgets = true;
            this.pointsError = null;
            this.availablePointsToDisplay = null;
            this.insufficientPointsInBudget = false;
            error = false;
          }
        }
      } catch (error) {
        console.error('Error fetching budgets.',error);
      }

      return error;
    },
    async clickNext() {
      try {
        const { points_no, selectedCategory } = this.form;
        const { points_type, max_points } = selectedCategory;
        const currentPoints = parseInt(points_no);
        const maxAllowedPoints = parseInt(max_points);

        // Check if current points exceed the maximum allowed category points
        const notEnoughPoints = points_type !== "fixed" && (currentPoints > maxAllowedPoints);
        this.pointsError = notEnoughPoints ? `maximum allowed points are : ${maxAllowedPoints}` : null;

        // If there's an existing category points error
        if (this.pointsError !== null) {
          return
        }

        let validPointsBudget = await this.validatePointsBudget()
        if (validPointsBudget) {
          return;
        }
        this.step = 2;
      } catch (error) {
        console.error(error);
      }

    },
  },
  filters: {},
  mounted() {
    //called event when modal is about to show/visible
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      this.fetchRewardCatagories();
      this.getMobileUsersForMultipleApp();
      console.log("Modal is about to be shown", bvEvent, modalId);
    });

    this.$root.$on("selectedUser", this.form.user_id);

    if (this.sitesarr.length > 0) {
      for (var i = 0; i < this.sitesarr.length; i++) {
        let x = new Object();
        x.id = this.sitesarr[i].id;
        x.name = this.sitesarr[i].name;
        this.siteOptions.push(x);
      }
    } else if (this.sitesarr) {
      let x = new Object();
      x.id = this.sitesarr.id;
      x.name = this.sitesarr.name;
      this.siteOptions.push(x);
    }
  },
  computed: {
    validateStepOne() {
      if (
        this.form.reward_catagory_id &&
        this.form.who &&
        this.form.points_no
      ) {
        if (
          (this.form.who === "All users at site" && this.form.site_id) ||
          (this.form.who === "Single app user" && this.form.user_id) ||
          (this.form.who === "Multiple app users" && this.form.user_id) ||
          this.form.who === "All users at organisation" ||
          this.form.who === "All current employees"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    validateAward() {
      if (
        this.form.reward_catagory_id &&
        this.form.selectedCategory.name &&
        this.form.selectedCategory.message &&
        this.form.who &&
        this.form.points_no
      ) {
        if (
          (this.form.who === "All users at site" && this.form.site_id) ||
          (this.form.who === "Single app user" && this.form.user_id) ||
          (this.form.who === "Multiple app users" && this.form.user_id) ||
          this.form.who === "All users at organisation" ||
          this.form.who === "All current employees"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    fixedCategory() {
      if (
        this.form.reward_catagory_id &&
        this.form.selectedCategory.points_type == "fixed"
      ) {
        return true;
      } else {
        return false;
      }
    },
    validateStepTwo() {
      if (this.form.name && this.form.Message) {
        return (this.stepTwoValid = true);
      } else {
        return (this.stepTwoValid = false);
      }
    },
    validatePointsError() {
      if (
        this.form.selectedCategory &&
        this.form.selectedCategory.points_type != "fixed" &&
        parseInt(this.form.points_no) >
          parseInt(this.form.selectedCategory.max_points)
      ) {
        this.pointsError =
          "maximum allowed points are : " +
          this.form.selectedCategory.max_points;
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vs__search,
.vs__search:focus {
  color: #cbcddb !important;
}

.bonus-form-lable {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 4px;
}

.number-of-points-error {
  border: 1px solid red !important;
  background-color: #FFEBEB !important;
  color: #D8000C; /* Text color to make it stand out on light red */
  box-shadow: 0 0 5px red;
}

.bonus-form-lable-wording {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 7px;
  margin-top: 20px;
}
.bonus-form-lable-wording-modal {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 3px;
  margin-top: 66px;
}

.bonus-form-lable-content {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 4px;
  margin-top: 20px;
}
.input-tagged {
  float: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  flex-basis: 100%;
  flex-grow: 1;
}
.tag-right {
  margin-right: 5px;
}
#Message .cke_chrome,
#cke_editor1 .cke_chrome {
  border: 1px solid #dcdee6 !important;
}

#Message .cke_top,
#cke_editor1 .cke_top {
  border-bottom: none !important;
}

.date-time-modal-styling {
  display: flex;
  margin-bottom: 10px;
  // margin-top: -33px !important;
}

.datetime-picker-modal {
  width: 180px;

  margin: 0 8px 0 0;

  background-color: #ffffff;
}

.datetime-time-modal {
  width: 180px;
  margin: 0 8px 0 0;
  background-color: #ffffff;
}

.modal-content {
  width: 800px;
}

.modal-content {
  width: 743px !important;
  height: 799px !important;
  overflow-y: scroll;
}

#text-box .vs_dropdown-menu,
.modal .vs_dropdown-menu {
  min-width: 1000px !important;
  width: 100% !important;
}

#vs8_listbox {
  min-width: 1000px !important;
}
.modal-body .input-box {
  float: left;
  width: 100%;
  margin-bottom: 10px !important;
  /* height: 94px; */
  margin-top: -16px !important;
}
.form-group.input-sendto {
  margin-bottom: 12px;
}
.multi-step-row .pop-up-box .top-check-row {
  float: left;
  width: 100%;
  margin-bottom: 35px;
}
.multi-step-row .pop-up-box .top-check-row ul {
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.multi-step-row .pop-up-box .top-check-row ul:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 59px;
  height: 2px;
  background: #fff;
  z-index: 9;
}
.multi-step-row .pop-up-box .top-check-row ul li {
  display: inline-block;
  padding: 0 30px;
  position: relative;
}
.multi-step-row .pop-up-box .top-check-row ul li a {
  display: block;
  text-align: center;
  width: 24px;
  height: 24px;
  border: 1px solid #f0f1f7;
  background: #ffffff;
  border-radius: 100%;
  color: #c9cbda;
  text-decoration: none;
  font: 600 12px/25px "InterUI", sans-serif;
  line-height: 22px;
}
.multi-step-row .pop-up-box .top-check-row ul li:before,
.multi-step-row .pop-up-box .top-check-row ul li.selected:before {
  content: "";
  position: absolute;
  left: -25px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 47px;
  height: 1px;
  background: #e9eaed;
}
.multi-step-row .pop-up-box .top-check-row ul li.selected a {
  // text-indent: 0;
  border: solid 1px #c9cbda;
  background: #ffffff;
  color: #7b7f96;
}
.multi-step-row .pop-up-box .top-check-row ul li.completed a {
  color: #fff;
  text-indent: 0;
  background: #51bf73 url("/images/checkmark.png") no-repeat center center;
  text-indent: -99999px;
}
.multi-step-row .pop-up-box .top-check-row ul li.completed:before {
  display: block;
}

.multi-step-row .pop-up-box .top-check-row ul li:first-child::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 47px;
  height: 1px;
  background: #e9eaed;
  display: none;
}

.multi-step-row .pop-up-box .text-box {
  float: left;
  width: 100%;
}
.multi-step-row .pop-up-box .text-box h3 {
  margin: 0 0 20px 0;
}
.multi-step-row .pop-up-box .text-box strong.title {
  display: block;
  padding: 0 0 15px 0;
  color: #363b54;
  font: 600 16px/16px "InterUI", sans-serif;
}
.multi-step-row .pop-up-box .text-box input[type="text"],
.multi-step-row .pop-up-box .text-box input[type="file"] {
  float: left;
  width: 100%;
  height: 44px;
  margin: 3px 0 0;
  padding: 10px 11px 12px 12px;
  border-radius: 3px;
  border: solid 1px #dcdee6;
  background-color: #ffffff;
  box-shadow: none;
  font-family: InterUI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #363b54;
}
.multi-step-row .pop-up-box .text-box textarea {
  float: left;
  width: 100%;
  height: 140px;
  resize: none;
  border-radius: 2px;
  border: 1px solid #e9eaed;
  box-shadow: none;
  color: #363b54;
  padding: 14px;
  box-sizing: border-box;
  font: 400 14px "InterUI", sans-serif;
  margin-bottom: 25px;
}
.multi-step-row .pop-up-box .text-box select {
  float: left;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #e9eaed;
  box-shadow: none;
  color: #363b54;
  padding: 0 10px;
  box-sizing: border-box;
  font: 400 14px "InterUI", sans-serif;
  margin-bottom: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  // background: url("images/down.png") no-repeat 99% center;
}
.multi-step-row .pop-up-box .btn-row {
  float: left;
  width: 100%;
  text-align: center;
}
.multi-step-row .pop-up-box a.btn-next,
.multi-step-row .pop-up-box .text-box input[type="button"] {
}

.multi-step-row .pop-up-box a.btn-cancel {
  display: inline-block;
  text-decoration: none;
  color: #312d8b;
  width: 96px;
  height: 40px;
  border-radius: 4px;
  background-color: #eeeff7;
  font: 600 12px/40px "InterUI", sans-serif;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  border: 0;
  margin-left: 0;
  cursor: pointer;
  margin: 0 2px;
}
.multi-step-row .pop-up-box .text-box ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.multi-step-row .pop-up-box .text-box ul li {
  display: block;
  padding: 0 0 15px 0;
  color: #363b54;
  font: 400 16px/16px "InterUI", sans-serif;
}
.multi-step-row .pop-up-box .text-box span.or {
  display: block;
  color: #363b54;
  padding: 0 0 10px 0;
  height: 24px;
  font-family: InterUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363b54;
}

.tab-content input[type="checkbox"]:checked {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: #5dc2a6;
}

label.Image-label-content {
  margin: -12px !important;
}

.img-Content-styling {
  font-family: InterUI !important;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  color: #9297ae;
  margin-top: 30px !important;
  text-transform: uppercase;
}
.upload-file-styling-bonus {
  display: inline-block;
  background-color: #f9fafb;
  color: black;
  padding: 9px 0px 10px 44px;
  font-family: sans-serif;
  border-radius: 0.3rem;
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  align-items: center;
  border: 2px dotted #9297ae;
  margin-top: 7px;
}
img.img-upload-btn {
  height: 87px;
  color: #656a85;
}
.upload-btn-text {
  font-family: InterUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #656a85;
}
.imgae-Modal-styling {
  margin-top: -25px !important;
  margin-bottom: 0px;
  font-family: "InterUI" !important;
  font-size: 11px !important;
  display: flex;
  justify-content: space-between;
}

.upload-img-content {
  width: 150px;
  height: 150px;
  border-radius: 4px;
}

.bonus-placeholder-styling {
  margin-bottom: 17px;
  border-radius: 3px;
}
.Paste-image {
  margin-top: 30px;
  color: red;
  font-family: "InterUI";
  font-size: 14px;
  cursor: pointer;
}
p.bonus-upload-img-text {
  font-family: "InterUI";
  font-size: 14px;
  margin-top: 5px;
  color: #656a85;
}
.border-content-bonus {
  border: solid 1px #dcdee6;
  background-color: #fff;
}
input.border-content-bonus {
  background-color: #dcdee6 !important;
  border-radius: 3px;
}
textarea.border-content-bonus {
  background-color: #dcdee6;
  border-radius: 3px !important;
}
textarea.border-content-bonus:focus {
  border: none;
}

[type="text"]:focus,
textarea:focus,
.emoji-wysiwyg-editor:focus {
  /* border: 1px solid #9297ae !important; */
  color: #363b54;
  outline: none;
}
.main-form-div {
  margin: 0px 40px 0px 40px !important;
}

.card-margin-content {
  margin: 0px 40px 0px 40px !important;
}

.cke_browser_webkit .cke_contents {
  border-radius: 5px;
  border: none;
}

.img-upload-hide {
  height: 150px;
  width: 150px;
  text-align: end;
  margin-top: -18px;
  margin-bottom: 18px;
}

.img-upload-hide-img {
  height: 150px;
  width: 150px;
  border-radius: 4px;
}

.fa-times-circle {
  cursor: pointer;
  font-size: 17px;
  position: relative;
  top: 7px;
  left: 10px;
  background-color: #fff;
  border-radius: 50%;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid green;
  border-radius: 510%;
  outline: none;
  // box-shadow: 0 0 5px 0px green inset;
  vertical-align: middle;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
// input[type="radio"]:checked:before {
//   background: input[type= "radio" ] {
//     -webkit-appearance: none;
//     width: 20px;
//     height: 20px;
//     border: 1px solid darkgray;
//     border-radius: 50%;
//     outline: none;
//     box-shadow: 0 0 5px 0px green inset;
//   }
// }
input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: green;
}

[type="file"],
[type="checkbox"],
[type="radio"] {
  margin: 0 0 0 !important;
}

.select-site {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 4px;
}

.Red-browse {
  color: red;
}
.Img-url-paste {
  margin-bottom: 69px;
}
.points-error-message {
  // margin-top: 30px;
  color: #E61414 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  cursor: pointer;
  margin-top: 5px;
}
.points-error-message-text {
  vertical-align: middle;
}
.points-error-message-icon {
  height: 18px;
  width: 18px;
}
input.border-content-bonus {
  border-radius: 3px;
  box-shadow: inset 0 2px 2px 0 rgb(48 55 131 / 12%);
  border: solid 1px #e6e7f0;
  background-color: rgba(209, 211, 224, 0.12);
}
.multi-step-row .pop-up-box .text-box input[type="text"],
.multi-step-row .pop-up-box .text-box input[type="file"] {
  float: left;
  width: 100%;
  height: 44px;
  margin: 3px 0 0;
  padding: 10px 11px 12px 12px;
  border-radius: 3px;
  border: solid 1px #dcdee6;
  background-color: #ffffff;
  box-shadow: none;
  font-family: InterUI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #363b54;
  border-radius: 3px !important;
  box-shadow: inset 0 2px 2px 0 rgb(48 55 131 / 12%) !important;
  border: solid 1px #e6e7f0 !important;
  background-color: rgba(209, 211, 224, 0.12) !important;
}
.multi-step-row .pop-up-box .text-box textarea {
  float: left;
  width: 100%;
  height: 140px;
  // resize: none;
  // border-radius: 2px;
  border: 1px solid #e9eaed;
  box-shadow: none;
  color: #363b54;
  padding: 14px;
  box-sizing: border-box;
  font: 400 14px "InterUI", sans-serif;
  margin-bottom: 25px;
  border-radius: 3px !important;
  box-shadow: inset 0 2px 2px 0 rgb(48 55 131 / 12%) !important;
  border: solid 1px #e6e7f0 !important;
  background-color: rgba(209, 211, 224, 0.12) !important;
}
.numberOfPoints {
  border: 1px solid #e9eaed;
  box-shadow: none;
  color: #363b54;
  padding: 14px;
  box-sizing: border-box;
  font: 400 14px "InterUI", sans-serif;
  margin-bottom: 25px;
  border-radius: 3px !important;
  // box-shadow: inset 0 2px 2px 0 rgb(48 55 131 / 12%) !important;
  border: solid 1px #e6e7f0 !important;
  background-color: rgba(209, 211, 224, 0.12) !important;
}
input[type="radio"][data-v-7cfb1b28]:checked:before {
  background: #5dc2a6 !important;
}
input[type="radio"][data-v-7cfb1b28] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #5dc2a6 !important;
  border-radius: 510%;
  outline: none;
  vertical-align: middle;
}

// ::placeholder {
//   font-size: 10000px !important;
//   color: #c9cbda !important;
// }

// .vs__search::placeholder {
//   font-size: 14px !important;
//   color: #c9cbda !important;
// }

.bonus-placeholder .vs__search {
  font-size: 14px !important;
  color: #c9cbda !important;
  font-family: "InterUI", sans-serif;
  font-size: 14px !important;
}

.not-enough-points {
  border: 1px solid #E61414 !important;
  background-color: rgba(239, 93, 114, 0.10);
}

.available-points-to-display {
  color: #E61414 !important;
}
</style>
