<template>
  <div class="component-container">
    <div class="regone-head">
      <img class="regone-logo" :src="logo" alt="" />
    </div>
    <div style="clear: both"></div>
    <div class="regone-form">
      <div class="innerform">
        <div class="reg-topimg">
          <img :src="data.logo" alt="" />
        </div>
        <h3>{{ translations.title }}</h3>
        <h5>
          {{ translations.line }}
          <a :href="whyJoinUrl" target="_blank">{{ translations.here }}.</a>
        </h5>
        <form>
          <div class="inoutfield">
            <label>* {{ translations.firstName }}</label>
            <input type="text" name="fname" class="form-control" value="" v-model="user.first_name" />
          </div>
          <div class="inoutfield">
            <label>* {{ translations.lastName }}</label>
            <input type="text" name="lname" class="form-control" value="" v-model="user.surname" />
          </div>
          <div class="inoutfield">
            <label>* {{ translations.emailAddress }}</label>
            <input type="text" name="email" class="form-control" value="" v-model="email" @blur="checkDuplicateEmail" />
            <div class="form-error" id="email">{{ translations.invalidEmail }}</div>
          </div>
          <div class="inoutfield">
            <label>* {{ translations.mobile }}</label>
            <input
              id="regphone"
              type="tel"
              name="mobile"
              class="form-control"
              value=""
              v-model="phone_number"
              @blur="checkDuplicateNumber"
            />
            <div class="form-error" id="mobile">{{ translations.invalidMobileNumber }}</div>
          </div>
          <div v-if="data.sites_count > 0" class="inoutfield">
            <label>* {{ translations.site }}</label>
            <select class="form-control" v-model="user.site_id">
              <option value="0">{{ translations.select }}</option>
              <template v-for="site in sites">
                <option :value="site.id">{{ site.name }}</option>
              </template>
            </select>
          </div>

          <button class="btn-primary" :disabled="btnDisbaled" @click.prevent="registerUserLink">
            {{ translations.submitButtonText }}
          </button>

          <p>
            {{ translations.agreeTermsAndPrivacyPolicyText }}
            <a :href="appTermsUrl" target="_blank">{{ translations.terms }}</a>
            {{ translations.and }}
            <a :href="appPrivacyUrl" target="_blank">{{ translations.privacyPolicy }}</a
            >.
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import intlTelInput from "intl-tel-input";
import intlTelInputUtilsScript from "intl-tel-input/build/js/utils.js";
import "intl-tel-input/build/css/intlTelInput.css";
import Vue from "vue";

export default {
  props: ["data", "appPrivacyUrl", "appTermsUrl", "brandLogo", "whyJoinUrl", "downloadUrl"],
  data() {
    return {
      sites: [],
      user: {
        first_name: "",
        surname: "",
        email: "",
        phone_number: "",
        site_id: 0,
        customer_id: this.data.customer_id,
        token: this.data.token,
      },
      translations: this.data.formTranslations,
      email: "",
      phone_number: "",
      phoneCheck: false,
      duplicateCheck: false,
      emailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      duplicateNumberCheck: false,
      logo: this.brandLogo || "/images/cf-login-logo.png",
    };
  },
  methods: {
    fetchSites() {
      var self = this;
      axios
        .get("/dashboard/points/fetch-sites/" + this.data.customer_id)
        .then(function (response) {
          self.sites = response.data.sites;
          console.log(self.sites);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    registerUserLink() {
      const input = document.querySelector("#regphone");
      var iti = window.intlTelInputGlobals.getInstance(input);
      var number = iti.getNumber(intlTelInputUtils.numberFormat.E164);
      var self = this;

      this.user.email = this.email;
      this.user.phone_number = number;

      axios
        .post("/dashboard/points/register-user-link", {
          user: this.user,
        })
        .then(function (response) {
          if (response.data == "success") {
            location.replace(location.protocol + "//" + location.hostname + "/invite-link/success?customer_id=" + self.user.customer_id);
          } else {
            Vue.swal({
              title: "No customer found",
              confirmButtonColor: "#f55d73",
              confirmButtonText: "OK",
              html: "Whoops – it looks like that link is no longer active, please contact your manager to ask for a new one.",
              allowOutsideClick: false,
            });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    emailCheck() {
      if (!this.emailRegex.test(this.email)) {
        $("#email").show();
      } else {
        $("#email").hide();
      }
    },
    phoneNumberCheck() {
      const input = document.querySelector("#regphone");
      var iti = window.intlTelInputGlobals.getInstance(input);
      var isValid = iti.isValidNumber();
      if (!isValid) {
        $("#mobile").show();
        this.phoneCheck = false;
      } else {
        $("#mobile").hide();
        this.phoneCheck = true;
      }
    },
    checkDuplicateEmail() {
      this.duplicateCheck = false;
      var self = this;
      axios
        .post("/dashboard/points/check-duplicate-email", {
          email: this.email,
        })
        .then(function (response) {
          if (response.data == "duplicate") {
            self.duplicateCheck = true;

            Vue.swal({
              title: self.translations.alertTitleEmail,
              confirmButtonColor: "#f55d73",
              confirmButtonText: self.translations.alertOkButton,
              html: self.translations.alertText,
              allowOutsideClick: false,
            });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    checkDuplicateNumber() {
      this.duplicateNumberCheck = false;
      const input = document.querySelector("#regphone");
      var iti = window.intlTelInputGlobals.getInstance(input);
      var number = iti.getNumber(intlTelInputUtils.numberFormat.E164);
      var self = this;
      axios
        .post("/dashboard/points/check-duplicate-number", {
          number: number,
        })
        .then(function (response) {
          if (response.data == "duplicate") {
            self.duplicateNumberCheck = true;

            Vue.swal({
              title: self.translations.alertTitleMobile,
              confirmButtonColor: "#f55d73",
              confirmButtonText: self.translations.alertOkButton,
              html: self.translations.alertText,
              allowOutsideClick: false,
            });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
  },
  mounted() {
    const input = document.querySelector("#regphone");
    intlTelInput(input, {
      // any initialisation options go here
      utilsScript: intlTelInputUtilsScript,
      initialCountry: this.data.def_country,
    });
    if (this.data.sites_count > 0) {
      this.fetchSites();
    }
  },
  watch: {
    email: function (val) {
      this.emailCheck();
    },
    phone_number: function (val) {
      this.phoneNumberCheck();
    },
  },
  computed: {
    btnDisbaled() {
      if (
        this.user.first_name &&
        this.user.surname &&
        this.emailRegex.test(this.email) &&
        !this.duplicateCheck &&
        this.phoneCheck &&
        !this.duplicateNumberCheck
      ) {
        if (this.data.sites_count > 0) {
          if (this.user.site_id && this.user.site_id != 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else return true;
    },
  },
};
</script>
