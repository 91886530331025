<script>
export default {
    name: 'TarrifsIcon'
}
</script>

<template><svg width="22"
         height="16"
         viewBox="0 0 22 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g id="Coin icon">
            <circle id="Ellipse 11"
                    cx="6.5"
                    cy="6.50043"
                    r="5.5"
                    stroke="#6A6F8E" />
            <path id="Ellipse 12"
                  d="M9.57544 11.0106C10.9978 13.6946 14.3266 14.7173 17.0106 13.295C19.6946 11.8726 20.7173 8.54371 19.2949 5.85974C17.8726 3.17577 14.5437 2.15304 11.8598 3.5754"
                  stroke="#6A6F8E"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
        </g>
    </svg>
</template>