<script>
export default {
  name: "PencilIcon",
};
</script>

<template>
  <svg width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M0 14.4601V17.5001C0 17.7801 0.22 18.0001 0.5 18.0001H3.54C3.67 18.0001 3.8 17.9501 3.89 17.8501L14.81 6.94006L11.06 3.19006L0.15 14.1001C0.0500001 14.2001 0 14.3201 0 14.4601ZM17.71 4.04006C18.1 3.65006 18.1 3.02006 17.71 2.63006L15.37 0.290059C14.98 -0.0999414 14.35 -0.0999414 13.96 0.290059L12.13 2.12006L15.88 5.87006L17.71 4.04006Z"
          fill="#6A6F8E" />
  </svg>
</template>
