<template>
  <tariffs-table-view
    v-if="tariffs"
    :tariffs="tariffs"
    :isCareSector="isCareSector"
    :loading="loading"
    :milestone="milestone"
    :hasPermission="hasPermission"
    :paid="paid"
    @on-update="updateTariff"
  />
</template>

<script>
import axios from "axios";
import TariffsTableView from "./TariffsTableView.vue";

export default {
  name: "TariffsTableData",
  props: {
    milestone: { type: Number, default: 12 },
    hasPermission: { type: Boolean, default: false },
    isCareSector: { type: Boolean, default: false },
    paid: { type: Boolean, default: false },
  },
  components: {
    TariffsTableView,
  },

  data() {
    return {
      tariffs: [],
      loading: false,
    };
  },
  methods: {
    async getTariffs() {
      this.loading = true;

      try {
        const { data } = await axios.get("/point-tariffs");

        this.tariffs = data.data;

        this.loading = false;
      } catch (err) {
        Vue.swal({
          title: "Oops!",
          text: "Something went wrong.",
          confirmButtonColor: "#ec5168",
          allowOutsideClick: false,
        });
        
        this.loading = false;
      }
    },
    updateTariff(data) {
      const index = this.tariffs.findIndex((tariff) => tariff.id === data.id);

      if (index !== -1) {
        this.$set(this.tariffs, index, data);
      }
    },
    addTariff(data) {
      this.tariffs.push(data);
    },
  },
  async created() {
    this.getTariffs();
  },
};
</script>
