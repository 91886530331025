<template>
  <div>
    <!-- Use string interpolation to set the target dynamically and set triggers to 'click' -->
    <b-popover :target="'popover-' + rowId" triggers="click">
      <div class="popover-container">
        <div class="popover-item" @click="emitEditAction()">
          <icon style="float:left" name="pencil-filled"/> &nbsp;&nbsp;Edit
        </div>

        <div class="popover-item">
          <icon style="float:left" name="trash"/> &nbsp;&nbsp;&nbsp;Delete
        </div>
    </div>
    </b-popover>
  </div>
</template>

<script>
import { Icon } from "../../../app/components/Icon";

export default {
  components: { Icon },
  props: {
    rowId: {
      type: String,
      required: true // Set to true if the prop is required
    },
    parentEditAction: {
      type: Function,
      required: false
    }
  },
  methods: {
    emitEditAction() {
      console.log("Editing row" + this.rowId);
    }
  },
};
</script>

<style lang="scss" scoped>
.popover-item:hover {
  cursor: pointer;
  background-color: #55c2a7;
  color: #fff;
}


.popover-container {
  display: flex;
  flex-direction: column;
  /* Add any other styles you need for the popover container */
}

.popover-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  /* Add any other styles you need for the popover items */
}


.popover-content {
  position: absolute;
  background: #fff;
  border-radius: 6px;
  margin-top: 4px;
  padding: 20px;
  color: #333;
  min-width: 300px;
  text-align: left;
}

.popover {
    top: 0;
    left: 0;
    z-index: 1060;
    max-width: 276px;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem
}

.popover,.popover .arrow {
    position: absolute;
    display: block
}

.popover .arrow {
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
}

.popover .arrow:after,.popover .arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,.bs-popover-top>.arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=top]>.arrow:before,.bs-popover-top>.arrow:before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0,0,0,.25)
}

.bs-popover-auto[x-placement^=top]>.arrow:after,.bs-popover-top>.arrow:after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,.bs-popover-right>.arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow:before,.bs-popover-right>.arrow:before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0,0,0,.25)
}

.bs-popover-auto[x-placement^=right]>.arrow:after,.bs-popover-right>.arrow:after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,.bs-popover-bottom>.arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=bottom]>.arrow:before,.bs-popover-bottom>.arrow:before {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(0,0,0,.25)
}

.bs-popover-auto[x-placement^=bottom]>.arrow:after,.bs-popover-bottom>.arrow:after {
    top: 1px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header:before,.bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,.bs-popover-left>.arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow:before,.bs-popover-left>.arrow:before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0,0,0,.25)
}

.bs-popover-auto[x-placement^=left]>.arrow:after,.bs-popover-left>.arrow:after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #212529
}
</style>