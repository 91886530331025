<template>
  <div class="dropdown-container"
       ref="dropdown"
       :class="{'empty-input': applyRedBorder}">
    <div class="selected-option"
         @click="toggleDropdown"
         :style="{color: checkedItems.length === 0 ? '#cacaca' : '#3C3C3C'}">
      {{ displaySelectedItems }}
      <img :class="['arrow', { 'arrow-up': isOpen }]"
           src="/images/svg/dropdown_icon.svg"
           alt="Dropdown arrow">
    </div>

    <transition name="fade">
      <ul v-if="isOpen"
          class="options-list">
        <li v-if="searchable"
            :key="0">
          <input ref="filterSearchInput"
                 class="filter-search-input"
                 type="text"
                 v-model="searchKeyword"
                 placeholder="Enter name to filter" />
        </li>
        <li v-for="item in filteredOptions"
            :key="item.id">
          <div>
            <label>
              <input type="checkbox"
                     class="custom-checkbox"
                     v-model="checkedItems"
                     :value="item.id">
              <span class="checkmark"></span>
              {{ item.name }}
            </label>
          </div>
        </li>
      </ul>
    </transition>
  </div>
</template>
  
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Select items..."
    },
    isNotValid: {
      type: Boolean,
      default: false
    },
    clearSelected: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      checkedItems: [],
      applyRedBorder: false,
      searchKeyword: '',
      filteredOptions: this.options
    };
  },
  computed: {
    displaySelectedItems() {
      const firstItem = this.options.find(option => option.id === this.checkedItems[0]);
      if (this.checkedItems.length === 0) return this.placeholder;
      if (this.checkedItems.length === 1) return firstItem.name;
      return `${firstItem.name}, +${this.checkedItems.length - 1} others`;
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$nextTick(() => {
          this.$refs.filterSearchInput.focus();
        });
      }
    },
    handleOutsideClick(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
    filterOptions() {
      const query = this.searchKeyword.toLowerCase().trim();
      if (!query) {
        this.filteredOptions = this.options;
      } else {
        this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(query));
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    isNotValid(newVal) {
      this.applyRedBorder = newVal && this.checkedItems?.length === 0;
    },
    checkedItems(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.checkedItems = newVal;
    },
    searchKeyword() {
      this.filterOptions();
    },
    options() {
      this.filterOptions();
    },
  }
}
</script>
  
  
<style lang="scss" scoped>
/* Existing Styles */
.disabled {
  opacity: 0.5 !important;
  /* Or any other styles to indicate a disabled state */
  cursor: not-allowed !important;
}

.empty-input {
  border: 1px solid #E61414 !important;
}

.dropdown-container {
  width: 100%;
  font-family: "Poppins", sans-serif;
  color: #989898;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #dcdee6;
  border-radius: 12px;
  margin-bottom: 10px;
  /* Gap between input and dropdown */
}

.selected-option {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: none;
  background-color: #fff;
  border-color: #dcdee6;
  padding-right: 10px;
  padding-left: 10px;
}

.options-list {
  line-height: 2;
  border-radius: 12px;
  scrollbar-width: thin;
  position: absolute;
  width: 100%;
  z-index: 2;
  background-color: #fff;
  top: 50px;
  right: 0;
  /* Adjust to height and for the gap */
  height: auto;
  max-height: 320px;
  /* Adjust for taller dropdown */
  overflow-y: auto;
  border: 1px solid #dcdee6;
  color: #3C3C3C;
  transition: transform 0.2s;
  padding: 5px;
}

.options-list li {
  padding: 10px;
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f7f7f7;
}

.arrow {
  width: 24px;
  height: 24px;
  transition: transform 0.2s;
  margin-left: auto;
  transform: rotate(180deg);
}

.arrow-up {
  transform: rotate(360deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* New Styles for Checkbox */
label {
  font-family: "Poppins", sans-serif !important;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  font-size: 14px;
  color: #545454;
  line-height: 24px;
  display: block;
  margin-bottom: 12px;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 2px solid #CCCCCC;
  border-radius: 6px;
}

.custom-checkbox:checked~.checkmark {
  background-image: url('/images/svg/enabled_checkbox.svg');
  border: none;
  background-position: center;
  background-size: 26px 26px;
}

.custom-checkbox {
  display: none;
}
</style>
  