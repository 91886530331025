<script>
export default {
  name: "PauseIcon",
};
</script>

<template>
  <svg width="22"
       height="22"
       viewBox="0 0 22 22"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M8.60002 14.5999V7.3999M13.4 14.5999V7.3999M11 20.5999C5.69809 20.5999 1.40002 16.3018 1.40002 10.9999C1.40002 5.69797 5.69809 1.3999 11 1.3999C16.302 1.3999 20.6 5.69797 20.6 10.9999C20.6 16.3018 16.302 20.5999 11 20.5999Z"
          stroke="#6A6F8E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
  </svg>
</template>
