<template>
    <section :style="cssVariables">
        <div class="questions-header"></div>
        <questions-table ref="questions-table"
                         :currentuser="currentuser"
                         :microsite="microsite" />
    </section>
</template>

<script>
import QuestionsTable from "../components/QuestionsTable";
import { cssVariables } from "../../../lib/css-variables";

export default {
    name: "ScreeningQuestions",
    props: {
        currentuser: { type: Object, required: true },
        microsite: { type: Object, required: true }
    },
    components: { QuestionsTable },
    created() {
        this.cssVariables = cssVariables();
    },
};
</script>

<style scoped lang="scss">
@import "./ScreeningQuestions.scss";
</style>