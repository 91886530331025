<script>
export default {
  name: "PlayIcon",
};
</script>

<template>
  <svg width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3744 10.7588L12.8288 9.92078L12.8148 9.92985L12.8012 9.93938L13.3744 10.7588ZM13.3744 9.46867L12.7814 10.2739L12.826 10.3068L12.874 10.3345L13.3744 9.46867ZM8.20366 5.66122L8.7966 4.85597L8.76839 4.8352L8.73879 4.81644L8.20366 5.66122ZM7.11966 6.22991L8.11965 6.22701L8.11963 6.21826L8.11945 6.2095L7.11966 6.22991ZM7.14134 13.709L6.14134 13.7119L6.14142 13.7402L6.14311 13.7684L7.14134 13.709ZM8.26509 14.3323L8.7493 15.2072L8.79522 15.1818L8.83822 15.1517L8.26509 14.3323ZM10 18C5.58172 18 2 14.4183 2 10H0C0 15.5228 4.47715 20 10 20V18ZM18 10C18 14.4183 14.4183 18 10 18V20C15.5228 20 20 15.5228 20 10H18ZM10 2C14.4183 2 18 5.58172 18 10H20C20 4.47715 15.5228 0 10 0V2ZM10 0C4.47715 0 0 4.47715 0 10H2C2 5.58172 5.58172 2 10 2V0ZM13.9199 11.5969C14.3323 11.3285 14.8704 10.8393 14.8694 10.0938C14.8683 9.32582 14.3021 8.84987 13.8747 8.60286L12.874 10.3345C12.9677 10.3886 12.9787 10.4133 12.9586 10.3878C12.9315 10.3532 12.8696 10.2512 12.8694 10.0965C12.8692 9.94545 12.928 9.85342 12.9423 9.83416C12.952 9.82101 12.9295 9.85521 12.8288 9.92078L13.9199 11.5969ZM13.9673 8.66343L8.7966 4.85597L7.61072 6.46646L12.7814 10.2739L13.9673 8.66343ZM8.73879 4.81644C8.28604 4.52965 7.64347 4.36117 7.03218 4.6466C6.36565 4.95781 6.10714 5.62689 6.11987 6.25032L8.11945 6.2095C8.11849 6.16275 8.12852 6.19137 8.09383 6.25775C8.05523 6.33159 7.98183 6.41046 7.87832 6.45879C7.67864 6.55202 7.5853 6.45326 7.66854 6.50599L8.73879 4.81644ZM6.11966 6.23281L6.14134 13.7119L8.14133 13.7061L8.11965 6.22701L6.11966 6.23281ZM6.14311 13.7684C6.17339 14.2771 6.35111 14.9731 7.00854 15.3242C7.65564 15.6698 8.32492 15.4421 8.7493 15.2072L7.78087 13.4573C7.69245 13.5063 7.66333 13.5057 7.68777 13.5024C7.72022 13.4979 7.82562 13.4933 7.95074 13.5601C8.07752 13.6278 8.13483 13.7209 8.15127 13.7559C8.16341 13.7818 8.14603 13.758 8.13957 13.6496L6.14311 13.7684ZM8.83822 15.1517L13.9475 11.5783L12.8012 9.93938L7.69195 13.5128L8.83822 15.1517Z"
          fill="#6A6F8E" />
  </svg>
</template>
