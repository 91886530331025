<template>
    <fieldset>
        <div class="main-form-div job-table">
            <h2 class="step-heading">{{ titleWithSubtitle }}</h2>

            <!-- Conditional care sector question -->
            <div v-if="brand.sector === 'care'"
                 class="job-row">
                <label class="job-label"
                       for="Question_1">
                    QUESTION 1: <span>*</span>
                </label>
                <input type="text"
                       id="Question_1"
                       v-model="form.Question_1"
                       :class="{ 'is-invalid': !isFieldValid(form.Question_1) }"
                       placeholder="New to care?"
                       disabled />
                <span v-if="!isFieldValid(form.carefriends_question)"
                      class="error-message">
                    "Question 1" is required.
                </span>
            </div>

            <div v-for="(question, index) in questions"
                 :key="index"
                 class="job-row">
                <label class="job-label"
                       for="Question_2">
                    QUESTION 2: (OPTIONAL) <span>*</span>
                </label>
                <single-select v-model="form.Question_2"
                               :options="question2"
                               placeholder="Driver?"
                               :class="{ 'is-invalid': !isFieldValid(form.Question_2) }" />
                <span v-if="!isFieldValid(form.Question_2)"
                      class="error-message">
                    "Question 2" is required.
                </span>
            </div>
            <div class="job-row">
                <label class="job-label"
                       for="Question_3">
                    QUESTION 3: (OPTIONAL) <span>*</span>
                </label>
                <single-select v-model="form.Question_3"
                               :options="question3"
                               placeholder="Right to work?"
                               :class="{ 'is-invalid': !isFieldValid(form.Question_3) }" />
                <span v-if="!isFieldValid(form.Question_3)"
                      class="error-message">
                    "Question 3" is required.
                </span>
            </div>
        </div>
    </fieldset>
</template>

<script>
import SingleSelect from '../../SingleSelect/SingleSelect.vue';

export default {
    name: 'Step4',
    components: { SingleSelect },
    props: {
        form: {
            type: Object,
            default: () => ({
                carefriends_question: '',
                question_type_1: 0,
                question_type_2: 0
            })
        },
        brand: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: 'Job Details'
        }
    },
    computed: {
        titleWithSubtitle() {
            return `${this.title} - Screening Questions`;
        },
        questions() {
            return [
                { model: 'question_type_1', options: this.driverQuestions, placeholder: '' },
                { model: 'question_type_2', options: this.writeToWorkQuestions, placeholder: '' }
            ];
        },
        checkValidation() {
            return ['question_type_1', 'question_type_2'].every((field) => this.isFieldValid(this.form[field]));
        }
    },
    methods: {
        isFieldValid(field) {
            return field !== '' && field !== null;
        },
        inputClass(field) {
            return { 'is-invalid': !this.isFieldValid(field) };
        },
    },
    watch: {
        form: {
            deep: true,
            handler() {
                this.$emit('update-step-validity', this.checkValidation);
            }
        }
    },
    mounted() {
        this.$emit('update-step-validity', true);
    }
};
</script>

<style scoped lang="scss">
@import "./Steps.scss";
</style>