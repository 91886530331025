<template>
    <div>
        <div class="questions-table">
            <div class="question-row question-row--head">
                <div class="question-column question-column--left-align">Label</div>
                <div class="question-column question-column--left-align">Type</div>
                <div class="question-column question-column--text-align">Text</div>
                <div class="question-column question-column--tag-align"></div>
                <div class="question-column question-column--tag-align">Tags</div>
                <div class="question-column question-column--left-align question-column--actions"></div>
            </div>

            <div v-if="questions.length > 0">
                <div class="question-row"
                     v-for="question in questions"
                     :key="question.id">
                    <div class="question-column question-column--left-align">{{ question.label }}</div>
                    <div class="question-column question-column--left-align">{{ formattedFieldType(question.field_type)
                        }}</div>
                    <div class="question-column question-column--text-align">{{ processQuestion(question.text) }}
                    </div>
                    <div class="question-column question-column--tag-align">
                        <span v-if="question.auto_reject_enabled">
                            <b-tooltip :target="'auto-reject-' + question.id"
                                       triggers="hover"
                                       placement="topleft">
                                Auto-reject is turned on for this question
                            </b-tooltip>
                            <icon :name="'personx'"
                                  :id="'auto-reject-' + question.id"
                                  aria-label="Auto-reject is turned on" />
                        </span>
                    </div>
                    <div class="question-column question-column--tag-align">
                        <span v-if="question.question_state !== ''"
                              class="question-status question-status--active">
                            {{ processStatus(question.question_state) }}
                        </span>
                    </div>
                    <div class="question-column question-column--left-align question-column--actions">
                        <question-actions v-if="question.question_state !== 'required' && checkAccountSettings()"
                                          ref="question-actions"
                                          :question="question"
                                          :currentuser="currentuser"
                                          @on-update="onUpdateQuestion" />
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="question-row">
                    <div class="question-column question-column--tag-align"
                         style="max-width: 100%; text-align: center;">
                        0 records
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div v-show="loading"
                 class="loading-overlay">
                <span>Loading...</span>
            </div>
        </transition>
    </div>
</template>

<script>
import QuestionActions from "../QuestionActions";
import { Icon } from "../../../../components/Icon";

export default {
    name: "QuestionsTableView",
    props: {
        questions: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        currentuser: { type: Object, required: true }
    },
    components: {
        Icon,
        QuestionActions
    },
    methods: {
        onUpdateQuestion(data) {
            this.$emit('on-update', data);
        },
        processQuestion(text) {
            if (typeof text !== 'string') {
                return '';
            }

            const words = text.split(' ');
            if (words.length > 15) {
                return words.slice(0, 15).join(' ') + '...';
            }

            return text;
        },
        formattedFieldType(text) {
            return text === 'YES / NO' ? 'Y/N' : text;
        },
        processStatus(state) {
            if (state !== '') {
                return state.toUpperCase();
            }

            return '';
        },
        checkAccountSettings() {
            if (this.currentuser.account_check === 1) {
                return true;
            }

            return false;
        }
    }
};
</script>

<style scoped lang="scss">
@import "./QuestionsTable.scss";
</style>