<template>
    <templates-table-view ref="templates-table-view"
                          v-if="templates"
                          :templates="templates"
                          :loading="loading"
                          :currentuser="currentuser"
                          @on-update="updateTemplate" />
</template>

<script>
import TemplatesTableView from "./TemplatesTableView.vue";

export default {
    props: {
        currentuser: { type: Object, required: true }
    },
    name: "TemplatesTableData",
    components: {
        TemplatesTableView,
    },
    data() {
        return {
            templates: [],
            loading: true,
        };
    },
    methods: {
        async getTemplates() {
            this.loading = true;

            try {
                const { data } = await axios.get("/templates");
                this.templates = data.data;
                this.loading = false;
            } catch (err) {
                Vue.swal({
                    title: "Oops!",
                    text: "Something went wrong.",
                    confirmButtonColor: "#ec5168",
                    allowOutsideClick: false,
                });
                this.loading = false;
            }
        },
        updateTemplate(data) {
            const index = this.templates.findIndex((template) => (template.name === data.name && template.customer_id === data.customer_id));

            if (index !== -1) {
                this.$set(this.templates, index, data);
            }
        }
    },
    async created() {
        await this.getTemplates();
    },
};
</script>