<template>
    <div>
        <img v-if="canPinJob"
             :id="'p-'+index"
             :src="job.pin_img"
             style="cursor: pointer; padding: 4px;"
             @click="pinnedJobsData(job.id)"
             v-b-modal="'pin-' + job.id">
        <img v-else
             :src="job.pin_img"
             style="padding: 4px;">

        <b-tooltip :target="'p-'+index"
                   triggers="hover">{{ job.pinned ? "Unpin job" : "Pin job" }}</b-tooltip>

        <b-modal class="tab-modal pin-modal"
                 :id="'pin-' + job.id"
                 :modal-class="loading ? 'modal--loading' : ''"
                 size="lg"
                 hide-header-close
                 no-close-on-backdrop>
            <h2 v-if="job_check == 1 || job_check == 2">Pin job?</h2>
            <h2 v-else-if="job_check == 3">Unpin job?</h2>
            <div class="grid-y">
                <div class="grid-x input-box-2">
                    <div class="cell auto input-box">
                        <p v-if="job_check == 1">Are you sure you want to pin this job? When pinned, this job will
                            be prioritised to appear on the home screen of the mobile app for your staff at this site.
                        </p>
                        <p v-else-if="job_check == 2">Are you sure you want to pin this job? This will replace
                            the currently pinned job on the mobile app home screen.</p>
                        <p v-else-if="job_check == 3">Are you sure you want to unpin this job? It will no longer be
                            prioritised to appear on the home screen of the mobile app.</p>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <button class="cancel-button"
                        @click="$bvModal.hide('pin-' + job.id)">Cancel</button>
                <button class="process-button"
                        @click="handlePinJob">Confirm</button>

            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'PinJob',
    props: {
        currentuser: { type: Object, required: true },
        job: { type: Object, required: true },
        index: { type: Number, default: 0 }
    },
    data() {
        return {
            job_check: '',
            loading: false
        };
    },
    methods: {
        handlePinJob() {
            this.loading = true;
            axios.post("/dashboard/jobs/pin", {
                id: this.job.id,
                pinned: this.job.pinned,
                job_check: this.job_check
            })
                .then(response => {
                    this.$bvModal.hide('pin-' + this.job.id);
                    this.showSuccessToast(response.data);
                    this.$emit('on-job-pinned');
                })
                .catch(error => {
                    console.error("handlePinJob Error", error);
                })
                .finally(() => {
                    this.loading = false
                });
        },
        pinnedJobsData(id) {
            this.job_check = '';
            axios.post("/dashboard/jobs/pinned-jobs-data", { id })
                .then(response => {
                    this.job_check = response.data;
                })
                .catch(error => {
                    console.error("pinnedJobsData Error:", error);
                });
        },
        showSuccessToast(message) {
            Vue.swal({
                html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Job ' + message,
                confirmButtonColor: "#5dc2a6",
                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: {
                    container: "candidate-toast"
                },
                showClass: {
                    popup: "animated fadeInLeft fast",
                    icon: "animated heartBeat delay-1s"
                },
                hideClass: {
                    popup: "animated fadeOutLeft slow"
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
            });
        }
    },
    computed: {
        canPinJob() {
            return this.currentuser ? this.currentuser.job_check : false;
        }
    }
};
</script>

<style scoped lang="scss">
@import "./PinJob.scss";
</style>