/**
 * Append tab id to the pagination links so that on clicking the link
 * the page is switched to the correct tab.
 */
$(document).ready(function () {
    const body = $('body');
    if (body.hasClass('points-page')) {
        $("#leaderboard .pagination li a").each(function(){
            const link = $(this).attr('href');
            $(this).attr('href', link + '#leaderboard');
        });
    }
    //User Account - sites
    if (body.hasClass('user-account-page')) {
        $("#sites .pagination li a").each(function(){
            const link = $(this).attr('href');
            $(this).attr('href', link + '#sites');
        });
    }
    //User Account - Dashboard users
    if (body.hasClass('user-account-page')) {
        $("#dashboard-users .pagination li a").each(function(){
            const link = $(this).attr('href');
            $(this).attr('href', link + '#dashboard-users');
        });
    }
    //User Account - Mobile app users
    if (body.hasClass('user-account-page')) {
        $("#app-users .pagination li a").each(function(){
            const link = $(this).attr('href');
            $(this).attr('href', link + '#app-users');
        });
    }
});
