<template>
    <section id="job-form"
             class="job-form multi-step-row multi-step-p">
        <div class="pop-up-box pop-up-box-styling">
            <div class="top-check-row">
                <ul>
                    <li v-for="n in totalSteps"
                        :key="n"
                        :class="{ selected: isActive(n), completed: isCompleted(n) }">
                        <a href="javascript:void(0)">{{ n }}</a>
                    </li>
                </ul>
            </div>

            <div class="job-form">
                <div :class="step < 5 ? 'job-leftside' : ''">
                    <div class="text-box"
                         id="text-box">
                        <div v-if="loading"
                             class="question-loading">
                            <img src="/images/loading-circle.gif" />
                        </div>
                        <Step1 v-if="step === 1"
                               :title="title"
                               :sites="sites"
                               :form="form"
                               @update-step-validity="handleStepValidation(1, $event)" />

                        <Step2 v-if="step === 2"
                               :title="title"
                               :form="form"
                               :currency="currency"
                               @update-step-validity="handleStepValidation(2, $event)" />

                        <Step3 v-if="step === 3"
                               :title="title"
                               :form="form"
                               @update-step-validity="handleStepValidation(3, $event)" />

                        <Step4 v-if="step === 4"
                               :title="title"
                               :form="form"
                               :brand="brand"
                               :currentuser="currentuser"
                               @update-step-validity="handleStepValidation(4, $event)" />

                        <Step5 v-if="step === 5"
                               :title="title"
                               :form="form"
                               :pusers="pusers"
                               :currentuser="currentuser"
                               @update-step-validity="handleStepValidation(5, $event)" />
                    </div>
                </div>
                <div v-if="step < 5"
                     class="job-rightside">
                    <job-preview :form="form"
                                 :currentuser="currentuser"
                                 :currency="currency" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import JobPreview from './JobPreview.vue';
import Step1 from './Steps/Step1.vue';
import Step2 from './Steps/Step2.vue';
import Step3 from './Steps/Step3.vue';
import Step4 from './Steps/Step4.vue';
import Step5 from './Steps/Step5.vue';

export default {
    name: 'JobForm',
    components: {
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        JobPreview
    },
    props: {
        job: {
            type: Object,
            default: () => ({})
        },
        formType: {
            type: String,
            default: 'add'
        },
        sites: {
            type: Array,
            default: () => []
        },
        pusers: {
            type: Array,
            default: () => []
        },
        currency: {
            type: String,
            required: true
        },
        brand: {
            type: Object,
            required: true
        },
        currentuser: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        currentStep: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            loading: false,
            totalSteps: 5,
            step: this.currentStep,
            form: this.initializeForm(),
            driverQuestions: [],
            writeToWorkQuestions: []
        };
    },
    computed: {
        title() {
            return (this.formType === 'add') ? "Add Job" : "Edit Job";
        }
    },
    methods: {
        initializeForm() {
            return {
                Job_Title: "",
                Job_Reference: "",
                site_id: -1,
                Location: "",
                Hours: "",
                RateMin: 0,
                Description: "",
                Video_Link: "",
                Notification_Emails: "",
                Notification_Emails_selected: [],
                hr_notification_emails: "",
                hr_notification_emails_selected: [],
                rates_of_pay: "",
                RateMax: 0,
                show_car_query: 0,
                JobType: "",
                others_jobtype: "",
                right_to_work_query_enabled: 0,
                auto_rejection_enabled: 0,
                Image: "",
                carefriends_question: "New to care?",
                question_type_1: 0,
                question_type_2: 0
            };
        },
        resetForm() {
            this.form = this.initializeForm();
        },
        setStep(step) {
            this.step = step;
        },
        isActive(step) {
            return this.step === step;
        },
        isCompleted(step) {
            return step < this.step;
        },
        submitForm() {
            this.$emit('on-submit', this.form);
        },
        handleStepValidation(step, isValid) {
            this.$emit('update-step-validity', { step, isValid });
        },
        async getUpdatedQuestions() {
            this.loading = true;
            var self = this;
            await axios.get('/dashboard/account/get-updated-jobs-questions/', {
                params: {
                    jobId: self.job.id
                }
            })
                .then(response => {
                    self.loading = false;
                    self.driverQuestions = response.data.driverQuestions;
                    self.writeToWorkQuestions = response.data.writeToWorkQuestions;
                    self.form.question_type_1 = response.data.question_type_1;
                    self.form.question_type_2 = response.data.question_type_2;
                    self.managementQuestions();
                })
                .catch(error => {
                    self.loading = false;
                    console.log(error);
                });
        },
        managementQuestions() {
            const noneOption = { id: 0, name: 'None' };
            this.driverQuestions = [noneOption, ...Object.values(this.driverQuestions)];
            this.writeToWorkQuestions = [noneOption, ...Object.values(this.writeToWorkQuestions)];
        },
        prepareForm() {
            if (this.job) {
                if (!this.job.site_id) {
                    this.job.site_id = this.form.site_id;
                };

                this.form = { ...this.form, ...this.job };
            }
        }
    },
    watch: {
        currentStep(newStep) {
            this.setStep(newStep);
        }
    },
    async created() {
        this.prepareForm();
        this.getUpdatedQuestions();
    }
};
</script>

<style scoped lang="scss">
@import "./JobForm.scss";
</style>