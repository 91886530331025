<template>
  <div>
    <div id="menu"
         class="cell auto magic_flag"
         v-if="magic_flag">
      <div class="megic-flag-left">
        <h3 class="Unsaved-updates">Unsaved changes</h3>
      </div>
      <div class="megic-flag-right1">
        <button id="top_discard_btn"
                class="button btn_lg btn orange_btn"
                v-if="magic_flag"
                @click="undoCheck = true"
                v-b-modal.model_undo>
          Discard
        </button>

        <input type="button"
               name="next"
               class="button btn_lg btn pink_btn"
               value="Save"
               @click="update()"
               :disabled="btnEditCompany" />
      </div>
    </div>
    <section class="tabs-section input-max-520 abc">
      <div>
        <h3 class="org-heading">
          <strong>My Organisation</strong>
        </h3>
        <div class="tabs-row"
             id="sec-company">
          <section v-if="canManageCompanyDetails"
                   class="bg-white">
            <div class="input-box"
                 style="margin-bottom: 0px">
              <label>Company name</label>
              <input type="text"
                     placeholder="Care friend"
                     v-model="name"
                     required
                     @input="flagon" />
            </div>

            <company-logo-component :customer="customer"
                                    :childComponant="childComponant"
                                    design="btn-upload"></company-logo-component>

            <div class="clearfix"></div>

            <div class="clearfix"></div>

            <div class="input-box">
              <label style="padding-bottom: 22px">Login URL</label>
              <span class="point-text">{{ subdomain }}.{{ murl }}</span>
            </div>

            <div class="input-box">
              <label style="padding-bottom: 22px">Total staff</label>
              <span class="point-text">{{ customer.total_employees }}</span>
            </div>
          </section>

          <section v-if="canManageCompanyDetails"
                   class="bg-white">
            <p class="dark">Contact details</p>

            <div class="input-box">
              <label>Address line 1</label>
              <input type="text"
                     class
                     placeholder="Address line 1"
                     v-model="address_line_1"
                     @input="flagon"
                     required />
            </div>

            <div class="input-box">
              <label>Address line 2</label>
              <input type="text"
                     class
                     placeholder="Address line 2"
                     v-model="address_line_2"
                     @input="flagon" />
            </div>

            <div class="input-box-3">
              <div class="input-box"
                   style="width: 250px; height: 66px; margin: 0px 20px 0px 0px; float: left">
                <label>Town/City</label>
                <input type="text"
                       placeholder="Town/City"
                       v-model="city"
                       required
                       @input="flagon" />
              </div>

              <div class="input-box"
                   style="width: 250px; height: 66px; margin: 0px 0px 20px 0px; float: left">
                <label>Postcode</label>
                <input type="text"
                       placeholder="Postcode"
                       @input="flagon"
                       v-model="postcode"
                       required />
              </div>
            </div>

            <div class="rectangleline"></div>

            <div class="input-box">
              <label>Website</label>
              <input type="text"
                     class
                     placeholder="Website"
                     @input="flagon"
                     v-model="website" />
            </div>

            <div class="input-box">
              <label>Email</label>
              <input type="text"
                     class
                     placeholder="Email"
                     @input="flagon"
                     v-model="contact_email" />
            </div>

            <div class="input-box">
              <label>Phone</label>
              <input type="text"
                     class
                     placeholder="Phone"
                     @input="flagon"
                     v-model="contact_number" />

              <div v-if="errors.length">
                <ul>
                  <li style="color: #cc4b37"
                      v-for="(error, index) in errors"
                      :key="index">{{ error }}</li>
                </ul>
              </div>
            </div>
          </section>

          <section class="bg-white-cab"
                   id="portal-setting">
            <p class="dark"
               style="text-transform: none; padding-bottom: 10px">Portal settings</p>
            <div class="clearfix"
                 style="margin-bottom: -2px"></div>
            <div class="input-box">
              <label class="primary-userlabel">
                Primary User
                <span id="rec_res_img"
                      class="IconInfoRoundIdle">
                  <img src="/images/icon-info-round-idle@2x.png"
                       alt="img" />
                </span>

                <b-tooltip target="rec_res_img"
                           triggers="hover"
                           placement="righttop">
                  Please select the portal user.
                </b-tooltip>
              </label>

              <v-select v-model="primary_user"
                        label="name"
                        :options="portalUsers"
                        :reduce="referrer => referrer.id"
                        :change="onChange()"
                        :disabled="!canManageCompanyDetails"
                        @input="flagon"></v-select>
            </div>
            <div class="rectangleline"></div>
            <div class="input-box">
              <default-job-logo-component :customer="customer"
                                          :default-job-logo-dimenstions="defaultJobLogoDimenstions"
                                          :canManageCompanyDetails="canManageCompanyDetails"
                                          design="btn-upload">
              </default-job-logo-component>
            </div>
          </section>

          <section v-if="!pointTariffsFeatureFlag"
                   class="bg-white">
            <p class="dark">Referral points</p>

            <div class="input-box-2"
                 style="width: 935px; max-width: 935px">
              <div class="input-box">
                <label>Share</label>

                <span class="point-text"
                      v-if="points.one_job_share > 1">{{ points.one_job_share }} points</span>
                <span class="point-text"
                      v-else>{{ points.one_job_share }} point</span>
              </div>

              <div class="input-box">
                <label>Expression of interest</label>

                <span class="point-text"
                      v-if="points.one_job_application > 1">{{ points.one_job_application }} points</span>
                <span class="point-text"
                      v-else>{{ points.one_job_application }} point</span>
              </div>
            </div>

            <div class="input-box-2"
                 style="width: 935px; max-width: 935px">
              <div class="input-box">
                <label>Successful interview</label>

                <span class="point-text"
                      v-if="points.interview_attended > 1">{{ points.interview_attended }} points</span>
                <span class="point-text"
                      v-else>{{ points.interview_attended }} point</span>
              </div>

              <div class="input-box">
                <label>Started work</label>

                <span class="point-text"
                      v-if="points.hired > 1">{{ points.hired }} points</span>
                <span class="point-text"
                      v-else>{{ points.hired }} point</span>
              </div>
            </div>

            <div class="input-box-2"
                 style="width: 935px; max-width: 935px">
              <div class="input-box">
                <label>In post {{ customer.milestone }} months</label>

                <span class="point-text"
                      v-if="points.employed_12_months > 1">{{ points.employed_12_months }} points</span>
                <span class="point-text"
                      v-else>{{ points.employed_12_months }} point</span>
              </div>

              <template v-if="isCareSector">
                <div class="input-box">
                  <label>New to care bonus</label>
                  <span class="point-text"
                        v-if="points.new_to_care > 1">{{ points.new_to_care }} points</span>
                  <span class="point-text"
                        v-else>{{ points.new_to_care }} point</span>
                </div>
              </template>
            </div>

            <div style="clear: both"></div>

            <p class="to-request-a-change">
              To request a change to your referral points level, please get in touch via the chat icon
            </p>
          </section>
        </div>
      </div>

      <div class="clearfix"></div>
      <div v-if="canManageCompanyDetails"
           class="bottom-line"></div>

      <div v-if="canManageCompanyDetails"
           class="cell auto popup-button-org">
        <button v-if="magic_flag"
                class="button btn_lg btn orange_btn"
                style="float: left; height: 52px; width: 120px"
                @click="undoCheck = true"
                v-b-modal.model_undo>
          Discard
        </button>

        <input type="button"
               name="next"
               class="button btn_lg btn pink_btn"
               value="Save"
               style="height: 52px; width: 120px"
               :disabled="btnEditCompany"
               @click="update()" />
      </div>

      <b-modal v-if="undoCheck"
               class="tab-modal"
               id="model_undo"
               size="lg">
        <div class="grid-y">
          <div class="grid-x input-box-2">
            <div class="cell auto input-box">
              <div class="discard-header4">
                <h3 class="discard-header-title">Discard all unsaved changes?</h3>
              </div>

              <p class="if-you-discard-chang">
                If you discard changes, you’ll delete any edits you made since you last saved.
              </p>
            </div>
          </div>
        </div>

        <div class="cell auto popup-button">
          <input class="button btn_lg btn pink_btn"
                 type="submit"
                 value="Discard changes"
                 style="float: right; margin-right: -13px; margin-bottom: 13px; margin-left: 7px"
                 @click="reset" />

          <input type="button"
                 class="button btn_lg btn orange_btn anon-can"
                 value="Continue editing"
                 @click="continueEdit" />
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  props: [
    "advancedAdmin",
    "customer",
    "primaryUser",
    "points",
    "currentUser",
    "isCareSector",
    "pointTariffsFeatureFlag",
    "defaultJobLogoDimenstions"
  ],
  data() {
    return {
      childComponant: new Vue(),

      // moved these values outside data array due to reactivity issues.
      address_line_1: this.customer.address_line_1,
      id: this.customer.id,
      name: this.customer.name,
      address_line_1: this.customer.address_line_1,
      address_line_2: this.customer.address_line_2,
      city: this.customer.city,
      postcode: this.customer.postcode,
      contact_number: this.customer.contact_number,
      contact_email: this.customer.contact_email,
      website: this.customer.website,
      subdomain: this.customer.subdomain,
      logo: this.customer.logo,
      default_job_logo: this.customer.default_job_logo,
      registration_code: this.customer.registration_code,
      murl: "carefriends.co.uk",
      primaryUserName: this.primaryUser,
      primary_user: this.primary_user,
      portalUsers: [],
      magic_flag: false,
      undoCheck: false,
      errors: [],
    };
  },
  methods: {
    update(e) {
      this.errors = [];

      if (!this.contact_number) {
        this.errors.push("Phone required.");
        e.preventDefault();
        return;
      }

      let data = {
        id: this.id,
        name: this.name,
        address_line_1: this.address_line_1,
        address_line_2: this.address_line_2,
        city: this.city,
        postcode: this.postcode,
        contact_number: this.contact_number,
        contact_email: this.contact_email,
        website: this.website,
        subdomain: this.subdomain,
        logo: this.logo,
        default_job_logo: this.default_job_logo,
        registration_code: this.registration_code,
        primary_user: this.primary_user,
      };

      Vue.swal({
        title: "Updating...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });

      var self = this;

      axios
        .post("/dashboard/account", data, {})
        .then(function (response) {
          self.magic_flag = false;
          localStorage.removeItem("checkdiscard");
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Portal settings updated',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "points-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#edit-company",
          });
        })
        .catch(function (error) {
          self.message = "Error!";
        });
    },
    getMicrosite() {
      var self = this;

      axios
        .get("/get-microsite-data/" + this.customer.microsite_id)
        .then(function (response) {
          var microsite = response.data;
          self.murl = microsite.base_url;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkboxToggle() {
      this.magic_flag = true;
    },
    continueEdit() {
      this.$bvModal.hide("model_undo");
    },
    reset() {
      this.undoCheck = false;
      this.address_line_1 = this.customer.address_line_1 ? this.customer.address_line_1 : "";
      this.name = this.customer.name ? this.customer.name : "";

      this.address_line_2 = this.customer.address_line_2 ? this.customer.address_line_2 : "";
      this.city = this.customer.city ? this.customer.city : "";
      this.postcode = this.customer.postcode ? this.customer.postcode : "";
      this.contact_number = this.customer.contact_number ? this.customer.contact_number : "";
      this.contact_email = this.customer.contact_email ? this.customer.contact_email : "";
      this.website = this.customer.website ? this.customer.website : "";
      this.subdomain = this.customer.subdomain ? this.customer.subdomain : "";
      this.primary_user = this.primary_user ? this.customer.primary_user : "";
      this.magic_flag = false;

      localStorage.removeItem("checkdiscard");

      if (localStorage.getItem("prelink")) {
        window.location = window.location.origin + localStorage.getItem("prelink");
        localStorage.removeItem("prelink");

        return true;
      }
    },
    onChange() {
      if (this.primary_user && this.portalUsers) {
        for (i = 0; i < this.portalUsers.length; i++) {
          if (this.primary_user == this.portalUsers[i].id) {
            this.primary_user_name = this.portalUsers[i].name;
          }
        }
      }
    },
    getPortalUsers() {
      var self = this;

      axios
        .get("/dashboard/account/get-all-portal-users")
        .then(function (response) {
          if (response.data) {
            self.portalUsers = response.data.users;
            self.primary_user = response.data.primaryUser;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    flagon() {
      this.magic_flag = true;
      this.undoCheck = true;
      localStorage.setItem("checkdiscard", "yes");
      document.body.classList.add("bg-light");
    },
  },
  computed: {
    btnEditCompany() {
      if (this.name && this.address_line_1 && this.city && this.postcode && this.contact_email && this.website) {
        return false;
      } else return true;
    },
    canManageCompanyDetails() {
      if (!this.currentUser) {
        return false;
      }

      return this.currentUser.account_check;
    },
  },
  mounted: function () {
    this.getPortalUsers();

    this.$root.$on("EditCompany::Success", EditCompanyData => {
      if (EditCompanyData.hasOwnProperty("name")) {
        this.name = EditCompanyData.name;
      }
      if (EditCompanyData.hasOwnProperty("address_line_1")) {
        this.address_line_1 = EditCompanyData.address_line_1;
      }
      if (EditCompanyData.hasOwnProperty("address_line_2")) {
        this.address_line_2 = EditCompanyData.address_line_2;
      }
      if (EditCompanyData.hasOwnProperty("city")) {
        this.city = EditCompanyData.city;
      }
      if (EditCompanyData.hasOwnProperty("contact_email")) {
        this.contact_email = EditCompanyData.contact_email;
      }
      if (EditCompanyData.hasOwnProperty("contact_number")) {
        this.contact_number = EditCompanyData.contact_number;
      }
      if (EditCompanyData.hasOwnProperty("postcode")) {
        this.postcode = EditCompanyData.postcode;
      }
      if (EditCompanyData.hasOwnProperty("subdomain")) {
        this.subdomain = EditCompanyData.subdomain;
      }
      if (EditCompanyData.hasOwnProperty("website")) {
        this.website = EditCompanyData.website;
      }
      if (EditCompanyData.hasOwnProperty("primary_user_name")) {
        this.primaryUserName = EditCompanyData.primary_user_name;
      }

      /**
       * TODO:
       * Logo can't be directly replaced with image bytes data, it need to have new image path.
       * This could be done by another axio and update the logo or could be received in response of EditCompany.
       */
      if (EditCompanyData.hasOwnProperty("logo")) {
        this.logo = EditCompanyData.logo;
      }
    }),
      this.getMicrosite();
  },
};
</script>
