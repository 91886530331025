<template>
  <div>
    <div class="add-job notification-modal-main-div">
      <button
        @click="addCategoryPressed"
        class="button pink_btn b_margin_0 no_wrap_btn job-add"
      >
        Add Category
      </button>
      <!-- <div class="grey_border_box_inner box_shadow"> -->

      <div>
        <b-modal
          class="modal-p-div"
          ref="add-category"
          id="modal-notification"
          size="xl"
          :no-close-on-backdrop="true"
        >
          <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
          </template>
          <section class="multi-step-row multi-step-p">
            <div class="pop-up-box pop-up-box-styling">
              <div class="top-check-row">
                <ul>
                  <li
                    v-bind:class="{
                      selected: isActive(1),
                      completed: done1,
                      selected: active1,
                    }"
                  >
                    <a @click="step = 1" href="javascript:void(0)">1</a>
                  </li>
                  <li
                    v-bind:class="{
                      selected: isActive(2),
                      completed: done2,
                      selected: active2,
                    }"
                  >
                    <a @click="step = 2" href="javascript:void(0)">2</a>
                  </li>
                </ul>
              </div>

              <div class="text-box" id="text-box">
                <p class="add-a-job">NEW BONUS POINTS CATEGORY</p>
                <p class="key-center">
                  <span class="key-details" v-if="step == 1"
                    >Category and points</span
                  >
                  <span class="key-details" v-if="step == 2">Content</span>
                </p>

                <fieldset v-if="step == 1">
                  <div class="job-leftside">
                    <div v-if="imageName != ''"></div>
                  </div>

                  <div class="main-form-div">
                    <div class="job-leftside">
                      <div v-if="imageName != ''"></div>
                    </div>
                    <form class="form-dropdown-styling justify-content-center">
                      <div class="form-group input-sendto">
                         

                        <label
                          class="bonus-form-lable"
                          for="exampleFormControlSelect1"
                          >CATEGORY NAME</label
                        >
                        <input
                          type="text"
                          placeholder="Add name"
                          v-model="content.name"
                        />

                        <label class="bonus-form-lable-POINTS" for="suggested"
                          >POINTS</label
                        >
                        <div class="radio-btn-input">
                          <input
                            type="radio"
                            id="Suggested"
                            value="suggested"
                            v-model="content.points_type"
                            v-bind:style="[
                              content.points_type == 'suggested'
                                ? { border: '1px solid #5dc2a6 !important' }
                                : { border: '1px solid #D1D3E0 !important' },
                            ]"
                          />
                           
                          <label for="Suggested" class="Suggested"
                            >Suggested</label
                          ><br />
                           
                          <input
                            type="radio"
                            id="Fixed"
                            value="fixed"
                            v-model="content.points_type"
                            v-bind:style="[
                              content.points_type == 'fixed'
                                ? { border: '1px solid #5dc2a6 !important' }
                                : { border: '1px solid #D1D3E0 !important' },
                            ]"
                          />
                            <label for="Fixed" class="Fixed">Fixed</label><br />
                        </div>
                      </div>

                      <input
                        type="number"
                        placeholder="Add points"
                        v-model="content.points"
                        oninput="validity.valid||(value='');"
                      />

                      <div
                        class="form-group"
                        v-if="content.points_type != 'fixed'"
                      >
                        <label
                          class="bonus-form-lable-max-points"
                          for="exampleFormControlSelect1"
                          >MAX POINTS</label
                        >
                        <input
                          type="number"
                          placeholder="Add max"
                          v-model="content.max_points"
                          oninput="validity.valid||(value='');"
                        />
                      </div>
                    </form>
                  </div>
                  <div class="cell auto popup-button pad-top">
                    <!-- <button @click.prevent="goback()" class="back button btn">
                                          Back
                                        </button> -->
                    <input
                      @click="step = 2"
                      type="button"
                      name="next"
                      class="next action-button button btn_lg btn pink_btn"
                      value="Next"
                      :disabled="!validateStepOne"
                    />
                  </div>
                </fieldset>

                <fieldset v-if="step == 2">
                  <form>
                    <div class="modal-styling-content">
                      <div class="radio-btn-suggested-fixed mouse-hover">
                        <input
                          type="radio"
                          id="sugestradio"
                          value="suggested"
                          v-model="content.content_type"
                          class="mouse-hover"
                          v-bind:style="[
                            content.content_type == 'suggested'
                              ? { border: '1px solid #5dc2a6 !important' }
                              : { border: '1px solid #D1D3E0 !important' },
                          ]"
                        />
                         
                        <label
                          for="sugestradio"
                          class="radio-Suggested mouse-hover"
                          >Suggested</label
                        ><br />
                         
                        <input
                          type="radio"
                          id="fixedbtn"
                          value="fixed"
                          v-model="content.content_type"
                          class="mouse-hover"
                          v-bind:style="[
                            content.content_type == 'fixed'
                              ? { border: '1px solid #5dc2a6 !important' }
                              : { border: '1px solid #D1D3E0 !important' },
                          ]"
                        />
                         
                        <label for="fixedbtn" class="radio-fixed mouse-hover"
                          >Fixed</label
                        ><br />
                      </div>
                      <div class="bonus-form-lable">
                        <label for="exampleInputEmail1" class="bonus-form-lable"
                          >TITLE *</label
                        >
                        <ckeditor
                          class="cheditor-styling-titleBonus"
                          id="exampleFormControlTextarea-B"
                          v-model="content.Title"
                          :config="titleEditorConfig"
                        ></ckeditor>

                        <span class="form-error" id="notificationTitle"
                          >Please enter a notification title</span
                        >
                      </div>

                      <div class="form-group">
                        <label
                          class="bonus-form-lable-wording"
                          for="exampleFormControlTextarea-B"
                          >WORDING *</label
                        >
                        <ckeditor
                          class="cheditor-styling"
                          :config="editorConfig"
                          id="exampleFormControlTextarea-B"
                          v-model="content.message"
                        ></ckeditor>
                      </div>
                      <!--
                      <div class="imgae-Modal-styling">
                        <p class="img-Content-styling">Image</p>
                        <p class="Paste-image">Paste image URL</p>
                      </div> -->

                      <div class="imgae-Modal-styling">
                        <p class="img-Content-styling">Image</p>
                        <p
                          v-if="isHidden"
                          v-on:click="
                            isHidden = !isHidden;
                            content.image_url = '';
                            content.image_name = '';
                          "
                          class="Paste-image"
                        >
                          Paste image URL
                        </p>
                        <p
                          v-else
                          v-on:click="
                            isHidden = !isHidden;
                            content.image_url = '';
                            content.image_name = '';
                          "
                          class="Paste-image"
                        >
                          Upload Image
                        </p>
                      </div>

                      <div v-if="!isHidden" class="Img-url-paste">
                        <input
                          placeholder="example.png"
                          type="text"
                          v-model="content.image_url"
                        />
                      </div>
                      <div v-else>
                        <label class="Image-label-content"></label>
                        <input
                          type="file"
                          ref="inputFile"
                          accept="image/*"
                          @change="uploadImage"
                          id="actual-btn"
                          hidden
                        />
                        <label
                          for="actual-btn"
                          class="upload-file-styling-bonus"
                        >
                          <img
                            class="img-upload-btn"
                            src="/images/Capture.PNG"
                          />
                          <span class="upload-btn-text"
                            >Drag and drop an image or
                            <span class="Red-browse">browse</span> to choose a
                            file.</span
                          >
                        </label>
                        <p class="bonus-upload-img-text">
                          PNG or JPEG. Minimum height and width 300px,1Mb max.
                          Landscape or it will be cropped by ios and Android
                        </p>
                        <div class="img-upload-hide" v-if="content.image_url">
                          <i
                            class="fa fa-times-circle"
                            @click="
                              content.image_url = '';
                              content.image_name = '';
                            "
                          ></i>
                          <img
                            :src="content.image_url"
                            alt=""
                            class="img-upload-hide-img"
                          />
                        </div>
                      </div>
                    </div>
                  </form>

                  <div v-if="1 == 0">
                    <label for="exampleInputEmail1" class="Title-content"
                      >TITLE</label
                    >
                    <input type="text" class="shadow-styling" />
                    <label
                      for="exampleInputEmail1"
                      class="Title-content shadow-styling"
                      >MESSAGE</label
                    >
                    <textarea id="w3review" name="w3review" rows="4" cols="50">
                    </textarea>

                    <div>
                      <img
                        class="img-upload-hide"
                        src="/images/cflogodashboard.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="cell auto popup-button pad-top">
                    <button @click.prevent="goback()" class="back button btn">
                      Back
                    </button>
                    <input
                      @click="saveCategory"
                      type="button"
                      name="FINISH"
                      class="next action-button button btn_lg btn pink_btn"
                      value="FINISH"
                      :disabled="!validateFinish"
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </section>
        </b-modal>
      </div>
      <b-modal class="tab-modal" id="modal_unaunthenticated" size="lg">
        <div class="grid-y">
          <div class="grid-x input-box-2">
            <div class="cell auto input-box">
              <div class="discard-header4">
                <h3 class="discard-header-title-oops">Oops!</h3>
              </div>
              <p class="if-you-discard-chang-oops">
                You don’t have permission to add new bonus point categories.
                Please ask your manager to help you with this.
              </p>
            </div>
          </div>
        </div>
        <div class="cell auto popup-button">
          <input
            class="button btn_lg btn pink_btn"
            type="submit"
            value="OK"
            style="
              float: right;
              margin-right: -13px;
              margin-bottom: 13px;
              margin-left: 7px;
            "
            @click="closeModal"
          />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue/dist/legacy.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    CKEditor,
    VueTimepicker,
    Datepicker,
    //  name:'imageUpload',
  },
  props: ["sitesarr", "templates", "currentuser"],
  data() {
    return {
      // colorslected: " border: 1px solid pink;",
      // colornotslected: " border: 1px solid blue;",
      isHidden: true,
      regex: /<div>(.*)<\/div>/g,
      mobile_users_arr: null,
      replaceWith: " ",
      highlightedDates: {
        daysOfMonth: [9, 13],
      },
      isNotValid: true,
      stepTwoValid: false,
      previewImage: "http://portal.cf-staging.co.uk/images/cf-login-logo.png",
      imageName: "",
      datePickerValue: new Date(),
      disabledDates: { to: new Date(Date.now() - 86400000) },
      step: 1,
      done1: false,
      active1: true,
      current1: false,
      done2: false,
      active2: false,
      current2: false,
      done3: false,
      active3: false,
      current3: false,
      templates: [],
      engagementTemplates: [],
      otherTemplates: [],
      senttooptions: [
        { id: "no repeat", name: "Does not repeat" },
        { id: "daily", name: "Daily" },
        { id: "weekly", name: "Weekly" },
        { id: "monthly", name: "Monthly" },
      ],
      selectUser: false,
      selectSite: false,
      siteOptions: [],
      select_who: "",
      selected_who: "",
      items: [
        { value: "Multiple app users", name: "Multiple Users" },
        { value: "Single app user", name: "Individual" },
        { value: "All users at site", name: "All Users at Site" },
        {
          value: "All users at organisation",
          name: "All Users at Organisation",
        },
      ],
      form: {
        Title: "",
        CTA: "",
        send_action: "",
        site_id: "",
        Scheduled: "",
        send_immediately: true,
        Message: "",
        Repeat: "no repeat",
        timezone: "",
        user_id: "",
        scheduleDate: moment(new Date()).format("DD-MM-YY"),
        scheduleTime: "12:00 pm",
      },
      titleEditorConfig: {
        autoParagraph: false,
        height: 60,
        editorplaceholder: "Add title",
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
        autoGrow_maxHeight: 50,
      },
      editorConfig: {
        autoParagraph: false,
        height: 100,
        editorplaceholder: "Add wording",
        toolbar: [["Bold", "BulletedList", "EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
      content: {
        id: null,
        Title: "",
        name: "",
        message: "",
        type: "suggested",
        image_url: "",
        image_name: "",
        points: "",
        content_type: "",
        points_type: "",
        max_points: "",
      },
    };
  },

  methods: {
    saveCategory() {
      var self = this;
      axios
        .post("/dashboard/account/reward-cat/add", this.content)
        .then(function (response) {
          if (response.status == 200) {
            if (response.data.success == false) {
              self.$refs["add-category"].hide();
              Vue.swal({
                html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> Category Name Already exists',
                confirmButtonColor: "#ea2020",
                confirmButtonText:
                  '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#ea2020",
                customClass: {
                  container: "candidate-toast",
                },
                showClass: {
                  popup: "animated fadeInLeft fast",
                  icon: "animated heartBeat delay-1s",
                },
                hideClass: {
                  popup: "animated fadeOutLeft slow",
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs",
              });
            } else {
              self.content.id = response.data.id;
              self.$root.$emit("categoryAdded", self.content);
              self.reset();
              self.$refs["add-category"].hide();
              Vue.swal({
                html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Category Added',
                confirmButtonColor: "#5dc2a6",
                confirmButtonText:
                  '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: {
                  container: "candidate-toast",
                },
                showClass: {
                  popup: "animated fadeInLeft fast",
                  icon: "animated heartBeat delay-1s",
                },
                hideClass: {
                  popup: "animated fadeOutLeft slow",
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs",
              });
            }
          } else {
            Vue.swal({
              html: '<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> Could not Add Category',
              confirmButtonColor: "#ea2020",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#ea2020",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
        })
        .catch(function (error) {});
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();

      reader.fileName = image.name;

      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.content.image_name = e.target.fileName;
        this.content.image_url = e.target.result;

        this.imageName = e.target.fileName;
        this.previewImage = e.target.result;
        this.$refs.inputFile.value = null;
        console.log(this.previewImage);
      };
    },
    goback() {
      if (this.step == 1) {
        return false;
      }
      if (this.step == 2) {
        this.step = 1;
        return true;
      }
      if (this.step == 3) {
        this.step = 2;
        return true;
      }
      if (this.step == 4) {
        this.step = 3;
        return true;
      }
    },
    isActive(id) {
      if (this.step <= id) {
        if (id == 1 && this.step <= 1) {
          return true;
        }
        return false;
      } else {
        if (this.step == 2) {
          this.done1 = true;
          this.active2 = true;
          this.current2 = true;
          return true;
        }
        if (this.step == 3) {
          this.done2 = true;
          this.active3 = true;
          this.current3 = true;
          return true;
        }
        if (this.step == 4) {
          this.current4 = true;
          this.active4 = true;
          this.done3 = true;
        }

        return true;
      }
    },
    closeModal() {
      this.$refs["add-category"].hide();
      this.$bvModal.hide("modal_unaunthenticated");
    },
    addCategoryPressed() {
      if (this.currentuser.bonus_categories_check == 1) {
        this.$bvModal.show("modal-notification");
      } else {
        this.$bvModal.show("modal_unaunthenticated");
      }
    },
    reset(){
      this.step= 1;
      this.done1= false;
      this.active1= true;
      this.current1= false;
      this.done2= false;
      this.active2= false;
      this.current2= false;
      this.content = {
        id: null,
          Title: "",
          name: "",
          message: "",
          type: "suggested",
          image_url: "",
          image_name: "",
          points: "",
          content_type: "",
          points_type: "",
          max_points: "",
      }
    }
  },
  filters: {},
  mounted() {
    //called event when modal is about to show/visible
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      this.getMobileUsersForMultipleApp();
      console.log("Modal is about to be shown", bvEvent, modalId);
    });

    this.form.timezone = new Date().getTimezoneOffset();
    $("#filter-date-picker").val(moment(new Date()).format("DD-MM-YY hh:mm a"));
    // this.templates = {
    //     engagement: [
    //         {id: 1, title: 'Title_1', message: 'message 1 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.', image_url: 'https://picsum.photos/id/237/200/300', category: 'engagement'},
    //         {id: 2, title: 'Title_2', message: 'message 2 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.', image_url: 'https://picsum.photos/id/237/200/300', category: 'engagement'},
    //         {id: 3, title: 'Title_3', message: 'message 3 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.', image_url: 'https://picsum.photos/id/237/200/300', category: 'engagement'},
    //         {id: 4, title: 'Title_4', message: 'message 4 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.', image_url: 'https://picsum.photos/id/237/200/300', category: 'engagement'},
    //         {id: 5, title: 'Title_5', message: 'message 5 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.', image_url: 'https://picsum.photos/id/237/200/300', category: 'engagement'},
    //     ],
    //     others: [
    //         {id: 5, title: 'Title_7', message: 'other message 5 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.', image_url: 'https://picsum.photos/id/237/200/300', category: 'others'},
    //         {id: 6, title: 'Title_8', message: 'other message 6 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.', image_url: 'https://picsum.photos/id/237/200/300', category: 'others'},
    //         {id: 7, title: 'Title_9', message: 'other message 7 Nullam maximus nibh eget ultricies euismod Pellentesque quis urna nec nulla consequat.', image_url: 'https://picsum.photos/id/237/200/300', category: 'others'},
    //     ]
    // };
    //

    this.$root.$on("selectedUser", this.form.user_id);
    if (this.sitesarr.length > 0) {
      for (var i = 0; i < this.sitesarr.length; i++) {
        let x = new Object();
        x.id = this.sitesarr[i].id;
        x.name = this.sitesarr[i].name;
        this.siteOptions.push(x);
      }
    } else if (this.sitesarr) {
      let x = new Object();
      x.id = this.sitesarr.id;
      x.name = this.sitesarr.name;
      this.siteOptions.push(x);
    }
  },
  computed: {
    validateStepOne() {
      if (this.content.name && this.content.points_type) {
        if (
          this.content.points_type == "suggested" &&
          this.content.points &&
          this.content.max_points
        ) {
          return true;
        } else if (this.content.points_type == "fixed" && this.content.points) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    validateFinish() {
      if (
        this.content.name &&
        this.content.points_type &&
        this.content.content_type
      ) {
        if (
          this.content.points_type == "suggested" &&
          this.content.points &&
          this.content.max_points &&
          this.content.Title &&
          this.content.message
        ) {
          return true;
        } else if (
          this.content.points_type == "fixed" &&
          this.content.points &&
          this.content.Title &&
          this.content.message
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bonus-form-lable {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 4px;
}

.bonus-form-lable-max-points {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 4px;
  margin-top: 16px;
}

.bonus-form-lable-POINTS {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 5px;
  margin-top: 70px;
}

.bonus-form-lable-wording {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 7px;
  margin-top: 20px;
}
.bonus-form-lable-wording-modal {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 3px;
  margin-top: 66px;
}

.bonus-form-lable-content {
  font-family: InterUI;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #9297ae;
  margin-bottom: 4px;
  margin-top: 20px;
}
.input-tagged {
  float: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  flex-basis: 100%;
  flex-grow: 1;
}
.tag-right {
  margin-right: 5px;
}
#Message .cke_chrome,
#cke_editor1 .cke_chrome {
  border: 1px solid #dcdee6 !important;
}

#Message .cke_top,
#cke_editor1 .cke_top {
  border-bottom: none !important;
}

.date-time-modal-styling {
  display: flex;
  margin-bottom: 10px;
  // margin-top: -33px !important;
}

.datetime-picker-modal {
  width: 180px;

  margin: 0 8px 0 0;

  background-color: #ffffff;
}

.datetime-time-modal {
  width: 180px;
  margin: 0 8px 0 0;
  background-color: #ffffff;
}

.modal-content {
  width: 800px;
}

.modal-content {
  width: 743px !important;
  height: 799px !important;
  overflow-y: scroll;
}

#text-box .vs_dropdown-menu,
.modal .vs_dropdown-menu {
  min-width: 1000px !important;
  width: 100% !important;
}

#vs8_listbox {
  min-width: 1000px !important;
}
.modal-body .input-box {
  float: left;
  width: 100%;
  margin-bottom: 10px !important;
  /* height: 94px; */
  margin-top: -16px !important;
}
// .form-group.input-sendto {
//   margin-bottom: 12px;
// }
.multi-step-row .pop-up-box .top-check-row {
  float: left;
  width: 100%;
  margin-bottom: 35px;
}
.multi-step-row .pop-up-box .top-check-row ul {
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.multi-step-row .pop-up-box .top-check-row ul:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 59px;
  height: 2px;
  background: #fff;
  z-index: 9;
}
.multi-step-row .pop-up-box .top-check-row ul li {
  display: inline-block;
  padding: 0 30px;
  position: relative;
}
.multi-step-row .pop-up-box .top-check-row ul li a {
  display: block;
  text-align: center;
  width: 24px;
  height: 24px;
  border: 1px solid #f0f1f7;
  background: #ffffff;
  border-radius: 100%;
  color: #c9cbda;
  text-decoration: none;
  font: 600 12px/25px "InterUI", sans-serif;
  line-height: 22px;
}
.multi-step-row .pop-up-box .top-check-row ul li:before,
.multi-step-row .pop-up-box .top-check-row ul li.selected:before {
  content: "";
  position: absolute;
  left: -25px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 47px;
  height: 1px;
  background: #e9eaed;
}
.multi-step-row .pop-up-box .top-check-row ul li.selected a {
  // text-indent: 0;
  border: solid 1px #c9cbda;
  background: #ffffff;
  color: #7b7f96;
}
.multi-step-row .pop-up-box .top-check-row ul li.completed a {
  color: #fff;
  text-indent: 0;
  background: #51bf73 url("/images/checkmark.png") no-repeat center center;
  text-indent: -99999px;
}
.multi-step-row .pop-up-box .top-check-row ul li.completed:before {
  display: block;
}

.multi-step-row .pop-up-box .top-check-row ul li:first-child::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 47px;
  height: 1px;
  background: #e9eaed;
  display: none;
}

.multi-step-row .pop-up-box .text-box {
  float: left;
  width: 100%;
}
.multi-step-row .pop-up-box .text-box h3 {
  margin: 0 0 20px 0;
}
.multi-step-row .pop-up-box .text-box strong.title {
  display: block;
  padding: 0 0 15px 0;
  color: #363b54;
  font: 600 16px/16px "InterUI", sans-serif;
}
.multi-step-row .pop-up-box .text-box input[type="text"],
.multi-step-row .pop-up-box .text-box input[type="file"] {
  float: left;
  width: 100%;
  height: 44px;
  margin: 3px 0 0;
  padding: 10px 11px 12px 12px;
  border-radius: 3px;
  border: solid 1px #dcdee6;
  background-color: #ffffff;
  box-shadow: none;
  font-family: InterUI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #363b54;
}
.multi-step-row .pop-up-box .text-box textarea {
  float: left;
  width: 100%;
  height: 140px;
  resize: none;
  border-radius: 2px;
  border: 1px solid #e9eaed;
  box-shadow: none;
  color: #363b54;
  padding: 14px;
  box-sizing: border-box;
  font: 400 14px "InterUI", sans-serif;
  margin-bottom: 25px;
}
.multi-step-row .pop-up-box .text-box select {
  float: left;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #e9eaed;
  box-shadow: none;
  color: #363b54;
  padding: 0 10px;
  box-sizing: border-box;
  font: 400 14px "InterUI", sans-serif;
  margin-bottom: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  // background: url("images/down.png") no-repeat 99% center;
}
.multi-step-row .pop-up-box .btn-row {
  float: left;
  width: 100%;
  text-align: center;
}
.multi-step-row .pop-up-box a.btn-next,
.multi-step-row .pop-up-box .text-box input[type="button"] {
}

.multi-step-row .pop-up-box a.btn-cancel {
  display: inline-block;
  text-decoration: none;
  color: #312d8b;
  width: 96px;
  height: 40px;
  border-radius: 4px;
  background-color: #eeeff7;
  font: 600 12px/40px "InterUI", sans-serif;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  border: 0;
  margin-left: 0;
  cursor: pointer;
  margin: 0 2px;
}
.multi-step-row .pop-up-box .text-box ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.multi-step-row .pop-up-box .text-box ul li {
  display: block;
  padding: 0 0 15px 0;
  color: #363b54;
  font: 400 16px/16px "InterUI", sans-serif;
}
.multi-step-row .pop-up-box .text-box span.or {
  display: block;
  color: #363b54;
  padding: 0 0 10px 0;
  height: 24px;
  font-family: InterUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363b54;
}

.tab-content input[type="checkbox"]:checked {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: #5dc2a6;
}

label.Image-label-content {
  margin: -12px !important;
}

.img-Content-styling {
  font-family: InterUI !important;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  color: #9297ae;
  margin-top: 30px !important;
  text-transform: uppercase;
}
.upload-file-styling-bonus {
  display: inline-block;
  background-color: #f9fafb;
  color: black;
  padding: 9px 0px 10px 44px;
  font-family: sans-serif;
  border-radius: 0.3rem;
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  align-items: center;
  border: 2px dotted #9297ae;
  margin-top: 7px;
}
img.img-upload-btn {
  height: 87px;
  color: #656a85;
}
.upload-btn-text {
  font-family: InterUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #656a85;
}
.imgae-Modal-styling {
  margin-top: -13px !important;
  margin-bottom: 0px;
  font-family: "InterUI" !important;
  font-size: 11px !important;
  display: flex;
  justify-content: space-between;
}

.upload-img-content {
  width: 150px;
  height: 150px;
  border-radius: 4px;
}

.bonus-placeholder-styling {
  margin-bottom: 17px;
  border-radius: 3px;
}
.Paste-image {
  margin-top: 30px;
  color: red;
  font-family: "InterUI";
  font-size: 14px;
  cursor: pointer;
}
p.bonus-upload-img-text {
  font-family: "InterUI";
  font-size: 14px;
  margin-top: 5px;
  color: #656a85;
}
.border-content-bonus {
  border: solid 1px #dcdee6;
  background-color: #fff;
}
input.border-content-bonus {
  background-color: #dcdee6 !important;
  border-radius: 3px;
}
textarea.border-content-bonus {
  background-color: #dcdee6;
  border-radius: 3px !important;
}
textarea.border-content-bonus:focus {
  border: none;
}

// [type="text"]:focus,
// textarea:focus,
// .emoji-wysiwyg-editor:focus {
//   /* border: 1px solid #9297ae !important; */
//   color: #363b54;
//   outline: none;
// }
.main-form-div {
  margin: 0px 40px 0px 40px !important;
}

.card-margin-content {
  margin: 0px 40px 0px 40px !important;
}

.modal-styling-content {
  margin: 0px 40px 0px 40px !important;
}
.radio-btn-suggested-fixed {
  padding-left: 3px;
}

// input[type="radio"]:after {
//   width: 16px;
//   height: 16px;
//   border-radius: 15px;
//   top: -2px;
//   left: -1px;
//   position: relative;
//   background-color: #d1d3d1;
//   content: "";
//   display: inline-block;
//   visibility: visible;
//   border: 2px solid white;
// }

// input[type="radio"]:checked:after {
//   width: 15px;
//   height: 15px;
//   border-radius: 15px;
//   top: -2px;
//   left: -1px;
//   position: relative;
//   background-color: #51bf73;
//   content: "";
//   display: inline-block;
//   visibility: visible;
//   border: 2px solid #51bf73;
// }
// input[type="radio"] {
//   -webkit-appearance: none;
//   width: 15px;
//   height: 15px;
//   border: 1px solid green;
//   border-radius: 510%;
//   outline: none;
//   // box-shadow: 0 0 5px 0px green inset;
//   vertical-align: middle;
// }

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
// input[type="radio"]:checked:before {
//   background: input[type= "radio" ] {
//     -webkit-appearance: none;
//     width: 20px;
//     height: 20px;
//     border: 1px solid darkgray;
//     border-radius: 50%;
//     outline: none;
//     box-shadow: 0 0 5px 0px green inset;
//   }
// }
input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: green;
}

[type="file"],
[type="checkbox"],
[type="radio"] {
  margin: 0 0 0 !important;
}
label.Suggested {
  margin-bottom: 2px;
  font-weight: 600;
}

label.Fixed {
  margin-bottom: 7px;
  font-weight: 600;
}

.radio-btn-suggested-fixed {
  margin-bottom: 12px;
  font-weight: 600;
}
.radio-btn-suggested-fixed {
  margin-bottom: 12px;
}
label.radio-Suggested {
  font-weight: 600;
  font-family: "InterUI";
  // cursor: no-drop;
}

.radio-fixed {
  font-weight: 600;
  font-family: "InterUI";
  cursor: no-drop;
}
.img-upload-hide {
  height: 150px;
  width: 150px;
  text-align: end;
  border-radius: 4px !important;
  margin-bottom: 14px;
}
.fa-times-circle {
  cursor: pointer;
  font-size: 17px;
  position: relative;
  top: 7px;
  left: 10px;
  background-color: #fff;
  border-radius: 50%;
}

.fa-times-circle:hover {
  color: red;
}

.mouse-hover {
  // pointer-events: none;
  // cursor: no-drop;
  cursor: pointer;
}
.img-upload-hide-img {
  height: 150px;
  width: 150px;
  border-radius: 4px;
}
.Red-browse {
  color: red;
}
.discard-header-title-oops {
  height: 34px;
  /* margin: -25px 0px 27px 0; */
  font-family: InterUI;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #363b54;
}
.if-you-discard-chang-oops {
  width: 457px;
  height: 44px;
  font-family: InterUI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #363b54;
  padding-bottom: 52px;
}
.Img-url-paste {
  margin-bottom: 69px;
}
input[type="radio"]:checked:before {
  background: #5dc2a6 !important;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #d1d3e0 !important;
  border-radius: 510%;
  outline: none;
  vertical-align: middle;
}
// .multi-step-row .pop-up-box .text-box input[type="text"],
// .multi-step-row .pop-up-box .text-box input[type="file"] {
//   float: left;
//   width: 100%;
//   height: 44px;
//   margin: 3px 0 0;
//   padding: 10px 11px 12px 12px;
//   border-radius: 3px;
//   border: solid 1px #dcdee6;
//   background-color: #ffffff;
//   box-shadow: none;
//   font-family: InterUI;
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.57;
//   letter-spacing: normal;
//   color: #363b54;
//   border-radius: 3px !important;
//   box-shadow: inset 0 2px 2px 0 rgb(48 55 131 / 12%) !important;
//   border: solid 1px #e6e7f0 !important;
//   background-color: rgba(209, 211, 224, 0.12) !important;
// }
// .multi-step-row .pop-up-box .text-box textarea {
//   float: left;
//   width: 100%;
//   height: 140px;
//   resize: none;
//   border-radius: 2px;
//   border: 1px solid #e9eaed;
//   box-shadow: none;
//   color: #363b54;
//   padding: 14px;
//   box-sizing: border-box;
//   font: 400 14px "InterUI", sans-serif;
//   margin-bottom: 25px;
//   border-radius: 3px !important;
//   box-shadow: inset 0 2px 2px 0 rgb(48 55 131 / 12%) !important;
//   border: solid 1px #e6e7f0 !important;
//   background-color: rgba(209, 211, 224, 0.12) !important;
// }

// border-color: #e9eaed;
.vs__search,
.vs__search:focus {
  color: #cbcddb !important;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 65%;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 54%;
  height: 53%;
  margin: 23% auto;
  border-radius: 65%;
}
</style>
