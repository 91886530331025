<template>
  <div>
    <div v-show="showLoad" class="show-load">
      <img :src="myImage" />
    </div>
    <div class="grid-x">
      <div class="cell auto font_0875 no-padding">
        <div class="grid-x">
          <div class="cell small-5">
            <input
              class="jobs-search"
              placeholder="Search"
              v-model="searchVar"
              name="searchVar"
              @keyup.delete="searchInvitationData()"
              @keyup="searchInvitations()"
            />
          </div>
          <div class="cell small-2">
            <v-select
              placeholder="Filter by:"
              v-model="statusFilter"
              :reduce="referrer => referrer.id"
              label="name"
              :options="filters"
              :clearable="false"
              @input="filteredInvitations()"
            ></v-select>
          </div>
          <div class="cell auto" align="right">
            <button
              v-b-modal.addInvitees
              class="button pink_btn b_margin_0 no_wrap_btn job-add"
            >Add Invitees</button>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="addInvitees" class="invitees_task" size="lg" centered>
      <h3>Invite app user</h3>
      <div class="grid-x input-box-2 invitee-modal-margin">
        <div class="cell auto input-box">
          <input type="text" placeholder="First Name" v-model="inviteUser.first_name" />
        </div>
        <div class="cell auto input-box">
          <input type="text" placeholder="Surname" v-model="inviteUser.surname" />
        </div>
      </div>
      <div class="grid-x input-box-2">
        <div class="cell auto input-box">
          <input type="text" placeholder="Email" v-model="inviteUser.email" />
        </div>
        <div class="cell auto input-box">
          <input type="text" placeholder="Mobile number" v-model="inviteUser.mobile" />
        </div>
      </div>
      <div class="grid-x input-box-2">
        <div class="cell auto input-box">
          <v-select
            placeholder="Site"
            v-model="inviteUser.site"
            :reduce="referrer => referrer.id"
            label="name"
            :options="sites"
            :clearable="false"
          ></v-select>
        </div>
        <div class="cell auto input-box"></div>
      </div>+ &nbsp; Add
      <a v-b-modal.manyInvitee class="many-invitee">many at once</a>
      <p>
        <br />Invitations to join {{ brandName }} will automatically be send via SMS and email. You can
        send a test message to yourself by clicking
        <a
          class="many-invitee"
          :href="currentURL+'/dashboard/points/test-invitations'"
        >here</a>.
        <br />
        <br />
      </p>
      <div class="cell auto popup-button">
        <input
          type="button"
          class="button btn_lg btn orange_btn"
          value="Save without Sending"
          :disabled="btnAddCanidate"
          @click="saveWithoutSending()"
        />
        <input
          type="button"
          class="button btn_lg btn pink_btn"
          value="Send Invitations"
          :disabled="btnAddCanidate"
          @click="saveWithSending()"
        />
      </div>
    </b-modal>

    <b-modal id="manyInvitee" ref="mymanyInvitee" size="lg" centered>
      <h3>Invite multiple app users - upload</h3>
      <div class="many-invitee-modal-margin">
        <p>
          Step 1:
          <a
            class="many-invitee"
            :href="currentURL+'/dashboard/points/download-csv'"
          >Download</a> template file
        </p>
        <p>
          Step 2: Copy and paste the details of your invitees into the template, one invite per row.
          Site names must match the sites your
          organisation has listed
        </p>
        <p>Step 3: Re-save the file and upload it below</p>
      </div>
      <div class="drag-drop-box leftside-invite">
        <div
          class="uploader"
          @dragenter="OnDragEnter"
          @dragleave="OnDragLeave"
          @dragover.prevent
          @drop="onDrop"
          :class="{ dragging: isDragging }"
        >
          <img src="/images/group-10.png" />
          <p>
            Drag and drop or
            <input
              data-button-text="browse"
              type="file"
              style="display:none"
              id="file"
              ref="fileInput"
              @change="onInputChange"
              value="Browse"
            />
            <a href="javascript:void(0)" @click="$refs.fileInput.click()" id="uploadTrigger">browse</a> to choose a file. Required file type csv.
          </p>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input
          type="button"
          class="button btn_lg btn orange_btn"
          @click="hidemanyInvitee"
          value="Cancel"
        />
        <input
          type="button"
          class="button btn_lg btn pink_btn"
          value="Next"
          :disabled="btnAddCanidate"
        />
      </div>
    </b-modal>
    
    <b-modal id="invitees-added" ref="myinvitees-added"  size="sl" centered>
    <h3>Invitees added</h3>
            <div class="invitee-modal-margin">
              <p>The details of your uploaded invitees have been saved.</p>
              <p>You can now send them SMS and email invitations and reminder to download {{ brandName }}.</p>
              <p>
                To send yourself a test email and SMS click
                <a
                  class="many-invitee"
                  :href="currentURL+'/dashboard/points/test-all'"
                >here</a>.
              </p>
              <template v-if="duplicates.length > 0">
                <p>
                  One or more of your new invitees email/phone number was identified as identical to an
                  existing invitee and was therefore not added.
                </p>
                <template v-for="(duplicate, index) in duplicates">
                  <p :key="index" class="duplicate-invitees" align="center">
                    <strong>Duplicate invitee [{{ duplicate["Name"] }} - {{ duplicate["Email"] }} - {{ duplicate["Phone Number"] }} - {{ duplicate["Site Name"] }}]</strong>
                  </p>
                </template>
              </template>
            </div>
    </b-modal>
    
    <div class="cf-table">
      <div class="grid-x grid-padding-x cf-table-head red_bg t_margin_1 align-middle">
        <div class="cell medium-1">
          <label class="contain-check">
            <input @click="taskBar()" type="checkbox" v-model="selectAll" />
            <span class="checkmark-check"></span>
          </label>
        </div>
        <div class="cell auto white">
          <p class="tb_margin_1">
            <strong>Name</strong>
          </p>
        </div>
        <div class="cell auto white">
          <p class="tb_margin_1">
            <strong>Email</strong>
          </p>
        </div>
        <div class="cell auto white">
          <p class="tb_margin_1">
            <strong>Mobile number</strong>
          </p>
        </div>
        <div class="cell auto white">
          <p class="tb_margin_1">
            <strong>Site</strong>
          </p>
        </div>
        <div class="cell auto white">
          <p class="tb_margin_1">
            <strong>Status</strong>
          </p>
        </div>
      </div>
      <template v-for="invitation in invitations">
        <div class="grid-x grid-padding-x align-middle b_border_b1">
          <div class="cell medium-1">
            <label class="contain-check">
              <input
                @click="taskBar()"
                type="checkbox"
                :value="invitation.id"
                v-model="inviteChecked"
              />
              <span class="checkmark-check"></span>
            </label>
          </div>
          <div class="cell auto">
            <p class="tb_margin_1">
              <strong>{{ invitation.first_name }} {{ invitation.surname }}</strong>
            </p>
          </div>
          <div class="cell auto">
            <p class="tb_margin_1">
              <strong>{{ invitation.email }}</strong>
            </p>
          </div>
          <div class="cell auto">
            <p class="tb_margin_1">
              <strong>{{ invitation.mobile_number }}</strong>
            </p>
          </div>
          <div class="cell auto">
            <p class="tb_margin_1">
              <strong>{{ invitation.site_name }}</strong>
            </p>
          </div>
          <div class="cell auto">
            <p class="tb_margin_1">
              <strong>
                <div
                  v-if="invitation.status === 'Invite sent'"
                >{{invitation.status}} [{{ invitation.updated_at | moment("DD-MM-YYYY") }}]</div>
                <div
                  v-else-if="invitation.status === 'Reminder sent'"
                >{{invitation.status}} [{{ invitation.updated_at | moment("DD-MM-YYYY") }}]</div>
                <div v-else>{{invitation.status}}</div>
              </strong>
            </p>
          </div>
        </div>
      </template>
    </div>
    <div id="main-taskbar">
      <section class="bar-row">
        <div class="inner-box">
          <strong class="number">{{inviteChecked.length}}</strong>
          <strong class="title">Invitee(s) selected</strong>
          <a @click="closeTaskBar()" class="exit">
            <img src="/images/exit-2.png" alt="img" />
          </a>
          <ul>
            <li>
              <a v-b-modal.inviteCheck>
                <img src="/images/send invitation.png" alt="img" />Invite
              </a>
            </li>
            <li>
              <a v-b-modal.reminderCheck>
                <img src="/images/reminder-icon.png" alt="img" />Send reminder
              </a>
            </li>
            <li>
              <a v-b-modal.deleteCheck>
                <img src="/images/delete icon.png" alt="img" />Delete
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>

    <b-modal @show="resetState" ref="myInviteCheck" id="inviteCheck" size="sl" centered>
        <h3>Are you sure?</h3>
            <div class="many-invitee-modal-margin">
			  <p>This will send an automated sequence of three SMS and/or email invitations to the selected app users.  The first SMS and/or email will be sent immediately.</p>
              <p>To find out more about our automated invitation sequence, <a href="https://support.carefriends.co.uk/articles/4521850-sending-invitations-to-download-the-app" target="_blank" class="many-invitee">click here</a>.</p>
              <p>
                To send yourself a test SMS and/or email,
                <a
                  class="many-invitee"
                  :href="currentURL+'/dashboard/points/test-invitations'"
                >click here</a>.
                <br />
                <br />
              </p>
            </div>
            <div class="cell auto popup-button">
              <input
                type="button"
                class="button btn_lg btn orange_btn"
                @click="hideInviteCheck"
                value="Cancel"
              />
              <input
                @click="sendInvitations()"
                type="button"
                class="button btn_lg btn pink_btn"
                value="Send Invitations"
              />
            </div>
    </b-modal>
    

    <b-modal ref="myreminderCheck" id="reminderCheck" class="invitees_deleted"  size="sl" centered>
    <h3>Are you sure?</h3>
            <div class="many-invitee-modal-margin">
              <p>Do you want to send a reminder SMS and email to invitees?</p>
              <p>
                You can send a test SMS and email to yourself by clicking
                <a
                  class="many-invitee"
                  :href="currentURL+'/dashboard/points/test-reminders'"
                >here</a>.
                <br />
                <br />
              </p>
            </div>
            <div class="cell auto popup-button">
              <input
                type="button"
                class="button btn_lg btn orange_btn"
                @click="hidereminderCheck"
                value="Cancel"
              />
              <input
                :disabled='isDisabled'
                @click="sendReminders()"
                type="button"
                class="button btn_lg btn pink_btn"
                value="Send Reminders"
              />
            </div>
    </b-modal>
   

    <b-modal ref="mydeleteCheck" id="deleteCheck" centered size="sl">
        <h3>Are you sure?</h3>
            <div class="many-invitee-modal-margin">
              <p>
                If you delete this invitee they will no longer be able to register for the app and you
                will not be able to send them further invitations.
                <br />
                <br />
              </p>
            </div>
            <div class="cell auto popup-button">
              <input
                type="button"
                class="button btn_lg btn orange_btn"
               @click="hidedeleteCheck"
                value="Cancel"
              />
              <input
                @click="deleteInvitees()"
                type="button"
                class="button btn_lg btn pink_btn"
                value="Delete"
              />
            </div>
    </b-modal>
    

    <b-modal id="only-csv" ref="myonly-csv" size="sl" centered>
          <h4>
              <br />Only CSV file type is allowed
            </h4>
            <div class="cell auto popup-button">
              <input
                @click="closeCSV()"
                type="button"
                class="button btn_lg btn pink_btn"
                value="Ok"
              />
            </div>
    </b-modal>
    
    <b-modal id="duplicate-user" ref="myduplicate-user">
        <h4>
              <br />User invitation identified as duplicate
            </h4>
            <div class="cell auto popup-button">
              <input
                @click="closeDup()"
                type="button"
                class="button btn_lg btn pink_btn"
                value="Ok"
              />
            </div>
    </b-modal>
   
    <template v-if="paginationData.last_page > 1">
      <paginate
        v-model="page"
        :page-count="paginationData.last_page"
        :page-range="3"
        :margin-pages="2"
        :click-handler="paginatorClickCallback"
        :prev-text="'<'"
        :next-text="'>'"
        :prev-class="'paginate-controls'"
        :next-class="'paginate-controls'"
        :container-class="'pagination'"
        :page-class="'page-item'"
        :active-class="'active'"
      ></paginate>
    </template>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate";
export default {
  components: { paginate: Paginate },
  props: ["sites", "userInvitations", "filter"],
  data() {
    return {
      invitations: this.userInvitations.data,
      showLoad: false,
      myImage: "/images/loading.gif",
      searchVar: null,
      statusFilter: this.filter,
      filters: [
        { id: 1, name: "No filter" },
        { id: 2, name: "No invite sent" },
        { id: 3, name: "Invite sent" },
        { id: 4, name: "Reminder sent" },
        { id: 5, name: "Registered" },
      ],
      inviteChecked: [],
      inviteUser: {
        first_name: null,
        surname: null,
        site: null,
        email: null,
        mobile: null,
      },
      isDragging: false,
      dragCount: 0,
      fileinput: null,
      currentURL: null,
      duplicates: [],
      paginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
      page: this.userInvitations.current_page,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showAddInvitees: false,
      isDisabled: false,
    };
  },
  methods: {
    hideInviteCheck(){
        this.$refs.myInviteCheck.hide();
    },

    hidereminderCheck(){
        this.$refs.myreminderCheck.hide();
    },
    hidedeleteCheck(){
        this.$refs.mydeleteCheck.hide();
    },
    hidemanyInvitee(){
        this.$refs.mymanyInvitee.hide();
    },

    resetState() {
      this.isDisabled = false;
    },

    searchInvitations() {
      if (this.searchVar.length > 0) {
        this.showLoad = true;
        var self = this;
        axios
          .post("/dashboard/points/search-invitees", {
            searchVar: this.searchVar,
          })
          .then(function (response) {
            if (response.data.invitees.data) {
              self.invitations = response.data.invitees.data;
              self.paginationData.current_page =
                response.data.invitees.current_page;
              self.paginationData.from = response.data.invitees.from;
              self.paginationData.last_page = response.data.invitees.last_page;
              self.paginationData.per_page = response.data.invitees.per_page;
              self.paginationData.to = response.data.invitees.to;
              self.paginationData.total = response.data.invitees.total;
            }
            self.showLoad = false;
          })
          .catch(function (error) {
            console.log(error);
          });
        //.finally(function() {
        //    self.showLoad = false;
        // });
      } else {
        this.invitations = this.userInvitations.data;
        this.paginationData.current_page = this.userInvitations.current_page;
        this.paginationData.from = this.userInvitations.from;
        this.paginationData.last_page = this.userInvitations.last_page;
        this.paginationData.per_page = this.userInvitations.per_page;
        this.paginationData.to = this.userInvitations.to;
        this.paginationData.total = this.userInvitations.total;
      }
    },
    searchInvitationData() {
      if (this.searchVar.length <= 0) {
        this.invitations = this.userInvitations.data;
        this.paginationData.current_page = this.userInvitations.current_page;
        this.paginationData.from = this.userInvitations.from;
        this.paginationData.last_page = this.userInvitations.last_page;
        this.paginationData.per_page = this.userInvitations.per_page;
        this.paginationData.to = this.userInvitations.to;
        this.paginationData.total = this.userInvitations.total;
      } else {
        this.searchInvitations();
      }
    },
    filteredInvitations() {
      if (this.statusFilter) {
        var self = this;
        axios
          .post("/dashboard/points/filter-invitees", {
            filter: this.statusFilter,
          })
          .then(function (response) {
            if (response.data.invitees.data) {
              self.invitations = response.data.invitees.data;
              self.paginationData.current_page =
                response.data.invitees.current_page;
              self.paginationData.from = response.data.invitees.from;
              self.paginationData.last_page = response.data.invitees.last_page;
              self.paginationData.per_page = response.data.invitees.per_page;
              self.paginationData.to = response.data.invitees.to;
              self.paginationData.total = response.data.invitees.total;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    paginatorClickCallback: function (pageNum) {
      if (this.statusFilter && this.statusFilter > 1) {
        window.location =
          window.location.origin +
          "/dashboard/app-users?page=" +
          pageNum +
          "&filter=" +
          this.statusFilter +
          "#invitations";
        return false;
      } else {
        window.location =
          window.location.origin +
          "/dashboard/app-users?page=" +
          pageNum +
          "#invitations";
        return false;
      }
    },
    saveWithoutSending() {
      var self = this;
      var show = true;
      axios
        .post("/dashboard/points/save-without-sending", {
          inviteUser: this.inviteUser,
        })
        .then(function (response) {
          if (response.data == "duplicate") {
            Vue.swal.close();
            // $("#duplicate-user").show();
            this.$refs.myduplicate-user.show();
            show = false;
            return;
          }
          self.getInvitees();

          if (show) {
            self.showAddInvitees = false;
            this.$refs.myaddInvitees.hide();
            Vue.swal({
              html:
                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invitee added',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast",
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s",
              },
              hideClass: {
                popup: "animated fadeOutLeft slow",
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs",
            });
          }
          $("body").removeClass("modal-open");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveWithSending() {
      var self = this;
      var show = true;
      axios
        .post("/dashboard/points/save-with-sending", {
          inviteUser: this.inviteUser,
        })
        .then(function (response) {
          console.log(response);
          if (response.data == "duplicate") {
            Vue.swal.close();
            // $("#duplicate-user").show();
            this.$refs.myduplicate-user.show();
            show = false;
            // $("body").removeClass("modal-open");
            return;
          }
          self.showAddInvitees = false;
          this.$refs.myaddInvitees.hide();
          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invite sent',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });

          $("body").removeClass("modal-open");

          self.getInvitees();
        })
        .catch(function (error) {
          console.log(error);
          $("body").removeClass("modal-open");
        });
    },
    OnDragEnter(e) {
      e.preventDefault();
      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    onInputChange(e) {
      const files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    addImage(file) {
      Vue.swal({
        title: "Uploading...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      if (!file.type.match("text/csv")) {
        Vue.swal.close();
        $("#only-csv").show();
        return;
      }
      let promise = new Promise((resolve, reject) => {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          resolve((vm.fileinput = reader.result));
        };
        reader.readAsText(file);
      });

      promise.then(
        (result) => {
          var vm = this;
          axios
            .post("/dashboard/points/parse-csv", { fileinput: this.fileinput })
            .then((response) => {
              vm.duplicates = [];
              if (response.data.duplicate.length > 0) {
                vm.duplicates = response.data.duplicate;
              }
              vm.getInvitees();
              Vue.swal.close();
              this.$refs.mymanyInvitee.hide();
              $("#invitees-added").show();
            });
        },
        (error) => {
          /* handle an error */
          console.log(error);
        }
      );
    },
    closeInvitees() {
      this.$refs.myinvitees-added.hide();
    },
    taskBar() {
      $("#main-taskbar").show();
    },
    closeTaskBar() {
      $("#main-taskbar").hide();
      this.inviteChecked = [];
    },
    sendInvitations() {
      var self = this;
      this.isDisabled = true;
      axios
        .post("/dashboard/points/send-invitations", {
          invitees: this.inviteChecked,
        })
        .then(function (response) {
          self.getInvitees();
          this.$refs.myinviteCheck.hide();
          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invitations sent',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      $("body").removeClass("modal-open");
    },
    sendReminders() {
      var self = this;
      axios
        .post("/dashboard/points/send-reminders", {
          invitees: this.inviteChecked,
        })
        .then(function (response) {
          self.getInvitees();
          this.$refs.myreminderCheck.hide();
          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Reminders sent',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      
      $("body").removeClass("modal-open");
    },
    deleteInvitees() {
      var self = this;
      axios
        .post("/dashboard/points/delete-invitees", {
          invitees: this.inviteChecked,
        })
        .then(function (response) {
          self.getInvitees();
          this.$refs.mydeleteCheck.hide();
          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Invitee(s) deleted',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      $("body").removeClass("modal-open");
    },
    closeCSV() {
      this.$refs.myonly-cv.hide();
    },
    closeDup() {
      this.$refs.myduplicate-user.hide();
    },
    getInvitees() {
      var self = this;
      axios
        .get("/dashboard/points/get-invitees")
        .then(function (response) {
          // handle success
          if (response.data.invitees.data) {
            self.invitations = response.data.invitees.data;
            self.paginationData.current_page =
              response.data.invitees.current_page;
            self.paginationData.from = response.data.invitees.from;
            self.paginationData.last_page = response.data.invitees.last_page;
            self.paginationData.per_page = response.data.invitees.per_page;
            self.paginationData.to = response.data.invitees.to;
            self.paginationData.total = response.data.invitees.total;
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
  },
  computed: {
    btnAddCanidate() {
      if (
        this.inviteUser.first_name &&
        this.inviteUser.surname &&
        this.reg.test(this.inviteUser.email) &&
        this.inviteUser.mobile
      ) {
        return false;
      } else return true;
    },
    selectAll: {
      get: function () {
        return this.invitations
          ? this.inviteChecked.length == this.invitations.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.invitations.forEach(function (user) {
            selected.push(user.id);
          });
        }

        this.inviteChecked = selected;
      },
    },
  },
  mounted() {
    $("#main-taskbar").hide();
    this.currentURL = window.location.origin;
    this.paginationData.current_page = this.userInvitations.current_page;
    this.paginationData.from = this.userInvitations.from;
    this.paginationData.last_page = this.userInvitations.last_page;
    this.paginationData.per_page = this.userInvitations.per_page;
    this.paginationData.to = this.userInvitations.to;
    this.paginationData.total = this.userInvitations.total;
  },
};
</script>
