<script>
import { AccountIcon } from "../Icons/AccountIcon";
import { ActivateIcon } from "../Icons/ActivateIcon";
import { AppUsersIcon } from "../Icons/AppUsersIcon";
import { ArrowDownIcon } from "../Icons/ArrowDownIcon";
import { BonusPointsIcon } from "../Icons/BonusPointsIcon";
import { CandidatesIcon } from "../Icons/CandidatesIcon";
import { CompanyDetailsIcon } from "../Icons/CompanyDetailsIcon";
import { ContactUsIcon } from "../Icons/ContactUsIcon";
import { DashboardIcon } from "../Icons/DashboardIcon";
import { DeactivateIcon } from "../Icons/DeactivateIcon";
import { EllipsisMenuIcon } from "../Icons/EllipsisMenuIcon";
import { FilterIcon } from "../Icons/FilterIcon";
import { HelpIcon } from "../Icons/HelpIcon";
import { InsightsIcon } from "../Icons/InsightsIcon";
import { JobsIcon } from "../Icons/JobsIcon";
import { KnowledgeBaseIcon } from "../Icons/KnowledgeBaseIcon";
import { LogOutIcon } from "../Icons/LogOutIcon";
import { NotificationsIcon } from "../Icons/NotificationsIcon";
import { PaymentsIcon } from "../Icons/PaymentsIcon";
import { PencilIcon } from "../Icons/PencilIcon";
import { ViewIcon } from "../Icons/ViewIcon";
import { ReportBugIcon } from "../Icons/ReportBugIcon";
import { ResourceToolkitIcon } from "../Icons/ResourceToolkitIcon";
import { RoadmapIcon } from "../Icons/RoadmapIcon";
import { SidebarToggleIcon } from "../Icons/SidebarToggleIcon";
import { UndoIcon } from "../Icons/UndoIcon";
import { UserSettingsIcon } from "../Icons/UserSettingsIcon";
import { ValidationErrorIcon } from "../Icons/ValidationErrorIcon";
import { SingleMonthlyBudgetIcon } from "../Icons/SingleMonthlyBudgetIcon";
import { SingleOneOffBudgetIcon } from "../Icons/SingleOneOffBudgetIcon";
import { SingleMonthlyCarryOverBudgetIcon } from "../Icons/SingleMonthlyCarryOverBudgetIcon";
import { MultipleUserMonthlyBudgetIcon } from "../Icons/MultipleUserMonthlyBudgetIcon";
import { MultipleUserOneOffBudgetIcon } from "../Icons/MultipleUserOneOffBudgetIcon";
import { MultipleUserMonthlyCarryOverBudgetIcon } from "../Icons/MultipleUserMonthlyCarryOverBudgetIcon";
import { SiteMonthlyBudgetIcon } from "../Icons/SiteMonthlyBudgetIcon";
import { SiteOneOffBudgetIcon } from "../Icons/SiteOneOffBudgetIcon";
import { SiteMonthlyCarryOverBudgetIcon } from "../Icons/SiteMonthlyCarryOverBudgetIcon";
import { MoreVerticalIcon } from "../Icons/MoreVerticalIcon";
import { TrashIcon } from "../Icons/TrashIcon";
import { PencilFilledIcon } from "../Icons/PencilFilledIcon";
import { FilterTableIcon } from "../Icons/FilterTableIcon";
import { DragIndicatorIcon } from "../Icons/DragIndicatorIcon";
import { AlertIcon } from "../Icons/AlertIcon";
import { AppSettingsIcon } from "../Icons/AppSettingsIcon";
import { IntegrationsIcon } from "../Icons/IntegrationsIcon";
import { PortalUsersIcon } from "../Icons/PortalUsersIcon";
import { SchemeRulesIcon } from "../Icons/SchemeRulesIcon";
import { SitesIcon } from "../Icons/SitesIcon";
import { TarrifsIcon } from "../Icons/TarrifsIcon";
import { TemplatesIcon } from "../Icons/TemplatesIcon";
import { SecurityIcon } from "../Icons/SecurityIcon";
import { DuplicateIcon } from "../Icons/DuplicateIcon";
import { PersonxIcon } from "../Icons/PersonxIcon";
import { WhiteFilterTableIcon } from "../Icons/WhiteFilterTableIcon";
import { SortIcon } from "../Icons/SortIcon";
import { PlayIcon } from "../Icons/PlayIcon";
import { PauseIcon } from "../Icons/PauseIcon";
import { ExportIcon } from "../Icons/ExportIcon";
import { PlusIcon } from "../Icons/PlusIcon";

export default {
  name: 'Icon',
  props: {
    name: { type: String, required: true },
  },
  components: {
    AccountIcon,
    ActivateIcon,
    AppUsersIcon,
    ArrowDownIcon,
    BonusPointsIcon,
    CandidatesIcon,
    CompanyDetailsIcon,
    ContactUsIcon,
    DashboardIcon,
    DeactivateIcon,
    EllipsisMenuIcon,
    FilterIcon,
    HelpIcon,
    InsightsIcon,
    JobsIcon,
    KnowledgeBaseIcon,
    LogOutIcon,
    NotificationsIcon,
    PaymentsIcon,
    PencilIcon,
    DuplicateIcon,
    ViewIcon,
    ReportBugIcon,
    ResourceToolkitIcon,
    RoadmapIcon,
    SidebarToggleIcon,
    UndoIcon,
    UserSettingsIcon,
    ValidationErrorIcon,
    SingleMonthlyBudgetIcon,
    SingleOneOffBudgetIcon,
    SingleMonthlyCarryOverBudgetIcon,
    MultipleUserMonthlyBudgetIcon,
    MultipleUserOneOffBudgetIcon,
    MultipleUserMonthlyCarryOverBudgetIcon,
    SiteMonthlyBudgetIcon,
    SiteOneOffBudgetIcon,
    SiteMonthlyCarryOverBudgetIcon,
    MoreVerticalIcon,
    TrashIcon,
    PencilFilledIcon,
    FilterTableIcon,
    DragIndicatorIcon,
    AlertIcon,
    AppSettingsIcon,
    IntegrationsIcon,
    PortalUsersIcon,
    SchemeRulesIcon,
    SitesIcon,
    TarrifsIcon,
    TemplatesIcon,
    SecurityIcon,
    PersonxIcon,
    WhiteFilterTableIcon,
    SortIcon,
    PlayIcon,
    PauseIcon,
    PlusIcon,
    ExportIcon
  },
  computed: {
    componentName() {
      let camelCaseName = '';
      const nameWords = this.name.split('-');

      nameWords.forEach((nameWord) => {
        return camelCaseName += nameWord.charAt(0).toUpperCase() + nameWord.slice(1);
      });

      return `${camelCaseName}Icon`;
    }
  }
}
</script>

<template>
  <component :is="componentName" />
</template>