<template>
    <div ref="tariff-actions-wrapper" class="tariff-actions-wrapper">
        <button class="tariff-actions__button" :class="{'tariff-actions__button--active': modal}" @click="toggleModal">
            <icon name="ellipsis-menu" class="ellipsis" />
        </button>

        <ul v-if="modal" class="tariff-actions-modal">
            <div class="tariff-actions">
                <li class="tariff-action" @click="editTariff">
                    <icon name="pencil" />
                    <span>Edit</span>
                </li>

                <li class="tariff-action" @click="updateTariffStatus">
                    <template v-if="tariff.active">
                        <icon name="deactivate" />
                        <span>Deactivate</span>
                    </template>

                    <template v-else>
                        <icon name="activate" />
                        <span>Activate</span>
                    </template>
                </li>
            </div>
        </ul>

        <edit-tariff
            ref="edit-tariff"
            :tariff="tariff"
            :isCareSector="isCareSector"
            :milestone="milestone"
            @on-update="onUpdate"
        />

        <update-tariff-status
            ref="update-tariff-status"
            :tariff="tariff"
            @on-update="onUpdate"
        />
    </div>
</template>

<script>
import { Icon } from "../../../../components/Icon";
import { EditTariff } from "../EditTariff";
import { UpdateTariffStatus } from "../UpdateTariffStatus";

export default {
    name: 'TariffActions',
    props: {
        tariff: { type: Object, required: true },
        isCareSector: { type: Boolean, default: false },
		milestone: { type: Number, default: 12 },
    },
    components: { Icon, EditTariff, UpdateTariffStatus },
    data() {
        return {
            modal: false,
        };
    },
    methods: {
        toggleModal() {
            this.modal ? this.hideModal() : this.showModal();
        },
        showModal() {
            this.modal = true;
            document.addEventListener('click', this.handleClick);
        },
        hideModal() {
            this.modal = false;
            document.removeEventListener('click', this.handleClick);
        },
        handleClick(event) {
            const wrapper = this.$refs['tariff-actions-wrapper'];
            const outsideClick = !wrapper.contains(event.target);

            if (outsideClick) {
                this.hideModal();
            }
        },
        onUpdate(data) {
            this.$emit('on-update', data);

            this.hideModal();
        },
        editTariff() {
            this.$refs['edit-tariff'].showModal();

            this.hideModal()
        },
        updateTariffStatus() {
            this.$refs['update-tariff-status'].updateStatus(this.tariff);
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick);
    }
}
</script>

<style scoped lang="scss">
@import "./TariffActions.scss";
</style>
