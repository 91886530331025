<template>
  <div>
    <div class="grid-x grid-padding-x red_bg t_margin_1 align-middle">
      <div class="cell auto white">
        <p class="tb_margin_1"><strong>Name</strong></p>
      </div>
      <div class="cell auto white">
        <p class="tb_margin_1"><strong>Status</strong></p>
      </div>
      <div class="cell auto white">
        <p class="tb_margin_1"><strong>New EOI notifications</strong></p>
      </div>
      <div class="cell auto white">
        <p class="tb_margin_1"><strong>Still in post notifications</strong></p>
      </div>
    </div>
    <div v-for="job in jobs" class="grid-x grid-padding-x align-middle b_border_b1">
      <div class="cell auto">
        <p class="tb_margin_1">
          <a target="_blank" :href="'/jobs/preview/' + job.Token"><strong class="job-preview">{{ job.Job_Title }}</strong></a>
        </p>
      </div>
      <div class="cell auto">
        <p class="tb_margin_1">
          <strong>{{ job.Status }}</strong>
        </p>
      </div>
      <div class="cell auto">
        <p class="tb_margin_1">
          <strong>{{ job.eoi_notifications }}</strong>
        </p>
      </div>
      <div class="cell auto">
        <p class="tb_margin_1">
          <strong>{{ job.tenure_notifications }}</strong>
        </p>
      </div>
    </div>
    <template v-if="paginationData.last_page > 1">
      <paginate
        v-model="paginationData.current_page"
        :page-count="paginationData.last_page"
        :page-range="3"
        :margin-pages="2"
        :click-handler="getJobs"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination-custom'"
        :page-class="'page-item'"
        :active-class="'active'"
      ></paginate>
    </template>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate";

export default {
  components: {paginate: Paginate},
  props: ['id'],
  data() {
    return {
      jobs: [],
      paginationData: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0
      }
    }
  },
  methods: {
    getJobs(page) {
      let params = "?page=" + page, self = this;
      axios.get("/dashboard/super/clients/get-jobs/" + this.id + params)
        .then(function (response) {
          if (response.data.jobs) {
            self.jobs = response.data.jobs.data;
            self.paginationData.current_page = response.data.jobs.current_page;
            self.paginationData.from = response.data.jobs.from;
            self.paginationData.last_page = response.data.jobs.last_page;
            self.paginationData.per_page = response.data.jobs.per_page;
            self.paginationData.to = response.data.jobs.to;
            self.paginationData.total = response.data.jobs.total;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getJobs(1);
  }
}
</script>
<style>
.job-preview {
  color: #f55d73 !important;
}
</style>