<template>
        <div class="company-content">
          <div class="btn-row" style="padding-right:20px;padding-top:20px;"><a href="#" class="btn-edit" data-toggle="modal" data-backdrop="static" data-target="#edit-company">Edit</a></div>
          <div class="modal fade tab-modal" id="edit-company" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><img src="/images/exit-2.png" alt="img"></span></button>
                </div>
                <div class="modal-body">

				  <div class="grid-y">
				  <h3>Edit organisation details</h3>
                    <div class="grid-x" >
					  <div class="cell auto input-box">
						<label>
						  Company name
						  <input
							class=""
							type="text"
							placeholder="eg. Jane"
							aria-describedby="example1Hint1"
							aria-errormessage="firstname-error"
							required
							v-model="data.name"
						  />

						</label>
                        </div>
					</div>

					<div class="grid-x" >
					  <div class="cell auto input-box">
						<label>
						  Address line 1
						  <input
							class=""
							type="text"
							placeholder="eg. Jane"
							aria-describedby="example1Hint1"
							aria-errormessage="firstname-error"
							required
							v-model="data.address_line_1"
						  />

						</label>
                        </div>
					</div>
					<div class="grid-x" >
					  <div class="cell auto input-box">
						<label>
						  Address line 2
						  <input
							class=""
							type="text"
							placeholder="eg. Jane"
							aria-describedby="example1Hint1"
							aria-errormessage="firstname-error"
							required
							v-model="data.address_line_2"
						  />

						</label>
                        </div>
					</div>
		  <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <label>
              Town/City
              <input
                class=""
                type="text"
                placeholder="eg. City"
                aria-describedby="example1Hint1"
                aria-errormessage="firstname-error"
                required
                v-model="data.city"
              />

            </label>
          </div>

          <div class="cell auto input-box">
            <label>
              Post code
              <input
                class=""
                type="text"
                placeholder="eg. Post code"
                aria-describedby="example1Hint1"
                aria-errormessage="surname-error"
                required
                v-model="data.postcode"
              />
            </label>
          </div>
        </div>

				<div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <label>
              Telephone
              <input
                class=""
                type="text"
                placeholder="eg. 878789798"
                aria-describedby="example1Hint1"
                aria-errormessage="firstname-error"
                required
                v-model="data.contact_number"
              />

            </label>
          </div>

          <div class="cell auto input-box">
            <label>
              Website
              <input
                class=""
                type="text"
                placeholder="eg. google.com"
                aria-describedby="example1Hint1"
                aria-errormessage="surname-error"
                required
                v-model="data.website"
              />
            </label>
          </div>
        </div>


				  <div class="griddasdas">
				  <h3>Edit portal settings</h3>
			         <div class="left-box">
					    <label>Upload new logo</label>
					  <company-logo-component :customer="customer" :childComponant="childComponant"></company-logo-component>
                    </div>
                    <div class="right-box">
					<div class="grid-x input-box-2">
					  <div class="cell auto input-box">
						  <label>
							Email
							<input
							  type="text"
							  v-model="data.contact_email"
							  placeholder="Sub domain"
							  aria-errormessage="subdomain"
							  required
							/>

						  </label>
						</div>
						</div>
                      <div class="grid-x input-box-2">
					  <div class="cell auto input-box">
						  <label>
							Sub domain
							<input
							  type="text"
							  v-model="data.subdomain"
							  placeholder="Sub domain"
							  aria-errormessage="subdomain"
							  required
							/>

						  </label>
						</div>
						</div>
					<div class="grid-x input-box-2">
						<div class="cell auto input-box">
						  <label>
							Leaderboard
							<v-select
							  v-model="data.leaderboardAnon"
							  label="name"
							  :options="leaderboardAnonopt"
							  :close-on-select="true"
							  :reduce="referrer => referrer.id"
							></v-select>

						  </label>
						</div>
					  </div>

                        <div class="grid-x input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    Primary user
                                    <v-select
                                        v-model="data.primary_user"
                                        label="name"
                                        :options="portalUsers"
                                        :reduce="referrer => referrer.id"
                                        :change="onChange()"
                                    ></v-select>

                                </label>
                            </div>
                        </div>
                    </div>
				</div>
                  <input type="submit" value="Update" @click="update()" :disabled="btnEditCompany">
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

</template>

<script>
export default {

  props: ["customer"],
  data() {
    return {
			childComponant: new Vue(),
			leaderboardAnonopt: [{id: 1, name: 'Anonymous'}, {id: 0, name: 'Not anonymous'}],
			data: {
				id: this.customer.id,
				name: this.customer.name,
				address_line_1: this.customer.address_line_1,
				address_line_2: this.customer.address_line_2,
				city: this.customer.city,
				postcode:this.customer.postcode,
				contact_number: this.customer.contact_number,
				contact_email: this.customer.contact_email,
				website: this.customer.website,
				subdomain: this.customer.subdomain,
				leaderboardAnon: this.customer.leaderboardAnon,
				primary_user: null,
                primary_user_name: null,
			},
        portalUsers: [],
    };
  },
  methods: {
      onChange() {
          if (this.data.primary_user && this.portalUsers) {
              for (i = 0; i < this.portalUsers.length; i++) {
                  if (this.data.primary_user == this.portalUsers[i].id) {
                      this.data.primary_user_name = this.portalUsers[i].name;
                  }
              }
          }
      },
      getPortalUsers() {
          var self = this;
          axios.get("/dashboard/account/get-all-portal-users")
              .then(function (response) {
                  if (response.data) {
                      self.portalUsers = response.data.users;
                      self.data.primary_user = response.data.primaryUser;
                  }
              })
              .catch(function (error) {
                  console.log(error);
              });
      },
    update() {
      console.log('update company: ', this.data);
      var self = this;
      Vue.swal({
        title: "Updating...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        }
      });
      var self = this;
      axios
        .post("/dashboard/account", this.data, {})
        .then(function(response) {
		  $('#edit-company').hide();
          $('.modal-backdrop.show').hide();
          //self.message = self.title+" Added!"
          console.log("Company updated sucessfully. ", response.data);
          self.$root.$emit("EditCompany::Success", self.data);

          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Company Updated.',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "company-toast"
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s"
            },
            hideClass: {
              popup: "animated fadeOutLeft slow"
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs"
          });
        })
        .catch(function(error) {

          // handle error
          self.message = "Error!";
          console.log("save Candidate - error: ", error);
        });
        //.finally(function() {});
    },
  },
  computed: {
    btnEditCompany() {
      if (
        this.data.name &&
        this.data.address_line_1 &&
        this.data.city &&
        this.data.postcode &&
        this.data.contact_email &&
        this.data.website &&
		this.data.subdomain
      ) {
        return false;
      } else return true;
    }
  },
    mounted() {
        this.getPortalUsers();
    }

};
</script>
