<template>
    <div>
        <div v-if="!tariff || !tariff.default" class="tariff-name">
            <label for="tariff-name-input" class="tariff-name__label">Name of tariff</label>
            <input type="text" id="tariff-name-input" v-model="form.name" :class="{'has-error': errors.name}">

            <div v-if="errors.name" class="tariff-error">
                <icon name="validation-error" />
                <span>{{ errors.name[0] }}</span>
            </div>
        </div>

        <div class="tariff-table">
            <div class="tariff-row tariff-row--head">
                <div class="tariff-column">Stage</div>
                <div class="tariff-column tariff-column--with-input">Points earned</div>
            </div>

            <div class="tariff-row">
                <div class="tariff-column">Job Share</div>
                <div class="tariff-column tariff-column--with-input">
                    <input type="number" v-model.number="form.one_job_share" min="0" oninput="validity.valid||(value='');" class="text-right" :class="{'has-error': errors.one_job_share}" />
                </div>

                <div v-if="errors.one_job_share" class="tariff-error">
                    <icon name="validation-error" />
                    <span>{{ errors.one_job_share[0] }}</span>
                </div>
            </div>

            <div class="tariff-row">
                <div class="tariff-column">Expression of Interest</div>
                <div class="tariff-column tariff-column--with-input">
                    <input type="number" v-model.number="form.one_job_application" min="0" oninput="validity.valid||(value='');" class="text-right" :class="{'has-error': errors.one_job_application}" />
                </div>

                <div v-if="errors.one_job_application" class="tariff-error">
                    <icon name="validation-error" />
                    <span>{{ errors.one_job_application[0] }}</span>
                </div>
            </div>

            <div class="tariff-row">
                <div class="tariff-column">Interview Successful</div>
                <div class="tariff-column tariff-column--with-input">
                    <input type="number" v-model.number="form.interview_attended" min="0" oninput="validity.valid||(value='');" class="text-right" :class="{'has-error': errors.interview_attended}" />
                </div>

                <div v-if="errors.interview_attended" class="tariff-error">
                    <icon name="validation-error" />
                    <span>{{ errors.interview_attended[0] }}</span>
                </div>
            </div>

            <div class="tariff-row">
                <div class="tariff-column">Started Work</div>
                <div class="tariff-column tariff-column--with-input">
                    <input type="number" v-model.number="form.hired" min="0" oninput="validity.valid||(value='');" class="text-right" :class="{'has-error': errors.hired}" />
                </div>

                <div v-if="errors.hired" class="tariff-error">
                    <icon name="validation-error" />
                    <span>{{ errors.hired[0] }}</span>
                </div>
            </div>

            <div class="tariff-row">
                <div class="tariff-column">Employed {{ milestone }} Month(s)</div>
                <div class="tariff-column tariff-column--with-input">
                    <input type="number" v-model.number="form.employed_x_months" min="0" oninput="validity.valid||(value='');" class="text-right" :class="{'has-error': errors.employed_x_months}" />
                </div>

                <div v-if="errors.employed_x_months" class="tariff-error">
                    <icon name="validation-error" />
                    <span>{{ errors.employed_x_months[0] }}</span>
                </div>
            </div>

            <div class="tariff-row" v-if="isCareSector">
                <div class="tariff-column">Starter is New to Care</div>
                <div class="tariff-column tariff-column--with-input">
                    <input type="number" v-model.number="form.new_to_care" min="0" oninput="validity.valid||(value='');" class="text-right" :class="{'has-error': errors.new_to_care}" />
                </div>

                <div v-if="errors.new_to_care" class="tariff-error">
                    <icon name="validation-error" />
                    <span>{{ errors.new_to_care[0] }}</span>
                </div>
            </div>

            <div class="tariff-row tariff-row--total">
                <div class="tariff-column">Total</div>
                <div class="tariff-column text-right">{{ totalValue }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from "../../../../components/Icon"

export default {
    name: 'TariffForm',
    props: {
        tariff: { type: [Object, null], default: null },
        milestone: { type: Number, default: 12 },
        isCareSector: { type: Boolean, default: false },
        errors: { type: Object, default: () => ({}) }
    },
    components: { Icon },
    data() {
        return {
            form: {
                name: null,
                one_job_share: null,
                one_job_application: null,
                interview_attended: null,
                hired: null,
                employed_x_months: null,
                new_to_care: null,
            }
        }
    },
    computed: {
        totalValue() {
            return Object.values(this.form).reduce((acc, val) => {
                const parsedVal = parseInt(val);
                
                if (!isNaN(parsedVal)) {
                    acc += parsedVal;
                }
                
                return acc;
            }, 0);
        },
    },
    methods: {
        submit() {
            let form = { ...this.form };

            if (!this.isCareSector) {
                delete form.new_to_care;
            }

            if (this.tariff && this.tariff.default) {
                delete form.name;
            }

            this.$emit('on-submit', form);
        },
    },
    created() {
        if (this.tariff) {
            this.form = {
                name: this.tariff.name,
                one_job_share: this.tariff.one_job_share,
                one_job_application: this.tariff.one_job_application,
                interview_attended: this.tariff.interview_attended,
                hired: this.tariff.hired,
                employed_x_months: this.tariff.employed_x_months,
                new_to_care: this.tariff.new_to_care
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import "./TariffForm.scss";
</style>