<template>
  <div v-if="data && status != 'deleted'"
       style="width: 100%">
    <!-- CANDIDATE -->
    <div class="grid-x grid-margin-x mt-3 mb-3"
         :id="'candidate-' + data.id">
      <!--- COMMENTS TOOLTIP -->
      <b-tooltip v-if="comments"
                 :target="'comments-tooltip-' + data.id"
                 triggers="hover">{{
        data.comments.length > 255
        ? data.comments.substring(0, 255) + "..."
        : data.comments
        }}</b-tooltip>

      <b-tooltip v-if="data.contactmade == 0 && data.unit == 'h' && data.duration == 1"
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Yay! New candidate to contact</b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 0 &&
          data.unit == 'd' &&
          data.duration >= 1 &&
          data.future == 1
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Yay! New candidate to contact</b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 0 &&
          data.unit == 'h' &&
          data.duration >= 1 &&
          data.future == 1
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Yay! New candidate to contact</b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 0 && data.unit == 'm' && data.future == 1
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Yay! New candidate to contact</b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 0 && data.unit == 'h' && data.duration <= 2
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Get in touch ASAP</b-tooltip>

      <b-tooltip v-else-if="data.contactmade == 0 && data.unit == 'm'"
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Hurry! This candidate has been waiting too long</b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 0 && data.unit == 'h' && data.duration < 8
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Get in touch ASAP</b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 0 && data.unit == 'd' && data.duration >= 1
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Hurry! This candidate has been waiting too long</b-tooltip>

      <b-tooltip v-else-if="data.contactmade == 1 && data.unit == 'm'"
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Response time below target</b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 1 && data.unit == 'd' && data.duration >= 1
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Response time below target</b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 1 && data.unit == 'h' && data.duration < 2
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">Super fast response!
        <i class="fa fa-hand-paper-o"
           aria-hidden="true"></i></b-tooltip>

      <b-tooltip v-else-if="
          data.contactmade == 1 && data.unit == 'h' && data.duration < 8
        "
                 :target="'details-tooltip-' + data.id"
                 triggers="hover">On track response</b-tooltip>
      <div class="cell small-3 shrink">
        <div :class="{ 'rotten-candidate1': rotten }">
          <div class="grid-y">
            <div class="show"
                 v-if="!editMode">
              <div class="grid-x">
                <div class="cell small-2">
                  <div v-if="
                      data.contactmade == 0 &&
                      data.unit == 'm' &&
                      data.future == 1
                    ">
                    <div class="shake fa-pulse1 green-tiles1 text-center">
                      <img src="/images/clock/green.svg"
                           class="element" />
                    </div>
                  </div>

                  <div v-else-if="data.contactmade == 0 && data.unit == 'm'">
                    <div class="shake fa-pulse1 red-tiles1 text-center">
                      <img src="/images/clock/red.svg"
                           class="element" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 0 &&
                      data.unit == 'h' &&
                      data.future == 1
                    ">
                    <div class="shake fa-pulse1 green-tiles1 text-center">
                      <img src="/images/clock/green.svg"
                           class="element" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 0 &&
                      data.unit == 'h' &&
                      data.duration == 1
                    ">
                    <div class="shake fa-pulse1 green-tiles1 text-center">
                      <img src="/images/clock/green.svg"
                           class="element" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 0 &&
                      data.unit == 'h' &&
                      data.duration == 2
                    ">
                    <div class="shake fa-pulse1 amber-tiles1 text-center">
                      <img src="/images/clock/amber.svg"
                           class="element" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 0 &&
                      data.unit == 'h' &&
                      data.duration < 8
                    ">
                    <div class="shake fa-pulse1 amber-tiles1 text-center">
                      <img src="/images/clock/amber.svg"
                           class="element" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 0 &&
                      data.unit == 'd' &&
                      data.duration >= 1 &&
                      data.future == 1
                    ">
                    <div class="shake fa-pulse1 green-tiles1 text-center">
                      <img src="/images/clock/green.svg"
                           class="element" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 0 &&
                      data.unit == 'd' &&
                      data.duration >= 1
                    ">
                    <div class="shake fa-pulse1 red-tiles1 text-center">
                      <img src="/images/clock/red.svg"
                           class="element" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 1 &&
                      data.unit == 'h' &&
                      data.duration <= 1
                    ">
                    <div class="green-tiles1 text-center"
                         aria-hidden="true">
                      <img src="/images/clock/green.svg" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 1 &&
                      data.unit == 'h' &&
                      data.duration <= 8
                    ">
                    <div class="amber-tiles1 text-center"
                         aria-hidden="true">
                      <img src="/images/clock/amber.svg" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 1 &&
                      data.unit == 'h' &&
                      data.duration < 2 &&
                      data.future == 1
                    ">
                    <div class="green-tiles1 text-center">
                      <img src="/images/clock/green.svg" />
                    </div>
                  </div>

                  <div v-else-if="data.contactmade == 1 && data.unit == 'm'">
                    <div class="red-tiles1 text-center">
                      <img src="/images/clock/red.svg" />
                    </div>
                  </div>

                  <div v-else-if="
                      data.contactmade == 1 &&
                      data.unit == 'd' &&
                      data.duration >= 1
                    ">
                    <div class="red-tiles1 text-center">
                      <img src="/images/clock/red.svg" />
                    </div>
                  </div>

                  <div v-else-if="data.contactmade == 1">
                    <div class="green-tiles1 text-center">
                      <img src="/images/clock/green.svg" />
                    </div>
                  </div>

                  <div v-else>
                    <button class="cirlePinkbtn">
                      <b> {{ initiates }} </b>
                    </button>
                  </div>
                </div>

                <div class="cell small-8">
                  <h6 class="candidate-capital candidate-title">
                    {{ data.First_Name }} {{ data.Surname }}
                  </h6>
                </div>

                <div class="small-2 justify-content-end">
                  <span v-if="data.email_phone_verification !== null"
                        style="margin: 3px -1px; cursor: pointer;"
                        v-b-tooltip.hover.html="{
                          customClass: 'my-tooltip-class',
                          title: getTooltipMessage(data.email_phone_verification),
                        }">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         height="13.5"
                         width="12.5"
                         viewBox="0 0 448 512"
                         :style="{ ...getFillColor(data.email_phone_verification)}">
                      <path
                            d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32V64 368 480c0 17.7 14.3 32 32 32s32-14.3 32-32V352l64.3-16.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L64 48V32z" />
                    </svg>
                  </span>
                  <div v-if="comments"
                       class=""
                       align="right"
                       :id="'comments-tooltip-' + data.id"
                       v-b-modal="'modalview-' + data.id"
                       @click="
                      showDrop = false;
                      displayCandidateDetailModal();
                    ">
                    <div class="candidate-icon">
                      <img src="/images/candidate-comments.png" />
                    </div>
                  </div>
                  <div class=""
                       align="right"
                       :id="'details-tooltip-' + data.id"
                       v-b-modal="'modalview-' + data.id"
                       @click="
                      showDrop = false;
                      displayCandidateDetailModal();
                    ">
                    <div class="candidate-icon">
                      <img src="/images/candidate-details.png" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="grid-x small-offset-2">
                <div class="cell">
                  <h6 class="candidate-capital candidate-subtitle">
                    {{ jobname ? jobname : job.Job_Title }}
                  </h6>
                </div>
                <div class="cell">
                  <div class="candidate-details">
                    <label>
                      <div style="display: flex; flex-direction: row">
                        <div class="col-md-6"
                             style="flex: 0 0 40%">
                          <i class="fa fa-clock-o"
                             aria-hidden="true"></i>&nbsp; {{ data.created_at | moment("DD.MM.YYYY") }} &nbsp;
                        </div>
                        <div class="col-md-6">
                          <i class="fas fa-code-branch"></i> &nbsp;{{ referredBy }}
                        </div>
                      </div>
                    </label>
                    <label>
                      <div style="display: flex; flex-direction: row">
                        <div class="col-md-6"
                             style="flex: 0 0 40%">
                          <i class="fa"
                             aria-hidden="true"
                             style="margin-left: -5px; margin-right: -5px"><img src="/images/icon-24-bolt.png" /></i>
                          {{ data.bolt | replace("mos", "mo") }}&nbsp;
                        </div>
                        <div v-if="data.timeAndDurationCheck"
                             class="col-md-6"
                             style="cursor: pointer;"
                             v-b-tooltip.hover.html="data.tooltipFeatureOn ? {
                                customClass: 'my-tooltip-class',
                                title: 'Driving is assumed to be the mode of travel. The duration shown is an estimate given what is known about historical and live traffic conditions. It assumes a planned arrival time of 9am.',
                            } : null">
                          <i class="fa"
                             aria-hidden="true"
                             style="width:23px"><img src="/images/route1.png" /></i> {{ data.timeAndDuration }}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <candidate-status-component v-if="
          data.progress &&
          data.progress[0] &&
          data.progress[0].stage == 'Interview offered'
        "
                                  progressStep="1"
                                  :recent="data.progress[0].recent"
                                  :currentuser="currentuser"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  :candidateId="data.id"
                                  stageName="Interview offered"
                                  v-bind:data="data.progress[0]"
                                  v-bind:status="data.progress[0].status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-else
                                  progressStep="1"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  :candidateId="data.id"
                                  :recent="0"
                                  stageName="Interview offered"
                                  :currentuser="currentuser"
                                  v-bind:data="progress.interviewOffered"
                                  v-bind:status="progress.interviewOffered.status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-if="
          data.progress &&
          data.progress[1] &&
          data.progress[1].stage == 'Interview booked'
        "
                                  progressStep="2"
                                  :recent="data.progress[1].recent"
                                  :currentuser="currentuser"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  :candidateId="data.id"
                                  stageName="Interview booked"
                                  v-bind:data="data.progress[1]"
                                  v-bind:status="data.progress[1].status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-else
                                  progressStep="2"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  :candidateId="data.id"
                                  :recent="0"
                                  stageName="Interview booked"
                                  :currentuser="currentuser"
                                  v-bind:data="progress.interviewBooked"
                                  v-bind:status="progress.interviewBooked.status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-if="
          data.progress &&
          data.progress[2] &&
          data.progress[2].stage == 'Interview successful'
        "
                                  progressStep="3"
                                  :recent="data.progress[2].recent"
                                  :currentuser="currentuser"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  showNewToCare
                                  :candidateId="data.id"
                                  stageName="Interview successful"
                                  v-bind:data="data.progress[2]"
                                  yesLabel="Interview successful"
                                  noLabel="Interview unsuccessful"
                                  noShowLabel="No show - reschedule"
                                  noShowArchiveLabel="No show - archive"
                                  v-bind:status="data.progress[2].status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-else
                                  progressStep="3"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  showNewToCare
                                  :candidateId="data.id"
                                  :recent="0"
                                  :currentuser="currentuser"
                                  stageName="Interview successful"
                                  v-bind:data="progress.interviewSuccessful"
                                  yesLabel="Interview successful"
                                  noLabel="Interview unsuccessful"
                                  noShowLabel="No show - reschedule"
                                  noShowArchiveLabel="No show - archive"
                                  v-bind:status="progress.interviewSuccessful.status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-if="
          data.progress &&
          data.progress[3] &&
          data.progress[3].stage == 'Job offered'
        "
                                  progressStep="4"
                                  :candidate="data"
                                  :recent="data.progress[3].recent"
                                  :currentuser="currentuser"
                                  :job="job"
                                  :statusComp="statusComp"
                                  notAcceptLabel="Job offered - not accepted"
                                  :candidateId="data.id"
                                  stageName="Job offered"
                                  v-bind:data="data.progress[3]"
                                  v-bind:status="data.progress[3].status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-else
                                  progressStep="4"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  notAcceptLabel="Job offered - not accepted"
                                  :recent="0"
                                  :currentuser="currentuser"
                                  :candidateId="data.id"
                                  stageName="Job offered"
                                  v-bind:data="progress.jobOffered"
                                  v-bind:status="progress.jobOffered.status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-if="
          data.progress &&
          data.progress[4] &&
          data.progress[4].stage == 'Started work'
        "
                                  progressStep="5"
                                  :candidate="data"
                                  :recent="data.progress[4].recent"
                                  :currentuser="currentuser"
                                  :job="job"
                                  :statusComp="statusComp"
                                  noLabel="Failed to Start"
                                  :candidateId="data.id"
                                  stageName="Started work"
                                  v-bind:data="data.progress[4]"
                                  v-bind:status="data.progress[4].status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-else
                                  progressStep="5"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  noLabel="Failed to Start"
                                  :candidateId="data.id"
                                  :recent="0"
                                  :currentuser="currentuser"
                                  stageName="Started work"
                                  v-bind:data="progress.startedWork"
                                  v-bind:status="progress.startedWork.status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-if="
          data.progress &&
          data.progress[5] &&
          data.progress[5].stage == 'Employed for 12 months'
        "
                                  progressStep="6"
                                  :candidate="data"
                                  :recent="data.progress[5].recent"
                                  :currentuser="currentuser"
                                  :job="job"
                                  :statusComp="statusComp"
                                  :candidateId="data.id"
                                  stageName="Employed for 12 months"
                                  v-bind:data="data.progress[5]"
                                  v-bind:status="data.progress[5].status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>

      <candidate-status-component v-else
                                  progressStep="6"
                                  :candidate="data"
                                  :job="job"
                                  :statusComp="statusComp"
                                  :candidateId="data.id"
                                  :recent="0"
                                  stageName="Employed for 12 months"
                                  :currentuser="currentuser"
                                  v-bind:data="progress.employedTwelveMonths"
                                  v-bind:status="progress.employedTwelveMonths.status"
                                  :point-tariffs-feature-flag="pointTariffsFeatureFlag"></candidate-status-component>
    </div>

    <!-- View candidate details -->
    <div class="viewcandidates">
      <b-modal :id="'modalview-' + data.id"
               size="lg"
               class="tab-modal test"
               @click="show"
               @hidden="closeCandidateModal">
        <div v-if="currentuser.candidate_check == 1"
             class="dropdown-action view-drop-right"
             align="center">
          <button class="dropdown button drop-margin"
                  type="button"
                  @click="showMenu()">
            . . .
          </button>
          <div v-if="showDrop"
               class="dropdown-pane drop-pane radius-3">
            <ul>
              <li>
                <a @click.stop="
                    editCandidate = true;
                    editpop();
                  ">Edit</a>
              </li>
              <li>
                <a v-b-modal="'delete-' + data.id"
                   @click="deleteCheck = true">Delete</a>
              </li>
              <li>
                <a v-b-modal="'anonymise-' + data.id"
                   @click="anonCheck = true">Anonymise</a>
              </li>
            </ul>
          </div>
          <edit-candidate-component v-if="editCandidate"
                                    :childComponant="childComponant"
                                    :users="users"
                                    :currentuser="currentuser"
                                    :jobs="job"
                                    :editCandidate="editCandidate"
                                    :data="data"
                                    :isCareSector="isCareSector"
                                    ref="modal"></edit-candidate-component>
        </div>

        <div class="candidates-view">
          {{ data.First_Name }} {{ data.Surname }}
        </div>

        <div class="grid-y">
          <div class="grid-x input-box-2">
            <div class="cell auto input-box">
              <div class="candidates-view-lable">Phone</div>
              <div class="candidates-view-span">{{ data.Phone_Number }}</div>
            </div>
            <div class="cell auto input-box">
              <div class="candidates-view-lable">Email</div>
              <div class="candidates-view-email">{{ data.Email }}</div>
            </div>
          </div>

          <div class="grid-x input-box-2">
            <div class="cell auto input-box">
              <div class="candidates-view-lable">Role applied for</div>
              <b-tooltip target="role-applied">{{ jobname ? jobname : job.Job_Title }}
              </b-tooltip>
              <div id="role-applied"
                   class="candidates-view-span role_applied">
                {{ jobname ? jobname : job.Job_Title }}
              </div>
            </div>
            <div v-if="showFilterSites"
                 class="cell auto input-box">
              <div class="candidates-view-lable">Site</div>
              <div class="candidates-view-span">
                {{ data.siteName ? data.siteName : "Company Wide" }}
              </div>
            </div>
          </div>

          <div class="grid-x input-box-2">
            <div class="cell auto input-box">
              <div class="candidates-view-lable">Referred by</div>
              <div class="candidates-view-span">{{ referredBy }}</div>
            </div>
            <div class="cell auto input-box">
              <div class="candidates-view-lable">Expressed interest</div>
              <div class="candidates-view-span">
                {{ data.created_at | moment("DD.MM.YYYY") }}
              </div>
            </div>
          </div>

          <div class="grid-x input-box-2">
            <div class="cell auto input-box">
              <div class="candidates-view-lable">Postal code</div>
              <div class="candidates-view-span">{{ data.Postcode }}</div>
            </div>
            <template v-if="isCareSector">
              <div class="cell auto input-box">
                <div class="candidates-view-lable">New to care</div>
                <div class="candidates-view-span">{{ newcare }}</div>
              </div>
            </template>
          </div>

          <div class="grid-x input-box-2">
            <div v-if="job.show_car_query"
                 class="cell auto input-box">
              <div class="candidates-view-lable">Own transport?</div>
              <div class="candidates-view-span">{{ haveCar }}</div>
            </div>
            <div v-if="job.right_to_work_query_enabled"
                 class="cell auto input-box">
              <div class="candidates-view-lable">Right to work in {{ data.job.customer.microsite.region_expression }}?
              </div>
              <div class="candidates-view-span">{{ hasRightToWorkInRegion }}</div>
            </div>
          </div>

          <div v-if="data.point_tariff"
               class="grid-x input-box-2">
            <div class="cell auto input-box">
              <div class="candidates-view-lable">Tariff</div>
              <div class="candidates-view-span">{{ data.point_tariff.name }}</div>
            </div>
          </div>

          <div class="grid-x input-box-2">
            <div class="cell auto input-box">
              <div class="candidates-view-lable">Comments</div>
              <textarea v-model="data.comments"
                        name="comments"
                        id=""
                        rows="12"
                        placeholder="Enter comments"></textarea>
            </div>
          </div>

          <div class="candidate-modal-footer">
            <button id="cancel-candidate-btn"
                    @click="closeCandidateModal()"
                    class="button btn btn-float b_margin_0 no_wrap_btn">
              CANCEL
            </button>
            <button id="save-candidate-btn"
                    @click="updateCandidateComments()"
                    class="button pink_btn btn-float b_margin_0 no_wrap_btn ml-3">
              SAVE
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal :id="'modalview-confirmation-' + data.id"
               centered
               size="sl"
               no-close-on-backdrop
               no-close-on-esc
               @hidden="discardChanges()">
        <div class="cell">
          <h3 class="mb-6">Do you want to save changes?</h3>
        </div>
        <div class="cell auto popup-button">
          <button id="cancel-candidate-confirmation-btn"
                  @click="discardChanges()"
                  class="button btn btn-float b_margin_0 no_wrap_btn">
            DISCARD CHANGES
          </button>
          <button id="save-candidate-confirmation-btn"
                  @click="updateCandidateComments()"
                  class="button pink_btn btn-float b_margin_0 no_wrap_btn ml-3">
            SAVE
          </button>
        </div>
      </b-modal>
    </div>

    <b-modal v-if="deleteCheck"
             class="tab-modal"
             :id="'delete-' + data.id"
             size="lg"
             :no-close-on-backdrop="true"
             modal-class="del-candidate">
      <h3>Delete Candidate - {{ data.First_Name }} {{ data.Surname }}</h3>
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <label>Please select the main reason for deleting this candidate
              <v-select class="remove-candidate"
                        v-model="reason"
                        :reduce="(referrer) => referrer.id"
                        label="name"
                        :options="reasons"
                        :close-on-select="true"
                        placeholder="Select a reason">
              </v-select>
            </label>
          </div>
        </div>
      </div>
      <template v-if="reason">
        <div class="grid-y">
          <div class="grid-x input-box-2">
            <div class="cell auto input-box">
              <p>Are you sure?</p>
              <template v-if="reason != 3">
                <p>
                  This action can not be undone. All of the candidates details
                  will be removed from your database. Any points awarded for
                  this candidate will be removed. The candidate will no longer
                  count towards your 'Total candidate count' or any other
                  analytics.
                </p>
                <p>
                  If you wish to permanently delete the candidate
                  {{ data.First_Name }} {{ data.Surname }} please click
                  'Delete'.
                </p>
              </template>
              <template v-else-if="reason == 3">
                <p>
                  We only recommend deleting candidates that are for testing or
                  duplicates. Candidates that are unsuitable, unresponsive or
                  that fail to start should be archived, not deleted. This means
                  any points awarded for them up to this point will remain, and
                  they will continue to count in your candidate analytics.
                </p>
                <p>
                  If you still feel you wish to delete the candidate, please
                  email
                  <a class="support-email"
                     :href="'mailto:' + data.job.customer.microsite.support_email_address">{{
                    data.job.customer.microsite.support_email_address }}
                  </a>
                  stating the reason for deletion and we will be happy to
                  arrange this.
                </p>
                <p>
                  Otherwise, to continue with archiving the candidate
                  {{ data.First_Name }} {{ data.Surname }} please click
                  'Archive'.
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="cell auto popup-button">
          <input type="button"
                 class="button btn_lg btn orange_btn"
                 value="Cancel"
                 @click="deleteCheck = false" />
          <input v-if="reason != 3"
                 class="button btn_lg btn pink_btn"
                 type="submit"
                 value="Delete"
                 @click="deleteCandidate()" />
          <input v-else-if="reason == 3"
                 class="button btn_lg btn pink_btn"
                 type="submit"
                 value="Archive"
                 @click="archiveCandidate()" />
        </div>
      </template>
    </b-modal>

    <b-modal v-if="confirmmsgCheck"
             class="tab-modal"
             :id="'confirmmsg-' + data.id"
             size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <p>
              Thank you for requesting to delete the candidate
              <b>{{ data.First_Name }} {{ data.Surname }}</b>. This request has been submitted to our support team and
              will be
              actioned as soon as possible. We will notify you when the
              candidate has been deleted. If you have any questions about this,
              please use the chat window in the bottom right of your screen to
              get in touch.
            </p>
          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input class="delete-candidate"
               value="Okay"
               type="submit"
               @click="confirmCandidate()" />
      </div>
    </b-modal>

    <b-modal v-if="anonCheck"
             class="tab-modal"
             :id="'anonymise-' + data.id"
             size="lg">
      <h3>Anonymise Candidate - {{ data.First_Name }} {{ data.Surname }}</h3>
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <p>Are you sure you want to anonymise the candidate?</p>
          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input class="delete-candidate"
               type="submit"
               value="Yes"
               @click="anonCandidate()" />
        <input type="button"
               class="button btn_lg btn orange_btn delete-candidate anon-can"
               value="No"
               @click="
            anonCheck = false;
            showDrop = false;
          " />
      </div>
    </b-modal>

    <b-modal v-if="anonDone"
             class="tab-modal"
             :id="'anonymise-done-' + data.id"
             size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <p>
              The candidate has been anonymised and moved to archive. All their
              personal data has now been removed from the system. This will not
              have any effect on the referrers points or your analytics.
            </p>
          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input class="delete-candidate"
               type="submit"
               value="Ok"
               @click="anonDone = false" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    data: {
      type: Object,
    },
    initalStatus: {
      type: String,
      default: "saved",
    },
    users: {
      type: Array,
    },
    childComponant: {
      type: Object,
    },
    job: {
      type: Object,
    },
    currentuser: {
      type: Object,
    },
    tabId: {
      type: Number,
    },
    showFilterSites: {
      type: Boolean,
    },
    availableJobs: {
      type: Array,
    },
    isCareSector: {
      type: Boolean,
    },
    pointTariffsFeatureFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      candidate: this.data,
      comments: this.data.comments,
      editMode: false,
      viewdetailMode: false,
      options: this.users,
      newtocare: [
        { id: 1, name: "Yes" },
        { id: 2, name: "No" },
        { id: -1, name: "Unsure" },
      ],
      availableUsers: [],
      initiate: [],
      progress: {
        interviewOffered: { status: "" },
        interviewBooked: { status: "" },
        interviewSuccessful: { status: "" },
        jobOffered: { status: "" },
        employedTwelveMonths: { status: "" },
        startedWork: { status: "" },
      },
      statusComp: new Vue(),
      status: this.initalStatus,
      uid: this.randomString(15),
      disabled: 0,
      editCandidate: false,
      showDrop: false,
      reason: "",
      deleteCheck: false,
      anonCheck: false,
      confirmmsgCheck: false,
      anonDone: false,
      reasons: [
        { id: 1, name: "This is a test candidate" },
        { id: 2, name: "This candidate is a duplicate" },
        { id: 3, name: "Other" },
      ],
      otherQuestions: [],
      newToCareQuestion: [],
    };
  },
  watch: {
    candidate: {
      handler(val) {
        if (this.status != "new") {
          this.status = "unsaved";
        }
      },
      deep: true,
    },
  },
  methods: {
    randomString(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    displayCandidateDetailModal(id) {
      this.editCandidate = false;
      if (this.viewdetailMode == false) {
        this.viewdetailMode = true;
      }
    },
    edit() {
      if (this.editMode == false) {
        this.editMode = true;
      }
    },
    show() {
      if (this.editMode) {
        this.editMode = false;
      }
    },
    cancel() {
      this.editMode = false;
    },
    deleteModel() {
      // Use sweetalert2

      var self = this;
      this.$swal({
        title: "Are you sure you want to delete this candidate?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F84366",
        cancelButtonColor: "#FA9748",
        confirmButtonText: "Yes, delete candidate",
      }).then((result) => {
        if (result.value) {
          var message = this.$swal({
            title: "Deleted!",
            text: "The candidate has been delete",
            type: "success",
            confirmButtonColor: "#FA9748",
          });

          if (self.status == "new") {
            self.deleteComponant();
            //Show delete successful message
            return message;
          }

          if (self.data.id) {
            axios
              .post(
                "/dashboard/candidates/delete/" + self.data.id,
                self.data.id
              )
              .then(function (response) {
                // handle success
                self.deleteComponant();
                //Show delete successful message
                return message;
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });
          }
        }
      });
    },
    deleteComponant() {
      this.status = "deleted";
    },
    confrim() {
      var self = this;
      self.statusComp.$emit("save");
    },
    save() {
      var self = this;

      Vue.swal({
        title: "Saving...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });

      if (this.status != "deleted") {
        if (this.status == "unsaved" && this.data.id != undefined) {
          self.statusComp.$emit("save");
        }
      }
    },

    updateCandidateComments() {
      Vue.swal({
        title: "Updating comments...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      var self = this;
      axios
        .post("/dashboard/candidates/update-candidate-comments", {
          candidateId: self.data.id,
          comments: self.data.comments,
        })
        .then(function (response) {
          self.comments = self.data.comments;
          self.confirmCheck = false;
          self.$parent.editCandidate = false;
          self.$parent.viewdetailMode = false;
          self.$root.$emit("updatedCandidates", response.data);
          self.closemodel("modalview-" + self.data.id);
          self.closemodel("modalview-confirmation-" + self.data.id);
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Candidate comments updated.',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          self.$root.$emit("bv::hide::modal", "modal-editcan");
        })
        .catch(function (error) {
          // handle error
          self.closemodel("modalview-" + self.data.id);
          self.closemodel("modalview-confirmation-" + self.data.id);
          console.log(error);
        });
    },

    // Comments Modal
    closeCandidateModal(bvModalEvent) {
      bvModalEvent && bvModalEvent.preventDefault();
      if (
        (!this.comments && !this.data.comments) ||
        this.data.comments == this.comments
      ) {
        this.discardChanges();
      } else if (this.data.comments != this.comments) {
        this.$bvModal.show("modalview-confirmation-" + this.data.id);
      }
    },

    discardChanges() {
      this.$bvModal.hide("modalview-confirmation-" + this.data.id);
      this.$bvModal.hide("modalview-" + this.data.id);
      this.clearComments();
    },

    clearComments() {
      this.data.comments = this.comments ? this.comments : "";
    },

    closemodel(id) {
      this.$bvModal.hide(id);
    },
    editpop() {
      this.editCandidate = true;
      this.$root.$emit("bv::show::modal", "modal-editcan");
    },
    showMenu() {
      if (this.showDrop == false) {
        this.showDrop = true;
      } else {
        this.showDrop = false;
      }
    },
    deleteCandidate() {
      Vue.swal({
        title: "Deleting...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      var self = this;
      axios
        .post("/dashboard/candidates/delete-candidate", {
          candidate_id: this.data.id,
          reason: this.reason,
        })
        .then(function (response) {
          self.$root.$emit("updatedCandidates", response.data);
          self.deleteCheck = false;
          self.showDrop = false;
          self.closemodel("modalview-" + self.data.id);

          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Candidate deleted',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          setTimeout(function () {
            location.reload();
          }, 5000);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    archiveCandidate() {
      Vue.swal({
        title: "Archiving...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      var self = this;
      axios
        .post("/dashboard/candidates/archive-candidate", {
          candidate_id: this.data.id,
        })
        .then(function (response) {
          self.$root.$emit("updatedCandidates", response.data);
          self.deleteCheck = false;
          self.showDrop = false;
          self.closemodel("modalview-" + self.data.id);

          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Candidate archived',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          setTimeout(function () {
            location.reload();
          }, 5000);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    confirmCandidate() {
      this.confirmmsgCheck = false;
    },
    anonCandidate() {
      this.anonDone = false;
      Vue.swal({
        title: "Submitting...",
        html: "",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
        },
      });
      var self = this;
      axios
        .post("/dashboard/candidates/anon-candidate", { candidate: this.data })
        .then(function (response) {
          self.$root.$emit("updatedCandidates", response.data);
          self.anonCheck = false;
          self.showDrop = false;
          self.closemodel("modalview-" + self.data.id);
          Vue.swal.close();
          self.anonDone = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    clear() {
      this.statusComp.$emit("clear");
    },
    convertMS(milliseconds) {
      var day, hour, minute, seconds, weeks, months;
      seconds = Math.floor(milliseconds / 1000);
      minute = Math.floor(seconds / 60);
      seconds = seconds % 60;
      hour = Math.floor(minute / 60);
      minute = minute % 60;
      day = Math.floor(hour / 24);
      hour = hour % 24;
      weeks = Math.floor(day / 7);
      day = day % 24;
      months = Math.floor(weeks / 4.32);
      weeks = weeks % 7;
      return {
        day: day,
        hour: hour,
        weeks: weeks,
        months: months,
        minute: minute,
        seconds: seconds,
      };
    },
    addWorkingDay(days) {
      var weekDayName = moment(days).format("dddd");
      var weekhours = moment(days).format("HH:mm");

      var now = new Date();

      var ms = moment(now, "YYYY-MM-DD HH:mm:ss").diff(
        moment(days, "YYYY-MM-DD HH:mm:ss")
      );

      var timespanArr = this.convertMS(ms);

      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
      let arr = s.split(":");
      let hour = parseInt(arr[0]);
      let min = parseInt(arr[1]);
      let sec = parseInt(arr[2]);

      if (hour == "0") {
        if (weekhours > "09:00" && weekhours < "05:00") {
          return (min / 60).toFixed(1);
        } else {
          return 0;
        }
      } else {
        if (hour < 65) {
          if (weekDayName == "Friday" && weekhours > "16:01") {
            return hour - 65;
          } else {
            if (weekhours > "16:01") {
              return hour - 17;
            } else if (weekhours > "09:00") {
              return hour;
            } else if (weekhours < "09:00") {
              var a = moment([weekhours], "HH:mm");
              var b = moment(["09:00"], "HH:mm");

              // calculate total duration
              var duration = moment.duration(
                moment("09:00", "HH:mm").diff(moment(weekhours, "HH:mm"))
              );

              // duration in hours
              var hours = parseInt(duration.asHours());

              // duration in minutes
              var minutes = parseInt(duration.asMinutes()) % 60;

              return parseInt(hour) - parseInt(hours);
            } else {
              return Math.round(hour / 3);
            }
          }
        } else {
          return Math.round(hour / 3);
        }
      }
    },
    boltDay(days) {
      var weekDayName = moment(days).format("dddd");
      var weekhours = moment(days).format("HH:mm");

      var now = new Date();

      var ms = moment(now, "YYYY-MM-DD HH:mm:ss").diff(
        moment(days, "YYYY-MM-DD HH:mm:ss")
      );

      var timespanArr = this.convertMS(ms);
      console.log(timespanArr);
      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
      let arr = s.split(":");
      let hour = parseInt(arr[0]);
      let min = parseInt(arr[1]);
      let sec = parseInt(arr[2]);

      if (hour == "0") {
        if (weekhours > "09:00" && weekhours < "05:00") {
          return (min / 60).toFixed(1) + " h";
        } else {
          return 0 + " h";
        }
      } else {
        if (hour < 65) {
          if (weekDayName == "Friday" && weekhours > "16:01") {
            return hour - 65 + " h";
          } else {
            if (weekhours > "16:01") {
              return hour - 17 + " h";
            } else if (weekhours > "09:00") {
              return hour + " h";
            } else if (weekhours < "09:00") {
              var a = moment([weekhours], "HH:mm");
              var b = moment(["09:00"], "HH:mm");

              // calculate total duration
              var duration = moment.duration(
                moment("09:00", "HH:mm").diff(moment(weekhours, "HH:mm"))
              );

              // duration in hours
              var hours = parseInt(duration.asHours());

              // duration in minutes
              var minutes = parseInt(duration.asMinutes()) % 60;

              return parseInt(hour) - parseInt(hours) + " h";
            } else {
              if (timespanArr.months > 0) {
                return timespanArr.months + "m";
              } else if (timespanArr.weeks > 0) {
                return timespanArr.weeks + "w";
              } else if (timespanArr.day > 0) {
                return timespanArr.day + "d";
              } else if (timespanArr.hour > 0) {
                return timespanArr.hour + "h";
              } else {
                return Math.round(hour / 3) + " h";
              }
            }
          }
        } else {
          if (timespanArr.months > 0) {
            return timespanArr.months + "m";
          } else if (timespanArr.weeks > 0) {
            return timespanArr.weeks + "w";
          } else if (timespanArr.day > 0) {
            return timespanArr.day + "d";
          } else if (timespanArr.hour > 0) {
            return timespanArr.hour + "h";
          } else {
            return Math.round(hour / 3) + " h";
          }
        }
      }
    },
    getTooltipMessage(emailPhoneVerification) {
      // 0 = Red Flad
      // 1 = Orange Flag
      // 2 = Green Flag
      // 3 = Gray Flag
      var tooltipLink = 'https://support.carefriends.co.uk/en/articles/8861279-validation-of-candidate-contact-details';
      switch (emailPhoneVerification) {
        case 0:
          return `We were unable to validate this candidate's email and phone number. You may have problems contacting this candidate. Click <a href="${tooltipLink}" target="_blank">here</a> to find out more.`;
        case 1:
          return `We have only been able to validate one of the candidate's contact methods. You may need to try both methods of contact. Click <a href="${tooltipLink}" target="_blank">here</a> to find out more.`;
        case 2:
          return `The candidate's email and phone number have both been validated successfully. Click <a href="${tooltipLink}" target="_blank">here</a> to find out more.`;
        case 3:
          return `This candidate's email or mobile number has been edited since the initial expression of interest`;
        default:
          return "";
      }
    },
    getFillColor(emailPhoneVerification) {
      // 0 = Red Flad
      // 1 = Orange Flag
      // 2 = Green Flag
      // 3 = Gray Flag
      switch (emailPhoneVerification) {
        case 0:
          return { fill: '#ef5d72', stroke: '#ef5d72', strokeWidth: '30px' };
        case 1:
          return { fill: '#faab5f', stroke: '#faab5f', strokeWidth: '30px' };
        case 2:
          return { fill: '#5dc2a6', stroke: '#5dc2a6', strokeWidth: '30px' };
        case 3:
          return { fill: '#9297ae', stroke: '#9297ae', strokeWidth: '30px' };
        default:
          return { fill: '#9297ae', stroke: '#9297ae', strokeWidth: '30px' };
      }
    }
  },
  mounted() {
    //end avaiable job here
    if (this.status == "new") {
      this.editMode = true;
    }

    this.childComponant.$on("save", this.save);

    this.childComponant.$on("clear", this.clear);

    this.availableUsers = this.users.map((user) =>
    ({
      id: user.id,
      name: `${user.first_name} ${user.surname}`
    })
    )
  },
  computed: {
    jobname: function () {
      return this.availableJobs.find((job) => job.id === this.data.job_id)?.name;
    },
    referredBy: function () {
      const user = this.users.find((user) => user.id === this.data.user_id) || this.data.user;

      if (user) {
        // Ensure we don't include the "(Deleted)" prefix when displaying the referrer!
        return `${user.first_name} ${user.surname}`.replace("(Deleted)", "").trim();
      }

      return "Unknown user";
    },
    progressIndex: function () {
      if (this.data.user) {
        for (var i = this.data.user.candidates.length - 1; i >= 0; i--) {
          if (this.data.user.candidates[i].id == this.data.id) {
            return i;
          }
        }
      }
    },
    initiates: function () {
      var fname = this.data.First_Name;
      var lanme = this.data.Surname;
      return fname.charAt(0) + "" + lanme.charAt(0);
    },
    newcare: function () {
      if (this.data.new_to_care == 1) {
        return "Yes";
      } else if (this.data.new_to_care == 2) {
        return "No";
      } else if (this.data.new_to_care == -1) {
        return "Unsure";
      } else {
        return this.data.new_to_care;
      }
    },
    haveCar: function () {
      if (this.data.have_car == 1) {
        return "Yes";
      } else if (this.data.have_car == 0) {
        return "No";
      } else if (this.data.have_car == 2) {
        return "Unsure";
      }
    },
    hasRightToWorkInRegion: function () {
      if (this.data.has_right_to_work_in_region == 1) {
        return "Yes";
      } else if (this.data.has_right_to_work_in_region == 0) {
        return "No";
      }
      return "-";
    },
    btnEditCanidate() {
      if (
        this.data.First_Name &&
        this.data.Surname &&
        this.data.Email &&
        this.data.user_id &&
        this.data.job_id &&
        this.data.Phone_Number &&
        this.data.newtocare &&
        this.data.Postcode
      ) {
        return false;
      } else return true;
    },

    btnDeleteCanidate() {
      if (this.reason) {
        return false;
      } else {
        return true;
      }
    },
    rotten: function () {
      if (this.data.rotten || this.data.starter_rotten) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {
    replace: function (st, rep, repWith) {
      const result = st.split(rep).join(repWith);
      return result;
    },
  },
};
</script>
<style>
.role_applied {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.candidates-view-email {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.candidates-view-email:focus,
.candidates-view-email:hover {
  display: contents;
  word-wrap: break-word;
  white-space: normal;
}
</style>
