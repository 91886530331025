$(document).ready(function () {
    //Display custom popup (Swal) when user clicks a link with unsaved progress changes
    if ($('body').hasClass('candidate-page')) {
        $(".candidate-prevent a").click(function (e) {
            candidateAlertForUnsavedChanges(e, this);
        });
    }
    /**
     * Display default browser alert for unsaved changes
     * Only when the browser is closed. Not needed for click events because
     * for clicks we have implemented our own custom alerts.
     */
    $(window).bind('beforeunload', function () {
        if ($(".candidate-page .unsaved-changes")[0] && (
            !$('.candidate-prevent a').hasClass('allow-redirect') &&
            !$('#selected-site').hasClass('allow-redirect')
        )) {
            return "You have unsaved changes. Are you sure you want to navigate away from this page?";

        }
    });
});

/**
 * This function alerts user with a popup if there are unsaved changes
 *
 * @param e
 * @param element
 */
function candidateAlertForUnsavedChanges(e, element) {

    if (jQuery(element).hasClass('allow-redirect')) {
        return;
    }

    if ($(".unsaved-changes")[0] && !jQuery(element).hasClass('allow-redirect')) {
        e.preventDefault();

        Vue.swal({
            width: 600,
            title: 'You have unsaved changes. Are you sure you want to navigate away from this page?',
            text: "",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f55d73',
            confirmButtonText: 'Yes - leave anyway',
            cancelButtonText: 'No - take me back',
            reverseButtons: true,
            customClass: {
                container:'swal-before-leaving',
                confirmButton: 'button pink_btn b_margin_0 no_wrap_btn',
                cancelButton: 'button orange_btn b_margin_0 no_wrap_btn'
              },
        }).then((result) => {
                if (result.value) {
                    console.log(e.type);
                    jQuery('.candidate-page .candidate-prevent a').addClass('allow-redirect');
                    window.location = jQuery(element).attr('href');
                }
            }
        );
    }

}

