<template>
    <div>
        <div class="job-row">
            <label class="job-label"
                   for="Image">
                IMAGE
                <span class="tooltip-span"
                      id="feature_img"
                      style="padding: 0 2px">
                    <img src="/images/InfoIcon.png"
                         aria-label="Site Info Tooltip" />
                </span>
                <b-tooltip target="feature_img"
                           triggers="hover">
                    If you don't choose a custom featured image, we will display a default one.
                </b-tooltip>
            </label>
            <div class="img-drag-drop-box">
                <div class="img-uploader"
                     @dragenter="isDragging = true"
                     @dragleave="isDragging = false"
                     @dragover.prevent
                     @drop="handleDrop"
                     :class="{ dragging: isDragging }">
                    <img src="/images/drag.png"
                         alt="Drag and Drop Icon" />
                    <span>
                        Drag and drop an image or
                        <input type="file"
                               ref="fileInput"
                               @change="handleFileChange"
                               hidden />
                        <a href="#"
                           @click.prevent="triggerFileInput">browse</a>
                        to choose a file.<br />
                        Accepted file types: PNG or JPEG.<br />
                        Recommended size: 1200 x 628 px.
                    </span>
                </div>
            </div>
        </div>
        <div class="job-row"
             v-if="imageName !== null && imageName !== ''">
            <div class="img-name">
                <div class="job-image-name-placeholder">
                    <div class="imageName-left">{{ imageName }}</div>
                    <div class="imageName-right"
                         @click="clearImage">
                        <img src="/images/cancel.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImageUploader",
    data() {
        return {
            isDragging: false,
            imageName: this.getImageNameFromStorage(),
        };
    },
    methods: {
        getImageNameFromStorage() {
            return localStorage.getItem('imageName');
        },
        saveImageNameToStorage(name) {
            localStorage.setItem('imageName', name);
            this.imageName = name;
        },
        handleDrop(event) {
            event.preventDefault();
            event.stopPropagation();
            this.isDragging = false;
            this.processFile(event.dataTransfer.files[0]);
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.processFile(file);
                this.$refs.fileInput.value = null;
            }
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        processFile(file) {
            if (!file || !file.type.match("image.*")) {
                this.showErrorToast("The image must be in either JPEG or PNG format.");
                return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width > 1200 || img.height > 628) {
                    this.showErrorToast("The image cannot be uploaded as it is over the maximum dimensions of 1200 x 628 pixels. Please re-size the image and try again.");
                } else if (file.size > 1024 * 1024) {
                    this.showErrorToast("The image size exceeds the allowed limit.");
                } else {
                    this.uploadImage(file);
                }
            };
        },
        uploadImage(file) {
            const formData = new FormData();
            formData.append("image", file);

            this.$swal({
                title: "Uploading...",
                allowOutsideClick: false,
                onBeforeOpen: () => this.$swal.showLoading(),
            });

            axios.post("/dashboard/validate-default-job-banner", formData, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(response => {
                    this.$swal.close();
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.$emit("input", response.data.imageName);
                        this.saveImageNameToStorage(file.name);
                    };
                    reader.readAsDataURL(file);
                })
                .catch(error => {
                    this.$swal.close();
                    error.response.data.errors.forEach(this.showErrorToast);
                });
        },
        clearImage() {
            this.saveImageNameToStorage(null);
            this.$refs.fileInput.value = null;
            this.$emit("input", "");
        },
        showErrorToast(message) {
            Vue.swal({
                html: `<span class="success-circle"><i class="fas fa-exclamation-circle"></i></span> ${message}`,
                confirmButtonColor: "#ea2020",
                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#ea2020",
                customClass: { container: "points-toast" },
                showClass: {
                    popup: "animated fadeInLeft fast swal2-margin-top",
                    icon: "animated heartBeat delay-1s",
                },
                hideClass: {
                    popup: "animated fadeOutLeft slow swal2-margin-top",
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs",
            });
        },
    }
};
</script>

<style scoped lang="scss">
@import "./ImageUploader.scss";
</style>