<script>
export default {
  name: 'BonusPointsIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 4C11.9171 4 11.8331 4.045 11.7951 4.137L9.69008 9.22L4.20408 9.652C4.00808 9.667 3.92808 9.913 4.07808 10.042L8.26208 13.615L6.97708 18.965C6.94108 19.114 7.06008 19.239 7.19308 19.239C7.23108 19.239 7.27108 19.229 7.30908 19.206L12.0001 16.331L16.6911 19.206C16.7291 19.229 16.7681 19.239 16.8071 19.239C16.9401 19.239 17.0581 19.114 17.0221 18.965L15.7381 13.615L19.9221 10.042C20.0721 9.913 19.9921 9.667 19.7951 9.652L14.3101 9.22L12.2051 4.137C12.1671 4.045 12.0831 4 12.0001 4ZM12.0001 6.779L13.2011 9.679L13.4831 10.359L14.2161 10.417L17.3461 10.663L14.9591 12.702L14.3991 13.18L14.5711 13.895L15.3041 16.948L12.6271 15.307L12.0001 14.923L11.3731 15.307L8.69608 16.948L9.42908 13.895L9.60108 13.18L9.04108 12.702L6.65408 10.663L9.78408 10.417L10.5171 10.359L10.7981 9.679L12.0001 6.779Z" fill="currentColor"/>
  </svg>
</template>