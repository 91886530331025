<template>
  <section :style="cssVariables">
    <div class="tarifs-header">
      <h2 style="padding: 0 !important;">
        Use this page to add or edit role specific points tariffs for each job and specify the number of points awarded
        for each stage of your referral process.
      </h2>

      <add-tariff 
        v-if="paid && hasPermission"
        :isCareSector="isCareSector"
        :milestone="milestone"
        @on-add="onAdd"></add-tariff>
    </div>

    <tariffs-table-data
      ref="tariffs-table-data"
      :isCareSector="isCareSector"
      :milestone="milestone"
      :hasPermission="hasPermission"
      :paid="paid"
    />
  </section>
</template>

<script>
import AddTariff from "../components/AddTariff/AddTariff.vue";
import TariffsTableData from "../components/TariffsTable/TariffsTableData.vue";
import { cssVariables } from "../../../lib/css-variables";

export default {
  components: { AddTariff, TariffsTableData },
  name: "Tariffs",
  props: {
    isCareSector: { type: Boolean, default: true },
    hasPermission: { type: Boolean, default: false },
    milestone: { type: Number, default: 12 },
    paid: { type: Boolean, default: false },
  },
  methods: {
    onAdd(data) {
      this.$refs["tariffs-table-data"].addTariff(data);
    },
  },
  created() {
    this.cssVariables = cssVariables();
  },
};
</script>

<style scoped lang="scss">
@import "./Tariffs.scss";
</style>
