const primaryButton = {
    backgroundColor: "#f55d73",
    color: "#FFF",
    border: "0",
    ":hover": {
        backgroundColor: "#ec5168",
        color: "#fff"
    }
};

module.exports = {
    license: "279bc505-8da7-4cbb-8633-29032b91d9e2",
    config: {
        fields: [
            {label: "Full name", key: "full_name"},
            {label: "First name", key: "first_name"},
            {label: "Surname", key: "surname"},
            {   label: "Email", 
                key: "email",
                validators: [
                    {
                        validate: "required_without",
                        fields: ["mobile_number"],
                        error: "Please enter either Mobile Number or Email, both fields can not be left blank."
                    }
                ]
            },
            {
                label: "Mobile number", 
                key: "mobile_number",
                validators: [
                    {
                        validate: "required_without",
                        fields: ["email"],
                        error: "Please enter either Mobile Number or Email, both fields can not be left blank."
                    }
                ]
            },
            {label: "Site name", key: "site_name"},
            {label: "Payroll Info", key: "PaymentInfo"}
        ],
        type: "app users",
        allowInvalidSubmit: false,
        managed: true,
        allowCustom: true,
        disableManualInput: true,
        theme: {
            buttons: {
                primary: primaryButton,
                success: primaryButton
            },
            dropzone: {
                fileTypes: {
                    color: "#363b54"
                },
                accepted: {
                    color: "#363b54"
                }
            },
            header: {
                title: {
                    color: "#363b54"
                },
            },
            global: {
                fontFamily: "InterUI, sans-serif"
            }
        },
        i18nOverrides: {
            en: {
                otherLocales: ["en-US", "en-CA", "en-GB"],
                overrides: {
                    dropzone: {
                        button: "Choose file to upload"
                    },
                    fileTypes: "You can upload employees' details as an excel file, .csv or any other sort of spreadsheet." +
                        "You need to include: employee name, email, phone number and site (if you have more than one office or location)." +
                        "The columns can be in any order, and include any column headers, but there can only be one employee per row.",
                    header: "You are authorising {{number}} app users",
                    header2: "Bulk authorisation of employees as app users"
                }
            }
        }
    }
};
