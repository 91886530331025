<template>
    <div ref="question-actions-wrapper"
         class="question-actions-wrapper">
        <button class="question-actions__button"
                :class="{'question-actions__button--active': modal}"
                @click="toggleModal">
            <icon name="ellipsis-menu"
                  class="ellipsis" />
        </button>

        <ul v-if="modal"
            class="question-actions-modal">
            <div class="question-actions">
                <li v-for="action in actions"
                    :key="action.name"
                    class="question-action"
                    @click="action.handler">
                    <icon :name="action.icon" />
                    <span>{{ action.label }}</span>
                </li>
            </div>
        </ul>

        <edit-question ref="edit-question"
                       :question="question"
                       :currentuser="currentuser"
                       @on-update="onUpdateQuestion" />
    </div>
</template>

<script>
import { Icon } from "../../../../components/Icon";
import EditQuestion from "../EditQuestion";

export default {
    name: 'QuestionActions',
    props: {
        question: { type: Object, required: true },
        currentuser: { type: Object, required: true }
    },
    components: { Icon, EditQuestion },
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        actions() {
            const actions = [
                { name: "edit", label: "Edit", icon: "pencil", handler: this.editQuestion },
                // { name: "duplicate", label: "Duplicate", icon: "duplicate", handler: this.duplicateQuestion },
            ];

            if (this.question.can_delete) {
                actions.push({ name: "delete", label: "Delete", icon: "trash", handler: this.deleteQuestion });
            }

            return actions;
        }
    },
    methods: {
        toggleModal() {
            this.modal ? this.hideModal() : this.showModal();
        },
        showModal() {
            this.modal = true;
            document.addEventListener('click', this.handleClick);
        },
        hideModal() {
            this.modal = false;
            document.removeEventListener('click', this.handleClick);
        },
        handleClick(event) {
            const wrapper = this.$refs['question-actions-wrapper'];
            const outsideClick = !wrapper.contains(event.target);

            if (outsideClick) {
                this.hideModal();
            }
        },
        onUpdateQuestion(data) {
            this.$emit('on-update', data);
            this.hideModal();
        },
        editQuestion() {
            this.$refs['edit-question'].showModal();
            this.hideModal()
        },
        duplicateQuestion() {
            console.log('Duplicate Question');
            this.hideModal()
        },
        deleteQuestion() {
            console.log('Delete Question');
            this.hideModal()
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick);
    }
}
</script>

<style scoped lang="scss">
@import "./QuestionActions.scss";
</style>