<template>
  <div>
    <div class="tariffs-table">
      <div class="tariff-row tariff-row--head">
        <div class="tariff-column tariff-column--left-align">Tariff</div>
        <div class="tariff-column">Job share</div>
        <div class="tariff-column">Expression of interest</div>
        <div class="tariff-column">Interview successful</div>
        <div class="tariff-column">Started work</div>
        <div class="tariff-column">Employed {{ milestone }} month(s)</div>
        <div v-if="isCareSector" class="tariff-column">Starter is new to Care</div>
        <div class="tariff-column tariff-column--status tariff-column--left-align">Status</div>
        <div v-if="hasPermission" class="tariff-column tariff-column--actions"></div>
      </div>

      <div class="tariff-row" v-for="tariff in tariffs" :key="tariff.id">
        <div class="tariff-column tariff-column--left-align">{{ tariff.name }}</div>
        <div class="tariff-column">{{ tariff.one_job_share }}</div>
        <div class="tariff-column">{{ tariff.one_job_application }}</div>
        <div class="tariff-column">{{ tariff.interview_attended }}</div>
        <div class="tariff-column">{{ tariff.hired }}</div>
        <div class="tariff-column">{{ tariff.employed_x_months }}</div>
        <div v-if="isCareSector" class="tariff-column">{{ tariff.new_to_care }}</div>
        <div class="tariff-column tariff-column--status">
          <div class="tariff-status-wrapper">
            <div class="tariff-status" :class="tariff.active ? 'tariff-status--active' : 'tariff-status--inactive'">
              {{ tariff.active ? 'Active' : 'Inactive' }}
            </div>
          </div>
        </div>

        <div v-if="hasPermission" class="tariff-column tariff-column--actions">
          <tariff-actions
            v-if="paid || tariff.default"
            :tariff="tariff"
            :isCareSector="isCareSector"
            :milestone="milestone"
            @on-update="onUpdate"
          />
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-show="loading" class="loading-overlay">
        <span>Loading...</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { cssVariables } from "../../../../lib/css-variables";
import { Icon } from "../../../../components/Icon";
import TariffActions from "../TariffActions/TariffActions.vue";

export default {
  name: "TariffsTableView",
  props: {
    tariffs: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    isCareSector: { type: Boolean, default: false },
    milestone: { type: Number, default: 12 },
    hasPermission: { type: Boolean, default: false },
    paid: { type: Boolean, default: false },
  },
  components: {
    Icon,
    TariffActions
  },
  methods: {
    onUpdate(data) {
      this.$emit('on-update', data)
    }
  },
  async created() {
    this.cssVariables = cssVariables();
  },
};
</script>

<style scoped lang="scss">
@import "./TariffsTable.scss";
</style>
