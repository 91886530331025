<template>
  <div class="view-points-user1">
    <div class="grid-x grid-padding-x align-middle">
      <div class="cell auto name-id-points convertLeb">
                <h3>Adding permissions</h3>
                <div class="cell">
                    <form data-abide novalidate style="width: 100%">
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    First Name*
                                    <input
                                        type="text"
                                        class
                                        v-model="usero.first_name"
                                        placeholder
                                        aria-errormessage="firstName"
                                        required
                                    />
                                    <span class="form-error" id="firstName">Please enter a first name.</span>
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Last Name*
                                    <input
                                        type="text"
                                        class
                                        v-model="usero.surname"
                                        placeholder
                                        aria-errormessage="surName"
                                        required
                                    />
                                    <span class="form-error" id="surName">Please enter a Last Name.</span>
                                </label>
                            </div>
                        </div>
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box">
                                <label>
                                    Email address*
                                    <input
                                        type="email"
                                        v-model="usero.email"
                                        class="white_form b_margin_1-25"
                                        placeholder
                                        aria-errormessage="email-edit"
                                        required
                                    />
                                    <span
                                        class="form-error"
                                        id="email-edit"
                                        pattern="email"
                                    >Please enter an email address.</span>
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Mobile number*
                                    <input
                                        type="text"
                                        v-model="usero.phone_number"
                                        aria-errormessage="phone-edit"
                                        required
                                    />
                                    <span
                                        class="form-error"
                                        id="phone-edit"
                                        pattern="text"
                                    >Please enter a mobile number.</span>
                                </label>
                            </div>
                        </div>
                        <div class="grid-x align-bottom input-box-2">
                            <div class="cell auto input-box" v-if="availableSites.length > 0">
                                <label>
                                    Select Site*
									<span class="yellow_link">
									<b-tooltip target="add-sites" triggers="hover" placement="topleft">If you select a single site, the portal user will ONLY be able to view and edit data for that site. If the portal user needs to see data for more than one site, please select 'All sites'.</b-tooltip>
									<span id="add-sites">
                                        <i class="fa fa-question-circle-o"></i></span>
                                    </span>
                                    <multiselect 
                                        class="multi-sites" 
                                        v-model="usero.sites_users" 
                                        :options="availableSites"
                                        :multiple="true" 
                                        :clear-on-select="false" 
                                        openDirection="below"
                                        :showLabels="false"
                                        :placeholder="sitesUsersPlaceholder"
                                        label="name" 
                                        @input="selectedtag"
                                        track-by="name"
                                    />
                                </label>
                            </div>
                            <div class="cell auto input-box">
                                <label>
                                    Eligible to earn referral points?

                                    <span class="yellow_link">
									<b-tooltip target="messages" triggers="hover" placement="topright">If you select 'No' this user will be able to share jobs, but will not earn any referral points for doing so. They will still be able to be awarded bonus points.</b-tooltip>
									<span id="messages">
                                        <i class="fa fa-question-circle-o"></i></span>
                                    </span>
                                    <v-select
                                        placeholder="Select"
                                        label="name"
                                        v-model="usero.points_eligible"
                                        :reduce="referrer => referrer.id"
                                        :options="eligibleOptions"
                                    ></v-select>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="cell t_margin_2">

                    <p>
                        Permissions
                        <br />NB - all admin users have read only access to the core pages of the portal
                    </p>

                    <div class="switch-label grid-x">
                        <label class="switch" for="candidate_check">
                            <input
                                type="checkbox"
                                name="candidate_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.candidate_check"
                                class="custom-control-input"
                                id="candidate_check"
                            />
                            <span class="slider round"></span>
                        </label>Edit candidate details and manage candidate progress
                    </div>


                    <div class="switch-label grid-x" for="bonus_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="bonus_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.bonus_check"
                                class="custom-control-input"
                                id="bonus_check"
                            />
                            <span class="slider round"></span>
                        </label>Allocate bonus points
                    </div>

                    <div class="switch-label grid-x" for="bonus_categories_check">
                      <label class="switch">
                        <input
                          type="checkbox"
                          name="bonus_categories_check"
                          true-value="1"
                          false-value="0"
                          v-model="usero.bonus_categories_check"
                          class="custom-control-input"
                          id="bonus_categories_check"
                        />
                        <span class="slider round"></span>
                      </label>Create/Edit bonus point categories
                    </div>

                    <div class="switch-label grid-x" for="approve_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="approve_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.approve_check"
                                class="custom-control-input"
                                id="approve_check"
                            />
                            <span class="slider round"></span>
                        </label> Approve payment requests
                    </div>

                    <div class="switch-label grid-x" for="paid_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="paid_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.paid_check"
                                class="custom-control-input"
                                id="paid_check"
                            />
                            <span class="slider round"></span>
                        </label>Mark payment requests as paid
                    </div>

                    <div class="switch-label grid-x" for="notification_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="notification_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.notification_check"
                                class="custom-control-input"
                                id="notification_check"
                            />
                            <span class="slider round"></span>
                        </label>Send and edit notifications
                    </div>

                    <div class="switch-label grid-x" for="users_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="users_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.users_check"
                                class="custom-control-input"
                                id="users_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage app users
                    </div>

                    <div class="switch-label grid-x" for="admin_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="admin_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.admin_check"
                                class="custom-control-input"
                                id="admin_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage admin users
                    </div>

                    <div class="switch-label grid-x" for="account_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="account_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.account_check"
                                class="custom-control-input"
                                id="account_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage account settings
                    </div>

                    <div class="switch-label grid-x" for="job_check">
                        <label class="switch">
                            <input
                                type="checkbox"
                                name="job_check"
                                true-value="1"
                                false-value="0"
                                v-model="usero.job_check"
                                class="custom-control-input"
                                id="job_check"
                            />
                            <span class="slider round"></span>
                        </label>Manage job settings
                    </div>

                    <div class="cell auto popup-button">
                        <button
                            class="button pink_btn no_wrap_btn"
                            style="float: right"
                            @click="showConvertuser"
                            :disabled="newbtnDisbaled"
                        >Update</button>
                    </div>

                    <!-- </form> -->
                </div>
                
      </div>
      <b-modal
            size="sl"
            ref="convertuserModel"
            centered
          >
            <div class="delete-appuser">
              <div class="pop-up-box">
                <h3>Are you sure?</h3>
                <div class="many-invitee-modal-margin">
                  <p>
                    This will send an email invite to the app user, asking them
                    to log in to the portal.
                  </p>
                  <br />
                </div>
                <div class="cell auto popup-button">
                  <input
                    type="button"
                    class="button btn_lg btn orange_btn"
                    @click="hideConvertuser"
                    value="Cancel"
                  />
                  <input
                    @click="convertApptoPortal(usero)"
                    type="button"
                    class="button btn_lg btn pink_btn"
                    value="Confirm"
                  />
                </div>
              </div>
            </div>
    </b-modal>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import eventBus from '../../utils/event-bus.js';

export const noSite = { id: null, name: "All sites" };

export default {
  components: { paginate: Paginate, eventBus },
  props: ["user","parentReference"],
  data() {
    return {
      viewPointsData: [],
      usero:this.user,
	  availableSites: [],
	  sites: [],
	  eligibleOptions: [{id: 1, name: 'Yes'}, {id: 0, name: 'No'}],
      convertUser: false,
    };
  },
  methods: {
    selectedtag(value){
        const shouldVoidSites = value.filter(
                tag => tag.id == noSite.id || 
                tag.name == noSite.name
            ).length > 0;

        if (shouldVoidSites == true) {
            this.usero.sites_users = [ noSite ];
        } else {
            const filtered = [];
            value.forEach((tag) => {
                if (tag.id != noSite.id && tag.name != noSite.name) {
                    filtered.push(tag);
                };
            });
            this.usero.sites_users = filtered;
        };
    },
    showConvertuser(){
        var self = this;
        self.$refs["convertuserModel"].show();
    },
    hideConvertuser(){
        var self = this;
        self.$refs["convertuserModel"].hide();
    },
    convertApptoPortal(user){
			Vue.swal({
				title: "Updating...",
				html: "",
				allowOutsideClick: false,
				onBeforeOpen: () => {
				  Vue.swal.showLoading();
				}
			  });
                this.savedStatus = 'saving';
                var self = this;
                axios.post('/dashboard/account/editportalPermissions', user)
                    .then(function (response) {
                        // handle success
                       Vue.swal.close();
					   self.portalpermission= false;
					   self.$bvModal.hide("portalpermission-"+user.id);
                        Vue.swal({
                            html:
                                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Portal user added',
                            confirmButtonColor: "#5dc2a6",
                            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                            background: "#5dc2a6",
                            customClass: {
                                container: "candidate-toast conv"
                            },
                            showClass: {
                                popup: "animated fadeInLeft fast",
                                icon: "animated heartBeat delay-1s"
                            },
                            hideClass: {
                                popup: "animated fadeOutLeft slow"
                            },
                            toast: true,
                            timer: 5000,
                            position: "top-right",
                            target: "#example-tabs"
                        });
                        self.hideConvertuser();
                        self.$emit('parentReference',user);
                        eventBus.$emit('userPermissionChanges', 'yes');
                        self.fetchSites();
                    })
                    .catch(function (error) {
                        // handle error
                        if(error.response.data.errors.email[0] != null) {
                            Vue.swal({
                                title: error.response.data.errors.email[0],
                                confirmButtonColor: '#F84366'
                            });

                            Vue.swal({
                                html:
                                    '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Could not send Invite',
                                confirmButtonColor: "#5dc2a6",
                                confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
                                background: "#f55d73",
                                customClass: {
                                    container: "candidate-toast"
                                },
                                showClass: {
                                    popup: "animated fadeInLeft fast",
                                    icon: "animated heartBeat delay-1s"
                                },
                                hideClass: {
                                    popup: "animated fadeOutLeft slow"
                                },
                                toast: true,
                                timer: 5000,
                                position: "top-right",
                                target: "#example-tabs"
                            });

                        }
                    })
        },
     getAvailableSites(){
                //Create array of available sites for dropdown
                var allSites = Object.keys(this.sites);
                var availableSites = [];
                availableSites.push(noSite);
                for (var i = allSites.length - 1; i >= 0; i--) {
                  if (this.sites[allSites[i]].name != 'None') {
                    availableSites.push(this.sites[allSites[i]]);
                  }
                }
                this.availableSites = availableSites;
            },
			 fetchSites: function () {
                var self = this;
                axios.get('/dashboard/account/get-portal-sites')
                    .then(function (response) {
                        if (response.data.sites) {
                            self.sites = response.data.sites;
                            if (self.sites) {
                                self.getAvailableSites();
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
  },
  filters: {

  },
  mounted() {
    let self = this;
    this.fetchSites();
	this.usero = this.user;
    this.usero.sites_users = this.user.sites_users.reduce(function (carry, cur) {
        if (cur.site_id == null || cur.name == 'All Sites') cur = noSite;
        return [...carry, cur];
    }, []);
  },
  computed: {
  newbtnDisbaled(){
                if(this.usero.first_name && this.usero.surname && this.usero.email && this.usero.phone_number){
                    return false;
                }else return true;
            },
    sitesUsersPlaceholder() {
        if (this.usero.sites_users.length == 0) {
            return 'Select site(s)';
        };

        return this.usero.sites_users.reduce(function (carry, cur, index) {
            if (index == 0) return cur.name;
            return carry + ', ' + cur.name;
        }, '');
    },
  },
};
</script>
<style lang="scss" scoped>
	.convertLeb label {
		color: #363b54;
		font: 400 14px/22px "InterUI", sans-serif;
	}
	.convertLeb .vs__dropdown-toggle {
		height: 44px;
		border-radius: 4px;
		border: solid 1px #dcdee6;
		background-color: #ffffff;
		color: #363b54;
       font: 400 13px "InterUI", sans-serif;
    text-transform: capitalize;
   }
   .convertLeb .vs__selected {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;

    border-radius: 4px;
    color: #333 !important;
    line-height: 1.4 !important;
    margin: 4px 2px 0 !important;
    padding: 0 .25em !important;
    z-index: 0;
   }
   .vs__selected {
    color: #333 !important;
   }
   .conv {
    margin-right: 12px !important;
   }
</style>