<script>
export default {
  name: 'InsightsIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9834 22.5H10.0156" stroke="currentColor" stroke-linecap="round"/>
    <path d="M14.5 11L13.25 12L12 11L10.75 12L9.5 11" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9838 16.3842C16.7409 15.537 18.7451 12.9703 18.7451 9.93548C18.7451 6.21023 15.7252 3.19032 11.9999 3.19032C8.27469 3.19032 5.25478 6.21023 5.25478 9.93548C5.25478 12.9703 7.25901 15.537 10.0161 16.3842V17.621C6.59344 16.74 4.06445 13.6331 4.06445 9.93548C4.06445 5.55284 7.61729 2 11.9999 2C16.3826 2 19.9354 5.55284 19.9354 9.93548C19.9354 13.6331 17.4064 16.74 13.9838 17.621V16.3842ZM12.0558 17.8708H11.9441C11.9627 17.8709 11.9813 17.871 11.9999 17.871C12.0186 17.871 12.0372 17.8709 12.0558 17.8708Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1746 17.2106C14.7925 17.3775 14.3948 17.5153 13.9842 17.6209V19.7225C13.9842 19.9417 13.8066 20.1193 13.5875 20.1193H10.4133C10.1941 20.1193 10.0165 19.9417 10.0165 19.7225V17.6209C9.60592 17.5153 9.2082 17.3775 8.82617 17.2106V19.7225C8.82617 20.5991 9.53674 21.3096 10.4133 21.3096H13.5875C14.464 21.3096 15.1746 20.5991 15.1746 19.7225V17.2106Z" fill="currentColor"/>
  </svg>
</template>