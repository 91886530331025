<template>
  <div>
    <b-modal modal-class="add-points-budget-modal"
             v-model="isVisible"
             class="modal-p-div"
             ref="add-budget-modal"
             id="add-budget-modal"
             size="XL">
      <section class="add-new-budget">
        <span class="add-new-budget-header">Add Budget</span>

        <form ref="Add_Budget_Form">
          <div class="modal-styling-content">
            <div class="mt-20">
              <label class="main_label"
                     for="">BUDGET NAME<span class="asterisk">*</span></label>
              <input type="text"
                     placeholder="Enter budget name.."
                     v-model="newPointsBudget.Name"
                     :class="{ 'error-input': showError && (this.newPointsBudget.Name === null || this.newPointsBudget.Name === '') }" />
              <span class="error-message"
                    v-if="showError && (this.newPointsBudget.Name === null || this.newPointsBudget.Name === '')">
                Please enter the budget name.
              </span>
            </div>
            <div class="radio-btn-oneoff-monthly mouse-hover mt-20"
                 style="padding:5px">
              <label class="main_label one-off-monthly-label">IS THIS A ONE-OFF OR MONTHLY BUDGET?<span
                      class="asterisk">*</span></label>
              <input name="oneOffOrMonthly"
                     type="radio"
                     id="oneoffradio"
                     value="0"
                     v-model="newPointsBudget.IsMonthly"
                     class="mouse-hover"
                     v-bind:style="[
                                                                                                  Number(newPointsBudget.IsMonthly) === 0 && newPointsBudget.IsMonthly !== null
                                                                                                      ? { border: '1px solid #5dc2a6 !important' }
                                                                                                      : { border: '1px solid #D1D3E0 !important' },
                                                                                                  ]" />

              <label for="oneoffradio"
                     class="radio-oneoff mouse-hover modal-form-label">One-off</label>
              <br />
              <input name="oneOffOrMonthly"
                     type="radio"
                     id="monthlyradio"
                     value="1"
                     v-model="newPointsBudget.IsMonthly"
                     class="mouse-hover"
                     v-bind:style="[
                                                                                                  Number(newPointsBudget.IsMonthly) === 1 && newPointsBudget.IsMonthly !== null
                                                                                                      ? { border: '1px solid #5dc2a6 !important' }
                                                                                                      : { border: '1px solid #D1D3E0 !important' },
                                                                                                  ]" />
              <label for="monthlyradio"
                     class="radio-monthly mouse-hover modal-form-label">Monthly</label>
              <br />
              <span class="error-message"
                    v-if="showError && (newPointsBudget.IsMonthly === null)">Please select an option.</span>
            </div>
            <div v-if="Number(newPointsBudget.IsMonthly) === 1"
                 class="radio-btn-rollover mouse-hover mt-20">
              <label class="main_label">SHOULD ANY UNDISTRIBUTED POINTS ROLL OVER TO THE NEXT MONTH?<span
                      class="asterisk">*</span></label>
              <input type="radio"
                     id="rolloverradio"
                     v-bind:value="true"
                     name="isRolledOver"
                     v-model="newPointsBudget.IsRolledOver"
                     class="mouse-hover"
                     v-bind:style="[
                                                                                                    newPointsBudget.IsRolledOver
                                                                                                      ? { border: '1px solid #5dc2a6 !important' }
                                                                                                      : { border: '1px solid #D1D3E0 !important' },
                                                                                                  ]" />
              <label for="rolloverradio"
                     class="radio-rollover mouse-hover modal-form-label"
                     style="font-family:'Poppins',sans-serif">Roll over</label>
              <br />
              <input type="radio"
                     id="dnrolloverradio"
                     v-bind:value="false"
                     name="isRolledOver"
                     v-model="newPointsBudget.IsRolledOver"
                     class="mouse-hover"
                     v-bind:style="[
                                                                                                  !newPointsBudget.IsRolledOver && newPointsBudget.IsRolledOver !== null
                                                                                                      ? { border: '1px solid #5dc2a6 !important' }
                                                                                                      : { border: '1px solid #D1D3E0 !important' },
                                                                                                  ]" />
              <label for="dnrolloverradio"
                     class="radio-dnrollover mouse-hover modal-form-label"
                     style="font-family:'Poppins',sans-serif">Do not roll over</label>
              <br />

              <span class="error-message"
                    v-if="showError && Number(newPointsBudget.IsMonthly) === 1 && newPointsBudget.IsRolledOver === null">Please
                select an option</span>
            </div>

            <div class="mt-20">
              <label class="main_label">BUDGET TYPE<span class="asterisk">*</span></label>
              <single-select-component key="budget-type-single-select"
                                       v-model="newPointsBudget.BudgetType"
                                       :options="budgetTypes"
                                       :isNotValid="budgetTypeIsNotValid" />
              <span class="error-message"
                    v-if="budgetTypeIsNotValid">Please select budget type.</span>
            </div>

            <div class="mt-20"
                 v-if="Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 1">
              <label class="main_label main_label_span">WHO SHOULD HOLD THIS BUDGET?<span class="asterisk">*</span><br />
                <span class="sub-label-text"> Each user selected will be assigned the budget amount.</span>
                <span class="IconInfoRoundIdle"
                      v-b-tooltip.hover
                      title="Only portal users who do not already have the same budget type will display.">
                  <img src="/images/icon-info-round-idle@2x.png"
                       alt="img" />
                </span>
              </label>
              <multi-select-component :searchable="true"
                                      key="individual-user-budget-multi-select"
                                      :options="currentSeparateArray"
                                      v-model="newPointsBudget.MultipleUserHolderSeparate"
                                      :isNotValid="usersForSeparateBudgetIsNotValid"
                                      placeholder="Select users..." />
              <span class="error-message"
                    v-if="usersForSeparateBudgetIsNotValid">
                Please select users.
              </span>
            </div>
            <div class="mt-20"
                 v-if="Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 2">
              <label class="main_label main_label_span">WHO SHOULD SHARE THIS BUDGET?<span class="asterisk">*</span><br />
                <span class="sub-label-text"> All selected users will share the budget amount.</span>
                <span class="IconInfoRoundIdle"
                      v-b-tooltip.hover
                      title="Only portal users who do not already have the same budget type will display.">
                  <img src="/images/icon-info-round-idle@2x.png"
                       alt="img" />
                </span>
              </label>
              <multi-select-component :searchable="true"
                                      key="shared-user-budget-multi-select"
                                      :options="currentSharedArray"
                                      v-model="newPointsBudget.MultipleUserHolderShared"
                                      :isNotValid="usersForSharedBudgetIsNotValid"
                                      placeholder="Select users..." />
              <span class="error-message"
                    v-if="usersForSharedBudgetIsNotValid">
                Please select users.
              </span>
            </div>
            <div class="mt-20"
                 v-if="Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 3">
              <label class="main_label main_label_span">WHICH SITE SHOULD HOLD THIS BUDGET?<span
                      class="asterisk">*</span><br />
                <span class="sub-label-text">All portal users with permission to award bonus points will share the budget
                  amount.</span>
                <span class="IconInfoRoundIdle"
                      v-b-tooltip.hover
                      title="Only sites that do not already have a budget will display.">
                  <img src="/images/icon-info-round-idle@2x.png"
                       alt="img" />
                </span>
              </label>
              <single-select-component :searchable="true"
                                       key="shared-site-budget-single-select"
                                       :options="sitesForSharedBudget"
                                       v-model="newPointsBudget.SingleSiteHolder"
                                       :isNotValid="siteIsNotValid"
                                       placeholder="Select sites..." />
              <span class="error-message"
                    v-if="siteIsNotValid">
                Please select a site.
              </span>
            </div>
            <div class="mt-20">
              <label class="main_label"
                     for="">NUMBER OF POINTS<span class="asterisk">*</span></label>
              <input type="number"
                     placeholder="Enter number of points.."
                     name=""
                     v-model="newPointsBudget.NumberOfPoints"
                     :class="{ 'error-input': showError && this.invalidNumberOfPoints(this.newPointsBudget.NumberOfPoints) }" />
              <span class="error-message"
                    v-if="showError && this.invalidNumberOfPoints(this.newPointsBudget.NumberOfPoints)">Please enter the
                number of points. Must be 0 and above.</span>
            </div>
          </div>
        </form>

        <div class="modal_footer mt-20">
          <button class="control-btn danger-trans-btn"
                  @click="closeModal">Cancel</button>
          <button class="control-btn success-btn"
                  @click="addBudget()">Save</button>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { Icon } from "../../../app/components/Icon";
import SingleSelectComponent from "./SingleSelectComponent.vue";
import MultiSelectComponent from "../MultiSelectComponent.vue";
export default {
  components: {
    MultiSelectComponent,
    Icon,
    SingleSelectComponent,
  },
  props: {
    oneOffUsersForSeparateBudget: { type: Array, required: true },
    monthlyRolloverUsersForSeparateBudget: { type: Array, required: true },
    monthlyNonRolloverUsersForSeparateBudget: { type: Array, required: true },
    oneOffUsersForSharedBudget: { type: Array, required: true },
    monthlyRolloverUsersForSharedBudget: { type: Array, required: true },
    monthlyNonRolloverUsersForSharedBudget: { type: Array, required: true },
    sitesForSharedBudget: { type: Array, required: true },
    usersForSeparateBudget: { type: Array, required: true },
    usersForSharedBudget: { type: Array, required: true },
    budgetTypes: { type: Array, required: true }
  },
  mounted() {
  },
  data() {
    return {
      clearSelectedValues: false,
      isVisible: false,
      showError: false,
      newPointBudgetRecord: {
        id: null,
        name: "",
        oneOffOrMonthly: null,
        isRolledOver: null,
        users: {},
        points: null,
        allocation: {
          used: 0,
          remaining: null,
          days_remaining: 30,
        },
        individual_budget_selected_users: [],
        shared_budget_selected_users: [],
        shared_budget_selected_site: [],
        type: null,
      },
      newPointsBudget: {
        Name: null,
        IsMonthly: null,
        IsRolledOver: null,
        BudgetType: null,
        MultipleUserHolderShared: [],
        MultipleUserHolderSeparate: [],
        SingleSiteHolder: null,
        NumberOfPoints: null,
      }
    }
  },
  methods: {
    addBudget() {
      this.showError = false;
      const formIncomplete = this.isFormIncomplete();

      if (formIncomplete) {
        this.showError = true;
        return;
      }

      this.showError = false;
      this.newPointsBudget.IsMonthly = Boolean(Number(this.newPointsBudget.IsMonthly));
      if (!this.newPointsBudget.IsMonthly) {
        this.newPointsBudget.IsRolledOver = null;
      }
      this.newPointsBudget.NumberOfPoints = Number(this.newPointsBudget.NumberOfPoints);
      this.newPointsBudget.SingleSiteHolder = this.newPointsBudget.SingleSiteHolder ? Number(this.newPointsBudget.SingleSiteHolder) : null;

      this.$emit('budgetsaved', this.newPointsBudget);
      this.closeModal();
    },
    /*
      Validation Methods
     */
    isFormIncomplete() {
      const Name = this.newPointsBudget.Name === null || this.newPointsBudget.Name === "";
      console.log(`name rule: ${Name}`)

      const IsMonthly = this.newPointsBudget.IsMonthly === null;
      console.log(`is monthly rule: ${IsMonthly}`)

      const IsRolledOver = (Number(this.newPointsBudget.IsMonthly) === 1 && this.newPointsBudget.IsRolledOver === null);
      console.log(`is rolled over rule: ${IsRolledOver}`)

      const BudgetType = !this.newPointsBudget.BudgetType ||
        (Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 1 && this.newPointsBudget.MultipleUserHolderSeparate?.length === 0) ||
        (Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 2 && this.newPointsBudget.MultipleUserHolderShared?.length === 0) ||
        (Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 3 && this.newPointsBudget.SingleSiteHolder === null);
      console.log(`budget type rule: ${BudgetType}`)

      const NumberOfPoints = this.invalidNumberOfPoints(this.newPointsBudget.NumberOfPoints);
      console.log(`number of points rule: ${NumberOfPoints}`);

      return Name || IsMonthly || IsRolledOver || BudgetType || NumberOfPoints;
    },
    invalidNumberOfPoints(numberOfPoints) {
      return numberOfPoints === null || numberOfPoints === '' || isNaN(Number(numberOfPoints)) || Number(numberOfPoints) < 0;
    },
    /*
      End of Validation Methods
     */
    onBudgetTypeChangeWhileInAddMode() {
      this.newPointsBudget.MultipleUserHolderSeparate = [];
      this.newPointsBudget.MultipleUserHolderShared = [];
      this.newPointsBudget.SingleSiteHolder = null;
    },
    limitText(count) {
      return `+${count} others`
    },
    closeModal() {
      this.isVisible = false;
      this.$refs["add-budget-modal"].hide();
      this.reset_form();
    },
    reset_form() {
      this.showError = false;
      this.newPointsBudget = {
        Name: null,
        IsMonthly: null,
        IsRolledOver: null,
        BudgetType: null,
        MultipleUserHolderShared: [],
        MultipleUserHolderSeparate: [],
        SingleSiteHolder: null,
        NumberOfPoints: null,
      }
    }

  },
  computed: {
    budgetTypeIsNotValid() {
      return this.showError && !this.newPointsBudget.BudgetType;
    },
    usersForSeparateBudgetIsNotValid() {
      return this.showError &&
        Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 1 &&
        this.newPointsBudget.MultipleUserHolderSeparate.length === 0
    },
    usersForSharedBudgetIsNotValid() {
      return this.showError &&
        Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 2 &&
        this.newPointsBudget.MultipleUserHolderShared.length === 0
    },
    siteIsNotValid() {
      return this.showError &&
        Number(this.newPointsBudget && this.newPointsBudget.BudgetType) === 3 &&
        this.newPointsBudget.SingleSiteHolder === null
    },
    currentSeparateArray() {
      if ((Number(this.newPointsBudget.IsMonthly) === 0 && Number(this.newPointsBudget.BudgetType) === 1)) {
        console.log('oneoff - separate');
        return this.oneOffUsersForSeparateBudget;
      } else if ((Number(this.newPointsBudget.IsMonthly) === 1 && Number(this.newPointsBudget.IsRolledOver) === 1) && Number(this.newPointsBudget.BudgetType) === 1) {
        console.log('monthly - rollover - separate');
        return this.monthlyRolloverUsersForSeparateBudget;
      } else if ((Number(this.newPointsBudget.IsMonthly) === 1 && Number(this.newPointsBudget.IsRolledOver) === 0) && Number(this.newPointsBudget.BudgetType) === 1) {
        console.log('monthly - no rollover - separate');
        return this.monthlyNonRolloverUsersForSeparateBudget;
      } else {
        return [];
      }
    },
    currentSharedArray() {
      if ((Number(this.newPointsBudget.IsMonthly) === 0 && Number(this.newPointsBudget.BudgetType) === 2)) {
        console.log('oneoff - shared');
        return this.oneOffUsersForSharedBudget;
      } else if ((Number(this.newPointsBudget.IsMonthly) === 1 && Number(this.newPointsBudget.IsRolledOver) === 1) && Number(this.newPointsBudget.BudgetType) === 2) {
        console.log('monthly - rollover - shared');
        return this.monthlyRolloverUsersForSharedBudget;
      } else if ((Number(this.newPointsBudget.IsMonthly) === 1 && Number(this.newPointsBudget.IsRolledOver) === 0) && Number(this.newPointsBudget.BudgetType) === 2) {
        console.log('monthly - no rollover - shared');
        return this.monthlyNonRolloverUsersForSharedBudget;
      } else {
        return [];
      }
    },
    // currentSiteArray() {
    //   if ((Number(this.newPointsBudget.IsMonthly) === 0 && Number(this.newPointsBudget.BudgetType) === 3)) {
    //     return this.oneOffSitesForSharedBudget;
    //   } else if ((Number(this.newPointsBudget.IsMonthly) === 1 && Number(this.newPointsBudget.IsRolledOver) === 1) && Number(this.newPointsBudget.BudgetType) === 3) {
    //     return this.monthlyRolloverSitesForSharedBudget;
    //   } else if ((Number(this.newPointsBudget.IsMonthly) === 1 && Number(this.newPointsBudget.IsRolledOver) === 0) && Number(this.newPointsBudget.BudgetType) === 3) {
    //     return this.monthlyNonRolloverSitesForSharedBudget;
    //   } else {
    //     return [];
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .modal-dialog {
  width: 656px !important;
  max-width: 656px !important;
}

::v-deep .multiselect__tag {
  position: relative;
  display: inline-block;
  line-height: 1;
  background: #ffffff !important;
  margin-bottom: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  color: #363b54 !important;
  font-family: "Poppins", sans-serif !important;
  padding: 0px 2px 0px 0px !important;
  border-radius: 5px;
  margin-right: 0px !important;
}

::v-deep .multiselect__tags {
  padding: 10px 40px 0px 8px !important;
  font-family: "Poppins", sans-serif !important;
}

::v-deep .multiselect__strong {
  /* margin-bottom: 10px !important; */
  line-height: 16px !important;
  /* display: inline-block; */
  /* vertical-align: super !important; */
  color: #363b54 !important;
  font-weight: normal !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  padding-left: 2px !important;
}

::v-deep .multiselect__tag-icon {
  display: none !important
}

.add-points-budget-modal .modal-dialog {
  width: 656px !important;
  max-width: 656px !important;
}

.sub-label-text {
  font-size: 12px;
  font-weight: lighter;
}

.control-btn:not(:last-child) {
  margin-right: 15px;
}

.control-btn {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.one-off-monthly-label {
  padding-bottom: 3px;
}

.modal-body label {
  font-family: "Poppins", sans-serif;
}

.add-new-budget-header {
  font: 600 22px/34px "Poppins", sans-serif;
  color: #3C3C3C;
  margin: 0 0 10px 0;
}

.error-input {
  border: 1px solid red !important;
  border-radius: 12px !important;
}

.multiselect.error-input {
  border-radius: 6px !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 500;
}

.mt-20 {
  margin-top: 20px;
}

.add-new-budget {
  .success-btn {
    background-color: #5dc2a6;
    border-radius: 20px;
    border: 2px solid #5dc2a6;
    font: 700 16px/15px "Poppins", sans-serif;
    color: #ffffff;
    padding: 12px 28px;
  }

  .success-btn:hover,
  .success-btn:focus {
    background-color: #469f86;
    border: 2px solid #469f86 !important;
    color: #ffffff;
  }

  .danger-trans-btn {
    background-color: #ffffff;
    border: 2px solid #E61414;
    border-radius: 20px;
    color: red;
    font: 700 16px/15px "Poppins", sans-serif;
    padding: 12px 18px;
  }

  .danger-trans-btn:hover,
  .danger-trans-btn:focus {
    background-color: #E61414;
    color: #ffffff;
  }

  .modal_footer {
    text-align: center;
  }

  .main_label {
    color: #6A6F8E;
    font: 600 12px/22px "Poppins", sans-serif !important;
  }

  .main_label_span {
    margin-bottom: 10px;
  }

  [type=text],
  select,
  [type=number],
  .multiselect__tags {
    height: 44px;
    border-radius: 12px;
    box-shadow: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #3C3C3C;
    padding-left: 10px;
  }

  select {
    margin: 0;
  }
}

.radio-btn-oneoff-monthly,
.radio-btn-rollover {
  padding-left: 3px;
  margin-bottom: 12px;
  font-weight: 600;
}

label.radio-oneoff,
label.radio-rollover {
  padding: 3px !important;
  color: #363b54;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

label.radio-monthly,
label.radio-dnrollover {
  padding: 3px !important;
  color: #363b54;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

.radio-monthly,
.radio-dnrollover {
  font-family: "Poppins", sans-serif;
  cursor: no-drop;
}

.mouse-hover {
  cursor: pointer;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #5dc2a6 !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #d1d3e0 !important;
  border-radius: 510%;
  outline: none;
  vertical-align: middle;
}

[type="file"],
[type="checkbox"],
[type="radio"] {
  margin: 0 0 0 !important;
}

label.oneoff,
label.rollover {
  margin-bottom: 2px;
  font-weight: 600;
}

label.monthly,
label.dnrollover {
  margin-bottom: 7px;
  font-weight: 600;
}

.asterisk {
  color: red;
}

.i_icon {
  background-color: #d7d7d7;
  border-radius: 50%;
  color: #293e8d;
  padding: 0px 5px;
}

.validate-error {
  padding: 1px 8px;
  margin-top: 0px;
  color: #E61414;
  font: 13px/22px "Poppins", sans-serif;
  font-weight: 500;
}

.tooltip {
  position: relative;
  font-family: "Poppins", sans-serif !important;
  text-align: left !important;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -.5rem;
  right: -8rem;
}
</style>