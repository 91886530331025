<template>
  <div>
    <div class="grid-x grid-padding-x">

      <div class="cell shrink client-bottom" @click="openAddMessageModal">
        <a class="btn-edit button pink_btn b_margin_0 no_wrap_btn">
          New</a
        >
      </div>
    </div>

    <div class="cf-table client-table">
      <div class="bg-card">
        <div>
          <table class="table1">
            <thead class="table-body-styling1">
            <tr>
              <td class="table-head-styling" scope="col">Message</td>
              <td class="table-head-styling" scope="col">ALL</td>
              <td class="table-head-styling" scope="col">UK<br/>CF</td>
              <td class="table-head-styling" scope="col">UK<br/>WF</td>
              <td class="table-head-styling" scope="col">ROI<br/>CF</td>
              <td class="table-head-styling" scope="col">AUS<br>CF</td>
              <td class="table-head-styling" scope="col">NL<br>WF</td>
            </tr>
            </thead>
            <tbody>
            <tr
              class="automation-hover-edit"
              v-for="(row, index) in this.messages"
            >
              <td scope="row" class="text-p-styling-automation">
                <div class="edit-automations-div">
                  <div>
                    <p
                      v-b-tooltip.hover.html="{
                          customClass: 'Notification-tooltip',
                          title: row.message,
                        }"
                      class="p-styling1"
                      v-html="
                          row.message
                            .replace(/<\/?[^>]+(>|$)/g, '')
                            .substring(0, 80).length < 79
                            ? row.message
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80)
                            : row.message
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .substring(0, 80) + '..'
                        "
                    ></p>
                  </div>

                  <div class="img-automation hover-icons">
                    <div>
                      <img
                        class="img-automation-styling"
                        @click="
                            openEditMessageModal(
                              row,
                              index
                            )
                          "
                        src="/images/icon-24-edit-02.png"
                        alt=""
                      />
                    </div>

                    <div>
                      <img
                        class="img-automation-styling"
                        @click="
                            openDeleteMessageModal(
                              row,
                              index
                            )
                          "
                        src="/images/icon-24-delete.png"
                        alt=""
                      />
                    </div>
                  </div>

                </div>
              </td>
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="row.all"
                      @click="
                      toggleMessage(row, 'all')
                    "
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="row.uk_cf"
                      @click="
                      toggleMessage(row, 'uk_cf')
                    "
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="row.uk_wf"
                      @click="
                      toggleMessage(row, 'uk_wf')
                    "
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="row.ie_cf"
                      @click="
                      toggleMessage(row, 'ie_cf')
                    "
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="row.au_cf"
                      @click="
                      toggleMessage(row, 'au_cf')
                    "
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="row.nl_wf"
                      @click="
                      toggleMessage(row, 'nl_wf')
                    "
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <b-modal
      ref="add-job"
      id="edit-message"
      size="xl"
      :no-close-on-backdrop="true"
    >
      <section class="multi-step-row">
        <div class="pop-up-box">
          <div class="text-box" id="text-box">
            <fieldset>
              <div class="job-leftside">
                <form>
                  <div class="form-group">
                    <p class="Edit-notification">Edit Message</p>
                    <label
                      for="exampleInputEmail1"
                      class="exampleInputEmailtitle"
                    >Message <span v-show="showEditValidationMessage" style="font-size: 0.75rem;font-weight: bold;color: #cc4b37;"
                    >  *max allowed characters are 23</span>
                    </label
                    >
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            v-model="currentObject.title"
                                            aria-errormessage="notificationTitle"
                                        /> -->

                    <ckeditor
                      class="form-control text-area-border styling-bottom"
                      placeholder="Add Wording"
                      id="exampleFormControlTextarea1"
                      name="Message"
                      rows="5"
                      v-model="currentObject.message"
                      :config="titleEditorConfig"
                    >
                    </ckeditor>

                  </div>
                </form>
              </div>

              <div class="cell auto popup-button pad-top edit-btn-styling">
                <button @click.prevent="closeModal" class="Cancel button btn">
                  Cancel
                </button>
                <input
                  @click="updateMessage"
                  type="button"
                  name="Edit"
                  class="next action-button button btn_lg btn pink_btn"
                  value="Save"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>
    <b-modal
      ref="add-message"
      id="add-message"
      size="xl"
      :no-close-on-backdrop="true"
    >
      <section class="multi-step-row">
        <div class="pop-up-box">
          <div class="text-box" id="text-box">
            <fieldset>
              <div class="job-leftside">
                <form>
                  <div class="form-group">
                    <p class="Edit-notification">New Message</p>
                    <label for="exampleInputEmail1" class="Title-content"
                    >Message <span v-show="showAddValidationMessage" style="font-size: 0.75rem;font-weight: bold;color: #cc4b37;"
                    >  *max allowed characters are 23</span></label
                    >
                    <!-- <input
                                            type="text"
                                            class="form-control"
                                            placeholder="eg. Jane"
                                            aria-describedby="Title"
                                            name="Title"
                                            aria-errormessage="notificationTitle"
                                            required
                                            v-model="form.Title"
                                        /> -->
                    <ckeditor
                      class="cheditor-styling-title"
                      :config="titleEditorConfig"
                      id="exampleFormControlTextarea1"
                      v-model="currentObjectAdd.message"
                    ></ckeditor>
                    <span class="form-error" id="notificationTrigger"
                    >Please enter a message</span
                    >
                  </div>
                </form>
              </div>

              <div class="cell auto popup-button pad-top edit-btn-styling">
                <button
                  @click.prevent="closeModal"
                  class="Cancel button btn"
                >
                  Cancel
                </button>
                <input
                  @click="saveNewMessage"
                  type="button"
                  name="Edit"
                  class="next action-button button btn_lg btn pink_btn"
                  value="Save"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </section>
    </b-modal>
    <b-modal class="tab-modal" id="delete-message" size="lg">
      <div class="grid-y">
        <div class="grid-x input-box-2">
          <div class="cell auto input-box">
            <div class="discard-header4">
              <h3 class="discard-header-title">Are you sure to delete it?</h3>
            </div>
            <p class="if-you-discard-chang">It can not be undone.</p>
          </div>
        </div>
      </div>
      <div class="cell auto popup-button">
        <input
          class="button btn_lg btn pink_btn"
          type="submit"
          value="Delete"
          style="
            float: right;
            margin-right: -13px;
            margin-bottom: 13px;
            margin-left: 7px;
          "
          @click="deleteMessage"
        />
        <input
          type="button"
          class="button btn_lg btn orange_btn anon-can"
          value="Cancel"
          @click="closeModal"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import CKEditor from "ckeditor4-vue/dist/legacy.js";

export default {
  props: ["messagearr"],
  CKEditor,
  components: { paginate: Paginate },
  data() {
    return {
      messages:this.messagearr,
      currentObjectAdd:{
        message: ''
      },
      currentObject:{
        id: null,
        message: ''
      },
      titleEditorConfig: {
        autoParagraph: false,
        autoGrow_maxHeight: 50,
        height: 60,
        editorplaceholder: "Add message",
        // removePlugins : 'autogrow',
        toolbar: [["EmojiPanel", "Undo", "Redo"]],
        removeButtons: "Italic",
        extraPlugins: ["emoji", "editorplaceholder"],
        enterMode: 2,
      },
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("add-message");
      this.$bvModal.hide("edit-message");
      this.$bvModal.hide("delete-message");
    },
    openAddMessageModal() {
      this.$bvModal.show("add-message");
    },
    openEditMessageModal(row, index) {
      this.currentObject = {
        message : row.message,
        uk_cf : row.uk_cf,
        uk_wf : row.uk_wf,
        au_cf : row.au_cf,
        ie_cf : row.ie_cf,
        nl_wf : row.nl_wf,
        all : row.all,
        id : row.id,
      };
      this.$bvModal.show("edit-message");
    },
    toggleMessage(row, val) {
      row[val] = !row[val]
      this.currentObject = {
        message : row.message,
        id : row.id,
      };
      if (val == 'uk_cf') {
        this.currentObject.uk_cf = row.uk_cf;
      } else if (val == 'uk_wf') {
        this.currentObject.uk_wf = row.uk_wf;
      } else if (val == 'ie_cf') {
        this.currentObject.ie_cf = row.ie_cf;
      } else if (val == 'au_cf') {
        this.currentObject.au_cf = row.au_cf;
      } else if (val == 'nl_wf') {
        this.currentObject.nl_wf = row.nl_wf;
      } else if (val == 'all') {
        this.currentObject.all = row.all;
      }
      this.updateMessage();
    },
    openDeleteMessageModal(row, index) {
      this.currentObject = {
        message : row.message,
        id : row.id,
      };
      this.$bvModal.show("delete-message");
    },
    saveNewMessage(){
      if(this.currentObjectAdd.message.length <= 23){

      let that = this;
      axios
        .post("/dashboard/super/welcome-messages", this.currentObjectAdd)
        .then(function (response) {
          that.closeModal();

          let temp = Object.assign({}, response.data.messages);

          that.messages = Object.assign({}, temp);


          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Message Created',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          // location.reload();
        })
        .catch(function (error) {
          // handle error
          console.log("Error Adding Template Notification - error: ", error);
        });

      }else{}
    },
    updateMessage() {
      if(this.currentObject.message.length <= 23){
      let that = this;
      axios
        .put(`/dashboard/super/welcome-messages/${this.currentObject.id}`, this.currentObject)
        .then(function (response) {
          that.closeModal();

          let temp = Object.assign({}, response.data.messages);

          that.messages = Object.assign({}, temp);


          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Message Updated',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          // location.reload();
        })
        .catch(function (error) {
          // handle error
          console.log("Error Adding Template Notification - error: ", error);
        });
    }else{}
    },
    deleteMessage() {
      let that = this;
      axios
        .delete(`/dashboard/super/welcome-messages/${this.currentObject.id}`)
        .then(function (response) {
          that.closeModal();

          let temp = Object.assign({}, response.data.messages);

          that.messages = Object.assign({}, temp);


          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Message Deleted',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          // location.reload();
        })
        .catch(function (error) {
          // handle error
          console.log("Error Adding Template Notification - error: ", error);
        });
    },
    postData() {
      axios
        .post("/dashboard/super/update/automations", this.automations)
        .then(function (response) {
          Vue.swal({
            html: '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Automations-Notifications are Updated',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast",
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s",
            },
            hideClass: {
              popup: "animated fadeOutLeft slow",
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs",
          });
          this.enableSaveButton = false;
          // location.reload();
        })
        .catch(function (error) {
          // handle error
          self.message = "Error!";
          console.log(
            "Error Updating Automations Notifications - error: ",
            error
          );
        });
    },
  },
  mounted() {
  },
  computed:{
    showAddValidationMessage(){
      if (this.currentObjectAdd.message.length > 23){
        return true
      }else{
        return false
      }
    },
    showEditValidationMessage(){
      if (this.currentObject.message.length > 23){
        return true
      }else{
        return false
      }
    }
  },
  watch: {
  },
};
</script>

<style scoped>
.img-automation {
  display: flex;
}

.img-automation {
  margin-top: 20px;
  /* font-size: 25px; */
  visibility: hidden;
  width: 47px;
}

.hover-icons{
  margin-top: 0 !important;
}

.table-head-styling {
  font-weight: 600;
  text-align: left;
  font-size: 11px;
  font-family: "InterUI";
  color: #9297ae;
  height: 56px !important;
  padding-left: 25px;
}

select#category {
  margin-top: 10px;
}
.mobile-notification-paragraph-automation {
  height: 27px;
}

p.mobile-notification-paragraph {
  width: 180px;
  /* height: 40px; */
  font-family: SFProText;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  overflow: hidden;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.05px;
  color: #292929;
  position: relative !important;
  top: -42px;
  left: 10px;
  /* word-wrap: break-word; */
  padding-bottom: 12px;
  overflow-x: hidden;
  height: 19px;
  /* text-overflow: ellipsis; */
  background-color: #f7f8fc;
  word-break: break-all;
  line-height: 10px;
}
</style>