<template>
  <div class="grid-x grid-padding-x align-middle b_border_b1" :class="{'rotten-candidate': requestData.rotten}">
    <div class="cell checbox-width">
      <label class="contain-check">
        <input
          @click="selectbox"
          type="checkbox"
          :value="requestData.id"
          v-model="selected"
        />
        <span class="checkmark-check"></span>
      </label>
    </div>
    <div class="cell auto">
      <p class="tb_margin_1">
        {{ requestData.created_at | moment("DD/MM/YYYY") }}
      </p>
    </div>
    <div class="cell auto">
      <p class="tb_margin_1 cursor-custom" v-b-modal="'modal-' + currentTime">
        <strong class="user-clickable"
          >{{ requestData.user.first_name }}
          {{ requestData.user.surname }}</strong
        >
      </p>
    </div>
    <div class="cell auto">
      <p class="tb_margin_1" style="text-align:right;">
        <strong>{{ requestData.user.PaymentInfo }}</strong>
      </p>
    </div>
	<div class="cell auto">
      <p class="tb_margin_1" style="text-align:right;">
        <strong>{{ requestData.user.balance }}</strong>
      </p>
    </div>
    <div class="cell auto">
      <p class="tb_margin_1" style="text-align:right;">
        <strong>{{ requestData.amount }}</strong>
      </p>
    </div>

    <div v-if="requestData.status == 0" class="cell auto" style="text-align:center;">
      <p class="pending tb_margin_1" ><span>Pending</span></p>
    </div>

    <div v-else class="cell auto" style="text-align:center;">
      <p class="approved tb_margin_1" ><span> Approved</span></p>
    </div>

    <div
      class="action-icons auto cell auto"
      :id="'action-icons-' + current_user_id"
    >
      <a
        v-if="currentuser.approve_check"
        @click="rejectRequest"
        v-b-tooltip.hover
        title="Reject"
      >
        <img src="/images/svg/close.svg"
      /></a>
      <a
        v-if="currentuser.approve_check && requestData.status == 0"
        @click="approveRequest"
        v-b-tooltip.hover
        title="Approve"
      >
        <img src="/images/svg/checkmark.svg"
      /></a>
      <a
        v-if="currentuser.paid_check"
        @click="makePayment"
        v-b-tooltip.hover
        title="Pay"
      >
        <img src="/images/svg/payment_.svg" />
      </a>
    </div>

    <!--<div v-if="editMode">-->
    <b-modal :id="'modal-' + currentTime" size="xl" hide-footer>
      <profile-card-component
        :user="requestData.user"
        :isCareSector="isCareSector"
        :taxName="taxName"
      >
      </profile-card-component>
    </b-modal>
    <!--</div>-->
  </div>
</template>

<script>
import eventBus from '../../utils/event-bus.js';

export default {
  components: {
    eventBus,
  },
  props: [
    "request",
    "childComponant",
    "current_user_id",
    "advanced",
    "currentuser",
    "makeAllSelected",
    "selectionAction",
    "isCareSector",
    "taxName",
  ],
  data() {
    return {
      payButtonClicked: false,
      requestData: this.request,
      saved: "",
      tickApproved: false,
      tickPaid: false,
      viewPointsData: [],
      balance: [],
      editMode: false,
      dateReg: [],
      leaderboardValues: [],
      pointValues: [],
      site_name: null,
      selected: this.makeAllSelected
    };
  },
  methods: {
    selectbox(val) {
      if (val) {
        let checkedState = false;
        if ($(val.target).is(":checked")) {
          checkedState = true;
        }
        this.$root.$emit("select", this.request, checkedState);
      }

      $("#selection-buttons").show();
    },
    rejectRequest() {
      var self = this;
      axios
        .post("/dashboard/points/request/destroy", {
          id: this.requestData.id
        })
        .then(function (response) {
          console.log(response);
          if (response) {
            self.$root.$emit("paymentRequests", false);
            self.saved = "saving";
            Vue.swal({
              html:
                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Payment Request is successfully Removed.',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast"
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s"
              },
              hideClass: {
                popup: "animated fadeOutLeft slow"
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs"
            });
          }
        });
    },

    approveRequest() {
      var self = this;
      this.requestData.approveStatus = true;
      const unauthorisedApprovalMessage = "Unfortunately, you cannot approve this payment request as it is your own. Please speak to your Care Friends lead for help with this.";

      if (!this.currentuser.account_check && this.requestData.user_id  === this.currentuser.id) {
        Vue.swal({
          title: unauthorisedApprovalMessage,
          confirmButtonColor: "#F84366",
          width: '450px',
        });
        return;
      }

      axios
        .post(
          "/dashboard/points/" + this.requestData.id + "/approve",
          this.requestData
        )
        .then(function (response) {
          eventBus.$emit('paymentRequestChanges', 'yes');
          // handle success
          if (response.data.success == false) {
            Vue.swal({
              title: "We could not Approve this Payment Request",
              confirmButtonColor: "#F84366"
            });
          } else {  
            self.requestData.status = true;
            self.$root.$emit("paymentRequests");
            self.saved = "";
            Vue.swal({
              html:
                '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Approved.',
              confirmButtonColor: "#5dc2a6",
              confirmButtonText:
                '<i class="fa fa-times" aria-hidden="true"></i>',
              background: "#5dc2a6",
              customClass: {
                container: "candidate-toast"
              },
              showClass: {
                popup: "animated fadeInLeft fast",
                icon: "animated heartBeat delay-1s"
              },
              hideClass: {
                popup: "animated fadeOutLeft slow"
              },
              toast: true,
              timer: 5000,
              position: "top-right",
              target: "#example-tabs"
            });
            console.log(response);
          }
        })
        .catch(function (error) {
          self.saved = "";
          let errorText;

          if (error.response.status === 401) {
            errorText = unauthorisedApprovalMessage ?? 'Unauthorised';
          } else {
            errorText = "Redeemable balance is insufficient for the payment request";
          }

          Vue.swal({
            title: errorText,
            confirmButtonColor: "#F84366"
          });
        });
    },

    makePayment() {
      if (this.payButtonClicked) {
        return;
      }
      this.payButtonClicked = true;
      var access = this.accessPermission();
      self = this;
      const unauthorisedActionMessage = 'Unfortunately, you cannot mark this request as paid as it is your own. Please speak to your Care Friends lead for help with this.';

      if (!this.currentuser.account_check && this.requestData.user_id  === this.currentuser.id) {
        Vue.swal({
          title: unauthorisedActionMessage,
          confirmButtonColor: "#F84366",
        });
        return;
      }

      if (access) {
        this.requestData.paidStatus = true;
        axios
          .post(
            "/dashboard/points/" + this.requestData.id + "/approve",
            this.requestData
          )
          .then(function (response) {
            eventBus.$emit('paymentRequestChanges', 'yes');
            // handle success
            if (response.data.success == false) {
              self.tickApproved = false;
              self.tickPaid = false;
              self.saved = "";
              Vue.swal({
                title:
                  "Redeemable balance is insufficient for the payment request",
                confirmButtonColor: "#F84366"
              });
            } else {
              self.requestData.paid_status = true;
              self.saved = "";
              self.$root.$emit("paymentRequests");
              Vue.swal({
                html:
                  '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Payment is Marked Paid.',
                confirmButtonColor: "#5dc2a6",
                confirmButtonText:
                  '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: {
                  container: "candidate-toast"
                },
                showClass: {
                  popup: "animated fadeInLeft fast",
                  icon: "animated heartBeat delay-1s"
                },
                hideClass: {
                  popup: "animated fadeOutLeft slow"
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
              });
            }
          })
          .catch(function (error) {
            self.saved = "";
            let errorText;

            if (error.response.status === 401) {
              errorText = unauthorisedActionMessage ?? 'Unauthorised';
            } else {
              errorText = "Redeemable balance is insufficient for the payment request";
            }

            Vue.swal({
              title: errorText,
              confirmButtonColor: "#F84366"
            });
          });
      }
    },
    accessPermission() {
      if ((this.requestData.approved_by_user_id = this.current_user_id)) {
        return true;
      } else false;
    },

    save() {
      if (this.saved === "" && this.requestData.status && this.advanced) {
        this.requestData.approved_by_user_id = this.current_user_id;
        this.saved = "saving";

        Vue.swal({
          title: "Saving...",
          html: "",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Vue.swal.showLoading();
          }
        });

        if (this.requestData.status && !this.requestData.paid_status) {
          this.tickApproved = true;
        } else if (this.requestData.status && this.requestData.paid_status) {
          this.tickApproved = true;
          this.tickPaid = true;
        }

        var self = this;
        //ADD APROVED OR PAID TO REQUEST DATA
        axios
          .post(
            "/dashboard/points/" + this.requestData.id + "/approve",
            this.requestData
          )
          .then(function (response) {
            eventBus.$emit('paymentRequestChanges', 'yes');
            // handle success
            if (response.data.success == false) {
              self.tickApproved = false;
              self.tickPaid = false;
              self.saved = "";
              Vue.swal({
                title:
                  "Redeemable balance is insufficient for the payment request",
                confirmButtonColor: "#F84366"
              });
            } else {
              self.saved = "";
              Vue.swal({
                html:
                  '<span class="success-circle"><i class="fas fa-check-circle"></i></span> Approved.',
                confirmButtonColor: "#5dc2a6",
                confirmButtonText:
                  '<i class="fa fa-times" aria-hidden="true"></i>',
                background: "#5dc2a6",
                customClass: {
                  container: "candidate-toast"
                },
                showClass: {
                  popup: "animated fadeInLeft fast",
                  icon: "animated heartBeat delay-1s"
                },
                hideClass: {
                  popup: "animated fadeOutLeft slow"
                },
                toast: true,
                timer: 5000,
                position: "top-right",
                target: "#example-tabs"
              });
              console.log(response);
            }
          })
          .catch(function (error) {
            self.saved = "";
            Vue.swal({
              title:
                "Redeemable balance is insufficient for the payment request",
              confirmButtonColor: "#F84366"
            });
          });
      }
    },
    selectifPending() {
      this.selected = false;
      if (this.request.status == 0) {
        this.selected = true;
      }
    },
    selectifApproved() {
      this.selected = false;
      if (this.request.status == 1) {
        this.selected = true;
      }
    },
    selectAll() {
      this.selected = true;
    },
    deselectAll() {
      this.selected = false;
    },
    approveBulk(id) {
      if (id && this.requestData.id && id == this.requestData.id) {
        this.requestData.status = true;
      }
    },
    approve() {
      this.requestData.status = true;
    },
    unApprove() {
      // this.requestData.status = false;
      // this.inviteChecked = false;
    },
    payBulk(id) {
      if (id && this.requestData.id && id == this.requestData.id) {
        this.requestData.paid_status = true;
      }
    },
    pay() {
      this.requestData.paid_status = true;
    },
    unPay() {
      this.requestData.paid_status = false;
    },
    getUserInfo(id) {
      var self = this;
      axios
        .post("/dashboard/get-user-info", { id: id })
        .then(function (response) {
          if (response.data.info) {
            self.leaderboardValues = response.data.info.leaderboardValues;
            self.pointValues = response.data.info.pointValues;
            self.site_name = response.data.info.site_name;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    viewPoints(id) {
      this.editMode = true;
      var self = this;
      axios
        .get("/view-points/" + id+"/registered")
        .then(function (response) {
          // handle success
          if (response.data.activityLog) {
            self.viewPointsData = response.data.activityLog;
            for (var i = self.viewPointsData.length - 1; i >= 0; i--) {
              if (i == self.viewPointsData.length - 1) {
                self.balance[i] = self.viewPointsData[i].points;
              } else {
                if (self.balance[i + 1] == null) {
                  self.balance[i + 1] = 0;
                }
                if (self.viewPointsData[i].points == null) {
                  self.viewPointsData[i].points = 0;
                }
                if (self.viewPointsData[i].paidPaymentRequest == 1) {
                  self.balance[i] =
                    self.balance[i + 1] - self.viewPointsData[i].points;
                } else if (self.viewPointsData[i].type == "Removal") {
                  self.balance[i] =
                    self.balance[i + 1] - self.viewPointsData[i].points;
                } else {
                  self.balance[i] =
                    self.balance[i + 1] + self.viewPointsData[i].points;
                }
              }
            }
          }
          if (self.requestData.user.created_at) {
            self.dateReg = self.requestData.user.created_at.split(" ");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      /*.finally(function () {
                        if(self.requestData.user.created_at) {
                            self.dateReg = self.requestData.user.created_at.split(' ');
                        }
                    });*/
    }
  },
  watch: {
    // selectbox: function(val){
    //     if(val){
    //         this.$emit('select', this.current_user_id);
    //     }else{
    //         this.$emit('select', '')
    //     }

    // },

    selectAll: function (val) {
      if (val) {
        this.childComponant.$emit("selectAll");
      } else {
        this.childComponant.$emit("deselectAll");
      }
    }
  },
  mounted() {
    if (this.advanced) {
      this.childComponant.$on("save", this.save);
      this.childComponant.$on("approve", this.approve);
      this.childComponant.$on("unApprove", this.unApprove);
      this.childComponant.$on("pay", this.pay);
      this.childComponant.$on("unPay", this.unPay);
      this.childComponant.$on("selectAll", this.selectAll);
      this.childComponant.$on("deselectAll", this.deselectAll);
      this.childComponant.$on("pendingAll", this.selectifPending);
      this.childComponant.$on("approvedAll", this.selectifApproved);
      this.childComponant.$on("approveBulk", this.approveBulk);
      this.childComponant.$on("payBulk", this.payBulk);
    }
    this.requestData.user.id = this.requestData.user_id;

    if (this.selectionAction == "all-pending" && this.requestData.status == 0) {
      this.selected = true;
    }
    if (
      this.selectionAction == "all-approved" &&
      this.requestData.status == 1
    ) {
      this.selected = true;
    }
  },
  computed: {
    currentTime() {
      var date = new Date();
      return date.getTime();
    }
  }
};
</script>
