<template>
  <div class="recruitment-process">
    <p>The recruitment process your candidates will be progressed through in the 'candidates' section is shown below:</p>
    <div class="grid-x align-middle">
      <div class="cell auto rec-process">
        <p>Expression of interest</p>
      </div>
      <embed src="/images/svg/right-arrow.svg" />
      <div class="cell auto rec-process">
        <p>Contacted & verified</p>
      </div>
      <embed src="/images/svg/right-arrow.svg" alt />
      <div class="cell auto rec-process">
        <p>Interview</p>
      </div>
      <embed src="/images/svg/right-arrow.svg" alt />
      <div class="cell auto rec-process">
        <p>Job offered</p>
      </div>
      <embed src="/images/svg/right-arrow.svg" alt />
      <div class="cell auto rec-process">
        <p>Started work</p>
      </div>
      <embed src="/images/svg/right-arrow.svg" alt />
      <div class="cell auto rec-process">
        <p>Starter reaches {{ milestone }} months</p>
      </div>
    </div>
    <br />
    <br />
    <p>
      At each of these stages, depending on the outcome (e.g. if the candidate is successful at interview
      or not) the app user who referred that candidate will be awarded points and/or send push notifications
      to update them on the candidate progress.
    </p>
    <p>If you want to, there are a few ways you can customize this process for your organization:</p>

    <div class="radio-tile-group" style="display: none;">
      <label
        class="radio-points-check"
      >&nbsp;&nbsp;Require candidates to be verified before expression of interest points are awarded?</label>
      <div class="input-container">
        <input class="radio-button" type="radio" name="pointsCheck" value="0" />

        <div class="radio-tile">
          <label for="walk" class="radio-tile-label">No</label>
        </div>
      </div>
      <div class="input-container">
        <input class="radio-button" type="radio" name="pointsCheck" value="1" />
        <div class="radio-tile">
          <label for="bike" class="radio-tile-label">Yes</label>
        </div>
      </div>
    </div>
    <p>Final milestone points and notification to be awarded on:</p>

	   <v-select
              v-model="smilestone"
              label="name"
              :options="milestonoptions"
              :reduce="referrer => referrer.id"
              :close-on-select="true"
			  :disabled="dispermission"
              :clearable="false"
			  class="col-md-3 "
        ></v-select>


    <div>
      <input
        type="submit"
        class="button pink_btn b_margin_0 no_wrap_btn float-right"
        value="Update changes"
        @click="submitRecruitment"
        :disabled="btnDisabled"
      />
    </div>

  </div>
</template>

<script>
export default {
  props: ["milestone", "newadmin", "accountcheck", "advancedadmin"],
  data() {
    return {
      smilestone: this.milestone,
      account: this.accountcheck,
      admin: this.advancedadmin,
      dispermission: true,
	  milestonoptions: [{id: '', name: 'Select milestone'}, {id: 12, name: '12 months'}, {id: 6, name: '6 months'}, {id: 3, name: '3 months'}, {id: 1, name: "1 month"}],
      data: {
        milestone: ""
      }
    };
  },
  methods: {
    submitRecruitment(e) {
      this.data.milestone = this.smilestone;
      e.preventDefault();
      const axios = require("axios");
      axios
        .post("/dashboard/account/recruitment-process", this.data, {})
        .then(response => {
          this.milestone = this.smilestone;
          console.log(response.data);
          Vue.swal({
            html:
              '<span class="success-circle"><i class="fas fa-check-circle"></i></span> PROCESS UPDATED.',
            confirmButtonColor: "#5dc2a6",
            confirmButtonText: '<i class="fa fa-times" aria-hidden="true"></i>',
            background: "#5dc2a6",
            customClass: {
              container: "candidate-toast"
            },
            showClass: {
              popup: "animated fadeInLeft fast",
              icon: "animated heartBeat delay-1s"
            },
            hideClass: {
              popup: "animated fadeOutLeft slow"
            },
            toast: true,
            timer: 5000,
            position: "top-right",
            target: "#example-tabs"
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    permission() {
      if (this.account == 1 && this.admin == 1) {
        this.dispermission = false;
      } else {
        this.dispermission = true;
      }
    }
  },
  mounted: function() {
    this.permission();
  },
  computed: {
    btnDisabled() {
      if (this.milestone != this.smilestone) {
        return false;
      } else return true;
    }
  }
};
</script>
